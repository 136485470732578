import * as MonthStyles from './components/MonthsWrapper/Month/Month.css';
import * as MonthHeaderStyles from './components/MonthsWrapper/Month/MonthHeader/MonthHeader.css';
import * as DayStyles from './components/MonthsWrapper/Month/Day/Day.css';
import * as WeeklyHeaderStyles from './components/MonthsWrapper/Month/WeeklyHeader/WeeklyHeader.css';
import * as MonthsWrapperStyles from './components/MonthsWrapper/MonthsWrapper.css';
import * as PopupDatepickerStyles from './components/PopupDatepickerStyles.css';
export default {
    PopupDatepickerStyles: PopupDatepickerStyles,
    MonthsWrapperStyles: MonthsWrapperStyles,
    MonthStyles: MonthStyles,
    DayStyles: DayStyles,
    WeeklyHeaderStyles: WeeklyHeaderStyles,
    MonthHeaderStyles: MonthHeaderStyles
};
