import React from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import AiPurchasePredictionMeal from '../AiPurchasePredictionMeal/AiPurchasePredictionMeal';
import AiPurchasePredictionBaggage from '../AiPurchasePredictionBaggage/AiPurchasePredictionBaggage';
var AiPurchasePredictionItem = function (_a) {
    var order = _a.order, service = _a.service, onChange = _a.onChange, onDisagree = _a.onDisagree, onAgree = _a.onAgree;
    var onAgreeAddServiceToOrder = function (service) {
        var segments = [];
        service.allowedSegments.forEach(function (segment) {
            segment.forEach(function (subSegment) {
                segments.push(subSegment);
            });
        });
        onAgree({
            passengerId: service.allowedPassengers[0][0],
            segmentId: service.allowedSegments[0][0],
            allowedSegments: service.allowedSegments,
            segmentIds: segments,
            serviceId: service.id,
            service: {
                id: service.id,
                type: service.type,
                price: service.price
            }
        });
    };
    if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
        var meal_1 = service;
        return (React.createElement(AiPurchasePredictionMeal, { order: order, meal: meal_1, onDisagree: onDisagree, onChange: function () { return onChange({ type: meal_1.type }); }, onAgree: function () { return onAgreeAddServiceToOrder(meal_1); } }));
    }
    if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
        var baggage_1 = service;
        return (React.createElement(AiPurchasePredictionBaggage, { item: baggage_1, onDisagree: onDisagree, onChange: function () { return onChange({ type: baggage_1.type, subType: baggage_1.baggageType }); }, onAgree: function () { return onAgreeAddServiceToOrder(baggage_1); } }));
    }
    return React.createElement(React.Fragment, null);
};
export default AiPurchasePredictionItem;
