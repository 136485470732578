import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme, ThemeProvider } from '../../../theme';
import PetPopup from '../PetPopup/PetPopup';
import Modal from '../../../Modal';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { getAnimalServices, getSegments, getTravellers, getIsConfirmed, getOrder } from '../../../Checkout/store/order/selectors';
import { useAnimalService } from '../hooks';
import { isServicesSaving } from '../../../Checkout/store/servicesIsSaving/selectors';
import { useRenderers } from '../../../renderProps';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import { getTravellersMap } from '../../../utils';
import BaggageSummary from '../../../ServiceSummary/services/BaggageSummary';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useIsCanModifyServices } from '../../hooks';
import { useToggleable } from '../../../hooks';
import Close from '../Close/Close';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
var Animal = function () {
    var _a;
    var css = useTheme('CheckoutAdditionalService').Animal;
    var _b = useTranslation('Checkout'), t = _b.t, i18n = _b.i18n;
    var order = useSelector(getOrder);
    var segments = useSelector(getSegments);
    var services = useSelector(getAnimalServices);
    var travellers = useSelector(getTravellers);
    var isOrderConfirmed = useSelector(getIsConfirmed);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var _d = useAnimalService(services, travellers), servicesByRfisc = _d.servicesByRfisc, minPrice = _d.minPrice, totalPrice = _d.totalPrice, onSave = _d.onSave, onClear = _d.onClear;
    var onDeleteItem = useCart().onDeleteItem;
    useEffect(function () {
        if (!isOrderServicesSaving) {
            close();
        }
    }, [isOrderServicesSaving]);
    var isCanModifyServices = useIsCanModifyServices(services);
    React.useEffect(function () {
        if (isOpen) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.PetsList);
        }
    }, [isOpen]);
    var travellersServices = useMemo(function () {
        var travellersMap = new Map();
        var servicesIds = services.map(function (service) { return service.id; });
        travellers.forEach(function (traveller) {
            var _a, _b, _c;
            var travellerServices = ((_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (service) { return servicesIds.includes(service.serviceId); })) ||
                [];
            if (travellerServices.length) {
                var segmentsIds_1 = travellerServices.reduce(function (acc, service) { return __spreadArray(__spreadArray([], __read(acc), false), __read(service.segmentIds), false); }, []);
                var allConfirmed_1 = segmentsIds_1.length > 1 && travellerServices.some(function (service) { return service.confirmedCount > 0; });
                var isAllFlights_1 = segmentsIds_1.length === segments.length;
                travellerServices.forEach(function (travellerService) {
                    var ids = !allConfirmed_1 && isOrderConfirmed
                        ? segmentsIds_1.filter(function (id) { return id === travellerService.segmentIds[0]; })
                        : segmentsIds_1;
                    var id = "".concat(traveller.id, "-").concat(ids.join('-')).concat(allConfirmed_1 ? "-confirmed" : '');
                    var services = travellerServices.filter(function (service) {
                        return service.serviceId === travellerService.serviceId &&
                            service.confirmedCount === travellerService.confirmedCount;
                    });
                    travellersMap.set(id, __assign(__assign({}, traveller), { travellerServices: services, segmentsIds: ids, isAllFlights: isAllFlights_1 }));
                });
            }
        });
        return __spreadArray([], __read(travellersMap.values()), false);
    }, [travellers, segments, isOrderConfirmed]);
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageSummary, { flight: order.flight, travellers: travellers, travellersById: getTravellersMap(order.travellers), services: order.additionalServices, onServiceDelete: onDeleteItem, isAnimalBaggage: true }, function (content) { return (React.createElement(ServiceWrapper, { className: css.container, header: t('Animal'), description: i18n.exists('Checkout:Take your pet in a trip') ? t('Checkout:Take your pet in a trip') : '', priceFrom: minPrice, priceTotal: totalPrice, buttons: [
                {
                    text: isCanModifyServices ? t('Change') : t('More details'),
                    action: open,
                    buttonType: 'change'
                }
            ], isSelected: !!travellersServices.length, isLoading: isOrderServicesSaving, addClick: open, addButtonText: !isCanModifyServices ? t('More details') : null },
            React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option_icon: css.option_icon,
                            option__icon: css.option__icon,
                            option__name: css.option__name
                        }
                    }
                } }, content))); }),
        React.createElement(Modal, { maxWidth: "sm", classes: { root: css.modal__root }, open: isOpen, onClose: close, closeButtonRenderer: function (onClick) { return React.createElement(Close, { onClick: onClick }); } },
            React.createElement(PetPopup, { segments: segments, services: servicesByRfisc, travellers: travellers, isLoading: isOrderServicesSaving, onConfirm: onSave, onClear: onClear, onClose: close, readOnly: !isCanModifyServices }))));
};
export default Animal;
