import React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
export var Info = function (props) {
    var _a;
    var css = useTheme('Communications').NotificationCard;
    return (React.createElement("span", { className: cn(css.card__info, props.className, (_a = {},
            _a[css.card__info_right] = props.align === 'right',
            _a)) }, props.children));
};
