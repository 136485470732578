import { __read, __spreadArray, __values } from "tslib";
import memes from '../../../memes';
import { SeatComfort } from '@websky/graphql';
import { getSeatPrice } from '../../utils';
var isSelectedSeatGuard = function (seat) {
    return seat.hasOwnProperty('type') || seat.hasOwnProperty('deckId') || seat.hasOwnProperty('isConfirmed');
};
export var isFreeSeat = function (seat) {
    var seatPrice = getSeatPrice(seat);
    if (isSelectedSeatGuard(seat)) {
        return !seat.rfisc && (seatPrice === null || seatPrice === void 0 ? void 0 : seatPrice.amount) === 0;
    }
    else {
        return (seatPrice === null || seatPrice === void 0 ? void 0 : seatPrice.amount) === 0 && !seat.rfisc && seat.isAvailable && seat.isExistent;
    }
};
export var getRowService = memes(function (seats) {
    return seats[0].isExistent && !seats[0].isAisle && seats[0].seatService;
});
export var cabinCodeComfortMap = new Map([
    ['C', SeatComfort.Comfort],
    ['Y', SeatComfort.Standard]
]);
var getRowComfort = function (row) {
    var _c;
    if (!((_c = row === null || row === void 0 ? void 0 : row.seats) === null || _c === void 0 ? void 0 : _c.length)) {
        return null;
    }
    var comfortCountMap = new Map();
    // Collect the count of available comfort levels by rows
    // to return the most frequently occurring comfort
    row.seats.forEach(function (seat) {
        var _c, _d;
        if (((_c = seat.seatService) === null || _c === void 0 ? void 0 : _c.comfort) && !seat.isAisle) {
            var comfort = seat.seatService.comfort;
            comfortCountMap.set(comfort, ((_d = comfortCountMap.get(comfort)) !== null && _d !== void 0 ? _d : 0) + (seat.isAvailable ? 1 : 0));
        }
    });
    if (comfortCountMap.size > 1) {
        // Returns the most frequently occurring comfort
        return __spreadArray([], __read(comfortCountMap.entries()), false).sort(function (_c, _d) {
            var _e = __read(_c, 2), _a = _e[0], aCount = _e[1];
            var _f = __read(_d, 2), _b = _f[0], bCount = _f[1];
            return bCount - aCount;
        })[0][0];
    }
    return __spreadArray([], __read(comfortCountMap.keys()), false)[0];
};
export var getRowsWithDifferentClasses = memes(function (rows, splitByCabin, isNewServicePredicate) {
    var rowsWithDifferentClassesMap = new Map();
    var rowComfort = getRowComfort(rows[0]);
    if (rowComfort) {
        rowsWithDifferentClassesMap.set(rows[0].number, rowComfort);
    }
    rows.forEach(function (nextRow, index) {
        var _c, _d;
        var prevRow = rows[index - 1];
        if (prevRow) {
            var lastRowNumber = __spreadArray([], __read(rowsWithDifferentClassesMap.keys()), false).pop();
            var lastRowComfort = rowsWithDifferentClassesMap.get(lastRowNumber);
            var prevRowComfort = (_c = getRowComfort(prevRow)) !== null && _c !== void 0 ? _c : lastRowComfort;
            var rowComfort_1 = (_d = getRowComfort(nextRow)) !== null && _d !== void 0 ? _d : lastRowComfort;
            var diffComfort = prevRowComfort !== rowComfort_1;
            if (isNewServicePredicate) {
                if (isNewServicePredicate(prevRow, nextRow)) {
                    rowsWithDifferentClassesMap.set(nextRow.number, rowComfort_1);
                }
            }
            else {
                if (splitByCabin) {
                    var diffCabinCode = prevRow.cabinCode !== nextRow.cabinCode;
                    if (diffCabinCode) {
                        rowsWithDifferentClassesMap.set(nextRow.number, cabinCodeComfortMap.get(nextRow.cabinCode));
                    }
                }
                else if (diffComfort) {
                    rowsWithDifferentClassesMap.set(nextRow.number, rowComfort_1);
                }
            }
        }
    });
    return rowsWithDifferentClassesMap;
});
export var getMinPriceOfServiceClass = function (rows, firstIdxOfNewClass, comfort, rowsWithDifferentClasses) {
    var _c;
    var minPrice = null;
    var rowIdx = firstIdxOfNewClass;
    var isNextClass = false;
    while (rowIdx < rows.length && !isNextClass) {
        var row = rows[rowIdx];
        row.parts.forEach(function (part) {
            var _c;
            if (((_c = part.service.seatService) === null || _c === void 0 ? void 0 : _c.comfort) === comfort &&
                (!minPrice || part.service.minPrice.amount < minPrice.amount)) {
                minPrice = part.service.minPrice;
            }
        });
        rowIdx++;
        isNextClass = rowsWithDifferentClasses.has((_c = rows[rowIdx]) === null || _c === void 0 ? void 0 : _c.number);
    }
    return minPrice;
};
export var getActualService = function (services, part) {
    var e_1, _c;
    var _d, _e, _f, _g;
    // пробуем найти по максимальному количеству однотипных сервисов
    var serviceComfortCount = new Map();
    try {
        for (var _h = __values(part.seats.values()), _j = _h.next(); !_j.done; _j = _h.next()) {
            var seat = _j.value;
            if (seat.seatService) {
                var countItem = serviceComfortCount.get(seat.seatService.comfort);
                if (!countItem) {
                    countItem = { serviceId: seat.seatService.id, count: 0 };
                    serviceComfortCount.set(seat.seatService.comfort, countItem);
                }
                countItem.count++;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var maxCount = 0;
    var serviceId = null;
    serviceComfortCount.forEach(function (item) {
        if (item.count > maxCount) {
            maxCount = item.count;
            serviceId = item.serviceId;
        }
    });
    if (serviceId) {
        if (services === null || services === void 0 ? void 0 : services.hasOwnProperty(serviceId)) {
            return (_d = services[serviceId]) === null || _d === void 0 ? void 0 : _d.comfort;
        }
        else {
            return (_f = (_e = part.seats.find(function (seat) { var _c; return ((_c = seat.seatService) === null || _c === void 0 ? void 0 : _c.id) === serviceId; })) === null || _e === void 0 ? void 0 : _e.seatService) === null || _f === void 0 ? void 0 : _f.comfort;
        }
    }
    // тогда по первому существующему
    var firstExistService = part.seats.find(function (seat) { return seat.seatService; });
    if (firstExistService === null || firstExistService === void 0 ? void 0 : firstExistService.seatService) {
        return (_g = services[firstExistService.seatService.id]) === null || _g === void 0 ? void 0 : _g.comfort;
    }
    return null;
};
