import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import DesktopFlightModalHeader from '../DesktopFlightModalHeader/DesktopFlightModalHeader';
import FlightInfoSegment from '../FlightInfoSegment/FlightInfoSegment';
import { getNextSegment } from '../../utils';
import { getDurationString, getFlightDuration } from '../../../Results/utils';
import SelectedFareGroup from '../SelectedFareGroup/SelectedFareGroup';
var DesktopFlightModalContentSelected = function (_a) {
    var _b, _c, _d, _e;
    var _f, _g;
    var className = _a.className, flight = _a.flight, lastSelectedFare = _a.lastSelectedFare, flightId = _a.flightId, onClose = _a.onClose, orderId = _a.orderId, hideConditionsButton = _a.hideConditionsButton, isReturnFlight = _a.isReturnFlight, _h = _a.isSelectorInModal, isSelectorInModal = _h === void 0 ? true : _h;
    var css = useTheme('DesktopFlight').DesktopFlightModalContentSelected;
    var t = useTranslation('FlightInfoModalContent').t;
    var price = ((_g = (_f = flight.pricesForFareGroups) === null || _f === void 0 ? void 0 : _f[0].prices) === null || _g === void 0 ? void 0 : _g[0].price) || null;
    return (React.createElement("div", { className: cn(className, (_b = {}, _b[css.container_dropdown] = !isSelectorInModal, _b)) },
        isSelectorInModal && React.createElement(DesktopFlightModalHeader, { title: t('You choose'), onClose: onClose }),
        React.createElement("div", { className: cn(css.content, (_c = {}, _c[css.content__center] = !lastSelectedFare, _c)) },
            lastSelectedFare && (React.createElement(SelectedFareGroup, { className: cn(css.flight, (_d = {}, _d[css.flight_thin] = flight.segments.length > 1, _d)), flightId: flightId, orderId: orderId, fare: lastSelectedFare, price: price, hideConditionsButton: hideConditionsButton })),
            React.createElement("div", { className: cn(css.segments, (_e = {},
                    _e[css.segments__big] = flight.segments.length > 1,
                    _e)) }, flight.segments.map(function (segment, index) { return (React.createElement("div", { className: css.segment__flight, key: index },
                React.createElement(FlightInfoSegment, { className: css.segment__flightWrapper, cardClassName: css.segment__card, transferClassName: css.segment__transfer, segment: segment, nextSegment: segment.transferDuration ? getNextSegment(flight.segments, index) : null, isMobile: true, isReturnFlight: isReturnFlight }),
                flight.segments.length > 1 && flight.segments.length - 1 === index && (React.createElement("div", { className: css.time },
                    t('Total duration'),
                    ": ",
                    getDurationString(getFlightDuration(flight.segments)))))); })))));
};
export default DesktopFlightModalContentSelected;
