import * as PaymentForm from './components/PaymentForm.css';
import * as Gateway from './components/Gateway/Gateway.css';
import * as GooglePay from './components/Gateway/GooglePay/GooglePay.css';
import * as Card from './components/Gateway/Iframe/Iframe.css';
import * as Summary from './components/Summary/Summary.css';
import * as Cash from './components/Gateway/Cash/Cash.css';
import * as Invoice from './components/Gateway/Invoice/Invoice.css';
import Qiwi from './components/Gateway/Qiwi/Qiwi.css';
import CopyCode from './components/CopyCode/CopyCode.css';
export default {
    PaymentForm: PaymentForm,
    Gateway: Gateway,
    GooglePay: GooglePay,
    Card: Card,
    Summary: Summary,
    Cash: Cash,
    Invoice: Invoice,
    Qiwi: Qiwi,
    CopyCode: CopyCode
};
