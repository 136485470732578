import * as Account from './components/AuthMethods/AuthMethods.css';
import * as DeleteAccountDialog from './components/DeleteAccountDialog/DeleteAccountDialog.css';
import * as InfoField from './components/AuthMethods/AuthMethod/AuthMethod.css';
import * as SocialButton from './components/SocialButton/SocialButton.css';
import * as SocialAuth from './components/SocialAuth/SocialAuth.css';
import * as NextFlight from './components/NextFlight/NextFlight.css';
import * as ConfirmDialog from './components/AuthMethods/AuthDialog/AuthDialog.css';
export default {
    Account: Account,
    DeleteAccountDialog: DeleteAccountDialog,
    InfoField: InfoField,
    SocialButton: SocialButton,
    SocialAuth: SocialAuth,
    NextFlight: NextFlight,
    ConfirmDialog: ConfirmDialog
};
