import { createAction } from 'redux-actions';
export var SET_PASSENGERS_STATE = 'SET_PASSENGERS_STATE';
export var SET_PASSENGER_VALUES = 'SET_PASSENGER_VALUES';
export var SET_PASSENGER_SERVICES = 'SET_PASSENGER_SERVICES';
export var SET_PASSENGER_SEATS = 'SET_PASSENGER_SEATS';
export var setPassengersState = createAction(SET_PASSENGERS_STATE);
export var setPassengerValues = createAction(SET_PASSENGER_VALUES, function (id, values) { return ({
    id: id,
    values: values
}); });
