import * as React from 'react';
import cn from 'classnames';
import MultiCity from './MultiCity/MultiCity';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import SearchFormPromoCode from './PromoCode/PromoCode';
import PayByMiles from './PayByMiles/PayByMiles';
import OfferHotels from './OfferHotels/OfferHotels';
import { PromoCodeStatus } from '../../../../PromoCode/types';
var Bottom = React.memo(function (props) {
    var _a = useTheme('SearchForm'), css = _a.Bottom, SearchForm = _a.SearchForm;
    var promoCode = props.promoCode;
    var _b = useConfig().SearchForm, enableComplexRoute = _b.enableComplexRoute, proMode = _b.proMode, payByMiles = _b.payByMiles, offerHotels = _b.offerHotels;
    var isControlsAvailable = (enableComplexRoute && !proMode) || offerHotels;
    return (React.createElement("div", { className: cn(SearchForm.row, css.bottom) },
        props.renderBefore,
        isControlsAvailable && (React.createElement("div", { className: css.controls },
            enableComplexRoute && !proMode && React.createElement(MultiCity, null),
            offerHotels && React.createElement(OfferHotels, null))),
        props.renderPromoCode ? (props.renderPromoCode()) : (React.createElement(SearchFormPromoCode, { promoCode: promoCode, status: promoCode ? PromoCodeStatus.Provided : undefined })),
        payByMiles && React.createElement(PayByMiles, null)));
});
export default Bottom;
