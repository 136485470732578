import * as React from 'react';
import { useContext } from 'react';
import { CartService } from '../../types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartContext } from '../../Cart';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import { useTranslation } from 'react-i18next';
import { BusinessLounge as BusinessLoungeIcon } from '../../../Icons';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
var BusinessLounge = function (_a) {
    var _b, _c;
    var travellers = _a.travellers, services = _a.services, onServiceDelete = _a.onServiceDelete;
    var businessLoungeServiceIds = (_c = (_b = services.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge; }).map(function (service) { return service.id; });
    var cartContext = useContext(CartContext);
    var t = useTranslation('Cart').t;
    var onDeleteHandler = function (travellerId, serviceId, segmentId) {
        onServiceDelete(CartService.BusinessLounge, {
            passengerId: travellerId,
            serviceId: serviceId,
            segmentId: segmentId
        });
    };
    var items = React.useMemo(function () {
        var items = [];
        travellers.forEach(function (traveller) {
            var _a, _b, _c, _d;
            var travellerName = (_a = cartContext.travellersById.get(traveller.id)) === null || _a === void 0 ? void 0 : _a.name;
            (_d = (_c = (_b = traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.forEach(function (service) {
                if (businessLoungeServiceIds.includes(service.serviceId)) {
                    var gdsService_1 = services.gdsServices.services.find(function (gdsService) { return gdsService.id === service.serviceId; });
                    service.segmentIds.forEach(function (segment) {
                        var _a;
                        var segmentDirection = (_a = cartContext.segmentsById.get(segment)) === null || _a === void 0 ? void 0 : _a.iataDirection;
                        items.push({
                            id: "".concat(traveller.id, "_").concat(service.serviceId, "_").concat(segment),
                            name: "".concat(segmentDirection, ", ").concat(travellerName),
                            price: gdsService_1.price,
                            included: service.confirmedCount === service.count,
                            onDelete: function () { return onDeleteHandler(traveller.id, service.serviceId, segment); }
                        });
                    });
                }
            });
        });
        return items;
    }, [businessLoungeServiceIds, travellers]);
    if (!items.length) {
        return null;
    }
    return (React.createElement(ServiceSummaryDivider, null,
        React.createElement(ServiceSummaryOption, { groupOption: {
                header: t('Business lounge'),
                headerIcon: BusinessLoungeIcon,
                items: items
            } })));
};
export default BusinessLounge;
