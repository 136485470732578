import { isAfter, isEqual, parseISO } from 'date-fns';
import { Sorting } from '../../../enums';
import { getDurationInSeconds, getFlightDuration } from '../../../utils';
import { FlightClass } from '@websky/graphql';
export var getFlightMinPrice = function (flight) {
    var result = null;
    flight.pricesForFareGroups.forEach(function (group) {
        if (group.prices && group.prices.length) {
            if (group.prices[0].price && (result === null || result > group.prices[0].price.amount)) {
                result = group.prices[0].price.amount;
            }
        }
    });
    return result;
};
export var getIataCompare = function (flight, iata) {
    var _a, _b;
    return ((_b = (_a = flight.segments[0].segment) === null || _a === void 0 ? void 0 : _a.operatingAirline) === null || _b === void 0 ? void 0 : _b.iata) === iata ? -1 : 1;
};
export var sortFlights = function (flights, sorting, iata, sortResultPreferCurrentAirline) {
    switch (sorting) {
        case Sorting.BestFlight:
            return flights;
        case Sorting.Price:
            return flights.sort(function (a, b) {
                var aPrice = getFlightMinPrice(a);
                var bPrice = getFlightMinPrice(b);
                var priceCompare = aPrice === bPrice ? 0 : aPrice > bPrice ? 1 : -1;
                if (sortResultPreferCurrentAirline) {
                    var aIata = getIataCompare(a, iata);
                    var bIata = getIataCompare(b, iata);
                    if (aIata === bIata) {
                        return priceCompare;
                    }
                    return aIata > bIata ? 1 : -1;
                }
                return priceCompare;
            });
        case Sorting.Departure:
            return flights.sort(function (a, b) {
                var aDate = parseISO("".concat(a.segments[0].segment.departure.date, "T").concat(a.segments[0].segment.departure.time));
                var bDate = parseISO("".concat(b.segments[0].segment.departure.date, "T").concat(b.segments[0].segment.departure.time));
                if (isEqual(aDate, bDate)) {
                    return 0;
                }
                return isAfter(aDate, bDate) ? 1 : -1;
            });
        case Sorting.Arrival:
            return flights.sort(function (a, b) {
                var aDate = parseISO("".concat(a.segments[a.segments.length - 1].segment.arrival.date, "T").concat(a.segments[a.segments.length - 1].segment.arrival.time));
                var bDate = parseISO("".concat(b.segments[b.segments.length - 1].segment.arrival.date, "T").concat(b.segments[b.segments.length - 1].segment.arrival.time));
                if (isEqual(aDate, bDate)) {
                    return 0;
                }
                return isAfter(aDate, bDate) ? 1 : -1;
            });
        case Sorting.FlightTime:
            return flights.sort(function (a, b) {
                var aPrice = getDurationInSeconds(getFlightDuration(a.segments));
                var bPrice = getDurationInSeconds(getFlightDuration(b.segments));
                if (aPrice === bPrice) {
                    return 0;
                }
                return aPrice > bPrice ? 1 : -1;
            });
        default:
            return flights;
    }
};
// Check that all rows has available flights, according to previously selected flight id.
export var filterFlightsBySelectedFares = function (flights, selectedFares) {
    return flights.filter(function (flight) {
        return flight.pricesForFareGroups.some(function (_a) {
            var prices = _a.prices;
            if (!prices) {
                return false;
            }
            if (selectedFares.length) {
                var lastSelectedFare = selectedFares[selectedFares.length - 1];
                var selectedMap_1 = {};
                lastSelectedFare.prices.forEach(function (price) { return (selectedMap_1[price.flight.id] = price.flight.id); });
                return prices.some(function (price) { return selectedMap_1.hasOwnProperty(price.flight.id); });
            }
            else {
                return true;
            }
        });
    });
};
export var filterFlightsByType = function (flights, directOnly, businessClass) {
    if (directOnly === void 0) { directOnly = false; }
    if (businessClass === void 0) { businessClass = false; }
    var filteredFlights = flights;
    if (directOnly) {
        filteredFlights = filteredFlights.filter(function (flight) { return flight.segments.length <= 1; });
    }
    if (businessClass) {
        filteredFlights = filteredFlights.filter(function (flight) {
            return flight.pricesForFareGroups.some(function (fare) { return fare.fareFamily.category === FlightClass.Business; });
        });
    }
    return filteredFlights;
};
