import { __assign } from "tslib";
import * as React from 'react';
import { forwardRef, memo } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import LocationAutocompleteComponent from './LocationAutocomplete';
import { useGraphQLClient } from '../../graphql-client';
var LocationAutocomplete = memo(forwardRef(function (props, ref) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(LocationAutocompleteComponent, __assign({ ref: ref }, props))));
}), function (prevProps, nextProps) {
    for (var prop in nextProps) {
        // Dirty hacks.
        if (nextProps.hasOwnProperty(prop) && prop !== 'inputRenderer') {
            if (nextProps[prop] !== prevProps[prop]) {
                return false;
            }
        }
    }
    return true;
});
export default LocationAutocomplete;
