import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import ContactsForm from '../../../../../Contacts/components/Contacts';
import { unlockForm } from '../../../../store/formIsLocked/actions';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import { InputType } from '../../../../../Passenger/types';
import { getOrderId, getIsReadonly, getIsRedirectFromMetasearch } from '../../../../store/order/selectors';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import { reachGoal, SplitTestGoal } from '../../../../../analytics';
import MobileAuthForm from '../../../../../MobileAuthForm';
import { get, set, TEST_AUTHORIZATION_REFUSED } from '../../../../../cache';
import { fillOrder } from '../../../../store/order/actions';
import { LoginType, TravellerFieldEnum } from '@websky/graphql';
import Modal from '../../../../../Modal';
import { useRenderers } from '../../../../../renderProps';
// #fixme: a lot of state objects
var Contacts = function (props) {
    var loginType = LoginType.Email;
    var _a = __read(useState(false), 2), customerFormIsOpen = _a[0], setCustomerFormIsOpen = _a[1];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var getUserInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, showCustomerForm;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, ((_a = props.refetchUser) === null || _a === void 0 ? void 0 : _a.call(props))];
                case 1:
                    data = _b.sent();
                    if (data && data.CurrentUser) {
                        props.unlockForm();
                    }
                    else {
                        showCustomerForm = isMobile && !get("".concat(TEST_AUTHORIZATION_REFUSED, "_").concat(props.orderId)) && !props.isReadonly;
                        if (showCustomerForm) {
                            // Somehow it doesn't work without timeout. The form gets re-rendered.
                            setTimeout(function () { return setCustomerFormIsOpen(true); }, 200);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var closeCustomerFormWithRefused = function () {
        setCustomerFormIsOpen(false);
        set("".concat(TEST_AUTHORIZATION_REFUSED, "_").concat(props.orderId), true);
        reachGoal(SplitTestGoal.RefusedToAuthorize);
    };
    var closeCustomerFormWithAgreed = function () {
        var _a;
        setCustomerFormIsOpen(false);
        reachGoal(SplitTestGoal.AgreedToAuthorize);
        (_a = props.refetchUser) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var agreeToAuthorize = function () {
        reachGoal(SplitTestGoal.AgreedToAuthorize);
    };
    useEffect(function () {
        if (!props.isReadonly) {
            getUserInfo();
        }
    }, [props.isReadonly]);
    var email = props.customer.values.find(function (_a) {
        var type = _a.type;
        return type === TravellerFieldEnum.Email;
    });
    var phone = props.customer.values.find(function (_a) {
        var type = _a.type;
        return type === TravellerFieldEnum.Phone;
    });
    var open = !props.isReadonly || props.isInputsReadonly;
    var ContactsWidget = useRenderers()
        .CheckoutContacts;
    if (!ContactsWidget) {
        ContactsWidget = ContactsForm;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ContactsWidget, { open: open, isInputsReadonly: props.isInputsReadonly, autofocus: props.autofocus, isAuthorized: !!props.userInfo, toggleOpen: function () { }, submitForm: props.submitForm, unlockForm: props.unlockForm, isFormLocked: props.isFormLocked, onLoginOpen: props.onLoginOpen, loginType: loginType, showSubscribeCheckbox: props.customer.showSubscribeCheckbox, email: {
                name: "customer.".concat(email.name),
                type: email.type,
                input: InputType.Text,
                defaultValue: email.value,
                validations: email.validationRules
            }, phone: {
                name: "customer.".concat(phone.name),
                type: phone.type,
                input: InputType.Phone,
                defaultValue: phone.value,
                validations: phone.validationRules
            } }),
        !props.userInfo && !props.isRedirectFromMetasearch && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: customerFormIsOpen, onClose: closeCustomerFormWithRefused, bottomDrawer: true, isRounded: true },
                React.createElement(MobileAuthForm, { onSuccess: closeCustomerFormWithAgreed, onReject: closeCustomerFormWithRefused, onSocialAuthClick: agreeToAuthorize }))))));
};
var mapStateToProps = function (state) {
    return {
        orderId: getOrderId(state),
        isReadonly: getIsReadonly(state),
        isRedirectFromMetasearch: getIsRedirectFromMetasearch(state),
        isFormLocked: isFormLocked(state)
    };
};
var mapDispatchToProps = {
    unlockForm: unlockForm,
    fillOrder: fillOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
