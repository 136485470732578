import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { OauthServiceType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import { icons } from './icons';
import AuthMethod from '../AuthMethods/AuthMethod/AuthMethod';
initI18n('SocialAuth');
var SocialAuth = function (_a) {
    var authMethods = _a.authMethods, onAuthRedirect = _a.onAuthRedirect, onDelete = _a.onDelete, socialAuthMethods = _a.socialAuthMethods;
    var t = useTranslation('SocialAuth').t;
    var css = useTheme('Account').SocialAuth;
    var activeAuthMethods = React.useMemo(function () {
        if (authMethods === null || authMethods === void 0 ? void 0 : authMethods.length) {
            return authMethods.filter(function (method) { return method.status; });
        }
        return [];
    }, [authMethods]);
    var availableCommonAuthMethods = React.useMemo(function () {
        if (activeAuthMethods.length) {
            return socialAuthMethods.filter(function (method) {
                var _a;
                return !((_a = activeAuthMethods.find(function (activeMethod) { return activeMethod.service.includes(method.service); })) === null || _a === void 0 ? void 0 : _a.status) &&
                    method.isAvailable;
            });
        }
        return socialAuthMethods.filter(function (method) { return method.isAvailable; });
    }, [activeAuthMethods, socialAuthMethods]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: cn(css.wrapper__auth) },
            React.createElement("span", { className: css.wrapper__title }, t('Quick login to your account')), activeAuthMethods === null || activeAuthMethods === void 0 ? void 0 :
            activeAuthMethods.map(function (item) { return (React.createElement(AuthMethod, { key: item.id, id: item.id, icon: icons[item.service], type: item.service, value: item.userName, confirmed: true, isRemovable: true, onDelete: onDelete })); }),
            availableCommonAuthMethods.length > 0 && (React.createElement("div", { className: css.login },
                activeAuthMethods.length > 0 && (React.createElement("span", { className: css.login__subtitle }, t('Connect another social media account'))),
                React.createElement("div", { className: css.login__auth }, availableCommonAuthMethods.map(function (method) { return (React.createElement("div", { "data-type": OauthServiceType[method.service], className: css.login__auth_icon, onClick: function () {
                        onAuthRedirect(OauthServiceType[method.service]);
                    } }, icons[method.service])); })))))));
};
export default SocialAuth;
