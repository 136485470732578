import { __assign } from "tslib";
import * as React from 'react';
import { Field } from '../../../../../Passenger/components/Field/Field';
import { FieldsGroupType } from '../utils';
import LoyaltyGroup from './LoyaltyGroup/LoyaltyGroup';
import ChangeGroup from './ChangeGroup/ChangeGroup';
import { TravellerFieldEnum } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import { OverrideComponent } from '../../../../../renderProps';
import Disabilities from '../../Disabilities/Disabilities';
var Group = function (props) {
    var _a;
    if (props.groupType === FieldsGroupType.Changes) {
        return React.createElement(ChangeGroup, __assign({}, props));
    }
    else if (props.groupType === FieldsGroupType.Loyalty) {
        return React.createElement(LoyaltyGroup, __assign({}, props));
    }
    else if (props.groupType === FieldsGroupType.Other) {
        return React.createElement(Disabilities, __assign({}, props));
    }
    var theme = useTheme('PassengerForm').Group;
    var birthDateField = React.useMemo(function () { return props.fields.find(function (field) { return field.type === TravellerFieldEnum.BirthDate; }); }, [props.fields]);
    return (React.createElement("div", { className: cn(theme.container, (_a = {},
            _a[theme.identity] = props.groupType === FieldsGroupType.Identity,
            _a[theme.documents] = props.groupType === FieldsGroupType.Documents,
            _a[theme.withIcons] = !!props.groupIcon,
            _a)) },
        props.groupIcon && React.createElement("div", { className: theme.icon }, props.groupIcon),
        props.fields.map(function (field, index) {
            var _a;
            return (React.createElement(OverrideComponent, { componentProps: {
                    disabled: field.readonly || props.disabled,
                    field: field,
                    birthDateField: birthDateField,
                    passengerId: props.passengerId,
                    onTravellerSelect: props.onTravellerSelect,
                    selectedProfileId: props.selectedProfileId,
                    passengerType: props.passengerType,
                    passengerDocType: props.passengerDocType,
                    className: cn((_a = {},
                        _a[theme.field_sex] = field.type === TravellerFieldEnum.Gender,
                        _a[theme.field_birthdate] = field.type === TravellerFieldEnum.BirthDate,
                        _a[theme.field_middleName] = field.type === TravellerFieldEnum.MiddleName,
                        _a)),
                    loadTravellers: props.isAuthorized,
                    autofocus: props.autofocus && index === 0,
                    onSwap: props.onSwap
                }, component: {
                    PassengerFormField: Field
                }, key: "".concat(field.name, "_").concat(index) }));
        })));
};
export default Group;
