import * as React from 'react';
export var rubleIcon = (React.createElement("svg", { width: "512", height: "512", viewBox: "0 0 512 512", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M512 256C512 397.385 397.385 512 256 512C114.615 512 0 397.385 0 256C0 114.615 114.615 0 256 0C397.385 0 512 114.615 512 256ZM390.427 188.72C390.427 250.419 340.511 300.44 278.707 300.44H221.726V324.426H289.953C295.752 324.426 300.453 329.127 300.453 334.926V379.913C300.453 385.712 295.752 390.413 289.953 390.413H221.727V424.9C221.727 430.699 217.026 435.4 211.227 435.4H166.24C160.441 435.4 155.74 430.699 155.74 424.9V390.414H132.5C126.701 390.414 122 385.713 122 379.914V334.927C122 329.128 126.701 324.427 132.5 324.427H155.74V300.44H132.5C126.701 300.44 122 295.739 122 289.94V244.953C122 239.154 126.701 234.453 132.5 234.453H155.74V87.5C155.74 81.7012 160.441 77 166.24 77H278.707C340.31 77 390.427 127.117 390.427 188.72ZM278.706 142.987H221.726V234.453H278.706C303.923 234.453 324.439 213.937 324.439 188.72C324.439 163.503 303.923 142.987 278.706 142.987Z", fill: "currentColor" })));
export var dollarIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.66699 10.0003C1.66699 5.40033 5.40033 1.66699 10.0003 1.66699C14.6003 1.66699 18.3337 5.40033 18.3337 10.0003C18.3337 14.6003 14.6003 18.3337 10.0003 18.3337C5.40033 18.3337 1.66699 14.6003 1.66699 10.0003ZM10.8033 11.1728C10.9586 11.341 11.0363 11.5688 11.0363 11.8563C11.0363 12.1332 10.9502 12.354 10.7779 12.5187C10.6057 12.68 10.371 12.7606 10.0738 12.7606C9.71579 12.7606 9.44055 12.6502 9.24805 12.4293C9.05892 12.205 8.96436 11.8843 8.96436 11.4672H7.50033C7.50033 12.1857 7.68945 12.7623 8.0677 13.197C8.44933 13.6281 8.98631 13.8822 9.67864 13.9593V15.0003H10.4841V13.9541C11.1123 13.8875 11.6054 13.6684 11.9633 13.2969C12.3213 12.9253 12.5003 12.4416 12.5003 11.8458C12.5003 11.5268 12.453 11.2481 12.3585 11.0098C12.2639 10.7679 12.1271 10.5541 11.9481 10.3684C11.7692 10.1791 11.5496 10.0108 11.2896 9.86363C11.0295 9.71291 10.6969 9.55343 10.2916 9.38518C9.88972 9.21694 9.61448 9.0522 9.46588 8.89097C9.31728 8.72973 9.24298 8.51592 9.24298 8.24954C9.24298 7.96212 9.31897 7.73779 9.47094 7.57656C9.62292 7.41182 9.83737 7.32945 10.1143 7.32945C10.3946 7.32945 10.6175 7.43461 10.783 7.64491C10.9519 7.85171 11.0363 8.16542 11.0363 8.58602H12.5003C12.5003 7.90253 12.3315 7.34698 11.9937 6.91936C11.656 6.48823 11.1883 6.22886 10.5905 6.14123V5.00033H9.77996V6.12546C9.17544 6.18855 8.6908 6.40937 8.32606 6.78792C7.96132 7.16647 7.77895 7.65192 7.77895 8.24428C7.77895 8.58778 7.83129 8.88396 7.93599 9.13282C8.04406 9.38168 8.19266 9.59899 8.38178 9.78476C8.57429 9.96703 8.80394 10.13 9.07074 10.2737C9.33754 10.4139 9.655 10.5559 10.0231 10.6996C10.3912 10.8433 10.6513 11.001 10.8033 11.1728Z", fill: "currentColor" })));
export var checkIcon = (React.createElement("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM14.7559 8.58926C15.0814 8.26382 15.0814 7.73618 14.7559 7.41074C14.4305 7.08531 13.9028 7.08531 13.5774 7.41074L8.90351 12.0846L6.42259 9.60373C6.09715 9.27829 5.56951 9.27829 5.24408 9.60373C4.91864 9.92916 4.91864 10.4568 5.24408 10.7822L8.90351 14.4417L14.7559 8.58926Z", fill: "currentColor" })));
export var noIcon = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.00033 0.666992C4.40033 0.666992 0.666992 4.40033 0.666992 9.00033C0.666992 13.6003 4.40033 17.3337 9.00033 17.3337C13.6003 17.3337 17.3337 13.6003 17.3337 9.00033C17.3337 4.40033 13.6003 0.666992 9.00033 0.666992ZM2.33366 9.00033C2.33366 5.31699 5.31699 2.33366 9.00033 2.33366C10.542 2.33366 11.9587 2.85866 13.0837 3.74199L3.74199 13.0837C2.85866 11.9587 2.33366 10.542 2.33366 9.00033ZM4.917 14.2587C6.042 15.142 7.45866 15.667 9.00033 15.667C12.6837 15.667 15.667 12.6837 15.667 9.00033C15.667 7.45866 15.142 6.04199 14.2587 4.91699L4.917 14.2587Z", fill: "currentColor" })));
export var chevronIcon = (React.createElement("svg", { width: "8", height: "12", viewBox: "0 0 8 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.59 0L8 1.41L3.42 6L8 10.59L6.59 12L0.59 6L6.59 0Z", fill: "currentColor" })));
export var closeIcon = (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M25.3337 8.5465L23.4537 6.6665L16.0003 14.1198L8.54699 6.6665L6.66699 8.5465L14.1203 15.9998L6.66699 23.4532L8.54699 25.3332L16.0003 17.8798L23.4537 25.3332L25.3337 23.4532L17.8803 15.9998L25.3337 8.5465Z", fill: "white" })));
