import { createSelector } from 'reselect';
import { StepType } from '../../types';
import { getHasSelectedPassengers } from '../passengers/selectors';
import { getSelectedReason } from '../reason/selectors';
import { getIsAllDocumentsProvided } from '../documents/selectors';
export var getSteps = function (state) { return state.steps; };
export var getActiveStep = createSelector(getSteps, function (steps) { return steps.find(function (step) { return step.isActive; }); });
export var getCanGoToNextStep = createSelector(getActiveStep, getHasSelectedPassengers, getSelectedReason, getIsAllDocumentsProvided, function (activeStep, hasSelectedPassengers, selectedReason, isAllDocumentsProvided) {
    switch (activeStep.type) {
        case StepType.Passengers:
            return hasSelectedPassengers;
        case StepType.Reason:
            return !!selectedReason;
        case StepType.AttachFiles:
            return isAllDocumentsProvided;
        default:
            return true;
    }
});
