import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getTravellersMap } from '../../../utils';
import { GdsServiceVipSubType } from '@websky/graphql';
import { PriorityBoarding as PriorityBoardingIcon } from '../../../Icons';
import VipSummary from '../../../ServiceSummary/services/VipSummary';
var PriorityBoarding = function (_a) {
    var travellers = _a.travellers, services = _a.services, flight = _a.flight, onServiceDelete = _a.onServiceDelete;
    var t = useTranslation('Cart').t;
    return (React.createElement(VipSummary, { services: services, onServiceDelete: onServiceDelete, travellers: travellers, travellersById: getTravellersMap(travellers), flight: flight, serviceName: t('Priority boarding'), serviceSubType: GdsServiceVipSubType.PriorityBoarding, headerIcon: PriorityBoardingIcon }, function (children) { return children && React.createElement("span", null, children); }));
};
export default PriorityBoarding;
