import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import Traveller from '../../Traveller';
import Loader from '../../Loader';
import { useGetTravellersQuery, useDefaultTravellerFieldsQuery, useDeleteTravellerMutation } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { TravellerTypes } from '../../Traveller/types';
import { useTheme } from '../../theme';
import { useCreateTraveller, useSubmitPersonalDetails, useUpdateTraveller } from '../hooks';
export var renderType;
(function (renderType) {
    renderType[renderType["Passengers"] = 0] = "Passengers";
    renderType[renderType["Companions"] = 1] = "Companions";
})(renderType || (renderType = {}));
var TravelCompanions = function (_a) {
    var _b, _c, _d;
    var format = _a.format;
    var getTravellerFields = useDefaultTravellerFieldsQuery();
    var getCompanions = useGetTravellersQuery();
    var _e = __read(useDeleteTravellerMutation(), 1), deleteTravellerProfile = _e[0];
    var _f = __read(useState(false), 2), expanded = _f[0], setExpanded = _f[1];
    var t = useTranslation('Account').t;
    var theme = useTheme('TravelCompanions').TravelCompanions;
    var accountTheme = useTheme('AccountModule').Account;
    var submitPersonalDetails = useSubmitPersonalDetails().submit;
    var onSubmitPersonalDetails = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, submitPersonalDetails(values)];
                case 1:
                    result = _b.sent();
                    if ((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.UpdateUserProfile) {
                        getCompanions.refetch();
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
            }
        });
    }); };
    var createNewCompanion = useCreateTraveller().submit;
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createNewCompanion(values)];
                case 1:
                    res = _b.sent();
                    if (res && res.data.CreateTravellerProfile) {
                        getCompanions.refetch();
                        setTimeout(function () {
                            setExpanded(false);
                        }, 1000);
                        return [2 /*return*/, true];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/, false];
            }
        });
    }); };
    var updateTravellerSubmit = useUpdateTraveller().submit;
    var updateTraveller = function (values, id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateTravellerSubmit(values, id)];
                case 1:
                    res = _a.sent();
                    if (!res.data.UpdateTravellerProfile) return [3 /*break*/, 3];
                    return [4 /*yield*/, getCompanions.refetch()];
                case 2:
                    _a.sent();
                    return [2 /*return*/, true];
                case 3: return [2 /*return*/, false];
            }
        });
    }); };
    var deleteTraveller = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setExpanded(false);
                    return [4 /*yield*/, deleteTravellerProfile({
                            variables: {
                                id: id
                            }
                        })];
                case 1:
                    res = _a.sent();
                    if (res.data.DeleteTravellerProfile) {
                        getCompanions.refetch();
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null, !getCompanions.loading &&
        ((_c = (_b = getCompanions === null || getCompanions === void 0 ? void 0 : getCompanions.data) === null || _b === void 0 ? void 0 : _b.CurrentUser) === null || _c === void 0 ? void 0 : _c.travellerProfiles) &&
        !getTravellerFields.loading &&
        ((_d = getTravellerFields.data) === null || _d === void 0 ? void 0 : _d.DefaultTravellerProfile) ? (React.createElement("div", { className: theme.companions },
        format === renderType.Passengers && (React.createElement(Traveller, { onSubmit: onSubmitPersonalDetails, type: TravellerTypes.PersonalDetails, traveller: __assign(__assign({}, getCompanions.data.CurrentUser.userProfile), { canBeDeleted: false }), expanded: expanded === 0, handleChange: function () {
                if (expanded === 0) {
                    setExpanded(false);
                }
                else {
                    setExpanded(0);
                }
            } })),
        format === renderType.Companions && (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: accountTheme.route__title }, t('Travel companions')),
            React.createElement("div", { className: theme.description },
                React.createElement("p", null, t('Usually traveling with a friend or family?')),
                React.createElement("p", null, t('Add them to your companions list and make checking-in altogether easier.'))),
            React.createElement(Traveller, { onSubmit: onSubmit, handleChange: function () {
                    if (expanded === 1) {
                        setExpanded(false);
                    }
                    else {
                        setExpanded(1);
                    }
                }, traveller: getTravellerFields.data.DefaultTravellerProfile, expanded: expanded === 1, type: TravellerTypes.CompanionEmpty }),
            getCompanions.data.CurrentUser.travellerProfiles.map(function (traveller, index) { return (React.createElement(Traveller, { key: traveller.id, expanded: index + 2 === expanded, handleChange: function () {
                    if (index + 2 === expanded) {
                        setExpanded(false);
                    }
                    else {
                        setExpanded(index + 2);
                    }
                }, onSubmit: function (values) { return updateTraveller(values, traveller.id); }, onDelete: deleteTraveller, traveller: traveller, type: TravellerTypes.CompanionFilled })); }))))) : (React.createElement(Loader, null))));
};
export default TravelCompanions;
