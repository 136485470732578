import { isSameMonth, toDate, parseISO } from 'date-fns';
export var getProgressLevel = function (price, maxPrice) {
    if (maxPrice === 0) {
        return 0;
    }
    return Math.round((price / maxPrice) * 100);
};
export var safeAddDays = function (date, days) {
    var newDate = date instanceof Date ? toDate(date) : parseISO(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};
export var safeStartOfMonth = function (date) {
    var newDate = date instanceof Date ? toDate(date) : parseISO(date);
    newDate.setDate(1);
    return newDate;
};
export var getDaysFromMonth = function (initialDate) {
    var currentDate = safeStartOfMonth(initialDate);
    var dates = [];
    while (isSameMonth(initialDate, currentDate)) {
        dates.push(currentDate);
        currentDate = safeAddDays(currentDate, 1);
    }
    return dates;
};
