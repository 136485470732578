export var Sorting;
(function (Sorting) {
    Sorting[Sorting["BestFlight"] = 0] = "BestFlight";
    Sorting[Sorting["Departure"] = 1] = "Departure";
    Sorting[Sorting["Optimal"] = 2] = "Optimal";
    Sorting[Sorting["Price"] = 3] = "Price";
    Sorting[Sorting["FlightTime"] = 4] = "FlightTime";
    Sorting[Sorting["Arrival"] = 5] = "Arrival";
    Sorting[Sorting["Nonstop"] = 6] = "Nonstop";
    Sorting[Sorting["BusinessClass"] = 7] = "BusinessClass";
})(Sorting || (Sorting = {}));
