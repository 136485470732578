import { __assign } from "tslib";
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import cn from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '../theme';
var loaderIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "24", height: "24", fill: "white" }),
    React.createElement("circle", { opacity: "0.3", cx: "12", cy: "12", r: "9", stroke: "url(#paint0_angular)", strokeWidth: "2" }),
    React.createElement("defs", null,
        React.createElement("radialGradient", { id: "paint0_angular", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(12 12) rotate(90) scale(10)" },
            React.createElement("stop", { stopOpacity: "0" }),
            React.createElement("stop", { offset: "1" })))));
var Input = function (props) {
    var _a, _b, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l, _m;
    var theme = useTheme('Input').Input;
    var variant = props.variant || 'filled';
    return (React.createElement(TextField, __assign({ fullWidth: true, placeholder: props.placeholder, label: props.label, disabled: props.disabled, variant: variant, focused: props.focused, inputProps: {
            autoComplete: (_e = props.autoComplete) !== null && _e !== void 0 ? _e : 'one-time-code',
            autoCorrect: 'off',
            autoCapitalize: 'off',
            autoFocus: props.autoFocus,
            spellCheck: false,
            tabIndex: props.tabIndex ? props.tabIndex : null,
            className: theme.real_input,
            inputMode: props.inputMode
        }, FormHelperTextProps: {
            classes: {
                error: cn(theme.hint, (_a = {}, _a[theme.hint_error] = props.absoluteHintPosition, _a))
            }
        }, value: props.value, name: props.name }, props.TextFieldProps, { className: cn(theme.root, props.className, (_f = props === null || props === void 0 ? void 0 : props.TextFieldProps) === null || _f === void 0 ? void 0 : _f.className, (_b = {},
            _b[theme.valueFilled] = (_g = props.TextFieldProps) === null || _g === void 0 ? void 0 : _g.value,
            _b)), InputLabelProps: {
            classes: __assign({ root: cn(theme.textField__root, props.labelClassName, (_c = {},
                    _c[theme.textField_standard] = variant === 'standard',
                    _c)), focused: cn(theme.textField, theme.textField_focused, props.labelFocusedClassName), error: cn(theme.label_error, props.labelErrorClassName), shrink: cn(theme.label_shrink, props.labelShrinkClassName), outlined: theme.textField_outlined, filled: theme.textField_filled }, (_j = (_h = props.TextFieldProps) === null || _h === void 0 ? void 0 : _h.InputLabelProps) === null || _j === void 0 ? void 0 : _j.classes),
            shrink: props.shrink
        }, InputProps: __assign(__assign({ endAdornment: props.isLoading ? (React.createElement(InputAdornment, { position: "end", className: theme.loader }, loaderIcon)) : (props.endAdornment), inputComponent: props.inputComponent, inputRef: props.inputRef, disableUnderline: variant !== 'standard', inputMode: props.inputMode }, (_k = props === null || props === void 0 ? void 0 : props.TextFieldProps) === null || _k === void 0 ? void 0 : _k.InputProps), { classes: __assign({ disabled: theme.disabled, focused: cn(theme.input_focused, props.inputFocusedClassName), root: cn(theme.input, props.inputClassName, (_d = {},
                    _d[theme.input_filled] = variant === 'filled',
                    _d[theme.input_outline] = variant === 'outlined',
                    _d[theme.input_standard] = variant === 'standard',
                    _d)), underline: theme.underline, notchedOutline: variant === 'outlined' ? theme.input_outline : null, error: cn(theme.input_error, props.inputErrorClassName) }, (_m = (_l = props.TextFieldProps) === null || _l === void 0 ? void 0 : _l.InputProps) === null || _m === void 0 ? void 0 : _m.classes) }) })));
};
export default Input;
