export var hasSegmentBoardingPass = function (segment, segmentsFailedBoardingPasses) {
    return segment.checkinInfo.airportHasDcs &&
        segment.checkinInfo.hasBoardingPasses !== false &&
        !segmentsFailedBoardingPasses.has(segment.id);
};
export var isDifferentCurrenciesError = function (error) {
    var _a;
    var differentCurrenciesError = 'The check in order cannot be processed because one of the tickets is issued in a currency different from the currency of the pass tickets in the check order';
    return (error.message.includes(differentCurrenciesError) ||
        ((_a = error.debugMessage) === null || _a === void 0 ? void 0 : _a.includes(differentCurrenciesError)));
};
