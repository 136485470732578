import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useState } from 'react';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal/components/Modal';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { WarningRounded } from '../../../Icons';
import { useDeleteOrderMutation } from '@websky/graphql';
import { useToggleable } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../../index';
var DeleteOrderModal = function (_a) {
    var _b;
    var orderId = _a.orderId, orderLocator = _a.orderLocator, onDeleted = _a.onDeleted, renderTrigger = _a.renderTrigger;
    var t = useTranslation('OrderCard').t;
    var css = useTheme('OrderCard').DeleteOrderModal;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var _d = __read(useState(false), 2), isDeleting = _d[0], setIsDeleting = _d[1];
    var closeModal = function () {
        setIsDeleting(false);
        close();
    };
    var _e = __read(useDeleteOrderMutation(), 1), deleteOrderMethod = _e[0];
    var deleteOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsDeleting(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, deleteOrderMethod({
                            variables: {
                                params: orderId
                            }
                        })];
                case 2:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if ((_b = data === null || data === void 0 ? void 0 : data.DeleteOrder) === null || _b === void 0 ? void 0 : _b.result) {
                        setIsDeleting(false);
                        onDeleted();
                        close();
                        return [2 /*return*/, true];
                    }
                    else {
                        console.error(errors);
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    console.error("Probably orderId not found");
                    console.error(error_1);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ open: open })) !== null && _b !== void 0 ? _b : (React.createElement(BaseButton, { variant: "smallSecondary", className: css.delete_order, onClick: function (e) {
            e.preventDefault();
            open();
        } }, t('DeleteFromList'))),
        React.createElement(Modal, __assign({ maxWidth: "sm", open: isOpen, onClose: closeModal, closeClassName: css.closeIcon }, (isMobile ? { bottomDrawer: true } : {}), { classes: {
                paper: cn(css.paper)
            } }),
            React.createElement("div", { className: css.header },
                React.createElement("div", { className: css.header__icon }, WarningRounded),
                React.createElement("div", { className: css.header__text }, t('BookingRemoval'))),
            React.createElement("div", { className: css.content },
                React.createElement("div", { className: css.message__text }, t('BookingRemovalMessage', { orderLocator: orderLocator }))),
            React.createElement("div", { className: css.footer },
                React.createElement(BaseButton, { variant: "secondary", isLoading: isDeleting, className: css.button__confirm, onClick: deleteOrder }, t('Remove')),
                React.createElement(BaseButton, { variant: "action", className: css.button__cancel, disabled: isDeleting, onClick: closeModal }, t('Cancel'))))));
};
export default DeleteOrderModal;
