import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import cn from 'classnames';
import Field from './Field/Field';
import { useTheme } from '../../../../../theme';
import Button from '../../../../../Button/Button';
import { getOrder } from '../../../store/order/selectors';
import InfoLinksLabel from '../../../../../InfoLinksLabel';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
var CreateRequest = function (_a) {
    var className = _a.className, header = _a.header, description = _a.description, buttonText = _a.buttonText, send = _a.send, initialValues = _a.initialValues, isSending = _a.isSending, autoMode = _a.autoMode;
    var theme = useTheme('Exchange').CreateRequest;
    var t = useTranslation('Exchange').t;
    var order = useSelector(getOrder);
    var onSubmitHandler = function (values) {
        send(values).then(function (data) { });
    };
    var formValues = __assign({}, initialValues);
    // for correct validation of the phone number
    if (formValues.phone &&
        formValues.phone.startsWith('7') &&
        formValues.phone.replace(/\(|\)\s/, '').length === 11 &&
        parsePhoneNumberFromString(formValues.phone, 'RU')) {
        formValues.phone = "+".concat(formValues.phone);
    }
    if (!formValues.phone.startsWith('+')) {
        formValues.phone = "+".concat(formValues.phone);
    }
    return (React.createElement(Form, { onSubmit: onSubmitHandler, initialValues: formValues }, function (_a) {
        var handleSubmit = _a.handleSubmit;
        return (React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", { className: cn(theme.container, className) },
                React.createElement("div", { className: theme.header }, header !== null && header !== void 0 ? header : t('Create request'),
                    description && React.createElement("div", { className: theme.description }, description)),
                React.createElement("div", { className: cn(theme.row, theme.main) },
                    React.createElement(Field, { name: "name", type: "text", label: t('Your name'), className: theme.field }),
                    React.createElement(Field, { name: "email", type: "email", label: t('Email'), className: theme.field }),
                    React.createElement(Field, { name: "phone", type: "phone", label: t('Your phone'), className: theme.field, displayInitialValueAsLocalNumber: false })),
                React.createElement("div", { className: cn(theme.row) },
                    React.createElement(Field, { name: "comment", type: "comment", label: t('Your comment'), className: theme.field, required: !autoMode }))),
            React.createElement("div", { className: theme.controls },
                React.createElement(Field, { name: "acceptTerm", type: "checkbox", label: React.createElement(InfoLinksLabel, { className: theme.label, order: order, type: 'exchange' }) }),
                React.createElement(Button, { variant: "flat", className: theme.submit, type: "submit", isLoading: isSending, size: "large", "data-next-button": true }, buttonText !== null && buttonText !== void 0 ? buttonText : t('Create request')))));
    }));
};
export default CreateRequest;
