import { __assign, __read } from "tslib";
import { isBefore, parse } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../../../../utils';
var selectSegmentDateReducer = function (state, _a) {
    var _b, _c;
    var action = _a.payload;
    var newState = {};
    var previousSegment = state[Object.keys(state).length - 1];
    var previousSelectedDate = previousSegment ? parse(previousSegment.date, API_DATE_FORMAT, new Date()) : null;
    var isChangePreviousDateAction = isBefore(parse(action.segment.date, API_DATE_FORMAT, new Date()), previousSelectedDate);
    if (isChangePreviousDateAction && Object.entries(state).length === 1) {
        return __assign(__assign({}, state), (_b = {}, _b[0] = action.segment, _b));
    }
    if (action.legId === Object.keys(state).length && !isChangePreviousDateAction) {
        return __assign(__assign({}, state), (_c = {}, _c[action.legId] = action.segment, _c));
    }
    Object.entries(state).forEach(function (_a) {
        var _b = __read(_a, 2), segmentLegId = _b[0], segment = _b[1];
        if (Number(segmentLegId) < action.legId) {
            newState[segmentLegId] = segment;
        }
        if (Number(segmentLegId) === action.legId) {
            newState[segmentLegId] = action.segment;
        }
    });
    return newState;
};
var clearSegmentDateReducer = function (state, _a) {
    var action = _a.payload;
    var newState = {};
    if (Object.keys(state).length <= action.legId) {
        // do not delete dates for already selected flights
        return state;
    }
    var legsArr = Object.keys(state);
    legsArr.pop();
    legsArr.forEach(function (legIndex) {
        newState[legIndex] = __assign({}, state[legIndex]);
    });
    return newState;
};
var actualizeSegmentsDateReducer = function (state, _a) {
    var action = _a.payload;
    var newState = {};
    action.segmentsSearchParams.forEach(function (searchParam, segmentLegId) {
        newState[segmentLegId] = searchParam;
    });
    return newState;
};
export var selectedDatesReducer = function (state, action) {
    switch (action.type) {
        case 'select':
            return selectSegmentDateReducer(state, action);
        case 'clear':
            return clearSegmentDateReducer(state, action);
        case 'actialize':
            return actualizeSegmentsDateReducer(state, action);
        default:
            return state;
    }
};
