import * as React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import BaggagePassenger from '../../BaggagePassenger/BaggagePassenger';
import BaggageSegment from '../../BaggageSegment/BaggageSegment';
import { getAvailableSegmentIds } from '../../../store/selector';
var BySegment = function (props) {
    var _a;
    var commonTheme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    var availableSegmentIds = useSelector(getAvailableSegmentIds);
    return (React.createElement("div", { className: cn(commonTheme.body, (_a = {}, _a[commonTheme.withTabs] = !!props.baggageTab, _a)) }, props.segments.map(function (segment) {
        var isAvailable = availableSegmentIds.includes(segment.id);
        return (React.createElement(React.Fragment, { key: segment.id },
            React.createElement(BaggageSegment, { segment: segment, baggageByRfics: props.upgradeBaggages, baggageTab: props.baggageTab, mobileSelectedServiceId: props.mobileSelectedServiceId, isAvailable: isAvailable }),
            props.passengers.map(function (passenger, i) {
                var _a;
                var key = "".concat(segment.id, "_").concat(passenger.id);
                var label = props.passengerLabels[passenger.id];
                var number = i + 1;
                var includedSegment = (_a = props.upgradeBaggages[0][0].segmentIds) === null || _a === void 0 ? void 0 : _a.some(function (segments) {
                    return segments.includes(segment.id);
                });
                return (React.createElement(BaggagePassenger, { baggageByRfics: props.upgradeBaggages, name: label, number: number, key: key, includedSegment: includedSegment, segmentId: segment.id, mobileSelectedServiceId: props.mobileSelectedServiceId, isAvailable: isAvailable, passenger: passenger }));
            })));
    })));
};
export default BySegment;
