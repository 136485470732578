import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
var Disabilities = function (props) {
    var css = useTheme('PassengersDetails').PassengersDetails;
    var t = useTranslation('OrderCard').t;
    if (!props.disabilities.length) {
        return null;
    }
    return (React.createElement("div", { className: css.disabilities },
        React.createElement("div", { className: css.disabilities__header }, t('Additional information')),
        props.disabilities.map(function (disability) { return (React.createElement("div", { key: disability.id },
            React.createElement("span", null, disability.id),
            React.createElement("span", null, "\u00A0\u2014\u00A0"),
            React.createElement("span", null, disability.name))); })));
};
export default Disabilities;
