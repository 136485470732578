import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import RecaptchaPage from './RecaptchaPage';
import { SHOW_RECAPTCHA, initI18n } from './utils';
import { APOLLO_ERRORS_EVENT } from './apolloErrorLink';
import WarningModal from './WarningModal';
import { useTranslation } from 'react-i18next';
import { ApolloErrorType } from './types';
initI18n('GuardedApolloProvider');
export var GuardedApolloProvider = function (_a) {
    var children = _a.children, client = _a.client;
    var _b = __read(useState(false), 2), recaptchaIsVisible = _b[0], setRecaptchaIsVisible = _b[1];
    var _c = __read(useState(''), 2), apolloErrors = _c[0], setApolloErrors = _c[1];
    var _d = __read(useState(), 2), apolloErrorType = _d[0], setApolloErrorType = _d[1];
    var onRecaptchaEvent = useCallback(function () { return setRecaptchaIsVisible(true); }, []);
    var t = useTranslation('GuardedApolloProvider').t;
    var onApolloErrors = function (e) {
        setApolloErrors(e.detail.message);
        if (e.detail.type) {
            setApolloErrorType(e.detail.type);
        }
    };
    var onCloseWarningPopup = useCallback(function () {
        setApolloErrors('');
    }, []);
    useEffect(function () {
        document.addEventListener(SHOW_RECAPTCHA, onRecaptchaEvent);
        document.addEventListener(APOLLO_ERRORS_EVENT, onApolloErrors);
        return function () {
            document.removeEventListener(SHOW_RECAPTCHA, onRecaptchaEvent);
            document.removeEventListener(APOLLO_ERRORS_EVENT, onApolloErrors);
        };
    });
    return (React.createElement(ApolloProvider, { client: client },
        children,
        recaptchaIsVisible && createPortal(React.createElement(RecaptchaPage, null), document.body),
        React.createElement(WarningModal, { title: t('Ops...'), content: t('Unfortunately, something went wrong. Please try again or contact with us'), isOpen: !!apolloErrors, errorMessage: t(apolloErrors), buttonText: t('Close'), onButtonClick: onCloseWarningPopup, hideContentText: apolloErrorType === ApolloErrorType.PnrBlocked })));
};
