import * as React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useIsMobile } from '../../../../utils';
import { getBaggageTab } from '../../store/selector';
import PassengerBaggageControl from './PassengerBaggageControl/PassengerBaggageControl';
var BaggagePassenger = function (props) {
    var _a;
    var theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var baggageTab = useSelector(getBaggageTab);
    var isMobile = useIsMobile();
    var isUnavailable = props.isAvailable === false;
    var services = React.useMemo(function () {
        if (isMobile && !baggageTab) {
            return [props.baggageByRfics.find(function (service) { return service[0].rfics === props.mobileSelectedServiceId; })];
        }
        return props.baggageByRfics;
    }, [props.baggageByRfics, props.mobileSelectedServiceId, isMobile, baggageTab]);
    return (React.createElement("div", { className: cn(theme.root, (_a = {}, _a[theme.disabled] = isUnavailable, _a)) },
        React.createElement("div", { className: cn(theme.row) },
            React.createElement("div", { className: theme.name_wrapper },
                React.createElement("div", { className: theme.number }, props.number),
                React.createElement("div", { className: theme.name }, props.name)),
            React.createElement("div", { className: theme.baggages }, services.map(function (baggageServices, index) { return (React.createElement(PassengerBaggageControl, { key: index, baggageServices: baggageServices, passenger: props.passenger, segmentId: props.segmentId, isUnavailable: isUnavailable })); })))));
};
export default BaggagePassenger;
