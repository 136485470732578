import { __assign } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { useRetrieveBookingValidation } from '../../RetrieveBooking/hooks';
import { Chevron } from '../../Icons';
import Input from '../../Input';
import Button from '../../Button';
import TicketExample from '../../TicketExample';
import BookingPreview from '../../BookingPreview';
export var FormType;
(function (FormType) {
    FormType["Booking"] = "Booking";
    FormType["Checkin"] = "Checkin";
})(FormType || (FormType = {}));
var AddBooking = function (_a) {
    var className = _a.className, type = _a.type, isLoading = _a.isLoading, onClose = _a.onClose, onSubmit = _a.onSubmit, order = _a.order, onClearOrder = _a.onClearOrder;
    var t = useTranslation('Account').t;
    var css = useTheme('AddBooking').AddBooking;
    var isBookingFormType = type === FormType.Booking;
    var _b = useRetrieveBookingValidation(), lastNameValidation = _b.lastNameValidation, ticketNumberValidation = _b.ticketNumberValidation, validateByOrderNumber = _b.validateByOrderNumber, ticketNumberFormat = _b.ticketNumberFormat;
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.close, onClick: onClose }),
        React.createElement("div", { className: css.title }, type === FormType.Booking ? t('Add booking') : t('Online check-in')),
        React.createElement(Form, { onSubmit: onSubmit, render: function (_a) {
                var _b;
                var handleSubmit = _a.handleSubmit, values = _a.values;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.inputs },
                        React.createElement(Field, { name: "lastName", validate: function (value) {
                                if (!value) {
                                    return t('AddPassenger:Required field');
                                }
                                if (type === FormType.Checkin) {
                                    return lastNameValidation(value);
                                }
                            }, render: function (_a) {
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, __assign({}, input, { className: css.input, inputClassName: css.input_root, disabled: !!order || isLoading, TextFieldProps: {
                                        type: 'lastName',
                                        placeholder: t('Last name'),
                                        error: (meta.error || meta.submitError) && meta.touched,
                                        helperText: !!meta.error && meta.touched ? meta.error : '',
                                        onChange: function (event) {
                                            input.onChange(event);
                                        }
                                    } })));
                            } }),
                        React.createElement(Field, { name: "ticket", parse: ticketNumberFormat, validate: function (value) {
                                if (!value) {
                                    return t('AddPassenger:Required field');
                                }
                                if (type === FormType.Checkin) {
                                    return ticketNumberValidation(value);
                                }
                                if (type === FormType.Booking) {
                                    if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                        return undefined;
                                    }
                                    else {
                                        return ticketNumberValidation(value) || validateByOrderNumber(value);
                                    }
                                }
                            }, render: function (_a) {
                                var _b;
                                var input = _a.input, meta = _a.meta;
                                return (React.createElement(Input, __assign({}, input, { className: css.input, inputClassName: css.input_root, disabled: !!order || isLoading, TextFieldProps: {
                                        type: 'ticket',
                                        placeholder: t('Ticket or booking number'),
                                        error: (meta.error || meta.submitError) && meta.touched,
                                        helperText: (meta.error || meta.submitError) && meta.touched
                                            ? (_b = meta.submitError) !== null && _b !== void 0 ? _b : meta.error
                                            : '',
                                        onChange: function (event) {
                                            input.onChange(event);
                                        }
                                    } })));
                            } })),
                    isBookingFormType && (isLoading || order) ? (React.createElement(BookingPreview, { order: order, onClearOrder: onClearOrder, isLoading: isLoading, redirectViaHistory: false })) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css.spoilers },
                            React.createElement(Accordion, { classes: {
                                    root: css.spoiler,
                                    expanded: css.spoilerExpanded
                                } },
                                React.createElement(AccordionSummary, { classes: {
                                        root: css.spoilerHeader,
                                        content: css.spoilerHeaderContent,
                                        expanded: css.spoilerHeaderExpended
                                    } },
                                    Chevron,
                                    t('How to find a booking id?')),
                                React.createElement(AccordionDetails, { classes: {
                                        root: css.spoilerDetails
                                    } },
                                    React.createElement("p", null, t('Enter the passengers surname (in latin characters) and ticket number, order ID or booking ID. You can find these in the itinerary receipt sent to purchasers Email after booking.')),
                                    React.createElement(TicketExample, null)))),
                        React.createElement("div", { className: css.footer },
                            !isLoading && (React.createElement(Button, { className: cn(css.button, css.buttonCancel), onClick: onClose }, t('Cancel'))),
                            React.createElement(Button, { className: cn(css.button, css.buttonContinue, (_b = {},
                                    _b[css.buttonDisabled] = isLoading,
                                    _b)), type: 'submit', disabled: isLoading }, t('Continue')))))));
            } })));
};
export default AddBooking;
