import { __assign, __rest } from "tslib";
import React from 'react';
import { FormControlLabel, Checkbox, Select, MenuItem } from '@material-ui/core';
import cn from 'classnames';
import SegmentCollapse from '../../../AdditionalServiceWrapper/components/SegmentCollapse/SegmentCollapse';
import Money from '../../../Money';
import { useTheme } from '../../../theme';
var Control = function (_a) {
    var optionClassName = _a.optionClassName, _b = _a.type, type = _b === void 0 ? 'select' : _b, value = _a.value, options = _a.options, onChange = _a.onChange, onChangeCollapse = _a.onChangeCollapse, props = __rest(_a, ["optionClassName", "type", "value", "options", "onChange", "onChangeCollapse"]);
    var theme = useTheme('CheckoutAdditionalService').Control;
    var onChangeCheckbox = function (id) { return function (_, checked) {
        onChange(id, checked);
    }; };
    var onChangeSelect = function (e) {
        onChange(e.target.value);
    };
    if (type === 'checkbox') {
        return (React.createElement(SegmentCollapse, __assign({ onChange: onChangeCollapse }, props), options.map(function (option, index) { return (React.createElement("div", { key: index, className: cn(theme.checkbox, optionClassName) },
            React.createElement(FormControlLabel, { value: 'end', label: option.label, classes: {
                    label: theme.checkbox__label
                }, disabled: option.disabled || props.isDisabled, control: React.createElement(Checkbox, { classes: {
                        root: theme.checkbox__root,
                        checked: theme.checkbox__checked
                    }, disableRipple: true, checked: !!option.value, onChange: onChangeCheckbox(option.id) }) }),
            option.price && (React.createElement("div", { className: theme.checkbox__price },
                React.createElement(Money, { money: option.price }))))); })));
    }
    return (React.createElement(SegmentCollapse, __assign({ type: 'checkbox', onChange: onChangeCollapse }, props),
        React.createElement(Select, { className: cn(theme.select, optionClassName), classes: { root: theme.select_root }, onChange: onChangeSelect, disableUnderline: true, disabled: props.isDisabled, value: value !== null && value !== void 0 ? value : options[0].value }, options.map(function (option, index) { return (React.createElement(MenuItem, { value: option.value, key: index },
            React.createElement("span", null, option.label),
            option.price && (React.createElement(React.Fragment, null,
                "\u00A0-",
                React.createElement(Money, { money: option.price }))))); }))));
};
export default Control;
