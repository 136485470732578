import * as AdditionalServicesStyles from './components/AdditionalService.css';
import * as Insurance from './components/Insurance/Insurance.css';
import * as DownloadInsurances from './components/Insurance/DownloadInsurances/DownloadInsurances.css';
import * as Business from './components/Business/Business.css';
export default {
    AdditionalServicesStyles: AdditionalServicesStyles,
    Insurance: Insurance,
    DownloadInsurances: DownloadInsurances,
    Business: Business
};
