import { TravellerFieldEnum } from '@websky/graphql';
import { getInputType } from './Passengers';
import i18n from 'i18next';
import { AdditionalTravellerFieldEnum, InputType } from '../../../../../Passenger/types';
export var getAccompanyingFields = function (orderCustomer) {
    var customerFields = orderCustomer.values
        .filter(function (value) {
        return [TravellerFieldEnum.AccompanyingPersonName, TravellerFieldEnum.AccompanyingPersonTicketNumber].includes(value.type);
    })
        .map(function (_a) {
        var name = _a.name, type = _a.type, validationRules = _a.validationRules, value = _a.value, isEditable = _a.isEditable;
        return {
            name: name,
            readonly: !isEditable,
            type: type,
            input: getInputType(type),
            placeholder: i18n.t("Passenger:".concat(name)),
            validations: validationRules,
            defaultValue: value
        };
    });
    if (customerFields.length) {
        customerFields.push({
            name: "accompanyingCheck",
            readonly: false,
            type: AdditionalTravellerFieldEnum.AccompanyingCheck,
            input: InputType.Checkbox,
            placeholder: '',
            validations: [
                {
                    required: true,
                    with: null,
                    hidden: null,
                    label: null,
                    hint: null,
                    placeholder: null,
                    minDate: null,
                    maxDate: null,
                    maxLength: null,
                    regExp: null,
                    options: null
                }
            ],
            defaultValue: null
        });
    }
    return customerFields;
};
