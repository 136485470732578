import { validateDate } from '../../../../../Passenger/utils';
import { TravellerFieldEnum } from '@websky/graphql';
export var findSubsidyForPassenger = function (passengerValues, subsidies) {
    var _a;
    if (!subsidies || !subsidies.length) {
        return null;
    }
    return (_a = subsidies.find(function (subsidy) {
        return subsidy.validation.every(function (validation) {
            var value = passengerValues[validation.name];
            return validation.validationRules.some(function (rules) {
                var _a, _b;
                if (!((_a = rules.with) === null || _a === void 0 ? void 0 : _a.length) ||
                    rules.with.every(function (cond) { return checkValue(passengerValues, cond.name, cond.value); })) {
                    if (validation.type === TravellerFieldEnum.BirthDate) {
                        return typeof validateDate(value, rules) !== 'string';
                    }
                    if ((_b = rules.options) === null || _b === void 0 ? void 0 : _b.length) {
                        return rules.options.some(function (option) { return checkValue(passengerValues, validation.name, option.value); });
                    }
                }
                return false;
            });
        });
    })) === null || _a === void 0 ? void 0 : _a.id;
};
export var checkValue = function (allValues, name, value) {
    return allValues[name] === value;
};
