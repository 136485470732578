import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { vouchersList } from '../../mocked';
import { Voucher } from '../Voucher/Voucher';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import Tabs from '../../../Tabs/Tabs';
import LoyaltyInfo from '../../../LoyaltyInfo';
import MilesHistory from '../../../MilesHistory';
import { useFfpAccountHistoryQuery, useFfpAccountLogoutMutation } from '@websky/graphql';
import WarningModal from '../../../WarningModal';
import { parseDate } from '../../utils';
import WxPagination from '../../../WxPagination';
import { usePagination } from '../../../WxPagination/utils';
import { OverrideComponent } from '../../../renderProps';
initI18n('Loyalty');
var Loyalty = function (props) {
    var _a;
    var t = useTranslation('Loyalty').t;
    var css = useTheme('Loyalty').Loyalty;
    var _b = __read(useState(0), 2), selectedTab = _b[0], setSelectedTab = _b[1];
    var _c = __read(useState(null), 2), accountHistoryError = _c[0], setAccountHistoryError = _c[1];
    var _d = useFfpAccountHistoryQuery({
        skip: !props.ffpInfo,
        onError: function (error) { return setAccountHistoryError(error.message); }
    }), ffpAccountHistory = _d.data, refetch = _d.refetch;
    var _e = __read(useFfpAccountLogoutMutation(), 1), ffpAccountLogoutMutation = _e[0];
    var _f = usePagination(ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory, 3), pagesCount = _f.pagesCount, itemsPerPage = _f.itemsPerPage, paginationAvailable = _f.paginationAvailable, handleChangePage = _f.handleChangePage;
    var milesHistory = (_a = ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) {
        var firstDate = parseDate(a.onTimeUTC), secondDate = parseDate(b.onTimeUTC);
        return secondDate.getTime() - firstDate.getTime();
    });
    var onCloseAccountHistoryError = function () {
        setAccountHistoryError(null);
    };
    var onLoyaltyCardSaved = function () {
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, errors, result, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, refetch()];
                    case 1:
                        _a = _c.sent(), data = _a.data, errors = _a.errors;
                        return [4 /*yield*/, props.onLoyaltyCardSaved()];
                    case 2:
                        result = _c.sent();
                        if (errors.length) {
                            setAccountHistoryError(errors[0].message);
                        }
                        else {
                            resolve(!!(data === null || data === void 0 ? void 0 : data.FfpAccountHistory) && result);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        _b = _c.sent();
                        reject(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    var onLogoutFfpAccount = function () {
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var data, result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, ffpAccountLogoutMutation()];
                    case 1:
                        data = (_b.sent()).data;
                        return [4 /*yield*/, props.onLoyaltyCardSaved()];
                    case 2:
                        result = _b.sent();
                        resolve(!!(data === null || data === void 0 ? void 0 : data.FfpAccountLogout.result) && result);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        reject(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(LoyaltyInfo, { ffpInfo: props.ffpInfo, userInfo: props.userInfo, loyaltyName: props.loyaltyName, links: props.links, onLoyaltySaved: onLoyaltyCardSaved, onLogoutFfp: onLogoutFfpAccount, onGoToSettings: props.onGoToSettings, isLoading: props.isLoading }),
            (ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory) && (React.createElement(OverrideComponent, { componentProps: {
                    className: css.milesHistory,
                    records: milesHistory.slice(itemsPerPage.startValue, itemsPerPage.endValue)
                }, component: { AccountMilesHistory: MilesHistory } })),
            !!paginationAvailable && (React.createElement(WxPagination, { className: css.pagination, pages: pagesCount, onHandleChange: handleChangePage })),
            props.vouchers && (React.createElement("div", { className: cn(css.card, css.promo) },
                React.createElement("h1", { className: css.promo__title }, t('Promo-code')),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Tabs, { tabs: [
                            { header: t('Active'), value: 0 },
                            { header: t('Expired'), value: 1 }
                        ], onChange: setSelectedTab, selected: selectedTab }),
                    selectedTab === 0 && (React.createElement("div", { className: css.vouchers }, vouchersList
                        .filter(function (voucher) { return voucher.expires > new Date(); })
                        .map(function (voucher) { return (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })); }))),
                    selectedTab === 1 && (React.createElement("div", { className: css.vouchers }, vouchersList
                        .filter(function (voucher) { return voucher.expires <= new Date(); })
                        .map(function (voucher) { return (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })); })))),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("div", { className: css.vouchers },
                        vouchersList.map(function (voucher) { return (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })); }),
                        vouchersList.length % 3 === 0 ? null : React.createElement("div", { style: { width: 233, height: 1 } })))))),
        React.createElement(WarningModal, { isOpen: !!accountHistoryError, onClose: onCloseAccountHistoryError, onButtonClick: onCloseAccountHistoryError, errorMessage: accountHistoryError, title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), buttonText: t('Ok') })));
};
export default Loyalty;
