import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ExareReason, InfoMessageTypeEnum } from '@websky/graphql';
import { Form } from '../../../Checkout/components/Checkout/Form/Form';
import { NotIncluded } from '../../../Icons';
import { ThemeProvider, useTheme } from '../../../theme';
import { usePassengerForm } from './hooks';
import { getPassengerNames } from '../../../utils';
import Button from '../../../Button';
import Notification from '../Notification/Notification';
import SimpleLoader from '../../../SimpleLoader';
import WarningModal from '../../../WarningModal';
var PassengerForm = function (_a) {
    var passenger = _a.passenger, reason = _a.reason, onFillOrder = _a.onFillOrder, setReason = _a.setReason;
    var t = useTranslation('EditPassenger').t;
    var theme = useTheme('EditPassenger').PassengerForm;
    var _b = usePassengerForm(passenger, onFillOrder, reason), editablePassenger = _b.editablePassenger, requestError = _b.requestError, isLoading = _b.isLoading, isTravellerDataLoading = _b.isTravellerDataLoading, allowSwapNames = _b.allowSwapNames, onSubmit = _b.onSubmit, onClearError = _b.onClearError;
    var isDocumentReplacement = reason === ExareReason.DocumentReplacement;
    var onToggleChangeFiles = React.useCallback(function () {
        setReason(function (state) { return (!state ? ExareReason.DocumentReplacement : null); });
    }, [setReason]);
    var renderToolbar = React.useCallback(function (_a) {
        var _b, _c, _d;
        var valid = _a.valid, values = _a.values, dirtyFields = _a.dirtyFields, errors = _a.errors;
        var passengerValues = values.passengers[0];
        var editablePassengerNames = getPassengerNames(editablePassenger);
        var dirtyFieldsArray = Object.keys(dirtyFields !== null && dirtyFields !== void 0 ? dirtyFields : {}).filter(function (field) {
            var fieldPath = field.split('.');
            var fieldName = fieldPath[fieldPath.length - 1];
            if (fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'middleName') {
                return passengerValues[fieldName] !== editablePassengerNames[fieldName];
            }
            return !isDocumentReplacement || (isDocumentReplacement && !field.includes('changeFiles'));
        });
        var isDirty = dirtyFieldsArray.length > 0;
        var isValidAndDirty = valid && isDirty;
        var isDiffNames = editablePassengerNames.firstName !== passengerValues.firstName ||
            editablePassengerNames.lastName !== passengerValues.lastName;
        var hasChangeFiles = !isDocumentReplacement || (isDocumentReplacement && ((_c = (_b = passengerValues.files) === null || _b === void 0 ? void 0 : _b.changeFiles) === null || _c === void 0 ? void 0 : _c.length) > 0);
        var isDisabled = (!isValidAndDirty && !isDiffNames) || !hasChangeFiles || !!((_d = Object.keys(errors)) === null || _d === void 0 ? void 0 : _d.length);
        return (React.createElement("div", { className: theme.footer },
            React.createElement(Button, { className: theme.button, isLoading: isLoading, disabled: isDisabled, type: "submit" }, t('Change'))));
    }, [editablePassenger, isLoading, isDocumentReplacement]);
    var formTheme = {
        PassengerForm: {
            Header: {
                header: theme.header__container,
                filled: theme.header__container_filled,
                number: theme.header__number
            },
            Group: {
                container: theme.group__container,
                identity: cn(theme.group__container__icon, theme.group__container_identity),
                documents: cn(theme.group__container__icon, theme.group__container_documents)
            }
        }
    };
    if (isTravellerDataLoading) {
        return React.createElement(SimpleLoader, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.messages },
                React.createElement(Notification, { icon: NotIncluded, status: InfoMessageTypeEnum.Warning, type: "context", title: t('Typo correction available'), infoMessage: {
                        text: t('To replace the document or change the surname, it is necessary to attach supporting documents.'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'warning'
                    } }),
                React.createElement(Notification, { status: InfoMessageTypeEnum.Info, type: "context", title: t('Attention please!'), infoMessage: {
                        text: t('Due to changes in the data in your order, the air ticket is being exchanged, and the airline cannot guarantee confirmation of previously purchased additional services. All services issued on one electronic document will be canceled, even if you make changes to part of the route. Refunds will be made by the airline according to the terms of the offer.'),
                        type: InfoMessageTypeEnum.Info,
                        code: 'info'
                    } })),
            React.createElement(ThemeProvider, { value: formTheme },
                React.createElement(Form, { passengerClassName: theme.passenger, customer: null, currentStep: null, passengers: [editablePassenger], renderToolbar: renderToolbar, onFormSubmit: onSubmit, setNextStep: function () { }, isChangeFiles: isDocumentReplacement, onToggleChangeFiles: onToggleChangeFiles, showContacts: false, allowSwapNames: allowSwapNames, isChangePassengers: true }))),
        React.createElement(WarningModal, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, title: t('Oops...'), content: (requestError === null || requestError === void 0 ? void 0 : requestError.message) || t('Refund:Unknown error'), isOpen: !!requestError, onClose: onClearError })));
};
export default PassengerForm;
