import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { GdsServiceProductStatus } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { closeIcon } from '../../../Checkout/Icons';
import { FileDownload, Included, WarningRounded } from '../../../Icons';
import Money from '../../../Money/components/Money';
import { isUnconfirmedService } from '../../../utils';
import { omitRequestedService, pickRequestedService } from '../../utils';
var ServiceSummaryOption = function (_a) {
    var _b;
    var groupOption = _a.groupOption;
    var t = useTranslation('Cart').t;
    var css = useTheme('ServiceSummary').ServiceSummaryOption;
    var items = groupOption.items, direction = groupOption.direction, allSegments = groupOption.allSegments, headerIcon = groupOption.headerIcon, header = groupOption.header;
    var hasUnconfirmedServices = items.some(function (item) { return isUnconfirmedService(item.status); });
    var requestedServices = items.filter(pickRequestedService);
    var renderSegmentTitle = function () {
        if (allSegments) {
            return t('All flights');
        }
        else if (direction) {
            return "".concat(direction.departure.airport.iata, " - ").concat(direction.arrival.airport.iata);
        }
        else {
            return '';
        }
    };
    var renderItems = function (items) {
        return (React.createElement("div", { className: css.options }, items.map(function (item, index) {
            var _a;
            var isBooked = item.status === GdsServiceProductStatus.Booked;
            var isRequested = item.status === GdsServiceProductStatus.Requested;
            var showControls = item.downloadUrl || item.included;
            return (React.createElement("div", { className: cn(css.option, (_a = {},
                    _a[css.option_icon] = item.icon,
                    _a[css.option_booked] = isBooked,
                    _a[css.option_requested] = isRequested,
                    _a[css.option_rejected] = item.status === GdsServiceProductStatus.Rejected,
                    _a[css.option_canceled] = item.status === GdsServiceProductStatus.Canceled,
                    _a[css.option_problematic] = item.status === GdsServiceProductStatus.Problematic,
                    _a[css.option_included] = item.included,
                    _a)), key: index, "data-index": index },
                item.icon ? React.createElement("div", { className: css.option__icon }, item === null || item === void 0 ? void 0 : item.icon) : null,
                React.createElement("span", { className: css.option__name }, item === null || item === void 0 ? void 0 : item.name),
                item.description && React.createElement("span", { className: css.option__description }, item === null || item === void 0 ? void 0 : item.description),
                showControls && (React.createElement("div", { className: css.option__controls },
                    item.downloadUrl && (React.createElement("a", { className: css.option__download, href: item.downloadUrl, target: "_blank" }, FileDownload)),
                    item.included && React.createElement("div", { className: css.option__iconIncluded }, Included))),
                item.price && !showControls && (React.createElement("div", { className: css.option__price },
                    React.createElement("div", { className: css.option__price_money },
                        item.priceFrom && t('Checkout:from'),
                        React.createElement(Money, { moneyClassName: css.option__money, money: item.price }),
                        !!item.onDelete && !isRequested && !isBooked && (React.createElement("div", { className: css.option__price_icon, onClick: item.onDelete }, closeIcon)))))));
        })));
    };
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.unconfirmedServices] = hasUnconfirmedServices,
            _b)) },
        header && (React.createElement("div", { className: css.header },
            headerIcon,
            " ",
            React.createElement("span", { className: css.header__title }, header),
            React.createElement("span", { className: css.header__direction }, renderSegmentTitle()),
            hasUnconfirmedServices && React.createElement("div", { className: css.header__icon }, WarningRounded))),
        hasUnconfirmedServices && (React.createElement("div", { className: cn(css.notification, css.notification_unconfirmed), dangerouslySetInnerHTML: {
                __html: t('The service you have added cannot be activated. Try connecting later or contact the airline operator.')
            } })),
        renderItems(items.filter(omitRequestedService)),
        requestedServices.length > 0 && (React.createElement("div", { className: css.notification, dangerouslySetInnerHTML: {
                __html: t('The service is awaiting confirmation. Do not close or refresh the window.')
            } })),
        renderItems(requestedServices)));
};
export default ServiceSummaryOption;
