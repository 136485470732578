import * as React from 'react';
import { useTheme } from '../../theme';
import cn from 'classnames';
import ImageWithFallback from '../../ImageWithFallback';
var AircraftLivery = function (_a) {
    var _b;
    var aircraft = _a.aircraft, isReturnFlight = _a.isReturnFlight, className = _a.className, returnClassName = _a.returnClassName, onClick = _a.onClick, _c = _a.fallbackAircraft, fallbackAircraft = _c === void 0 ? 'https://cdn.websky.aero/content/default-content/images/Aircrafts/738/738.png' : _c;
    var css = useTheme('AircraftLivery').AircraftLivery;
    var aircraftIcon = isReturnFlight && (aircraft === null || aircraft === void 0 ? void 0 : aircraft.iconRt) ? aircraft.iconRt : aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon;
    var isReturnPlane = isReturnFlight && !(aircraft === null || aircraft === void 0 ? void 0 : aircraft.iconRt);
    return (React.createElement("div", { className: cn(css.plane, className, (_b = {},
            _b[css.plane_return] = isReturnPlane,
            _b[returnClassName] = returnClassName && isReturnPlane,
            _b)), onClick: onClick },
        React.createElement(ImageWithFallback, { src: aircraftIcon, fallbackSrc: fallbackAircraft, alt: aircraft === null || aircraft === void 0 ? void 0 : aircraft.name })));
};
export default AircraftLivery;
