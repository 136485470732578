import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import Button from '../../Button';
import Input from '../../Input';
import { emailRegex } from '../../utils';
import { ResendTicketsStages } from '../types';
var ResendTicketsForm = function (_a) {
    var className = _a.className, defaultEmail = _a.defaultEmail, status = _a.status, onCancel = _a.onCancel, onSubmit = _a.onSubmit;
    var css = useTheme('ResendTicketsForm').ResendTicketsForm;
    var t = useTranslation('RegisteredPassenger').t;
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.title }, t('Send all documents to email')),
        React.createElement(Form, { onSubmit: onSubmit, initialValues: { email: defaultEmail }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Field, { name: 'email', validate: function (value) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (!emailRegex.test(value)) {
                                return t('Please enter a valid email');
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(Input, __assign({}, input, { label: t('Email'), TextFieldProps: {
                                    error: !!meta.error && meta.touched,
                                    helperText: !!meta.error && meta.touched ? meta.error : '',
                                    onChange: function (event) {
                                        input.onChange(event);
                                    }
                                } })));
                        } }),
                    React.createElement("div", { className: css.footer },
                        status === ResendTicketsStages.Default && (React.createElement(Button, { className: css.cancel, onClick: onCancel }, t('Cancel'))),
                        React.createElement(Button, { type: 'submit', isLoading: status === ResendTicketsStages.Loading, isSuccess: status === ResendTicketsStages.Success }, t('Send')))));
            } })));
};
export default ResendTicketsForm;
