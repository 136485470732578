import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import { Button } from '../index';
var SuggestionModalContent = function (_a) {
    var _b;
    var onAgree = _a.onAgree, onDisagree = _a.onDisagree, icon = _a.icon, text = _a.text, isLoading = _a.isLoading, agreeButtonText = _a.agreeButtonText, disagreeButtonText = _a.disagreeButtonText, insideSlideBottom = _a.insideSlideBottom;
    var theme = useTheme('SuggestionModalContent').SuggestionModalContent;
    return (React.createElement("div", { className: cn(theme.modal__content, (_b = {}, _b[theme.modal__content_insideSlideBottom] = insideSlideBottom, _b)) },
        React.createElement("div", null, icon),
        React.createElement("div", { className: theme.modal__text }, text),
        React.createElement(Button, { className: theme.modal__button, isLoading: isLoading, onClick: onAgree }, agreeButtonText),
        disagreeButtonText && onDisagree && (React.createElement(Button, { className: cn(theme.modal__button, theme.modal__button_decline), onClick: onDisagree }, disagreeButtonText))));
};
export default SuggestionModalContent;
