import { createTheme } from '../utils';
import * as TravellerForm from './components/TravellerForm/TravellerForm.css';
import * as Preferences from './components/Preferences/Preferences.css';
import * as SectionWrapper from './components/SectionWrapper/SectionWrapper.css';
import * as DocumentsSection from './components/DocumentsSection/DocumentsSection.css';
import * as DocumentFieldsItem from './components/DocumentsSection/DocumentFieldsItem/DocumentFieldsItem.css';
import * as PersonalInfoSection from './components/PersonalInfoSection/PersonalInfoSection.css';
import * as ContactsSection from './components/ContactsSection/ContactsSection.css';
export var defaultTheme = {
    TravellerForm: TravellerForm,
    Preferences: Preferences,
    SectionWrapper: SectionWrapper,
    DocumentsSection: DocumentsSection,
    DocumentFieldsItem: DocumentFieldsItem,
    PersonalInfoSection: PersonalInfoSection,
    ContactsSection: ContactsSection
};
export default defaultTheme;
export var useTheme = createTheme(defaultTheme).useTheme;
