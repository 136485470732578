import { getTravellers } from '../Checkout/store/order/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
export var useIsCanModifyServices = function (services) {
    var travellers = useSelector(getTravellers);
    return useMemo(function () {
        return services.some(function (service) {
            var canBeRemoved = service.allowedPassengers.some(function (ids) {
                return ids.some(function (passengerId) {
                    var _a, _b, _c, _d, _e;
                    var passengerServices = (_a = travellers.find(function (traveller) { return traveller.id === passengerId; })) === null || _a === void 0 ? void 0 : _a.services;
                    if (passengerServices) {
                        var gdsService = (_c = (_b = passengerServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (srv) { return srv.serviceId === service.id; });
                        if (gdsService) {
                            return gdsService.count > gdsService.confirmedCount;
                        }
                        var brandIncludedService = (_e = (_d = passengerServices.brandIncludedServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(function (srv) { return srv.serviceId === service.id; });
                        if (brandIncludedService) {
                            return gdsService.count > gdsService.confirmedCount;
                        }
                    }
                    return false;
                });
            });
            return canBeRemoved || service.canBeAdded;
        });
    }, [services, travellers]);
};
