import { __assign } from "tslib";
export var scheduleFlightAdapter = function (flight, date) {
    if (date === void 0) { date = null; }
    return ({
        segments: flight.segments.map(function (_a) {
            var segment = _a.segment, transferDuration = _a.transferDuration;
            return ({
                segment: {
                    id: segment.id,
                    flightNumber: segment.flightNumber,
                    flightInfo: segment.flightInfo,
                    operatingAirline: segment.operatingAirline,
                    marketingAirline: segment.marketingAirline,
                    duration: segment.duration,
                    departure: __assign(__assign({}, segment.departure), { date: date }),
                    arrival: __assign(__assign({}, segment.arrival), { date: date }),
                    aircraft: segment.aircraft,
                    stops: segment.stops,
                    messages: []
                },
                transferDuration: transferDuration
            });
        }),
        pricesForFareGroups: []
    });
};
