import * as React from 'react';
import cn from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '../../../theme';
var Value = function (_a) {
    var className = _a.className, location = _a.location, value = _a.value, placeholder = _a.placeholder, isOpen = _a.isOpen, isLoading = _a.isLoading;
    var theme = useTheme('ScheduleForm').Value;
    if (isOpen) {
        return (React.createElement("div", { className: cn(className, theme.container) },
            !value && React.createElement("span", { className: theme.placeholder }, placeholder),
            isLoading && (React.createElement(CircularProgress, { classes: {
                    root: theme.loader,
                    colorPrimary: theme.colorPrimary
                }, size: 18 }))));
    }
    return (React.createElement("div", { className: cn(className, theme.container) }, location && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.name }, location.name),
        React.createElement("div", { className: theme.iata }, location.iata)))));
};
export default Value;
