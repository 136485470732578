import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { WarningRounded } from '../../../Icons';
import { useAeroexpressState } from './hooks';
import Disclaimer from '../../../Disclaimer/Disclaimer';
import AdditionalServiceWrapper from '../../../AdditionalServiceWrapper';
import AeroexpressSegment from '../AeroexpressSegment/AeroexpressSegment';
var AeroexpressPopup = function (_a) {
    var trips = _a.trips, selectedTrips = _a.selectedTrips, isLoading = _a.isLoading, onClose = _a.onClose, onSubmit = _a.onSubmit;
    var css = useTheme('CheckoutAdditionalService').AeroexpressPopup;
    var t = useTranslation('Checkout').t;
    var _b = useAeroexpressState(trips, selectedTrips), state = _b.state, tripsByDirection = _b.tripsByDirection, selectedTripItems = _b.selectedTripItems, totalPrice = _b.totalPrice, allPassengers = _b.allPassengers, setTrip = _b.setTrip, clearTrips = _b.clearTrips
    // toggleAllPassengers
    ;
    var onSubmitHandler = function () {
        var selectedTripsIds = selectedTrips.map(function (trip) { return trip.id; });
        var selectedTripsItemIds = selectedTripItems.map(function (trip) { return trip.id; });
        if (JSON.stringify(selectedTripsIds.sort()) !== JSON.stringify(selectedTripsItemIds.sort())) {
            onSubmit(selectedTripsItemIds);
        }
        else {
            onClose();
        }
    };
    var onClear = function () {
        clearTrips();
        if (selectedTrips.length) {
            onSubmit([]);
        }
    };
    return (React.createElement(AdditionalServiceWrapper, { headerClassName: css.header, textClassName: css.text, contentClassName: css.content, footerClassName: css.footer, header: t('Aeroexpress'), text: React.createElement(React.Fragment, null,
            React.createElement("p", null, t('No worries about traffic jams, arrival at the airport strictly on schedule.')),
            React.createElement("p", null, t('Aeroexpress tickets are valid for 30 days.')),
            React.createElement("div", { className: css.info },
                React.createElement("div", { className: css.disclaimer },
                    React.createElement("a", { href: "https://aeroexpress.ru", target: "_blank" },
                        React.createElement(Disclaimer, { className: css.content_disclaimer, icon: WarningRounded }, t('Aeroexpress:View schedule')))))), totalPrice: totalPrice, isLoading: isLoading, onConfirm: onSubmitHandler, onClear: onClear, onCancel: onClose },
        React.createElement("div", { className: css.segments }, tripsByDirection.map(function (_a) {
            var _b = __read(_a, 2), direction = _b[0], trips = _b[1];
            return (React.createElement(AeroexpressSegment, { key: direction, state: state, direction: direction, trips: trips, allPassengers: allPassengers, onChange: setTrip }));
        }))));
};
export default AeroexpressPopup;
