import { __awaiter, __generator, __read, __rest } from "tslib";
import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';
import Login from './Login/Login';
import CreateAccount from './CreateAccount/CreateAccount';
import { useResponseErrorParser } from './hooks';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { OverrideComponent } from '../../../../renderProps';
import { LoginType, useCreateFfpAccountMutation, useFfpDefaultAccountQuery, useGetAccountInfoQuery } from '@websky/graphql';
import { useTheme } from '../../../../theme';
import { ApolloError } from 'apollo-boost';
import ModalConfirm from '../../../../ModalConfirm';
import Link from '../../../../Link';
import { Hello } from '../../../../Icons';
export var ConnectType;
(function (ConnectType) {
    ConnectType["Login"] = "Login";
    ConnectType["Reset"] = "Reset";
    ConnectType["Create"] = "Create";
})(ConnectType || (ConnectType = {}));
var LoyaltyConnect = function (_a) {
    var _b, _c;
    var _d = _a.type, connectType = _d === void 0 ? ConnectType.Login : _d, isOpen = _a.isOpen, onClose = _a.onClose, onDataLoading = _a.onDataLoading, props = __rest(_a, ["type", "isOpen", "onClose", "onDataLoading"]);
    var t = useTranslation('Loyalty').t;
    var css = useTheme('Loyalty').LoyaltyConnect;
    var _e = __read(React.useState(connectType), 2), type = _e[0], typeChange = _e[1];
    var newAccFormRef = React.useRef(null);
    React.useEffect(function () {
        typeChange(isOpen ? connectType : null);
    }, [connectType, isOpen]);
    var parseResponse = useResponseErrorParser().parseResponse;
    var _f = __read(React.useState(null), 2), error = _f[0], setError = _f[1];
    var _g = __read(React.useState(false), 2), createAccountSuccess = _g[0], setCreateAccountSuccess = _g[1];
    var _h = __read(React.useState(false), 2), createAccountLoading = _h[0], setCreateAccountLoading = _h[1];
    var _j = useFfpDefaultAccountQuery(), data = _j.data, isDefaultDataLoading = _j.loading;
    var _k = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache',
        skip: type !== ConnectType.Create
    }), accountInfo = _k.data, accountInfoLoading = _k.loading;
    React.useEffect(function () {
        onDataLoading === null || onDataLoading === void 0 ? void 0 : onDataLoading(accountInfoLoading);
    }, [accountInfoLoading]);
    var _l = __read(useCreateFfpAccountMutation(), 1), createAccountMutation = _l[0];
    var onError = function (message) {
        var _a;
        var parseMessage = parseResponse(message);
        setError(parseMessage);
        return _a = {}, _a[FORM_ERROR] = parseMessage, _a;
    };
    var createAccountRequest = function (newFfpAccountData) { return __awaiter(void 0, void 0, Promise, function () {
        var data_1, err_1, error_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setCreateAccountLoading(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, createAccountMutation({
                            variables: {
                                params: newFfpAccountData
                            }
                        })];
                case 2:
                    data_1 = (_d.sent()).data;
                    if (!((_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.CreateFfpAccount) === null || _a === void 0 ? void 0 : _a.result)) {
                        // TODO: HACK fix it
                        if (data_1.CreateFfpAccount.message === 'Сообщение программы: invalid FFP LK system') {
                            setCreateAccountSuccess(data_1.CreateFfpAccount.result);
                            setTimeout(function () { return typeChange(ConnectType.Login); });
                            return [2 /*return*/, undefined];
                        }
                        return [2 /*return*/, onError(data_1.CreateFfpAccount.message)];
                    }
                    else {
                        setTimeout(function () { return typeChange(ConnectType.Login); });
                        return [2 /*return*/, undefined];
                    }
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _d.sent();
                    error_1 = 'Unknown error';
                    if (err_1 instanceof Error && err_1.message) {
                        error_1 = err_1.message;
                    }
                    else if (err_1 instanceof ApolloError && ((_c = (_b = err_1.graphQLErrors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.message)) {
                        if (err_1.graphQLErrors.some(function (gqlError) {
                            return gqlError.message.includes('(0) Неверные исходные данные:') &&
                                gqlError.message.includes('уже используется!');
                        })) {
                            error_1 = t('The specified email is already in use, please log in to your personal account using the data you provided during registration or restore your card number/password');
                        }
                        else {
                            error_1 = err_1.graphQLErrors[0].message;
                        }
                    }
                    setCreateAccountSuccess(false);
                    return [2 /*return*/, onError(error_1)];
                case 4:
                    setCreateAccountLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var toCreateAccount = function () {
        if (props.showRegisterAction) {
            typeChange(ConnectType.Create);
        }
    };
    var toResetPassword = function () {
        typeChange(ConnectType.Reset);
    };
    var closeErrorHandler = function () { return setError(null); };
    var hasConfirmedEmail = (_c = (_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.some(function (method) { return method.loginType === LoginType.Email && method.confirmed; });
    var _m = __read(React.useState(false), 2), isCreateAccountButtonDisabled = _m[0], setIsCreateAccountButtonDisabled = _m[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalConfirm, { classes: { root: css.warning__modal }, title: t('Oops...'), size: "medium", isOpen: isOpen &&
                !accountInfoLoading &&
                !isDefaultDataLoading &&
                !hasConfirmedEmail &&
                type === ConnectType.Create, onClose: onClose, onCancel: onClose, confirmBtn: t('Fill email'), onConfirm: props.onGoToSettings }, t('To continue registering in the loyalty system, fill out and confirm your email in your personal account.')),
        React.createElement(ModalConfirm, { size: "medium", onClose: onClose, isOpen: isOpen && type === ConnectType.Create && hasConfirmedEmail, isLoading: createAccountLoading, title: React.createElement("div", { className: css.createTitle },
                React.createElement("div", null, Hello),
                React.createElement("div", null, t('Create account'))), confirmBtn: t('Create'), disableConfirmBtn: isCreateAccountButtonDisabled, buttonsExtra: React.createElement(React.Fragment, null,
                React.createElement(Link, { action: function () { return typeChange(ConnectType.Login); } }, t('I have a card'))), onConfirm: function () {
                var _a;
                (_a = newAccFormRef.current) === null || _a === void 0 ? void 0 : _a.submit();
            }, scroll: "body" },
            React.createElement(OverrideComponent, { component: {
                    CreateFfpAccountForm: CreateAccount
                }, componentProps: {
                    initialValues: data,
                    onCreateAccount: createAccountRequest,
                    success: createAccountSuccess,
                    toLogin: function () { return typeChange(ConnectType.Login); },
                    error: error,
                    closeError: closeErrorHandler,
                    formRef: newAccFormRef,
                    isOpen: isOpen && type === ConnectType.Create && hasConfirmedEmail,
                    onErrorsChanges: function (hasErrors) {
                        setIsCreateAccountButtonDisabled(hasErrors);
                    }
                } })),
        React.createElement(ModalConfirm, { size: "medium", onClose: onClose, isOpen: isOpen && type === ConnectType.Login, hideFooter: true, hideTitle: true },
            React.createElement(Login, { onLoyaltyCardSaved: props.onLoyaltyCardSaved, toCreateAccount: toCreateAccount, toResetPassword: toResetPassword, showRegisterAction: props.showRegisterAction, queryType: props.authType })),
        React.createElement(ModalConfirm, { size: "medium", onClose: onClose, isOpen: isOpen && type === ConnectType.Reset, hideFooter: true, hideTitle: true },
            React.createElement(ForgotPassword, { toLogin: function () { return typeChange(ConnectType.Login); }, email: props.userInfo.email }))));
};
export default LoyaltyConnect;
