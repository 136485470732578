import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var FfpInfoStatuses = function (props) {
    var _a;
    var css = useTheme('LoyaltyInfo').FfpInfo;
    var t = useTranslation('Loyalty').t;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.progress_status },
            React.createElement(LinearProgress, { classes: {
                    root: css.progress,
                    colorPrimary: css.progress_bg,
                    barColorPrimary: css.progress_color
                }, variant: 'determinate', value: props.milesPercentage }),
            React.createElement("span", null, t('Left {{miles}} for {{level}} status', {
                miles: props.ffpInfo.milesForNextLevel.toLocaleString(),
                level: t(props.nextLevel)
            }))),
        React.createElement("div", { className: css.progress_fares },
            React.createElement(LinearProgress, { classes: {
                    root: css.progress,
                    colorPrimary: css.progress_bg,
                    barColorPrimary: css.progress_color
                }, variant: 'determinate', value: props.faresPercentage }),
            React.createElement("span", null,
                t('or'),
                " ",
                ((_a = props.ffpInfo) === null || _a === void 0 ? void 0 : _a.segmentsForNextLevel) > 1 ? props.ffpInfo.segmentsForNextLevel : 1,
                ' ',
                t('out of'),
                " ",
                props.ffpInfo.currentLevelMaxSegments,
                " ",
                t('flights')))));
};
export default FfpInfoStatuses;
