var _this = this;
import { __read } from "tslib";
import * as React from 'react';
import { useConfig } from '../context';
export var getVarioqubCounterScript = function () {
    var varioqubId = useConfig().global.varioqubId;
    if (!varioqubId) {
        return false;
    }
    var id = varioqubId; // default Varioqub or AC Varioqub
    return "(function(e, x, pe, r, i, me, nt){\n\t\te[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},\n\t\tme=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)})\n\t\t(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');\n\t\tymab('metrika.".concat(id, "', 'init',  function (resp) {\n\t\t\twindow.dispatchEvent( new CustomEvent(\"onVarioqubData\",{detail:{resp:resp}} ) );\n\t\t});");
};
export var useVarioqub = function () {
    var varioqubId = useConfig().global.varioqubId;
    if (!varioqubId) {
        return null;
    }
    var _a = __read(React.useState(null), 2), varioqub = _a[0], setVarioqub = _a[1];
    window.addEventListener('onVarioqubData', function (e) {
        var _a, _b;
        var flags = (_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.resp) === null || _b === void 0 ? void 0 : _b.flags;
        var flagsMap = new Map(Object.entries(flags).map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], _c = _b[1], value = _c === void 0 ? [] : _c;
            if (key === 'SmartBox') {
                return [key, value[0] === 'True'];
            }
            else {
                return [key, value];
            }
        }));
        setVarioqub(flagsMap);
        window.removeEventListener('onVarioqubData', _this);
    });
    return varioqub;
};
export var VarioqubContext = React.createContext(null);
