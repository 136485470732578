import React, { useEffect, useRef } from 'react';
export var Chat = function () {
    var chatContainer = useRef();
    useEffect(function () {
        if (chatContainer.current) {
            document.dispatchEvent(new CustomEvent('paxChatPlacement', {
                detail: {
                    chatPlacementElement: chatContainer.current
                }
            }));
        }
        return function () {
            document.dispatchEvent(new CustomEvent('paxChatPlacement', {
                detail: undefined
            }));
        };
    }, [chatContainer]);
    return React.createElement("div", { ref: chatContainer });
};
