import classnames from 'classnames';
import * as React from 'react';
import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { addSegment, removeSegment } from '../../../store/segments/actions';
import { canAddSegment, canRemoveSegment, isMultiCity, getSegments } from '../../../store/segments/selectors';
import Segment from './Segment/Segment';
import Actions from './Actions/Actions';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { useForm } from 'react-final-form';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
var Segments = memo(function (_a) {
    var _b;
    var segments = _a.segments, isMultiCity = _a.isMultiCity, canAddSegment = _a.canAddSegment, canRemoveSegment = _a.canRemoveSegment, addSegment = _a.addSegment, removeSegment = _a.removeSegment;
    var css = useTheme('SearchForm').Segments;
    var pauseValidation = useForm().pauseValidation;
    var showAddSegment = isMultiCity && canAddSegment;
    var showRemoveSegment = canRemoveSegment;
    var showActionsBlock = showAddSegment || showRemoveSegment;
    var alternative = useConfig().SearchForm.mobileFormType !== 'common';
    var proMode = useConfig().SearchForm.proMode;
    var removeLastSegment = useCallback(function () { return removeSegment(segments.length - 1); }, [segments]);
    var onAddSegmentHandler = function () {
        addSegment();
        pauseValidation();
    };
    return (React.createElement("div", { className: classnames(css.segments, (_b = {},
            _b[css.segments_multiCity] = isMultiCity,
            _b[css.alternative] = alternative,
            _b)) },
        React.createElement("div", null, segments.map(function (segment, index) { return (React.createElement(Segment, { key: index, segmentId: index })); })),
        !proMode && showActionsBlock && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Actions, { isAlternativeView: alternative, canRemoveSegment: canRemoveSegment, onAddSegment: onAddSegmentHandler, removeLastSegment: removeLastSegment, showAddSegment: showAddSegment, showRemoveSegment: showRemoveSegment })))));
}, function (prevProps, nextProps) {
    return prevProps.isMultiCity === nextProps.isMultiCity && prevProps.segments.length === nextProps.segments.length;
});
var mapStateToProps = function (state) {
    return {
        isMultiCity: isMultiCity(state),
        canAddSegment: canAddSegment(state),
        canRemoveSegment: canRemoveSegment(state),
        segments: getSegments(state)
    };
};
var mapDispatchToProps = {
    addSegment: addSegment,
    removeSegment: removeSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Segments);
