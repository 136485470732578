import * as React from 'react';
import cn from 'classnames';
import Wings from './Wings/Wings';
import { useTheme } from '../../../theme';
import Row from './Row/Row';
import { getRowsWithDifferentClasses } from './utils';
import { useContext } from 'react';
import { SeatsRecommendContext } from '../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import SeatMapError from './SeatMapError/SeatMapError';
import { useConfig } from '../../../context';
var SeatMap = React.memo(function (props) {
    var _a, _b, _c;
    var theme = useTheme('SeatMap').SeatMapStyles;
    var splitByCabin = useConfig().global.seatMap.splitByCabin;
    var seatMapRef = React.useRef(null);
    var rows = props.segment.decks[0].rows;
    var isSeatMapTooWide = rows[0] && rows[0].seats && rows[0].seats.length >= 10;
    var isSeatMapSmall = rows.length <= 20 && props.passengers.length > 2;
    var seatRecommendContext = useContext(SeatsRecommendContext);
    var rowsWithDifferentClasses = React.useMemo(function () { return getRowsWithDifferentClasses(rows, splitByCabin, props.isNewServicePredicate); }, [rows, props.isNewServicePredicate]);
    return (React.createElement("div", { className: cn(theme.container, (_a = {}, _a[theme.container_small] = isSeatMapSmall, _a)), ref: props.seatMapRef },
        React.createElement(SeatMapError, null),
        seatRecommendContext.isOpen && React.createElement("div", { className: theme.map__overlay }),
        React.createElement("div", { className: cn(theme.seatMap__wrapper, (_b = {}, _b[theme.seatMap__wrapper_withoutWings] = false, _b)), ref: seatMapRef },
            React.createElement("div", { className: cn(theme.seatMap, (_c = {},
                    _c[theme.seatMap_wide] = isSeatMapTooWide,
                    _c)) },
                React.createElement(Wings, null, rows.map(function (row, rowIndex) {
                    var isNewServiceClass = rowsWithDifferentClasses.has(row.number);
                    return (React.createElement(Row, { allSeatServices: props.allSeatServices, services: props.services, key: row.number, isSeatMapWide: isSeatMapTooWide, onSelect: props.onSelect, passengers: props.passengers, nextRow: rows[rowIndex + 1], passengerId: props.passengerId, segmentId: props.segment.segmentInfo.id, lastRow: rows.length - 1, rowIndex: rowIndex, row: row, newClassType: isNewServiceClass, onClear: props.onClear, rows: rows, activeDoubleSeats: props.activeDoubleSeats, rowsWithDifferentClasses: rowsWithDifferentClasses }));
                }))))));
}, function (prevProps, nextProps) { return !nextProps.isActive; });
export default SeatMap;
