import { combineReducers } from 'redux';
import { orderReducer } from './order/reducers';
import { passengersReducer } from './passengers/reducers';
import { flightsToExchangeReducer } from './flightsToExchange/reducers';
import { exchangeReducer } from './exchangeType/reducers';
import { newFlightsReducer } from './newFlights/reducers';
import { fareFamilyReducer } from './fareFamily/reducers';
import { setReasonReducer } from './reason/reducer';
export var rootReducer = combineReducers({
    order: orderReducer,
    passengers: passengersReducer,
    flightsToExchange: flightsToExchangeReducer,
    exchangeType: exchangeReducer,
    newFlights: newFlightsReducer,
    fareFamily: fareFamilyReducer,
    reason: setReasonReducer
});
