import { __read, __values } from "tslib";
import * as React from 'react';
import { useFormState } from 'react-final-form';
import { validate } from '../../../../../../Passenger/utils';
import { getInputType } from '../Passengers';
import { findSubsidyForPassenger } from '../../SubsidyRequest/utils';
import SubsidyRejectPopup from '../../../../../../SubsidyRejectPopup/SubsidyRejectPopup';
import { useTranslation } from 'react-i18next';
export var SubsidyState;
(function (SubsidyState) {
    SubsidyState[SubsidyState["None"] = 0] = "None";
    SubsidyState[SubsidyState["Checking"] = 1] = "Checking";
    SubsidyState[SubsidyState["Confirmed"] = 2] = "Confirmed";
    SubsidyState[SubsidyState["NoAvailable"] = 3] = "NoAvailable";
})(SubsidyState || (SubsidyState = {}));
var PassengerSubsidy = function (_a) {
    var _b, _c;
    var passengerId = _a.passengerId, subsidyInfo = _a.subsidyInfo, fields = _a.fields, children = _a.children, orderSubsidyState = _a.orderSubsidyState, backToResults = _a.backToResults;
    var formState = useFormState(), passengerValues = (_b = formState.values['passengers']) === null || _b === void 0 ? void 0 : _b[parseInt(passengerId)];
    var t = useTranslation('PassengerSubsidy').t;
    var _d = __read(React.useState(false), 2), errorPopupOpen = _d[0], setErrorPopupOpen = _d[1];
    var _e = __read(React.useState(), 2), subsidyResult = _e[0], setSubsidyResult = _e[1];
    var isValidForSubsidyCheck = React.useMemo(function () {
        var e_1, _a;
        if (!(subsidyInfo === null || subsidyInfo === void 0 ? void 0 : subsidyInfo.length)) {
            return false;
        }
        else {
            try {
                for (var fields_1 = __values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                    var field = fields_1_1.value;
                    if (!!validate(passengerValues === null || passengerValues === void 0 ? void 0 : passengerValues[field.name], passengerValues, getInputType(field.type), field.validationRules)) {
                        return false;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return true;
        }
    }, [passengerValues, subsidyInfo, fields]);
    React.useEffect(function () {
        if (isValidForSubsidyCheck && (orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.canCheckSubsidies)) {
            var subsidyId = findSubsidyForPassenger(passengerValues, subsidyInfo);
            if (subsidyId) {
                orderSubsidyState
                    .checkSubsidy({
                    id: passengerId,
                    subsidyId: subsidyId,
                    values: fields
                        .filter(function (field) { return passengerValues.hasOwnProperty(field.name); })
                        .map(function (field) { return ({
                        type: field.type,
                        name: field.name,
                        value: passengerValues[field.name]
                    }); })
                })
                    .then(function (data) {
                    if (!data.isSubsidyApplied) {
                        setErrorPopupOpen(true);
                        setSubsidyResult(data);
                    }
                });
            }
        }
    }, [isValidForSubsidyCheck, orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.canCheckSubsidies]);
    var subsidyTravellerState = React.useMemo(function () {
        return orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.travellers.find(function (traveller) { return traveller.traveller.id === passengerId; });
    }, [orderSubsidyState, passengerId]);
    return (React.createElement(React.Fragment, null,
        (orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesAvailable) && (React.createElement(SubsidyRejectPopup, { open: errorPopupOpen, traveller: subsidyResult === null || subsidyResult === void 0 ? void 0 : subsidyResult.traveller, segment: subsidyResult === null || subsidyResult === void 0 ? void 0 : subsidyResult.failedSegment, rejectMessage: (_c = subsidyResult === null || subsidyResult === void 0 ? void 0 : subsidyResult.rejectMessage) !== null && _c !== void 0 ? _c : t('Subsidies not available'), onClose: function () { return setErrorPopupOpen(false); }, onNewSearch: backToResults })),
        children({
            noSubsidyAvailable: subsidyTravellerState === null || subsidyTravellerState === void 0 ? void 0 : subsidyTravellerState.isSubsidyRejected,
            withSubsidy: orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesAvailable,
            subsidyRejectMessage: (subsidyTravellerState === null || subsidyTravellerState === void 0 ? void 0 : subsidyTravellerState.rejectMessage)
                ? subsidyTravellerState.rejectMessage
                : t('Subsidies not available')
        })));
};
export default PassengerSubsidy;
