import { InfoMessagePositionsEnum } from '@websky/graphql';
export var filterMessageByPosition = function (slotPosition, message) {
    var _a, _b, _c;
    if (Array.isArray(slotPosition)) {
        return ((slotPosition.includes(InfoMessagePositionsEnum.default) && !((_a = message.positions) === null || _a === void 0 ? void 0 : _a.length)) ||
            slotPosition.some(function (position) { var _a; return (_a = message.positions) === null || _a === void 0 ? void 0 : _a.includes(position); }));
    }
    return ((slotPosition === InfoMessagePositionsEnum.default && !((_b = message.positions) === null || _b === void 0 ? void 0 : _b.length)) ||
        ((_c = message.positions) === null || _c === void 0 ? void 0 : _c.includes(slotPosition)));
};
