import { __read, __spreadArray } from "tslib";
import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import { rootReducer } from './reducers';
import memes from '../../memes';
export var createStore = memes(function () {
    var middleware = [];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    return originalCreateStore(rootReducer, applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false)));
});
