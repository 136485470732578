import { __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
export var usePagination = function (initialIndex) {
    var _a = __read(React.useState(initialIndex !== null && initialIndex !== void 0 ? initialIndex : 0), 2), paginationIndex = _a[0], setPaginationIndex = _a[1];
    var handleChange = useCallback(function (index) {
        setPaginationIndex(index);
    }, [paginationIndex]);
    return {
        currentIndex: paginationIndex,
        handleChange: handleChange
    };
};
