import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import ButtonBase from '../../BaseComponents/Button';
import cn from 'classnames';
import { Money } from '../../Money';
import { getDurationString, getMinPrices } from '../../Results/utils';
import { useTheme } from '../../theme';
import { MobileSegmentFlat } from '../../MobileSegmentInfo';
import { useTranslation } from 'react-i18next';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import Modal from '../../Modal';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import { CloseClear, Expand, InfoDetails } from '../../Icons';
import { Collapse } from '@material-ui/core';
import TotalDuration from '../../DesktopFlightRoute/components/TotalDuration/TotalDuration';
import Tooltip from '../../Tooltip';
import { useConfig } from '../../context';
import TransitTooltip from '../../TransitTooltip';
import { TransitTooltipContext } from '../../TransitTooltip/utils';
import InfoMessages from '../../InfoMessages';
var Flight = function (props) {
    var _a, _b, _c;
    var _d, _e, _f, _g, _h, _j, _k;
    var t = useTranslation('FlightPrice').t;
    var showAirplane = props.showAirplane, flight = props.flight, _l = props.selectedFares, selectedFares = _l === void 0 ? [] : _l, isPreOpen = props.isPreOpen, searchParameters = props.searchParameters, onSubsidySuggestPopupOpen = props.onSubsidySuggestPopupOpen, renderSelectButton = props.renderSelectButton;
    var _m = useDesktopFlightWithPopup(props), subStatuses = _m.subStatuses, upgradeOptions = _m.upgradeOptions, economyFareGroups = _m.economyFareGroups, businessFareGroups = _m.businessFareGroups, milesFareGroups = _m.milesFareGroups, flightSegments = _m.flightSegments, lastSelectedFare = _m.lastSelectedFare, isModalOpen = _m.isModalOpen, openModal = _m.openModal, closeModal = _m.closeModal, getOnFareSelect = _m.getOnFareSelect;
    useEffect(function () {
        if (isModalOpen && props.onSetViewFlight) {
            props.onSetViewFlight(flight);
        }
    }, [isModalOpen]);
    var popupCss = useTheme('DesktopFlight').DesktopFlightPopup;
    var theme = useTheme('MobileFlight').FlightStyles;
    var _o = __read(React.useState(props.readonly), 2), open = _o[0], setOpen = _o[1];
    var isTransitTariff = React.useContext(TransitTooltipContext).isTransitTariff;
    var subsidyFaresConditionURL = useConfig().global.companyInfo.subsidyFaresConditionURL;
    var flightId = (_h = (_g = (_f = (_e = (_d = flight.pricesForFareGroups) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.prices) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.flight) === null || _h === void 0 ? void 0 : _h.id;
    var _p = __read(useMemo(function () {
        if (!props.isSelected) {
            return [null, null];
        }
        var fareFamily = flight.pricesForFareGroups[0].fareFamily;
        return [fareFamily, selectedFares[0]];
    }, [flight, selectedFares, props.isSelected]), 2), fareFamily = _p[0], selectedFare = _p[1];
    var _q = __read(useMemo(function () {
        var _a, _b, _c, _d;
        var prices = getMinPrices(flight.pricesForFareGroups, true, selectedFares, false, props.preselectedFlightClass);
        var minOriginalPrice = getMinPrices(flight.pricesForFareGroups, true, selectedFares, true, props.preselectedFlightClass);
        var minPrice = prices[0] || prices[1];
        return [
            minPrice,
            ((_b = (_a = minOriginalPrice[0]) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.amount) > ((_c = minPrice === null || minPrice === void 0 ? void 0 : minPrice.price) === null || _c === void 0 ? void 0 : _c.amount) && ((_d = minOriginalPrice[0]) === null || _d === void 0 ? void 0 : _d.price),
            prices[0].priceInMiles,
            prices
        ];
    }, [flight, selectedFares, props.preselectedFlightClass]), 4), price = _q[0], originalPrice = _q[1], priceInMiles = _q[2], prices = _q[3];
    var stopPoints = React.useMemo(function () {
        if (flightSegments.length > 1) {
            return flightSegments
                .filter(function (segmentWithTransfer) { return !!segmentWithTransfer.transferDuration; })
                .map(function (segmentWithTransfer) {
                return {
                    airport: segmentWithTransfer.segment.arrival,
                    duration: getDurationString(segmentWithTransfer.transferDuration)
                };
            });
        }
        return [];
    }, [flight]);
    useEffect(function () {
        if (isPreOpen) {
            closeModal();
        }
    }, [isPreOpen]);
    var onExpandHandler = function () { return setOpen(!open); };
    var renderSegment = function (segments, segmentId, last, first, multipleSegment) {
        var _a;
        return (React.createElement("div", { className: cn(theme.segment__container, (_a = {},
                _a[theme.summary] = multipleSegment,
                _a)), onClick: multipleSegment && !props.canceled ? onExpandHandler : null, key: segmentId },
            first && (React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flight, className: theme.flightInfoMessage, itemHtmlClassName: theme.flightInfoMessage__htmlMessage, messages: flight.segments[0].segment.messages })),
            React.createElement(MobileSegmentFlat, { segments: segments, showAirplaneInfo: (flightSegments.length === 1 && !selectedFare) || open, className: theme.segment, stopCount: multipleSegment && stopPoints.length, last: last, multipleSegments: multipleSegment, totalDuration: flightSegments.length > 1 && last ? (React.createElement(TotalDuration, { lastSegment: segments[0].segment, flight: flight })) : null, renderCompanyName: props.renderCompanyName, isReturnFlight: props.isReturnFlight }),
            multipleSegment &&
                stopPoints.map(function (stop) {
                    return (React.createElement("div", { className: theme.stopPoint },
                        stop.airport.airport.city.name,
                        " \u2013 ",
                        stop.duration));
                })));
    };
    var renderSubsidyInfo = function () {
        return (React.createElement(Tooltip, { title: t('A subsidized fare requires all passengers on the booking to have benefits.') },
            React.createElement("a", { href: subsidyFaresConditionURL, onClick: function (e) { return e.stopPropagation(); }, target: "_blank", className: theme.subsidies },
                t('Subsidized flight'),
                " ",
                InfoDetails)));
    };
    var isExpand = flightSegments.length > 1 && !props.readonly && !props.canceled;
    return (React.createElement("div", { className: cn(theme.flight, (_a = {},
            _a[theme.flight_withAircraft] = showAirplane,
            _a[theme.flight_selected] = props.isSelected,
            _a[theme.readonly] = props.readonly,
            _a[theme.expanded] = isExpand,
            _a)) },
        props.flightIcon,
        React.createElement("div", { className: cn(theme.segments, (_b = {},
                _b[theme.segments_expanded] = open,
                _b)) },
            flightSegments.length > 1 && (React.createElement(React.Fragment, null,
                React.createElement(Collapse, { in: open },
                    React.createElement("div", null, flightSegments.map(function (segment, index) {
                        return renderSegment([segment], "c_".concat(index), index === flightSegments.length - 1, index === 0, false);
                    }))),
                !open && renderSegment(flightSegments, '0', true, false, true),
                isExpand && (React.createElement("div", { className: cn(theme.expand, (_c = {},
                        _c[theme.expand_open] = open,
                        _c)), onClick: onExpandHandler }, Expand)))),
            flightSegments.length === 1 && renderSegment(flightSegments, '0', true, true)), (_j = renderSelectButton === null || renderSelectButton === void 0 ? void 0 : renderSelectButton({
        readonly: props.readonly,
        isSelected: props.isSelected,
        hidePrice: props.hidePrice,
        prices: prices
    })) !== null && _j !== void 0 ? _j : (React.createElement(React.Fragment, null, !props.readonly && !props.isSelected && (React.createElement("div", { className: theme.price__wrapper },
        isTransitTariff && (React.createElement("div", { className: theme.transitTariff },
            React.createElement(TransitTooltip, null))),
        originalPrice && (React.createElement("div", { className: theme.price_original },
            React.createElement(Money, { money: originalPrice, moneyClassName: theme.price_originalMoney }))),
        React.createElement(ButtonBase, { variant: "optionAction", className: theme.price, onClick: onSubsidySuggestPopupOpen ? function () { return onSubsidySuggestPopupOpen(openModal); } : openModal }, !props.hidePrice && !props.hideOnlyPrice ? (React.createElement("div", { className: theme.price__text },
            React.createElement("span", null, t('Select from')),
            priceInMiles ? (React.createElement(PriceInMiles, { amount: priceInMiles, moneyClassName: cn(theme.price__money, theme.price__miles), currencyClassName: theme.currency })) : (React.createElement(Money, { moneyClassName: theme.price__money, money: price.price })))) : (React.createElement("div", { className: theme.price__text },
            React.createElement("span", null, t('Select'))))),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flightPrice, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage, messages: flight.segments[0].segment.messages }))))),
        selectedFare && !subStatuses.length && (React.createElement("div", { className: theme.selectedFare },
            React.createElement("div", null,
                prices[0].hasSubsidyFare && renderSubsidyInfo(),
                fareFamily.title),
            React.createElement("div", { className: theme.selectedFare__priceWrapper },
                !props.hideOnlyPrice && (React.createElement(Money, { moneyClassName: theme.selectedFare__price, money: selectedFare.prices[0].price })),
                props.onClear && (React.createElement("div", { onClick: props.onClear, className: theme.price__close }, CloseClear))))),
        !!subStatuses.length && (React.createElement("div", { className: theme.subStatus },
            React.createElement("span", null, t("DesktopFlightInfo:SubStatus_".concat(subStatuses[0]))))),
        priceInMiles && (React.createElement("div", { className: theme.miles__taxes },
            t('taxes and fees'),
            ":",
            React.createElement(Money, { money: price.price }))),
        React.createElement(Modal, { isRounded: true, maxWidth: 'md', open: isModalOpen, onClose: closeModal, classes: { paper: popupCss.paper, scrollBody: popupCss.scrollBody }, fullScreen: true, closeButtonRenderer: function () { return null; } },
            React.createElement(DesktopFlightModalContent, { lastSelectedFare: (_k = flight.pricesForFareGroups.find(function (fareGroup) { var _a; return fareGroup && ((_a = fareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.id) === lastSelectedFare; })) === null || _k === void 0 ? void 0 : _k.fareFamily, upgradeOptions: upgradeOptions, flight: flight, flightId: flightId, orderId: props.orderId, economyFares: economyFareGroups, businessFares: businessFareGroups, milesFares: milesFareGroups, getOnFareSelect: getOnFareSelect, hidePrice: props.hidePrice, searchParameters: searchParameters, preselectedFlightClass: props.preselectedFlightClass, onClose: closeModal }))));
};
export default Flight;
