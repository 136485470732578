import * as React from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import FareConditions from '../../FareConditions/components/Component';
import Dialog from '../../Service/components/Dialog/Dialog';
import Modal from '../../Modal';
import { MOBILE_MIN_WIDTH } from '../../utils';
var FareConditionsModal = function (_a) {
    var segmentId = _a.segmentId, flightId = _a.flightId, orderId = _a.orderId, onClose = _a.onClose, isOpen = _a.isOpen;
    var t = useTranslation('FareConditions').t;
    var renderFareConditions = function () {
        return React.createElement(FareConditions, { segmentId: segmentId, flightId: flightId, orderId: orderId });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isOpen, onClose: onClose }, renderFareConditions())),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { isOpen: isOpen, onClose: onClose, dialogType: 'fullscreen', dialogHeader: t('Fare conditions'), content: renderFareConditions(), mountOnEnter: true }))));
};
export default FareConditionsModal;
