import * as React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useTheme } from '../../../theme';
import { Switch } from '../../../index';
import Counter from '../../../Counter/Counter';
var MAX_COUNTER_VALUE = 7;
var MIN_COUNTER_VALUE = 0;
var Control = function (_a) {
    var type = _a.type, count = _a.count, disabled = _a.disabled, label = _a.label, onSetCount = _a.onSetCount;
    var theme = useTheme('VipServiceSelect').VipServiceSelect;
    var onChange = function () {
        onSetCount(count > 0 ? 0 : 1);
    };
    var onCounterIncrease = function () {
        if (count + 1 <= MAX_COUNTER_VALUE) {
            onSetCount(count + 1);
        }
    };
    var onCounterDecrease = function () {
        if (count - 1 >= MIN_COUNTER_VALUE) {
            onSetCount(count - 1);
        }
    };
    if (type === 'switch') {
        return (React.createElement(Switch, { isDisabled: disabled, switchPosition: "right", mainClassName: theme.switch__container, activeClassName: theme.switch__container_active, labelClassName: theme.label, label: label, isActive: count > 0, onClick: onChange }));
    }
    else if (type === 'checkbox') {
        return (React.createElement(FormControlLabel, { value: "end", label: label, classes: { label: theme.checkbox_label }, control: React.createElement(Checkbox, { classes: {
                    root: theme.checkbox,
                    checked: theme.checkbox_checked
                }, checked: count > 0, disabled: disabled, onChange: onChange, disableRipple: true }) }));
    }
    else if (type === 'counter') {
        return (React.createElement("div", { className: theme.passenger__wrapper_counter },
            label,
            React.createElement(Counter, { value: count, maxValue: MAX_COUNTER_VALUE, minValue: MIN_COUNTER_VALUE, onIncrease: onCounterIncrease, onDecrease: onCounterDecrease, showOnlyIncreaseButtonWhenValueIsNull: true, readonly: disabled, classes: {
                    root: theme.counter__root,
                    minus: theme.counter,
                    plus: theme.counter,
                    value: theme.counter__value
                } })));
    }
    return null;
};
export default Control;
