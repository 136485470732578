import React, { useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { WelcomeIcon } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum, useGetAccountInfoQuery } from '@websky/graphql';
import { getUserValue } from '../../../../utils';
var Welcome = function () {
    var _a;
    var css = useTheme('LoginPage').Welcome;
    var t = useTranslation('LoginPage').t;
    var currentUser = useGetAccountInfoQuery();
    var name = useMemo(function () {
        var _a;
        var name = null;
        if ((_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) {
            name = getUserValue(currentUser.data.CurrentUser.userProfile, TravellerFieldEnum.FirstName);
        }
        return name;
    }, [(_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title },
            React.createElement("span", { className: css.icon_wrapper }, WelcomeIcon),
            name ? "".concat(t('Welcome back,'), " ").concat(name, "!") : t('Welcome back!'))));
};
export default Welcome;
