var _a;
import { createTheme } from '../utils';
import * as PriceMatrixStyles from './components/PriceMatrix.css';
import * as MatrixStyles from './components/Matrix/Matrix.css';
import * as OutboundStyles from './components/Outbound/Outbound.css';
export var defaultTheme = {
    PriceMatrixStyles: PriceMatrixStyles,
    MatrixStyles: MatrixStyles,
    OutboundStyles: OutboundStyles
};
export var Provider = (_a = createTheme(defaultTheme), _a.Provider), withTheme = _a.withTheme;
