import * as React from 'react';
import { cityIn } from 'lvovich';
import { useTranslation } from 'react-i18next';
import { getDurationString, getFlightDuration } from '../../../Results/utils';
import { useTheme } from '../../../theme';
var TotalDuration = function (props) {
    var _a;
    var lastSegment = props.lastSegment, flight = props.flight, _b = props.separator, separator = _b === void 0 ? '-' : _b;
    var theme = useTheme('DesktopFlightRoute').TotalDuration;
    var t = useTranslation('DesktopFlightInfo').t;
    return (React.createElement(React.Fragment, null,
        ((_a = lastSegment.stops) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement("span", { className: theme.duration__stops },
            t('DesktopFlightInfo:TechnicalLandingIn', {
                count: lastSegment.stops.length
            }),
            ' ',
            lastSegment.stops.map(function (stop) { return cityIn(stop.airport.city.name); }).join(', '),
            ".")),
        flight.segments.length > 1 && (React.createElement("span", null,
            t("Total duration"),
            " ",
            separator,
            " ",
            getDurationString(getFlightDuration(flight.segments))))));
};
export default TotalDuration;
