import { __assign } from "tslib";
import { initI18n } from '../../../utils';
import * as React from 'react';
import { useGraphQLClient } from '../../../graphql-client';
import { ApolloProvider } from '@apollo/react-hooks';
import BaseComponent from './BaseComponent/BaseComponent';
initI18n('LoginPage');
export var Component = function (props) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(BaseComponent, __assign({}, props))));
};
export default Component;
