import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
var OrderCities = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var flight = _a.flight, bookingPreview = _a.bookingPreview;
    var css = useTheme('OrderCard').OrderPreview;
    var airports = useMemo(function () {
        var _a, _b;
        var isRoundTrip = false;
        if (flight.segmentGroups.length === 2) {
            var firstSegments = flight.segmentGroups[0].segments;
            var lastSegments = flight.segmentGroups[1].segments;
            var firstCity = (_a = firstSegments[0].departure.airport.city) === null || _a === void 0 ? void 0 : _a.id;
            var lastCity = (_b = lastSegments[lastSegments.length - 1].departure.airport.city) === null || _b === void 0 ? void 0 : _b.id;
            if (firstCity === lastCity) {
                isRoundTrip = true;
            }
        }
        // if there and back then only the starting city and the ending city of the first flight are displayed
        if (isRoundTrip) {
            var segments = flight.segmentGroups[0].segments;
            var first = segments[0];
            var last = segments[segments.length - 1];
            return [first.departure.airport, last.arrival.airport];
        }
        else {
            var segments = flight.segmentGroups[0].segments;
            var first = segments[0];
            return __spreadArray([
                first.departure.airport
            ], __read(flight.segmentGroups
                .map(function (_a) {
                var segments = _a.segments;
                return segments[segments.length - 1];
            })
                .map(function (segment) { return segment.arrival.airport; })), false);
        }
    }, [flight.segmentGroups]);
    if (airports.length === 2 && !bookingPreview) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.city }, (_e = (_c = (_b = airports[0].city) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : (_d = airports[0]) === null || _d === void 0 ? void 0 : _d.iata) !== null && _e !== void 0 ? _e : ''),
            React.createElement("div", { className: cn(css.city, css.main_city) }, (_j = (_g = (_f = airports[1].city) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : (_h = airports[1]) === null || _h === void 0 ? void 0 : _h.iata) !== null && _j !== void 0 ? _j : '')));
    }
    else {
        return (React.createElement("div", { className: css.cities_chain }, airports.map(function (airport, i) {
            var _a, _b, _c;
            return (React.createElement("div", { className: css.city, key: i }, (_c = (_b = (_a = airport.city) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : airport === null || airport === void 0 ? void 0 : airport.iata) !== null && _c !== void 0 ? _c : ''));
        })));
    }
};
export default OrderCities;
