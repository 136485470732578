import * as React from 'react';
import cn from 'classnames';
import { Bike, Choosed, PetInHold, Serfing, SmallPet, MediumPet, BigPet, SnowBoard, SportEquipment, overridedIcons } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useRenderers } from '../../../../../renderProps';
import { useMemo } from 'react';
import { BaggageType } from '@websky/graphql';
var HeaderEquipmentItem = function (_a) {
    var _b, _c;
    var className = _a.className, rfics = _a.rfics, icon = _a.icon, baggageType = _a.baggageType, isActive = _a.isActive, isSelected = _a.isSelected, onClick = _a.onClick;
    var css = useTheme('UpgradeBaggageDialog').HeaderEquipmentItem;
    var typesByRfics = {
        '0EF': SnowBoard,
        '0EG': SnowBoard,
        '0EH': SnowBoard,
        '0EC': Bike,
        '0EI': Serfing,
        '0DD': SnowBoard,
        '0BT': overridedIcons.PetInHold,
        '021': SmallPet,
        '0BS': MediumPet,
        '0AY': BigPet
    };
    var renderHeaderEquipmentIcon = useRenderers().renderHeaderEquipmentIcon;
    var iconByBaggageType = (_b = {},
        _b[BaggageType.PetInCabin] = PetInHold,
        _b);
    var equipmentIcon = useMemo(function () {
        if (icon) {
            return icon;
        }
        else if (renderHeaderEquipmentIcon) {
            return renderHeaderEquipmentIcon(rfics, baggageType);
        }
        else if (baggageType && (iconByBaggageType === null || iconByBaggageType === void 0 ? void 0 : iconByBaggageType[baggageType])) {
            return iconByBaggageType[baggageType];
        }
        else if (rfics && (typesByRfics === null || typesByRfics === void 0 ? void 0 : typesByRfics[rfics])) {
            return typesByRfics[rfics];
        }
        else {
            return SportEquipment;
        }
    }, [icon, rfics, baggageType, typesByRfics, iconByBaggageType, renderHeaderEquipmentIcon]);
    return (React.createElement("div", { className: cn(className, css.wrapper, (_c = {},
            _c[css.wrapper_pointer] = !!onClick,
            _c[css.active] = isActive,
            _c)), onClick: onClick },
        isSelected && React.createElement("div", { className: css.selected }, Choosed),
        equipmentIcon));
};
export default HeaderEquipmentItem;
