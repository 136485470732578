import { createSelector } from 'reselect';
import { getSelectedPassengers } from '../passengers/selectors';
import { getSelectedReason } from '../reason/selectors';
import { getReasonsInfo } from '../refundInfo/selectors';
var getState = function (state) { return state.documents; };
export var getDocuments = createSelector(getState, function (state) { return state; });
export var getIsAllDocumentsProvided = createSelector(getSelectedPassengers, getState, function (selectedPassengers, documents) {
    return selectedPassengers.every(function (selectedPassenger) { var _a; return (_a = documents[selectedPassenger.id]) === null || _a === void 0 ? void 0 : _a.some(Boolean); });
});
export var getRefundFiles = createSelector(getState, getSelectedPassengers, getSelectedReason, getReasonsInfo, function (documentsState, selectedPassengers, selectedReason, reasons) {
    return Array.prototype.concat.apply([], selectedPassengers.map(function (passenger) {
        var _a;
        return (_a = documentsState[passenger.id]) === null || _a === void 0 ? void 0 : _a.filter(Boolean).map(function (document) {
            var _a;
            return ({
                content: document.content,
                originalFileName: document.name,
                type: (_a = reasons === null || reasons === void 0 ? void 0 : reasons.find(function (reason) { return reason.reason === selectedReason; })) === null || _a === void 0 ? void 0 : _a.docs[0],
                passengerId: passenger.id
            });
        });
    }));
});
