import { getCurrency } from '../cache';
export var getAeroexpressProductId = function (trip) {
    return "".concat(trip.id).concat(trip.class).concat(trip.departureDate);
};
export var isSelectedTrip = function (trip) {
    return (trip === null || trip === void 0 ? void 0 : trip.tickets) !== undefined;
};
export var getAeroexpressMinPrice = function (trips) {
    return {
        amount: trips.map(function (trip) { return trip.price.amount; }).sort(function (a, b) { return a - b; })[0],
        currency: getCurrency()
    };
};
