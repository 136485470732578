import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { Switch } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import LoyaltyCard from './LoyaltyCard/LoyaltyCard';
import { setRedeemMiles } from '../../../../store/redeemMiles/actions';
import { getRedeemMiles } from '../../../../store/selectors';
import PassengersBlockedModal from './PassengersBlockedModal/PassengersBlockedModal';
import { useToggleable } from '../../../../../hooks';
import { useConfig } from '../../../../../context';
var PayByMiles = function () {
    var theme = useTheme('SearchForm').PayByMiles;
    var t = useTranslation('SearchForm').t;
    var form = useForm();
    var dispatch = useDispatch();
    var isActive = useSelector(getRedeemMiles);
    var clearLoyalty = function () {
        dispatch(setRedeemMiles(false));
    };
    var _a = useToggleable(false), isOpen = _a.isOpen, toggle = _a.toggle, close = _a.close;
    var passengersConfig = useConfig().SearchForm.passengersConfig;
    var isSomePassengersBlocked = React.useMemo(function () {
        return passengersConfig.some(function (it) { return it.blockPayByMiles; });
    }, [passengersConfig]);
    var onSetLoyalty = function (card, logged) {
        if (isSomePassengersBlocked && !!card) {
            toggle();
            return;
        }
        dispatch(setRedeemMiles(!!card));
        if (logged) {
            try {
                form.submit();
            }
            catch (e) {
                return;
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(LoyaltyCard, { setLoyalty: onSetLoyalty }, function (requestLoyaltyCard) {
            return (React.createElement(Switch, { mainClassName: theme.switchWrapper, labelClassName: theme.switchBox, label: t('Pay by miles'), activeClassName: theme.switchActive, isActive: isActive, onClick: isActive ? clearLoyalty : requestLoyaltyCard }));
        }),
        isSomePassengersBlocked && (React.createElement(PassengersBlockedModal, { isOpen: isOpen, onClose: close, onConfirm: function () {
                toggle();
                dispatch(setRedeemMiles(true));
            }, onCancel: close }))));
};
export default PayByMiles;
