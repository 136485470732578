import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import { Tooltip } from '../../../index';
import { getLocale } from '../../../utils';
import SelectSegments from '../../../SelectSegments';
import Notification from '../../../Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { InfoDetails, FileDownload } from '../../../Icons';
var Controls = function (_a) {
    var className = _a.className, segments = _a.segments, canDownloadBoardingPass = _a.canDownloadBoardingPass, onBoardingPassDownload = _a.onBoardingPassDownload, onAppleWalletPassDownload = _a.onAppleWalletPassDownload, setSegmentsFailedBoardingPasses = _a.setSegmentsFailedBoardingPasses;
    var css = useTheme('RegisteredPassenger').Controls;
    var t = useTranslation('RegisteredPassenger').t;
    var _b = __read(useState(false), 2), boardingPassLoading = _b[0], setBoardingPassLoading = _b[1];
    var _c = __read(useState(false), 2), appleWalletLoading = _c[0], setAppleWalletLoading = _c[1];
    var currentLocale = getLocale();
    var handleDownloadResult = function (downloadResult, segmentId, segments) {
        var segmentsIdsToAdd = segmentId ? [segmentId] : segments.map(function (segment) { return segment.id; });
        if (!downloadResult) {
            setSegmentsFailedBoardingPasses(function (prevState) { return new Set(__spreadArray(__spreadArray([], __read(prevState), false), __read(segmentsIdsToAdd), false)); });
        }
    };
    var onBoardingPassClickHandler = useCallback(function (segmentId) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setBoardingPassLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onBoardingPassDownload(segmentId)];
                case 2:
                    result = _a.sent();
                    handleDownloadResult(result, segmentId, segments);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setBoardingPassLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }; }, [onBoardingPassDownload, segments, handleDownloadResult]);
    var onAppleWalletPassClickHandler = useCallback(function (segmentId) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAppleWalletLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onAppleWalletPassDownload(segmentId)];
                case 2:
                    result = _a.sent();
                    handleDownloadResult(result, segmentId, segments);
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    setAppleWalletLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }; }, [onAppleWalletPassDownload, segments, handleDownloadResult]);
    var appleWalletUrl = "https://cdn.websky.aero/content/frontend/images/apple-wallet-".concat(currentLocale, ".svg");
    var hasSegmentWithAstra = segments.some(function (segment) { return segment.checkinInfo.airportHasDcs; });
    return (React.createElement("div", { className: cn(css.container, className) },
        canDownloadBoardingPass && onBoardingPassDownload && (React.createElement(SelectSegments, { title: t('Select flight for download'), segments: hasSegmentWithAstra ? segments : [], onClick: onBoardingPassClickHandler(null), renderTrigger: function (_a) {
                var onClick = _a.onClick, isLoading = _a.isLoading;
                return (React.createElement(Button, { variant: "outline", className: css.control, onClick: onClick, disabled: isLoading }, t('Boarding pass')));
            }, renderButton: function (segment) {
                var canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                if (!canDownloadBoardingPass) {
                    return (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                        React.createElement(Button, { className: css.controlDownload, onClick: function () { return null; }, disabled: true }, FileDownload)));
                }
                return (React.createElement(Button, { className: css.controlDownload, onClick: onBoardingPassClickHandler(segment.id), isLoading: boardingPassLoading }, FileDownload));
            } })),
        canDownloadBoardingPass && onAppleWalletPassDownload && (React.createElement(SelectSegments, { title: t('Select flight for download'), segments: segments, onClick: onAppleWalletPassClickHandler(null), renderTrigger: function (_a) {
                var _b;
                var onClick = _a.onClick, isLoading = _a.isLoading;
                return (React.createElement("div", { className: cn(css.controlImg, (_b = {},
                        _b[css.controlImg__disabled] = isLoading,
                        _b)) },
                    React.createElement("img", { src: appleWalletUrl, alt: "Add to Apple Wallet", onClick: onClick })));
            }, renderButton: function (segment) {
                var _a;
                var canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                if (!canDownloadBoardingPass) {
                    return (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                        React.createElement("div", { className: cn(css.controlImg, css.controlImg__disabled) },
                            React.createElement("img", { src: appleWalletUrl, alt: "Add to Apple Wallet", onClick: function () { return null; } }))));
                }
                return (React.createElement("div", { className: cn(css.controlImg, (_a = {},
                        _a[css.controlImg__disabled] = appleWalletLoading,
                        _a)) },
                    React.createElement("img", { src: appleWalletUrl, alt: "Add to Apple Wallet", onClick: onAppleWalletPassClickHandler(segment.id) })));
            } })),
        !canDownloadBoardingPass && (React.createElement(Notification, { icon: InfoDetails, status: InfoMessageTypeEnum.Info, type: "context", infoMessage: {
                text: t('You must receive the boarding pass at the airport on the registration desk.'),
                type: InfoMessageTypeEnum.Info,
                code: 'astra-info'
            } }))));
};
export default Controls;
