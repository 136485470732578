import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { InfoMessageTypeEnum } from '@websky/graphql';
import Passenger from './Passenger/Passenger';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import Toolbar from '../../../../Toolbar';
import WarningModal from '../../../../WarningModal';
import Segment from '../../../../RetrieveBooking/components/Segment/Segment';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import MobileSegment from '../../../../RetrieveBooking/components/MobileSegment/MobileSegment';
import Notification from '../../../../Notification';
import { isCorrectCyrillicLastName, isCorrectLatingLastname, isTicketNumber } from '../../../../RetrieveBooking/hooks';
var reducer = function (state, action) {
    var isPassengerExists = state.find(function (passenger) { return passenger.id === action.id; });
    if (isPassengerExists) {
        return state.map(function (passenger) { return (passenger.id === action.id ? __assign({}, action) : __assign({}, passenger)); });
    }
    else {
        return __spreadArray(__spreadArray([], __read(state), false), [
            __assign(__assign({}, action), { id: state.length.toString() })
        ], false);
    }
};
var init = function (groupOrderParams) {
    if (isTicketNumber(groupOrderParams.id) &&
        (isCorrectLatingLastname(groupOrderParams.secret) || isCorrectCyrillicLastName(groupOrderParams.secret))) {
        return [
            {
                id: '0',
                passengerName: groupOrderParams.secret,
                passengerId: groupOrderParams.id,
                selected: true
            }
        ];
    }
    return [];
};
var GroupOrder = function (props) {
    var _a, _b;
    var _c = __read(React.useReducer(reducer, props.groupOrderParams, init), 2), state = _c[0], dispatch = _c[1];
    var _d = __read(React.useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(React.useState(null), 2), error = _e[0], setError = _e[1];
    var t = useTranslation('Checkout').t;
    var theme = useTheme('Checkout').GroupOrder;
    var onChangeHandler = function (passenger, selected) {
        dispatch(__assign(__assign({}, passenger), { selected: selected }));
    };
    var onAddPassengerHandler = function (lastName, id, internalId) {
        return new Promise(function (resolve) {
            dispatch({
                passengerId: id,
                passengerName: lastName,
                selected: true,
                id: internalId
            });
            resolve(true);
        });
    };
    var selectedPassengers = state.filter(function (passenger) { return passenger.selected; });
    var onNextClickHandler = function () {
        var passengers = selectedPassengers.map(function (passenger) {
            return {
                id: passenger.id,
                lastName: passenger.passengerName,
                ticketNumber: passenger.passengerId
            };
        });
        if (passengers.length) {
            setLoading(true);
            props
                .onAccept(passengers)
                .then(function (order) {
                var _a, _b;
                if ((_a = order.splitOrder) === null || _a === void 0 ? void 0 : _a.id) {
                    props.onSuccess(order.splitOrder);
                }
                if (((_b = order === null || order === void 0 ? void 0 : order.notMatchedTravellersData) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    order.notMatchedTravellersData.forEach(function (passenger) {
                        dispatch({
                            passengerId: passenger.ticketNumber,
                            passengerName: passenger.lastName,
                            error: true,
                            selected: true,
                            id: passenger.id
                        });
                    });
                }
                setLoading(false);
            })
                .catch(function (errors) {
                var _a;
                setError(((_a = errors === null || errors === void 0 ? void 0 : errors[0]) === null || _a === void 0 ? void 0 : _a.message) || 'Error while FindGroupOrder');
                setLoading(false);
            });
        }
    };
    return (React.createElement("div", { className: theme.groupOrder },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.container }, t('Search my booking'))),
        React.createElement("div", { className: cn(theme.container, theme.notifications) },
            React.createElement(Notification, { className: theme.notification__item, status: InfoMessageTypeEnum.Warning, type: "context", infoMessage: {
                    text: t('Specify all passengers for whom you need to open an order for correct display.'),
                    type: InfoMessageTypeEnum.Warning,
                    code: 'warning'
                } })),
        ((_b = (_a = props.groupOrder.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement("div", { className: cn(theme.container, theme.flight) }, props.groupOrder.flight.segmentGroups.map(function (segment, index) { return (React.createElement(React.Fragment, { key: segment.groupId },
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Segment, { variant: 'common', segment: segment })),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(MobileSegment, { segment: segment, className: theme.segment })))); }))),
        React.createElement("div", { className: cn(theme.container, theme.passengers) },
            state.map(function (passenger) {
                return (React.createElement(Passenger, { passenger: passenger, key: passenger.passengerId, onSelect: onChangeHandler, onAddPassenger: onAddPassengerHandler }));
            }),
            React.createElement(Passenger, { type: "add", isOpen: !state.length, onAddPassenger: onAddPassengerHandler })),
        React.createElement(Toolbar, { totalPrice: null, isLoading: loading, buttonDisabled: !selectedPassengers.length, onButtonClick: onNextClickHandler }),
        React.createElement(WarningModal, { isOpen: !!error, onClose: function () { return setError(null); }, title: t('Oops..'), content: t('An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.'), errorMessage: error })));
};
export default GroupOrder;
