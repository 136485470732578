import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { seatIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
var Header = function (_a) {
    var _b;
    var _c, _d;
    var className = _a.className, name = _a.name, seats = _a.seats, controls = _a.controls, _e = _a.isCheckedIn, isCheckedIn = _e === void 0 ? true : _e;
    var css = useTheme('RegisteredPassenger').Header;
    var t = useTranslation('RegisteredPassenger').t;
    var seatServiceClass = (_d = (_c = seats === null || seats === void 0 ? void 0 : seats[0]) === null || _c === void 0 ? void 0 : _c.seat.seatService) === null || _d === void 0 ? void 0 : _d.serviceClass;
    return (React.createElement("div", { className: cn(css.container, className, (_b = {},
            _b[css.disabled] = !isCheckedIn,
            _b)) },
        React.createElement("div", { className: css.info },
            React.createElement("div", { className: css.passenger },
                React.createElement("div", { className: css.passenger__label }, t('Passenger')),
                React.createElement("div", { className: css.passenger__name }, name)),
            (seats === null || seats === void 0 ? void 0 : seats.length) > 0 && (React.createElement("div", { className: css.seats },
                React.createElement("div", { className: css.seats__label }, t('Seat')),
                React.createElement("div", { className: css.seats__icon }, seatIcon),
                React.createElement("span", { className: css.seats__text },
                    seats.map(function (service) { return service.seat.number; }).join(', '),
                    React.createElement(MediaQuery, { minWidth: "mobile" }, seatServiceClass ? React.createElement(React.Fragment, null,
                        ", ",
                        t(seatServiceClass)) : ''))))),
        React.createElement("div", { className: css.controls }, controls)));
};
export default Header;
