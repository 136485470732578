import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var ExitRow = function () {
    var theme = useTheme('SeatMap').SeatMapStyles;
    var t = useTranslation('SeatMap').t;
    return (React.createElement("div", { className: theme.exit },
        React.createElement("div", { className: theme.exit__label }, t('SeatMap:Exit')),
        React.createElement("div", { className: theme.exit__label }, t('SeatMap:Exit'))));
};
export default ExitRow;
