import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import BaggageCounter from '../../../../BaggageCounter';
import { serviceForMultipleSegments, usePassengerState } from '../../../hooks';
import Tooltip from '../../../../../Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedServicesByPassengersMap, getMaxBaggagePerPassengerCount, getService, getPassenger } from '../../../store/selector';
import { findMaxCountServicesInFlight, getBaggageCountInSegment, getMaxBaggageCount, getMinBaggageCount, updateSelectedItems } from '../../../utils';
import { useConfig } from '../../../../../context';
import { useAvailableCountForTravellerOnSegment } from '../../../../../AdditionalServices/hooks';
var PassengerBaggageCounter = function (_a) {
    var _b, _c, _d;
    var className = _a.className, passengerId = _a.passengerId, baggages = _a.baggages, onChange = _a.onChange, disabled = _a.disabled, segmentIds = _a.segmentIds, segmentId = _a.segmentId;
    var theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var t = useTranslation('IncludedBaggage').t;
    var _e = usePassengerState(passengerId, baggages, segmentIds, segmentId), items = _e.items, selectedItems = _e.selectedItems, selectedCount = _e.selectedCount;
    var services = useSelector(getService);
    var selectedServicesByPassengers = useSelector(getSelectedServicesByPassengersMap);
    var defaultMaxBaggagePerPassenger = useSelector(getMaxBaggagePerPassengerCount);
    var passenger = useSelector(function (state) { return getPassenger(state, passengerId); });
    var baggageLimitConfig = (_c = (_b = useConfig().global) === null || _b === void 0 ? void 0 : _b.baggage) === null || _c === void 0 ? void 0 : _c.baggageLimit;
    var selectedBaggageCountInSegmentForPassenger = React.useMemo(function () {
        return segmentId
            ? getBaggageCountInSegment(selectedServicesByPassengers.get(passengerId) || [], segmentId)
            : findMaxCountServicesInFlight(selectedServicesByPassengers.get(passengerId) || []);
    }, [selectedServicesByPassengers, segmentId]);
    var selectedPassengerBaggageByTypeCount = React.useMemo(function () {
        var selectedPassengerBag = selectedServicesByPassengers.get(passengerId);
        if (selectedPassengerBag) {
            var bagCount = void 0;
            if (!segmentId) {
                bagCount = findMaxCountServicesInFlight(selectedPassengerBag);
            }
            else {
                bagCount = selectedPassengerBag
                    .filter(function (i) { return i.segmentIds.includes(segmentId); })
                    .reduce(function (count, bag) { return (services.some(function (s) { return s.id === bag.serviceId; }) ? count + bag.count : count); }, 0);
            }
            return bagCount;
        }
        return 0;
    }, [passengerId, segmentId, selectedServicesByPassengers, services]);
    var availableForTravellerOnSegment = useAvailableCountForTravellerOnSegment((_d = baggages[0]) === null || _d === void 0 ? void 0 : _d.id, passengerId, segmentIds[0]);
    var maxCount = React.useMemo(function () {
        var _a;
        if (availableForTravellerOnSegment !== undefined) {
            return availableForTravellerOnSegment;
        }
        if ((_a = baggages[0]) === null || _a === void 0 ? void 0 : _a.maxServiceCountPerPassenger) {
            return baggages[0].maxServiceCountPerPassenger;
        }
        return getMaxBaggageCount(defaultMaxBaggagePerPassenger, baggageLimitConfig, baggages[0].type, selectedCount, selectedPassengerBaggageByTypeCount, selectedBaggageCountInSegmentForPassenger);
    }, [
        selectedBaggageCountInSegmentForPassenger,
        defaultMaxBaggagePerPassenger,
        selectedCount,
        selectedPassengerBaggageByTypeCount,
        baggages,
        availableForTravellerOnSegment
    ]);
    var minCount = React.useMemo(function () { return getMinBaggageCount(passenger, baggages, segmentId); }, [passenger]);
    var _f = __read(React.useState(false), 2), tooltipOpen = _f[0], setTooltipOpen = _f[1];
    var onChangeHandler = function (count) {
        var newItems = updateSelectedItems(selectedCount, count, items, selectedItems);
        if (newItems) {
            onChange(newItems);
        }
        if (multipleSegments) {
            setTooltipOpen(true);
            setTimeout(function () { return setTooltipOpen && setTooltipOpen(false); }, 700);
        }
    };
    var multipleSegments = React.useMemo(function () { return serviceForMultipleSegments(baggages[0]); }, [baggages]);
    return (React.createElement("div", { className: cn(className, theme.counter_wrapper) },
        React.createElement(Tooltip, { title: t('Added for all flights'), disableHoverListener: !multipleSegments, disableTouchListener: !multipleSegments, open: tooltipOpen },
            React.createElement("div", null,
                React.createElement(BaggageCounter, { max: maxCount, min: minCount, value: selectedCount, onChange: onChangeHandler, disabled: disabled })))));
};
export default PassengerBaggageCounter;
