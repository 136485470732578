import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import { groupServices } from '../../Cart/utils';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
var CommonSummary = function (props) {
    var summaryGroups = React.useMemo(function () {
        var travellerItems = [];
        props.travellers.forEach(function (traveller) {
            var _a;
            var groupedService = groupServices(props.services, traveller, traveller.id, props.groupType, props.flight.segments, props.serviceFilterPredicate);
            var items = [];
            (_a = Object.keys(groupedService[traveller.id] || {})) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                var _a;
                (_a = groupedService[traveller.id][key].included) === null || _a === void 0 ? void 0 : _a.forEach(function (included) {
                    var includedItem = props.includedOptionItemFactory({
                        selectedService: included,
                        key: key,
                        travellerId: traveller.id
                    });
                    if (props.includedOptionItemFactory && includedItem) {
                        items.push(includedItem);
                    }
                });
                if (groupedService[traveller.id][key].additional) {
                    groupedService[traveller.id][key].additional.forEach(function (selectedService) {
                        var newItems = props.serviceOptionItemFactory({
                            selectedService: selectedService,
                            key: key,
                            travellerId: traveller.id
                        });
                        if (Array.isArray(newItems)) {
                            items.push.apply(items, __spreadArray([], __read(newItems), false));
                        }
                        else {
                            items.push(newItems);
                        }
                    });
                }
            });
            if (!!items.length) {
                var newGroup = props.serviceOptionGroupFactory({ items: items, travellerId: traveller.id });
                if (Array.isArray(newGroup)) {
                    travellerItems.push.apply(travellerItems, __spreadArray([], __read(newGroup), false));
                }
                else {
                    travellerItems.push(newGroup);
                }
            }
        });
        return travellerItems;
    }, [props.services, props.travellers, props.flight]);
    var content = React.useMemo(function () {
        return !!summaryGroups.length ? (React.createElement(ServiceSummaryDivider, null, summaryGroups.map(function (meal) {
            return React.createElement(CartItemOptionAdapter, { option: meal, key: meal.header });
        }))) : null;
    }, [summaryGroups]);
    return props.children(content);
};
export default CommonSummary;
