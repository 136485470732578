import { __assign } from "tslib";
import { SET_LAST_EXCHANGE, SET_ORDER } from './actions';
export var orderReducer = function (state, action) {
    if (state === void 0) { state = null; }
    if (action.type === SET_ORDER) {
        return action.payload;
    }
    else if (action.type === SET_LAST_EXCHANGE) {
        return __assign(__assign({}, state), { exareInfo: __assign(__assign({}, state.exareInfo), { lastExchange: action.payload }) });
    }
    return state;
};
