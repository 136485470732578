import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { memo } from 'react';
import cn from 'classnames';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { CardType } from '../types';
import { useTheme } from '../../theme';
import { useConfig } from '../../context';
import { useRenderers } from '../../renderProps';
initI18n('PromoLoader');
var defaultPromoLoaders = [
    {
        type: CardType.Seats,
        title: 'Comfort on board',
        text: 'We want to make sure your travel experience is as fun and smooth as possible also when travelling as a family. All the way from planning your trip to arriving at the airport and enjoying your time in the air, we pay special attention to your comfort.',
        image: 'https://cdn.websky.aero/content/frontend/images/banner-comfort.jpg'
    },
    {
        type: CardType.Meal,
        title: 'Meal',
        text: 'Every flight is an opportunity to relax and replenish. On our local flights by Russia and flights to CIS, we serve a diverse selection of food, snacks and beverages, tailored to the time and length of your flight.',
        image: 'https://cdn.websky.aero/content/frontend/images/banner-meal.jpg'
    },
    {
        type: CardType.Aircraft,
        title: 'Fleet',
        text: 'The fleet consists of over 30 aircraft. Our fleet is one the most modern in country, and thanks to it can offer you even better travel comfort on our flights.',
        image: 'https://cdn.websky.aero/content/frontend/images/banner-fleet-default.jpg'
    },
    {
        type: CardType.WiFi,
        title: 'Wi-Fi Connect',
        text: 'Flying is not a reason to refuse to communicate with family and colleagues. Wi-Fi internet on board is a unique portal to the world of entertainment and information in flight.',
        image: 'https://cdn.websky.aero/content/frontend/images/banner-wifi.jpg'
    }
];
var CheckedIcon = (React.createElement("svg", { width: "64", height: "64", viewBox: "0 0 64 64", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "32", cy: "32", r: "32", fill: "currentColor" }),
    React.createElement("path", { d: "M18.5 34.6646L26.836 43L47.0005 22", stroke: "white", strokeWidth: "5", strokeLinecap: "round" })));
var PromoLoader = memo(function (_a) {
    var _b, _c;
    var type = _a.type, innerPromoLoader = _a.innerPromoLoader, text = _a.text;
    var css = useTheme('PromoLoader').PromoLoader;
    var t = useTranslation('PromoLoader').t;
    var promoLoaders = useConfig().Engine.promoLoaders;
    var renderPromoLoaderContent = useRenderers().renderPromoLoaderContent;
    // if renderer passed, do not calculate card
    if (renderPromoLoaderContent) {
        return renderPromoLoaderContent({ type: type, innerPromoLoader: innerPromoLoader });
    }
    var cards = (promoLoaders === null || promoLoaders === void 0 ? void 0 : promoLoaders.length) ? promoLoaders : defaultPromoLoaders;
    var card = type ? cards.find(function (card) { return card.type === type; }) : cards[Math.floor(Math.random() * cards.length)];
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {}, _b[css.promo_inner] = innerPromoLoader, _b)) },
        React.createElement(LinearProgress, { variant: "indeterminate", classes: {
                barColorPrimary: css.progress__bar__color,
                colorPrimary: css.progress__color
            } }),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: css.loader__wrp },
                React.createElement(CircularProgress, { classes: { colorPrimary: css.loader__color } }))),
        React.createElement("div", { className: css.text }, text !== null && text !== void 0 ? text : "".concat(t('One moment please'), "...")),
        React.createElement("div", { className: css.promo },
            card.city && React.createElement("h4", { className: css.card__city }, t(card.city)),
            React.createElement("div", { className: cn(css.promo__image, (_c = {}, _c[css.withoutContent] = !card.title || !card.text, _c)), style: { backgroundImage: "url(".concat(card.image, ")") } },
                React.createElement("span", { className: css.promo__icon }, CheckedIcon)),
            (card.title || card.text) && (React.createElement("div", { className: css.promo__content },
                React.createElement("h2", null, t(card.title)),
                React.createElement("div", null, t(card.text)))))));
});
export default PromoLoader;
