import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../Modal';
import { useTheme } from '../../../../../theme';
import { useIsMobile } from '../../../../../utils';
import { BaseButton } from '../../../../../index';
var SureModal = function (_a) {
    var _b, _c;
    var title = _a.title, description = _a.description, cancelText = _a.cancelText, confirmText = _a.confirmText, onConfirm = _a.onConfirm, hideCancel = _a.hideCancel, props = __rest(_a, ["title", "description", "cancelText", "confirmText", "onConfirm", "hideCancel"]);
    var css = useTheme('SeatMap').SureModal;
    var t = useTranslation('SeatMap').t;
    var isMobile = useIsMobile();
    return (React.createElement(Modal, __assign({ maxWidth: "xs", classes: __assign(__assign({}, props.classes), { scrollBody: cn(css.scrollBody, (_b = props.classes) === null || _b === void 0 ? void 0 : _b.scrollBody), paper: cn(css.paper, (_c = props.classes) === null || _c === void 0 ? void 0 : _c.paper) }), slideUp: isMobile }, props),
        title && React.createElement("div", { className: css.title }, title),
        React.createElement("div", { className: css.description }, description),
        React.createElement("div", { className: css.footer },
            !hideCancel && (React.createElement(BaseButton, { className: css.button, onClick: props.onClose }, cancelText || t('Cancel'))),
            React.createElement(BaseButton, { className: css.button, onClick: onConfirm }, confirmText || t('Confirm')))));
};
export default SureModal;
