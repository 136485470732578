import * as DayStyles from './components/Month/Day/Day.css';
import * as MonthsListStyles from './components/MonthsList/MonthsList.css';
import * as LegendsStyles from './components/Legends/Legends.css';
import * as PriceGraphStyles from './components/PriceGraph.css';
import * as SliderStyles from './components/Slider/Slider.css';
export var defaultTheme = {
    PriceGraphStyles: PriceGraphStyles,
    DayStyles: DayStyles,
    MonthsListStyles: MonthsListStyles,
    LegendsStyles: LegendsStyles,
    SliderStyles: SliderStyles
};
export default defaultTheme;
