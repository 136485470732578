import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { isImageValid } from './utils';
import { Log } from '../sentry';
var ImageWithFallback = function (_a) {
    var src = _a.src, fallbackSrc = _a.fallbackSrc, _b = _a.alt, alt = _b === void 0 ? '' : _b, props = __rest(_a, ["src", "fallbackSrc", "alt"]);
    var _c = __read(React.useState(false), 2), isError = _c[0], setIsError = _c[1];
    var onLoad = function () {
        if (isError) {
            setIsError(true);
        }
    };
    var getImgRef = function (img) {
        if ((img === null || img === void 0 ? void 0 : img.complete) && (img.naturalWidth || img.naturalHeight)) {
            onLoad();
        }
    };
    React.useEffect(function () {
        var isCurrentSrc = true;
        isImageValid(src).then(function (isValid) {
            if (!isValid && isCurrentSrc) {
                setIsError(true);
                Log({
                    category: 'ImageWithFallback',
                    level: 'warning',
                    message: src
                });
            }
        });
        return function () {
            isCurrentSrc = false;
        };
    });
    React.useEffect(function () {
        onLoad();
    }, [src]);
    if (isError && !fallbackSrc) {
        return null;
    }
    return (React.createElement("img", __assign({ ref: getImgRef }, props, (isError && fallbackSrc ? { src: fallbackSrc } : { src: src, onLoad: onLoad }), { alt: alt })));
};
export default ImageWithFallback;
