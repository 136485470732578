import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { startOfWeek } from 'date-fns';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../theme';
import { Datepicker } from '../../../Datepicker/components/Datepicker';
import { getWeekDatesIntervalOfDate } from '../../utils';
var Weekpicker = function (_a) {
    var _b, _c;
    var className = _a.className, date = _a.date, availableDates = _a.availableDates, onSelect = _a.onSelect, props = __rest(_a, ["className", "date", "availableDates", "onSelect"]);
    var css = useTheme('CoreSchedule').Weekpicker;
    var _d = __read(React.useState(), 2), hoverDate = _d[0], setHoverDate = _d[1];
    var datepickerTheme = useTheme('Datepicker');
    var theme = {
        Datepicker: __assign(__assign({}, datepickerTheme), { PopupDatepickerStyles: __assign(__assign({}, datepickerTheme.PopupDatepickerStyles), { block: cn(datepickerTheme.PopupDatepickerStyles.block, className) }), MonthsWrapperStyles: __assign(__assign({}, datepickerTheme.MonthsWrapperStyles), { wrapper: cn(datepickerTheme.MonthsWrapperStyles.wrapper, css.months__wrapper), nav_prev: cn(datepickerTheme.MonthsWrapperStyles.nav_prev, css.months__prev), nav_next: cn(datepickerTheme.MonthsWrapperStyles.nav_next, css.months__next), monthsList: cn(datepickerTheme.MonthsWrapperStyles.monthsList, css.months__list) }), MonthHeaderStyles: __assign(__assign({}, datepickerTheme.MonthHeaderStyles), { monthHeader: cn(datepickerTheme.MonthHeaderStyles.monthHeader, css.month__header) }), MonthStyles: __assign(__assign({}, datepickerTheme.MonthStyles), { month: cn(datepickerTheme.MonthStyles.month, css.month), week: cn(datepickerTheme.MonthStyles.week, css.month__week) }), WeeklyHeaderStyles: __assign(__assign({}, datepickerTheme.WeeklyHeaderStyles), { day: cn(datepickerTheme.WeeklyHeaderStyles.day, css.header__day) }), DayStyles: __assign(__assign({}, datepickerTheme.DayStyles), { day: cn(datepickerTheme.DayStyles.day, css.day__day), day_today: cn(datepickerTheme.DayStyles.day_today, css.day__today), day_selected: cn(datepickerTheme.DayStyles.day_selected, css.day__selected), day_weekend: cn(datepickerTheme.DayStyles.day_weekend, css.day__weekend), day_startPeriod: cn(datepickerTheme.DayStyles.day_startPeriod, css.day__start), day_endPeriod: cn(datepickerTheme.DayStyles.day_endPeriod, css.day__end), day_inPeriod: cn(datepickerTheme.DayStyles.day_inPeriod, css.day__inPeriod), day__inside: cn(datepickerTheme.DayStyles.day_today, css.day__inside) }) })
    };
    var selectedDates = getWeekDatesIntervalOfDate(date);
    var hoverDates = hoverDate ? getWeekDatesIntervalOfDate(hoverDate) : [];
    var onSelectHandler = function (date) {
        onSelect(startOfWeek(date, { weekStartsOn: 1 }));
    };
    return (React.createElement(ThemeProvider, { value: theme },
        React.createElement(Datepicker, __assign({}, props, { openDate: date, minDate: startOfWeek(new Date(), { weekStartsOn: 1 }), selectedDates: selectedDates, highlightedDates: __assign((_b = {}, _b[css.highlightDate] = availableDates, _b), (hoverDates.length ? (_c = {}, _c[css.hoverDate] = hoverDates, _c) : {})), onSelect: onSelectHandler, onHover: setHoverDate }))));
};
export default Weekpicker;
