import * as React from 'react';
import RequestComponent from '../Request/Request';
import RequestForm from './RequestForm';
import { useSelector } from 'react-redux';
import { isOpenedExchange } from '../../../store/order/selectors';
import { ExchangeStep } from '../../../types';
import { OverrideComponent } from '../../../../../renderProps';
var Request = function (_a) {
    var successPayment = _a.successPayment, clearSelectedFares = _a.clearSelectedFares, setExchangeSuccess = _a.setExchangeSuccess, setStep = _a.setStep, refreshOrder = _a.refreshOrder, newFlightId = _a.newFlightId, goToOrder = _a.goToOrder;
    var openedExchange = useSelector(isOpenedExchange);
    var goToPayment = function () {
        setStep(ExchangeStep.Payment);
    };
    var goToNewRequest = function () {
        setStep(ExchangeStep.Passengers);
    };
    var goToFlights = function () {
        clearSelectedFares();
        setStep(ExchangeStep.NewFlights);
    };
    return newFlightId && !successPayment && !openedExchange ? (React.createElement(OverrideComponent, { componentProps: {
            goToPayment: goToPayment,
            goToFlights: goToFlights,
            setExchangeSuccess: setExchangeSuccess,
            refreshOrder: refreshOrder,
            clearSelectedFares: clearSelectedFares,
            flightId: newFlightId
        }, component: { renderExchangeRequest: RequestComponent } })) : (React.createElement(RequestForm, { goToNewRequest: goToNewRequest, goToPayment: goToPayment, goToOrder: goToOrder }));
};
export default Request;
