import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Switch from '../../../../Switch';
import { useTheme } from '../../../../theme';
import { useToggleable } from '../../../../hooks';
import SureModal from './SureModal/SureModal';
import Tooltip from '../../../../Tooltip';
import { InfoDetails } from '../../../../Icons';
import { TABLET_MIDDLE_WIDTH } from '../../../../utils';
import { useMediaQuery } from 'react-responsive';
var DoubleSeatIcon = (React.createElement("svg", { width: "20", height: "17", viewBox: "0 0 20 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.6667 1.50033C10.6667 0.763948 11.2637 0.166992 12.0001 0.166992H18.6667C19.403 0.166992 20 0.763946 20 1.50033V10.1671H10.6667V1.50033ZM0 1.50034C0 0.763962 0.596954 0.167007 1.33333 0.167007H7.99995C8.73633 0.167007 9.33328 0.76396 9.33328 1.50034V10.1671H0V1.50034ZM1 10.8337C0.447715 10.8337 0 11.2814 0 11.8337V13.5004H9.33328V11.8337C9.33328 11.2814 8.88556 10.8337 8.33328 10.8337H1ZM1.33334 14.8339H4V13.5007H5.33332V14.8339H7.99997V16.1672H5.33332V16.1674H4V16.1672H1.33334V14.8339ZM14.6668 14.8339H12.0001V16.1672H14.6668V16.1674H16.0001V16.1672H18.6667V14.8339H16.0001V13.5007H14.6668V14.8339ZM10.6667 11.8337C10.6667 11.2814 11.1144 10.8337 11.6667 10.8337H19C19.5523 10.8337 20 11.2814 20 11.8337V13.5004H10.6667V11.8337ZM12.0001 3.16703H19.0001V4.16704H12.0001V3.16703ZM1.00001 3.16707H8.00006V4.16707H1.00001V3.16707Z", fill: "currentColor" })));
var DoubleSeat = function (_a) {
    var className = _a.className, description = _a.description, isActive = _a.isActive, hasSeatsByPassenger = _a.hasSeatsByPassenger, _b = _a.showInfo, showInfo = _b === void 0 ? true : _b, toggleExtraSeat = _a.toggleExtraSeat;
    var css = useTheme('SeatMap').DoubleSeat;
    var t = useTranslation('SeatMap').t;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var _d = useToggleable(false), isOpenInfo = _d.isOpen, openInfo = _d.open, closeInfo = _d.close;
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var toggle = function () {
        if (isOpenInfo) {
            closeInfo();
        }
        if (hasSeatsByPassenger) {
            open();
        }
        else {
            toggleExtraSeat(!isActive);
        }
    };
    var onConfirm = function () {
        close();
        toggleExtraSeat(!isActive);
    };
    var onClickSwitch = function () {
        if (!isActive && isTablet && showInfo) {
            openInfo();
        }
        else {
            toggle();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(className, css.container) },
            React.createElement("div", { className: css.switch__wrapper },
                React.createElement(Switch, { mainClassName: css.switch__control, labelClassName: css.switch__label, activeClassName: css.switch__active, label: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: css.switch__icon }, DoubleSeatIcon),
                        t('Double seat')), isActive: isActive, switchPosition: "right", onClick: onClickSwitch })),
            description && (React.createElement("div", { className: css.description },
                description,
                ' ',
                React.createElement(Tooltip, { title: t('When you select this service, it will be automatically applied to all flights of your route') },
                    React.createElement("span", { className: css.switch__infoIcon }, InfoDetails))))),
        React.createElement(SureModal, { open: isOpen, title: t('Selected seats will be deleted'), description: t(!isActive
                ? 'When choosing the «Double Seat» service, the seats previously added by the passenger will be deleted'
                : 'When the «Double Seat» service is disabled, the seats added by the passenger earlier will be deleted'), onConfirm: onConfirm, onClose: close, hideCancel: true }),
        React.createElement(SureModal, { open: isOpenInfo, title: t('The service applies to all flights'), description: t('When you select this service, it will be automatically applied to all flights of your route'), onConfirm: toggle, onClose: closeInfo, hideCancel: true })));
};
export default DoubleSeat;
