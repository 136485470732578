import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import { useCallback } from 'react';
var Link = function (_a) {
    var action = _a.action, className = _a.className, children = _a.children, target = _a.target;
    var css = useTheme('Link').Link;
    var onClick = typeof action === 'function'
        ? useCallback(function (e) {
            e.preventDefault();
            action(e);
        }, [action])
        : null;
    return (React.createElement("a", { target: target, className: cn(css.link, className), href: typeof action === 'string' ? action : '#', onClick: onClick }, children));
};
export default Link;
