import { __read } from "tslib";
import { useCallback, useState } from 'react';
export var useClarifyDocumentModal = function () {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var _b = __read(useState(null), 2), searchParams = _b[0], setSearchParams = _b[1];
    var close = useCallback(function () {
        setOpen(false);
    }, [isOpen]);
    var open = useCallback(function () {
        setOpen(true);
    }, [isOpen]);
    var needToShowClarifyModal = function (error) {
        return error.message.includes('There are more than one passengers with family');
    };
    return { isOpen: isOpen, open: open, close: close, needToShowClarifyModal: needToShowClarifyModal, searchParams: searchParams, setSearchParams: setSearchParams };
};
