import { useSelector } from 'react-redux';
import { getIsTravellerDataFilled } from '../../../../../store/order/selectors';
import { useToggleable } from '../../../../../../../hooks';
import { getIsCheckinTravellersDataFilledFromLocalStorage, setIsCheckinTravellersDataFilledToLocalStorage } from '../../../../../../../cache';
export var useCheckinAddDocumentsService = function (orderId) {
    var isTravellersDataFilled = useSelector(getIsTravellerDataFilled);
    var _a = useToggleable(false), isOpen = _a.isOpen, setOpen = _a.setOpen;
    var onDocumentsUpdated = function () {
        setOpen(false);
        setIsCheckinTravellersDataFilledToLocalStorage(orderId, true);
    };
    var isNeedToOpen = false;
    if (!!getIsCheckinTravellersDataFilledFromLocalStorage(orderId)) {
        isNeedToOpen = false;
    }
    else {
        isNeedToOpen = !isTravellersDataFilled;
    }
    return {
        setDataFilled: setIsCheckinTravellersDataFilledToLocalStorage,
        onDocumentsUpdated: onDocumentsUpdated,
        isNeedToOpen: isNeedToOpen,
        isOpen: isOpen,
        setOpen: setOpen,
        isTravellersDataFilled: isTravellersDataFilled
    };
};
