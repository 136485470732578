import { __assign, __read, __spreadArray } from "tslib";
import { createSelector } from 'reselect';
// import { getBaggageServices, getIsInsuranceSelected, getIsTransferSelected, getServices } from '../order/selectors';
import { getIsInsuranceSelected, getIsTransferSelected } from '../order/selectors';
import { ServiceType } from '../../components/Checkout/OrderPaidServiceCards/types';
import { getPassengerStateCompatible } from '../selectedServices/selectors';
import { capitalizeFirstLetter, getPassengerLabel, getPassengerNames } from '../../../utils';
import { getExtraSeatServices } from '../../../Modules/Checkin/store/order/selectors';
import { OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum as OldTravellerFieldEnum, TravellerFieldEnum as NewTravellerFieldEnum } from '@websky/graphql';
// A workaround to fix cyclic dependencies. Fix this in the future.
var getOrder = function (state) { return state.order; };
var getServices = createSelector(getOrder, function (order) { var _a, _b; return (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) !== null && _b !== void 0 ? _b : []; });
var getBaggageServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; });
});
// Workaround end
export var getPassengerState = function (state) { return state.passengers; };
export var getPassengerLabels = createSelector(getPassengerState, function (passengers) {
    var result = {};
    var passengersMap = {};
    passengers.forEach(function (passenger) {
        var _a;
        var passengerType = (_a = passenger.supplierTravellerType) !== null && _a !== void 0 ? _a : passenger.type;
        if (!passengersMap.hasOwnProperty(passengerType)) {
            passengersMap[passengerType] = 0;
        }
        var passengerIndex = ++passengersMap[passengerType];
        var _b = getPassengerNames(passenger), firstName = _b.firstName, lastName = _b.lastName;
        if (firstName && lastName) {
            result[passenger.id] = [lastName, firstName].map(capitalizeFirstLetter).join(' ');
        }
        else {
            result[passenger.id] = "".concat(getPassengerLabel(passenger), " ").concat(passengerIndex);
        }
    });
    return result;
});
export var getPassengerLastName = function (passenger) {
    var _a;
    return (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var type = _a.type, value = _a.value;
        return type === NewTravellerFieldEnum.LastName && value;
    });
};
export var getPassengerFirstName = function (passenger) {
    var _a;
    return (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var value = _a.value, type = _a.type;
        return type === NewTravellerFieldEnum.FirstName && value;
    });
};
export var getPassengerMiddleName = function (passenger) {
    var _a;
    return (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var value = _a.value, type = _a.type;
        return type === NewTravellerFieldEnum.MiddleName && value;
    });
};
export var getPassengerNationalities = createSelector(getPassengerState, function (passengers) {
    var result = new Map();
    var nationalityField = passengers[0].values.find(function (field) { return field.type === OldTravellerFieldEnum.Nationality; });
    nationalityField &&
        nationalityField.validationRules &&
        nationalityField.validationRules[0].options.forEach(function (option) {
            if (!result.hasOwnProperty(option.value)) {
                result[option.value] = option.label;
            }
        });
    return result;
});
export var makeHasAnySelectedBaggage = function (passengerId) {
    return createSelector(getPassengerStateCompatible, getBaggageServices, function (passengers, baggageServices) {
        return passengers[passengerId].services.some(function (selectedService) {
            return baggageServices.some(function (baggage) { return baggage.id === selectedService.serviceId; });
        });
    });
};
export var createSelectedBaggage = function (passengers, baggageServices) {
    var result = [];
    passengers.forEach(function (_a) {
        var id = _a.id, services = _a.services;
        var selectedBaggage = {};
        services.forEach(function (selectedService) {
            if (baggageServices.some(function (baggage) { return baggage.id === selectedService.serviceId; })) {
                selectedService.segmentIds.forEach(function (segmentId) {
                    if (!selectedBaggage.hasOwnProperty(selectedService.serviceId)) {
                        selectedBaggage[selectedService.serviceId] = {};
                    }
                    if (selectedBaggage[selectedService.serviceId].hasOwnProperty(segmentId)) {
                        selectedBaggage[selectedService.serviceId][segmentId].count += selectedService.count;
                    }
                    else {
                        selectedBaggage[selectedService.serviceId][segmentId] = __assign({}, selectedService);
                    }
                });
            }
        });
        result[id] = selectedBaggage;
    });
    return result;
};
export var makeHasSelectedService = function (type) {
    return createSelector(getServices, getIsInsuranceSelected, getIsTransferSelected, getPassengerStateCompatible, function (orderServices, isInsuranceSelected, isTransferSelected, passengers) {
        return passengers.some(function (passenger) {
            return passenger.services.some(function (travellerService) {
                switch (type) {
                    case ServiceType.Baggage:
                        return orderServices.find(function (orderService) {
                            return orderService.id === travellerService.serviceId &&
                                orderService.type === OrderAdditionalServiceGdsServiceServiceType.Baggage;
                        });
                    case ServiceType.Meal:
                        return orderServices.find(function (orderService) {
                            return orderService.id === travellerService.serviceId &&
                                orderService.type === OrderAdditionalServiceGdsServiceServiceType.Meal;
                        });
                    case ServiceType.Seats:
                        return orderServices.find(function (orderService) {
                            return orderService.id === travellerService.serviceId &&
                                orderService.type === OrderAdditionalServiceGdsServiceServiceType.Seat;
                        });
                    case ServiceType.Insurance:
                        return isInsuranceSelected;
                    case ServiceType.Transfer:
                        return isTransferSelected;
                }
            });
        });
    });
};
export var getSelectedBaggage = createSelector(getPassengerStateCompatible, getBaggageServices, createSelectedBaggage);
export var createSelectedExtraSeats = function (passengers, extraSeatServices) {
    var selectedExtraSeats = [];
    var extraSeatIds = extraSeatServices.map(function (service) { return service.id; });
    passengers.forEach(function (passenger) {
        var _a;
        (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.forEach(function (service) {
            if (extraSeatIds.includes(service.serviceId)) {
                selectedExtraSeats = __spreadArray(__spreadArray([], __read(selectedExtraSeats), false), [
                    __assign(__assign({}, service), { passengerId: passenger.id })
                ], false);
            }
        });
    });
    return selectedExtraSeats;
};
export var getSelectedExtraSeats = createSelector(getPassengerStateCompatible, getExtraSeatServices, createSelectedExtraSeats);
