import * as React from 'react';
export var arrowIcon = (React.createElement("svg", { width: "25", height: "24", viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M7.5 10L12.5 15L17.5 10H7.5Z", fill: "currentColor" })));
export var infoIcon = (React.createElement("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.333008 7.00016C0.333008 3.32016 3.31967 0.333496 6.99967 0.333496C10.6797 0.333496 13.6663 3.32016 13.6663 7.00016C13.6663 10.6802 10.6797 13.6668 6.99967 13.6668C3.31967 13.6668 0.333008 10.6802 0.333008 7.00016ZM6.99967 6.3335C7.36786 6.3335 7.66634 6.63197 7.66634 7.00016V9.66683C7.66634 10.035 7.36786 10.3335 6.99967 10.3335V10.3335C6.63148 10.3335 6.33301 10.035 6.33301 9.66683V7.00016C6.33301 6.63197 6.63148 6.3335 6.99967 6.3335V6.3335ZM6.99967 12.3335C4.05967 12.3335 1.66634 9.94016 1.66634 7.00016C1.66634 4.06016 4.05967 1.66683 6.99967 1.66683C9.93967 1.66683 12.333 4.06016 12.333 7.00016C12.333 9.94016 9.93967 12.3335 6.99967 12.3335ZM6.99967 3.66683C7.36786 3.66683 7.66634 3.96531 7.66634 4.3335V4.3335C7.66634 4.70169 7.36786 5.00016 6.99967 5.00016V5.00016C6.63148 5.00016 6.33301 4.70169 6.33301 4.3335V4.3335C6.33301 3.96531 6.63148 3.66683 6.99967 3.66683V3.66683Z", fill: "#CDCDCD" })));
export var plusIcon = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M14.25 9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25V3.75H9.75V8.25H14.25V9.75Z", fill: "currentColor" })));
export var mobilePlusIcon = (React.createElement("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.5 7.5H0.5V6.5H13.5V7.5Z" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.5 0.5L7.5 13.5H6.5L6.5 0.5H7.5Z" })));
export var minusIcon = (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M14.25 9.75H3.75V8.25H14.25V9.75Z", fill: "currentColor" })));
export var mobileMinusIcon = (React.createElement("svg", { width: "13", height: "2", viewBox: "0 0 13 2", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13 1.5H0V0.5H13V1.5Z" })));
export var passengerIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 12C13.66 12 15 10.66 15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z", fill: "white" }))));
