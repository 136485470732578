import * as React from 'react';
import cn from 'classnames';
import Button from '../../../../../Button';
import { useTheme } from '../../../../../theme';
import { Add, Minus } from '../../../../../Icons';
var Actions = React.memo(function (_a) {
    var _b, _c, _d;
    var canRemoveSegment = _a.canRemoveSegment, showAddSegment = _a.showAddSegment, showRemoveSegment = _a.showRemoveSegment, onAddSegment = _a.onAddSegment, removeLastSegment = _a.removeLastSegment, isAlternativeView = _a.isAlternativeView;
    var css = useTheme('SearchForm').Actions;
    return (React.createElement("div", { className: cn(css.actions, (_b = {},
            _b[css.actions_alternative] = isAlternativeView,
            _b)) },
        React.createElement(Button, { className: cn(css.addSegment, (_c = {},
                _c[css.addSegment_alternative] = isAlternativeView,
                _c)), onClick: showAddSegment ? onAddSegment : null, disabled: !showAddSegment }, Add),
        canRemoveSegment && (React.createElement(Button, { className: cn(css.removeSegment, (_d = {},
                _d[css.removeSegment_alternative] = isAlternativeView,
                _d)), onClick: removeLastSegment, disabled: !showRemoveSegment }, Minus))));
});
export default Actions;
