import { __assign, __read } from "tslib";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '../../../theme';
import { nextStep, prevStep, setStep } from '../../store/steps/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getCanGoToNextStep, getSteps } from '../../store/steps/selectors';
import { isActiveStepAfter } from './utils';
import { setRefundInfo } from '../../store/refundInfo/actions';
import { useGetRefundDocumentsQuery, useCreateRefundOrderMutation, useGetLastRefundQuery } from '@websky/graphql';
import { useConfig } from '../../../context';
import { useTranslation } from 'react-i18next';
export var useRefundInfo = function (options) {
    var _a = __read(useState({}), 2), docs = _a[0], setDocs = _a[1];
    var _b = __read(useState(null), 2), prices = _b[0], setPrices = _b[1];
    var dispatch = useDispatch();
    var _c = useGetRefundDocumentsQuery(__assign({}, options)), data = _c.data, loading = _c.loading, error = _c.error, refetch = _c.refetch;
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.GetRefundInfo) {
            dispatch(setRefundInfo(data === null || data === void 0 ? void 0 : data.GetRefundInfo));
        }
    }, [data, dispatch]);
    var reasons = useMemo(function () {
        var _a, _b, _c;
        if (!data) {
            return [];
        }
        var filterReasonsWithoutDocs = (_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.confirmationDocsAreRequired;
        return (_c = (_b = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _b === void 0 ? void 0 : _b.unvoluntaryReasonsInfo) === null || _c === void 0 ? void 0 : _c.map(function (reason) {
            var _a;
            if (filterReasonsWithoutDocs) {
                if (!((_a = reason === null || reason === void 0 ? void 0 : reason.docs) === null || _a === void 0 ? void 0 : _a.length)) {
                    return null;
                }
            }
            return {
                title: reason.reason.toString(),
                value: reason.reason
            };
        }).filter(Boolean);
    }, [data]);
    useEffect(function () {
        var _a, _b;
        (_b = (_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.unvoluntaryReasonsInfo) === null || _b === void 0 ? void 0 : _b.forEach(function (reason) {
            var _a;
            var _b, _c;
            docs[reason.reason.toString()] = (_b = reason === null || reason === void 0 ? void 0 : reason.docs) === null || _b === void 0 ? void 0 : _b[0];
            setDocs(__assign(__assign({}, docs), (_a = {}, _a[reason.reason.toString()] = (_c = reason === null || reason === void 0 ? void 0 : reason.docs) === null || _c === void 0 ? void 0 : _c[0], _a)));
        });
    }, [reasons]);
    useEffect(function () {
        var _a;
        if (!prices && ((_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.prices)) {
            setPrices(data.GetRefundInfo.prices);
        }
    }, [data]);
    return {
        data: data,
        loading: loading,
        error: error,
        refetch: refetch,
        reasons: reasons,
        docks: docs,
        prices: prices
    };
};
export var useMobileStepsPositionClassNames = function () {
    var css = useTheme('Refund').Refund;
    var setClassesToPassedSteps = function () {
        var passedSteps = Array.from(document.getElementsByClassName(css.passed));
        var firstPassedStep = passedSteps[0];
        passedSteps.forEach(function (passedStep, index) {
            if (index > 0) {
                firstPassedStep.classList.add(css.withoutBottomBorderRadius);
            }
            if (index !== 0) {
                passedStep.classList.add(css.withoutTopBorderRadius);
                passedStep.classList.add(css.withTopBorder);
                if (index !== passedSteps.length - 1) {
                    passedStep.classList.add(css.withoutBottomBorderRadius);
                }
            }
        });
    };
    return { setClassesToPassedSteps: setClassesToPassedSteps };
};
export var useCreateRefundOrder = function () {
    return useCreateRefundOrderMutation({ fetchPolicy: 'no-cache' });
};
export var useLastRefundQuery = function (orderId) {
    var _a, _b;
    if (!orderId) {
        return null;
    }
    var _c = useGetLastRefundQuery({
        variables: { orderId: orderId },
        fetchPolicy: 'no-cache'
    }), loading = _c.loading, data = _c.data;
    return {
        loading: loading,
        data: (_b = (_a = data === null || data === void 0 ? void 0 : data.Order) === null || _a === void 0 ? void 0 : _a.exareInfo) === null || _b === void 0 ? void 0 : _b.lastRefund
    };
};
export var useSteps = function () {
    var dispatch = useDispatch();
    var currentStep = useSelector(getActiveStep);
    var canGoToNextStep = useSelector(getCanGoToNextStep);
    var steps = useSelector(getSteps);
    var goToNextStep = useCallback(function () { return dispatch(nextStep()); }, [dispatch, currentStep]);
    var goToPrevStep = useCallback(function () { return dispatch(prevStep()); }, [dispatch, currentStep]);
    var isCurrentActiveStepAfter = isActiveStepAfter.bind(null, steps);
    var handleMobileCloseClick = useCallback(function (stepType) {
        var isStepPassed = isCurrentActiveStepAfter(stepType);
        return isStepPassed
            ? function () {
                return dispatch(setStep({
                    type: stepType,
                    isActive: true,
                    additionalText: ''
                }));
            }
            : null;
    }, [currentStep]);
    return {
        currentStep: currentStep,
        goToNextStep: goToNextStep,
        goToPrevStep: goToPrevStep,
        canGoToNextStep: canGoToNextStep,
        isCurrentActiveStepAfter: isCurrentActiveStepAfter,
        handleMobileCloseClick: handleMobileCloseClick
    };
};
export var useHtmlDescriptionOfError = function (error) {
    var _a = useConfig().Engine, checkInURL = _a.checkInURL, checkInEnabled = _a.checkInEnabled;
    var t = useTranslation('Refund').t;
    var header = null, body = null;
    var message = typeof error === 'string' ? error : error === null || error === void 0 ? void 0 : error.message;
    if (!(message === null || message === void 0 ? void 0 : message.length)) {
        return null;
    }
    if (message.includes('Refund operations with checked segments are not permitted')) {
        header = t('It is not possible to refund a ticket that has been checked-in for a flight');
        body = "".concat(t('If you wish to make a refund, please cancel your flight check-in.'), " ").concat(checkInEnabled
            ? t("You can do it yourself by going to <a href='{{link}}' target='_blank'>Online Registration section</a>.", { link: checkInURL })
            : '');
    }
    else if (message.includes('Refund operations with used segments are not permitted')) {
        header = t('It is not possible to refund a used ticket');
        body = t('The ticket has been flown on, you cannot return a used ticket.');
    }
    else if (message.includes('Refund operation allowed only in manual mode')) {
        header = t('To make an exchange please contact a customer support service');
    }
    else {
        return null;
    }
    return {
        header: header,
        body: body
    };
};
