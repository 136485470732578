import React from 'react';
import { initI18n } from '../../../../../../utils';
import ModalConfirm from '../../../../../../ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
initI18n('Checkout');
var PassengersBlockedModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    var t = useTranslation('SearchForm').t;
    var theme = useTheme('SearchForm').PassengersBlockedModal;
    return (React.createElement(ModalConfirm, { classes: theme, isOpen: isOpen, onClose: onClose, onConfirm: onConfirm, onCancel: onCancel, title: t('Restrictions'), confirmBtn: t('Continue'), cancelBtn: t('Cancel') },
        React.createElement("div", { className: theme.contentWrapper }, t('When using miles, bookings for some categories will not be available'))));
};
export default PassengersBlockedModal;
