import * as React from 'react';
import { isEqual, parseISO } from 'date-fns';
import { getDaysFromMonth } from '../../utils';
import { Day } from './Day/Day';
import { MonthsPrices } from '../PriceGraph';
export var Month = function (props) {
    var isoInitialDate = props.initialDate.toISOString(), monthsPrices = React.useContext(MonthsPrices);
    return (React.createElement(React.Fragment, null, getDaysFromMonth(props.initialDate).map(function (date, index) {
        var _a, _b;
        var dateISO = date.toISOString();
        var price = (_b = (monthsPrices.prices.hasOwnProperty(dateISO) && ((_a = monthsPrices.prices[dateISO]) === null || _a === void 0 ? void 0 : _a.price))) !== null && _b !== void 0 ? _b : {
            amount: 0,
            currency: monthsPrices.monthsPricesMap[isoInitialDate].maxPrice
                ? monthsPrices.monthsPricesMap[isoInitialDate].maxPrice.currency
                : null
        };
        return (React.createElement(Day, { key: index, minPrice: monthsPrices.monthsPricesMap[isoInitialDate].minPrice, maxPrice: monthsPrices.monthsPricesMap[isoInitialDate].maxPrice, price: price, date: date, isOutDate: props.outDate && dateISO === props.outDate.toISOString(), isBackDate: props.backDate && dateISO === props.backDate.toISOString(), routeType: props.routeType, onSelect: props.onSelect, isFocused: props.focusedDate && isEqual(parseISO(props.focusedDate), date), isMobileMode: props.isMobileMode }));
    })));
};
