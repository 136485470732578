import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useEffect } from 'react';
import { useTheme } from '../../../../theme';
export var Switch = function (props) {
    var _a;
    var _b = __read(React.useState(props.value ? props.value : ''), 2), selectedOption = _b[0], setSelectedOption = _b[1];
    var theme = useTheme('Passenger').Switch;
    var selectOption = function (event) {
        setSelectedOption(event.target.value);
        props.onChange && props.onChange(event.target.value, event);
        props.onBlur && props.onBlur();
    };
    useEffect(function () {
        setSelectedOption(props.value || '');
    }, [props.value]);
    return (React.createElement("div", { className: cn(theme.container, props.className, (_a = {},
            _a[theme.disabled] = props.disabled,
            _a)) },
        props.options.map(function (option, index) {
            var _a;
            return (React.createElement("label", { key: index, "data-test-id": "element", className: cn(theme.element, (_a = {},
                    _a[theme.element_active] = selectedOption === option.key,
                    _a[theme.element_error] = props.error,
                    _a)) },
                React.createElement("input", { key: index, type: "radio", name: props.name, value: option.key, onChange: selectOption, checked: selectedOption === option.key, disabled: props.disabled }),
                option.icon));
        }),
        props.error && React.createElement("p", { className: theme.error }, props.error),
        React.createElement("input", { type: "radio", name: props.name, value: "", onChange: selectOption, checked: selectedOption === '', style: { display: 'none' }, disabled: props.disabled }),
        React.createElement("select", { style: { display: 'none' }, name: props.name, value: selectedOption, onChange: function () { } }, props.options.map(function (option, index) { return (React.createElement("option", { key: index, value: option.key }, option.value)); }))));
};
