import React from 'react';
import { useTheme } from '../../../theme';
import Loader from '../../../Loader';
import WarningModal from '../../../WarningModal';
import { useTranslation } from 'react-i18next';
export var CommunicationDetailPageWrapper = function (props) {
    var css = useTheme('Communications').CommunicationDetailPage;
    var t = useTranslation('Communications').t;
    return (React.createElement("div", { className: css.communicationPage },
        props.loading && React.createElement(Loader, null),
        props.title && React.createElement("h1", { className: css.title }, props.title),
        props.body && (React.createElement("div", { className: css.communicationPage__content, dangerouslySetInnerHTML: { __html: props.body } })),
        React.createElement(WarningModal, { isOpen: props.error, title: t('Oops'), content: t('Something went wrong') })));
};
