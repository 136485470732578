import * as React from 'react';
export var SeatIcon = (React.createElement("svg", { width: "72", height: "72", viewBox: "0 0 72 72", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "72", height: "72", fill: "white" }),
    React.createElement("path", { d: "M48.0175 13.0808C48.0175 9.97525 45.6596 7.30444 42.5572 7.30444H35.7318H29.0305C25.928 7.30444 23.5701 9.97525 23.5701 13.0808L19.7852 38.733C19.7852 41.8386 22.3292 44.5094 25.4937 44.5094H35.7938H46.2181C49.3205 44.5094 51.9266 41.8386 51.9266 38.733L48.0175 13.0808Z", fill: "#CFE83D" }),
    React.createElement("path", { d: "M19.785 66C17.6753 66 16 64.3229 16 62.2111V42.3975C16 40.2857 17.6753 38.6086 19.785 38.6086C21.8947 38.6086 23.57 40.2857 23.57 42.3975V62.2111C23.57 64.3229 21.8326 66 19.785 66Z", fill: "#32597C" }),
    React.createElement("path", { d: "M51.4295 66C49.3199 66 47.6445 64.3229 47.6445 62.2111V42.3975C47.6445 40.2857 49.3199 38.6086 51.4295 38.6086C53.5392 38.6086 55.2145 40.2857 55.2145 42.3975V62.2111C55.2145 64.3229 53.5392 66 51.4295 66Z", fill: "#32597C" }),
    React.createElement("path", { d: "M39.9513 17.118H31.6367C29.4029 17.118 27.6035 15.3168 27.6035 13.0807V10.0373C27.6035 7.80124 29.4029 6 31.6367 6H39.8892C42.123 6 43.9224 7.80124 43.9224 10.0373V13.0807C43.9845 15.3168 42.123 17.118 39.9513 17.118Z", fill: "#EDEDED" }),
    React.createElement("path", { d: "M47.6444 47.3044H23.5693V55.379H47.6444V47.3044Z", fill: "#CFE83D" }),
    React.createElement("path", { d: "M51.4298 50.1615C50.499 50.1615 49.6924 49.354 49.6924 48.4223V43.205C49.6924 42.2733 50.499 41.4658 51.4298 41.4658C52.3605 41.4658 53.1671 42.2733 53.1671 43.205V48.4223C53.1671 49.354 52.3605 50.1615 51.4298 50.1615Z", fill: "white" }),
    React.createElement("path", { d: "M19.7852 50.1615C18.8545 50.1615 18.0479 49.354 18.0479 48.4223V43.205C18.0479 42.2733 18.8545 41.4658 19.7852 41.4658C20.716 41.4658 21.5226 42.2733 21.5226 43.205V48.4223C21.5226 49.354 20.716 50.1615 19.7852 50.1615Z", fill: "white" }),
    React.createElement("path", { d: "M41.5017 44.5093H30.0847C27.6648 44.5093 25.6172 42.5217 25.6172 40.0372C25.6172 37.6149 27.6028 35.5652 30.0847 35.5652H41.5017C43.9217 35.5652 45.9693 37.5528 45.9693 40.0372C45.9693 42.4596 43.9837 44.5093 41.5017 44.5093Z", fill: "#B6D43E" })));
