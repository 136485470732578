import { __assign } from "tslib";
var hasCountPropGuard = function (object) {
    return object.hasOwnProperty('count');
};
var BaseDiff = /** @class */ (function () {
    function BaseDiff(orderBeforeChange, orderAfterChange) {
        this.getTravellersServicesDiff = function (beforeChange, afterChange, getId) {
            var added = [];
            var removed = [];
            var beforeChangeMap = new Map();
            beforeChange === null || beforeChange === void 0 ? void 0 : beforeChange.forEach(function (service) { return beforeChangeMap.set(getId(service), service); });
            var afterChangeMap = new Map();
            afterChange === null || afterChange === void 0 ? void 0 : afterChange.forEach(function (service) { return afterChangeMap.set(getId(service), service); });
            afterChange === null || afterChange === void 0 ? void 0 : afterChange.forEach(function (afterChangeService) {
                var beforeChangeService = beforeChangeMap.get(getId(afterChangeService));
                if (!beforeChangeService) {
                    added.push(afterChangeService);
                }
                else if (hasCountPropGuard(afterChangeService) && hasCountPropGuard(beforeChangeService)) {
                    var after = afterChangeService;
                    var before = beforeChangeService;
                    if (after.count > before.count) {
                        added.push(__assign(__assign({}, after), { count: after.count - before.count }));
                    }
                    else if (after.count < before.count) {
                        removed.push(__assign(__assign({}, before), { count: before.count - after.count }));
                    }
                }
            });
            beforeChange === null || beforeChange === void 0 ? void 0 : beforeChange.forEach(function (beforeChangeService) {
                var serviceAfterChange = afterChangeMap.get(getId(beforeChangeService));
                if (!serviceAfterChange) {
                    removed.push(beforeChangeService);
                }
            });
            return { added: added, removed: removed };
        };
        this.orderBeforeChange = orderBeforeChange;
        this.orderAfterChange = orderAfterChange;
    }
    return BaseDiff;
}());
export default BaseDiff;
