import { __assign, __read, __spreadArray } from "tslib";
export var groupServicesByType = function (refundServices) {
    var servicesByType = new Map();
    refundServices.forEach(function (refundService) {
        var serviceType = refundService.service.type;
        servicesByType.set(serviceType, __spreadArray(__spreadArray([], __read((servicesByType.has(serviceType) ? servicesByType.get(serviceType) : [])), false), [
            refundService
        ], false));
    });
    return servicesByType;
};
export var groupRefundServicesByPassenger = function (refundServices) {
    var serviceByPassenger = {};
    refundServices === null || refundServices === void 0 ? void 0 : refundServices.forEach(function (gdsInfo) {
        var service = gdsInfo.service;
        gdsInfo.gdsServiceProductsRefundInfo.forEach(function (product) {
            setProductToGroup(serviceByPassenger, product, service);
        });
    });
    return serviceByPassenger;
};
var setProductToGroup = function (group, product, service) {
    var _a, _b, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l, _m;
    var passengerId = product.product.passengerId, emdNumber = product.product.emdNumber;
    if (group.hasOwnProperty(passengerId)) {
        group[passengerId] = __assign(__assign({}, group[passengerId]), (_a = {}, _a[emdNumber] = {
            services: __assign(__assign({}, (((_e = group[passengerId][emdNumber]) === null || _e === void 0 ? void 0 : _e.services) || {})), (_b = {}, _b[service.id] = {
                service: service,
                products: __spreadArray(__spreadArray([], __read((((_g = (_f = group[passengerId][emdNumber]) === null || _f === void 0 ? void 0 : _f.services) === null || _g === void 0 ? void 0 : _g[service.id].products) || [])), false), [product], false),
                sumToRefund: {
                    amount: (((_k = (_j = (_h = group[passengerId][emdNumber]) === null || _h === void 0 ? void 0 : _h.services) === null || _j === void 0 ? void 0 : _j[service.id].sumToRefund) === null || _k === void 0 ? void 0 : _k.amount) || 0) +
                        ((_l = product.sumToRefund) === null || _l === void 0 ? void 0 : _l.amount),
                    currency: (_m = product.sumToRefund) === null || _m === void 0 ? void 0 : _m.currency
                }
            }, _b))
        }, _a));
    }
    else {
        group[passengerId] = (_c = {},
            _c[emdNumber] = {
                services: (_d = {},
                    _d[service.id] = {
                        service: service,
                        products: [product],
                        sumToRefund: product.sumToRefund
                    },
                    _d)
            },
            _c);
    }
};
export var getProductsToExchange = function (services, segmentIds) {
    if (segmentIds === void 0) { segmentIds = []; }
    var emdMap = new Map();
    var productIdsByEmd = new Map();
    var productIds = [];
    services.forEach(function (service) {
        var isMatchBySegments = !segmentIds.length || segmentIds.find(function (segmentId) { return service.segmentIds.includes(segmentId); });
        service.products.forEach(function (product) {
            if (product.emdNumber) {
                productIdsByEmd.set(product.emdNumber, __spreadArray(__spreadArray([], __read((productIdsByEmd.get(product.emdNumber) || [])), false), [
                    parseInt(product.id)
                ], false));
                if (isMatchBySegments) {
                    service.products.forEach(function (product) {
                        emdMap.set(product.emdNumber, product.emdNumber);
                    });
                }
            }
        });
    });
    emdMap.forEach(function (emd) {
        if (productIdsByEmd.has(emd)) {
            productIds.push.apply(productIds, __spreadArray([], __read(productIdsByEmd.get(emd)), false));
        }
    });
    return __spreadArray([], __read(new Set(productIds)), false);
};
export var getPassengersSelectedProductIds = function (travellers, passengerIds, segmentIds, isAllFlightToExchange) {
    var services = [];
    passengerIds.forEach(function (passengerId) {
        var _a, _b, _c, _d, _e;
        var traveller = travellers.find(function (traveller) { return traveller.id === passengerId; });
        if ((_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) {
            services.push.apply(services, __spreadArray([], __read((_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services), false));
        }
        if ((_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.seats) {
            traveller.services.seats.forEach(function (seat) {
                if (!seat.product) {
                    return;
                }
                services.push({
                    segmentIds: [seat.segment.id],
                    products: [seat.product]
                });
            });
        }
    });
    return getProductsToExchange(services, isAllFlightToExchange ? [] : segmentIds);
};
