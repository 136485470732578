import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { Chevron } from '../../../../../Icons';
import cn from 'classnames';
var LoginTypeSelector = function (_a) {
    var _b;
    var selected = _a.selected, type = _a.type, value = _a.value, onClick = _a.onClick;
    var css = useTheme('LoginPage').LoginTypeSelector;
    return (React.createElement("div", { className: cn(css.item, (_b = {}, _b[css.selected] = selected, _b)), onClick: onClick },
        React.createElement("div", { className: css.info },
            React.createElement("span", { className: css.type }, type),
            value && React.createElement("span", null, value)),
        React.createElement("span", { className: css.icon }, Chevron)));
};
export default LoginTypeSelector;
