import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '../../../../Link';
import { Field, Form } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { WelcomeIcon } from '../../../Icons';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useConfig } from '../../../../context';
import cn from 'classnames';
import { LoginType, useSendSecureCodeMutation } from '@websky/graphql';
var RegistrationForm = function (_a) {
    var login = _a.login, loginType = _a.loginType, onSuccess = _a.onSuccess, onError = _a.onError;
    var css = useTheme('LoginPage').RegistrationForm;
    var t = useTranslation('LoginPage').t;
    var _b = __read(useSendSecureCodeMutation(), 2), sendCode = _b[0], loading = _b[1].loading;
    var companyInfo = useConfig().global.companyInfo;
    var validate = useCallback(function (values) {
        var errors = {};
        if (!values.agree) {
            errors['agree'] = t('Please, read the rules of service and accept it');
        }
        return errors;
    }, []);
    var onSubmit = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(loginType === LoginType.FFP)) return [3 /*break*/, 1];
                    onSuccess();
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, sendCode({
                        variables: {
                            params: {
                                login: login,
                                loginType: loginType
                            }
                        }
                    })];
                case 2:
                    data = _a.sent();
                    if (data.data.SendSecureCode.result) {
                        onSuccess();
                    }
                    else {
                        onError('Something went wrong');
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [onSuccess, login, loginType]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title },
            React.createElement("span", { className: css.icon_wrapper }, WelcomeIcon),
            t('Welcome!')),
        React.createElement("div", { className: css.message }, t('You are logged in for the first time, please read the terms and conditions and give consent')),
        React.createElement(Form, { validate: validate, onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.row },
                        React.createElement("div", { className: css.input_wrapper },
                            React.createElement(Field, { name: "agree", type: "checkbox", render: function (_a) {
                                    var _b;
                                    var input = _a.input, meta = _a.meta;
                                    var notValid = !!meta.error && meta.touched;
                                    return (React.createElement(FormControl, { error: notValid },
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { className: cn(css.checkbox, (_b = {},
                                                    _b[css.checked] = input.checked,
                                                    _b)), color: "primary", checked: input.checked, onChange: input.onChange }), label: React.createElement("span", { className: css.label },
                                                t('I agree to the'),
                                                ' ',
                                                React.createElement(Link, { target: "_blank", action: companyInfo.privacyPolicy }, t('Terms of Service and Privacy Policy'))) }),
                                        notValid ? (React.createElement(FormHelperText, { className: css.error }, meta.error)) : null));
                                } })),
                        React.createElement("div", { className: css.button_wrapper },
                            React.createElement(SubmitButton, { loading: loading }, t('Continue'))))));
            } })));
};
export default RegistrationForm;
