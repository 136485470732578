import { __decorate, __extends } from "tslib";
import * as React from 'react';
import { createDatesArray } from '../../utils';
import { format } from 'date-fns';
import { HeaderType } from '../PriceMatrix';
import { withTheme } from '../../theme';
import classnames from 'classnames';
var Outbound = /** @class */ (function (_super) {
    __extends(Outbound, _super);
    function Outbound() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Outbound.prototype.fill = function () {
        return createDatesArray(this.props.minDate, this.props.maxDate);
    };
    Outbound.prototype.arrowClick = function (dir) {
        this.props.onArrowClick && this.props.onArrowClick(this.props.type, dir);
    };
    Outbound.prototype.render = function () {
        var _a;
        var _this = this;
        var dates = this.fill(), style = this.props.theme.OutboundStyles;
        return (React.createElement("div", { className: classnames(style.container, (_a = {},
                _a[style.container_row] = this.props.type === HeaderType.Return,
                _a[style.container_col] = this.props.type === HeaderType.Outbound,
                _a)) },
            React.createElement("div", { className: style.nav },
                React.createElement("div", { className: style.label }, this.props.type === HeaderType.Outbound ? 'Outbound' : 'Return'),
                React.createElement("div", { className: style.arrows },
                    React.createElement("div", { className: style.arrow_prev, onClick: function () { return _this.arrowClick(_this.props.type === HeaderType.Return ? 'prev' : 'next'); } }),
                    React.createElement("div", { className: style.arrow_next, onClick: function () { return _this.arrowClick(_this.props.type === HeaderType.Return ? 'next' : 'prev'); } }))),
            React.createElement("div", { className: style.wrapper, ref: this.props.innerRef }, dates.map(function (date, index) {
                return (React.createElement("div", { key: index, className: style.day },
                    React.createElement("span", null, format(date, 'd MMM')),
                    React.createElement("span", { className: style.day_dow }, format(date, 'EEE'))));
            }))));
    };
    Outbound = __decorate([
        withTheme
    ], Outbound);
    return Outbound;
}(React.Component));
export { Outbound };
