import { defaultTheme as priceGraphDefaultTheme } from '../PriceGraph/theme';
import { defaultTheme as priceMatrixDefaultTheme } from '../PriceMatrix/theme';
import * as SearchFormDatepickerStyles from './components/SearchFormDatepicker.css';
import * as MonthStyles from './css/Month/Month.css';
import * as MonthHeaderStyles from './css/Month/MonthHeader/MonthHeader.css';
import * as DayStyles from './css/Month/Day/Day.css';
import * as WeeklyHeaderStyles from './css/Month/WeeklyHeader/WeeklyHeader.css';
import * as MonthsWrapperStyles from './css/MonthsWrapper/MonthsWrapper.css';
import * as PriceGraphStyles from './css/PriceGraph/PriceGraph.css';
import * as SliderStyles from './css/PriceGraph/Slider/Slider.css';
import * as PriceMatrixStyles from './css/PriceMatrix/PriceMatrix.css';
var merge = require('lodash.merge');
export var priceGraphTheme = merge({}, priceGraphDefaultTheme, {
    PriceGraphStyles: PriceGraphStyles,
    SliderStyles: SliderStyles
});
export var priceMatrixTheme = merge({}, priceMatrixDefaultTheme, {
    PriceMatrixStyles: PriceMatrixStyles
});
export var defaultDatepickerTheme = {
    MonthsWrapperStyles: MonthsWrapperStyles,
    MonthStyles: MonthStyles,
    DayStyles: DayStyles,
    WeeklyHeaderStyles: WeeklyHeaderStyles,
    MonthHeaderStyles: MonthHeaderStyles
};
export default {
    SearchFormDatepickerStyles: SearchFormDatepickerStyles,
    MonthsWrapperStyles: MonthsWrapperStyles,
    MonthStyles: MonthStyles,
    DayStyles: DayStyles,
    WeeklyHeaderStyles: WeeklyHeaderStyles,
    MonthHeaderStyles: MonthHeaderStyles
};
