import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getOrder, getIsBookedOrConfirmed } from '../../../../store/order/selectors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '../../../../../Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useEffect } from 'react';
import InfoLinksLabel from '../../../../../InfoLinksLabel';
var lastVisibleError = '';
var PassengersToolbarCheckbox = function () {
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').PassengersToolbar;
    var ref = React.useRef(null);
    var order = useSelector(getOrder);
    var isOrderBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    var _a = useField('terms', {
        type: 'checkbox',
        initialValue: false,
        validate: function (value) {
            if (!value) {
                return t('Please, read the rules of service and accept it');
            }
        }
    }), input = _a.input, meta = _a.meta;
    useEffect(function () {
        // safari bug, when checkbox onBlur didn't fired
        // and form didn't update it's state
        input.onBlur();
    }, [input.value]);
    if (meta.error) {
        lastVisibleError = meta.error;
    }
    return (React.createElement(React.Fragment, null, !isOrderBookedOrConfirmed && (React.createElement(FormControlLabel, { ref: ref, classes: {
            root: css.root,
            label: css.label
        }, control: React.createElement(Tooltip, { open: meta.submitFailed && !input.value, title: lastVisibleError, placement: "top-start", classes: {
                popper: css.tooltip
            }, PopperProps: {
                container: ref.current
            } },
            React.createElement(Checkbox, { color: "primary", classes: {
                    colorPrimary: css.checkbox,
                    checked: css.checkbox_checked
                }, checked: input.checked, inputProps: __assign({}, input) })), label: React.createElement(InfoLinksLabel, { order: order, type: 'checkout' }) }))));
};
export default PassengersToolbarCheckbox;
