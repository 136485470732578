import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useInsurancePageWrapper } from '../../../InsurancePage/hooks';
import { getInsurances, getIsConfirmed, getOrder } from '../../../Checkout/store/order/selectors';
import { getInsurancePriceToPay } from '../../../Checkout/store/orderPrice/selectors';
import { useToggleable } from '../../../hooks';
import { getMinPrice, getTotalPrice, initI18n } from '../../../utils';
import { analytics_removeInsurance } from '../../../Insurance/utils';
export var useInsuranceUpsale = function (insurancePrograms) {
    var insurancePageWrapper = useInsurancePageWrapper({ strategy: 'checkout-order-service', insurancePrograms: insurancePrograms });
    var isConfirmed = useSelector(getIsConfirmed);
    var insurances = useSelector(getInsurances);
    var insuranceTotalPrice = useSelector(getInsurancePriceToPay);
    var order = useSelector(getOrder);
    var _a = useToggleable(false), isOpen = _a.isOpen, open = _a.open, close = _a.close;
    var availablePrograms = useMemo(function () {
        if (!(insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length)) {
            return insurances.insurancePrograms;
        }
        return insurances.insurancePrograms.filter(function (program) {
            return insurancePrograms.some(function (available) { return available.code === program.code; });
        });
    }, [insurances.insurancePrograms, insurancePrograms]);
    var selectedPrograms = useMemo(function () {
        var _a;
        var selectedProgramsMap = new Map();
        if ((_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
            insurances.selectedInsurances.forEach(function (insurance) {
                var insuranceCode = insurance.insuranceProgram.code;
                var isAvailableInsurance = availablePrograms.some(function (program) { return program.code === insuranceCode; });
                // exclude duplicate selected insurances
                if (isAvailableInsurance && !selectedProgramsMap.has(insuranceCode)) {
                    selectedProgramsMap.set(insuranceCode, insurance);
                }
            });
        }
        return __spreadArray([], __read(selectedProgramsMap.values()), false);
    }, [insurances.selectedInsurances, availablePrograms]);
    var insuranceSummaryServices = useMemo(function () {
        var additionalServices = insurancePageWrapper.order.additionalServices;
        return __assign(__assign({}, additionalServices), { insurances: __assign(__assign({}, additionalServices.insurances), { insurancePrograms: availablePrograms }) });
    }, [insurancePageWrapper.order.additionalServices, availablePrograms]);
    var totalPrice = useMemo(function () {
        if (!(insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length)) {
            return insuranceTotalPrice;
        }
        return getTotalPrice(selectedPrograms, function (insurance) { return insurance.insuranceProgram.totalPrice; });
    }, [insurancePrograms, selectedPrograms]);
    var minPrice = useMemo(function () {
        return getMinPrice(availablePrograms, function (insurance) { return insurance.totalPrice; });
    }, [insurances]);
    var isDifferentPrices = useMemo(function () { return new Set(availablePrograms.map(function (insurance) { return insurance.totalPrice.amount; })).size > 1; }, [availablePrograms]);
    var singleInsurance = useMemo(function () {
        if (availablePrograms.length === 1) {
            return availablePrograms[0];
        }
        return null;
    }, [availablePrograms]);
    var onAddInsurance = useCallback(function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedProgramsSet;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    selectedProgramsSet = new Set();
                    insurances.selectedInsurances.forEach(function (insurance) {
                        return selectedProgramsSet.add(insurance.insuranceProgram.code);
                    });
                    selectedProgramsSet.add(code);
                    return [4 /*yield*/, insurancePageWrapper.saveOrderServicesWithParams(__spreadArray([], __read(selectedProgramsSet), false))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [insurances.selectedInsurances, insurancePageWrapper.saveOrderServicesWithParams]);
    var onDeleteInsurance = useCallback(function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedProgramsSet, deletingProgram;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    selectedProgramsSet = new Set();
                    (_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.forEach(function (insurance) {
                        return selectedProgramsSet.add(insurance.insuranceProgram.code);
                    });
                    selectedProgramsSet.delete(code);
                    deletingProgram = (_b = insurances.selectedInsurances.find(function (selected) { return selected.insuranceProgram.code === code; })) === null || _b === void 0 ? void 0 : _b.insuranceProgram;
                    if (deletingProgram) {
                        analytics_removeInsurance(deletingProgram, order.travellers);
                    }
                    return [4 /*yield*/, insurancePageWrapper.saveOrderServicesWithParams(__spreadArray([], __read(selectedProgramsSet), false))];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [insurances.selectedInsurances, insurancePageWrapper.saveOrderServicesWithParams]);
    var onAddSingle = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!singleInsurance) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, onAddInsurance(singleInsurance.code)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [singleInsurance, onAddInsurance]);
    var onDeleteSingle = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!singleInsurance) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, onDeleteInsurance(singleInsurance.code)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [singleInsurance, onDeleteInsurance]);
    var onConfirm = useCallback(function () { return insurancePageWrapper.saveOrderServicesCallback(close); }, [
        insurancePageWrapper.saveOrderServicesCallback
    ]);
    var onClose = useCallback(function () {
        insurancePageWrapper.onHideImportantInsurancesError();
        insurancePageWrapper.onContinue(close);
    }, [insurancePageWrapper.onHideImportantInsurancesError, insurancePageWrapper.onContinue]);
    var handleAgree = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!insurancePageWrapper.priorityInsurance) return [3 /*break*/, 2];
                    return [4 /*yield*/, insurancePageWrapper.handleAgree()];
                case 1:
                    _a.sent();
                    insurancePageWrapper.closeUpsaleModal();
                    close();
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, insurancePageWrapper.handleAgree()];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [insurancePageWrapper.priorityInsurance, insurancePageWrapper.handleAgree, close]);
    var handleDisagree = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, insurancePageWrapper.handleDisagree()];
                case 1:
                    _a.sent();
                    close();
                    return [2 /*return*/];
            }
        });
    }); }, [insurancePageWrapper.handleDisagree, close]);
    return {
        // methods
        open: open,
        handleAgree: handleAgree,
        handleDisagree: handleDisagree,
        onConfirm: onConfirm,
        onClose: onClose,
        onAddInsurance: onAddInsurance,
        onDeleteInsurance: onDeleteInsurance,
        onAddSingle: onAddSingle,
        onDeleteSingle: onDeleteSingle,
        setInsurancePriceToPay: insurancePageWrapper.setInsurancePriceToPay,
        setSaveOrderServicesParams: insurancePageWrapper.setSaveOrderServicesParams,
        setIsNeedToSendSaveReq: insurancePageWrapper.setIsNeedToSendSaveReq,
        // data
        order: insurancePageWrapper.order,
        insurancePriceToPay: insurancePageWrapper.insurancePriceToPay,
        priorityInsurance: insurancePageWrapper.priorityInsurance,
        availablePrograms: availablePrograms,
        selectedPrograms: selectedPrograms,
        insuranceSummaryServices: insuranceSummaryServices,
        minPrice: minPrice,
        totalPrice: totalPrice,
        singleInsurance: singleInsurance,
        isOpen: isOpen,
        isConfirmed: isConfirmed,
        isDifferentPrices: isDifferentPrices,
        isUpsaleOpen: insurancePageWrapper.isUpsaleOpen,
        isLoading: insurancePageWrapper.isLoading,
        isShowImportantInsurancesError: insurancePageWrapper.isShowImportantInsurancesError
    };
};
initI18n('Checkout');
export var useInsuranceGroupTexts = function (groupName, insurancePrograms) {
    var _a, _b, _c;
    var _d = useTranslation('Checkout'), t = _d.t, i18n = _d.i18n;
    var titleKey = "Checkout:InsuranceGroupTitle_".concat(groupName);
    var descrKey = "Checkout:InsuranceGroupDescr_".concat(groupName);
    var title = i18n.exists(titleKey) ? t(titleKey) : t('InsuranceGroupTitle_default');
    var descr = i18n.exists(descrKey) ? t(descrKey) : t('InsuranceGroupDescr_default');
    if ((insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length) === 1) {
        var singleInsurance = insurancePrograms[0];
        return {
            title: (_a = singleInsurance.name) !== null && _a !== void 0 ? _a : title,
            descr: (_b = singleInsurance.description) !== null && _b !== void 0 ? _b : descr,
            options: ((_c = singleInsurance.options) === null || _c === void 0 ? void 0 : _c.length) ? singleInsurance.options.map(function (_a) {
                var name = _a.name;
                return name;
            }) : []
        };
    }
    return { title: title, descr: descr, options: [] };
};
