import { __assign, __read } from "tslib";
import * as React from 'react';
import { Money } from '../../Money';
import { useTheme } from '../theme';
import { VoucherStatus } from '../types';
import Tooltip from '../../Tooltip/Tooltip';
import MediaQuery from 'react-responsive';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
initI18n('Voucher');
export var Voucher = function (props) {
    var _a, _b;
    var theme = useTheme().VoucherTheme;
    var t = useTranslation('Voucher').t;
    var _c = __read(React.useState(false), 2), isLoading = _c[0], setIsLoading = _c[1], _d = __read(React.useState(false), 2), codeValid = _d[0], setCodeValid = _d[1];
    var inputRef = null;
    var addVoucher = function () {
        var value = inputRef.value;
        if (value && !isLoading) {
            props.addVoucher && props.addVoucher(inputRef.value);
            setIsLoading(true);
        }
    };
    var onKeyUp = function (e) {
        var value = inputRef.value;
        setCodeValid(value.length >= props.minSymbolsCount);
        if (e.key === 'Enter' && codeValid) {
            addVoucher();
        }
    };
    React.useEffect(function () {
        if (props.voucherCode) {
            setIsLoading(false);
        }
    }, [props.voucherCode]);
    return (React.createElement("div", { className: theme.container },
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.voucher__header },
                t('E-voucher'),
                React.createElement(Tooltip, { placement: "left", title: props.detailsTooltip, tooltipClassName: theme.mobileTooltip },
                    React.createElement("div", { className: theme.info })))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Tooltip, { title: props.detailsTooltip },
                React.createElement("div", { className: theme.info }, t('Details')))),
        React.createElement("div", { className: theme.vouchersList },
            props.voucherCode && (React.createElement("div", { className: theme.voucher },
                React.createElement("div", { className: theme.voucher__read },
                    React.createElement("span", null, props.voucherCode),
                    React.createElement("div", { className: theme.voucher__right },
                        props.voucherStatus === VoucherStatus.Complete && props.voucherMoney && (React.createElement(Money, { moneyClassName: theme.voucher__discount, money: __assign({}, props.voucherMoney) })),
                        React.createElement("div", { onClick: function () { return props.removeVoucher && props.removeVoucher(); }, className: theme.voucher__remove }))))),
            !props.voucherCode && (React.createElement("div", { className: theme.voucher },
                React.createElement("input", { className: cn(theme.voucher__input, (_a = {}, _a[theme.voucher__input_load] = isLoading, _a)), readOnly: isLoading, autoFocus: true, autoComplete: "one-time-code", autoCorrect: "none", spellCheck: false, ref: function (ref) { return (inputRef = ref); }, onKeyUp: onKeyUp, placeholder: t('Enter code') })))),
        React.createElement("div", { className: theme.footer },
            props.voucherStatus === VoucherStatus.Complete && (React.createElement(Button, { className: theme.button, variant: "flat", onClick: props.onDone }, t('Done'))),
            props.voucherStatus !== VoucherStatus.Complete && (React.createElement(Button, { className: cn(theme.button, (_b = {},
                    _b[theme.button_disabled] = !codeValid,
                    _b[theme.button_loader] = isLoading,
                    _b)), disabled: !codeValid, variant: "flat", onClick: addVoucher }, t('Add'))))));
};
export default Voucher;
