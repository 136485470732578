import * as React from 'react';
export var arrowIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z", fill: "#B6B6B6" })));
export var emptyCard = (React.createElement("svg", { width: "25", height: "16", viewBox: "0 0 25 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("rect", { width: "25", height: "16", rx: "2", fill: "#CFD1D1" }),
    React.createElement("rect", { x: "3", y: "4", width: "6", height: "4", fill: "white" }),
    React.createElement("rect", { x: "3", y: "11", width: "4", height: "1.5", fill: "#B8B9BB" }),
    React.createElement("rect", { x: "8", y: "11", width: "4", height: "1.5", fill: "#B8B9BB" }),
    React.createElement("rect", { x: "13", y: "11", width: "4", height: "1.5", fill: "#B8B9BB" }),
    React.createElement("rect", { x: "18", y: "11", width: "4", height: "1.5", fill: "#B8B9BB" })));
