import { __assign, __read } from "tslib";
import { useContext, useMemo } from 'react';
import { AvailableServicesCountContext } from './context';
import { ModeContext } from '../context';
import { Mode } from '../types';
var calculateSelectedCheckoutServices = function (selectedServices, serviceIdToGdsTypeMap, currentServiceId) {
    var selectedCountByGdsType = {};
    Object.entries(selectedServices).forEach(function (_a) {
        var _b = __read(_a, 2), travellerId = _b[0], servicesObj = _b[1];
        Object.entries(servicesObj).forEach(function (_a) {
            var _b = __read(_a, 2), serviceId = _b[0], services = _b[1];
            services.forEach(function (service) {
                if (service.servicePayload.id === currentServiceId) {
                    return;
                }
                var serviceGdsType = serviceIdToGdsTypeMap.get(serviceId);
                service.segments.forEach(function (segmentId) {
                    var _a, _b;
                    var _c, _d, _e;
                    selectedCountByGdsType[travellerId] = __assign(__assign({}, (selectedCountByGdsType[travellerId] || {})), (_a = {}, _a[segmentId] = __assign(__assign({}, (((_c = selectedCountByGdsType[travellerId]) === null || _c === void 0 ? void 0 : _c[segmentId]) || {})), (_b = {}, _b[serviceGdsType] = (((_e = (_d = selectedCountByGdsType[travellerId]) === null || _d === void 0 ? void 0 : _d[segmentId]) === null || _e === void 0 ? void 0 : _e[serviceGdsType]) || 0) +
                        service.number, _b)), _a));
                });
            });
        });
    });
    return selectedCountByGdsType;
};
var calculateSelectedCheckinServices = function (selectedServices, serviceIdToGdsTypeMap, currentServiceId) {
    var selectedCountByGdsType = {};
    Object.entries(selectedServices).forEach(function (_a) {
        var _b = __read(_a, 2), travellerId = _b[0], travelerServices = _b[1];
        Object.entries(travelerServices).forEach(function (_a) {
            var _b = __read(_a, 2), serviceId = _b[0], servicesBySegments = _b[1];
            Object.entries(servicesBySegments).forEach(function (_a) {
                var _b, _c;
                var _d, _e, _f;
                var _g = __read(_a, 2), segmentId = _g[0], number = _g[1];
                if (serviceId === currentServiceId) {
                    return;
                }
                var serviceGdsType = serviceIdToGdsTypeMap.get(serviceId);
                selectedCountByGdsType[travellerId] = __assign(__assign({}, (selectedCountByGdsType[travellerId] || {})), (_b = {}, _b[segmentId] = __assign(__assign({}, (((_d = selectedCountByGdsType[travellerId]) === null || _d === void 0 ? void 0 : _d[segmentId]) || {})), (_c = {}, _c[serviceGdsType] = (((_f = (_e = selectedCountByGdsType[travellerId]) === null || _e === void 0 ? void 0 : _e[segmentId]) === null || _f === void 0 ? void 0 : _f[serviceGdsType]) || 0) + number, _c)), _b));
            });
        });
    });
    return selectedCountByGdsType;
};
export var useAvailableCountForTravellerOnSegment = function (serviceId, travellerId, segmentId) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useContext(AvailableServicesCountContext), order = _j.order, selectedServices = _j.selectedServices;
    var isBookingMode = useContext(ModeContext) === Mode.Booking;
    if (!order || !selectedServices) {
        console.warn('empty order or selectedServices');
        return undefined;
    }
    var gdsService = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(function (service) { return service.id === serviceId; });
    var gdsServiceMaxCountPerPassenger = gdsService.maxServiceCountPerPassenger;
    var gdsType = gdsService === null || gdsService === void 0 ? void 0 : gdsService.gdsType;
    var serviceIdToGdsTypeMap = useMemo(function () {
        var _a, _b;
        var serviceIdToGdsTypeMap = new Map();
        (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.forEach(function (service) {
            serviceIdToGdsTypeMap.set(service.id, service.gdsType);
        });
        return serviceIdToGdsTypeMap;
    }, [(_c = order.additionalServices.gdsServices) === null || _c === void 0 ? void 0 : _c.services]);
    var selectedCountByGdsType = useMemo(function () {
        return isBookingMode
            ? calculateSelectedCheckoutServices(selectedServices, serviceIdToGdsTypeMap, serviceId)
            : calculateSelectedCheckinServices(selectedServices, serviceIdToGdsTypeMap, serviceId);
    }, [selectedServices, serviceIdToGdsTypeMap, serviceId]);
    var maxCountForTravellerOnSegment = (_f = (_e = (_d = order.additionalServices.gdsServices) === null || _d === void 0 ? void 0 : _d.servicesRestrictionsByType) === null || _e === void 0 ? void 0 : _e.find(function (restriction) { return restriction.gdsType === gdsType; })) === null || _f === void 0 ? void 0 : _f.maxCountForTravellerOnSegment;
    if (maxCountForTravellerOnSegment !== undefined) {
        return maxCountForTravellerOnSegment - (((_h = (_g = selectedCountByGdsType[travellerId]) === null || _g === void 0 ? void 0 : _g[segmentId]) === null || _h === void 0 ? void 0 : _h[gdsType]) || 0);
    }
    else if (typeof gdsServiceMaxCountPerPassenger === 'number') {
        return gdsServiceMaxCountPerPassenger;
    }
    else {
        return undefined;
    }
};
