import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Collapse } from '@material-ui/core';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { Chevron } from '../../../Icons';
var SegmentCollapse = function (_a) {
    var _b, _c;
    var className = _a.className, children = _a.children, date = _a.date, info = _a.info, isActive = _a.isActive, isDisabled = _a.isDisabled, _d = _a.type, type = _d === void 0 ? 'collapse' : _d, onChange = _a.onChange;
    var css = useTheme('AdditionalServiceWrapper').SegmentCollapse;
    var _f = __read(useState(isActive !== null && isActive !== void 0 ? isActive : false), 2), isCollapse = _f[0], setIsCollapse = _f[1];
    var isCheckbox = type === 'checkbox';
    useEffect(function () {
        if (isCheckbox && isCollapse !== isActive) {
            setIsCollapse(isActive);
        }
    }, [isActive]);
    var onChangeHandler = function (_e, checked) {
        setIsCollapse(checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(checked);
    };
    return (React.createElement("div", { className: cn(css.segment, className, (_b = {},
            _b[css.collapse] = type === 'collapse',
            _b)) },
        React.createElement(FormControlLabel, { classes: {
                root: css.label__root,
                label: css.label__label
            }, checked: isCollapse, disabled: isDisabled, control: React.createElement(Checkbox, { classes: {
                    root: css.checkbox,
                    checked: css.checkbox__checked
                }, disableRipple: true }), label: React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.date }, date),
                info && React.createElement("span", { className: css.info }, info),
                !isCheckbox && (React.createElement("span", { className: cn(css.arrow, (_c = {},
                        _c[css.arrow__collapse] = isCollapse,
                        _c)) }, Chevron))), onChange: onChangeHandler }),
        React.createElement(Collapse, { classes: { wrapperInner: css.wrapperInner }, in: isCollapse }, children)));
};
export default SegmentCollapse;
