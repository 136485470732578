import { __assign, __read, __spreadArray, __values } from "tslib";
import { BaggageDirection } from '../BaggageList/types';
export var convertBaggageOnBaggageItem = function (baggages, selectedServices, segments) {
    var _a, _b, _c;
    var firstBaggage = baggages[0];
    var minPrice = {
        amount: ((_a = firstBaggage.price) === null || _a === void 0 ? void 0 : _a.amount) * ((_b = firstBaggage.segmentIds[0]) === null || _b === void 0 ? void 0 : _b.length),
        currency: (_c = firstBaggage.price) === null || _c === void 0 ? void 0 : _c.currency
    };
    baggages === null || baggages === void 0 ? void 0 : baggages.forEach(function (baggage) {
        baggage.segmentIds.forEach(function (segmentId) {
            if (!minPrice.amount || baggage.price.amount * segmentId.length < (minPrice === null || minPrice === void 0 ? void 0 : minPrice.amount)) {
                minPrice.amount = baggage.price.amount * segmentId.length;
                minPrice.currency = baggage.price.currency;
            }
        });
    });
    return {
        baggage: baggages,
        selected: isSelected(baggages[0], selectedServices),
        priceFrom: minPrice,
        servicesBySegments: getSelectedServicesBySegments(baggages, selectedServices, segments)
    };
};
export var getSelectedServicesBySegments = function (baggages, selectedServices, segments) {
    // Collect selected services, merging segmentIds and count
    var selectedServicesByPassengerMap = new Map();
    selectedServices.forEach(function (selectedService) {
        var _a, _b;
        if (baggages.some(function (baggage) { return baggage.id === selectedService.serviceId; })) {
            var key = [selectedService.passengerId, selectedService.serviceId, selectedService.count].join('-');
            var existService = selectedServicesByPassengerMap.get(key);
            var segmentIds = __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(((_a = existService === null || existService === void 0 ? void 0 : existService.segmentIds) !== null && _a !== void 0 ? _a : [])), false), __read(selectedService.segmentIds), false))), false);
            var count = Math.max((_b = existService === null || existService === void 0 ? void 0 : existService.count) !== null && _b !== void 0 ? _b : 0, selectedService.count);
            selectedServicesByPassengerMap.set(key, __assign(__assign({}, selectedService), { segmentIds: segmentIds, count: count }));
        }
    });
    // Collect selected services based on the key - [firstSegmentId, lastSegmentId]
    var selectedServicesMap = new Map();
    selectedServicesByPassengerMap.forEach(function (selectedService) {
        var _a, _b;
        var firstSegmentId = selectedService.segmentIds[0];
        var firstSegment = segments[+firstSegmentId];
        var key = getSegmentsIatasKey(firstSegment, firstSegment);
        var segmentIds = [firstSegmentId];
        // Multiple segments
        if (selectedService.segmentIds.length > 1) {
            var lastSegmentId = selectedService.segmentIds[selectedService.segmentIds.length - 1];
            var lastSegment = segments[+lastSegmentId];
            key = getSegmentsIatasKey(firstSegment, lastSegment);
            segmentIds = [firstSegmentId, lastSegmentId];
        }
        var directions = segments
            .filter(function (segment) { return segmentIds.includes(segment.id); })
            .map(function (segment) { return ({
            departure: { iata: segment.departure.airport.iata },
            arrival: { iata: segment.arrival.airport.iata }
        }); });
        selectedServicesMap.set(key, {
            count: ((_b = (_a = selectedServicesMap.get(key)) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0) + selectedService.count,
            directions: directions,
            allSegments: selectedService.segmentIds.length === segments.length
        });
    });
    var selectedBaggageServices = __spreadArray([], __read(selectedServicesMap.values()), false);
    // If the count and allSegments of all selected services matches, then display as for all flights
    if (selectedBaggageServices.length > 1 &&
        selectedBaggageServices.every(function (selectedService) {
            var firstSelectedBaggage = selectedBaggageServices[0];
            return (firstSelectedBaggage.count === selectedService.count &&
                firstSelectedBaggage.allSegments === selectedService.allSegments);
        })) {
        return [__assign(__assign({}, selectedBaggageServices[0]), { allSegments: true })];
    }
    return selectedBaggageServices;
};
export var getSegmentsIatasKey = function (leftSegment, rightSegment, separator) {
    if (separator === void 0) { separator = ' – '; }
    return [leftSegment.departure.airport.iata, rightSegment.arrival.airport.iata].join(separator);
};
export var isSelected = function (baggage, selectedServices) {
    return calcCount(baggage, selectedServices) > 0;
};
export var calcCount = function (baggage, selectedServices) {
    return selectedServices.filter(function (el) { return el.serviceId === baggage.id; }).reduce(function (acc, el) { return acc + el.count; }, 0);
};
export var detectDirection = function (baggage, selectedServices, order) {
    var firstSegment = order.flight.segments[0];
    var lastSegment = order.flight.segments[order.flight.segments.length - 1];
    var allDirections = order === null || order === void 0 ? void 0 : order.travellers.map(function (traveller) {
        var services = selectedServices.filter(function (el) { return traveller.id === el.passengerId; });
        if (services.length === 0) {
            return null;
        }
        return detectDirectionForTraveller(baggage, services, firstSegment, lastSegment);
    });
    var existRoundTrip = allDirections.some(function (direction) { return direction === BaggageDirection.RoundTrip; });
    var existThere = allDirections.some(function (direction) { return direction === BaggageDirection.There; });
    var existReturn = allDirections.some(function (direction) { return direction === BaggageDirection.Return; });
    if (existRoundTrip) {
        return BaggageDirection.RoundTrip;
    }
    else if (existThere && existReturn) {
        return BaggageDirection.RoundTrip;
    }
    else if (existThere) {
        return BaggageDirection.There;
    }
    else if (existReturn) {
        return BaggageDirection.Return;
    }
    return null;
};
export var detectDirectionForTraveller = function (baggage, selectedServices, firstSegment, lastSegment) {
    var segmentIds = [];
    selectedServices
        .filter(function (service) { return baggage.id === service.serviceId; })
        .forEach(function (el) {
        return el.segmentIds.forEach(function (segmentId) {
            segmentIds.push(segmentId);
        });
    });
    if (segmentIds.length === 0) {
        return null;
    }
    var thereDirection = segmentIds.includes(firstSegment.segment.id);
    var returnDirection = false;
    if (firstSegment.segment.id !== lastSegment.segment.id) {
        returnDirection = segmentIds.includes(lastSegment.segment.id);
    }
    if (thereDirection && returnDirection) {
        return BaggageDirection.RoundTrip;
    }
    else if (thereDirection) {
        return BaggageDirection.There;
    }
    else if (returnDirection) {
        return BaggageDirection.Return;
    }
    return null;
};
export var factorySelectedServices = function (selectedServices) {
    var e_1, _a, e_2, _b, e_3, _c;
    var _d, _e;
    var result = [];
    var addedKeys = new Map();
    try {
        for (var _f = __values(Object.entries(selectedServices)), _g = _f.next(); !_g.done; _g = _f.next()) {
            var _h = __read(_g.value, 2), passengerId = _h[0], passengerSelectedServices = _h[1];
            try {
                for (var _j = (e_2 = void 0, __values(Object.entries(passengerSelectedServices))), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var _l = __read(_k.value, 2), serviceId = _l[0], segments = _l[1];
                    try {
                        for (var _m = (e_3 = void 0, __values(Object.entries(segments))), _o = _m.next(); !_o.done; _o = _m.next()) {
                            var _p = __read(_o.value, 2), segmentId = _p[0], selectedService = _p[1];
                            var segmentIds = (_d = selectedServices[passengerId][serviceId][segmentId]) === null || _d === void 0 ? void 0 : _d.segmentIds;
                            var key = "".concat(passengerId, "-").concat(serviceId, "-").concat(segmentIds.join('-'));
                            if (!addedKeys.has(key)) {
                                result.push({
                                    passengerId: passengerId,
                                    segmentIds: (_e = selectedServices[passengerId][serviceId][segmentId]) === null || _e === void 0 ? void 0 : _e.segmentIds,
                                    serviceId: serviceId,
                                    count: selectedService.count
                                });
                                addedKeys.set(key, key);
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_o && !_o.done && (_c = _m.return)) _c.call(_m);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_b = _j.return)) _b.call(_j);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
};
