import { __extends } from "tslib";
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
var Dropdown = /** @class */ (function (_super) {
    __extends(Dropdown, _super);
    function Dropdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Dropdown.prototype.handleClickOutside = function (e) {
        if (e.offsetX <= document.body.clientWidth) {
            this.props.onClose(e);
        }
    };
    Dropdown.prototype.render = function () {
        // Do not remove dropdown content from the DOM.
        // Necessary in case of nested dropdowns (autocomplete dropdown + directions popup);
        if (this.props.keepMounted) {
            var styles = {};
            if (!this.props.isOpen) {
                styles.display = 'none';
            }
            return React.createElement("div", { style: styles }, this.props.children);
        }
        return this.props.isOpen ? this.props.children : null;
    };
    Dropdown.defaultProps = {
        keepMounted: false
    };
    return Dropdown;
}(React.Component));
export default onClickOutside(Dropdown);
