import { __read, __values } from "tslib";
import memes from '../../../../memes';
var getSeatsNumbers = function (selectedSeatMap) {
    return selectedSeatMap.map(function (_a) {
        var seat = _a.seat;
        return (seat ? seat.number : '');
    });
};
export var isNextSeatMapEqualsToPrev = function (prevSeatMap, nextSeatMap) {
    var e_1, _a;
    var prevSeats = getSeatsNumbers(prevSeatMap);
    var nextSeats = getSeatsNumbers(nextSeatMap);
    if (prevSeats.length !== nextSeats.length) {
        return false;
    }
    try {
        // this approach will be better than reduce
        for (var _b = __values(Object.entries(prevSeats)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), index = _d[0], number = _d[1];
            if (nextSeats[index] !== number) {
                return false;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return true;
};
export var isSeatOccupied = memes(function (seat, passenger, segmentId) {
    return passenger.seats &&
        passenger.seats.some(function (selectedSeat) {
            return selectedSeat.segmentId === segmentId && "".concat(selectedSeat.row).concat(selectedSeat.letter) === seat.number;
        });
});
export var isOccupiedByOtherPassenger = memes(function (seat, passengers, passengerId, segmentId) {
    var otherPassengers = passengers.filter(function (passenger) { return passenger.id !== passengerId; });
    return otherPassengers
        .map(function (passenger) { return passenger.seats; })
        .filter(Boolean)
        .some(function (otherPassengerSeat) {
        return otherPassengerSeat.some(function (otherSeat) {
            return otherSeat.segmentId === segmentId && "".concat(otherSeat.row).concat(otherSeat.letter) === seat.number;
        });
    });
});
export var findPassengerWithThisSeat = memes(function (seat, passengers, segmentId) {
    return passengers.find(function (passenger) {
        return passenger.seats &&
            passenger.seats
                .filter(function (seat) { return seat.segmentId === segmentId; })
                .some(function (selectedSeat) { return "".concat(selectedSeat.row).concat(selectedSeat.letter) === seat.number; });
    });
});
