import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { LoginType, useAuthenticateMutation, useSendPersonalDetailsMutation } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
import { useToggleable } from '../../../../../hooks';
import Fields from '../Fields/Fields';
import Link from '../../../../../Link';
import Switch from '../../../../../PassengerForm/components/components/Switch/Switch';
import { formatError, getCorporateNumber } from '../../../../utils';
import { BaseButton as Button, IconButton } from '../../../../../BaseComponents';
var Login = function (props) {
    var t = useTranslation('Loyalty').t;
    var css = useTheme('Loyalty').LoyaltyConnect;
    var _a = __read(useSendPersonalDetailsMutation(), 1), updateUserProfile = _a[0];
    var _b = __read(useAuthenticateMutation(), 1), authenticate = _b[0];
    var iataCode = useConfig().global.companyInfo.iataCode;
    var isB2 = iataCode === 'B2';
    var _c = useToggleable(false), isCorporateCard = _c.isOpen, onToggleCorporateCard = _c.toggle;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var loyaltyNumber, res, error_1, errorMessage, res, error_2, errorMessage;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    loyaltyNumber = (isB2 && isCorporateCard
                        ? getCorporateNumber(data['loyaltyNumber'])
                        : data['loyaltyNumber']).trim();
                    if (!(props.queryType === 'authenticate')) return [3 /*break*/, 5];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    loginType: LoginType.FFP,
                                    login: loyaltyNumber,
                                    secureCode: data['loyaltyPassword']
                                }
                            }
                        })];
                case 2:
                    res = _c.sent();
                    if (!!((_a = res.data) === null || _a === void 0 ? void 0 : _a.Authenticate) && !res.errors) {
                        props === null || props === void 0 ? void 0 : props.onLoyaltyCardSaved();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    errorMessage = formatError(error_1);
                    return [2 /*return*/, {
                            loyaltyNumber: t(errorMessage),
                            loyaltyPassword: errorMessage
                        }];
                case 4: return [3 /*break*/, 8];
                case 5:
                    _c.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, updateUserProfile({
                            variables: {
                                params: {
                                    loyaltyCards: [
                                        {
                                            airlineCode: iataCode,
                                            cardNumber: loyaltyNumber,
                                            pinCode: data['loyaltyPassword']
                                        }
                                    ]
                                }
                            }
                        })];
                case 6:
                    res = _c.sent();
                    if (((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.UpdateUserProfile) && !(res === null || res === void 0 ? void 0 : res.errors)) {
                        props === null || props === void 0 ? void 0 : props.onLoyaltyCardSaved();
                        return [2 /*return*/, undefined];
                    }
                    return [3 /*break*/, 8];
                case 7:
                    error_2 = _c.sent();
                    errorMessage = formatError(error_2);
                    return [2 /*return*/, {
                            loyaltyNumber: t(errorMessage),
                            loyaltyPassword: errorMessage
                        }];
                case 8: return [2 /*return*/, undefined];
            }
        });
    }); };
    return (React.createElement("div", { className: css.loyaltyConnect },
        React.createElement("div", { className: css.header }, t('Log in to LightJet Club')),
        React.createElement("div", null,
            React.createElement(Form, { onSubmit: onSubmit }, function (_a) {
                var handleSubmit = _a.handleSubmit, submitting = _a.submitting, submitSucceeded = _a.submitSucceeded;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.group },
                        React.createElement(Fields, null)),
                    isB2 && (React.createElement("div", { className: css.group },
                        React.createElement(Switch, { switchPosition: "left", className: css.switch__root, labelClassName: css.switch__label, label: t('Passenger:Corporate card'), isActive: isCorporateCard, onClick: onToggleCorporateCard }))),
                    React.createElement("div", { className: css.buttons__wrapper },
                        React.createElement("div", { className: css.actions },
                            props.showRegisterAction && (React.createElement(Link, { action: props.toCreateAccount }, t('Not a member yet? Join for free!'))),
                            React.createElement(Link, { action: props.toResetPassword }, t('Forgot password?'))),
                        submitSucceeded ? (React.createElement(IconButton, { isSuccess: submitSucceeded })) : (React.createElement(Button, { variant: "action", type: "submit", isLoading: submitting }, t('Connect'))))));
            }))));
};
export default Login;
