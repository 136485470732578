import { __assign } from "tslib";
import * as React from 'react';
import { Provider } from 'react-redux';
import { lazy, Suspense } from 'react';
import { createStore } from '../store';
import { useGraphQLClient } from '../../graphql-client';
import { initI18n } from '../../utils';
import { GuardedApolloProvider } from '../../GuardedApolloProvider';
var Results = lazy(function () { return import('./Results/Results'); });
initI18n('Results');
var Component = function (props) {
    var client = useGraphQLClient();
    var store = createStore();
    return (React.createElement(Provider, { store: store },
        React.createElement(GuardedApolloProvider, { client: client },
            React.createElement(Suspense, { fallback: React.createElement("div", null) },
                React.createElement(Results, __assign({}, props))))));
};
export default Component;
