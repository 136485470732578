import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import SeatTooltip from './SeatTooltip/SeatTooltip';
import { useTheme } from '../../../../theme';
import { forwardRef, useContext, useState } from 'react';
import { SeatsRecommendContext } from '../../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { TravellerFieldEnum, SeatComfort } from '@websky/graphql';
import { isFreeSeat as getIsFreeSeat } from '../utils';
import { getSeatPrice } from '../../../utils';
import SeatServiceSlide from './SeatServiceSlide/SeatServiceSlide';
import { getUserValue, TABLET_MIDDLE_WIDTH } from '../../../../utils';
import { useToggleable } from '../../../../hooks';
var Seat = forwardRef(function (_a, ref) {
    var _b, _c;
    var _d, _e, _f, _g;
    var seat = _a.seat, onSelect = _a.onSelect, isAvailable = _a.isAvailable, isSmall = _a.isSmall, occupiedBy = _a.occupiedBy, passenger = _a.passenger, isConfirmed = _a.isConfirmed, segmentId = _a.segmentId, passengerId = _a.passengerId, onClear = _a.onClear, backgroundColor = _a.backgroundColor, hideSeatName = _a.hideSeatName, className = _a.className, combinedSeat = _a.combinedSeat, isCombinedWith = _a.isCombinedWith, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, dataColorNum = _a.dataColorNum;
    var theme = useTheme('SeatMap').SeatStyles;
    var _h = useToggleable(false), showSeatInfo = _h.isOpen, openSeatInfo = _h.open, closeSeatInfo = _h.close;
    var isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var _j = __read(useState(false), 2), isTooltipOpened = _j[0], setTooltipOpened = _j[1];
    var seatRecommendContext = useContext(SeatsRecommendContext);
    var seats = [seat];
    if (combinedSeat) {
        seats = __spreadArray(__spreadArray([], __read(seats), false), [combinedSeat], false);
    }
    var isFreeSeat = getIsFreeSeat(seat);
    var isAvailableSeat = (isAvailable || occupiedBy) && !isConfirmed;
    var handleSelect = function () {
        if (isMobile) {
            onMouseEnter();
            openSeatInfo();
        }
        else if (isAvailableSeat) {
            onSelect(seat, passengerId);
            setTooltipOpened(false);
        }
    };
    var handleMobileSelect = function (seat) {
        closeSeatInfo();
        isAvailable && onSelect(seat, passengerId);
        onMouseLeave();
    };
    var onCloseSeatInfo = function () {
        closeSeatInfo();
        onMouseLeave();
    };
    var preselectSeatForCurrentPassenger = seatRecommendContext.isOpen
        ? (_e = (_d = seatRecommendContext.rowsMap[segmentId]) === null || _d === void 0 ? void 0 : _d.seats[passengerId]) === null || _e === void 0 ? void 0 : _e.seat.number
        : false;
    var isPreselect = seatRecommendContext.isOpen
        ? (_f = seatRecommendContext.rowsMap[segmentId]) === null || _f === void 0 ? void 0 : _f.seatsMap.hasOwnProperty(seat.number)
        : false;
    var seatLabel = "<span>".concat(seat.row, "</span><span>").concat(seat.letter, "</span>");
    var shortPassengerName = false;
    if (occupiedBy) {
        var firstName = getUserValue(occupiedBy, TravellerFieldEnum.FirstName), lastName = getUserValue(occupiedBy, TravellerFieldEnum.LastName);
        if (firstName && lastName) {
            seatLabel = "".concat(lastName.charAt(0)).concat(firstName.charAt(0));
            shortPassengerName = true;
        }
        else {
            seatLabel = (parseInt(occupiedBy.id) + 1).toString();
        }
    }
    var seatPrice = getSeatPrice(seat);
    var isHideForWideBodyAircraft = isSmall && !seat.isExistent && !seat.isAisle;
    var button = (React.createElement(ButtonBase, { ref: ref, "data-rfisc": seat.rfisc, "data-seat-number": seat.number, "data-color-num": dataColorNum, className: cn(theme.seat, className, (_b = {},
            _b[theme.seat_small] = isSmall,
            _b[theme.seat_free] = isFreeSeat,
            _b[theme.seat_standart] = seat.service && seat.service.comfort === SeatComfort.Standart,
            _b[theme.seat_business] = seat.service && seat.service.comfort === SeatComfort.Comfort,
            _b[theme.seat_preferred] = seat.service && seat.service.comfort === SeatComfort.Preferred,
            _b[theme.seat_unexistent] = !seat.isExistent,
            _b[theme.seat_unavailable] = !isAvailableSeat,
            _b[theme.seat_occupied] = occupiedBy,
            _b[theme.seat_confirmed] = isConfirmed,
            _b[theme.seat_recommendCurrent] = isPreselect && preselectSeatForCurrentPassenger === seat.number,
            _b[theme.seat_recommend] = isPreselect,
            _b[theme.seat_customColor] = !!backgroundColor,
            _b[theme.seat_combinedLeft] = isCombinedWith === 'left',
            _b[theme.seat_combinedRight] = isCombinedWith === 'right'
        // [theme.male]: seat.isSeat && seat.occupiedBy === Gender.Male,
        // [theme.female]: props.seat.isSeat && props.seat.occupiedBy === Gender.Female
        ,
            _b)), disabled: !isAvailableSeat, onClick: handleSelect, onMouseEnter: isMobile ? null : onMouseEnter, onMouseLeave: isMobile ? null : onMouseLeave, style: backgroundColor ? { background: backgroundColor } : null }, seat.isExistent && (isAvailable || occupiedBy) && (React.createElement("div", { className: cn(theme.number, (_c = {}, _c[theme.number_name] = shortPassengerName, _c)), dangerouslySetInnerHTML: {
            __html: seatLabel
        } }))));
    if (isHideForWideBodyAircraft) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH + 1 },
            React.createElement(SeatTooltip, { isFreeSeat: isFreeSeat, seat: seat, service: (_g = seat.services) === null || _g === void 0 ? void 0 : _g[0], isRecommend: isPreselect, occupiedBy: occupiedBy, isAvailable: isAvailable, seatPrice: seatPrice, hideSeatName: hideSeatName, isTooltipOpened: isTooltipOpened, onToggle: setTooltipOpened }, button)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            button,
            ReactDOM.createPortal(React.createElement(SeatServiceSlide, { dataColorNum: dataColorNum, isFreeSeat: isFreeSeat, onClose: onCloseSeatInfo, onSelect: handleMobileSelect, seats: seats, passenger: occupiedBy !== null && occupiedBy !== void 0 ? occupiedBy : passenger, isOpen: showSeatInfo, isSelected: !!occupiedBy }), document.querySelector('body')))));
});
export default React.memo(Seat);
