import { __assign, __rest } from "tslib";
import * as React from 'react';
import ServiceSummaryOption from '../../ServiceSummary/components/ServiceSummaryOption';
import { getPassengerTypeLabel } from '../../utils';
var CartItemOptionAdapter = function (_a) {
    var _b;
    var _c = _a.option, headerType = _c.headerType, traveller = _c.traveller, option = __rest(_c, ["headerType", "traveller"]);
    var itemHeader = option.header;
    if (headerType === 'travellerName') {
        itemHeader =
            (_b = traveller.name) !== null && _b !== void 0 ? _b : "".concat(getPassengerTypeLabel(traveller.type, traveller.supplierTravellerType), " ").concat(traveller.typeNumber);
    }
    return (React.createElement(ServiceSummaryOption, { groupOption: __assign(__assign({}, option), { header: itemHeader }) }));
};
export default CartItemOptionAdapter;
