import { __assign } from "tslib";
import { RefundMode, StepType, Unvoluntary } from './types';
import { initialState as stepsInitialState } from './store/steps/reducer';
import { TravellerFieldEnum } from '@websky/graphql';
import { concatPassengerNames, getUserValue } from '../utils';
var isSplitAllowed = function (passengerType, isChildRefundWithoutAdultAllowed) {
    return passengerType === 'CLD' && isChildRefundWithoutAdultAllowed;
};
var getPassengerName = function (traveller) {
    var firstName = getUserValue(traveller, TravellerFieldEnum.FirstName);
    var lastName = getUserValue(traveller, TravellerFieldEnum.LastName);
    return concatPassengerNames(lastName, firstName);
};
var getPassengerStateObject = function (travellers, traveller, childRefundWithoutAdultAllowed, adultsCount, isSelected) {
    return {
        id: traveller.id,
        name: getPassengerName(traveller),
        children: travellers
            .filter(function (orderTraveller) {
            var _a;
            var isSplitPassengerAllowed = isSplitAllowed(orderTraveller.type, childRefundWithoutAdultAllowed);
            var isLinkedChild = ((_a = orderTraveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id) === traveller.id;
            return isLinkedChild && (!isSplitPassengerAllowed || (isSplitPassengerAllowed && adultsCount === 1));
        })
            .map(function (child) { return ({
            name: getPassengerName(child),
            id: child.id,
            selected: false
        }); }),
        selected: isSelected,
        phone: getUserValue(traveller, TravellerFieldEnum.Phone),
        email: getUserValue(traveller, TravellerFieldEnum.Email)
    };
};
export var useCheckoutOrderRefundAdapter = function (order) {
    var _a;
    var passengers = {};
    var isOnlyOnePassenger = order.travellers.length === 1;
    var adultsCount = order.travellers.filter(function (traveller) { return traveller.type === 'ADT'; }).length;
    var childRefundWithoutAdultAllowed = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.childRefundWithoutAdultAllowed;
    order.travellers.forEach(function (traveller) {
        if (traveller.linkedTraveller) {
            if (isSplitAllowed(traveller.type, childRefundWithoutAdultAllowed)) {
                passengers[traveller.id] = getPassengerStateObject(order.travellers, traveller, childRefundWithoutAdultAllowed, adultsCount, isOnlyOnePassenger);
            }
        }
        else {
            passengers[traveller.id] = getPassengerStateObject(order.travellers, traveller, childRefundWithoutAdultAllowed, adultsCount, isOnlyOnePassenger);
        }
    });
    var documents = {};
    Object.keys(passengers).forEach(function (passengerId) {
        documents[passengerId] = [];
    });
    var stepsState = isOnlyOnePassenger
        ? stepsInitialState.map(function (step) {
            if (step.type === StepType.Reason) {
                return __assign(__assign({}, step), { isActive: true });
            }
            return __assign(__assign({}, step), { isActive: false });
        })
        : stepsInitialState;
    return {
        passengers: passengers,
        reason: order.exareInfo.refundAutoMode ? Unvoluntary.Unvoluntary : null,
        steps: stepsState,
        mode: order.exareInfo.refundAutoMode ? RefundMode.Auto : RefundMode.Manual,
        customer: null,
        documents: documents,
        flightId: order.flight.id,
        orderId: order.id,
        refundInfo: {},
        aviaOrder: order
    };
};
