import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from 'react-final-form';
import cn from 'classnames';
import mime from 'mime';
import { useTheme } from '../../../../../../theme';
import { useToggleable } from '../../../../../../hooks';
import { useFileConverter } from '../../../../../../Refund/Components/AddFile/hooks';
import { AttachFile, CloseClear, Included } from '../../../../../../Icons';
import Switch from '../../../../../../Switch';
var ChangeGroup = function (props) {
    var t = useTranslation('Passenger').t;
    var _a = useTheme('PassengerForm'), groupTheme = _a.Group, theme = _a.ChangeGroup;
    var _b = useToggleable(props.isChangeFiles), isOpen = _b.isOpen, toggle = _b.toggle;
    var toBase64 = useFileConverter().toBase64;
    var field = props.fields[0];
    var fieldState = useField("".concat(field.name, ".changeFiles"), {
        type: field.type,
        validate: function (value) {
            if (isOpen && !value.length) {
                return t('Attach files');
            }
            return undefined;
        }
    });
    var inputFiles = fieldState.input.value;
    var isFilled = (inputFiles === null || inputFiles === void 0 ? void 0 : inputFiles.length) > 0;
    var showError = (fieldState.meta.modified && fieldState.meta.touched) || fieldState.meta.submitFailed;
    var onToggle = function () {
        var _a;
        (_a = props.onToggleChangeFiles) === null || _a === void 0 ? void 0 : _a.call(props, !isOpen);
        toggle();
    };
    var onChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var files, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = e.target.files) === null || _a === void 0 ? void 0 : _a.length)) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all(Array.from(e.target.files).map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                            var name, document;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        name = file.name;
                                        return [4 /*yield*/, toBase64(file)];
                                    case 1:
                                        document = _a.sent();
                                        return [2 /*return*/, {
                                                name: name,
                                                content: document.content,
                                                size: file.size
                                            }];
                                }
                            });
                        }); }))];
                case 2:
                    files = _b.sent();
                    fieldState.input.onChange(__spreadArray(__spreadArray([], __read(inputFiles), false), __read(files), false));
                    fieldState.input.onBlur();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _b.sent();
                    e.target.value = null;
                    console.log(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onClickPreview = function (file) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        var mimeType = mime.getType(file.name);
        var fileContent = "data:".concat(mimeType, ";base64,").concat(file.content);
        var win = window.open();
        win.document.write("<iframe src=\"".concat(fileContent, "\" frameborder=\"0\" style=\"border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;\" allowfullscreen></iframe>"));
    }; };
    var onRemoveFile = function (index) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        fieldState.input.onChange(inputFiles.filter(function (_, fileIndex) { return fileIndex !== index; }));
        fieldState.input.onBlur();
    }; };
    return (React.createElement("div", { className: cn(groupTheme.container, theme.container) },
        React.createElement("div", { className: theme.wrapper },
            React.createElement(Switch, { mainClassName: theme.toggle, activeClassName: theme.toggle_active, labelClassName: theme.toggle__label, label: React.createElement("div", { className: theme.label },
                    t('Replacement of passport based on age or change of surname upon marriage or divorce'),
                    React.createElement("div", { className: theme.description }, t('Attach supporting document'))), isActive: isOpen, onClick: onToggle })),
        React.createElement(Collapse, { in: isOpen, mountOnEnter: true, unmountOnExit: true },
            React.createElement("div", { className: theme.collapse },
                React.createElement(FormControlLabel, { classes: { root: theme.control, label: theme.control__label }, control: React.createElement("input", { className: theme.input, onChange: onChange, type: "file", accept: "image/jpeg, application/pdf", multiple: true }), label: React.createElement(React.Fragment, null,
                        React.createElement("div", { className: theme.collapse__icon }, AttachFile),
                        React.createElement("div", { className: theme.collapse__content },
                            React.createElement("div", { className: theme.collapse__contentInfo },
                                React.createElement("div", { className: theme.collapse__title },
                                    React.createElement("div", { className: theme.collapse__titleWrapper },
                                        isFilled && (React.createElement("div", { className: theme.collapse__titleIcon }, Included)),
                                        t('Attach a photocopy of the certificate'))),
                                React.createElement("div", { className: theme.collapse__format }, t('File format: jpg, pdf. File size: no more than 5MB', {
                                    nsSeparator: '|'
                                }))),
                            isFilled && (React.createElement("div", { className: theme.collapse__files }, inputFiles.map(function (file, index) { return (React.createElement("div", { key: index, className: theme.collapse__filesItem, onClick: onClickPreview(file) },
                                React.createElement("span", null, file.name),
                                React.createElement("div", { className: theme.collapse__filesItemSize },
                                    Math.ceil(file.size / 1024),
                                    " Kb"),
                                React.createElement("div", { className: theme.collapse__filesItemIcon, onClick: onRemoveFile(index) }, CloseClear))); }))),
                            showError && (React.createElement("div", { className: theme.collapse__error }, fieldState.meta.error || fieldState.meta.submitError)))) })))));
};
export default ChangeGroup;
