import i18n from 'i18next';
export var getTransferShortLabel = function (segments) {
    if (segments.length > 1) {
        var transfers_1 = [];
        segments.forEach(function (_a, index) {
            var segment = _a.segment;
            if (index > 0) {
                transfers_1.push(segment.departure.airport.iata);
            }
        });
        return "".concat(transfers_1.length, " ").concat(i18n.t('MobileSegmentInfo:stop'), " ").concat(i18n.t('MobileSegmentInfo:in'), " ").concat(transfers_1.join(', '), " \u2022 ");
    }
    return '';
};
export var isDifferentAirportName = function (airport) {
    return airport.city && airport.city.name !== airport.name;
};
// this function used in new version of mobileSegmentInfo component
export var getTransferInfo = function (segments) {
    if (segments.length < 1) {
        return {
            iata: null,
            transfersCount: 0
        };
    }
    var transfersIata = segments.slice(1).map(function (segment) { return segment.segment.departure.airport.iata; });
    return {
        iata: transfersIata,
        transfersCount: transfersIata.length
    };
};
