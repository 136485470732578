import { __assign } from "tslib";
import { format, startOfWeek } from 'date-fns';
import { SET_LOCATION, SWITCH_LOCATION } from './actions';
import { API_DATE_FORMAT } from '../../../utils';
var initialState = {
    departure: null,
    arrival: null,
    date: format(startOfWeek(new Date(), { weekStartsOn: 1 }), API_DATE_FORMAT)
};
export var segmentsReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_LOCATION:
            return __assign(__assign({}, state), (_a = {}, _a[action.payload.locationType] = action.payload.location, _a));
        case SWITCH_LOCATION:
            return __assign(__assign({}, state), { departure: state.arrival, arrival: state.departure });
        default:
            return state;
    }
};
