import * as React from 'react';
import { useTheme } from '../../../theme';
var SectionWrapper = function (_a) {
    var title = _a.title, children = _a.children;
    var css = useTheme('TravellerForm').SectionWrapper;
    return (React.createElement("div", { className: css.sectionWrapper },
        title && React.createElement("div", { className: css.sectionWrapper__title }, title),
        React.createElement("div", { className: css.sectionWrapper__content }, children)));
};
export default SectionWrapper;
