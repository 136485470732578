import { __values } from "tslib";
import { createSelector } from 'reselect';
import { getCustomerData, getPassengers } from '../order/selectors';
import { TravellerFieldEnum } from '@websky/graphql';
import { PassengerType } from '../../../../enums';
import { getUserValue } from '../../../../utils';
export var getSelectedPassengerIds = function (state) { return state.passengers; };
export var getFirstSelectedPassengerRequestFormInitialData = createSelector(getSelectedPassengerIds, getPassengers, getCustomerData, function (selectedPassengerIds, passengers, customer) {
    var e_1, _a;
    var firstSelectedPassengerValues = {
        name: '',
        email: '',
        phone: ''
    };
    try {
        for (var passengers_1 = __values(passengers), passengers_1_1 = passengers_1.next(); !passengers_1_1.done; passengers_1_1 = passengers_1.next()) {
            var passenger = passengers_1_1.value;
            if (selectedPassengerIds.includes(passenger.id) && passenger.type === PassengerType.Adult) {
                firstSelectedPassengerValues.name =
                    getUserValue(passenger, TravellerFieldEnum.FirstName) || customer.name;
                firstSelectedPassengerValues.email =
                    getUserValue(passenger, TravellerFieldEnum.Email) || customer.email;
                firstSelectedPassengerValues.phone =
                    getUserValue(passenger, TravellerFieldEnum.Phone) || customer.phone;
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (passengers_1_1 && !passengers_1_1.done && (_a = passengers_1.return)) _a.call(passengers_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return firstSelectedPassengerValues;
});
