import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ThanksBanner from '../ThanksBanner/ThanksBanner';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { Form } from '../Form/Form';
import { StepType } from '../../../types';
import { ThemeProvider, useTheme } from '../../../../theme';
import { setPassengerValues as setPassengerValuesAction } from '../../../store/passengers/actions';
import Actualization from '../Actualization/Actualization';
import { fillOrder as fillOrderAction } from '../../../store/order/actions';
import { getOrder, getAnimalServices, getIsFareLockConfirmed, getIsFareLockSelected, getIsTravellersDataFilled, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getTravellersValueNames, getIsConfirmed, getIsConfirmedOrCancelled, getIsNew, getIsReadonly, getCustomerSubscribed } from '../../../store/order/selectors';
import { checkInternationalFlight, prepareBookingRequest, getOrderPromoCodeInfoParams, getWithValidPromoCodesFormValues } from '../../../utils';
import WarningModal from '../../../../WarningModal';
import Warnings from '../../../../Warnings';
import { OrderGoal, reachGoal } from '../../../../analytics';
import MobileToolbar from '../../../../MobileToolbar';
import { Aeroexpress, Baggage, Loyalty, Meal, Seats, Animal } from '../../../../CheckoutAdditionalService/components';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import PassengersToolbar from '../Form/PassengersToolbar/PassengersToolbar';
import { PromoMessages } from '../../../../PromoMessage/PromoMessage';
import SimpleLoader from '../../../../SimpleLoader';
import Business from '../../../../CheckoutAdditionalService/components/Business/Business';
import OrderCard from '../../../../OrderCard';
import { useDownloadOrderDocument, usePromoCodesData } from '../hooks';
import { useFareLock } from '../../../../FareLock/hooks';
import FareLock from '../../../../FareLock';
import SelectedFlight from '../SelectedFlight/SelectedFlight';
import { WarningRounded } from '../../../../Icons';
import PriorityBoarding from '../../../../CheckoutAdditionalService/components/PriorityBoarding/PriorityBoarding';
import Flights from '../Flights/Flights';
import CapsuleHotel from '../../../../CheckoutAdditionalService/components/CapsuleHotel/CapsuleHotel';
import { OverrideComponent } from '../../../../renderProps';
import { useBookOrderMutation, useOrderPromoCodeInfoQuery, FlightSegmentStatus, useCheckinInfoQuery, OrderStatus, InfoMessagePositionsEnum } from '@websky/graphql';
import { useConfig } from '../../../../context';
import PromoCodes from '../PromoCodes/PromoCodes';
import { nextStep } from '../../../../Refund/store/steps/actions';
import InfoMessages from '../../../../InfoMessages';
import OrderExareStatus from '../OrderExareStatus/OrderExareStatus';
import { pnrBlockedRegExp } from '../../../../apolloErrorLink';
import OrderInsuranceBlockSlot from '../OrderInsuranceBlockSlot/OrderInsuranceBlockSlot';
var Passengers = function (_a) {
    var _b;
    var _c, _d, _e, _f, _g, _h, _j, _k;
    var showRefundButton = _a.showRefundButton, setBookingError = _a.setBookingError, bookingError = _a.bookingError, backToResults = _a.backToResults, onSetOrder = _a.onSetOrder, onOrderBooked = _a.onOrderBooked, currentStep = _a.currentStep, _l = _a.showServiceCards, showServiceCards = _l === void 0 ? true : _l, goToPrevStep = _a.goToPrevStep, goToExchange = _a.goToExchange, goToChangePassenger = _a.goToChangePassenger, showExchangeButton = _a.showExchangeButton, goToRefund = _a.goToRefund, userInfo = _a.userInfo, refetchUser = _a.refetchUser, orderSubsidyState = _a.orderSubsidyState, setStep = _a.setStep, setNextStep = _a.setNextStep, steps = _a.steps;
    var css = useTheme('Checkout').CheckoutStyles;
    var resultsCss = useTheme('Results').Results;
    var t = useTranslation('Checkout').t;
    var _m = __read(useState(null), 2), innerFormValues = _m[0], setInnerFormValues = _m[1];
    var _o = __read(useState(false), 2), showActualizationResults = _o[0], setShowActualizationResults = _o[1];
    var _p = __read(useBookOrderMutation(), 2), bookOrder = _p[0], isBooking = _p[1].loading;
    var promoCodesData = usePromoCodesData();
    // const [cancelOrder] = useMutation<CancelOrderResponse, CancelOrderVariables>(CancelOrderQuery.CancelOrder);
    var servicesAvailability = useCheckoutServicesCardsAvailability();
    var order = useSelector(getOrder);
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var noAvailableServices = Object.keys(servicesAvailability).every(function (key) { return servicesAvailability[key] === false; });
    var isFareLockSelected = useSelector(getIsFareLockSelected);
    var isFareLockConfirmed = useSelector(getIsFareLockConfirmed);
    var animalServices = useSelector(getAnimalServices);
    var travellersMap = useSelector(getTravellersValueNames);
    var isCancelled = useSelector(getIsCancelled);
    var isReadonly = useSelector(getIsReadonly);
    var isBooked = useSelector(getIsBooked);
    var isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    var isNew = useSelector(getIsNew);
    var isConfirmed = useSelector(getIsConfirmed);
    var isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    var customerSubscribed = useSelector(getCustomerSubscribed);
    var iataCode = (_c = useConfig()) === null || _c === void 0 ? void 0 : _c.global.companyInfo.iataCode;
    var hasAnimal = iataCode === 'N4';
    var dispatch = useDispatch();
    var setPassengerValues = function (index, passengerValues) {
        return dispatch(setPassengerValuesAction(index, passengerValues));
    };
    var fillOrder = function (order) { return dispatch(fillOrderAction(order)); };
    var detailsStyles = useMemo(function () { return ({
        DesktopSummaryFlight: {
            SummaryFlight: {
                flight: css.desktopFlight__flight
            }
        },
        Passenger: {
            PassengerStyles: {
                passenger: css.form__passenger,
                header__text: css.form__passengerHeaderText
            }
        }
    }); }, [order.id]);
    var getCheckinInfoResponse = useCheckinInfoQuery({
        skip: !isConfirmedOrCancelled,
        variables: {
            params: {
                aviaOrderId: order.id
            }
        }
    }).data;
    var refetchPromoCodeInfo = useOrderPromoCodeInfoQuery({ skip: true }).refetch;
    var clearError = function () { return setBookingError(null); };
    var checkPromoCodes = function (formValues) { return __awaiter(void 0, void 0, Promise, function () {
        var promoCodeInfoParams, result, _a, data, errors, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    promoCodeInfoParams = getOrderPromoCodeInfoParams(order, formValues);
                    if (!promoCodeInfoParams) {
                        return [2 /*return*/, null];
                    }
                    result = null;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    promoCodesData.setLoading(true);
                    return [4 /*yield*/, refetchPromoCodeInfo(promoCodeInfoParams)];
                case 2:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (!errors) {
                        result = {
                            promoCodesInfo: data.OrderPromoCodeInfo
                        };
                    }
                    else {
                        result = {
                            error: (_b = errors[0]) === null || _b === void 0 ? void 0 : _b.message
                        };
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _c.sent();
                    result = {
                        error: e_1.message
                    };
                    return [3 /*break*/, 5];
                case 4:
                    promoCodesData.setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, result];
            }
        });
    }); };
    var setBookingErrorHandler = function (errors) {
        if (errors === void 0) { errors = []; }
        if (!errors.some(function (error) { return pnrBlockedRegExp.test((error === null || error === void 0 ? void 0 : error.message) || ''); })) {
            setBookingError(__assign(__assign({}, errors[0]), { message: errors[0].message }));
        }
    };
    var startBooking = function (formValues) { return __awaiter(void 0, void 0, Promise, function () {
        var _a, data, errors, error_1, apolloError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    reachGoal(OrderGoal.Book);
                    return [4 /*yield*/, bookOrder({
                            variables: prepareBookingRequest(order.id, formValues, orderSubsidyState)
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors && errors.length) {
                        setBookingErrorHandler(errors);
                        reachGoal(OrderGoal.BookingError, {
                            error: errors[0]
                        });
                        return [2 /*return*/, null];
                    }
                    else if (data && data.BookOrder) {
                        onOrderBooked(data.BookOrder);
                        return [2 /*return*/, data.BookOrder];
                    }
                    return [2 /*return*/, null];
                case 2:
                    error_1 = _b.sent();
                    apolloError = error_1;
                    order.travellers.forEach(function (traveller, index) {
                        var passengerValues = traveller.values.map(function (value) {
                            return {
                                name: value.name,
                                type: value.type,
                                value: formValues['passengers'][index][value.name]
                            };
                        });
                        setPassengerValues(index, passengerValues);
                    });
                    setBookingErrorHandler(apolloError === null || apolloError === void 0 ? void 0 : apolloError.graphQLErrors);
                    reachGoal(OrderGoal.BookingError, {
                        error: error_1
                    });
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onFormSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var promoCodesInfoResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    order.travellers.forEach(function (traveller, index) {
                        var passengerValues = traveller.values.map(function (value) {
                            return {
                                name: value.name,
                                type: value.type,
                                value: values['passengers'][index][value.name]
                            };
                        });
                        setPassengerValues(index, passengerValues);
                    });
                    setShowActualizationResults(true);
                    setInnerFormValues(values);
                    return [4 /*yield*/, checkPromoCodes(values)];
                case 1:
                    promoCodesInfoResult = _a.sent();
                    if (!!promoCodesInfoResult) return [3 /*break*/, 5];
                    if (!(order.status === OrderStatus.New)) return [3 /*break*/, 3];
                    return [4 /*yield*/, startBooking(values)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    nextStep();
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    promoCodesData.setData(promoCodesInfoResult);
                    promoCodesData.setOpen(true);
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); }, [order.travellers, order.flight.segments, order.travellers, orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.travellers, order.status]);
    var promoCodesSubmitCallBack = function () { return __awaiter(void 0, void 0, void 0, function () {
        var withValidPromoCodesValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    promoCodesData.close();
                    withValidPromoCodesValues = getWithValidPromoCodesFormValues(innerFormValues, promoCodesData.data.promoCodesInfo, travellersMap);
                    return [4 /*yield*/, startBooking(withValidPromoCodesValues)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var promoCodesCancelCallback = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            promoCodesData.setData(null);
            promoCodesData.close();
            return [2 /*return*/];
        });
    }); };
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    useEffect(function () {
        // if there is no available services in mobile, redirect to payment
        if (Object.keys(servicesAvailability).every(function (key) { return servicesAvailability[key] === false; }) &&
            isBooked &&
            isMobile) {
            setStep(StepType.Payment);
        }
    }, [isBooked]);
    var handlePriceChangedConfirmed = useCallback(function () {
        startBooking(innerFormValues);
    }, [startBooking, innerFormValues]);
    var _q = useFareLock(order, fillOrder), handleChangeFareLock = _q.handleChangeFareLock, isSelected = _q.isSelected, price = _q.price, fareLockService = _q.fareLockService;
    var renderFlights = useCallback(function () { return React.createElement(Flights, { order: order, backToResults: backToResults }); }, []);
    var renderForm = useCallback(function (inModal) {
        if (inModal) {
            return (React.createElement(OverrideComponent, { componentProps: {
                    showToolbar: false,
                    showServices: false,
                    currentStep: currentStep,
                    customer: order.customer,
                    passengers: order.travellers,
                    onFormSubmit: onFormSubmit,
                    isInternationalFlight: checkInternationalFlight(order.flight),
                    userInfo: userInfo,
                    refetchUser: refetchUser,
                    inModal: true,
                    orderSubsidyState: orderSubsidyState,
                    backToResults: backToResults,
                    setNextStep: setNextStep
                }, component: {
                    CheckoutForm: Form
                } }));
        }
        else {
            if (isBooked) {
                if (isFareLockSelected && !isFareLockConfirmed) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                            isBooked && (React.createElement(MobileToolbar, { onBack: goToPrevStep, onContinue: function () { return setStep(StepType.Payment); } })),
                            isConfirmed && (React.createElement(PassengersToolbar, { setNextStep: setNextStep, currentStep: currentStep }))),
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, isBookedOrConfirmed && (isTravellersDataFilled || isFareLockSelected) && (React.createElement(PassengersToolbar, { setNextStep: setNextStep, currentStep: currentStep })))));
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.form },
                        React.createElement(OverrideComponent, { componentProps: {
                                showServices: false,
                                showContacts: false,
                                showForm: !isTravellersDataFilled,
                                currentStep: currentStep,
                                customer: order.customer,
                                passengers: order.travellers,
                                onFormSubmit: onFormSubmit,
                                isInternationalFlight: checkInternationalFlight(order.flight),
                                userInfo: userInfo,
                                refetchUser: refetchUser,
                                orderSubsidyState: orderSubsidyState,
                                backToResults: backToResults,
                                setNextStep: setNextStep
                            }, component: {
                                CheckoutForm: Form
                            } }))));
            }
            if (isNew || isConfirmed) {
                return (React.createElement(React.Fragment, null,
                    isNew && !isMobile && renderFlights(),
                    React.createElement("div", { className: css.form },
                        React.createElement(OverrideComponent, { componentProps: {
                                showContacts: isNew || (isConfirmed && !isMobile),
                                showForm: isNew || !isTravellersDataFilled,
                                showServices: false,
                                currentStep: currentStep,
                                customer: order.customer,
                                passengers: order.travellers,
                                onFormSubmit: onFormSubmit,
                                isInternationalFlight: checkInternationalFlight(order.flight),
                                userInfo: userInfo,
                                refetchUser: refetchUser,
                                orderSubsidyState: orderSubsidyState,
                                backToResults: backToResults,
                                setNextStep: setNextStep
                            }, component: {
                                CheckoutForm: Form
                            } }))));
            }
            return null;
        }
    }, [
        order.status,
        order.customer,
        order.travellers,
        isFareLockSelected,
        isFareLockConfirmed,
        currentStep,
        isTravellersDataFilled,
        userInfo,
        refetchUser,
        orderSubsidyState,
        setNextStep,
        customerSubscribed
    ]);
    var canceledFlights = React.useMemo(function () {
        return order.flight.segmentGroups.filter(function (group) {
            return group.segments.some(function (segment) { return segment.status === FlightSegmentStatus.Canceled; });
        });
    }, [order.flight]);
    var _r = useDownloadOrderDocument(), isDocumentsDownload = _r.loading, handleDownload = _r.handleDownload, docError = _r.error, docWarningIsOpen = _r.warningIsOpen, docWarningClose = _r.warningClose;
    var isLoading = isBooking || isDocumentsDownload || promoCodesData.loading;
    var modalWarningContent = t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.');
    var modalErrorMessage = bookingError ? t(bookingError.message.replace('GraphQL error: ', '')) : '';
    if ((bookingError === null || bookingError === void 0 ? void 0 : bookingError.message) === 'Не удалось создать заказ с детьми без сопровождения совершеннолетними пассажирами' ||
        (bookingError === null || bookingError === void 0 ? void 0 : bookingError.message) === 'The child is too young to fly without an adult') {
        modalWarningContent = t('Placing an order with a child not accompanied by an adult is prohibited.');
        modalErrorMessage = t('The child is too young to fly without an adult');
    }
    return (React.createElement("div", { className: cn(css.checkout, (_b = {},
            _b[css.checkout_margined] = isCancelled,
            _b)) },
        isLoading ? React.createElement(SimpleLoader, null) : null,
        React.createElement(WarningModal, { title: t('Oops...'), content: modalWarningContent, errorMessage: modalErrorMessage, onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: !!bookingError }),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.'), errorMessage: docError ? docError.message.replace('GraphQL error: ', '') : '', onClose: docWarningClose, onButtonClick: docWarningClose, buttonText: t('OK, thank you'), isOpen: !!docWarningIsOpen }),
        React.createElement("div", null,
            isConfirmed && React.createElement(ThanksBanner, null),
            isConfirmed && canceledFlights.length > 0 && (React.createElement("div", { className: css.notice },
                WarningRounded,
                ' ',
                t('Attention, times have changed for flight {{route}}', {
                    route: "".concat(canceledFlights[0].segments[0].departure.airport.city.name, " - ").concat(canceledFlights[0].segments[canceledFlights[0].segments.length - 1].arrival.airport.city
                        .name)
                }))),
            !isReadonly && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: css.passengers__header },
                    React.createElement("h1", { className: css.passengers__header__title }, t('Passengers'))))),
            React.createElement(OrderExareStatus, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger }),
            isConfirmedOrCancelled && (React.createElement("div", { className: css.paidOrderWrapper },
                React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, onDownloadClick: handleDownload, order: order, isCheckinOpen: (_d = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _d === void 0 ? void 0 : _d.isAvailable, type: 'review' }))),
            React.createElement("div", { className: css.summary__wrp },
                isBooked && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("h3", { className: css.summary__header }, t('Review your trip')))),
                !!((_e = order.messages) === null || _e === void 0 ? void 0 : _e.length) && (React.createElement("div", { className: css.messages },
                    React.createElement(InfoMessages, { messages: order.messages, position: InfoMessagePositionsEnum.default, className: resultsCss.messages, itemHtmlClassName: resultsCss.messages__htmlMessage, itemClassName: css.messages_item }),
                    React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutPassengersNotifications: function () { return null; } } }))),
                !isConfirmed && isFareLockSelected && isBooked && (React.createElement("div", { className: css.fareLockWrapper },
                    React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, onChange: handleChangeFareLock, isActive: isSelected, confirmed: isSelected || isFareLockConfirmed, addedToOrder: true }))),
                isBooked && (React.createElement("div", { className: css.summary },
                    React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, order: order, isCheckinOpen: ((_f = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _f === void 0 ? void 0 : _f.isAvailable) || true, type: 'review' })))),
            (isBookedOrConfirmed || isConfirmedOrCancelled) && (React.createElement(OverrideComponent, { component: { CheckoutSelectedFlight: SelectedFlight }, componentProps: {
                    orderId: order.id,
                    flight: order.flight,
                    subsidized: order.hasSubsidizedFares,
                    confirmed: isConfirmedOrCancelled,
                    canceled: isCancelled
                } })),
            showServiceCards && isBookedOrConfirmed && !noAvailableServices && isTravellersDataFilled && (React.createElement(React.Fragment, null, Object.values(servicesAvailability).includes(true) && (React.createElement("div", { className: css.checkoutServices },
                servicesAvailability.Seat && (React.createElement(Seats, { isCheckinAvailable: (_g = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _g === void 0 ? void 0 : _g.isAvailable })),
                servicesAvailability.Baggage && React.createElement(Baggage, null),
                servicesAvailability.Meal && React.createElement(Meal, null),
                servicesAvailability.Insurance && (React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutInsurance: OrderInsuranceBlockSlot } })),
                servicesAvailability.Aeroexpress && React.createElement(Aeroexpress, null),
                ((_h = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _h === void 0 ? void 0 : _h.ffpSupported) && (React.createElement(Loyalty, { userInfo: userInfo, refetchUser: refetchUser })),
                servicesAvailability.BusinessLounge && React.createElement(Business, null),
                servicesAvailability.PriorityBoarding && React.createElement(PriorityBoarding, null),
                servicesAvailability.BusinessLounge && React.createElement(CapsuleHotel, null),
                hasAnimal && animalServices.length > 0 && React.createElement(Animal, null))))),
            isNew && (React.createElement(ThemeProvider, { value: {
                    PromoMessage: {
                        PromoMessage: {
                            wrapper: css.promoMessageWrapper
                        }
                    }
                } },
                React.createElement(PromoMessages, null))),
            React.createElement(ThemeProvider, { value: detailsStyles }, renderForm()),
            !isBookedOrConfirmed && order.warnings && !!order.warnings.length && (React.createElement(Warnings, { warnings: order.warnings }))),
        !isBookedOrConfirmed && (React.createElement(Actualization, { startBooking: handlePriceChangedConfirmed, orderId: order.id, open: showActualizationResults, onClose: function () { return setShowActualizationResults(false); }, onSetOrder: onSetOrder, actualizationInfo: order.actualizationResult, backToResults: backToResults, showOkMessage: false })),
        React.createElement(PromoCodes, { isOpen: promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.isOpen, onSubmit: promoCodesSubmitCallBack, onCancel: promoCodesCancelCallback, promoCodesInfo: (_j = promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.data) === null || _j === void 0 ? void 0 : _j.promoCodesInfo, formValues: innerFormValues, error: (_k = promoCodesData === null || promoCodesData === void 0 ? void 0 : promoCodesData.data) === null || _k === void 0 ? void 0 : _k.error })));
};
export default Passengers;
