import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import cn from 'classnames';
import { useToggleable } from '../../../../hooks';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { Expand } from '../../../../Icons';
var Receipt = function (_a) {
    var _b;
    var className = _a.className, totalText = _a.totalText, totalPrice = _a.totalPrice, detailPrices = _a.detailPrices;
    var t = useTranslation('EditPassenger').t;
    var theme = useTheme('EditPassenger').Receipt;
    var _c = useToggleable(false), isOpen = _c.isOpen, toggle = _c.toggle;
    var hasDetailPrices = detailPrices && detailPrices.length > 0;
    var onToggle = function () {
        if (hasDetailPrices) {
            toggle();
        }
    };
    return (React.createElement("div", { className: cn(theme.container, className) },
        React.createElement("div", { className: cn(theme.summary, (_b = {},
                _b[theme.summary_collapsible] = hasDetailPrices,
                _b[theme.summary_expand] = isOpen,
                _b)), onClick: onToggle },
            React.createElement("div", { className: theme.summary__title },
                totalText || t('Total to pay'),
                React.createElement(Money, { moneyClassName: theme.summary__money, money: totalPrice })),
            hasDetailPrices && React.createElement("div", { className: theme.summary__arrow }, Expand)),
        hasDetailPrices && (React.createElement(Collapse, { in: isOpen, mountOnEnter: true, unmountOnExit: true },
            React.createElement("div", { className: theme.details }, detailPrices.map(function (price, index) { return (React.createElement("div", { className: theme.details__row, key: index },
                React.createElement("span", null, price.title),
                React.createElement(Money, { moneyClassName: theme.details__money, money: price.price }))); }))))));
};
export default Receipt;
