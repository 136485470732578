import * as React from 'react';
import { useTranslation } from 'react-i18next';
import UpgradeBaggageDialog from '../../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
var UpgradeModal = function (props) {
    var t = useTranslation('IncludedBaggage').t;
    return (React.createElement(UpgradeBaggageDialog, { className: props.className, open: props.open, onClose: props.onClose, onConfirm: props.onConfirm, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, upgradeBaggage: props.upgradeBaggage, disabled: props.disabled, selectedServices: props.selectedServices, headerProps: {
            header: t('Upgrade'),
            description: ''
        }, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: props.isLoading }));
};
export default UpgradeModal;
