import { __awaiter, __generator, __read } from "tslib";
import { useLogoutMutation } from '@websky/graphql';
import { useHistory } from 'react-router-dom';
import * as Cookie from 'js-cookie';
import { GRAPHQL_KEY } from '../../cache';
export var useLogout = function (replace) {
    var history = useHistory();
    var _a = __read(useLogoutMutation(), 2), logoutMutation = _a[0], loading = _a[1].loading;
    var onLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    Cookie.remove(GRAPHQL_KEY);
                    return [4 /*yield*/, logoutMutation()];
                case 1:
                    _a.sent();
                    if (replace) {
                        history.replace(replace);
                    }
                    window.location.reload();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return { onLogout: onLogout, loading: loading };
};
