import * as React from 'react';
import cn from 'classnames';
import { parse } from 'date-fns';
import { useTheme } from '../../../theme';
import { format, removeDotsFromDate, TABLET_MIDDLE_WIDTH } from '../../../utils';
import { weatherIcons } from '../../../Weather/icons';
import { getTemperaturePrefix } from '../../../Weather/utils';
import { Land, Takeoff } from '../../../Icons';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { TemperatureUnits } from '@websky/graphql';
var Location = function (_a) {
    var _b, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var className = _a.className, airport = _a.airport, date = _a.date, time = _a.time, weather = _a.weather, type = _a.type, isMobileProps = _a.isMobile, terminal = _a.terminal;
    var css = useTheme('DesktopFlight').Location;
    var t = useTranslation('Location').t;
    console.log(terminal);
    var flightDate = parse(date, 'yyyy-MM-dd', new Date());
    var weatherIcon = (weather === null || weather === void 0 ? void 0 : weather.type) && weatherIcons[weather.type];
    var image = (_h = (_g = (_f = (_e = airport === null || airport === void 0 ? void 0 : airport.city) === null || _e === void 0 ? void 0 : _e.images) === null || _f === void 0 ? void 0 : _f.panorama) === null || _g === void 0 ? void 0 : _g.url) !== null && _h !== void 0 ? _h : (_k = (_j = airport.images) === null || _j === void 0 ? void 0 : _j.panorama) === null || _k === void 0 ? void 0 : _k.url;
    var flightIcon = {
        arrival: Land,
        departure: Takeoff
    };
    var mobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH }) || isMobileProps;
    var titleEqualsAirportName = ((_l = airport.city) === null || _l === void 0 ? void 0 : _l.name) === airport.title;
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.isMobile] = mobile,
            _b)) },
        React.createElement("div", { className: css.info },
            React.createElement("span", { className: css.time },
                time,
                React.createElement("span", { className: css.time_iata }, (_m = airport.iata) === null || _m === void 0 ? void 0 : _m.toUpperCase())),
            React.createElement("span", { className: css.date },
                React.createElement("div", { className: css.date_icon }, flightIcon[type]),
                removeDotsFromDate(format(flightDate, 'dd MMM, EEEEEE')))),
        React.createElement("div", { className: css.airport },
            React.createElement("span", { className: css.city }, ((_o = airport.city) === null || _o === void 0 ? void 0 : _o.name) ? (_p = airport.city) === null || _p === void 0 ? void 0 : _p.name : airport.title),
            React.createElement("span", { className: css.terminal },
                React.createElement("span", { className: css.terminal_city }, (_q = airport.city) === null || _q === void 0 ? void 0 : _q.name),
                !mobile && airport.iata && (React.createElement("span", { className: css.terminal_iata }, titleEqualsAirportName ? airport.iata : "(".concat(airport.iata, ")"))),
                !titleEqualsAirportName && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: cn(css.terminal_airport, (_c = {},
                            _c[css.terminal_airportTerminal] = terminal,
                            _c)) }, airport.title))),
                terminal && (React.createElement("span", { className: css.terminal_number },
                    t('Terminal'),
                    " ",
                    terminal)))),
        React.createElement("div", { className: cn(css.weather, (_d = {},
                _d[css.weather_gradient] = weather,
                _d)), style: image ? { backgroundImage: "url(".concat(image, ")") } : {} }, weather && (React.createElement("div", { className: css.weather_wrapper },
            weatherIcon,
            React.createElement("span", { className: css.weather_temp },
                getTemperaturePrefix(weather.temperature),
                Math.abs(Math.round(weather.temperature)),
                "\u00B0",
                weather.measurement === TemperatureUnits.Fahrenheit && 'F'))))));
};
export default Location;
