import { TravellerFieldEnum } from '@websky/graphql';
export var initialPassengersValuesFactory = function (passengers, withDisabilities) {
    return passengers.map(function (passenger) {
        var values = {
            type: passenger.type
        };
        if (withDisabilities) {
            values.disabilities = { enabled: false, values: {} };
        }
        passenger.values.forEach(function (value) {
            if (value.type === TravellerFieldEnum.Nationality &&
                !value.validationRules[0].options.find(function (option) { return option.value === value.value; })) {
                value.value = value.validationRules[0].options[0].value;
            }
            else {
                values[value.name] = value.value;
            }
        });
        return values;
    });
};
