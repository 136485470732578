import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import cn from 'classnames';
import AdditionalService from '../../../AdditionalServiceCard/components/AdditionalService';
import { useTheme } from '../../../theme';
import { alfaInsuranceLogo, UmbrellaIcon } from './logo';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { SlideBottom } from '../../../index';
import DialogContent from './DialogContent/DialogContent';
import Money from '../../../Money';
import { DisplayMode } from '../types';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { getTotalPrice, initI18n } from '../../../utils';
initI18n('Checkout');
var Insurance = function (_a) {
    var _b;
    var displayMode = _a.displayMode, insurance = _a.insurance, _c = _a.selectedInsurances, selectedInsurances = _c === void 0 ? [] : _c, onClick = _a.onClick, isMobileStepbar = _a.isMobileStepbar, isBlockForPayment = _a.isBlockForPayment;
    var _d = __read(useState(false), 2), confirmationPopupIsOpen = _d[0], setConfirmationPopupIsOpen = _d[1];
    var _e = __read(useState(false), 2), addingIsInProgress = _e[0], setAddingIsInProgress = _e[1];
    var _f = __read(useState(false), 2), refusingIsInProgress = _f[0], setRefusingIsInProgress = _f[1];
    var isHorizontal = displayMode === DisplayMode.Horizontal;
    var t = useTranslation('Checkout').t;
    var css = useTheme('AdditionalServices').Insurance;
    var _g = useUpsaleWarning(), showWarning = _g.showWarning, closeWarning = _g.closeWarning, toggleWarningIfOrderConfirmed = _g.toggleWarningIfOrderConfirmed;
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var closeConfirmationPopup = useCallback(function () { return !refusingIsInProgress && setConfirmationPopupIsOpen(false); }, []);
    var openConfirmationPopup = useCallback(function () {
        if (shouldRedirect) {
            toggleWarningIfOrderConfirmed();
            return;
        }
        setConfirmationPopupIsOpen(true);
    }, [shouldRedirect]);
    var onInsuranceAdd = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddingIsInProgress(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onClick(__spreadArray(__spreadArray([], __read(selectedInsurances.map(function (_a) {
                            var insuranceProgram = _a.insuranceProgram;
                            return insuranceProgram.code;
                        })), false), [insurance.code], false))];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setAddingIsInProgress(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onInsuranceRemove = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setRefusingIsInProgress(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onClick([])];
                case 2:
                    _a.sent();
                    closeConfirmationPopup();
                    setTimeout(function () { return setRefusingIsInProgress(false); }, 500);
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2);
                    setRefusingIsInProgress(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var activeInsurance = selectedInsurances &&
        selectedInsurances.find(function (_a) {
            var insuranceProgram = _a.insuranceProgram;
            return insuranceProgram.code === insurance.code;
        });
    var totalInsurancePrice = useMemo(function () { return getTotalPrice(selectedInsurances, function (insurance) { return insurance.passengerPrice; }); }, [selectedInsurances]);
    var openPolicy = function () { return window.open(activeInsurance.policyUrl, '_blank'); };
    var handleInsuranceClick = useCallback(function () {
        if (isBlockForPayment) {
            return;
        }
        if (activeInsurance) {
            if (activeInsurance.policyUrl) {
                openPolicy();
            }
            else {
                openConfirmationPopup();
            }
        }
        else {
            onInsuranceAdd();
        }
    }, [activeInsurance, isBlockForPayment]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        !isMobileStepbar && (React.createElement(AdditionalService, { horizontal: displayMode === DisplayMode.Horizontal, service: {
                header: 'Insurance',
                price: insurance.totalPrice,
                subheader: insurance.name,
                icon: UmbrellaIcon,
                content: insurance.description,
                logo: isHorizontal ? null : React.createElement("div", { className: css.logo }, alfaInsuranceLogo),
                active: !!activeInsurance
            }, isLoading: addingIsInProgress, buttonText: t(activeInsurance ? (activeInsurance.policyUrl ? 'Download policy' : 'Cancel') : 'Add'), onClick: handleInsuranceClick })),
        isMobileStepbar && (React.createElement(React.Fragment, null, activeInsurance ? (React.createElement("div", { className: css.stepbar__actions, onClick: handleInsuranceClick },
            React.createElement(Money, { money: totalInsurancePrice }),
            !isBlockForPayment && (React.createElement("i", { className: cn((_b = {},
                    _b[css.stepbar__action_openPolicy] = activeInsurance.policyUrl,
                    _b[css.stepbar__action_close] = !activeInsurance.policyUrl,
                    _b)) })))) : (!isBlockForPayment && React.createElement("span", { onClick: handleInsuranceClick }, t('Add'))))),
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Dialog, { open: confirmationPopupIsOpen, onClose: closeConfirmationPopup, classes: { paper: css.popup } },
                React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: refusingIsInProgress }))),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { onClose: closeConfirmationPopup, isOpen: confirmationPopupIsOpen },
                React.createElement("div", { className: css.slide__content },
                    React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationPopup, onInsuranceRemove: onInsuranceRemove, refusingIsInProgress: refusingIsInProgress }))))));
};
export default Insurance;
