import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import OrderPreview from './OrderPreview/OrderPreview';
import OrderStatus from './OrderStatus/OrderStatus';
import OrderBooking from './OrderBooking/OrderBooking';
import OrderPassengers from './OrderPassengers/OrderPassengers';
import OrderCardControls from './OrderCardControls/OrderCardControls';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { OrderStatus as OrderStatusType } from '@websky/graphql';
import OrderTickets from './OrderTickets/OrderTickets';
import Button from '../../Button/Button';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import OrderChange from './OrderChange/OrderChange';
import { OverrideComponent } from '../../renderProps';
import { useConfig } from '../../context';
import DeleteOrderModal from './DeleteOrderModal/DeleteOrderModal';
initI18n('OrderCard');
export var OrderCardContext = React.createContext(null);
var OrderCard = function (props) {
    var _a, _b;
    var _c, _d;
    var type = props.type, accountOrderAdditionalItem = props.accountOrderAdditionalItem, accountOrderAdditionalButtons = props.accountOrderAdditionalButtons;
    var css = useTheme('OrderCard').OrderCard;
    var t = useTranslation('OrderCard').t;
    var _e = useConfig().global, reserveEngines = _e.reserveEngines, iataCode = _e.companyInfo.iataCode;
    var isArmeniaOrGeorgian = iataCode === 'RM' || iataCode === 'A9';
    var onClickHandler = function () {
        if (typeof props.onClick === 'function') {
            props.onClick(props.order.id);
        }
    };
    var exchangeAvailable = (_c = props.order.exareInfo) === null || _c === void 0 ? void 0 : _c.exchangeAvailability;
    var refundAvailable = (_d = props.order.exareInfo) === null || _d === void 0 ? void 0 : _d.refundAvailability;
    var onOrderChangeClick = function (buttonType) {
        buttonType === 'exchange' ? props.goToExchange() : props.goToRefund();
    };
    return (React.createElement(OrderCardContext.Provider, { value: props },
        React.createElement("div", { className: cn(css.container, (_a = {},
                _a[css.type_review] = type === 'review',
                _a[css.type_account] = type === 'account',
                _a)) },
            React.createElement(OrderPreview, { className: css.preview, onClick: props.onClick && onClickHandler }),
            React.createElement("div", { className: cn(css.root, (_b = {}, _b[css.orderConfirmed] = props.order.status === OrderStatusType.Confirmed, _b)) },
                type === 'review' && (React.createElement(React.Fragment, null,
                    React.createElement(PaymentStatus, null),
                    React.createElement(OverrideComponent, { componentProps: {
                            className: cn(css.item, css.status)
                        }, component: { renderOrderCardStatus: OrderStatus } }),
                    React.createElement(OverrideComponent, { componentProps: {
                            className: cn(css.item, css.booking),
                            onDownloadTicket: props.onDownloadClick
                        }, component: {
                            renderOrderCardBooking: OrderBooking
                        } }),
                    (!(reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) || !isArmeniaOrGeorgian) && React.createElement(OrderCardControls, null),
                    React.createElement(OrderPassengers, { className: cn(css.item, css.passengers), goToChangePassenger: props.goToChangePassenger }),
                    props.order.status === OrderStatusType.Confirmed && type === 'review' && (React.createElement(OrderTickets, { className: cn(css.item, css.tickets) })),
                    (exchangeAvailable || refundAvailable) && (React.createElement(OrderChange, { onClick: onOrderChangeClick, exchangeAvailable: exchangeAvailable, refundAvailable: refundAvailable })))),
                type === 'account' && (React.createElement(React.Fragment, null,
                    React.createElement(OrderBooking, { className: cn(css.item, css.booking) }),
                    React.createElement(OrderPassengers, { className: cn(css.item, css.passengers) }),
                    React.createElement(PaymentStatus, null), accountOrderAdditionalItem === null || accountOrderAdditionalItem === void 0 ? void 0 :
                    accountOrderAdditionalItem(),
                    React.createElement("div", { className: css.buttons },
                        React.createElement(DeleteOrderModal, { orderId: props.order.id, orderLocator: props.order.locator, onDeleted: props.onDeleted }),
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, accountOrderAdditionalButtons === null || accountOrderAdditionalButtons === void 0 ? void 0 :
                            accountOrderAdditionalButtons(),
                            React.createElement(Button, { variant: "flat", size: "common", className: css.openOrder, onClick: onClickHandler }, t('Open'))))))))));
};
export default OrderCard;
