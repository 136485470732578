import { __assign, __values } from "tslib";
import { reachGoal } from '../analytics';
import { DataLayerEvent } from './types';
var DATA_LAYER_KEY = 'dataLayer';
var EMPTY_DATA_LAYER_EVENT = {
    ecommerce: null
};
var DataLayerAnalytics = /** @class */ (function () {
    function DataLayerAnalytics(initParams) {
        if (initParams === void 0) { initParams = {}; }
        var _this = this;
        this.commonParams = {};
        this.setCommonParams = function (params) {
            _this.commonParams = __assign(__assign({}, _this.commonParams), params);
        };
        this.initParams = initParams;
        if (process.env.NODE_ENV === 'development') {
            this.debugMode = true;
        }
    }
    DataLayerAnalytics.debugEvent = function (eventName, event) {
        console.log("DATA_LAYER: ".concat(eventName), event);
    };
    DataLayerAnalytics.createInstance = function () {
        return new DataLayerAnalytics();
    };
    DataLayerAnalytics.prototype.sendDataLayerEvent = function (event) {
        window[DATA_LAYER_KEY] = window[DATA_LAYER_KEY] || [];
        window[DATA_LAYER_KEY].push(event);
        if (this.debugMode) {
            DataLayerAnalytics.debugEvent((event === null || event === void 0 ? void 0 : event.event) || 'Empty', event);
        }
    };
    DataLayerAnalytics.prototype.pushEmptyToDataLayer = function () {
        this.sendDataLayerEvent(EMPTY_DATA_LAYER_EVENT);
    };
    DataLayerAnalytics.prototype.sendVisitParameters = function (event) {
        var _a, _b;
        var visitParameters = {
            main: (_a = {},
                _a[window.location.hostname] = (_b = {},
                    _b[event.event] = {
                        ecommerce: {}
                    },
                    _b),
                _a),
            extra: {
                ecommerce: JSON.stringify(event.ecommerce)
            }
        };
        reachGoal(event.event, visitParameters);
    };
    DataLayerAnalytics.prototype.pushToDataLayer = function (event, ecommerceItems, params, itemsListName) {
        var _this = this;
        var _a, _b;
        if (ecommerceItems === void 0) { ecommerceItems = null; }
        if (params === void 0) { params = {}; }
        var eventObj = {
            event: event,
            ecommerce: __assign({ item_list_name: itemsListName, coupon: (_a = this.initParams.coupon) !== null && _a !== void 0 ? _a : null, currency: (_b = this.initParams.currency) !== null && _b !== void 0 ? _b : null, items: ecommerceItems.map(function (item) { return (__assign(__assign({}, _this.commonParams), item)); }) }, params)
        };
        this.addValueToEvent(eventObj);
        this.pushEmptyToDataLayer();
        this.sendDataLayerEvent(eventObj);
        this.sendVisitParameters(eventObj);
    };
    DataLayerAnalytics.prototype.init = function (params) {
        this.initParams = params;
    };
    DataLayerAnalytics.prototype.addValueToEvent = function (eventObj) {
        var e_1, _a;
        var _b;
        switch (eventObj.event) {
            case DataLayerEvent.AddToCart:
            case DataLayerEvent.ViewItem:
            case DataLayerEvent.Checkout:
            case DataLayerEvent.Purchase:
            case DataLayerEvent.RemoveFromCart:
                if (!((_b = eventObj === null || eventObj === void 0 ? void 0 : eventObj.ecommerce) === null || _b === void 0 ? void 0 : _b.items)) {
                    return eventObj;
                }
                var value = 0;
                try {
                    for (var _c = __values(eventObj.ecommerce.items), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var item = _d.value;
                        if (item.price && item.quantity) {
                            value += item.price * item.quantity;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                eventObj.ecommerce.value = value;
                return eventObj;
            default:
                return eventObj;
        }
    };
    return DataLayerAnalytics;
}());
export { DataLayerAnalytics };
var instance = DataLayerAnalytics.createInstance();
export default instance;
