import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import MediaQuery from 'react-responsive';
import Button from '../../../Button/Button';
import { closeDialogIcon } from '../../Icons';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { useTheme } from '../../../theme';
var DeleteAccountDialog = function (props) {
    var _a;
    var css = useTheme('Account').DeleteAccountDialog, _b = __read(useState(false), 2), disabledButton = _b[0], setDisabledButton = _b[1], t = useTranslation('Account').t;
    var closeDialog = function () {
        props.onClose();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("span", { onClick: closeDialog, className: css.closeButton }, closeDialogIcon)),
        React.createElement("div", { className: css.Dialog },
            React.createElement("h3", { className: css.title }, t('Are you sure?')),
            React.createElement("p", { className: css.text }, t('Are you sure you want to delete your account?')),
            React.createElement("div", { className: css.actions },
                React.createElement(Button, { disabled: disabledButton, className: cn((_a = {},
                        _a[css.disabled] = disabledButton,
                        _a)), onClick: function () {
                        setDisabledButton(true);
                        props.onRemoveAccount();
                        props.onClose();
                    } }, t('Yes, Delete account')),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Button, { className: css.close__slide__button, onClick: props.onClose }, t('No')))))));
};
export default DeleteAccountDialog;
