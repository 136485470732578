import { __assign } from "tslib";
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ModeContext } from '../../../context';
import { Mode } from '../../../types';
import { getOrder } from '../../../Checkout/store/order/selectors';
import { getCheckinOrder } from '../../../Modules/Checkin/store/order/selectors';
import { useInsuranceState } from '../../store';
import * as React from 'react';
import { scrollTo } from '../../../utils';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import { analytics_addInsurance, analytics_removeInsurance } from '../../../Insurance/utils';
import { useImportantInsurancesTouched } from '../../hooks';
export var useInsurancePage = function (_a) {
    var availablePrograms = _a.availablePrograms, onTotalInsurancePriceChange = _a.onTotalInsurancePriceChange, onSaveOrderServicesParamsChange = _a.onSaveOrderServicesParamsChange, onServicesChanged = _a.onServicesChanged;
    var mode = useContext(ModeContext);
    var order = mode === Mode.Booking ? useSelector(getOrder) : useSelector(getCheckinOrder);
    React.useEffect(function () {
        var _a, _b;
        scrollTo(0);
        if ((_b = (_a = orderWithAvailableInsurances.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.InsurancesList, {
                servicesIds: orderWithAvailableInsurances.additionalServices.insurances.insurancePrograms.map(function (insurance) { return insurance.code; })
            });
        }
    }, []);
    var orderWithAvailableInsurances = useMemo(function () {
        var _a, _b, _c;
        if (!(availablePrograms === null || availablePrograms === void 0 ? void 0 : availablePrograms.length)) {
            return order;
        }
        var selectedInsurances = (_c = (_b = (_a = order.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances) === null || _b === void 0 ? void 0 : _b.filter(function (insurance) {
            return availablePrograms.some(function (availableInsurance) { return availableInsurance.code === insurance.insuranceProgram.code; });
        })) !== null && _c !== void 0 ? _c : [];
        return __assign(__assign({}, order), { additionalServices: __assign(__assign({}, order.additionalServices), { insurances: __assign(__assign({}, order.additionalServices.insurances), { insurancePrograms: availablePrograms, selectedInsurances: selectedInsurances }) }) });
    }, [order, availablePrograms]);
    var _b = useInsuranceState(orderWithAvailableInsurances), state = _b.state, initialStateChanged = _b.initialStateChanged, dispatch = _b.dispatch, selectedProgramsCodes = _b.selectedProgramsCodes, insurancesWithExcludedMainPrograms = _b.insurancesWithExcludedMainPrograms, mainPrograms = _b.mainPrograms, availableInsurances = _b.availableInsurances, selectedInsurances = _b.selectedInsurances, saveOrderServicesParams = _b.saveOrderServicesParams, totalPrice = _b.totalPrice;
    var importantInsurancesTouched = useImportantInsurancesTouched();
    var doNotRenderForAllPassengersText = useMemo(function () {
        return order.travellers.length === 1;
    }, [order.travellers]);
    var toggle = function (code) {
        var program = state.availableInsurances[code];
        var isSelected = Boolean(state.selectedInsurances[code]);
        if (isSelected) {
            analytics_removeInsurance(program, order.travellers);
        }
        else {
            analytics_addInsurance(program, order.travellers);
        }
        dispatch({
            type: isSelected ? 'removeInsurance' : 'selectInsurance',
            payload: program
        });
    };
    var select = function (code) {
        var program = state.availableInsurances[code];
        importantInsurancesTouched.onTouchInsurance(code);
        analytics_addInsurance(program, order.travellers);
        dispatch({
            type: 'selectInsurance',
            payload: program
        });
    };
    var remove = function (code) {
        var program = state.availableInsurances[code];
        importantInsurancesTouched.onTouchInsurance(code);
        if (importantInsurancesTouched.getIsTouched(code)) {
            analytics_removeInsurance(program, order.travellers);
        }
        dispatch({
            type: 'removeInsurance',
            payload: program
        });
    };
    var getProductState = useCallback(function (code) {
        if (state.confirmedInsurances[code]) {
            return 'confirmed';
        }
        else if (state.selectedInsurances[code]) {
            return 'selected';
        }
        else {
            return 'available';
        }
    }, [state]);
    var getPolicyUrl = useCallback(function (code) {
        var _a;
        return (_a = state.confirmedInsurances[code]) === null || _a === void 0 ? void 0 : _a.policyUrl;
    }, [state]);
    // child-parent
    useEffect(function () {
        onServicesChanged === null || onServicesChanged === void 0 ? void 0 : onServicesChanged(initialStateChanged);
    }, [initialStateChanged, onServicesChanged]);
    useEffect(function () {
        onSaveOrderServicesParamsChange === null || onSaveOrderServicesParamsChange === void 0 ? void 0 : onSaveOrderServicesParamsChange(saveOrderServicesParams);
    }, [onSaveOrderServicesParamsChange, saveOrderServicesParams]);
    useEffect(function () {
        onTotalInsurancePriceChange === null || onTotalInsurancePriceChange === void 0 ? void 0 : onTotalInsurancePriceChange(totalPrice);
    }, [onTotalInsurancePriceChange, totalPrice]);
    return {
        selectedProgramsCodes: selectedProgramsCodes,
        doNotRenderForAllPassengersText: doNotRenderForAllPassengersText,
        insurancesWithExcludedMainPrograms: insurancesWithExcludedMainPrograms,
        availableInsurances: availableInsurances,
        selectedInsurances: selectedInsurances,
        getProductState: getProductState,
        getPolicyUrl: getPolicyUrl,
        toggle: toggle,
        select: select,
        remove: remove,
        mainPrograms: mainPrograms,
        isLoading: state.isLoading
    };
};
