import { __assign } from "tslib";
import { format } from 'date-fns';
import { SPECIAL_CATEGORIES } from '../../../SearchForm/utils';
import { AviaPassengerType } from '@websky/graphql';
import { Currency } from '../../../enums';
var PROMO_REGEX = 'PROMO([a-zA-Zа-яА-Я0-9]{1,10})';
var isCurrentCompanySwitchedToAutoGeneration = function () {
    var _a, _b, _c;
    return ['ZZ', 'F7'].includes((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
};
export var getIATA = function (data) {
    return data.substr(0, 3);
};
export var isDateGuard = function (date) {
    return !!date.getTime;
};
export var getAbsoluteDate = function (isoString) {
    return isoString.substr(0, 19);
};
export var getDateWithoutTimezone = function (date) {
    return dateConverter(date, false);
};
export var getDateWithTimezone = function (date) {
    return dateConverter(date, true);
};
export var getAirport = function (airport) {
    var _a;
    if ((_a = airport.city) === null || _a === void 0 ? void 0 : _a.name) {
        return __assign(__assign({}, airport), { name: airport.city.name });
    }
    return airport;
};
var dateConverter = function (date, withTimezone) {
    if (isDateGuard(date)) {
        var dateWithoutTimezone = new Date(withTimezone ? date.getTime() : date.getTime() + date.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
    else {
        var newDate = new Date(date);
        var dateWithoutTimezone = new Date(withTimezone ? newDate.getTime() : newDate.getTime() + newDate.getTimezoneOffset() * 60000);
        dateWithoutTimezone.setMilliseconds(0);
        return dateWithoutTimezone;
    }
};
export var getDate = function (data) {
    var year = data.slice(0, 4);
    var month = data.slice(4, 6);
    var day = data.slice(6, 8);
    return getDateWithoutTimezone("".concat(year, "-").concat(month, "-").concat(day));
};
export var getPassenger = function (data) {
    var type = data.substr(0, 3), count = parseInt(data.substr(3, 1)), isExtendedPassengerType = SPECIAL_CATEGORIES.includes(type);
    return {
        passengerType: isExtendedPassengerType ? AviaPassengerType.ADT : type,
        extendedPassengerType: isExtendedPassengerType ? type : null,
        count: count
    };
};
/**
 * Use for smooth transition to regex auto generation
 *
 * @deprecated
 */
var isPassengerNext__deprecated = function (data) {
    return /^(ADT|GCF|CLD|INF|INS|МЛА|SCM|SCF|PDA|YTH|SRC)(.)*\d/.test(data);
};
/**
 * @deprecated
 */
var isFastSearchCorrect__deprecated = function (fastSearch) {
    return /^(([A-Z]|[А-Я]){6}\d{8})+((((ADT|МЛА|CLD)\d)|((SCF|SCM|YTH|SRC|МЛА)\d)+)((GCF|CLD|INF|INS)\d)*)?((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|UZS|AZN|TRY|RSD|ILS|CNY|GBP)?$/.test(fastSearch);
};
/**
 * @deprecated
 */
var _WZ_isFastSearchCorrect__deprecated = function (fastSearch) {
    return /^(([A-Z]|[А-Я]){6}\d{8})+((((ADT|PDA|CLD|INF|INS)\d)+)|((SCF|SCM|МЛА|CLD|INF|INS)\d)+)?(PROMO([a-zA-Zа-яА-Я0-9]{1,10}?))?(RUB|USD|KZT)?$/.test(fastSearch);
};
export var isPassengerNext = function (passengersConfig, data) {
    var passengerTypes = passengersConfig.map(function (passenger) { return passenger.code; }).join('|');
    return new RegExp("^(".concat(passengerTypes, ")(.)*\\d")).test(data);
};
var isPassengerNextChecker = function (passengersConfig, str) {
    if (isCurrentCompanySwitchedToAutoGeneration()) {
        return isPassengerNext(passengersConfig, str);
    }
    else {
        return isPassengerNext__deprecated(str);
    }
};
export var hasPromo = function (value) {
    return RegExp(PROMO_REGEX).test(value);
};
export var extractPromo = function (value) {
    var _a;
    return (_a = value.match(PROMO_REGEX)) === null || _a === void 0 ? void 0 : _a[1];
};
export var isPromoOrCurrencyNext = function (data) {
    return /^((PROMO([a-zA-Zа-яА-Я0-9]{1,10}))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|GBP)?$/.test(data);
};
export var extractPromoCodeAndCurrency = function (fastSearch) {
    var matchedResult = fastSearch.match(/((PROMO([a-zA-Zа-яА-Я0-9]{1,10}?))|(PAYBYMILES))?(RUB|USD|KZT|GEL|EUR|AMD|GBP)?$/);
    return {
        promoCode: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[3]) || '',
        payByMiles: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[4]) === 'PAYBYMILES',
        currency: (matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult[5]) || ''
    };
};
export var isPayByMiles = function (fastSearch) { return /PAYBYMILES$/.test(fastSearch); };
export var extractCurrency = function (fastSearch) {
    var _a;
    var matchedResult = fastSearch.match(/(RUB|USD|KZT)$/);
    return ((_a = matchedResult === null || matchedResult === void 0 ? void 0 : matchedResult.slice(-1)) === null || _a === void 0 ? void 0 : _a[0]) || '';
};
export var fastSearchParse = function (fastSearch, passengersConfig, allowPromoCodes) {
    var str = fastSearch;
    var segments = [], passengers = [];
    while (!isPassengerNextChecker(passengersConfig, str) && !isPromoOrCurrencyNext(str) && str.length) {
        var departure = getIATA(str), arrival = getIATA(str.slice(3)), date = getDate(str.slice(6));
        segments.push({
            arrival: {
                iata: arrival
            },
            departure: {
                iata: departure
            },
            date: format(date, 'yyyy-MM-dd')
        });
        str = str.slice(14);
    }
    while (isPassengerNextChecker(passengersConfig, str)) {
        passengers.push(getPassenger(str));
        str = str.slice(4);
    }
    if (!passengers.length) {
        passengers.push({
            passengerType: AviaPassengerType.ADT,
            count: 1
        });
    }
    var result = {
        segments: segments,
        passengers: passengers
    };
    var _a = extractPromoCodeAndCurrency(fastSearch), promoCode = _a.promoCode, payByMiles = _a.payByMiles, currency = _a.currency;
    if (allowPromoCodes && promoCode) {
        result.promotionCode = promoCode;
    }
    if (currency) {
        result.currency = currency;
    }
    result.ffpMode = payByMiles;
    return result;
};
export var generateFastSearchRegex = function (config) {
    var airportsAndDatesRegex = "(([A-Z]|[\u0410-\u042F]){6}\\d{8})+";
    var passengerTypes = config.SearchForm.passengersConfig.map(function (passenger) { return passenger.code; }).join('|');
    var passengersRegex = "(((".concat(passengerTypes, ")\\d)+)?");
    var promo = config.global.allowPromoCodes ? '(PROMO([a-zA-Zа-яА-Я0-9]{1,10}))' : '';
    var payByMiles = config.SearchForm.payByMiles ? '(PAYBYMILES)' : '';
    var promoAndMilesArr = [promo, payByMiles].filter(Boolean);
    var promoAndMilesRegex = promoAndMilesArr.length ? "(".concat(promoAndMilesArr.join('|'), ")?") : '';
    var currencyRegex = "(".concat(Object.values(Currency).join('|'), ")?");
    return new RegExp("^".concat(airportsAndDatesRegex).concat(passengersRegex).concat(promoAndMilesRegex).concat(currencyRegex, "$"));
};
export var isFastSearchCorrect = function (config, fastSearch) {
    var _a;
    if (isCurrentCompanySwitchedToAutoGeneration()) {
        var regex = generateFastSearchRegex(config);
        return regex.test(fastSearch);
    }
    else if (((_a = config.global.companyInfo) === null || _a === void 0 ? void 0 : _a.iataCode) === 'WZ') {
        return _WZ_isFastSearchCorrect__deprecated(fastSearch);
    }
    else {
        return isFastSearchCorrect__deprecated(fastSearch);
    }
};
