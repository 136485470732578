import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCheckinSaveOrderServicesMutation } from '@websky/graphql';
import { getCheckinOrder, getExtrasPrice, getTotalPrice } from '../../../../store/order/selectors';
import AdditionalServiceBanner from '../../../../../../AdditionalServiceBanner/AdditionalServiceBanner';
import MediaQuery from '../../../../../../MediaQuery/MediaQuery';
import MobileToolbar from '../../../../../../MobileToolbar';
import Toolbar from '../../../../../../Toolbar';
import { CheckinStep } from '../../../../utils';
import SimpleLoader from '../../../../../../SimpleLoader';
import AdditionalServices from '../../../../../../AdditionalServices/components/AdditionalServices';
import { useTheme } from '../../../../../../theme';
import { Money } from '../../../../../../Money';
import { useGetGoBack } from '../utils';
import Consents from '../../../../../../Consents';
import { useCustomCssVariable, useOffsetTop } from '../../../../../../hooks';
var Extras = function (_a) {
    var onProceed = _a.onProceed, onSetOrder = _a.onSetOrder;
    var css = useTheme('Checkin').Checkin;
    var t = useTranslation('Checkin').t;
    var rootNode = useRef();
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var offsetTop = useOffsetTop(rootNode);
    useCustomCssVariable('extras-offset-top', "".concat(offsetTop.toString(10), "px"));
    var order = useSelector(getCheckinOrder);
    var totalPrice = useSelector(getTotalPrice);
    var extrasPriceToPay = useSelector(getExtrasPrice);
    var _c = __read(useCheckinSaveOrderServicesMutation(), 1), saveCheckinOrderServicesMutation = _c[0];
    var onProceedHandler = useCallback(function (onConfirmConsents) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (onConfirmConsents) {
                        onConfirmConsents();
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    return [4 /*yield*/, onProceed()];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }; }, [onProceed, setIsLoading]);
    var saveCheckinOrderServices = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveCheckinOrderServicesMutation({ variables: { params: params } })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    }); }, []);
    var goBack = useGetGoBack(CheckinStep.Extras);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement("div", { className: css.extras, ref: rootNode },
            React.createElement(AdditionalServiceBanner, { service: "extras" }),
            React.createElement("div", { className: css.wrapper },
                React.createElement(AdditionalServices, { additionalServices: order.additionalServices, className: css.additionalServices, orderId: order.id, travellers: order.travellers.map(function (passenger) { return (__assign(__assign({}, passenger), { supplierTravellerType: null, needOthsSsr: null, subsidyInfo: null, fares: null })); }), saveOrderServices: saveCheckinOrderServices, onSetOrder: onSetOrder }),
                React.createElement(Consents, null, function (_a) {
                    var onConfirm = _a.onConfirm;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { maxWidth: "mobile" },
                            React.createElement(MobileToolbar, { onBack: goBack, onContinue: onProceedHandler(onConfirm) })),
                        React.createElement(MediaQuery, { minWidth: "mobile" },
                            React.createElement(Toolbar, { totalPrice: totalPrice, additional: extrasPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                                    t('Extras'),
                                    ": ",
                                    React.createElement(Money, { money: extrasPriceToPay }))) : null, onBackButtonClick: goBack, onButtonClick: onProceedHandler(onConfirm) }))));
                })))));
};
export default Extras;
