import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
var StatusMessage = function (props) {
    var _a;
    var type = props.type, title = props.title, text = props.text;
    var css = useTheme('StatusMessage').StatusMessage;
    return (React.createElement("div", { className: cn(css.statusMessage, (_a = {},
            _a[css.statusMessage_success] = type === 'success',
            _a[css.statusMessage_fail] = type === 'fail',
            _a)) },
        React.createElement("h3", { className: css.statusMessage__title }, title),
        React.createElement("p", { className: css.statusMessage__text }, text)));
};
export default StatusMessage;
