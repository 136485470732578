import { __assign } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDifferentForReturnFlight } from '../../store/selector';
import BySegment from './BySegment/BySegment';
import AllFlight from './AllFlight/AllFlight';
import { getBaggageTab } from '../../store/selector';
var BaggageBody = function (props) {
    var isDifferentForReturnFlight = useSelector(getDifferentForReturnFlight);
    var baggageTab = useSelector(getBaggageTab);
    var filteredBaggage = useMemo(function () {
        if (baggageTab) {
            return props.upgradeBaggages.filter(function (baggage) { return baggage[0].rfics === baggageTab; });
        }
        return props.upgradeBaggages;
    }, [props.upgradeBaggages, baggageTab]);
    var baggageProps = __assign(__assign({}, props), { upgradeBaggages: filteredBaggage, baggageTab: baggageTab });
    if (isDifferentForReturnFlight) {
        return React.createElement(BySegment, __assign({}, baggageProps));
    }
    else {
        return React.createElement(AllFlight, __assign({}, baggageProps));
    }
};
export default BaggageBody;
