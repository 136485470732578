import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { initI18n, useIsMobile } from '../../utils';
import { useRetrieveBookingValidation } from '../hooks';
import { useRetrieveBooking } from './RetrieveBooking.hook';
import Input from '../../Input';
import Button from '../../Button/Button';
import Tooltip from '../../Tooltip';
import ClarifyDocumentModal from './ClarifyDocumentModal/ClarifyDocumentModal';
import Notification from '../../Notification';
import BookingPreview from '../../BookingPreview';
import { InfoQuestion } from '../../Icons';
initI18n('RetrieveBooking');
export var Mode;
(function (Mode) {
    Mode[Mode["Checkout"] = 0] = "Checkout";
    Mode[Mode["Checkin"] = 1] = "Checkin";
})(Mode || (Mode = {}));
var RetrieveBooking = function (props) {
    var _a;
    var mode = props.mode, className = props.className, title = props.title, initialId = props.initialId, initialSecret = props.initialSecret, idTitle = props.idTitle, secretTitle = props.secretTitle, hideSecretHint = props.hideSecretHint, withBookingPreview = props.withBookingPreview, onHideOrderPreview = props.onHideOrderPreview;
    var _b = useTranslation('RetrieveBooking'), t = _b.t, i18n = _b.i18n;
    var css = useTheme('RetrieveBooking').RetrieveBooking;
    var isMobile = useIsMobile();
    var showOrderPreview = !!props.orderPreview;
    var model = useRetrieveBooking(props);
    var _c = useRetrieveBookingValidation(), lastNameValidation = _c.lastNameValidation, ticketNumberValidation = _c.ticketNumberValidation, validateByOrderNumber = _c.validateByOrderNumber, ticketNumberFormat = _c.ticketNumberFormat;
    var inputVariant = isMobile ? 'standard' : 'filled';
    var renderTooltip = useCallback(function (meta) {
        if (meta.data.warning && mode === Mode.Checkin) {
            return (React.createElement(Tooltip, { tooltipClassName: css.tooltip, title: t(meta.data.warning), open: true },
                React.createElement("div", { className: css.tooltip__icon }, InfoQuestion)));
        }
        if (isMobile || hideSecretHint) {
            return null;
        }
        return (React.createElement(Tooltip, { title: t('You can find security code in your booking confirmation email') },
            React.createElement("div", { className: css.tooltip__icon }, InfoQuestion)));
    }, [mode, isMobile, hideSecretHint]);
    return (React.createElement("div", { className: cn(css.container, className, (_a = {},
            _a[css.container_checkin] = mode === Mode.Checkin,
            _a)) },
        React.createElement("div", { className: css.title }, title !== null && title !== void 0 ? title : t('Retrieve booking')),
        React.createElement(ClarifyDocumentModal, { searchParams: model.searchParams, onSubmit: model.handleClarifySubmit, open: model.isClarityDocumentOpen, onClose: model.closeClarityDocument }),
        React.createElement(Form, { onSubmit: model.handleSubmit }, function (_a) {
            var _b;
            var handleSubmit = _a.handleSubmit, valid = _a.valid;
            return (React.createElement("form", { className: css.form, onSubmit: handleSubmit },
                React.createElement("div", { className: css.form__row },
                    React.createElement(Field, { name: "id", initialValue: initialId, parse: ticketNumberFormat, validate: function (value) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (mode === Mode.Checkin) {
                                return ticketNumberValidation(value);
                            }
                            if (mode === Mode.Checkout) {
                                if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                    return undefined;
                                }
                                else {
                                    return ticketNumberValidation(value) || validateByOrderNumber(value);
                                }
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(Input, { disabled: model.isLoading || (withBookingPreview && showOrderPreview), absoluteHintPosition: true, label: idTitle || t('Booking ID'), variant: inputVariant, TextFieldProps: __assign(__assign({}, input), { helperText: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                        (meta.error || meta.submitError), error: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                        (!!meta.error || !!meta.submitError) }) }));
                        } })),
                React.createElement("div", { className: css.form__row },
                    React.createElement(Field, { name: "secret", initialValue: initialSecret, validate: function (value, allValues, meta) {
                            if (!value) {
                                return t('Required field');
                            }
                            if (mode === Mode.Checkin) {
                                return lastNameValidation(value);
                            }
                        }, render: function (_a) {
                            var input = _a.input, meta = _a.meta;
                            return (React.createElement(React.Fragment, null,
                                React.createElement(Input, { disabled: model.isLoading || (withBookingPreview && showOrderPreview), absoluteHintPosition: true, label: secretTitle || t('Security code'), variant: inputVariant, TextFieldProps: __assign(__assign({}, input), { helperText: (model.isOrderNotFoundError && t('Order not found')) ||
                                            (model.isBlockedForPayment &&
                                                t('The order is being paid at the moment, please try again later')) ||
                                            (((meta.modified && meta.touched) || meta.submitFailed) &&
                                                (meta.error || meta.submitError)), error: model.isOrderNotFoundError ||
                                            model.isBlockedForPayment ||
                                            (((meta.modified && meta.touched) || meta.submitFailed) &&
                                                (!!meta.error || !!meta.submitError)) }) }),
                                renderTooltip(meta)));
                        } })),
                model.codeIsWrong && model.isErrorNotification && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, contextClassName: css.notification_context, textClassName: css.notification__text, infoMessage: {
                        text: t('Booking not found. Please, check the information entered or use your e-ticket number for identification'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'warning'
                    }, type: "context" })),
                model.isOnlyAirportCheckin && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, contextClassName: css.notification_context, textClassName: css.notification__text, infoMessage: {
                        text: t('Online check-in is not available. Check-in only at the airport'),
                        type: InfoMessageTypeEnum.Warning,
                        code: 'warning'
                    }, type: "context" })),
                withBookingPreview && (model.isLoading || props.orderPreview) ? (React.createElement(BookingPreview, { redirectViaHistory: true, order: props.orderPreview, onClearOrder: onHideOrderPreview, isLoading: model.isLoading })) : (React.createElement("div", { className: cn(css.form__row, css.form__row_footer) },
                    model.isCheckinMode && i18n.exists('RetrieveBooking:checkinHint') && (React.createElement("div", { className: css.checkinHint }, t('RetrieveBooking:checkinHint'))),
                    React.createElement(Button, { className: cn(css.button, (_b = {},
                            _b[css.button_disabled] = !valid,
                            _b)), type: "submit", disabled: !valid, isLoading: model.isLoading }, t('Open'))))));
        })));
};
export default RetrieveBooking;
