import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFlightsToExchange, getSelectedPassengers } from '../../../../store/selectors';
import Chip from './Chip/Chip';
import { useTheme } from '../../../../../../theme';
import { getPassengerName } from '../../utils';
import { ExchangeStep } from '../../../../types';
import { getPassengers } from '../../../../store/order/selectors';
import { getSelectedReason } from '../../../../store/reason/selectors';
import { useConfig } from '../../../../../../context';
var Selections = function (_a) {
    var setStep = _a.setStep;
    var t = useTranslation('Exchange').t;
    var location = useLocation();
    var exareShowPassengersStep = useConfig().Engine.exareShowPassengersStep;
    var passengers = useSelector(getPassengers);
    var isOnlyPassenger = passengers.length === 1;
    var selectedPassengers = useSelector(getSelectedPassengers);
    var selectedSegments = useSelector(getFlightsToExchange);
    var selectedReason = useSelector(getSelectedReason);
    var theme = useTheme('Exchange').Selections;
    var toPassengers = function () {
        if (!isOnlyPassenger) {
            setStep(ExchangeStep.Passengers);
        }
    };
    var toSegments = function () { return setStep(ExchangeStep.Segments); };
    var toReasons = function () { return setStep(ExchangeStep.Reasons); };
    var isPassengerPage = location.pathname.indexOf('passenger') >= 0;
    var isFlightPage = location.pathname.indexOf('segments') >= 0;
    var isReasonsPage = location.pathname.indexOf('reasons') >= 0;
    return (React.createElement("div", { className: theme.wrapper },
        !isPassengerPage &&
            selectedPassengers.map(function (passenger) { return (React.createElement(Chip, { label: getPassengerName(passenger), onClick: toPassengers, key: "p_".concat(passenger.id), readonly: isOnlyPassenger || !exareShowPassengersStep, type: "passenger" })); }),
        !isReasonsPage && (React.createElement(Chip, { className: theme.reasons, label: t(selectedReason), onClick: toReasons, type: "passenger" })),
        !isFlightPage &&
            !isPassengerPage &&
            selectedSegments.flightsByIds.map(function (segment) {
                return (React.createElement(Chip, { label: "".concat(segment.segments[0].departure.airport.city.name, " - ").concat(segment.segments[segment.segments.length - 1].arrival.airport.city.name), onClick: toSegments, key: "s_".concat(segment.groupId), type: "flight" }));
            })));
};
export default Selections;
