import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { InfoDetails } from '../../../../../Icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Field } from 'react-final-form';
var List = memo(function (props) {
    var _a;
    var theme = useTheme('PassengerForm').Disabilities;
    var t = useTranslation('Passenger').t;
    var idsToDisable = useMemo(function () {
        var _a;
        var ids = [];
        Object.entries((_a = props.passengerDisabilitiesState.values) !== null && _a !== void 0 ? _a : {}).forEach(function (_a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], isChecked = _c[1];
            if (isChecked) {
                var disabilityInfo = props.availableDisabilities.find(function (availableDisability) { return availableDisability.id === key; });
                ids.push.apply(ids, __spreadArray([], __read(((_b = disabilityInfo === null || disabilityInfo === void 0 ? void 0 : disabilityInfo.notCombinedWithAny) !== null && _b !== void 0 ? _b : [])), false));
            }
        });
        return ids;
    }, [props.passengerDisabilitiesState]);
    return (React.createElement("div", { className: cn(theme.disabilities, (_a = {}, _a[theme.disabilities_error] = props.isError, _a)) },
        React.createElement("div", { className: theme.disabilities__text },
            React.createElement("div", null, InfoDetails),
            React.createElement("div", null, t('Passengers with disabilities and other persons with reduced mobility and persons accompanying them shall board the aircraft before boarding'))),
        React.createElement("div", { className: theme.checkboxes }, props.availableDisabilities.map(function (disability) { return (React.createElement(Field, { name: "".concat(props.fieldNameBasePath, ".values.").concat(disability.id), initialValue: false, type: 'checkbox', key: disability.id, render: function (_a) {
                var input = _a.input;
                return (React.createElement(FormControlLabel, { label: disability.name, control: React.createElement(Checkbox, { color: "primary", classes: {
                            colorPrimary: theme.checkbox,
                            checked: theme.checkbox_checked,
                            disabled: theme.checkbox_disabled,
                            root: theme.checkbox_root
                        }, checked: input.checked, onChange: input.onChange, disabled: idsToDisable.includes(disability.id) }) }));
            } })); }))));
});
export default List;
