import { __assign, __awaiter, __generator, __read } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { PaymentType } from '../../../../PaymentForm/types';
import { sessionStorageGet, UTM_MARKS_KEY } from '../../../../cache';
import { PaymentResultStatus } from '../Checkout';
import { PAYMENT_KEY_TO_TYPE_MAP } from './Payment';
import { OrderStatus, useCompletePaymentMutation, usePaymentMethodsQuery, useSetOrderStatusMutation } from '@websky/graphql';
import { useIataCode } from '../../../../hooks';
export var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Fail"] = "fail";
})(PaymentResult || (PaymentResult = {}));
var PAYMENT_METHOD_UNAVAILABLE = 'Payment method is not available';
export var useCheckoutPayment = function (_a) {
    var orderId = _a.orderId, order = _a.order, onSuccess = _a.onSuccess, redirectPathname = _a.redirectPathname, onPaymentMethodsLoaded = _a.onPaymentMethodsLoaded, orderPaymentResult = _a.orderPaymentResult;
    var _b = __read(useState(), 2), paymentResult = _b[0], setPaymentResult = _b[1];
    var _c = __read(useState(false), 2), testPaymentInProgress = _c[0], setTestPaymentInProgress = _c[1];
    var _d = __read(useState(null), 2), selectedPaymentPage = _d[0], setSelectedPaymentPage = _d[1];
    var selectedPaymentType = selectedPaymentPage === null || selectedPaymentPage === void 0 ? void 0 : selectedPaymentPage.type;
    var showPaymentCardsEvenForSingleMethod = ['2S'].includes(useIataCode());
    var openFrameInNewTab = ['2S'].includes(useIataCode());
    var _e = __read(useState(null), 2), requestError = _e[0], setRequestError = _e[1];
    var onRequestError = function (error) {
        var graphQLErrors = error.graphQLErrors;
        if (graphQLErrors.length) {
            var paymentError = graphQLErrors.find(function (error) {
                var _a, _b;
                return ((_a = error.message) === null || _a === void 0 ? void 0 : _a.includes(PAYMENT_METHOD_UNAVAILABLE)) ||
                    ((_b = error.debugMessage) === null || _b === void 0 ? void 0 : _b.includes(PAYMENT_METHOD_UNAVAILABLE));
            });
            if (paymentError) {
                setRequestError(paymentError);
            }
        }
    };
    var onClearError = function () {
        setRequestError(null);
    };
    var baseUrl = window.location.href.replace(window.location.hash, "#".concat(redirectPathname));
    var utmMarks = sessionStorageGet(UTM_MARKS_KEY);
    var utmParameters = Object.keys(utmMarks || {}).length ? utmMarks : null;
    var _f = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withCard: selectedPaymentType === PaymentType.Card,
            withCredit: selectedPaymentType === PaymentType.Credit,
            withGpay: selectedPaymentType === PaymentType.Gpay,
            withItransfer: selectedPaymentType === PaymentType.Itransfer,
            withKaspi: selectedPaymentType === PaymentType.Kaspi,
            withQiwi: selectedPaymentType === PaymentType.Qiwi,
            withSbol: selectedPaymentType === PaymentType.Sbol,
            withFps: selectedPaymentType === PaymentType.Fps,
            parameters: {
                orderId: orderId,
                backRedirectUrl: "".concat(baseUrl, "/successfull"),
                backRedirectUrlOnFail: "".concat(baseUrl, "/failed"),
                utm: utmParameters
            }
        },
        skip: orderPaymentResult === PaymentResultStatus.Success,
        onError: onRequestError
    }), data = _f.data, loading = _f.loading;
    var testPaymentEnabled = process.env.TEST_PAYMENT_ENABLED === 'true';
    var _g = __read(useSetOrderStatusMutation(), 1), runTestPayment = _g[0];
    var _h = __read(useCompletePaymentMutation(), 2), completePayment = _h[0], completePaymentLoading = _h[1].loading;
    var onTestPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errors, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setTestPaymentInProgress(true);
                    return [4 /*yield*/, runTestPayment({
                            variables: {
                                orderId: orderId,
                                status: OrderStatus.Confirmed
                            }
                        })];
                case 1:
                    errors = (_a.sent()).errors;
                    if (errors) {
                        setPaymentResult(PaymentResult.Fail);
                    }
                    else {
                        setPaymentResult(PaymentResult.Success);
                    }
                    setTestPaymentInProgress(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setPaymentResult(PaymentResult.Fail);
                    setTestPaymentInProgress(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onCompletePayment = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, completePayment({
                            variables: __assign({ orderId: orderId, secureCode: null }, values)
                        })];
                case 1:
                    data_1 = (_a.sent()).data;
                    if (data_1 === null || data_1 === void 0 ? void 0 : data_1.CompletePayment.result) {
                        setPaymentResult(PaymentResult.Success);
                    }
                    else {
                        setPaymentResult(PaymentResult.Fail);
                    }
                    return [2 /*return*/, (data_1 === null || data_1 === void 0 ? void 0 : data_1.CompletePayment.result) || false];
                case 2:
                    e_1 = _a.sent();
                    setPaymentResult(PaymentResult.Fail);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [completePayment, order]);
    var onPaymentComplete = useCallback(function () {
        onSuccess(true);
    }, [onSuccess]);
    var closePaymentResult = function () {
        if (paymentResult === PaymentResult.Success) {
            onPaymentComplete();
        }
        else {
            setPaymentResult(null);
        }
    };
    useEffect(function () {
        if (!loading && (data === null || data === void 0 ? void 0 : data.PaymentMethods)) {
            // select payment page if only one available
            // filter out null values
            var availablePaymentMethods = Object.entries(data === null || data === void 0 ? void 0 : data.PaymentMethods).filter(function (_a) {
                var _b = __read(_a, 2), paymentMethod = _b[1];
                return typeof paymentMethod === 'object' && !!paymentMethod;
            });
            if (availablePaymentMethods.length === 1 && !showPaymentCardsEvenForSingleMethod) {
                var availablePaymentMethodKeyValue = availablePaymentMethods[0];
                var paymentType = PAYMENT_KEY_TO_TYPE_MAP.get(availablePaymentMethodKeyValue[0]);
                if (paymentType) {
                    setSelectedPaymentPage({ type: paymentType, content: null, title: '' });
                }
            }
            if (onPaymentMethodsLoaded) {
                if (selectedPaymentPage) {
                    onPaymentMethodsLoaded();
                }
            }
        }
    }, [loading, data]);
    var handleSelectPaymentMethod = useCallback(function (page) {
        if (!selectedPaymentPage) {
            setSelectedPaymentPage(page);
        }
    }, [selectedPaymentPage]);
    return {
        // methods
        closePaymentResult: closePaymentResult,
        onTestPayment: onTestPayment,
        setPaymentResult: setPaymentResult,
        onPaymentComplete: onPaymentComplete,
        onCompletePayment: onCompletePayment,
        onClearError: onClearError,
        // data
        orderPaymentResult: orderPaymentResult,
        data: data,
        paymentResult: paymentResult,
        loading: loading,
        completePaymentLoading: completePaymentLoading,
        selectedPaymentPage: selectedPaymentPage,
        handleSelectPaymentMethod: handleSelectPaymentMethod,
        testPaymentEnabled: testPaymentEnabled,
        testPaymentInProgress: testPaymentInProgress,
        openFrameInNewTab: openFrameInNewTab,
        requestError: requestError
    };
};
