import { __extends } from "tslib";
import OrderDataLayer from './OrderDataLayer';
import { OrderStep } from '../types';
import { OrderType } from './types';
var CheckinOrderDataLayer = /** @class */ (function (_super) {
    __extends(CheckinOrderDataLayer, _super);
    function CheckinOrderDataLayer(instance) {
        return _super.call(this, instance) || this;
    }
    CheckinOrderDataLayer.prototype.getOrderType = function () {
        return OrderType.Checkin;
    };
    CheckinOrderDataLayer.prototype.getFlightSegments = function () {
        return this.getOrder().segments;
    };
    CheckinOrderDataLayer.prototype.getOrderStatus = function () {
        return OrderStep.Checkin;
    };
    return CheckinOrderDataLayer;
}(OrderDataLayer));
export default CheckinOrderDataLayer;
