import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Unvoluntary, StepType } from '../../types';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter } from '../../../utils';
import { isRefundPassenger } from '../../utils';
import { useTheme } from '../../../theme';
import { usePassengerNames } from '../Passenger/hooks';
import { useSteps } from '../Refund/hooks';
var Breadcrumbs = function (props) {
    var _a;
    var passengers = props.passengers, reason = props.reason, renderAfter = props.renderAfter;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Breadcrumbs;
    var getPassengerName = usePassengerNames().getPassengerName;
    var currentStep = useSteps().currentStep;
    var renderBreadcrumb = useCallback(function (title, type) { return (React.createElement("div", { title: title, className: css.breadcrumb, key: title },
        React.createElement("div", { className: css.text }, title),
        type ? renderAfter === null || renderAfter === void 0 ? void 0 : renderAfter(type) : null)); }, [renderAfter]);
    var renderPassengers = useCallback(function () {
        if (!passengers.length) {
            return null;
        }
        var passengerName;
        if (isRefundPassenger(passengers[0])) {
            passengerName = passengers.map(getPassengerName).join(', ');
        }
        else {
            passengerName = passengers
                .map(function (passenger) {
                return "".concat(capitalizeFirstLetter(getPassengerFirstName(passenger).value.toLowerCase()), " ").concat(capitalizeFirstLetter(getPassengerLastName(passenger).value.toLowerCase()));
            })
                .join(', ');
        }
        return passengerName.split(',').map(function (name) { return renderBreadcrumb(name, 'passenger'); });
    }, [passengers]);
    var renderRefundInfo = useCallback(function () {
        if (!reason) {
            return null;
        }
        var isVoluntaryReason = reason === Unvoluntary.Unvoluntary;
        var title = t(isVoluntaryReason ? 'Voluntary refund' : 'Involuntary refund');
        if (reason && !isVoluntaryReason) {
            return renderBreadcrumb("".concat(title, " \u2013 ").concat(t(reason)), 'refundInfo');
        }
        return renderBreadcrumb(title, 'refundInfo');
    }, [reason]);
    return (React.createElement("div", { className: cn(css.breadcrumbs, props.className, (_a = {},
            _a[css.passengersStep] = currentStep.type === StepType.Passengers,
            _a)) },
        renderPassengers(),
        " ",
        renderRefundInfo()));
};
export default Breadcrumbs;
