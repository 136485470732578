import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '../../../theme';
import { Money as MoneyComponent } from '../../../Money';
import { ArrowIcon } from './Icons';
import Counter from '../../../Counter/Counter';
import { getPassengerAbbreviation } from '../../utils';
import { BaseButton } from '../../../index';
import SimpleLoader from '../../../SimpleLoader';
import { useToggleable } from '../../../hooks';
var Details = function (_a) {
    var _b, _c;
    var _d;
    var marker = _a.marker, meal = _a.meal, oldPrice = _a.oldPrice, counters = _a.counters, onDecrease = _a.onDecrease, onIncrease = _a.onIncrease, onClear = _a.onClear, onSubmit = _a.onSubmit, readonly = _a.readonly, isLoading = _a.isLoading, isCountChanged = _a.isCountChanged, onAddForAll = _a.onAddForAll, isDescriptionOpened = _a.isDescriptionOpened;
    var t = useTranslation('Meal').t;
    var css = useTheme('Meal').Details;
    var _e = useToggleable(isDescriptionOpened), isOpenDescription = _e.isOpen, onToggleDescription = _e.toggle;
    var _f = __read(useState(0), 2), antiBlinkingTotalPrice = _f[0], setAntiBlinkingTotalPrice = _f[1];
    var totalPrice = { amount: 0, currency: '' };
    var clearIsUnavailable = readonly || isLoading;
    var totalNumberOfMeals = 0;
    counters.forEach(function (counter) {
        var _a, _b;
        if (counter.minAvailable > 0) {
            clearIsUnavailable = true;
        }
        totalNumberOfMeals += counter.count;
        totalPrice.amount = totalPrice.amount + counter.count * ((_a = meal.price) === null || _a === void 0 ? void 0 : _a.amount);
        totalPrice.currency = (_b = meal.price) === null || _b === void 0 ? void 0 : _b.currency;
    });
    var handleAddMealForAllPassengers = useCallback(function () {
        if (!readonly) {
            onAddForAll(meal);
        }
    }, [meal]);
    var canAddForAllPassengers = meal.allowedPassengers.length <= 4;
    useEffect(function () {
        if ((totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount) && totalPrice.amount !== antiBlinkingTotalPrice) {
            setAntiBlinkingTotalPrice(totalPrice.amount);
        }
    }, [totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount]);
    var onDecreaseHandler = useCallback(function (passenger) { return function () {
        if (isLoading) {
            return;
        }
        onDecrease(meal, passenger);
    }; }, [meal, isLoading, onDecrease]);
    var onIncreaseHandler = useCallback(function (passenger) { return function () {
        if (isLoading) {
            return;
        }
        onIncrease(meal, passenger);
    }; }, [meal, isLoading, onIncrease]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.image, style: meal.imageURL ? { backgroundImage: "url(".concat(meal.imageURL, ")") } : {} }, marker && React.createElement("div", { className: css.marker }, marker)),
            React.createElement("div", { className: css.content },
                React.createElement("div", { className: css.row },
                    React.createElement("div", { className: css.name }, meal.name),
                    React.createElement("div", { className: css.priceWrapper },
                        !!(oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount) && React.createElement(MoneyComponent, { moneyClassName: css.oldPrice, money: oldPrice }),
                        !!meal.price.amount ? (React.createElement(MoneyComponent, { moneyClassName: css.price, money: meal.price })) : (React.createElement("span", { className: css.price }, t('Free'))))),
                React.createElement("div", { className: css.row },
                    React.createElement("span", { className: css.trigger, onClick: onToggleDescription },
                        t('Show description'),
                        React.createElement("span", { className: cn(css.trigger__icon, (_b = {},
                                _b[css.trigger__icon_rotated] = isOpenDescription,
                                _b)) }, ArrowIcon)),
                    !!meal.weight && (React.createElement("span", { className: css.weight },
                        meal.weight,
                        " ",
                        t('g')))),
                React.createElement(Collapse, { in: isOpenDescription, mountOnEnter: true, unmountOnExit: true },
                    React.createElement("div", { className: css.description },
                        React.createElement("div", { className: css.description__row, dangerouslySetInnerHTML: { __html: meal.description } }),
                        !!((_d = meal.allergens) === null || _d === void 0 ? void 0 : _d.length) && (React.createElement("div", { className: css.description__row },
                            React.createElement("div", { className: css.description__header }, t('Allergens')),
                            React.createElement("div", null, meal.allergens.join(', '))))))),
            React.createElement("div", { className: css.selector },
                React.createElement("div", { className: css.counters }, counters.map(function (_a) {
                    var _b, _c;
                    var _d;
                    var passenger = _a.passenger, count = _a.count, minAvailable = _a.minAvailable, maxAvailable = _a.maxAvailable;
                    return (React.createElement("div", { key: passenger.id, className: css.counter },
                        React.createElement("span", { className: css.passenger },
                            React.createElement("span", { className: css.passenger__index }, passenger.isIdentified
                                ? getPassengerAbbreviation(passenger.name)
                                : parseInt(passenger.id) + 1),
                            React.createElement("span", null, passenger.name)),
                        React.createElement(Counter, { classes: {
                                minus: cn((_b = {}, _b[css.disabled] = isLoading, _b)),
                                plus: cn((_c = {}, _c[css.disabled] = isLoading, _c))
                            }, value: count, showOnlyIncreaseButtonWhenValueIsNull: true, minValue: minAvailable, maxValue: ((_d = meal.price) === null || _d === void 0 ? void 0 : _d.amount) === 0 ? 1 : maxAvailable, onDecrease: onDecreaseHandler(passenger), onIncrease: onIncreaseHandler(passenger), readonly: readonly })));
                })),
                React.createElement("div", { className: css.footer },
                    totalNumberOfMeals === 0 && canAddForAllPassengers && !readonly && (React.createElement("span", { className: css.addForAll, onClick: handleAddMealForAllPassengers }, t('Add for all'))),
                    totalNumberOfMeals > 0 && (React.createElement("div", { className: css.footer__price },
                        antiBlinkingTotalPrice ? (React.createElement("div", { className: css.totalPrice },
                            React.createElement("span", { className: css.totalText },
                                t('Total'),
                                ":\u00A0"),
                            React.createElement(MoneyComponent, { moneyClassName: css.total, money: {
                                    amount: antiBlinkingTotalPrice,
                                    currency: totalPrice.currency
                                } }))) : (React.createElement("span", { className: css.total }, t('Free'))),
                        !clearIsUnavailable && (React.createElement("div", { className: css.clear },
                            React.createElement("span", { onClick: function () { return onClear(meal); } }, t('Clear')))))),
                    React.createElement(BaseButton, { variant: "smallThird", className: cn(css.button, (_c = {},
                            _c[css.button_empty] = !isCountChanged && !readonly,
                            _c)), onClick: onSubmit }, t('Done')))))));
};
export default Details;
