import { __assign } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import { Collapse } from '@mui/material';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { DisabilitiesContext } from './context';
import { useToggleable } from '../../../../hooks';
import { Field, useFormState } from 'react-final-form';
import Switch from '../Switch/Switch';
import List from './List/List';
import { validateDisabilities } from './utils';
import cn from 'classnames';
var Disabilities = function (_a) {
    var _b, _c;
    var groupIcon = _a.groupIcon;
    var theme = useTheme('PassengerForm').Disabilities;
    var groupTheme = useTheme('PassengerForm').Group;
    var t = useTranslation('Passenger').t;
    var _d = useContext(DisabilitiesContext), availableDisabilities = _d.availableDisabilities, passengerId = _d.passengerId;
    var _e = useFormState(), passengers = _e.values.passengers, errors = _e.errors, submitFailed = _e.submitFailed;
    var disabilities = passengers[passengerId].disabilities;
    var _f = useToggleable(disabilities === null || disabilities === void 0 ? void 0 : disabilities.enabled), isOpen = _f.isOpen, toggle = _f.toggle;
    var fieldNameBasePath = "passengers.".concat(passengerId, ".disabilities");
    if (!(availableDisabilities === null || availableDisabilities === void 0 ? void 0 : availableDisabilities.length)) {
        return null;
    }
    return (React.createElement("div", { className: theme.container },
        React.createElement(Field, { name: "".concat(fieldNameBasePath, ".enabled"), initialValue: isOpen, type: 'checkbox', key: JSON.stringify(disabilities.values), validate: function (value, allValues) {
                var error = validateDisabilities(allValues['passengers'][passengerId]);
                return error ? t(error) : null;
            }, render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement("div", { className: theme.switchWrapper },
                    groupIcon && React.createElement("div", { className: cn(groupTheme.icon, theme.icon) }, groupIcon),
                    React.createElement(Switch, { label: t('Passenger with disabilities'), isActive: isOpen, onClick: function () {
                            toggle();
                            input.onChange(__assign(__assign({}, new Event('change')), { target: {
                                    value: !isOpen
                                } }));
                        }, className: theme.switchControl }),
                    meta.submitFailed && meta.error && (React.createElement("div", { className: theme.switchControl__errorText }, meta.error))));
            } }),
        React.createElement(Collapse, { in: isOpen },
            React.createElement(List, { availableDisabilities: availableDisabilities, passengerId: passengerId, passengerDisabilitiesState: disabilities, fieldNameBasePath: fieldNameBasePath, isError: submitFailed && !!((_c = (_b = errors['passengers']) === null || _b === void 0 ? void 0 : _b[passengerId]) === null || _c === void 0 ? void 0 : _c['disabilities']) }))));
};
export default Disabilities;
