import { __read } from "tslib";
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getServicesFallbackURL, shouldRedirectToFallback } from '../../../store/order/selectors';
export var useUpsaleWarning = function () {
    var _a = __read(useState(false), 2), showWarning = _a[0], setShowWaring = _a[1];
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var redirectURL = useSelector(getServicesFallbackURL);
    var openWarning = useCallback(function () {
        setShowWaring(true);
    }, []);
    var closeWarning = useCallback(function () {
        setShowWaring(false);
    }, []);
    var toggleWarningIfOrderConfirmed = useCallback(function () {
        if (shouldRedirect && redirectURL) {
            setShowWaring(true);
        }
        else {
            setShowWaring(false);
        }
    }, [shouldRedirect, redirectURL]);
    return { showWarning: showWarning, openWarning: openWarning, closeWarning: closeWarning, toggleWarningIfOrderConfirmed: toggleWarningIfOrderConfirmed };
};
