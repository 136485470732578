import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { Add, Chevron, ProfileIcon } from '../../Icons';
import { TravellerTypes } from '../../Traveller/types';
import { useRenderers } from '../../renderProps';
import { getPassengerNames } from '../../utils';
var UserCard = function (_a) {
    var _b;
    var className = _a.className, profile = _a.profile, editText = _a.editText, editLink = _a.editLink, inline = _a.inline, headerClassName = _a.headerClassName, confirmContacts = _a.confirmContacts, type = _a.type, onClick = _a.onClick, showArrow = _a.showArrow;
    var css = useTheme('UserCard').UserCard;
    var t = useTranslation('Account').t;
    var getCustomUserCardHeaderLabel = useRenderers().getCustomUserCardHeaderLabel;
    var _c = getPassengerNames(profile), firstName = _c.firstName, lastName = _c.lastName, middleName = _c.middleName;
    var headerLabel = React.useMemo(function () {
        if (getCustomUserCardHeaderLabel) {
            return getCustomUserCardHeaderLabel(profile, type);
        }
        if (type === TravellerTypes.CompanionEmpty) {
            return t('Add companion');
        }
        if (firstName && lastName) {
            return "".concat(lastName, " ").concat(firstName, " ").concat(middleName !== null && middleName !== void 0 ? middleName : '');
        }
        return t('Welcome!');
    }, [type, firstName, lastName, middleName]);
    var avatarIcon = React.useMemo(function () {
        if (type === TravellerTypes.CompanionEmpty) {
            return Add;
        }
        if (firstName && lastName) {
            return (React.createElement("span", null,
                lastName.charAt(0).toUpperCase(),
                firstName.charAt(0).toUpperCase()));
        }
        return ProfileIcon;
    }, [type, firstName, lastName]);
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.inline] = inline,
            _b[css.companion] = type === TravellerTypes.CompanionEmpty || type === TravellerTypes.CompanionFilled,
            _b[css.empty] = type === TravellerTypes.CompanionEmpty,
            _b)), onClick: onClick ? onClick : function () { } },
        React.createElement("div", { className: cn(css.header, headerClassName) },
            React.createElement("div", { className: css.header_content },
                React.createElement("div", { className: css.avatar }, avatarIcon),
                React.createElement("div", { className: css.info },
                    React.createElement("div", { className: css.name }, headerLabel),
                    confirmContacts && React.createElement("div", { className: css.notification }, t('Please confirm your contacts'))),
                (!!editText || showArrow) && (React.createElement("span", { className: css.edit },
                    editText,
                    " ",
                    showArrow && Chevron))))));
};
export default UserCard;
