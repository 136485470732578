import { __read, __spreadArray } from "tslib";
import { createSelector } from 'reselect';
import memes from '../../../memes';
import { sum } from '../../../utils';
export var getSelectedFares = function (state) { return state.selectedFares; };
var memoizedSum = memes(sum);
export var getTotalPrice = createSelector([getSelectedFares], function (selectedFares) {
    var lastSelectedFare = selectedFares[selectedFares.length - 1];
    var flightId = lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.flightInfo.id;
    var money = selectedFares.map(function (_a) {
        var prices = _a.prices;
        return prices.find(function (price) { return price.flight.id === flightId; }).price;
    });
    return memoizedSum.apply(void 0, __spreadArray([], __read(money), false));
});
export var getCreateOrderUrl = createSelector([getSelectedFares], function (selectedFares) {
    return "#/results/create-order/".concat(selectedFares.map(function (selectedFare) { return selectedFare.flightInfo.id; }).join('/'));
});
