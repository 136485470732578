import * as Group from './components/components/FormFields/Group/Group.css';
import * as PassengerForm from './components/PassengerForm.css';
import * as Header from './components/components/Header/Header.css';
import * as FormFields from './components/components/FormFields/FormFields.css';
import * as PopupWrapper from './components/components/PopupWrapper/PopupWrapper.css';
import * as FullscreenPopup from './components/components/PopupWrapper/FullscreenPopup/FullscreenPopup.css';
import * as LoyaltyGroup from './components/components/FormFields/Group/LoyaltyGroup/LoyaltyGroup.css';
import * as Disabilities from './components/components/Disabilities/Disabilities.css';
import * as Switch from './components/components/Switch/Switch.css';
import * as Accompanying from './components/components/FormFields/Group/Accompanying/Accompanying.css';
import * as ChangeGroup from './components/components/FormFields/Group/ChangeGroup/ChangeGroup.css';
export default {
    Group: Group,
    PassengerForm: PassengerForm,
    Header: Header,
    FormFields: FormFields,
    PopupWrapper: PopupWrapper,
    FullscreenPopup: FullscreenPopup,
    LoyaltyGroup: LoyaltyGroup,
    ChangeGroup: ChangeGroup,
    Disabilities: Disabilities,
    Switch: Switch,
    Accompanying: Accompanying
};
