import { SET_SERVICES_IS_SAVING, SET_SERVICES_SAVED } from './actions';
var initialServicesIsSavingState = false;
export var servicesIsSavingReducer = function (state, action) {
    if (state === void 0) { state = initialServicesIsSavingState; }
    switch (action.type) {
        case SET_SERVICES_IS_SAVING:
            return true;
        case SET_SERVICES_SAVED:
            return false;
    }
    return initialServicesIsSavingState;
};
