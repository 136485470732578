import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { UserSettings } from '../../types';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useSetUserSettingsMutation } from '@websky/graphql';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../../../Loader';
var MailSettings = function (_a) {
    var onError = _a.onError, userSettings = _a.userSettings, userEmail = _a.userEmail, refetchUserSettings = _a.refetchUserSettings;
    var _b = __read(useSetUserSettingsMutation(), 1), setSettings = _b[0];
    var _c = __read(React.useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var theme = useTheme('Account').Account, t = useTranslation('Account').t;
    var setUserSettings = function (settingName, value) { return __awaiter(void 0, void 0, void 0, function () {
        var res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, setSettings({
                            variables: {
                                params: [
                                    {
                                        name: settingName,
                                        value: value ? 'true' : 'false'
                                    }
                                ]
                            }
                        })];
                case 1:
                    res = _a.sent();
                    if (res.errors && res.errors.length) {
                        onError(res.errors[0]);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    onError(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var isChecked = (userSettings === null || userSettings === void 0 ? void 0 : userSettings.find(function (set) { return set.name === UserSettings.Subscribe; }).value) === 'true';
    var onChange = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, setUserSettings(UserSettings.Subscribe, !isChecked)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refetchUserSettings()];
                case 2:
                    _a.sent();
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: theme.account__offers },
        React.createElement("div", { className: theme.account__subscription },
            React.createElement("div", { className: theme.subscription__title }, t('Email for notification')),
            React.createElement("div", { className: theme.subscription__value }, userEmail)),
        React.createElement(FormControlLabel, { control: isLoading ? (React.createElement(Loader, { className: theme.subscription__loader })) : (React.createElement(Checkbox, { classes: {
                    root: theme.checkbox,
                    checked: theme.checked,
                    colorSecondary: theme.colorSecondary
                } })), onClick: onChange, disabled: isLoading, checked: isChecked, label: !isLoading && t('I want to receive news and special offers'), classes: {
                label: theme.mui__label
            } })));
};
export default MailSettings;
