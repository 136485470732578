import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { Grow, Slide } from '@mui/material';
export var GrowTransition = React.forwardRef(function Transition(props, ref) {
    return React.createElement(Grow, __assign({ ref: ref }, props));
});
export var SlideUpTransition = React.forwardRef(function Transition(props, ref) {
    var _a = __read(useState('up'), 2), direction = _a[0], setDirection = _a[1];
    return (React.createElement(Slide, __assign({ direction: direction, onEntered: function () { return setDirection('up'); }, onExited: function () { return setDirection('down'); }, ref: ref }, props)));
});
