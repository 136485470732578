import { __read } from "tslib";
import * as React from 'react';
var ManualInput = function (_a) {
    var children = _a.children, inputClassName = _a.inputClassName, onEnter = _a.onEnter, showInput = _a.showInput;
    var _b = __read(React.useState(false), 2), isInputActive = _b[0], setInputActive = _b[1];
    var onInputChange = function (e) {
        setInputActive(!!e.target.value);
    };
    var onKeyDown = function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            onEnter(e.target.value);
        }
    };
    React.useEffect(function () {
        if (!showInput) {
            setInputActive(false);
        }
    }, [showInput]);
    return (React.createElement(React.Fragment, null,
        showInput && (React.createElement("input", { type: "text", autoFocus: true, className: inputClassName, onChange: onInputChange, onKeyDown: onKeyDown })),
        !isInputActive && children));
};
export default ManualInput;
