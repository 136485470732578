import { __assign, __rest } from "tslib";
import * as React from 'react';
import SegmentCompact from './SegmentCompact/SegmentCompact';
import SegmentCommon from './SegmentCommon/SegmentCommon';
var Segment = function (_a) {
    var variant = _a.variant, props = __rest(_a, ["variant"]);
    if (variant === 'compact') {
        return React.createElement(SegmentCompact, __assign({}, props));
    }
    return React.createElement(SegmentCommon, __assign({}, props));
};
export default Segment;
