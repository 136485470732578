import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ApolloError } from 'apollo-boost';
import { ExareReason, ExareStatus, TravellerFieldEnum, useConfirmChangeTravellerOrderMutation, useCreateChangeTravellerDataMutation, useRefuseExareMutation } from '@websky/graphql';
import { getCustomer, getOrder } from '../../../Checkout/store/order/selectors';
import { capitalizeFirstLetter, getPassengerNames, getUserValue } from '../../../utils';
import { getDifferentPassengerValues } from '../../utils';
export var usePassengerTicketControls = function (_a) {
    var lastChangePaxData = _a.lastChangePaxData;
    var _b = __read(React.useState(null), 2), requestError = _b[0], setRequestError = _b[1];
    var _c = __read(useCreateChangeTravellerDataMutation(), 2), createChangePaxData = _c[0], createChangeDataLoading = _c[1].loading;
    var _d = __read(useRefuseExareMutation(), 2), refuseExare = _d[0], refuseExareLoading = _d[1].loading;
    var _e = __read(useConfirmChangeTravellerOrderMutation(), 2), confirmChangeTravellerOrder = _e[0], confirmChangeLoading = _e[1].loading;
    var availableActions = React.useMemo(function () {
        return {
            refuse: !!(lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.refuseAllowed),
            payment: (lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.status) === ExareStatus.AwaitingPayment,
            confirm: (lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.status) === ExareStatus.NeedConfirm,
            createNew: lastChangePaxData && [ExareStatus.Rejected, ExareStatus.Finished].includes(lastChangePaxData.status)
        };
    }, [lastChangePaxData]);
    var onRequestError = React.useCallback(function (e) {
        var _a;
        if (e instanceof ApolloError) {
            if ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                setRequestError(e.graphQLErrors[0]);
            }
        }
    }, [setRequestError]);
    var onClearError = React.useCallback(function () {
        setRequestError(null);
    }, [setRequestError]);
    var onCreateOrderRequest = React.useCallback(function (orderId, values, changeTravellers) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createChangePaxData({
                            variables: {
                                parameters: {
                                    orderId: orderId,
                                    name: values.name,
                                    email: values.email,
                                    phone: values.phone,
                                    comment: values.comment,
                                    travellers: __spreadArray([], __read(changeTravellers), false)
                                }
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_1 = _a.sent();
                    onRequestError(e_1);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [createChangePaxData, onRequestError]);
    var onRefuseRequest = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, refuseExare({
                            variables: { id: lastChangePaxData.id }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_2 = _a.sent();
                    onRequestError(e_2);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [refuseExare, lastChangePaxData, onRequestError]);
    var onConfirmRequest = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, confirmChangeTravellerOrder({ variables: { id: lastChangePaxData.id } })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_3 = _a.sent();
                    onRequestError(e_3);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [confirmChangeTravellerOrder, lastChangePaxData, onRequestError]);
    return {
        // data
        requestError: requestError,
        availableActions: availableActions,
        isLoading: createChangeDataLoading || refuseExareLoading || confirmChangeLoading,
        // functions
        onCreateOrderRequest: onCreateOrderRequest,
        onRefuseRequest: onRefuseRequest,
        onConfirmRequest: onConfirmRequest,
        onRequestError: onRequestError,
        onClearError: onClearError
    };
};
export var usePassengerTicket = function (passenger, prices, onGoToPayment, onRefetchOrder, onReturnToOrder, setChangePaxOrder, changeTravellers, lastChangePaxData, reason) {
    var t = useTranslation('EditPassenger').t;
    var order = useSelector(getOrder);
    var customer = useSelector(getCustomer);
    var ticketControls = usePassengerTicketControls({ lastChangePaxData: lastChangePaxData });
    var fullName = React.useMemo(function () {
        var _a = getPassengerNames(passenger), firstName = _a.firstName, lastName = _a.lastName;
        return [lastName, firstName].map(capitalizeFirstLetter).join(' ');
    }, [passenger]);
    var customerValues = React.useMemo(function () {
        var isFirstPassenger = passenger.id === '0';
        var name = getUserValue(customer, TravellerFieldEnum.FirstName);
        if (isFirstPassenger || !name) {
            var changedName = (changeTravellers === null || changeTravellers === void 0 ? void 0 : changeTravellers.length)
                ? getUserValue(changeTravellers[0], TravellerFieldEnum.FirstName)
                : null;
            var passengerName = getUserValue(passenger, TravellerFieldEnum.FirstName);
            name = changedName || passengerName;
        }
        return {
            name: name,
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [customer, passenger, changeTravellers]);
    var editableValues = React.useMemo(function () {
        var _a;
        var actualTravellerValues = [];
        if (changeTravellers === null || changeTravellers === void 0 ? void 0 : changeTravellers.length) {
            actualTravellerValues = changeTravellers[0].values;
        }
        else if ((_a = lastChangePaxData === null || lastChangePaxData === void 0 ? void 0 : lastChangePaxData.travellers) === null || _a === void 0 ? void 0 : _a.length) {
            actualTravellerValues = lastChangePaxData.travellers[0].values;
        }
        var differentValues = getDifferentPassengerValues(passenger.values, actualTravellerValues);
        return __spreadArray([], __read(differentValues.entries()), false).map(function (_a) {
            var _b = __read(_a, 2), type = _b[0], inputParameter = _b[1];
            var value = inputParameter.value;
            if (type === TravellerFieldEnum.DocType) {
                return "".concat(t("Passenger:docType"), ": ").concat(t("Passenger:".concat(value)));
            }
            return "".concat(t("Passenger:".concat(inputParameter.name)), ": ").concat(value);
        });
    }, [passenger, changeTravellers, lastChangePaxData]);
    var actualPrices = React.useMemo(function () {
        var _a;
        var ticketPrices;
        if (lastChangePaxData) {
            ticketPrices = lastChangePaxData.prices;
        }
        else if (prices) {
            ticketPrices = prices;
        }
        if (ticketPrices) {
            var priceToPay = null;
            if (((_a = ticketPrices.priceToPay) === null || _a === void 0 ? void 0 : _a.amount) !== undefined) {
                priceToPay = __assign(__assign({}, ticketPrices.priceToPay), { amount: Math.abs(ticketPrices.priceToPay.amount) });
            }
            return __assign(__assign({}, ticketPrices), { priceToPay: priceToPay });
        }
        return null;
    }, [lastChangePaxData, prices]);
    var isAutoMode = React.useMemo(function () {
        var _a, _b, _c;
        var autoMode = (_c = (_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.changeTravellerDataAvailability) === null || _b === void 0 ? void 0 : _b.find(function (changeTraveller) { return changeTraveller.id === passenger.id; })) === null || _c === void 0 ? void 0 : _c.autoMode;
        return reason !== ExareReason.DocumentReplacement && autoMode;
    }, [order.exareInfo, passenger, reason]);
    var onFillOrder = React.useCallback(function (changeTravellerOrder) {
        var status = changeTravellerOrder === null || changeTravellerOrder === void 0 ? void 0 : changeTravellerOrder.status;
        if ([ExareStatus.AwaitingPayment, ExareStatus.NeedConfirm].includes(status)) {
            setChangePaxOrder(changeTravellerOrder);
            if (status === ExareStatus.AwaitingPayment) {
                onGoToPayment();
            }
        }
        else {
            onReturnToOrder(true);
        }
    }, [setChangePaxOrder, onGoToPayment, onReturnToOrder]);
    var onCreateOrderRequest = React.useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ticketControls.onCreateOrderRequest(order.id, values, changeTravellers)];
                case 1:
                    data = _a.sent();
                    if (data === null || data === void 0 ? void 0 : data.CreateChangeTravellerData) {
                        onFillOrder(data.CreateChangeTravellerData);
                    }
                    return [2 /*return*/, !!data];
            }
        });
    }); }, [ticketControls.onCreateOrderRequest, order, changeTravellers, onFillOrder]);
    var onRefuseRequest = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ticketControls.onRefuseRequest()];
                case 1:
                    data = _a.sent();
                    if (!((data === null || data === void 0 ? void 0 : data.RefuseExare) && data.RefuseExare.status === ExareStatus.Refused)) return [3 /*break*/, 3];
                    return [4 /*yield*/, onRefetchOrder()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [ticketControls.onRefuseRequest, onRefetchOrder]);
    var onConfirmRequest = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ticketControls.onConfirmRequest()];
                case 1:
                    data = _a.sent();
                    if (data === null || data === void 0 ? void 0 : data.ConfirmExare) {
                        onFillOrder(data.ConfirmExare);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [ticketControls.onConfirmRequest, setChangePaxOrder]);
    return {
        // data
        order: order,
        customer: customer,
        fullName: fullName,
        customerValues: customerValues,
        editableValues: editableValues,
        actualPrices: actualPrices,
        requestError: ticketControls.requestError,
        availableActions: ticketControls.availableActions,
        isLoading: ticketControls.isLoading,
        isAutoMode: isAutoMode,
        // functions
        onCreateOrderRequest: onCreateOrderRequest,
        onRefuseRequest: onRefuseRequest,
        onConfirmRequest: onConfirmRequest,
        onClearError: ticketControls.onClearError
    };
};
