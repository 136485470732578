var _a;
import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { CategoriesPage } from './CategoriesPage/CategoriesPage';
import { NotificationsPage } from './NotificationsPage/NotificationsPage';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { initI18n } from '../../utils';
import { PersonBox, Chat as ChatIcon, StarBold, Tag, NotificationBold } from '../../Icons';
import { AppealsPage } from './Appeals/AppealsPage';
import { PromotionsPage } from './Promotions/PromotionsPage';
import { OrdersPage } from './Orders/OrdersPage';
import { PromoCodesPage } from './PromoCodes/PromoCodesPage';
import { Chat } from './Chat/Chat';
import { PaximizerCommunicationSectionEnum, useGetPaxSectionsQuery, useReadCommunicationSectionMessageQuery } from '@websky/graphql';
import { OrderDetailPage } from './Orders/OrderDetailPage';
import { PromotionsDetailPage } from './Promotions/PromotionsDetailPage';
import { PromoCodesDetailPage } from './PromoCodes/PromoCodesDetailPage';
import Loader from '../../Loader';
import { AppealDetailPage } from './Appeals/AppealDetailPage';
import { useDispatch, useSelector } from 'react-redux';
import { getCommunicationCategory } from '../store/selectors';
import { markAllAsReadInCategory } from '../store/actions';
initI18n('Communications');
var CATEGORIES_CONFIG = (_a = {},
    _a[PaximizerCommunicationSectionEnum.my_appeals] = {
        listComponent: React.createElement(AppealsPage, null),
        icon: PersonBox,
        detailComponent: React.createElement(AppealDetailPage, null),
        path: 'my-appeals'
    },
    _a[PaximizerCommunicationSectionEnum.chat] = {
        listComponent: React.createElement(Chat, null),
        icon: ChatIcon,
        path: 'chat'
    },
    _a[PaximizerCommunicationSectionEnum.promotions] = {
        listComponent: React.createElement(PromotionsPage, null),
        icon: StarBold,
        detailComponent: React.createElement(PromotionsDetailPage, null),
        path: 'promotions'
    },
    _a[PaximizerCommunicationSectionEnum.order_notifications] = {
        listComponent: React.createElement(OrdersPage, null),
        icon: NotificationBold,
        detailComponent: React.createElement(OrderDetailPage, null),
        path: 'orders'
    },
    _a[PaximizerCommunicationSectionEnum.my_promo_codes] = {
        listComponent: React.createElement(PromoCodesPage, null),
        icon: Tag,
        detailComponent: React.createElement(PromoCodesDetailPage, null),
        path: 'promo-codes'
    },
    _a);
export var CategoryContext = createContext(null);
var CommunicationBase = function () {
    var categoryDictionary = useSelector(getCommunicationCategory);
    var dispatch = useDispatch();
    var path = useRouteMatch().path;
    var _a = __read(useState(false), 2), isLoadingRead = _a[0], setIsLoadingRead = _a[1];
    var readAll = useReadCommunicationSectionMessageQuery({
        skip: true
    }).refetch;
    var loadingCategories = useGetPaxSectionsQuery({
        variables: {
            withLastMessage: true
        }
    }).loading;
    var _b = __read(useState(false), 2), appliedReadAll = _b[0], setAppliedReadAll = _b[1];
    useEffect(function () {
        setAppliedReadAll(false);
    }, [path]);
    var markAllAsRead = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoadingRead(true);
                    return [4 /*yield*/, readAll({
                            sectionId: id
                        })];
                case 1:
                    response = _a.sent();
                    if (response) {
                        dispatch(markAllAsReadInCategory(id));
                        setAppliedReadAll(true);
                    }
                    setIsLoadingRead(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var categories = useMemo(function () {
        return Object.values(categoryDictionary).map(function (category) {
            var el = CATEGORIES_CONFIG[category.id];
            return __assign(__assign({}, category), { id: category.id, path: el.path, icon: el.icon || '', listComponent: el.listComponent, detailComponent: el.detailComponent });
        });
    }, [categoryDictionary]);
    return (React.createElement(Switch, null,
        loadingCategories && React.createElement(Loader, null),
        React.createElement(Route, { exact: true, path: path, render: function () { return React.createElement(CategoriesPage, { categories: categories }); } }),
        React.createElement(CategoryContext.Provider, { value: {
                appliedReadAll: appliedReadAll
            } },
            React.createElement(Route, { path: "".concat(path, "/:categoryId"), render: function () { return (React.createElement(NotificationsPage, { categories: categories, loadingRead: isLoadingRead, markAllAsRead: markAllAsRead })); } }))));
};
export default withRouter(CommunicationBase);
