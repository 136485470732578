import * as React from 'react';
import { ArrowForward } from '../../../Icons';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import FullscreenDialogClose from '../Close/Close';
import { useRenderers } from '../../../renderProps';
var FullScreenDialogHeader = function (_a) {
    var onClose = _a.onClose, closeClassName = _a.closeClassName, headerClassName = _a.headerClassName, title = _a.title, renderBeforeCloseButton = _a.renderBeforeCloseButton, hideTitle = _a.hideTitle;
    var theme = useTheme('FullscreenDialog').FullscreenDialog;
    var renderFullScreenDialogClose = useRenderers().renderFullScreenDialogClose;
    var renderCloseButton = React.useMemo(function () {
        if (renderFullScreenDialogClose) {
            return renderFullScreenDialogClose({
                onClose: onClose,
                closeClassName: closeClassName
            });
        }
        else {
            return React.createElement(FullscreenDialogClose, { onClose: onClose, closeClassName: closeClassName });
        }
    }, [onClose, closeClassName, renderFullScreenDialogClose]);
    return (React.createElement("div", { className: cn(theme.header, headerClassName) },
        React.createElement("div", { className: theme.header__wrapper },
            !hideTitle && (React.createElement("div", { className: theme.header__title },
                React.createElement("div", { className: theme.back, onClick: onClose }, ArrowForward),
                title)), renderBeforeCloseButton === null || renderBeforeCloseButton === void 0 ? void 0 :
            renderBeforeCloseButton(),
            renderCloseButton)));
};
export default FullScreenDialogHeader;
