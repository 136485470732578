import { __read, __spreadArray } from "tslib";
import { ADD_PASSENGER_IDS, REMOVE_PASSENGER_IDS, SET_PASSENGER_IDS } from './actions';
export var passengersReducer = function (state, action) {
    if (state === void 0) { state = []; }
    if (action.type === ADD_PASSENGER_IDS) {
        return __spreadArray(__spreadArray([], __read(state), false), __read(action.payload), false);
    }
    if (action.type === REMOVE_PASSENGER_IDS) {
        return state.filter(function (id) { return action.payload.indexOf(id) === -1; });
    }
    if (action.type === SET_PASSENGER_IDS) {
        return action.payload;
    }
    return state;
};
