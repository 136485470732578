import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { BaseButton, InfoDetails } from '../../../../index';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../Tooltip';
import { useConfig } from '../../../../context';
import TransitTooltip from '../../../../TransitTooltip';
import { TransitTooltipContext } from '../../../../TransitTooltip/utils';
import InfoMessages from '../../../../InfoMessages';
import { InfoMessagePositionsEnum } from '@websky/graphql';
var bestIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "14", cy: "14", r: "14", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.6 11.5111C20.2844 11.5111 20.8444 12.0711 20.8444 12.7555L20.8382 12.8053L20.8444 12.8115V14C20.8444 14.1617 20.8133 14.3111 20.7573 14.4542L18.8782 18.8409C18.6915 19.2889 18.2497 19.6 17.7333 19.6H12.1333C11.4489 19.6 10.8889 19.04 10.8889 18.3555V12.1333C10.8889 11.7911 11.0257 11.48 11.256 11.256L15.3502 7.15552L16.0097 7.80885C16.1777 7.97685 16.2835 8.2133 16.2835 8.46841L16.2649 8.66752L15.6737 11.5111H19.6ZM9.64441 19.6H7.15552V12.1333H9.64441V19.6Z", fill: "white" })));
var minimumSeatsAccordingIata = new Map([
    ['N4', 10],
    ['DV', 10],
    ['Y7', 10],
    ['A9', 10],
    ['LEO', 10],
    ['WZ', 10],
    ['YC', 10],
    ['ZZ', 10]
]);
export var SimpleFlightPrice = function (_a) {
    var _b;
    var _c;
    var singleFlight = _a.singleFlight, best = _a.best, originalPriceOrLabel = _a.originalPriceOrLabel, price = _a.price, additionalTaxes = _a.additionalTaxes, selected = _a.selected, subsidized = _a.subsidized, seatsLeft = _a.seatsLeft, flightMessages = _a.flightMessages;
    var css = useTheme('DesktopFlightRoute').FlightPriceStyles;
    var t = useTranslation('FlightPrice').t;
    var isTransitTariff = React.useContext(TransitTooltipContext).isTransitTariff;
    var _d = useConfig().global.companyInfo, subsidyFaresConditionURL = _d.subsidyFaresConditionURL, iataCode = _d.iataCode;
    var minimumIataSeatsValue = (_c = minimumSeatsAccordingIata.get(iataCode)) !== null && _c !== void 0 ? _c : 10;
    return (React.createElement("div", { className: cn(css.price, (_b = {},
            _b[css.price_selected] = selected,
            _b[css.price_subsidies] = subsidized,
            _b)) },
        React.createElement("div", { className: css.price__container },
            subsidized && (React.createElement(Tooltip, { titleClassName: css.subsidies__tooltip, title: t('A subsidized fare requires all passengers on the booking to have benefits.') },
                React.createElement("a", { href: subsidyFaresConditionURL, onClick: function (e) { return e.stopPropagation(); }, target: "_blank", className: css.subsidies },
                    t('Subsidized flight'),
                    " ",
                    InfoDetails))),
            isTransitTariff && React.createElement(TransitTooltip, null),
            originalPriceOrLabel,
            selected ? (price) : (React.createElement(BaseButton, { variant: "optionAction", className: css.button }, price)),
            additionalTaxes,
            best && React.createElement("div", { className: css.best }, bestIcon),
            Number.isInteger(seatsLeft) && seatsLeft < minimumIataSeatsValue && (React.createElement("div", { className: css.seats }, t('{{count}} {{ticket}} {{left}}', {
                count: seatsLeft,
                ticket: t('tickets', { count: seatsLeft }),
                left: t('left', { count: seatsLeft })
            }))),
            React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flightPrice, className: css.infoMessage, itemHtmlClassName: css.infoMessage__htmlMessage, messages: flightMessages }))));
};
