import { __assign } from "tslib";
import { PaximizerCommunicationSectionEnum } from '@websky/graphql';
import { setCommunicationCategory, decreaseUnreadCountByCategory, markAllAsReadInCategory, increaseUnreadCountByCategory } from './actions';
var initialState = {};
export var communicationsReducer = function (state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    if (action.type === setCommunicationCategory.toString()) {
        if (action.payload) {
            var result_1 = {};
            action.payload.forEach(function (category) {
                // Todo: временно скрываем my_appeals
                if (category.id !== PaximizerCommunicationSectionEnum.my_appeals) {
                    result_1[category.id] = category;
                }
            });
            return result_1;
        }
    }
    else if (action.type === markAllAsReadInCategory.toString()) {
        var payload = action.payload;
        return __assign(__assign({}, state), (_a = {}, _a[payload] = __assign(__assign({}, state[payload]), { unreadMessagesCount: 0 }), _a));
    }
    else if (action.type === decreaseUnreadCountByCategory.toString()) {
        var payload = action.payload;
        if (payload) {
            return __assign(__assign({}, state), (_b = {}, _b[payload] = __assign(__assign({}, state[payload]), { unreadMessagesCount: state[payload].unreadMessagesCount - 1 }), _b));
        }
        return state;
    }
    else if (action.type === increaseUnreadCountByCategory.toString()) {
        var payload = action.payload;
        if (payload) {
            return __assign(__assign({}, state), (_c = {}, _c[payload] = __assign(__assign({}, state[payload]), { unreadMessagesCount: state[payload].unreadMessagesCount + 1 }), _c));
        }
        return state;
    }
    return initialState;
};
