import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PromoCodeStatus } from '../types';
import Link from '../../Link';
import Modal from '../../Modal';
import PromoCodeForm from '../Form/PromoCodeForm';
import { useTheme } from '../../theme';
import * as icons from '../../Icons/';
import { initI18n, useIsMobile } from '../../utils';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import { CloseClear } from '../../Icons/';
import { CloseButton } from './../../index';
initI18n('PromoCode');
/**
 * you can get all needed data from ../hooks.ts ( except onSubmit handler )
 */
var PromoCode = function (props) {
    var _a;
    var t = useTranslation('PromoCode').t;
    var theme = useTheme('PromoCode').PromoCode;
    var allowPromoCodes = useConfig().global.allowPromoCodes;
    var isMobile = useIsMobile();
    var SHOW_MODAL_IF_STATUS = [PromoCodeStatus.Empty, PromoCodeStatus.NotApplied];
    var SHOW_CLEAR_IF_STATUS = [PromoCodeStatus.Applied, PromoCodeStatus.Provided, PromoCodeStatus.NotApplied];
    var clearTrigger = props.onClear && (React.createElement("span", { className: theme.clearWrapper },
        React.createElement(Link, { action: props.onClear, className: theme.clear },
            t('reset'),
            " ",
            React.createElement("span", { className: theme.clearIcon }, icons.CloseClear))));
    var closeButtonRenderer = function (onClick) { return (React.createElement(CloseButton, { variant: "closePopup", className: theme.closeButton, onClick: onClick }, CloseClear)); };
    return allowPromoCodes ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.promoCode, props.className, (_a = {},
                _a[theme.promoCode_applied] = props.status === PromoCodeStatus.Applied,
                _a[theme.promoCode_notApplied] = props.status === PromoCodeStatus.NotApplied,
                _a[theme.promoCode_empty] = props.status === PromoCodeStatus.Empty,
                _a)) },
            React.createElement("div", { className: theme.contentWrp },
                SHOW_MODAL_IF_STATUS.includes(props.status) && (React.createElement("span", { className: theme.content },
                    props.status === PromoCodeStatus.Empty && (React.createElement(Link, { className: theme.trigger, action: props.onOpenForm }, t('Do you have a promo code?'))),
                    props.status === PromoCodeStatus.NotApplied && (React.createElement(Link, { className: theme.trigger, action: props.onOpenForm },
                        React.createElement("span", { className: theme.icon_notApplied }, icons.Warning),
                        t('Promo code not applied'))))),
                SHOW_CLEAR_IF_STATUS.includes(props.status) && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: theme.content },
                        props.status === PromoCodeStatus.Provided && props.promoCode && (React.createElement("span", null,
                            t('Promo code'),
                            ": ",
                            props.promoCode)),
                        props.status === PromoCodeStatus.Applied && props.promoCode && (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: theme.icon }, icons.Included),
                            t('Promo code applied')))),
                    props.promoCode && clearTrigger)),
                props.renderAfter && props.renderAfter(props))),
        React.createElement(Modal, { open: props.isFormOpen, onClose: props.onCloseForm, isRounded: true, maxWidth: 'xs', slideUp: isMobile, classes: { root: theme.modal__root, scrollBody: theme.modal__scrollBody, paper: theme.modal }, closeButtonRenderer: closeButtonRenderer },
            React.createElement(OverrideComponent, { componentProps: {
                    isLoading: props.isLoading,
                    status: props.status,
                    onSubmit: props.onAddPromoCode
                }, component: {
                    renderPromoCodeForm: PromoCodeForm
                } })))) : null;
};
export default PromoCode;
