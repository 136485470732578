import { __assign } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Tooltip from '../../Tooltip';
import Button from '../../Button/Button';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useConfig } from '../../context';
initI18n('CheckinRules');
var lastVisibleError = '';
var CheckinRules = function (_a) {
    var _b, _c, _d;
    var onSumbit = _a.onSumbit, rules = _a.rules;
    var css = useTheme('CheckinRules').CheckinRules;
    var t = useTranslation('CheckinRules').t;
    var isDev = process.env.NODE_ENV === 'development';
    var iataCode = (_d = (_c = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.global) === null || _c === void 0 ? void 0 : _c.companyInfo) === null || _d === void 0 ? void 0 : _d.iataCode;
    return (React.createElement("div", { className: css.rules },
        isDev && (React.createElement(Helmet, null,
            React.createElement("link", { rel: "stylesheet", type: "text/css", href: {
                    ZZ: '',
                    N4: 'https://nordwindairlines.ru/build/layout/adaptive.css?v1'
                }[iataCode] }))),
        React.createElement("h3", { className: css.rules__title }, t('Terms & Conditions')),
        React.createElement("div", { className: css.rules__text, dangerouslySetInnerHTML: { __html: rules } }),
        React.createElement(Form, { onSubmit: onSumbit }, function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement("form", { className: css.rules__footer, onSubmit: handleSubmit },
                React.createElement(Field, { type: "checkbox", name: "agree", initialValue: false, validate: function (value) {
                        if (!value) {
                            return t('Please read and accept the online check-in terms and conditions');
                        }
                    } }, function (_a) {
                    var meta = _a.meta, input = _a.input;
                    if (meta.error) {
                        lastVisibleError = meta.error;
                    }
                    return (React.createElement(FormControlLabel, { classes: {
                            label: css.rules__label
                        }, control: React.createElement(Tooltip, { open: meta.submitFailed && !input.value, title: lastVisibleError, placement: "top-start" },
                            React.createElement(Checkbox, { color: "primary", classes: {
                                    colorPrimary: css.rules__checkbox,
                                    checked: css.rules__checkbox_checked
                                }, checked: input.checked, inputProps: __assign({}, input) })), label: React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: t('I have read and agreed to the online check-in terms and conditions')
                            } }) }));
                }),
                React.createElement(Button, { type: "submit", variant: "flat", size: "large", className: css.rules__button }, t('Continue'))));
        })));
};
export default CheckinRules;
