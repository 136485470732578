import { createTheme } from '../utils';
import * as Results from './components/Results/Results.css';
import * as Calendar from './components/Results/Calendar/Calendar.css';
import * as Sortings from './components/Results/FlightsList/Sortings/Sortings.css';
import * as DummyWeekCalendar from './components/Results/Calendar/DummyWeekCalendar/DummyWeekCalendar.css';
import * as DummyPriceGraph from './components/Results/Calendar/DummyPriceGraph/DummyPriceGraph.css';
import * as MobilePriceGraph from './components/Results/FlightsList/MobilePriceGraph/MobilePriceGraph.css';
import * as FlightsList from './components/Results/FlightsList/FlightsList.css';
import * as SelectedFares from './components/Results/SelectedFares/SelectedFares.css';
import * as OtherDates from './components/Results/OtherDates/OtherDates.css';
import * as Disclaimer from './components/Results/Disclaimer/Disclaimer.css';
export var defaultTheme = {
    Results: Results,
    Sortings: Sortings,
    Calendar: Calendar,
    DummyWeekCalendar: DummyWeekCalendar,
    DummyPriceGraph: DummyPriceGraph,
    MobilePriceGraph: MobilePriceGraph,
    FlightsList: FlightsList,
    SelectedFares: SelectedFares,
    OtherDates: OtherDates,
    Disclaimer: Disclaimer
};
export var Consumer = createTheme(defaultTheme).Consumer;
export default defaultTheme;
