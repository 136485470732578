import { __assign, __rest } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { useIsMobile } from '../../utils';
import { useToggleable } from '../../hooks';
import Button from '../../Button';
import Modal from '../../Modal';
import MilesRecoveryContent from './MilesRecoveryContent/MilesRecoveryContent';
var MilesRecovery = function (_a) {
    var _b;
    var renderTrigger = _a.renderTrigger, props = __rest(_a, ["renderTrigger"]);
    var css = useTheme('MilesRecovery').MilesRecoveryStyles;
    var t = useTranslation('MilesRecovery').t;
    var isMobile = useIsMobile();
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(open)) !== null && _b !== void 0 ? _b : React.createElement(Button, { onClick: open }, t('Miles recovery')),
        React.createElement(Modal, { maxWidth: 'sm', classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, slideUp: isMobile, open: isOpen, onClose: close, disablePortal: true },
            React.createElement(MilesRecoveryContent, __assign({}, props, { onClose: close })))));
};
export default MilesRecovery;
