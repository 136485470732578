import { __assign, __awaiter, __generator, __read, __rest, __spreadArray } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSegments } from '../../../../../store/order/selectors';
import { BaseButton } from '../../../../../../../index';
import { useTheme } from '../../../../../../../theme';
import { initI18n } from '../../../../../../../utils';
import MediaQuery from '../../../../../../../MediaQuery/MediaQuery';
import { SelectableSegmentDesktop, SelectableSegmentMobile } from '../../../../../../../SelectableSegment';
import ModalConfirm from '../../../../../../../ModalConfirm/components/ModalConfirm';
initI18n('SelectSegment');
var CHECKED_IN_SEGMENT_STATUS = 'CheckedIn';
var SelectSegmentsModal = function (props) {
    var onSelect = props.onSegmentSelect, selectedSegments = props.selectedSegments, onConfirm = props.onConfirm, header = props.header, passedGroupTitle = props.passedGroupTitle, _a = props.type, type = _a === void 0 ? 'default' : _a, modalProps = __rest(props, ["onSegmentSelect", "selectedSegments", "onConfirm", "header", "passedGroupTitle", "type"]);
    var css = useTheme('Checkin').SelectSegmentsModal;
    var segments = useSelector(getSegments);
    var t = useTranslation('SelectSegment').t;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var renderSegments = useCallback(function (segment) {
        var isDisabled = type === 'cancel' && !segment.checkinInfo.cancelAvailable;
        return (React.createElement(React.Fragment, { key: segment.id },
            React.createElement(MediaQuery, { minWidth: 'mobile' },
                React.createElement(SelectableSegmentDesktop, { segment: segment, onChange: onSelect, selected: selectedSegments.has(segment.id), isLoading: isLoading, isDisabled: isDisabled })),
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement(SelectableSegmentMobile, { segment: segment, onChange: onSelect, selected: selectedSegments.has(segment.id), isLoading: isLoading, isDisabled: isDisabled }))));
    }, [selectedSegments, segments, type, isLoading]);
    var segmentsByGroup = React.useMemo(function () {
        return segments.reduce(function (result, currentSegment) {
            if (currentSegment.checkinInfo.checkInStatus === CHECKED_IN_SEGMENT_STATUS) {
                return __assign(__assign({}, result), { passed: __spreadArray(__spreadArray([], __read(result.passed), false), [currentSegment], false) });
            }
            return __assign(__assign({}, result), { checkin: __spreadArray(__spreadArray([], __read(result.checkin), false), [currentSegment], false) });
        }, {
            passed: [],
            checkin: []
        });
    }, [segments]);
    var onConfirmHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onConfirm()];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ModalConfirm, __assign({ size: "large" }, modalProps, { isOpen: modalProps.open, hideTitle: true, hideFooter: true, noPadding: true, ignoreClose: true, classes: { root: css.dialogRoot } }),
        React.createElement("div", { className: css.header }, header !== null && header !== void 0 ? header : t('Check-in for flights')),
        React.createElement("div", { className: css.content },
            !!segmentsByGroup.passed.length && (React.createElement(React.Fragment, null,
                React.createElement("h2", { className: css.checkin__status }, passedGroupTitle !== null && passedGroupTitle !== void 0 ? passedGroupTitle : t('GetTalon')),
                segmentsByGroup.passed.map(renderSegments))),
            !!segmentsByGroup.checkin.length && (React.createElement(React.Fragment, null,
                React.createElement("h2", { className: css.checkin__status }, t('GoToCheckin')),
                segmentsByGroup.checkin.map(renderSegments))),
            React.createElement(BaseButton, { variant: 'action', className: css.button, disabled: !selectedSegments.size, isLoading: isLoading, onClick: onConfirmHandler }, t('Confirm')))));
};
export default SelectSegmentsModal;
