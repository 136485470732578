import * as React from 'react';
import { Button } from '../../../../index';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import FullscreenPopup from './FullscreenPopup/FullscreenPopup';
import cn from 'classnames';
var PopupWrapper = function (props) {
    var _a;
    var t = useTranslation('Passenger').t;
    var theme = useTheme('PassengerForm').PopupWrapper;
    if (props.inline) {
        return React.createElement(React.Fragment, null, props.children);
    }
    return (React.createElement(FullscreenPopup, { open: props.open },
        React.createElement("div", { className: theme.fields }, props.children),
        React.createElement("div", { className: theme.controls },
            React.createElement(Button, { variant: "flat", onClick: props.onClose, className: theme.cancel }, t('Cancel')),
            React.createElement(Button, { variant: "flat", onClick: props.onClose, disabled: !props.correct, className: cn(theme.confirm, (_a = {}, _a[theme.disabled] = !props.correct, _a)) }, t('Confirm')))));
};
export default PopupWrapper;
