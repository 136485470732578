import * as React from 'react';
import cn from 'classnames';
import { Chevron, CloseClear } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../Tooltip';
import SeatPrice from '../../../SeatPrice/SeatPrice';
var DesktopPassenger = function (_a) {
    var _b;
    var className = _a.className, activeClassName = _a.activeClassName, passengerName = _a.passengerName, passengerAvatarText = _a.passengerAvatarText, seatNumbers = _a.seatNumbers, price = _a.price, onClick = _a.onClick, isActive = _a.isActive, isDisabled = _a.isDisabled, onClearSeat = _a.onClearSeat, recommend = _a.recommend, changeable = _a.changeable, isFreeSeat = _a.isFreeSeat, _c = _a.showSelect, showSelect = _c === void 0 ? true : _c, tooltipTitle = _a.tooltipTitle;
    var theme = useTheme('SeatMap').Passenger;
    var t = useTranslation('SeatMap').t;
    var passenger = (React.createElement("div", { className: cn(className, theme.item, (_b = {},
            _b[theme.item_active] = isActive,
            _b[theme.item_recommend] = recommend,
            _b[theme.item_disabled] = isDisabled,
            _b[theme.item_withSeat] = !!(seatNumbers === null || seatNumbers === void 0 ? void 0 : seatNumbers.length),
            _b[activeClassName] = isActive,
            _b)), onClick: onClick },
        React.createElement("div", { className: theme.main },
            React.createElement("div", { className: theme.avatar }, passengerAvatarText),
            React.createElement("div", { className: theme.passengerName }, passengerName)),
        !isDisabled && (React.createElement(React.Fragment, null, seatNumbers.length > 0 ? (React.createElement("div", { className: theme.payload },
            React.createElement("div", { className: theme.numbers }, seatNumbers.join(', ')),
            isFreeSeat ? (React.createElement("span", { className: theme.freeSeat__text }, t('Free seat'))) : (price && (React.createElement(SeatPrice, { className: theme.seatPrice, moneyClassName: theme.money, money: price }))),
            recommend ? (React.createElement("div", null, Chevron)) : (changeable && (React.createElement("div", { className: theme.clear, onClick: onClearSeat }, CloseClear))))) : (showSelect && React.createElement("div", { className: cn(theme.payload, theme.select) }, t('Select')))))));
    if (tooltipTitle) {
        return React.createElement(Tooltip, { title: tooltipTitle }, passenger);
    }
    return passenger;
};
export default DesktopPassenger;
