import * as React from 'react';
import { useTheme } from '../../../theme';
import { arrowIcon, emptyCard } from '../../Icons';
import { ButtonBase } from '@material-ui/core';
var Gateway = function (_a) {
    var type = _a.type, header = _a.header, description = _a.description, onClick = _a.onClick, paymentType = _a.paymentType;
    var css = useTheme('PaymentForm').Gateway;
    return (React.createElement(ButtonBase, { className: css.selector, onClick: onClick, "data-payment-type": paymentType },
        React.createElement("div", { className: css.header }, header !== null && header !== void 0 ? header : emptyCard,
            React.createElement("span", { className: css.header__arrow },
                React.createElement("span", null, type),
                " ",
                React.createElement("span", { className: css.arrow }, arrowIcon))),
        description && React.createElement("p", { className: css.description }, description)));
};
export default Gateway;
