import cn from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { isMultiCity } from '../../../store/segments/selectors';
import Passengers from '../Passengers/Passengers';
import StartSearch from '../StartSearch/StartSearch';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import Currency from '../Currency/Currency';
import { useIsWithSelectableCurrencies } from '../hooks';
import { OverrideComponent } from '../../../../renderProps';
var AdvancedPassengers = React.lazy(function () { return import('../AdvancedPassengers/AdvancedPassengers'); });
var Controls = React.memo(function (props) {
    var _a;
    var css = useTheme('SearchForm').Controls;
    var proMode = useConfig().SearchForm.proMode;
    var withSelectableCurrencies = useIsWithSelectableCurrencies();
    return (React.createElement("div", { className: cn(css.controls, (_a = {},
            _a[css.controls_multiCity] = props.isMultiCity,
            _a[css.controls_advanced] = proMode,
            _a[css.controls_withCurrency] = withSelectableCurrencies,
            _a)) },
        proMode ? (React.createElement(React.Suspense, { fallback: "Loading..." },
            React.createElement(AdvancedPassengers, null))) : (React.createElement(OverrideComponent, { componentProps: {}, component: { SearchFormPassengers: Passengers } })),
        withSelectableCurrencies && (React.createElement(OverrideComponent, { componentProps: {}, component: { SearchFormCurrency: Currency } })),
        React.createElement(StartSearch, { mode: props.isMultiCity ? 'text' : 'icon' })));
});
var mapStateToProps = function (state) {
    return {
        isMultiCity: isMultiCity(state)
    };
};
export default connect(mapStateToProps)(Controls);
