import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import FullscreenDialog from '../../../FullscreenDialog/components/FullscreenDialog';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import { Button } from '../../../index';
import { initI18n } from '../../../utils';
initI18n('Service');
var Dialog = React.forwardRef(function (props, ref) {
    var theme = useTheme('Service').DialogStyles;
    var t = useTranslation('Service').t;
    return props.dialogType === 'fullscreen' ? (React.createElement(FullscreenDialog, { isOpen: props.isOpen, onClose: props.onClose, title: props.dialogHeader, contentClassName: cn(theme.dialog__content, props.dialogContentClassName), headerClassName: props.dialogHeaderClassName, closeClassName: props.dialogCloseClassName, fadeTransition: true, keepMounted: true, rootDialogClassName: theme.dialog, mountOnEnter: props.mountOnEnter, hideHeader: props.hideHeader, ref: ref }, props.content)) : (React.createElement(Modal, { scroll: props.bodyScroll ? 'body' : 'paper', open: props.isOpen, onClose: props.onClose, maxWidth: false, classes: {
            root: theme.dialog,
            paper: cn(theme.paper, props.dialogClassName)
        }, keepMounted: true, mountOnEnter: props.mountOnEnter },
        props.dialogHeader && React.createElement("div", { className: theme.popupHeader }, props.dialogHeader),
        props.content,
        !props.withoutControls && (React.createElement("div", { className: props.dialogControlsClassName },
            React.createElement(Button, { onClick: props.onClear, className: theme.dialog__button_clear }, t('Clear')),
            React.createElement(Button, { onClick: props.onClose, className: theme.dialog__button_done }, t('Done'))))));
});
export default Dialog;
