import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { FlightClass, InfoMessagePositionsEnum } from '@websky/graphql';
import { InfoDetails, BaseButton } from '../../../index';
import { useDesktopFlightModalContent, useLastSelectedFlight } from './hooks';
import { Tabs } from '../../types';
import { useTheme } from '../../../theme';
import { FlightInfoV2 } from '../../../FlightInfo';
import { usePagination } from '../../../Pagination/hooks';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import Pagination from '../../../Pagination';
import Modal from '../../../Modal';
import CompareFares from '../../../CompareFares/components/CompareFares';
import Link from '../../../Link';
import FareGroupGrid from '../../../FareGroup/components/FareGroupGrid/FareGroupGrid';
import { OverrideComponent } from '../../../renderProps';
import { findIndexFirstAvailableFareGroup, isFareGroupAvailable, isFaresHasOneItem, isShowFaresPagination, roundAvailableSliderIndex } from './utils';
import FlightInfoSegment from '../FlightInfoSegment/FlightInfoSegment';
import { getDurationString, getFlightDuration } from '../../../Results/utils';
import DesktopFlightModalHeader from '../DesktopFlightModalHeader/DesktopFlightModalHeader';
import { getNextSegment } from '../../utils';
import { analytics_fareAddToCart, analytics_setFareGroupsListItems } from '../../../Results/components/Results/dataLayer/results';
import InfoMessages from '../../../InfoMessages';
import { filterMessageByPosition } from '../../../InfoMessages/utils';
import Dialog from '../../../Service/components/Dialog/Dialog';
import { SwipeableDrawer } from '@material-ui/core';
import { useToggleable } from '../../../hooks';
initI18n('FlightInfoModalContent');
var DesktopFlightModalContent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h, _j;
    var _k = props.isSelectorInModal, isSelectorInModal = _k === void 0 ? true : _k;
    var _l = useDesktopFlightModalContent(props), active = _l.active, handleBusinessButtonClick = _l.handleBusinessButtonClick, handleEconomyButtonCLick = _l.handleEconomyButtonCLick, handleMilesButtonClick = _l.handleMilesButtonClick, isFareCompareOpen = _l.isFareCompareOpen, openFareCompare = _l.openFareCompare, closeFareCompare = _l.closeFareCompare;
    var t = useTranslation('FlightInfoModalContent').t;
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isSelectorInModalOrMobile = isSelectorInModal || isMobile;
    var allFares = !isSelectorInModalOrMobile
        ? __spreadArray(__spreadArray(__spreadArray([], __read(props.economyFares), false), __read(props.businessFares), false), __read(props.milesFares), false) : [];
    var lastSelectedFare = useLastSelectedFlight();
    var isActiveEconomyFares = active === Tabs.EconomyFares;
    var isActiveBusinessFares = active === Tabs.BusinessFares;
    var isActiveMilesFares = active === Tabs.MilesFares;
    var isActiveFlightInfo = active === Tabs.FlightInfo;
    var isOneEconomyFare = isFaresHasOneItem(props.economyFares);
    var isOneBusinessFare = isFaresHasOneItem(props.businessFares);
    var isOneMilesFare = isFaresHasOneItem(props.milesFares);
    var isOneAllFare = isFaresHasOneItem(allFares);
    var slidesToShow = isTablet ? (isMobile ? 1.05 : 2) : (_j = (_h = props.sliderSettings) === null || _h === void 0 ? void 0 : _h.slidesToShow) !== null && _j !== void 0 ? _j : 3;
    var slidesToScroll = slidesToShow >= 2 ? slidesToShow : 1;
    var slidesToShowOnce = props.slidesToShowOnce || 1.1;
    var _m = useToggleable(false), isOpen = _m.isOpen, open = _m.open, close = _m.close, toggle = _m.toggle;
    var _o = useMemo(function () {
        var firstEconomyAvailableFareGroup = findIndexFirstAvailableFareGroup(props.economyFares, lastSelectedFare);
        var firstBusinessAvailableFareGroup = findIndexFirstAvailableFareGroup(props.businessFares, lastSelectedFare);
        var firstEconomyAvailableSliderIndex = roundAvailableSliderIndex(firstEconomyAvailableFareGroup, slidesToScroll);
        var firstBusinessAvailableSliderIndex = roundAvailableSliderIndex(firstBusinessAvailableFareGroup, slidesToScroll);
        return {
            firstEconomyAvailableSliderIndex: firstEconomyAvailableSliderIndex,
            firstBusinessAvailableSliderIndex: firstBusinessAvailableSliderIndex
        };
    }, [props.economyFares, props.businessFares, lastSelectedFare, slidesToScroll]), firstEconomyAvailableSliderIndex = _o.firstEconomyAvailableSliderIndex, firstBusinessAvailableSliderIndex = _o.firstBusinessAvailableSliderIndex;
    var _p = usePagination(firstEconomyAvailableSliderIndex), economySliderIndex = _p.currentIndex, handleEconomySliderIndexChange = _p.handleChange;
    var _q = usePagination(firstBusinessAvailableSliderIndex), businessSliderIndex = _q.currentIndex, handleBusinessSliderIndexChange = _q.handleChange;
    var _r = usePagination(0), milesSliderIndex = _r.currentIndex, handleMilesSliderIndexChange = _r.handleChange;
    var _s = usePagination(0), allFaresSliderIndex = _s.currentIndex, handleAllFaresSliderIndexChange = _s.handleChange;
    var economySliderRef = React.useRef();
    var businessSliderRef = React.useRef();
    var milesSliderRef = React.useRef();
    useEffect(function () {
        // return to previous item when user change active tab
        handleEconomySliderIndexChange(firstEconomyAvailableSliderIndex);
        handleBusinessSliderIndexChange(firstBusinessAvailableSliderIndex);
    }, [active]);
    useEffect(function () {
        var _a;
        (_a = props.setIsComparisonOpen) === null || _a === void 0 ? void 0 : _a.call(props, isFareCompareOpen);
    }, [isFareCompareOpen]);
    var theme = useTheme('DesktopFlight').ModalContent;
    var isOneActiveFare = (isActiveEconomyFares && isOneEconomyFare) ||
        (isActiveBusinessFares && isOneBusinessFare) ||
        (isActiveMilesFares && isOneMilesFare);
    var sliderWrapperClassName = cn(theme.slider__wrp, (_a = {},
        _a[theme.slider__wrp_singleItem] = isOneActiveFare,
        _a));
    var _t = __read(React.useState(false), 2), isTooltipsOpened = _t[0], setTooltipsOpened = _t[1];
    var sliderSettings = __assign(__assign({ slidesToShow: isOneActiveFare ? slidesToShowOnce : slidesToShow, infinite: false, speed: 250, slidesToScroll: slidesToScroll, swipe: isTablet }, props.sliderSettings), { className: theme.slider, onSwipe: function () {
            setTooltipsOpened(false);
        } });
    var allSliderSettings = __assign(__assign({}, sliderSettings), { slidesToShow: isOneAllFare ? slidesToShowOnce : slidesToShow, className: cn(sliderSettings.className, (_b = {}, _b[theme.slider_allOnce] = isOneAllFare, _b)), beforeChange: function (_, nextSlide) {
            handleAllFaresSliderIndexChange(nextSlide);
        } });
    var economySliderSettings = __assign(__assign({}, sliderSettings), { initialSlide: firstEconomyAvailableSliderIndex, beforeChange: function (currentSlide, nextSlide) {
            handleEconomySliderIndexChange(nextSlide);
        } });
    var businessSliderSettings = __assign(__assign({}, sliderSettings), { className: 'slider-business', initialSlide: firstBusinessAvailableSliderIndex, beforeChange: function (currentSlide, nextSlide) {
            handleBusinessSliderIndexChange(nextSlide);
        } });
    var milesSliderSettings = __assign(__assign({}, sliderSettings), { className: 'slider-miles', beforeChange: function (currentSlide, nextSlide) {
            handleMilesSliderIndexChange(nextSlide);
        } });
    var renderFareGroup = function (group, index) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var isEconomyFareGroup = group.fareFamily.category === FlightClass.Economy;
        var isAvailable = isFareGroupAvailable(group, lastSelectedFare);
        var disabledForPriceInMiles = ((_c = (_b = group.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.priceInMiles) > 0 && !((_f = (_e = (_d = group.prices) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.flight) === null || _f === void 0 ? void 0 : _f.canBePaidByMiles);
        var onFareSelect = function () {
            analytics_fareAddToCart(group, index, props.flight, props.searchParameters);
            props.getOnFareSelect(group)();
        };
        var fareGroupGridProps = {
            className: cn(theme.fareGroup__slide, (_a = {}, _a[theme.fareGroup_withoutUpgradeOption] = !!props.lastSelectedFare, _a)),
            showUnavailableOptions: true,
            onUpgrade: isEconomyFareGroup &&
                props.upgradeOptions.has(group.fareFamily.id) &&
                props.getOnFareSelect(props.upgradeOptions.get(group.fareFamily.id).canUpgradeTo),
            upgradeOption: isEconomyFareGroup && props.upgradeOptions.get(group.fareFamily.id),
            variant: 'white',
            isDisabled: !isAvailable || disabledForPriceInMiles,
            fare: group.fareFamily,
            onSelect: onFareSelect,
            price: !props.hidePrice && group.prices && group.prices[0] ? group.prices[0].price : null,
            originalPrice: !props.hidePrice && group.prices && group.prices[0] && group.prices[0].originalPrice
                ? group.prices[0].originalPrice
                : null,
            flightId: (_h = (_g = group.prices) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.flight.id,
            priceInMiles: (_k = (_j = group.prices) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.priceInMiles,
            disabledForMiles: disabledForPriceInMiles,
            orderId: props.orderId,
            isTooltipsOpened: isTooltipsOpened,
            setTooltipsOpened: setTooltipsOpened,
            flightMessages: props.flight.segments[0].segment.messages
        };
        return React.createElement(OverrideComponent, { componentProps: fareGroupGridProps, component: { FareGroupGrid: FareGroupGrid }, key: index });
    };
    var renderSliderIndex = function (slidesCount, index, slidesToScroll) {
        var pagesCount = Math.ceil(slidesCount / slidesToScroll);
        if (isMobile) {
            pagesCount = slidesCount;
        }
        if ((isTablet && slidesCount > 2) || slidesCount > sliderSettings.slidesToScroll) {
            return (React.createElement("span", { className: theme.pagination__index },
                Math.ceil(index / slidesToScroll) + 1,
                " / ",
                pagesCount));
        }
        else {
            return null;
        }
    };
    var isEconomyExists = React.useMemo(function () {
        return props.economyFares.some(function (economyFare) { return economyFare.prices; });
    }, [props.economyFares]);
    var isBusinessFaresExists = React.useMemo(function () {
        return props.businessFares.some(function (businessFare) { return businessFare.prices; });
    }, [props.businessFares]);
    var isMilesExists = React.useMemo(function () {
        return props.milesFares.some(function (milesFare) { return milesFare.prices; });
    }, [props.milesFares]);
    var container = useRef();
    var sliderAnchorRef = useRef();
    var onScrollTop = useCallback(function () {
        container.current.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        });
    }, [container]);
    React.useEffect(function () {
        var _a;
        analytics_setFareGroupsListItems(__spreadArray(__spreadArray(__spreadArray([], __read(props.economyFares), false), __read(props.businessFares), false), __read(props.milesFares), false), props.flight, props.searchParameters);
        (_a = sliderAnchorRef === null || sliderAnchorRef === void 0 ? void 0 : sliderAnchorRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, []);
    var renderInfoMessages = function (position, renderCallback) {
        var _a, _b, _c;
        var messages = (_c = (_b = (_a = props.flight.segments[0]) === null || _a === void 0 ? void 0 : _a.segment) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.filter(function (message) {
            return filterMessageByPosition(position, message);
        });
        // to prevent the slide from being displayed if there are no messages
        if (messages === null || messages === void 0 ? void 0 : messages.length) {
            return renderCallback();
        }
        return null;
    };
    var infoMessageByClass = React.useMemo(function () {
        var position = active === Tabs.EconomyFares
            ? InfoMessagePositionsEnum.upsaleEconomy
            : InfoMessagePositionsEnum.upsaleBusiness;
        return renderInfoMessages(position, function () { return (React.createElement(InfoMessages, { position: position, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage, htmlClassName: theme.infoMessage__htmlContainer, messages: props.flight.segments[0].segment.messages })); });
    }, [props.flight.segments, active]);
    var infoMessageRight = React.useMemo(function () {
        var position = InfoMessagePositionsEnum.upsaleRight;
        return renderInfoMessages(position, function () { return (React.createElement(InfoMessages, { position: position, className: cn(theme.infoMessage, theme.upsaleRightInfoMessage), itemHtmlClassName: theme.infoMessage__htmlMessage, contentClassName: theme.infoMessage__contentClassName, messages: props.flight.segments[0].segment.messages })); });
    }, [props.flight.segments]);
    return (React.createElement(Paper, { elevation: 0, className: cn(theme.paper, (_c = {},
            _c[theme.paper_dropdown] = !isSelectorInModalOrMobile,
            _c[theme.paper_pagination] = !isSelectorInModalOrMobile && allFares.length > allSliderSettings.slidesToScroll,
            _c)), ref: container },
        isSelectorInModalOrMobile && (React.createElement(DesktopFlightModalHeader, { title: t('Select your bundle'), onClose: props.onClose })),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.upsaleTop, className: theme.topInfoMessage, itemHtmlClassName: theme.topInfoMessage__htmlMessage, messages: props.flight.segments[0].segment.messages }),
        React.createElement("div", { className: theme.buttons },
            isSelectorInModalOrMobile && (React.createElement(React.Fragment, null,
                (isEconomyExists || (!isMilesExists && !isBusinessFaresExists)) && (React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.fareGroup__button, (_d = {},
                        _d[theme.fareGroup__button_active] = isActiveEconomyFares,
                        _d)), onClick: handleEconomyButtonCLick }, t('Economy'))),
                isBusinessFaresExists && (React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.fareGroup__button, theme.fareGroup__button_business, (_e = {},
                        _e[theme.fareGroup__button_active] = isActiveBusinessFares,
                        _e)), onClick: handleBusinessButtonClick }, t('Business'))),
                isMilesExists && (React.createElement(BaseButton, { variant: "optionAction", className: cn(theme.fareGroup__button, theme.fareGroup__button, (_f = {},
                        _f[theme.fareGroup__button_active] = isActiveMilesFares,
                        _f)), onClick: handleMilesButtonClick }, t('Premium'))))),
            active !== Tabs.FlightInfo && (React.createElement(Link, { className: theme.compareFaresLink, action: openFareCompare },
                t('View full conditions'),
                React.createElement("span", { className: theme.compareFaresLink_icon }, InfoDetails)))),
        React.createElement("div", { className: theme.content },
            React.createElement("div", { className: cn(theme.content_wrapper, (_g = {},
                    _g[theme.content_offset] = !!infoMessageByClass || !!infoMessageRight,
                    _g)) },
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("div", null, allFares.length > 0 ? (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, __assign({}, allSliderSettings), allFares.map(renderFareGroup)),
                        renderSliderIndex(allFares.length, allFaresSliderIndex, allSliderSettings.slidesToScroll))) : (React.createElement(React.Fragment, null,
                        isActiveEconomyFares && (React.createElement("div", { className: sliderWrapperClassName },
                            React.createElement(Slider, __assign({}, economySliderSettings),
                                infoMessageByClass,
                                props.economyFares.map(renderFareGroup),
                                infoMessageRight),
                            renderSliderIndex(props.economyFares.length, economySliderIndex, slidesToScroll))),
                        isActiveBusinessFares && (React.createElement("div", { className: sliderWrapperClassName },
                            React.createElement(Slider, __assign({}, businessSliderSettings),
                                infoMessageByClass,
                                props.businessFares.map(renderFareGroup),
                                infoMessageRight),
                            renderSliderIndex(props.businessFares.length, businessSliderIndex, slidesToScroll))),
                        isActiveMilesFares && (React.createElement("div", { className: sliderWrapperClassName },
                            React.createElement(Slider, __assign({}, milesSliderSettings),
                                props.milesFares.map(renderFareGroup),
                                infoMessageRight),
                            renderSliderIndex(props.milesFares.length, milesSliderIndex, milesSliderSettings.slidesToShow)))))),
                    React.createElement("div", { ref: sliderAnchorRef }),
                    isActiveFlightInfo && (React.createElement(OverrideComponent, { componentProps: {
                            flight: props.flight,
                            selectedFareFamily: props.lastSelectedFare
                        }, component: { DesktopFlightContentFlightInfo: FlightInfoV2 } }))),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    isActiveEconomyFares && (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, __assign({}, economySliderSettings, { ref: economySliderRef }), props.economyFares.map(renderFareGroup)),
                        isShowFaresPagination(props.economyFares) && (React.createElement(Pagination, { className: theme.pagination, itemsCount: props.economyFares.length, currentIndex: economySliderIndex, onChange: function (index) { return economySliderRef.current.slickGoTo(index); } })))),
                    isActiveBusinessFares && (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, __assign({}, businessSliderSettings, { ref: businessSliderRef }), props.businessFares.map(renderFareGroup)),
                        isShowFaresPagination(props.businessFares) && (React.createElement(Pagination, { className: theme.pagination, itemsCount: props.businessFares.length, currentIndex: businessSliderIndex, onChange: function (index) { return businessSliderRef.current.slickGoTo(index); } })))),
                    isActiveMilesFares && (React.createElement("div", { className: sliderWrapperClassName },
                        React.createElement(Slider, __assign({}, milesSliderSettings, { ref: milesSliderRef }), props.milesFares.map(renderFareGroup)),
                        isShowFaresPagination(props.milesFares) && (React.createElement(Pagination, { className: theme.pagination, itemsCount: props.milesFares.length, currentIndex: milesSliderIndex, onChange: function (index) { return milesSliderRef.current.slickGoTo(index); } })))),
                    React.createElement("div", { ref: sliderAnchorRef }),
                    (infoMessageRight || infoMessageByClass) && (React.createElement(SwipeableDrawer, { anchor: "bottom", classes: { paper: theme.swipeable__paper }, open: isOpen, onOpen: open, onClose: close, onClick: toggle, swipeAreaWidth: 76, disableSwipeToOpen: false, ModalProps: { keepMounted: true } }, infoMessageRight !== null && infoMessageRight !== void 0 ? infoMessageRight : infoMessageByClass))),
                isSelectorInModalOrMobile && (React.createElement("div", { className: theme.segments },
                    props.flight.segments.map(function (segment, index) { return (React.createElement(FlightInfoSegment, { key: index, className: theme.segments__flight, segment: segment, nextSegment: segment.transferDuration ? getNextSegment(props.flight.segments, index) : null, isReturnFlight: props.isReturnFlight })); }),
                    props.flight.segments.length > 1 && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: theme.total_time },
                            t('Total duration'),
                            ":",
                            ' ',
                            getDurationString(getFlightDuration(props.flight.segments))),
                        React.createElement("div", { className: theme.content_footer },
                            React.createElement(BaseButton, { variant: "smallPrimary", onClick: onScrollTop, className: theme.scroll_up }, t('Scroll up'))))))))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isFareCompareOpen, onClose: closeFareCompare, isRounded: true },
                React.createElement(CompareFares, { flightId: props.flightId, orderId: props.orderId }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { isOpen: isFareCompareOpen, dialogType: "fullscreen", dialogHeader: t('MobileSelectedFare:Compare fare families'), onClose: closeFareCompare, content: React.createElement(CompareFares, { flightId: props.flightId, orderId: props.orderId }), mountOnEnter: true }))));
};
export default DesktopFlightModalContent;
