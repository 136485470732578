import { __awaiter, __generator, __read } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { LoginType, useAuthenticateMutation } from '@websky/graphql';
import LoginTypeSelector from './LoginTypeSelector/LoginTypeSelector';
import { useTheme } from '../../../../theme';
import Button, { ActionButton } from '../../../../Button';
import { Steps } from '../../../types';
import { getLoginTypeValue } from '../../../utils';
import { AUTH_DUPLICATES, removeFromSessionStorage } from '../../../../cache';
var LoginTypeSelectorForm = function (_a) {
    var _b;
    var loginTypes = _a.loginTypes, login = _a.login, code = _a.code, stayLoggedIn = _a.stayLoggedIn, onSuccess = _a.onSuccess, setLoginData = _a.setLoginData, setStep = _a.setStep;
    var _c = useTheme('LoginPage'), css = _c.LoginTypeSelectorForm, cssSubmit = _c.SubmitButton;
    var t = useTranslation('LoginPage').t;
    var _d = __read(useState(LoginType.Email), 2), loginType = _d[0], setLoginType = _d[1];
    var _e = __read(useAuthenticateMutation(), 2), authenticate = _e[0], authenticateLoading = _e[1].loading;
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(loginType === LoginType.FFP)) return [3 /*break*/, 5];
                    removeFromSessionStorage(AUTH_DUPLICATES);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    login: login,
                                    loginType: loginType,
                                    secureCode: code,
                                    oneSession: !stayLoggedIn
                                }
                            }
                        })];
                case 2:
                    _a.sent();
                    onSuccess();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    if (loginType === LoginType.Email) {
                        setLoginData({
                            login: getLoginTypeValue(loginTypes, LoginType.Email),
                            loginType: LoginType.Email
                        });
                        setStep(Steps.LogIn);
                    }
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var onBack = function () {
        removeFromSessionStorage(AUTH_DUPLICATES);
        setLoginData({ login: '', loginType: undefined });
        setStep(Steps.LogIn);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.title }, t('Choose an account to enter'))),
        React.createElement("div", { className: css.content },
            React.createElement("span", { className: css.text, dangerouslySetInnerHTML: {
                    __html: t('We found an account created with the email', {
                        email: getLoginTypeValue(loginTypes, LoginType.Email)
                    })
                } }),
            React.createElement("div", { className: css.types }, loginTypes.map(function (item) { return (React.createElement(LoginTypeSelector, { key: item.type, type: item.type, value: item.value, selected: item.type === loginType, onClick: function () { return setLoginType(item.type); } })); })),
            React.createElement("div", { className: css.footer },
                React.createElement(Button, { variant: "text", onClick: onBack }, t('Back')),
                React.createElement(ActionButton, { onClick: onSubmit, className: cn(cssSubmit.root, css.buttonSubmit, (_b = {},
                        _b[cssSubmit.loading] = authenticateLoading,
                        _b)) },
                    React.createElement("span", { className: cssSubmit.content }, t('Continue')))))));
};
export default LoginTypeSelectorForm;
