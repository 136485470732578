import { __assign, __rest } from "tslib";
import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { useSeatAdditionalInfo } from '../../../SeatsInfo/hooks';
import SeatAdditionalInfo from '../../../SeatsInfo/SeatAdditionalInfo/SeatAdditionalInfo';
import SeatPrice from '../../../SeatPrice/SeatPrice';
var SeatTooltip = React.memo(function (_a) {
    var seat = _a.seat, props = __rest(_a, ["seat"]);
    var theme = useTheme('SeatMap').SeatTooltipStyles;
    var t = useTranslation('SeatMap').t;
    var _b = useSeatAdditionalInfo(seat), restrictions = _b.restrictions, permissions = _b.permissions, additional = _b.additional, withInfo = _b.withInfo;
    var renderTooltip = function (props) {
        var _a;
        var _b, _c;
        return (React.createElement(Tooltip, { PopperProps: { 'data-rfics': seat.rfisc }, classes: {
                tooltip: cn(theme.tooltip, (_a = {},
                    _a[theme.tooltip_withInfo] = withInfo,
                    _a[theme.tooltip_freeSeat] = props.isFreeSeat,
                    _a[theme.tooltip_recommend] = props.isRecommend,
                    _a)),
                popper: theme.popper,
                tooltipPlacementBottom: theme.tooltip_bottom
            }, open: props.isTooltipOpened, onOpen: function () { return props.onToggle(true); }, onClose: function () { return props.onToggle(false); }, placement: "top", title: React.createElement("div", null,
                React.createElement("div", { className: theme.container },
                    React.createElement("div", { className: theme.number }, props.seat.number),
                    React.createElement("div", { className: theme.details },
                        props.isFreeSeat ? (React.createElement("span", null, t('Free seat'))) : (React.createElement(SeatPrice, { moneyClassName: theme.details__price, money: props.seatPrice })),
                        !props.hideSeatName && ((_b = props.service) === null || _b === void 0 ? void 0 : _b.name) && (React.createElement("span", { className: theme.details__seatName }, (_c = props.service) === null || _c === void 0 ? void 0 : _c.name)))),
                withInfo && (React.createElement(SeatAdditionalInfo, { className: theme.info, restrictions: restrictions, permissions: permissions, additional: additional }))) }, props.children));
    };
    return props.isAvailable && seat.isAvailable && seat.isExistent
        ? renderTooltip(__assign({}, __assign({ seat: seat }, props)))
        : props.children;
});
export default SeatTooltip;
