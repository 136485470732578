import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useIataCode } from '../../../../hooks';
var IATA_WING_HEIGHT = {
    F7: 7
};
var DIFF_IN_PX_BETWEEN_PICTURE_TOP_AND_WING_TOP = 55;
var Wings = React.memo(function (props) {
    var _a;
    var _b;
    var ref = React.useRef(null), wings = React.useRef(null);
    var _c = __read(React.useState(false), 2), hasWings = _c[0], setHasWings = _c[1];
    var iataCode = useIataCode();
    var theme = useTheme('SeatMap').SeatMapStyles;
    var wingHeight = (_b = IATA_WING_HEIGHT[iataCode]) !== null && _b !== void 0 ? _b : 6;
    React.useEffect(function () {
        if (ref) {
            var wingSeats = ref.current.querySelectorAll('div[with-wings="true"]');
            if (!wingSeats.length) {
                setHasWings(false);
                return;
            }
            setHasWings(true);
            var firstSeat = wingSeats.item(0), count = wingSeats.length;
            var topDiff = DIFF_IN_PX_BETWEEN_PICTURE_TOP_AND_WING_TOP;
            if (count > wingHeight) {
                var rate = count / wingHeight;
                topDiff *= rate;
                wings.current.style.transform = "scaleY(".concat(rate, ")");
            }
            wings.current.style.top = "".concat(firstSeat['offsetTop'] - topDiff, "px");
        }
    }, [ref]);
    return (React.createElement("div", { ref: ref },
        props.children,
        React.createElement("div", { ref: wings, className: cn(theme.wings, (_a = {}, _a[theme.wings_center] = !hasWings, _a)) })));
});
export default Wings;
