import { __read, __spreadArray } from "tslib";
export var getCategories = function (meals, segmentId) {
    var categories = new Set();
    meals.forEach(function (meal) {
        if (meal.segmentsId.indexOf(segmentId) !== -1 && !categories.has(meal.category)) {
            categories.add(meal.category);
        }
    });
    return __spreadArray([], __read(categories), false);
};
export var getSelectedProductsBySegments = function (selectedProducts, segments) {
    return segments.map(function (segment, index) {
        if (selectedProducts.length <= index) {
            return [];
        }
        var products = [];
        for (var meal in selectedProducts[segment.id].meals) {
            if (selectedProducts[segment.id].meals.hasOwnProperty(meal) &&
                selectedProducts[segment.id].meals[meal].count > 0) {
                products.push(selectedProducts[segment.id].meals[meal]);
            }
        }
        return products;
    });
};
export var getSegmentsWithMeal = function (meals) {
    var segmentsWithMeal = new Set();
    meals.forEach(function (meal) { return meal.segmentsId.forEach(function (segmentId) { return segmentsWithMeal.add(segmentId); }); });
    return segmentsWithMeal;
};
export var removeDuplicatesByName = function (meals) {
    return Array.from(meals
        .reduce(function (meals, meal) {
        var uncapitalizedName = meal.name.toLowerCase();
        if (!meals.has(uncapitalizedName)) {
            meals.set(uncapitalizedName, meal);
        }
        return meals;
    }, new Map())
        .values());
};
