import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { ApolloError } from 'apollo-boost';
import { Mode } from './RetrieveBooking';
import { useConfig } from '../../context';
import { useClarifyDocumentModal } from './ClarifyDocumentModal/hooks';
var onlyAirportCheckInError = 'Invalid sub status: OnlyAirportCheckIn';
export function useRetrieveBooking(_a) {
    var _this = this;
    var mode = _a.mode, initialId = _a.initialId, initialSecret = _a.initialSecret, submitWithInitialValues = _a.submitWithInitialValues, onSubmit = _a.onSubmit;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var clarifyDocumentModal = useClarifyDocumentModal();
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(false), 2), codeIsWrong = _c[0], setCodeIsWrong = _c[1];
    var _d = __read(useState(false), 2), isBlockedForPayment = _d[0], setIsBlockedForPayment = _d[1];
    var _e = __read(useState(false), 2), isOnlyAirportCheckin = _e[0], setIsOnlyAirportCheckin = _e[1];
    var isCheckoutMode = mode === Mode.Checkout;
    var isCheckinMode = mode === Mode.Checkin;
    var isErrorNotification = iataCode === 'A9' || iataCode === 'KV' || iataCode === 'F7';
    var isOrderNotFoundError = codeIsWrong && !isErrorNotification && !isOnlyAirportCheckin;
    useEffect(function () {
        if (submitWithInitialValues) {
            handleSubmit({ id: initialId, secret: initialSecret }, {});
        }
    }, [submitWithInitialValues]);
    var onSubmitForm = useCallback(function (values) {
        var _a, _b;
        var id = values['id'];
        var secret = (_a = values['secret']) === null || _a === void 0 ? void 0 : _a.trim();
        var extraSecret = (_b = values['extraSecret']) === null || _b === void 0 ? void 0 : _b.trim();
        var payload = isCheckinMode ? { id: id, secret: secret, extraSecret: extraSecret } : { id: id, secret: secret, secret2fa: extraSecret };
        return onSubmit(payload);
    }, [onSubmit, isCheckinMode]);
    var handleError = useCallback(function (values, e) {
        var _a;
        console.warn(e);
        if (e instanceof ApolloError &&
            ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.some(function (error) {
                var _a, _b;
                return ((_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.includes(onlyAirportCheckInError)) ||
                    ((_b = error === null || error === void 0 ? void 0 : error.debugMessage) === null || _b === void 0 ? void 0 : _b.includes(onlyAirportCheckInError));
            }))) {
            setIsOnlyAirportCheckin(true);
        }
        else if (e instanceof Error && clarifyDocumentModal.needToShowClarifyModal(e)) {
            clarifyDocumentModal.setSearchParams(values);
            clarifyDocumentModal.open();
        }
        else {
            setCodeIsWrong(true);
        }
    }, [setIsOnlyAirportCheckin, setCodeIsWrong, clarifyDocumentModal]);
    var handleSubmit = useCallback(function (values, form) { return __awaiter(_this, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCodeIsWrong(false);
                    setIsLoading(true);
                    setIsOnlyAirportCheckin(false);
                    if (isCheckinMode && form.hasOwnProperty('getFieldState')) {
                        // clear warning on submit
                        form.getFieldState('secret').data.warning = null;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onSubmitForm(values)];
                case 2:
                    result = _a.sent();
                    if (result) {
                        if (typeof result !== 'boolean') {
                            setIsBlockedForPayment(result.isBlockedForPayment);
                        }
                    }
                    else {
                        setCodeIsWrong(true);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    handleError(values, e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [onSubmitForm, handleError, isCheckinMode]);
    var handleClarifySubmit = useCallback(function (values) { return __awaiter(_this, void 0, void 0, function () {
        var result, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, onSubmitForm(values)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, !!result];
                case 2:
                    e_2 = _a.sent();
                    console.warn(e_2);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [onSubmitForm]);
    return {
        // data
        isLoading: isLoading,
        codeIsWrong: codeIsWrong,
        isCheckoutMode: isCheckoutMode,
        isCheckinMode: isCheckinMode,
        isBlockedForPayment: isBlockedForPayment,
        isOnlyAirportCheckin: isOnlyAirportCheckin,
        isErrorNotification: isErrorNotification,
        isOrderNotFoundError: isOrderNotFoundError,
        isClarityDocumentOpen: clarifyDocumentModal.isOpen,
        searchParams: clarifyDocumentModal.searchParams,
        // methods
        handleSubmit: handleSubmit,
        handleClarifySubmit: handleClarifySubmit,
        closeClarityDocument: clarifyDocumentModal.close
    };
}
