import * as React from 'react';
import cn from 'classnames';
import { CheckoutAdditionalService } from '../../../index';
import TransferSelectorModal from '../TransferSelectorModal/TransferSelectorModal';
import { useTransferSelector } from './model';
import TransferServiceSummary from '../TransferServiceSummary/TransferServiceSummary';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var TransferSelector = function (_a) {
    var _b;
    var service = _a.service, servicesState = _a.servicesState, isOpen = _a.isOpen, onClose = _a.onClose, onOpen = _a.onOpen, segments = _a.segments, passengers = _a.passengers, onChange = _a.onChange, isSelected = _a.isSelected;
    var t = useTranslation('Transfer').t;
    var theme = useTheme('Transfer').TransferSelector;
    var model = useTransferSelector(service, servicesState);
    return (React.createElement("div", null,
        React.createElement(CheckoutAdditionalService, { headerPriceClassName: theme.headerPrice, headerClassName: theme.header, headerTextClassName: theme.headerText, descriptionClassName: theme.description, dataRfisc: service.rfisc, buttonsClassName: theme.buttons, className: cn(theme.serviceCard, (_b = {},
                _b[theme.active] = isSelected,
                _b)), isSelected: isSelected, priceFrom: model.minPrice, hideFrom: true, priceTotal: model.totalPrice, header: service.name, description: service.description, addClick: function () { return onOpen(service.id); }, buttons: [
                {
                    text: t('Edit'),
                    action: function () { return onOpen(service.id); }
                }
            ] },
            React.createElement(TransferServiceSummary, { service: service, segments: segments, servicesState: servicesState, passengers: passengers })),
        React.createElement(TransferSelectorModal, { service: service, servicesState: model.state, onChange: model.toggleService, passengers: passengers, segments: segments, isOpen: isOpen, totalPrice: model.totalPrice, onSubmit: function () {
                onChange(model.state); // сохраняем родительский стейт (дергаем фактическое добавление )
                onClose();
            }, onClose: function () {
                model.restoreState(); // сбрасываем локальный стейт
                onClose();
            }, onClear: function () {
                model.removeTransfer(); // Сбросить текущий трансфер
            } })));
};
export default TransferSelector;
