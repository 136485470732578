import { __assign, __rest } from "tslib";
import * as React from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { Choosed } from '../../../../Icons';
import Loader from '../Loader/Loader';
var IconButton = function (_a) {
    var _b;
    var classes = _a.classes, children = _a.children, isSuccess = _a.isSuccess, isLoading = _a.isLoading, disabled = _a.disabled, _c = _a.disableElevation, disableElevation = _c === void 0 ? true : _c, restProps = __rest(_a, ["classes", "children", "isSuccess", "isLoading", "disabled", "disableElevation"]);
    var theme = useTheme('BaseComponents').IconButton;
    return (React.createElement(Button, __assign({ variant: "contained", classes: __assign(__assign({}, classes), { root: cn(theme.root, classes === null || classes === void 0 ? void 0 : classes.root, (_b = {},
                _b[theme.success] = isSuccess,
                _b[theme.loading] = isLoading,
                _b)) }), disabled: disabled || isLoading, disableElevation: disableElevation }, restProps), isLoading ? React.createElement(Loader, { classes: { colorPrimary: theme.colorPrimary } }) : isSuccess ? Choosed : children));
};
export default IconButton;
