import * as React from 'react';
import { useContext } from 'react';
import { CartContext } from '../../Cart';
import BaggageSummary from '../../../ServiceSummary/services/BaggageSummary';
var Animal = function (_a) {
    var flight = _a.flight, travellers = _a.travellers, services = _a.services, onServiceDelete = _a.onServiceDelete;
    var cartContext = useContext(CartContext);
    return (React.createElement(BaggageSummary, { flight: flight, travellers: travellers, services: services, travellersById: cartContext.travellersById, onServiceDelete: onServiceDelete, isAnimalBaggage: true }, function (content) { return React.createElement(React.Fragment, null, content); }));
};
export default Animal;
