import { __read } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import { Money } from '../../../Money';
import { useTheme } from '../../../theme';
import { getProgressLevel } from '../../utils';
import { DropdownAdapter } from '../DropdownAdapter/DropdownAdapter';
import { TABLET_MIN_WIDTH, format } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { isSameYear, parseISO } from 'date-fns';
export var MonthsList = function (props) {
    var t = useTranslation('PriceGraph').t;
    var firstMonthYear = props.months[0].dateISO;
    var minPrice = {
        amount: props.months[0].minPrice ? props.months[0].minPrice.amount : 0,
        currency: props.months[0].minPrice ? props.months[0].minPrice.currency : null
    }, maxPrice = {
        amount: 0,
        currency: props.months[0].minPrice ? props.months[0].minPrice.currency : null
    };
    var _a = __read(React.useState(false), 2), isOpen = _a[0], setVisibility = _a[1], theme = useTheme('PriceGraph').MonthsListStyles, currentMonth = new Date(props.selectedMonth);
    props.months.map(function (month) {
        if (month.minPrice) {
            if (month.minPrice.amount < minPrice.amount) {
                minPrice.amount = month.minPrice.amount;
            }
            if (month.minPrice.amount > maxPrice.amount) {
                maxPrice.amount = month.minPrice.amount;
            }
        }
    });
    var onChange = function (dateISO) {
        setVisibility(false);
        props.onChange(dateISO);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.currentMonth, onClick: function () { return setVisibility(true); } },
                React.createElement("span", { className: theme.currentMonth__monthName }, format(currentMonth, 'LLLL')),
                React.createElement("span", { className: theme.currentMonth__year }, currentMonth.getFullYear()))),
        React.createElement(DropdownAdapter, { isOpen: isOpen, onClose: function () { return setVisibility(false); } },
            React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement("div", { className: theme.mobileHeader }, t('Select month'))),
            React.createElement("div", { className: theme.monthList }, props.months.map(function (month, index) {
                var _a;
                return (React.createElement("div", { key: index, className: classnames(theme.month, (_a = {},
                        _a[theme.month_min] = month.minPrice && month.minPrice.amount === minPrice.amount,
                        _a[theme.month_max] = month.minPrice && month.minPrice.amount === maxPrice.amount,
                        _a)), onClick: function () { return onChange(month.dateISO); } },
                    React.createElement("div", { className: theme.month__name }, format(new Date(month.dateISO), !isSameYear(parseISO(firstMonthYear), parseISO(month.dateISO))
                        ? 'LLLL yyyy'
                        : 'LLLL')),
                    month.minPrice && (React.createElement(Money, { moneyClassName: theme.month__price, money: month.minPrice, showCurrencyBeforeAmount: true })),
                    React.createElement("div", { className: theme.bar },
                        React.createElement("div", { className: theme.progress, style: {
                                width: "".concat(getProgressLevel(month.minPrice ? month.minPrice.amount : 0, maxPrice.amount), "%")
                            } }))));
            })))));
};
