import { __assign, __read } from "tslib";
import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useTheme } from '../../../theme';
var Preferences = function (props) {
    var css = useTheme('TravellerForm').Preferences, _a = __read(useState(props.Seats && props.Seats[0]), 2), seatSelect = _a[0], setSeatSelect = _a[1], _b = __read(useState(props.Meal && props.Meal[0]), 2), mealSelect = _b[0], setMealSelect = _b[1], t = useTranslation('Traveller').t;
    return (React.createElement("div", { className: css.preferences },
        React.createElement("h1", { className: css.title }, t('FLIGHT PREFERENCES')),
        React.createElement("div", { className: css.selects },
            React.createElement(Field, { name: 'preferences.seats', defaultValue: seatSelect, render: function (_a) {
                    var input = _a.input;
                    return (React.createElement(FormControl, null,
                        React.createElement(InputLabel, { classes: {
                                root: css.label,
                                focused: css.focused
                            } }, t('Seats')),
                        React.createElement(Select, __assign({}, input, { value: seatSelect, onChange: function (event) {
                                if (typeof event.target.value === 'string') {
                                    setSeatSelect(event.target.value);
                                    input.onChange(event);
                                }
                            } }), props.Seats &&
                            props.Seats.map(function (seat) { return (React.createElement(MenuItem, { key: seat, value: seat }, seat)); }))));
                } }),
            React.createElement(Field, { name: 'preferences.meal', defaultValue: mealSelect, render: function (_a) {
                    var input = _a.input;
                    return (React.createElement(FormControl, null,
                        React.createElement(InputLabel, { classes: {
                                root: css.label,
                                focused: css.focused
                            } }, t('Meal')),
                        React.createElement(Select, { value: mealSelect, onChange: function (event) {
                                if (typeof event.target.value === 'string') {
                                    setMealSelect(event.target.value);
                                    input.onChange(event);
                                }
                            } }, props.Meal &&
                            props.Meal.map(function (meal) { return (React.createElement(MenuItem, { key: meal, value: meal }, meal)); }))));
                } }))));
};
export default Preferences;
