import { __awaiter, __generator, __read } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import { ResendTicketsStages } from '../../../ResendTicketsForm/types';
import ResendTicketsForm from '../../../ResendTicketsForm';
import { useIsMobile } from '../../../utils';
import Button from '../../../Button';
var SendToEmail = function (_a) {
    var _b;
    var className = _a.className, onResendTickets = _a.onResendTickets, changeLoadingStatus = _a.changeLoadingStatus, renderTrigger = _a.renderTrigger;
    var css = useTheme('RegisteredPassenger').SendToEmail;
    var t = useTranslation('RegisteredPassenger').t;
    var _c = __read(useState(false), 2), isOpen = _c[0], setIsOpen = _c[1];
    var _d = __read(useState(ResendTicketsStages.Default), 2), resendStage = _d[0], setResendStage = _d[1];
    var toggleModal = function () {
        setIsOpen(function (prevState) { return !prevState; });
    };
    var resendTicketsHandler = function (_a) {
        var email = _a.email;
        return __awaiter(void 0, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        changeLoadingStatus && setResendStage(ResendTicketsStages.Loading);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        changeLoadingStatus && setResendStage(ResendTicketsStages.Loading);
                        return [4 /*yield*/, onResendTickets(email)];
                    case 2:
                        _b.sent();
                        setTimeout(function () {
                            toggleModal();
                            changeLoadingStatus && setResendStage(ResendTicketsStages.Default);
                        }, 1500);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        changeLoadingStatus && setResendStage(ResendTicketsStages.Error);
                        setTimeout(function () {
                            changeLoadingStatus && setResendStage(ResendTicketsStages.Default);
                        }, 2500);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var isMobile = useIsMobile();
    var onCloseModal = resendStage !== ResendTicketsStages.Loading ? toggleModal : null;
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(toggleModal)) !== null && _b !== void 0 ? _b : (React.createElement(Button, { className: cn(css.text, className), onClick: toggleModal }, t('Send to email'))),
        React.createElement(Modal, { open: isOpen, onClose: onCloseModal, maxWidth: "md", classes: { paper: css.paper, scrollBody: css.scrollBody }, slideUp: isMobile },
            React.createElement(ResendTicketsForm, { status: resendStage, onSubmit: resendTicketsHandler, onCancel: toggleModal }))));
};
export default SendToEmail;
