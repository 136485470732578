import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTheme } from '../theme';
import SimpleLoader from '../SimpleLoader';
import SegmentTabs from '../SegmentTabs/SegmentTabs';
import MediaQuery from '../MediaQuery/MediaQuery';
import { initI18n, scrollTo } from '../utils';
import MobileToolbar from '../MobileToolbar';
initI18n('ServiceWithSegments');
var ServiceWithSegments = function (_a) {
    var children = _a.children, isLoading = _a.isLoading, title = _a.title, segments = _a.segments, enabledSegments = _a.enabledSegments, onMobileProceed = _a.onMobileProceed, onMobileBack = _a.onMobileBack, _b = _a.initialSegmentId, initialSegmentId = _b === void 0 ? '0' : _b, onClose = _a.onClose, segmentTabsClassName = _a.segmentTabsClassName, segmentTabsWrapperClassName = _a.segmentTabsWrapperClassName, segmentTabsSegmentsClassName = _a.segmentTabsSegmentsClassName;
    var css = useTheme('ServiceWithSegments').ServiceWithSegments;
    var _c = __read(useState(initialSegmentId), 2), activeSegmentId = _c[0], setActiveSegmentId = _c[1];
    var activeSegment = segments.find(function (_a) {
        var id = _a.id;
        return id === activeSegmentId;
    });
    var nextSegmentId = useMemo(function () {
        return __spreadArray([], __read(enabledSegments), false).filter(function (segmentId) { return segments.find(function (segment) { return parseInt(segment.id, 10) === parseInt(segmentId, 10); }); })
            .sort()
            .find(function (segmentId) { return parseInt(segmentId, 10) > parseInt(activeSegmentId, 10); });
    }, [enabledSegments, activeSegmentId]);
    var onContinue = function () {
        if (typeof nextSegmentId !== 'undefined') {
            setActiveSegmentId(nextSegmentId);
        }
        else {
            onMobileProceed();
        }
        scrollTo(0);
    };
    var onBack = function () {
        var prevSegmentId = __spreadArray([], __read(enabledSegments), false).sort()
            .reverse()
            .find(function (segmentId) { return parseInt(segmentId) < parseInt(activeSegmentId); });
        if (typeof prevSegmentId !== 'undefined') {
            setActiveSegmentId(prevSegmentId);
        }
        else {
            onMobileBack();
        }
        scrollTo(0);
    };
    return (React.createElement("div", { className: css.service },
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(SegmentTabs, { title: title, segments: segments, sticky: true, onSegmentClick: setActiveSegmentId, activeSegment: activeSegmentId, onClose: onClose, className: segmentTabsClassName, wrapperClassName: segmentTabsWrapperClassName, segmentsClassName: segmentTabsSegmentsClassName })),
        React.createElement("div", { className: css.wrapper },
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement("div", { className: css.title }, activeSegment.title)),
            children({
                activeSegmentId: activeSegmentId
            })),
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(MobileToolbar, { onBack: onBack, onContinue: onContinue }))));
};
export default ServiceWithSegments;
