import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../theme';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
import { PromotionCard } from './PromotionCard/PromotionCard';
import Loader from '../../../Loader';
import InfiniteScroll from 'react-infinite-scroller';
import { useGetPaxPromotionTeasersQuery } from '@websky/graphql';
import { useCommunicationList } from '../../hooks';
import { PaximizerCommunicationSectionEnum } from '@websky/graphql';
export var PromotionsPage = function () {
    var css = useTheme('Communications').Communication;
    var path = useRouteMatch().path;
    var _a = useGetPaxPromotionTeasersQuery({
        skip: true
    }), loading = _a.loading, refetch = _a.refetch;
    var loadPromotions = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data, notification;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, refetch({
                        offset: values.offset,
                        count: values.count
                    })];
                case 1:
                    data = (_b.sent()).data;
                    notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationPromotionTeasers;
                    return [2 /*return*/, {
                            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0,
                            items: ((_a = notification === null || notification === void 0 ? void 0 : notification.items) === null || _a === void 0 ? void 0 : _a.length) ? notification.items : []
                        }];
            }
        });
    }); };
    var _b = useCommunicationList(PaximizerCommunicationSectionEnum.promotions, loadPromotions, loading), readIds = _b.readIds, markAsRead = _b.markAsRead, promoCodes = _b.items, pagination = _b.pagination, loadMore = _b.loadMore, errorMessage = _b.errorMessage;
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.promotions, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "promotion-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, promoCodes.map(function (item) { return (React.createElement(Link, { key: item.id, to: "".concat(path, "/").concat(item.id) },
                React.createElement(PromotionCard, { title: item.title, date: item.date, isRead: item.read || readIds[item.id], preview: item.image, desc: item.text, markAsRead: function () { return markAsRead(item.id); } }))); })))));
};
