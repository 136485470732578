import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import SwitchComponent from '../../../../Switch';
import { useIataCode } from '../../../../hooks';
import { useIsMobile } from '../../../../utils';
var Switch = function (props) {
    var theme = useTheme('PassengerForm').Switch;
    var iataCode = useIataCode();
    var isMobile = useIsMobile();
    var switchPosition = iataCode === 'B2' && !isMobile ? 'right' : 'left';
    return (React.createElement(SwitchComponent, __assign({ mainClassName: cn(theme.toggle, props.className), activeClassName: theme.toggle_active, labelClassName: theme.switch__label, switchPosition: switchPosition }, props)));
};
export default Switch;
