import * as React from 'react';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import { Slide } from '@material-ui/core';
var FullscreenPopup = function (props) {
    var _a;
    var theme = useTheme('PassengerForm').FullscreenPopup;
    React.useEffect(function () {
        window.document.body.style.overflow = props.open ? 'hidden' : 'auto';
    }, [props.open]);
    return (React.createElement(Slide, { direction: "up", in: props.open },
        React.createElement("div", { className: cn(theme.popup, (_a = {}, _a[theme.visible] = props.open, _a)) }, props.children)));
};
export default FullscreenPopup;
