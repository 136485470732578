import { __read } from "tslib";
import { useTranslation } from 'react-i18next';
import { useToggleable } from '../hooks';
import { emailRegex, initI18n } from '../utils';
import { useSaveClientInfoMutation } from '@websky/graphql';
import { useConfig } from '../context';
import { clearPhoneNumber } from '../PhoneInput/utils';
initI18n('CustomerContactsForm');
export var useCustomerContactsValidation = function () {
    var FFPRegex = useConfig().global.FFPRegex;
    var t = useTranslation('CustomerContactsForm').t;
    var phoneRegExes = [
        {
            pattern: '^\\+?(?!1+$|2+$|3+$|4+$|5+$|6+$|7+$|8+$|9+$|0+$)'
        },
        {
            pattern: '^\\+?[1-9]\\d+$'
        },
        {
            pattern: '^\\+?\\d{10,}$'
        },
        {
            pattern: '^\\+?\\d{10,18}$'
        },
        {
            pattern: '^\\+?(9\\d{10,}|[^9]\\d{9,})$'
        },
        {
            pattern: '^\\+?(7\\d{10}|[^7]\\d{9,})$'
        }
    ];
    var testEmail = function (value) {
        if (!value) {
            return t('Required');
        }
        else if (!emailRegex.test(value)) {
            return t('Please enter a valid email');
        }
        else {
            return undefined;
        }
    };
    var testPhone = function (value) {
        var cleanValue = clearPhoneNumber(value);
        var validated = phoneRegExes.filter(function (rule) { return !new RegExp(rule.pattern).test(cleanValue); });
        if (!value) {
            return t('Required');
        }
        else if (validated.length) {
            return t('Please enter a valid phone');
        }
        else {
            return undefined;
        }
    };
    var testFFP = function (value) {
        if (!value) {
            return t('Required');
        }
        else if (!FFPRegex.test(value)) {
            return t('Please enter a valid FFP');
        }
        else {
            return undefined;
        }
    };
    return {
        testEmail: testEmail,
        testPhone: testPhone,
        testFFP: testFFP
    };
};
export var useCustomerContactsForm = function () {
    var _a = useToggleable(false), isContactsModalOpen = _a.isOpen, openContactsModal = _a.open, closeContactsModal = _a.close;
    var _b = __read(useSaveClientInfoMutation(), 2), saveClientInfo = _b[0], loading = _b[1].loading;
    return {
        isContactsModalOpen: isContactsModalOpen,
        openContactsModal: openContactsModal,
        closeContactsModal: closeContactsModal,
        saveClientInfo: saveClientInfo,
        loading: loading
    };
};
