var RU = {
    mask: '+{7} (#00) 000-00-00',
    definitions: {
        '#': /[9]/
    }
};
var International = {
    mask: '+0000000000000'
};
export var Mask = {
    RU: RU,
    International: International
};
