import * as React from 'react';
import { useCallback } from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import Switch from '../../../Switch';
import { Money as MoneyComponent } from '../../../Money';
import cn from 'classnames';
var SwitchBlock = function (_a) {
    var price = _a.price, isActive = _a.isActive, className = _a.className, onChange = _a.onChange;
    var css = useTheme('FareLock').SwitchBlock;
    var t = useTranslation('FareLock').t;
    var onClick = useCallback(function () {
        if (onChange) {
            onChange(!isActive);
        }
    }, [isActive, onChange]);
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("div", { className: css.switch_wrapper },
            React.createElement(Switch, { labelClassName: css.switch_label, mainClassName: css.switch_control, activeClassName: css.switch_active, label: t('Lock fare'), isActive: isActive, switchPosition: 'right', onClick: onClick })),
        React.createElement("div", { className: css.price_wrapper },
            React.createElement("div", { className: css.price_label }, t('only')),
            React.createElement("div", { className: css.price },
                React.createElement(MoneyComponent, { money: price })))));
};
export default SwitchBlock;
