import * as React from 'react';
import { ExareStatus } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../Button/Button';
import { useTheme } from '../../../../../../theme';
import Dialog from '../../Dialog/Dialog';
var RequestControls = function (_a) {
    var order = _a.order, deleteRequest = _a.deleteRequest, goToNewExchange = _a.goToNewExchange, goToPayment = _a.goToPayment;
    var t = useTranslation('Exchange').t;
    var theme = useTheme('Exchange').RequestControls;
    return (React.createElement("div", { className: theme.container },
        order.refuseAllowed && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.text }, t('If you change your mind, or want to create a new request with different parameters, delete this request')),
            React.createElement(Dialog, { text: t('The change request was deleted. Want to create a new request?'), acceptText: t('Yes'), onAccept: function () { return deleteRequest(order); }, cancelText: t('No'), iconClassName: theme.dialog__deleteIcon, onCancel: function () { return deleteRequest(order, true); } }, function (_a) {
                var open = _a.open;
                return (React.createElement(Button, { variant: "flat", size: "large", className: theme.refuse, onClick: open }, t('Delete request')));
            }))),
        order.status === ExareStatus.AwaitingPayment && (React.createElement(React.Fragment, null,
            React.createElement("div", null),
            React.createElement(Button, { variant: "flat", size: "large", className: theme.button, onClick: goToPayment }, t('Payment exchange')))),
        (order.status === ExareStatus.Rejected ||
            order.status === ExareStatus.Refused ||
            order.status === ExareStatus.Finished) && (React.createElement(Button, { variant: "flat", size: "large", className: theme.button, onClick: goToNewExchange }, t('Add new request')))));
};
export default RequestControls;
