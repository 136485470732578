import * as React from 'react';
import Money from '../Money';
import { getLabel } from './utils';
import { initI18n } from '../utils';
initI18n('PriceInMiles');
var PriceInMiles = function (_a) {
    var amount = _a.amount, moneyClassName = _a.moneyClassName, amountClassName = _a.amountClassName, currencyClassName = _a.currencyClassName;
    return (React.createElement(Money, { money: {
            amount: amount,
            currency: 'miles'
        }, currencyRenderer: function () {
            return (React.createElement("span", { style: { paddingLeft: '4px' }, className: currencyClassName }, getLabel(amount)));
        }, amountClassName: amountClassName, moneyClassName: moneyClassName, currencyClassName: currencyClassName }));
};
export default PriceInMiles;
