import { __assign } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '../../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { AdditionalTravellerFieldEnum } from '../../../../../../../Passenger/types';
var Checkbox = function (props) {
    var theme = useTheme('PassengerForm').Accompanying;
    var t = useTranslation('Passenger').t;
    var checkboxField = props.accompanyingFields.find(function (field) { return field.type === AdditionalTravellerFieldEnum.AccompanyingCheck; });
    var _a = useField("customer.".concat(checkboxField.name), {
        type: 'checkbox',
        initialValue: checkboxField.defaultValue,
        validate: function (value) {
            if (!value) {
                return t('Required');
            }
            return undefined;
        }
    }), input = _a.input, meta = _a.meta;
    useEffect(function () {
        input.onBlur();
    }, [input.value]);
    return (React.createElement("div", { className: theme.checkbox },
        React.createElement(FormControlLabel, { control: React.createElement(MuiCheckbox, { color: 'primary', classes: {
                    root: theme.formControl__checkbox,
                    checked: theme.checkbox__checked
                }, checked: input.checked, inputProps: __assign({}, input) }), classes: {
                label: theme.formControl__label
            }, label: React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: t('I confirm that the provided ticket number and the name of the accompanying passenger are correct and valid.')
                } }) }),
        React.createElement("div", { className: theme.error }, meta.submitFailed && meta.error)));
};
export default Checkbox;
