import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import MediaQuery from '../../../../../MediaQuery/MediaQuery';
import Modal from '../../../../../Modal';
import LoginForm from '../../../../../LoginPage/components/LoginForm/Component';
import { reachGoal, UserGoal } from '../../../../../analytics';
import { useTheme } from '../../../../../theme';
var Login = function (props) {
    var _a = __read(useState(false), 2), isLoginPageOpened = _a[0], setLoginPageOpened = _a[1];
    var _b = __read(React.useState(''), 2), preselectedLogin = _b[0], setPreselectedLogin = _b[1];
    var theme = useTheme('Contacts').ContactsStyles;
    var onOpenLogin = function (preselectedLogin) {
        setPreselectedLogin(preselectedLogin);
        setLoginPageOpened(true);
    };
    var onSuccessfulLogin = useCallback(function (isRegistration) {
        if (isRegistration === void 0) { isRegistration = false; }
        setLoginPageOpened(false);
        reachGoal(isRegistration ? UserGoal.Registration : UserGoal.Login);
        if (props.refetchUser) {
            props.refetchUser();
        }
    }, [props.refetchUser]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { maxWidth: "mobile" },
            React.createElement(Modal, { open: isLoginPageOpened, onClose: function () { return setLoginPageOpened(false); }, bottomDrawer: true, isRounded: true, className: theme.footer__slideBottom },
                React.createElement("div", { className: theme.footer__slideBottom },
                    React.createElement(LoginForm, { login: preselectedLogin, onSuccess: onSuccessfulLogin })))),
        React.createElement(MediaQuery, { minWidth: "mobile" },
            React.createElement(Modal, { open: isLoginPageOpened, onClose: function () { return setLoginPageOpened(false); }, maxWidth: "sm", classes: {
                    paper: theme.login__modal
                } },
                React.createElement(LoginForm, { login: preselectedLogin, onSuccess: onSuccessfulLogin }))),
        props.children({
            openLogin: onOpenLogin,
            userInfo: props.userInfo,
            refetchUser: props.refetchUser,
            isLoginFormOpen: isLoginPageOpened
        })));
};
export default Login;
