import { __assign } from "tslib";
import { createSelector } from 'reselect';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { getSeatPrice } from '../../../SeatMap/utils';
var getPassengersState = function (state) { return state.passengers; };
export var getSelectedServicesState = function (state) { return state.selectedServices; };
export var getSelectedServices = createSelector([getSelectedServicesState, getPassengersState], function (state, passengers) {
    var passengersServices = [];
    passengers.forEach(function (passenger) {
        if (state[passenger.id]) {
            var passengerServices = state[passenger.id];
            var seatsServices_1 = [], gdsServices_1 = [];
            var _loop_1 = function (serviceId) {
                if (passengerServices.hasOwnProperty(serviceId) && passengerServices[serviceId] !== null) {
                    var services = passengerServices[serviceId];
                    services.forEach(function (service) {
                        switch (service.servicePayload.type) {
                            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                                var seatServicePayload_1 = service.servicePayload;
                                if (service.number > 0) {
                                    service.segments.forEach(function (segmentId) {
                                        seatsServices_1.push({
                                            segmentId: segmentId,
                                            deckId: seatServicePayload_1.deckId,
                                            letter: seatServicePayload_1.letter,
                                            row: parseInt(seatServicePayload_1.row),
                                            type: seatServicePayload_1.seatType
                                        });
                                    });
                                }
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                            case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
                            case OrderAdditionalServiceGdsServiceServiceType.Vip:
                            case OrderAdditionalServiceGdsServiceServiceType.Misc:
                                gdsServices_1.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number
                                });
                                break;
                            default:
                                gdsServices_1.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number
                                });
                                break;
                        }
                    });
                }
            };
            for (var serviceId in passengerServices) {
                _loop_1(serviceId);
            }
            passengersServices.push({
                travellerId: passenger.id,
                gdsServices: {
                    setServices: gdsServices_1
                },
                seats: seatsServices_1
            });
        }
    });
    return passengersServices;
});
export var getHasSelectedSeats = createSelector(getSelectedServices, function (selectedServices) {
    return selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices.some(function (travellerServices) { var _a; return ((_a = travellerServices.seats) === null || _a === void 0 ? void 0 : _a.length) > 0; });
});
export var getPassengerStateCompatible = createSelector([getPassengersState, getSelectedServicesState], function (passengers, selectedServices) {
    var linkedPassengers = passengers.filter(function (passenger) { return passenger.linkedTraveller; });
    var linkedPassengersIdsMap = new Map(linkedPassengers.map(function (passenger) { return [passenger.linkedTraveller.id, passenger.id]; }));
    var passengerState = passengers.map(function (passenger) {
        var passengerServices = selectedServices[passenger.id];
        var passengersServices = [], seats = [];
        var _loop_2 = function (serviceId) {
            if (passengerServices.hasOwnProperty(serviceId) && passengerServices[serviceId]) {
                var services = passengerServices[serviceId];
                services.forEach(function (service) {
                    if (service.number > 0) {
                        switch (service.servicePayload.type) {
                            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                                var seatServicePayload_2 = service.servicePayload;
                                var price_1 = getSeatPrice(seatServicePayload_2);
                                service.segments.forEach(function (segmentId) {
                                    seats.push(__assign(__assign({}, seatServicePayload_2), { price: price_1, segmentId: segmentId }));
                                });
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                                passengersServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number,
                                    confirmedCount: service.servicePayload.confirmedCount
                                });
                                break;
                            case OrderAdditionalServiceGdsServiceServiceType.Vip:
                                passengersServices.push({
                                    segmentIds: service.segments,
                                    serviceId: serviceId,
                                    count: service.number,
                                    confirmedCount: service.servicePayload.confirmedCount
                                });
                        }
                    }
                });
            }
        };
        for (var serviceId in passengerServices) {
            _loop_2(serviceId);
        }
        return {
            id: passenger.id,
            type: passenger.type,
            values: passenger.values,
            services: passengersServices,
            infantPassengerId: passenger.type === AviaPassengerType.ADT && (linkedPassengersIdsMap.get(passenger.id) || null),
            supplierTravellerType: passenger.supplierTravellerType,
            seats: seats
        };
    });
    return passengerState;
});
