import { __extends } from "tslib";
import { OrderStatus } from '@websky/graphql';
import OrderDataLayer from './OrderDataLayer';
import { OrderStep } from '../types';
import { OrderType } from './types';
var CheckoutDataLayer = /** @class */ (function (_super) {
    __extends(CheckoutDataLayer, _super);
    function CheckoutDataLayer(instance) {
        return _super.call(this, instance) || this;
    }
    CheckoutDataLayer.prototype.getOrderType = function () {
        return OrderType.Checkout;
    };
    CheckoutDataLayer.prototype.getFlightSegments = function () {
        return this.getOrder().flight.segments.map(function (segment) { return segment.segment; });
    };
    CheckoutDataLayer.prototype.getOrderStatus = function () {
        return this.getOrder().status === OrderStatus.New
            ? OrderStep.Booking
            : this.getOrder().status === OrderStatus.Confirmed
                ? OrderStep.Paid
                : OrderStep.Checkout;
    };
    return CheckoutDataLayer;
}(OrderDataLayer));
export default CheckoutDataLayer;
