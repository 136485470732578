import * as React from 'react';
var baggageIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.27218 4.54316H6.58038C5.52347 4.54316 4.66653 5.45294 4.66675 6.57552V24.0007C4.66675 25.1233 5.5237 26.0333 6.5806 26.0333H6.83757V27.5087C6.83757 27.7799 7.04426 28 7.30023 28H9.48862C9.74381 28 9.95128 27.7803 9.95128 27.5087V26.0333H16.8825V27.5087C16.8825 27.7799 17.0895 28 17.3452 28H19.5338C19.789 28 19.9963 27.7803 19.9963 27.5087V26.0333H20.2529C21.3096 26.0333 22.1667 25.1236 22.1667 24.0007V6.57541C22.1667 5.45306 21.3098 4.54305 20.2529 4.54305H18.5612V2.32741C18.5612 1.04422 17.5778 0 16.3694 0H10.4639C9.25529 0 8.27218 1.04422 8.27218 2.32741V4.54316ZM17.1003 4.54305H9.73365V2.32741C9.73365 1.89968 10.0614 1.55161 10.4642 1.55161H16.3697C16.7725 1.55161 17.1003 1.89968 17.1003 2.32741V4.54305ZM7.04259 9.4106C7.04259 8.87294 7.47066 8.43709 7.99872 8.43709H18.8348C19.3629 8.43709 19.7909 8.87294 19.7909 9.4106C19.7909 9.94825 19.3629 10.3841 18.8348 10.3841H7.99872C7.47066 10.3841 7.04259 9.94825 7.04259 9.4106ZM7.04259 13.3046C7.04259 12.767 7.47066 12.3311 7.99872 12.3311H18.8348C19.3629 12.3311 19.7909 12.767 19.7909 13.3046C19.7909 13.8423 19.3629 14.2781 18.8348 14.2781H7.99872C7.47066 14.2781 7.04259 13.8423 7.04259 13.3046ZM7.99872 16.2252C7.47066 16.2252 7.04259 16.661 7.04259 17.1987C7.04259 17.7363 7.47066 18.1722 7.99872 18.1722H18.8348C19.3629 18.1722 19.7909 17.7363 19.7909 17.1987C19.7909 16.661 19.3629 16.2252 18.8348 16.2252H7.99872ZM7.04259 21.0927C7.04259 20.5551 7.47066 20.1192 7.99872 20.1192H18.8348C19.3629 20.1192 19.7909 20.5551 19.7909 21.0927C19.7909 21.6304 19.3629 22.0662 18.8348 22.0662H7.99872C7.47066 22.0662 7.04259 21.6304 7.04259 21.0927Z", fill: "currentColor" })));
var insuranceIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M26.8333 14.1431C26.2378 13.7283 25.3341 13.4636 24.3218 13.4636C22.7545 13.4636 21.4467 14.098 21.1424 14.9417H21.0118C20.715 14.0925 19.4032 13.4525 17.8291 13.4525C16.8151 13.4525 15.9118 13.7189 15.3168 14.1349V21.2766C15.3168 23.0541 13.825 24.5 11.9915 24.5C10.1579 24.5 8.66625 23.0541 8.66625 21.2766C8.66625 20.8839 8.9946 20.5656 9.39977 20.5656C9.80495 20.5656 10.1333 20.8839 10.1333 21.2766C10.1333 22.2699 10.9669 23.0779 11.9915 23.0779C13.0161 23.0779 13.8497 22.2699 13.8497 21.2766V14.1465C13.2544 13.7303 12.3507 13.4636 11.3363 13.4636C9.76888 13.4636 8.46108 14.098 8.15687 14.9417H8.02633C7.72948 14.0925 6.41769 13.4525 4.84355 13.4525C3.83188 13.4525 2.92862 13.7169 2.33325 14.1312C2.81164 9.06745 7.72684 5.04234 13.8497 4.74259V4.21106C13.8497 3.81825 14.1781 3.5 14.5832 3.5C14.9884 3.5 15.3168 3.81829 15.3168 4.21106V4.74267C21.4439 5.04307 26.3614 9.07409 26.8333 14.1431Z", fill: "currentColor" })));
var transferIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.5784 2.97266L27.2992 11.8843L18.5784 20.796V15.586H7.16865V13.9269L3.96585 17.1998L7.16865 20.4727V18.6499H16.8073V20.9832H9.50198V26.1932L0.701172 17.1998L9.50198 8.2063V13.2527H20.9117V15.0755L24.0345 11.8843L20.9117 8.69321V10.1888H12.2797V7.85552H18.5784V2.97266Z", fill: "currentColor" })));
var seatIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.1789 21.3858L11.843 23.9831H6.70006V25.9451H12.9493L13.0622 26L13.0904 25.9451H19.0983V23.9831H14.0996L15.4354 21.3858H19.4965C20.7483 21.3858 21.7632 20.3992 21.7632 19.1823C21.7632 17.9653 20.7483 16.9788 19.4965 16.9788H11.3533L8.515 8.57633V3.70886C8.515 2.76508 7.72798 2 6.75713 2C5.78629 2 4.99927 2.76508 4.99927 3.70886V8.57633L8.82366 21.3858H13.1789ZM16.0946 7.40013L18.8787 9.95983L25.9052 3.49963L26.8333 4.35287L18.8787 11.6663L15.1666 8.25337L16.0946 7.40013Z", fill: "currentColor" })));
var mealIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.7272 3.5V7.31818H24.5L22.925 23.0968C22.8295 23.8795 22.1709 24.5 21.3691 24.5H19.7463L19.7272 16.8636C19.7272 12.8259 16.7682 10.3345 13.265 9.52318L13.0454 7.31818H17.8182V3.5H19.7272ZM4.46409 24.4905C3.92955 24.4905 3.5 24.0609 3.5 23.5264V22.5909H17.8277V23.5264C17.8277 24.0609 17.3982 24.4905 16.8636 24.4905H4.46409ZM10.6591 11.1268C7.07955 11.1268 3.5 13.0455 3.5 16.8636H17.8182C17.8182 13.0455 14.2386 11.1268 10.6591 11.1268ZM17.8182 18.7727H3.5V20.6818H17.8182V18.7727Z", fill: "currentColor" })));
var ffpIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M13.0685 3.26145C13.3997 2.41295 14.6003 2.41295 14.9315 3.26145L17.0995 8.81536C17.2416 9.17947 17.5829 9.4274 17.9731 9.45006L23.9251 9.79565C24.8344 9.84845 25.2055 10.9904 24.5008 11.5676L19.8887 15.3457C19.5863 15.5934 19.456 15.9945 19.555 16.3726L21.0656 22.1401C21.2964 23.0213 20.325 23.727 19.5583 23.2352L14.5399 20.0163C14.2109 19.8053 13.7891 19.8053 13.4601 20.0163L8.44167 23.2352C7.67498 23.727 6.70362 23.0213 6.9344 22.1401L8.44499 16.3726C8.54403 15.9945 8.41368 15.5934 8.11132 15.3457L3.49917 11.5676C2.79455 10.9904 3.16557 9.84845 4.07489 9.79565L10.0269 9.45006C10.4171 9.4274 10.7584 9.17947 10.9005 8.81536L13.0685 3.26145Z", fill: "#9A9A9A" })));
export default {
    baggageIcon: baggageIcon,
    insuranceIcon: insuranceIcon,
    transferIcon: transferIcon,
    seatIcon: seatIcon,
    mealIcon: mealIcon,
    ffpIcon: ffpIcon
};
