import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCheckinOrderQuery } from '@websky/graphql';
import { fillCheckinOrder } from '../../store/order/actions';
import OrderGuard from '../../../../OrderGuard/OrderGuard';
import Order from './Order/Order';
import { getCheckinOrder } from '../../store/order/selectors';
import CheckinErrorBoundary from './CheckinErrorBoundary/CheckinErrorBoundary';
import { getPassengerLastName } from '../../../../Checkout/store/passengers/selectors';
import { useUpdateCheckinTravellerServices } from '../../hooks';
var OrderWrapper = function (_a) {
    var _b, _c, _d, _e;
    var orderId = _a.orderId;
    var dispatch = useDispatch();
    var order = useSelector(getCheckinOrder);
    var onOrderAccessGranted = useCallback(function () { return window.location.reload(); }, []);
    var lastName;
    var ticketNumber;
    if (order && order.travellers.length) {
        lastName = (_b = getPassengerLastName(order.travellers[0])) === null || _b === void 0 ? void 0 : _b.value;
        ticketNumber = (_e = (_d = (_c = order.travellers[0]) === null || _c === void 0 ? void 0 : _c.tickets) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.number;
    }
    var onOrderLoad = function (order) {
        dispatch(fillCheckinOrder(order));
    };
    useUpdateCheckinTravellerServices();
    return (React.createElement(OrderGuard, { orderId: orderId, orderLoaded: !!order, onOrderLoad: onOrderLoad, onOrderAccessGranted: onOrderAccessGranted, loadOrder: useGetCheckinOrderQuery },
        React.createElement(CheckinErrorBoundary, { lastName: lastName, ticketNumber: ticketNumber },
            React.createElement(Order, null))));
};
export default OrderWrapper;
