import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import { getSelectedPassengersWithChildrenIds } from '../../store/passengers/selectors';
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import PassengersPage from '../Pages/Passengers';
import ReasonsPage from '../Pages/Reasons';
import RequestFormPage from '../Pages/Request';
import AddFilesPage from '../Pages/AddFiles';
import StepsButtons from '../StepsButtons/StepsButtons';
import CreateTicket from '../CreateTicket/CreateTicket';
import Ticket from '../Ticket/Ticket';
import { useHtmlDescriptionOfError, useMobileStepsPositionClassNames, useRefundInfo } from './hooks';
import { getSelectedReason } from '../../store/reason/selectors';
import { createGetCreateRefundParams, isAutoRefundMode } from '../../store/selectors';
import { getActiveStep, getCanGoToNextStep } from '../../store/steps/selectors';
import { nextStep, setStep } from '../../store/steps/actions';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import Wrapper from '../Wrapper/Wrapper';
import { StepType, Unvoluntary } from '../../types';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import GetLastRefundTicket from '../GetLastRefundTicket/GetLastRefundTicket';
import { useToggleable } from '../../../hooks';
import RefundInfoModal from '../Modals/RefundInfoModal/RefundInfoModal';
import PromoLoader from '../../../PromoLoader';
import { getIsAutoModeAllowed } from '../../store/aviaOrder/selectors';
import { getIsDocUploadRequired } from '../../store/refundInfo/selectors';
import { useConfirmRefundOrderMutation, useRefuseRefundOrderMutation } from '@websky/graphql';
import WarningModal from '../../../WarningModal';
import SimpleLoader from '../../../SimpleLoader';
import { pnrBlockedRegExp } from '../../../apolloErrorLink';
import { useConfig } from '../../../context';
var errorsRequireRedirectToOrder = [
    'Refund operations with used segments are not permitted',
    'THIS IS A MANUAL OPERATION - SET PENALTY MANUALLY'
];
initI18n('Refund');
var Refund = function (props) {
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Refund;
    var _a = useToggleable(false), allowAccessToNewlyCreatedRefund = _a.isOpen, allowOpenNewTicket = _a.open;
    var _b = useToggleable(false), successConfirmModalOpen = _b.isOpen, openSuccessConfirmModal = _b.open, closeSuccessConfirmModal = _b.close;
    var currentStep = useSelector(getActiveStep);
    var selectedReason = useSelector(getSelectedReason);
    var isAutoMode = useSelector(isAutoRefundMode);
    var selectedPassengersWithChildrenIds = useSelector(getSelectedPassengersWithChildrenIds);
    var canGoToNextStep = useSelector(getCanGoToNextStep);
    var getRefundParamsSelector = createGetCreateRefundParams(props.orderId);
    var baseUrl = "/booking/".concat(props.orderId, "/refund");
    var history = useHistory();
    var isAutoModeAllowed = useSelector(getIsAutoModeAllowed);
    var isDocumentUploadRequired = useSelector(getIsDocUploadRequired);
    var _c = __read(useState(null), 2), refundOrder = _c[0], setRefundOrder = _c[1];
    var _d = __read(useState(null), 2), requestError = _d[0], setRequestError = _d[1];
    var _e = __read(useState(null), 2), onClearError = _e[0], setOnClearError = _e[1];
    var _f = __read(useState(false), 2), reloadOrderLoading = _f[0], setReloadOrderLoading = _f[1];
    var createRefundParams = useSelector(getRefundParamsSelector);
    var _g = useToggleable(false), isEmptyPassengersModal = _g.isOpen, openEmptyPassengersModal = _g.open, closeEmptyPassengersModal = _g.close;
    var exareShowPassengersStep = useConfig().Engine.exareShowPassengersStep;
    var dispatch = useDispatch();
    var handleReturnToOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setReloadOrderLoading(true);
                    return [4 /*yield*/, props.onReloadCheckoutOrder()];
                case 1:
                    _a.sent();
                    setReloadOrderLoading(true);
                    document.location.hash = "#/booking/".concat(props.orderId, "/order");
                    return [2 /*return*/];
            }
        });
    }); }, [document.location]);
    var handleCloseConfirmModal = useCallback(function () {
        closeSuccessConfirmModal();
        handleReturnToOrder();
    }, [closeSuccessConfirmModal]);
    var goToNextStep = useCallback(function () { return dispatch(nextStep()); }, [dispatch, currentStep]);
    var handleCreateNewRequest = useCallback(function () {
        history.push("".concat(baseUrl, "/new"));
        dispatch(setStep({ additionalText: '', type: StepType.Passengers, isActive: true }));
    }, []);
    var _h = __read(useRefuseRefundOrderMutation({ variables: { id: null } }), 2), refuseRefund = _h[0], refuseLoading = _h[1].loading;
    var _j = __read(useConfirmRefundOrderMutation({ variables: { id: null } }), 2), confirmRefund = _j[0], confirmLoading = _j[1].loading;
    var setClassesToPassedSteps = useMobileStepsPositionClassNames().setClassesToPassedSteps;
    var htmlDescriptionOfError = useHtmlDescriptionOfError(requestError);
    var hasPnrBlockedError = function (errors) {
        return errors === null || errors === void 0 ? void 0 : errors.some(function (error) { return pnrBlockedRegExp.test((error === null || error === void 0 ? void 0 : error.message) || ''); });
    };
    var onRequestError = function (errors) {
        if (errors === void 0) { errors = []; }
        var debugMessage = errors[0]['debugMessage'] || errors[0]['message'];
        if (debugMessage === 'There is already an open request for a refund.') {
            history.push("".concat(baseUrl.replace('new/', ''), "/"));
        }
        else if (debugMessage === 'This operation is not available') {
            history.push("".concat(baseUrl.replace('new', ''), "/"));
        }
        else if (!hasPnrBlockedError(errors)) {
            setRequestError(errors[0]);
        }
    };
    var clearError = function () {
        setRequestError(null);
        onClearError === null || onClearError === void 0 ? void 0 : onClearError();
    };
    var clearErrorAndGoOrder = function () {
        clearError();
        handleReturnToOrder();
    };
    var handleRefuse = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, refuseRefund({ variables: { id: id } })];
                case 1:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (errors === null || errors === void 0 ? void 0 : errors.length) {
                        onRequestError(errors);
                    }
                    else if (data === null || data === void 0 ? void 0 : data.RefuseExare) {
                        handleCreateNewRequest();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _c.sent();
                    if ((_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length) {
                        onRequestError(e_1.graphQLErrors);
                    }
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var handleConfirm = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, e_2;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, confirmRefund({ variables: { id: id } })];
                case 1:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    if (errors === null || errors === void 0 ? void 0 : errors.length) {
                        onRequestError(errors);
                    }
                    else if (data === null || data === void 0 ? void 0 : data.ConfirmExare) {
                        setRefundOrder(data.ConfirmExare);
                        openSuccessConfirmModal();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _c.sent();
                    if ((_b = e_2 === null || e_2 === void 0 ? void 0 : e_2.graphQLErrors) === null || _b === void 0 ? void 0 : _b.length) {
                        onRequestError(e_2.graphQLErrors);
                    }
                    console.log(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () { return setClassesToPassedSteps; }, [currentStep.type]);
    var _k = useRefundInfo({
        variables: {
            includePrices: false,
            isCompelled: selectedReason !== Unvoluntary.Unvoluntary,
            orderId: props.orderId,
            passengers: selectedPassengersWithChildrenIds
        },
        skip: currentStep.type !== StepType.Reason,
        onCompleted: function (data) {
            var _a;
            if ((data === null || data === void 0 ? void 0 : data.GetRefundInfo) && !((_a = data === null || data === void 0 ? void 0 : data.GetRefundInfo) === null || _a === void 0 ? void 0 : _a.unvoluntaryReasonsInfo.length)) {
                dispatch(setStep({ additionalText: '', type: StepType.CreateTicket, isActive: true }));
            }
        },
        onError: function (error) {
            onRequestError(error === null || error === void 0 ? void 0 : error.graphQLErrors);
        }
    }), reasons = _k.reasons, docsToFilesMap = _k.docks, prices = _k.prices, pricesLoading = _k.loading, refetch = _k.refetch;
    var fetchReasons = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_3, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, refetch({
                            includePrices: isAutoModeAllowed && selectedReason === Unvoluntary.Unvoluntary,
                            isCompelled: selectedReason !== Unvoluntary.Unvoluntary,
                            orderId: props.orderId,
                            passengers: selectedPassengersWithChildrenIds
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    error = e_3;
                    onRequestError(error === null || error === void 0 ? void 0 : error.graphQLErrors);
                    console.log(e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (currentStep.type === StepType.CreateTicket) {
            if (selectedPassengersWithChildrenIds.length) {
                fetchReasons();
            }
            else {
                openEmptyPassengersModal();
            }
        }
    }, [currentStep]);
    var closeEmptyModalAndGoPassengers = useCallback(function () {
        closeEmptyPassengersModal();
        dispatch(setStep({ additionalText: '', type: StepType.Passengers, isActive: false }));
    }, [closeEmptyPassengersModal, dispatch]);
    var isErrorRequireRedirectToOrder = requestError && errorsRequireRedirectToOrder.includes(requestError.message);
    var isLoading = pricesLoading || confirmLoading || refuseLoading;
    return (React.createElement(React.Fragment, null,
        reloadOrderLoading && React.createElement(SimpleLoader, null),
        React.createElement(ProtectedRoute, { isAccessible: !!props.refundOrder || allowAccessToNewlyCreatedRefund, path: '/booking/:orderId/refund', exact: true, redirectTo: "".concat(baseUrl, "/new"), render: function () { return (React.createElement(Wrapper, { onClose: handleReturnToOrder, hideBreadcrumbs: true }, confirmLoading || refuseLoading ? (React.createElement(PromoLoader, null)) : (React.createElement(GetLastRefundTicket, { orderId: props.orderId }, function (lastRefundOrder) {
                allowOpenNewTicket();
                return (React.createElement(React.Fragment, null,
                    React.createElement(Ticket, { isLoading: confirmLoading || refuseLoading, onNewRequest: handleCreateNewRequest, isAutoMode: isAutoMode, refundOrder: refundOrder !== null && refundOrder !== void 0 ? refundOrder : lastRefundOrder, onConfirm: handleConfirm, onReject: handleRefuse }),
                    React.createElement(RefundInfoModal, { onAgree: handleCloseConfirmModal, renderText: function () {
                            return t('Successful refund. Confirmation and detalization have been sent to your e-mail');
                        }, renderAgreeText: function () { return t('Ok'); }, open: successConfirmModalOpen })));
            })))); } }),
        React.createElement(Route, { path: '/booking/:orderId/refund/new', exact: true, render: function () { return (React.createElement(Wrapper, { onClose: handleReturnToOrder, className: css.wrapper },
                React.createElement(PassengersPage, { skipPassengersStep: !exareShowPassengersStep }),
                React.createElement(ReasonsPage, { isLoading: pricesLoading, reasonsGroups: reasons }),
                isDocumentUploadRequired && React.createElement(AddFilesPage, { docsToFilesMap: docsToFilesMap }),
                React.createElement(RequestFormPage, { isLoading: pricesLoading, prices: isAutoModeAllowed && selectedReason === Unvoluntary.Unvoluntary && prices }),
                !isLoading &&
                    currentStep.type !== StepType.CreateTicket &&
                    currentStep.type !== StepType.Ticket && (React.createElement(MediaQuery, { maxWidth: 'mobile' },
                    React.createElement(StepsButtons, { className: css.buttons, position: 'center', nextButton: {
                            children: t('Continue'),
                            onClick: goToNextStep,
                            disabled: !canGoToNextStep
                        } }))),
                currentStep.type === StepType.Ticket && createRefundParams && (React.createElement(CreateTicket, { onCreate: function (refundOrder) {
                        setRefundOrder(refundOrder);
                        allowOpenNewTicket();
                        history.push(baseUrl);
                    }, onError: function (errors) {
                        onRequestError(errors);
                        setOnClearError(function () { return history.push(baseUrl); });
                    }, params: createRefundParams })))); } }),
        React.createElement(WarningModal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, title: t('Oops...'), content: (htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.header) ? (React.createElement("div", { className: css.warningModalContent, dangerouslySetInnerHTML: { __html: htmlDescriptionOfError.header } })) : ((requestError === null || requestError === void 0 ? void 0 : requestError.message) || t('Unknown error')), errorMessage: (htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.body) && (React.createElement("div", { className: css.warningModalContent, dangerouslySetInnerHTML: { __html: htmlDescriptionOfError.body } })), isOpen: !!requestError, onClose: !isErrorRequireRedirectToOrder ? clearError : null, buttonText: isErrorRequireRedirectToOrder ? t('Back to order') : null, onButtonClick: isErrorRequireRedirectToOrder ? clearErrorAndGoOrder : null }),
        React.createElement(WarningModal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, title: t('Oops...'), content: t('No passengers selected for ticket refund. Please select at least one passenger to proceed.'), isOpen: isEmptyPassengersModal, buttonText: t('Go to passenger selection'), onButtonClick: closeEmptyModalAndGoPassengers })));
};
export default Refund;
