import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCreateRefundOrder } from '../Refund/hooks';
import PromoLoader from '../../../PromoLoader';
var CreateTicket = function (props) {
    var params = props.params;
    var _a = __read(useCreateRefundOrder(), 1), createRefundMutation = _a[0];
    var _b = __read(useState(true), 2), isLoading = _b[0], setIsLoading = _b[1];
    var createRefund = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1, error;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, createRefundMutation({ variables: { params: params } })];
                case 1:
                    data = (_b.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.CreateRefund) {
                        props.onCreate(data.CreateRefund);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _b.sent();
                    error = e_1;
                    (_a = props.onError) === null || _a === void 0 ? void 0 : _a.call(props, error.graphQLErrors);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        createRefund();
    }, []);
    if (isLoading) {
        return React.createElement(PromoLoader, null);
    }
    return null;
};
export default CreateTicket;
