import * as React from 'react';
import { FormControlLabel } from '@mui/material';
import BaseCheckbox from '../../../BaseComponents/Checkbox';
import { useTheme } from '../../../theme';
import Tooltip from '../../../Tooltip';
import cn from 'classnames';
var Checkbox = React.forwardRef(function (_a, ref) {
    var className = _a.className, label = _a.label, tooltipLabel = _a.tooltipLabel, isChecked = _a.isChecked, isTooltipOpen = _a.isTooltipOpen, onChange = _a.onChange;
    var theme = useTheme('Consents').Checkbox;
    return (React.createElement(FormControlLabel, { classes: { root: cn(className, theme.control), label: theme.label }, checked: isChecked, control: React.createElement(Tooltip, { classes: { popper: theme.popper }, title: tooltipLabel, open: isTooltipOpen },
            React.createElement(BaseCheckbox, { onChange: onChange })), label: React.createElement("div", { className: theme.label, dangerouslySetInnerHTML: { __html: label } }), ref: ref }));
});
export default Checkbox;
