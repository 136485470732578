import { __assign } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { iataWithAdditionalQueryForServices, useAdditionalQueryForServices, useAdditonalQueryForSeatMap, useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { useSelector } from 'react-redux';
import { getSeatsMinPrice, getServicesFallbackURL, getIsAllSegmentsAvailableOnlyOnCheckin, shouldRedirectToFallback, getOrder } from '../../../Checkout/store/order/selectors';
import { useTranslation } from 'react-i18next';
import { getSeatsPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { getPassengerStateCompatible } from '../../../Checkout/store/selectedServices/selectors';
import { useRenderers } from '../../../renderProps';
import { getSeatsWithDoubleSeats } from '../../../SeatMap/utils';
import SeatSummary from '../../../ServiceSummary/services/SeatSummary';
import { getTravellersMap } from '../../../utils';
import { getSegmentsById } from '../../../Cart/utils';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useIataCode } from '../../../hooks';
import SimpleLoader from '../../../SimpleLoader';
var SeatsAdditionalService = function (_a) {
    var _b;
    var isCheckinAvailable = _a.isCheckinAvailable;
    var t = useTranslation('Checkout').t;
    var minPrice = useSelector(getSeatsMinPrice);
    var seatsIssuedPrice = useSelector(getSeatsPrice);
    var servicePageProps = useServicePageProps();
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var isAllSegmentsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    var _c = useUpsaleWarning(), showWarning = _c.showWarning, closeWarning = _c.closeWarning, toggleWarningIfOrderConfirmed = _c.toggleWarningIfOrderConfirmed;
    var toggleSeatsModal = function () {
        if (iataWithAdditionalQueryForServices.includes(iataCode)) {
            loadAndDispatchAdditionalServices();
        }
        servicePageProps.toggleModal(ServiceType.Seats);
    };
    var handleClick = shouldRedirect ? toggleWarningIfOrderConfirmed : toggleSeatsModal;
    var order = useSelector(getOrder);
    var passengers = useSelector(getPassengerStateCompatible);
    var css = useTheme('CheckoutAdditionalService').Seats;
    var iataCode = useIataCode();
    var onDeleteItem = useCart().onDeleteItem;
    var allSeatsConfirmed = passengers.every(function (passenger) {
        var seatsWithDoubleSeats = getSeatsWithDoubleSeats(passenger.seats);
        return passenger.seats.every(function (seat) { return seat.isConfirmed || seatsWithDoubleSeats.has(seat.number); });
    });
    var _d = useAdditonalQueryForSeatMap(), isSeatMapLoading = _d.isLoading, loadAndDispatchSeatMap = _d.loadAndDispatch;
    var _e = useAdditionalQueryForServices({
        onCompleted: function () {
            loadAndDispatchSeatMap();
        }
    }), isAdditionalServicesLoading = _e.isLoading, loadAndDispatchAdditionalServices = _e.loadAndDispatch;
    var buttons = [
        {
            text: t(allSeatsConfirmed ? 'More details' : 'Change'),
            action: toggleSeatsModal,
            buttonType: 'change'
        }
    ];
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_b = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _b !== void 0 ? _b : CheckoutAdditionalService;
    var isDV = useIataCode() === 'DV';
    var isServicesLoading = isAdditionalServicesLoading || isSeatMapLoading;
    return (React.createElement(React.Fragment, null,
        React.createElement(SeatSummary, { travellers: order.travellers, travellersById: getTravellersMap(order.travellers), segmentsById: getSegmentsById(order.flight.segments), onServiceDelete: onDeleteItem }, function (content) { return (React.createElement(ServiceWrapper, { showFromZeroIfZeroPrice: isDV, className: css.seats, showAddButton: !isAllSegmentsAvailableOnCheckin, icon: icons.seatIcon, addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, header: t('Seats'), description: isAllSegmentsAvailableOnCheckin
                ? isCheckinAvailable
                    ? t('Seat selection is available in the online registration service')
                    : t('You can select seats using the online registration service after issuing tickets')
                : t('The top ones get the top seats!'), priceTotal: seatsIssuedPrice.amount ? seatsIssuedPrice : null, buttons: buttons, priceFrom: !isAllSegmentsAvailableOnCheckin ? minPrice : null, isSelected: !!content }, content)); }),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        isServicesLoading && React.createElement(SimpleLoader, null),
        React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: isServicesLoading ? null : servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels }, childrenProps))); })));
};
export default SeatsAdditionalService;
