import { __assign } from "tslib";
import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import CompareFares from './CompareFares';
import { useGraphQLClient } from '../../graphql-client';
var Component = function (props) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(CompareFares, __assign({}, props))));
};
export default Component;
