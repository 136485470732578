import { __assign, __read, __spreadArray } from "tslib";
import { getServiceCategory, getTravellersTypes } from './utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { DataLayerItemsListName, ItemCategory } from './types';
import { getRelatedSegments } from '../Baggage/UpgradeBaggageDialog/utils';
var WebskyServicesAnalytics = /** @class */ (function () {
    function WebskyServicesAnalytics() {
    }
    WebskyServicesAnalytics.insurances = function (services, travellers) {
        return services === null || services === void 0 ? void 0 : services.map(function (service) { return ({
            category: ItemCategory.Insurance,
            name: service.name,
            price: service.totalPrice.amount,
            count: 1,
            forAllSegments: true,
            travellersTypes: getTravellersTypes(travellers)
        }); });
    };
    WebskyServicesAnalytics.selectedInsurances = function (services, travellers) {
        var insurancesMap = new Map();
        services === null || services === void 0 ? void 0 : services.forEach(function (service) {
            insurancesMap.set(service.insuranceProgram.code, service);
        });
        var dataLayer = [];
        var travellersTypes = travellers ? getTravellersTypes(travellers) : [];
        insurancesMap.forEach(function (service, code) {
            var _a, _b;
            dataLayer.push({
                category: ItemCategory.Insurance,
                name: (_a = service.insuranceProgram) === null || _a === void 0 ? void 0 : _a.name,
                price: (_b = service.insuranceProgram) === null || _b === void 0 ? void 0 : _b.totalPrice.amount,
                count: 1,
                forAllSegments: true,
                travellersTypes: travellersTypes
            });
        });
        return dataLayer;
    };
    WebskyServicesAnalytics.aeroexpress = function (services, travellers) {
        try {
            return services.reduce(function (newServices, service) {
                var _a;
                var segmentIds = (_a = service.segments) === null || _a === void 0 ? void 0 : _a.map(function (segment) { return segment.id; });
                return __spreadArray(__spreadArray([], __read(newServices), false), __read(segmentIds.map(function (segmentId) { return ({
                    category: ItemCategory.Aeroexpress,
                    name: "".concat(service.toStation.direction, "-").concat(service.class),
                    price: service.price.amount,
                    count: 1,
                    segmentId: segmentId,
                    travellersTypes: getTravellersTypes(travellers)
                }); })), false);
            }, []);
        }
        catch (e) {
            return [];
        }
    };
    WebskyServicesAnalytics.baggage = function (services, travellers) {
        return services.reduce(function (newServices, serivce) {
            var segmentIds = __spreadArray([], __read((getRelatedSegments(serivce.segmentIds) || [])), false);
            return __spreadArray(__spreadArray([], __read(newServices), false), __read(segmentIds.map(function (segmentId) {
                var _a;
                return ({
                    category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Baggage, serivce.type),
                    name: "".concat(serivce.name).concat(((_a = serivce.value) === null || _a === void 0 ? void 0 : _a.amount) ? "-".concat(serivce.value.amount).concat(serivce.value.measurement) : ''),
                    price: serivce.price.amount,
                    segmentId: segmentId,
                    count: 1,
                    isRecommend: serivce.isRecommended,
                    travellersTypes: getTravellersTypes(travellers)
                });
            })), false);
        }, []);
    };
    WebskyServicesAnalytics.seat = function (service, segmentId, isRecommend, travellerType) {
        return {
            name: service.number,
            price: service.price.amount,
            category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Seat),
            segmentId: segmentId,
            isRecommend: isRecommend,
            count: 1,
            travellersTypes: travellerType ? [travellerType] : []
        };
    };
    WebskyServicesAnalytics.seats = function (seatMap, travellers) {
        var items = [];
        try {
            if (seatMap.availableSeatsCount > 0) {
                seatMap.decks.forEach(function (deck) {
                    return deck.rows.forEach(function (row) {
                        row.seats.forEach(function (seat) {
                            var _a;
                            if (seat.isAvailable) {
                                items.push({
                                    category: ItemCategory.Seat,
                                    name: seat.number,
                                    price: (_a = seat.price) === null || _a === void 0 ? void 0 : _a.amount,
                                    segmentId: seatMap.segmentsInfo.id,
                                    isRecommend: false,
                                    count: 1,
                                    travellersTypes: getTravellersTypes(travellers)
                                });
                            }
                        });
                    });
                });
            }
        }
        catch (e) { }
        return items;
    };
    WebskyServicesAnalytics.vip = function (services, travllers) {
        var _a;
        var items = [];
        var category = getServiceCategory((_a = services === null || services === void 0 ? void 0 : services[0]) === null || _a === void 0 ? void 0 : _a.type);
        services.forEach(function (service) {
            service.allowedPassengers.forEach(function (passenger) {
                var _a, _b;
                items.push({
                    category: category,
                    name: (+passenger[0] + 1).toString(),
                    price: service.price.amount,
                    segmentId: (_b = (_a = service.allowedSegments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b[0],
                    count: 1,
                    travellersTypes: getTravellersTypes(travllers)
                });
            });
        });
        return items;
    };
    WebskyServicesAnalytics.selectVip = function (service, passengerId, segmentId) {
        return {
            name: (+passengerId + 1).toString(),
            price: service.price.amount,
            segmentId: segmentId,
            count: 1,
            category: getServiceCategory(service.type)
        };
    };
    WebskyServicesAnalytics.flight = function (services, travellers) {
        return services.map(function (flight) {
            var _a, _b;
            return ({
                category: ItemCategory.Flight,
                name: (_b = (_a = flight.fareFamily) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : 'NA',
                price: flight.price.amount,
                segmentIds: flight.segments.map(function (segment) { return segment.id; }),
                travellersTypes: getTravellersTypes(travellers),
                count: 1
            });
        });
    };
    WebskyServicesAnalytics.convertGdsServiceToDataLayer = function (service, selectedService, travellerType) {
        var _a;
        return {
            category: getServiceCategory(service.type, service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage
                ? service.baggageType
                : null),
            name: service.name,
            price: service.price.amount,
            segmentId: (_a = selectedService.segmentIds) === null || _a === void 0 ? void 0 : _a[0],
            count: selectedService.count,
            travellersTypes: [travellerType]
        };
    };
    WebskyServicesAnalytics.getPriceFreezeServices = function (order) {
        var _a, _b, _c, _d, _e;
        var priceFreezeService = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (service) { return service.isPriceFreeze; });
        if (priceFreezeService &&
            order.travellers.some(function (traveller) {
                var _a, _b, _c;
                return (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (selectedService) { return selectedService.serviceId === priceFreezeService.id; });
            })) {
            return [
                {
                    name: 'hold',
                    category: ItemCategory.PriceFreeze,
                    forAllSegments: true,
                    price: (((_d = priceFreezeService.price) === null || _d === void 0 ? void 0 : _d.amount) || 0) *
                        ((_e = order.travellers.filter(function (traveller) {
                            var _a, _b, _c;
                            return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return service.serviceId === priceFreezeService.id; });
                        })) === null || _e === void 0 ? void 0 : _e.length),
                    count: 1
                }
            ];
        }
        return [];
    };
    WebskyServicesAnalytics.getVipItemListName = function (service) {
        if ((service === null || service === void 0 ? void 0 : service.type) === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge) {
            return DataLayerItemsListName.Business;
        }
        return null;
    };
    WebskyServicesAnalytics.getCheckoutSelectedServices = function (order, skipConfirmedServices) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (skipConfirmedServices === void 0) { skipConfirmedServices = true; }
        try {
            if (order.isPriceFreezeProductSelected) {
                var priceFreezeService_1 = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (service) { return service.isPriceFreeze; });
                if (order.travellers.some(function (traveller) {
                    var _a, _b, _c;
                    return (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return service.serviceId === priceFreezeService_1.id && !service.confirmedCount; });
                })) {
                    return WebskyServicesAnalytics.getPriceFreezeServices(order);
                }
            }
            return __spreadArray(__spreadArray([], __read((((_d = order.priceToPay.airticket) === null || _d === void 0 ? void 0 : _d.amount) > 0
                ? this.flight((_e = order.flight) === null || _e === void 0 ? void 0 : _e.segmentGroups, order.travellers)
                : [])), false), __read(this.getAllSelectedServicesInOrder((_f = order.additionalServices) === null || _f === void 0 ? void 0 : _f.gdsServices, order.travellers, (_g = order.additionalServices) === null || _g === void 0 ? void 0 : _g.insurances, (_h = order.additionalServices) === null || _h === void 0 ? void 0 : _h.transfer, skipConfirmedServices)), false);
        }
        catch (error) {
            console.log(error);
            return [];
        }
    };
    WebskyServicesAnalytics.getCheckinSelectedServices = function (order) {
        var _a, _b, _c;
        return __spreadArray([], __read(this.getAllSelectedServicesInOrder((_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices, order.travellers.map(function (traveller) { return (__assign(__assign({}, traveller), { needOthsSsr: false, fares: [] })); }), (_b = order.additionalServices) === null || _b === void 0 ? void 0 : _b.insurances, (_c = order.additionalServices) === null || _c === void 0 ? void 0 : _c.transfer)), false);
    };
    WebskyServicesAnalytics.getAllSelectedServicesInOrder = function (gdsServices, passengers, insurances, transfer, skipConfirmedServices) {
        var _this = this;
        var _a, _b, _c;
        if (skipConfirmedServices === void 0) { skipConfirmedServices = true; }
        var itemList = [];
        var servicesMap = (_b = (_a = gdsServices === null || gdsServices === void 0 ? void 0 : gdsServices.services) === null || _a === void 0 ? void 0 : _a.reduce(function (map, service) {
            map.set(service.id, service);
            return map;
        }, new Map())) !== null && _b !== void 0 ? _b : new Map();
        passengers.forEach(function (passenger) {
            var _a, _b, _c, _d, _e;
            if (passenger.services) {
                (_c = (_b = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(function (selectedService) {
                    var service = servicesMap.get(selectedService.serviceId);
                    if ((!skipConfirmedServices || selectedService.count > selectedService.confirmedCount) &&
                        !!service) {
                        itemList.push(_this.convertGdsServiceToDataLayer(service, __assign(__assign({}, selectedService), { count: !skipConfirmedServices
                                ? selectedService.count
                                : selectedService.count - selectedService.confirmedCount }), passenger.type));
                    }
                });
                (_e = (_d = passenger.services) === null || _d === void 0 ? void 0 : _d.seats) === null || _e === void 0 ? void 0 : _e.filter(function (seat) { return !skipConfirmedServices || !seat.isConfirmed; }).forEach(function (selectedSeat) {
                    var _a, _b, _c, _d;
                    itemList.push(_this.seat(selectedSeat.seat, (_a = selectedSeat.segment) === null || _a === void 0 ? void 0 : _a.id, (_d = (_c = (_b = passenger.preselectedServices) === null || _b === void 0 ? void 0 : _b.seats) === null || _c === void 0 ? void 0 : _c.some(function (preselected) {
                        var _a, _b, _c, _d;
                        return ((_a = preselected.seat) === null || _a === void 0 ? void 0 : _a.number) === ((_b = selectedSeat.seat) === null || _b === void 0 ? void 0 : _b.number) &&
                            ((_c = preselected.segment) === null || _c === void 0 ? void 0 : _c.id) === ((_d = selectedSeat.segment) === null || _d === void 0 ? void 0 : _d.id);
                    })) !== null && _d !== void 0 ? _d : false, passenger.type));
                });
            }
        });
        if (insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) {
            itemList.push.apply(itemList, __spreadArray([], __read(this.selectedInsurances(insurances.selectedInsurances.filter(function (insurance) { return !skipConfirmedServices || !insurance.policyUrl; }), passengers)), false));
        }
        if ((_c = transfer === null || transfer === void 0 ? void 0 : transfer.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) {
            itemList.push.apply(itemList, __spreadArray([], __read(this.aeroexpress(transfer.aeroexpress.selectedTrips.filter(function (trip) { var _a; return !skipConfirmedServices || !((_a = trip.tickets) === null || _a === void 0 ? void 0 : _a.length); }), passengers)), false));
        }
        return itemList;
    };
    return WebskyServicesAnalytics;
}());
export default WebskyServicesAnalytics;
