import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
import Amenities from '../../Amenities';
import { initI18n } from '../../utils';
import { Time } from '../../Icons';
import AircraftPopup from '../../AircraftPopup';
import { useIataCode } from '../../hooks';
import ImageWithFallback from '../../ImageWithFallback';
import { useRenderers } from '../../renderProps';
initI18n('FlightCard');
var FlightCard = function (_a) {
    var _b;
    var airline = _a.airline, flightNumber = _a.flightNumber, duration = _a.duration, aircraft = _a.aircraft, amenities = _a.amenities, statistics = _a.statistics, className = _a.className, markers = _a.markers, isReturnFlight = _a.isReturnFlight;
    var css = useTheme('FlightCard').FlightCard;
    var hasAmenities = (amenities === null || amenities === void 0 ? void 0 : amenities.length) > 0;
    var iataCode = useIataCode();
    var hideAmenitiesIfAllUnable = ['WZ', 'ZF', 'RM', 'A9']; // в темах этих ак внутри Amenities не отображем элементы с available: false
    var hasAvailableAmenity = (amenities === null || amenities === void 0 ? void 0 : amenities.some(function (amenity) { return amenity.available; })) || (markers === null || markers === void 0 ? void 0 : markers.eco);
    var hideAmenities = !hasAmenities || (!hasAvailableAmenity && hideAmenitiesIfAllUnable.includes(iataCode));
    var renderButton = useCallback(function (onClick) {
        var _a;
        return (React.createElement("div", { className: cn(css.aircraft__name, (_a = {},
                _a[css.aircraft__name_active] = !!(aircraft === null || aircraft === void 0 ? void 0 : aircraft.id),
                _a[css.aircraft__name_withNoAmenities] = hideAmenities,
                _a)), onClick: (aircraft === null || aircraft === void 0 ? void 0 : aircraft.id) ? onClick : null },
            React.createElement("span", { className: css.aircraft__nameTitle }, aircraft === null || aircraft === void 0 ? void 0 : aircraft.name)));
    }, [aircraft, statistics, hideAmenities]);
    var renderFLightCardCarrierName = useRenderers().renderFLightCardCarrierName;
    var renderAirlineName = useMemo(function () {
        if (renderFLightCardCarrierName) {
            return renderFLightCardCarrierName({ airline: airline, aircraft: aircraft, flightNumber: flightNumber, duration: duration });
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.airline__number }, flightNumber),
                React.createElement("span", { className: css.airline__name }, airline.name)));
        }
    }, [airline.name]);
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.airline }, renderAirlineName),
            React.createElement("div", { className: css.flight },
                Time,
                duration)),
        React.createElement("div", { className: cn(css.aircraft, (_b = {}, _b[css.aircraft_return] = isReturnFlight, _b)) },
            React.createElement(ImageWithFallback, { className: css.aircraft__image, src: aircraft === null || aircraft === void 0 ? void 0 : aircraft.icon, fallbackSrc: 'https://cdn.websky.aero/content/default-content/images/Aircrafts/738/738.png', alt: aircraft === null || aircraft === void 0 ? void 0 : aircraft.name })),
        React.createElement("div", { className: css.footer },
            React.createElement(AircraftPopup, { id: aircraft === null || aircraft === void 0 ? void 0 : aircraft.id, operatingIata: airline.iata, name: aircraft === null || aircraft === void 0 ? void 0 : aircraft.name, renderButton: renderButton }),
            !hideAmenities && React.createElement(Amenities, { className: css.amenities, amenities: amenities, isEco: markers === null || markers === void 0 ? void 0 : markers.eco }))));
};
export default FlightCard;
