import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import Switch from '../../../../../Switch';
import { useDispatch, useSelector } from 'react-redux';
import { isOfferHotels } from '../../../../store/offerHotels/selectors';
import { offerHotelsAction } from '../../../../store/offerHotels/actions';
var OfferHotels = function (props) {
    var t = useTranslation('SearchForm').t;
    var theme = useTheme('SearchForm').OfferHotels;
    var isOfferHotelsActive = useSelector(isOfferHotels), dispatch = useDispatch();
    var toggleOfferHotels = function () {
        dispatch(offerHotelsAction(!isOfferHotelsActive));
    };
    return (React.createElement(Switch, { mainClassName: theme.switchItem, isActive: isOfferHotelsActive, labelClassName: theme.toggle__label, label: t('Offer hotels'), activeClassName: theme.toggle_active, switchPosition: "right", onClick: toggleOfferHotels }));
};
export default OfferHotels;
