import * as React from 'react';
import classnames from 'classnames';
import { useTheme } from '../../theme';
export var Switch = function (props) {
    var _a, _b, _c;
    var css = useTheme('Switch').Switch;
    return (React.createElement("div", { className: classnames(css.wrapper, props.mainClassName, (_a = {}, _a[css.active] = props.isActive, _a[props.activeClassName] = props.isActive, _a), (_b = {}, _b[css.disable] = props.isDisabled, _b[props.disableClassName] = props.isDisabled, _b), (_c = {}, _c[css.right] = props.switchPosition === 'right', _c)), onClick: !props.isDisabled && props.onClick ? props.onClick : null },
        React.createElement("div", { className: classnames(css.label, props.labelClassName) }, props.label)));
};
export default Switch;
