import { __assign } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../../../CheckoutAdditionalService';
import { useTranslation } from 'react-i18next';
import CommonContent from '../CommonContent';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var Seat = function (props) {
    var t = useTranslation('Checkout').t;
    var getServiceName = function (service, productId, passengerId) {
        var traveller = props.passengers.find(function (passenger) { return passenger.id === passengerId; });
        var seat = traveller.services.seats.find(function (seat) {
            return seat.product.id === productId;
        });
        var segment;
        if (Array.isArray(props.flight)) {
            segment = props.flight.find(function (segment) { return segment.id === seat.segment.id; });
        }
        else {
            segment = props.flight.segments.find(function (segment) { return segment.segment.id === seat.segment.id; }).segment;
        }
        return (React.createElement(React.Fragment, null,
            segment.departure.airport.iata,
            " - ",
            segment.arrival.airport.iata,
            ", ",
            seat.seat.number));
    };
    var theme = useTheme('RefundSummary').CommonContent;
    return (React.createElement(CheckoutAdditionalService, { isSelected: true, className: cn(theme.serviceCard, theme.serviceCard_seats), addClick: function () { return null; }, header: t('Seats'), description: '' },
        React.createElement(CommonContent, __assign({}, props, { getServiceName: getServiceName }))));
};
export default Seat;
