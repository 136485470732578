import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Mode } from '../../../../RetrieveBooking/components/RetrieveBooking';
import { useTheme } from '../../../../theme';
import { useFindOrder } from './FindOrder.hook';
import RetrieveBooking from '../../../../RetrieveBooking';
import GroupOrder from '../GroupOrder/GroupOrder';
import SecureAccess from '../../../../SecureAccess/SecureAccess';
var FindOrder = function (props) {
    var initialId = props.initialId, onSuccess = props.onSuccess, callSubmit = props.callSubmit;
    var css = useTheme('Checkout').FindOrder;
    var _a = useParams(), id = _a.id, secret = _a.secret;
    var model = useFindOrder(props);
    return (React.createElement(React.Fragment, null,
        React.createElement(SecureAccess, { isOpen: model.secureAccess.isOpen, showError: !model.isLoading && model.secureAccess.showEror, isLoading: model.isLoading, onSubmit: model.onSubmitSecureAccess, onResend: model.onResendSecureAccess }),
        model.groupOrderResponse ? (React.createElement(GroupOrder, { groupOrder: model.groupOrderResponse, onSuccess: function (order) { return onSuccess(order, false); }, onAccept: model.onAcceptGroupOrder, setGroupOrder: model.setGroupOrderResponse, groupOrderParams: model.groupOrderParams })) : (React.createElement("div", { className: css.wrapper },
            React.createElement(RetrieveBooking, { mode: Mode.Checkout, initialId: id !== null && id !== void 0 ? id : initialId, initialSecret: secret, orderPreview: model.orderPreview, submitWithInitialValues: id && secret && callSubmit, onSubmit: model.onFindOrder, onHideOrderPreview: model.onHideOrderPreview, withBookingPreview: model.isAuthorized })))));
};
export default FindOrder;
