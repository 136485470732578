import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { addExternalResource } from './utils';
import { emitEvent } from '../utils';
import { WebskyEventType } from '../types';
import { useTranslation } from 'react-i18next';
var addResources = function (i18n, resource) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (!i18n) {
                    return [2 /*return*/, ['I18N library is not initialized']];
                }
                if (typeof resource !== 'object') {
                    return [2 /*return*/, ['Locales should only be of `object` type']];
                }
                return [4 /*yield*/, addExternalResource(i18n, resource)];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, [e_1]];
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * Component for support third party (external) translation resources.
 * External js script must subscribe on `WebskyEventType.LanguageChanged` event.
 * It is emitted after i18next was initialized or locale of Websky's engine was changed,
 * and indicated that `__webskyAddLocaleResource` can be used.
 *
 * `__webskyAddLocaleResource` is global function that can pass bundles from external resources to i18next instance.
 * It takes an argument in the following format:
 *
 * If MODULE_NAME is not specified, translation will be placed into default bundle
 *
 * ```
 * 	{
 * 	   [LANG_KEY]: {
 * 	       '[MODULE_NAME]:[KEY]': '[TRANSLATION]'
 * 	   }
 * 	}
 * ```
 */
var ExternalLocaleResources = function () {
    var initEvent = React.useRef(false);
    var i18n = useTranslation().i18n;
    var lang = i18n.language;
    React.useEffect(function () {
        window['__webskyAddLocaleResource'] = function (resource) {
            addResources(i18n, resource).then(function (errors) {
                if (errors.length) {
                    console.log.apply(console, __spreadArray([], __read(__spreadArray(['External locales errors:'], __read(errors), false)), false));
                }
                i18n.changeLanguage(i18n.language);
                console.info("External locales added");
            });
        };
        if (!initEvent.current) {
            emitEvent(document, {
                type: WebskyEventType.LanguageChanged,
                data: i18n.language
            });
            initEvent.current = true;
        }
        return function () {
            initEvent.current = false;
        };
    }, []);
    React.useEffect(function () {
        emitEvent(document, {
            type: WebskyEventType.LanguageChanged,
            data: i18n.language
        });
    }, [lang]);
    return null;
};
export default ExternalLocaleResources;
