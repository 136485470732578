import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateExchangeMutation } from '@websky/graphql';
import CreateRequest from './CreateRequest';
import { getOrder, isAutoMode } from '../../../store/order/selectors';
import { getFlightsToExchange, getSelectedPassengers } from '../../../store/selectors';
import { getNewFlightId, getSelectedFares } from '../../../store/newFlights/selectors';
import { getSelectedFlightsInfo } from '../utils';
import { getSelectedReason } from '../../../store/reason/selectors';
import { Reasons } from '../../../store/types';
import WarningModal from '../../../../../WarningModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useHtmlDescriptionOfError } from '../hooks';
import { getFirstSelectedPassengerRequestFormInitialData } from '../../../store/passengers/selectors';
var hideWarningModalForMessages = ['Exchange operation allowed only in manual mode'];
var CreateRequestWrapper = function (props) {
    var t = useTranslation('Exchange').t;
    var theme = useTheme('Exchange').CreateRequest;
    var order = useSelector(getOrder);
    var passengers = useSelector(getSelectedPassengers);
    var flightToExchange = useSelector(getFlightsToExchange);
    var newFlightId = useSelector(getNewFlightId);
    var selectedFares = useSelector(getSelectedFares);
    var autoMode = useSelector(isAutoMode);
    var selectedReason = useSelector(getSelectedReason);
    var firstSelectedPassengerValues = useSelector(getFirstSelectedPassengerRequestFormInitialData);
    var _a = __read(useState(), 2), error = _a[0], setError = _a[1];
    var _b = __read(useCreateExchangeMutation(), 2), createRequest = _b[0], loading = _b[1].loading;
    var htmlDescriptionOfError = useHtmlDescriptionOfError(error);
    var sendRequest = function (params, openRequest) {
        var _a;
        if (!flightToExchange.flightsToExchange.length) {
            return new Promise(function () { return false; });
        }
        var requestParameters = {
            orderId: order.id,
            passengers: passengers.map(function (passenger) { return parseInt(passenger.id); }),
            email: params['email'],
            phone: params['phone'],
            comment: "".concat(params['comment'] || '').concat(!autoMode ? ".\n".concat(getSelectedFlightsInfo(selectedFares)) : ''),
            name: params['name'],
            segmentGroupId: parseInt(flightToExchange.flightsByIds[0].groupId),
            newFlightId: (_a = props.flightId) !== null && _a !== void 0 ? _a : newFlightId,
            isCompelled: selectedReason === Reasons.Unvoluntary
        };
        return createRequest({
            variables: {
                params: requestParameters
            }
        })
            .then(function (data) {
            if (data.data.CreateExchange) {
                props.onOrderCreated(data.data.CreateExchange);
                return true;
            }
            return false;
        })
            .catch(function (e) {
            var _a, _b;
            if (hideWarningModalForMessages.includes((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message)) {
                props.refreshOrder(true);
            }
            else {
                setError(e);
            }
            return false;
        });
    };
    var closeWarningModal = function () { return setError(null); };
    var getWarningModalContent = function () {
        var _a, _b;
        return (htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.header) ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlDescriptionOfError.header } })) : (((_b = (_a = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) || t('Unknown error'));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(WarningModal, { className: theme.warningModal, isOpen: !!error, onClose: closeWarningModal, title: t('Oops...'), content: getWarningModalContent(), errorMessage: (htmlDescriptionOfError === null || htmlDescriptionOfError === void 0 ? void 0 : htmlDescriptionOfError.body) && (React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlDescriptionOfError.body } })) }),
        React.createElement(CreateRequest, { initialValues: firstSelectedPassengerValues, send: sendRequest, isSending: loading || props.isLoading, autoMode: autoMode })));
};
export default CreateRequestWrapper;
