import { __assign, __awaiter, __generator, __read } from "tslib";
import { useState, useCallback } from 'react';
import { useFindOrderMutation, useFindGroupOrderMutation, useGetUserProfileQuery } from '@websky/graphql';
import { useSecureAccess } from '../../../../SecureAccess/SecureAccess.hook';
import { ApolloError } from 'apollo-boost';
export function useFindOrder(_a) {
    var _this = this;
    var onSuccess = _a.onSuccess;
    var _b = useGetUserProfileQuery({
        fetchPolicy: 'no-cache'
    }), userProfile = _b.data, userProfileLoading = _b.loading, getUserProfile = _b.refetch;
    var _c = __read(useFindOrderMutation(), 2), findOrderMutation = _c[0], findOrderLoading = _c[1].loading;
    var _d = __read(useFindGroupOrderMutation(), 2), findGroupOrderMutation = _d[0], findGroupOrderLoading = _d[1].loading;
    var secureAccess = useSecureAccess();
    var _e = __read(useState(null), 2), orderPreview = _e[0], setOrderPreview = _e[1];
    var _f = __read(useState(null), 2), groupOrderParams = _f[0], setGroupOrderParams = _f[1];
    var _g = __read(useState(null), 2), groupOrderResponse = _g[0], setGroupOrderResponse = _g[1];
    var getIsAuthorizedUser = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!userProfileLoading) {
                        return [2 /*return*/, !!(userProfile === null || userProfile === void 0 ? void 0 : userProfile.CurrentUser)];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getUserProfile()];
                case 2:
                    data = (_b.sent()).data;
                    return [2 /*return*/, !!(data === null || data === void 0 ? void 0 : data.CurrentUser)];
                case 3:
                    _a = _b.sent();
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [userProfile, userProfileLoading, getUserProfile]);
    var onHideOrderPreview = useCallback(function () {
        setOrderPreview(null);
    }, [setOrderPreview]);
    var onFindOrder = useCallback(function (params) { return __awaiter(_this, void 0, void 0, function () {
        var isAuthorized, data, e_1, errors, isGroupOrder, groupOrder;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    onHideOrderPreview();
                    return [4 /*yield*/, getIsAuthorizedUser()];
                case 1:
                    isAuthorized = _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 7]);
                    return [4 /*yield*/, findOrderMutation({
                            variables: {
                                params: __assign(__assign({}, params), { saveInProfile: false })
                            }
                        })];
                case 3:
                    data = (_b.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.FindOrder) {
                        if (isAuthorized) {
                            setOrderPreview(data.FindOrder);
                        }
                        else {
                            onSuccess(data.FindOrder);
                        }
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [3 /*break*/, 7];
                case 4:
                    e_1 = _b.sent();
                    if (!(e_1 instanceof ApolloError && ((_a = e_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length))) return [3 /*break*/, 6];
                    errors = e_1.graphQLErrors;
                    isGroupOrder = errors.some(function (error) {
                        var _a, _b;
                        return (((_a = error === null || error === void 0 ? void 0 : error.debugMessage) === null || _a === void 0 ? void 0 : _a.includes('group order')) ||
                            ((_b = error === null || error === void 0 ? void 0 : error.message) === null || _b === void 0 ? void 0 : _b.includes('group order')));
                    });
                    if (secureAccess.isNeedToOpenSecureAccessModal(errors)) {
                        secureAccess.open();
                        secureAccess.setSubmitParams({ params: { id: params.id, secret: params.secret } });
                    }
                    if (!isGroupOrder) return [3 /*break*/, 6];
                    secureAccess.close();
                    return [4 /*yield*/, onFindGroupOrder({
                            id: params.id,
                            secret: params.secret,
                            travellersDataForSplit: []
                        })];
                case 5:
                    groupOrder = _b.sent();
                    if (groupOrder) {
                        setGroupOrderParams({ id: params.id, secret: params.secret });
                        setGroupOrderResponse(groupOrder);
                        return [2 /*return*/, true];
                    }
                    secureAccess.close();
                    _b.label = 6;
                case 6: return [2 /*return*/, false];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [
        findOrderMutation,
        setGroupOrderParams,
        setGroupOrderResponse,
        getIsAuthorizedUser,
        onHideOrderPreview,
        secureAccess.isNeedToOpenSecureAccessModal,
        secureAccess.setSubmitParams,
        secureAccess.open,
        secureAccess.close
    ]);
    var onFindGroupOrder = useCallback(function (params) { return __awaiter(_this, void 0, void 0, function () {
        var _a, data, errors, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, findGroupOrderMutation({
                            variables: { params: params }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (secureAccess.isNeedToOpenSecureAccessModal(errors)) {
                        secureAccess.setSubmitParams({
                            params: {
                                id: params.id,
                                secret: params.secret,
                                travellersDataForSplit: params.travellersDataForSplit
                            }
                        });
                        secureAccess.open();
                    }
                    if (data === null || data === void 0 ? void 0 : data.FindGroupOrder) {
                        return [2 /*return*/, data.FindGroupOrder];
                    }
                    return [2 /*return*/, null];
                case 2:
                    e_2 = _b.sent();
                    console.log(e_2);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        findGroupOrderMutation,
        secureAccess.isNeedToOpenSecureAccessModal,
        secureAccess.setSubmitParams,
        secureAccess.open
    ]);
    var onAcceptGroupOrder = useCallback(function (travellersDataForSplit) {
        return onFindGroupOrder({
            id: groupOrderParams.id,
            secret: groupOrderParams.secret,
            travellersDataForSplit: travellersDataForSplit
        });
    }, [onFindGroupOrder, groupOrderParams]);
    var onSubmitSecureAccess = useCallback(function (secret2fa) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!secureAccess.isFindGroupOrderVariablesGuard(secureAccess.submitParams)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onFindGroupOrder({
                            id: secureAccess.submitParams.params.id,
                            secret: secureAccess.submitParams.params.secret,
                            travellersDataForSplit: secureAccess.submitParams.params.travellersDataForSplit
                            // secret2fa
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onFindOrder({
                        id: secureAccess.submitParams.params.id,
                        secret: secureAccess.submitParams.params.secret,
                        secret2fa: secret2fa
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    secureAccess.setShowError(true);
                    return [2 /*return*/];
            }
        });
    }); }, [onFindOrder, onFindGroupOrder, secureAccess]);
    var onResendSecureAccess = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!secureAccess.isFindGroupOrderVariablesGuard(secureAccess.submitParams)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onFindGroupOrder({
                            id: secureAccess.submitParams.params.id,
                            secret: secureAccess.submitParams.params.secret,
                            travellersDataForSplit: secureAccess.submitParams.params.travellersDataForSplit
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onFindOrder({
                        id: secureAccess.submitParams.params.id,
                        secret: secureAccess.submitParams.params.secret
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onFindOrder, onFindGroupOrder, secureAccess]);
    return {
        // data
        orderPreview: orderPreview,
        secureAccess: secureAccess,
        groupOrderParams: groupOrderParams,
        groupOrderResponse: groupOrderResponse,
        isLoading: findOrderLoading || findGroupOrderLoading,
        isAuthorized: !!(userProfile === null || userProfile === void 0 ? void 0 : userProfile.CurrentUser),
        // methods
        setGroupOrderParams: setGroupOrderParams,
        setGroupOrderResponse: setGroupOrderResponse,
        onFindOrder: onFindOrder,
        onFindGroupOrder: onFindGroupOrder,
        onAcceptGroupOrder: onAcceptGroupOrder,
        onSubmitSecureAccess: onSubmitSecureAccess,
        onResendSecureAccess: onResendSecureAccess,
        onHideOrderPreview: onHideOrderPreview
    };
}
