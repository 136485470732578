var _a;
import { handleAction, handleActions } from 'redux-actions';
import { CLEAR_PROMO_CODE, clearPromoCode, SET_PROMO_CODE, setPromoCode } from './actions';
export var defaultState = '';
export var setPromoCodeHandler = handleAction(setPromoCode, function (state, action) { return action.payload; }, defaultState);
export var clearPromoCodeHandler = handleAction(clearPromoCode, function (state, action) { return ''; }, defaultState);
export default handleActions((_a = {},
    _a[SET_PROMO_CODE] = setPromoCodeHandler,
    _a[CLEAR_PROMO_CODE] = clearPromoCodeHandler,
    _a), defaultState);
