import * as React from 'react';
import { ActionButton } from '../../../../Button';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var SubmitButton = function (_a) {
    var _b;
    var loading = _a.loading, children = _a.children;
    var css = useTheme('LoginPage').SubmitButton;
    return (React.createElement(ActionButton, { disabled: loading, type: 'submit', className: cn(css.root, (_b = {},
            _b[css.loading] = loading,
            _b)) },
        React.createElement("span", { className: css.content }, children)));
};
export default SubmitButton;
