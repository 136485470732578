import * as React from 'react';
import { useContext } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PriceBlock from './PriceBlock/PriceBlock';
import { getDisabled, getPrice } from '../../store/selector';
import { BaggageContext } from '../../../context';
import { BaseButton } from '../../../../index';
var BaggageFooter = function (_a) {
    var _b;
    var onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var theme = useTheme('UpgradeBaggageDialog').BaggageFooter;
    var isReadOnly = (_b = useContext(BaggageContext)) === null || _b === void 0 ? void 0 : _b.isReadOnly;
    var t = useTranslation('IncludedBaggage').t;
    var disabled = useSelector(getDisabled) || isReadOnly;
    var price = useSelector(getPrice);
    return (React.createElement("div", { className: theme.root },
        React.createElement("div", { className: theme.row },
            price && React.createElement(PriceBlock, { price: price, hideClear: isReadOnly }),
            React.createElement("div", { className: theme.buttons_row },
                React.createElement(BaseButton, { variant: "smallSecondary", className: theme.cancel_btn, onClick: onCancel }, t('Cancel')),
                !isReadOnly && (React.createElement(BaseButton, { variant: "smallThird", className: theme.confirm_btn, onClick: onConfirm, disabled: disabled }, t('Confirm')))))));
};
export default BaggageFooter;
