import memes from '../memes';
export var getTemperaturePrefix = memes(function (temperature) {
    if (Math.round(temperature) > 0) {
        return '+';
    }
    else if (Math.round(temperature) < 0) {
        return '-';
    }
    return '';
});
