import * as Button from './Button.css';
import * as BaseButton from './components/BaseButton/BaseButton.css';
import * as ActionButton from './components/ActionButton/ActionButton.module.css';
import * as WhiteButton from './components/WhiteButton/WhiteButton.css';
import * as ModalButton from './components/ModalButton/ModalButton.css';
export default {
    Button: Button,
    ActionButton: ActionButton,
    WhiteButton: WhiteButton,
    BaseButton: BaseButton,
    ModalButton: ModalButton
};
