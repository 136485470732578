import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Button from '../../../BaseComponents/Button';
import { Money } from '../../../Money';
var AiPredictionItemWrapper = function (_a) {
    var headerTitle = _a.headerTitle, buttonTitle = _a.buttonTitle, service = _a.service, onChange = _a.onChange, onDisagree = _a.onDisagree, onAgree = _a.onAgree;
    var theme = useTheme('AIPurchasePredictionService').AiPredictionItemWrapper;
    var t = useTranslation('AIPurchasePredictionService').t;
    return (React.createElement("div", { className: theme.predictionItem },
        React.createElement("div", { className: theme.predictionItem__header }, headerTitle),
        React.createElement("div", { className: theme.predictionCard },
            service.renderHead(),
            React.createElement("div", { className: theme.predictionCard__content },
                React.createElement("div", { className: theme.predictionCard__desc },
                    React.createElement("div", { className: theme.predictionCard__descLabel }, service.name),
                    service.renderDesc && service.renderDesc(),
                    React.createElement("span", null, t('All flights'))),
                React.createElement("div", { className: theme.predictionCard__cost },
                    React.createElement(Money, { money: service.price, moneyClassName: theme.predictionCard__price }),
                    React.createElement(Button, { variant: 'optionEdit', onClick: onChange }, t('Change'))))),
        React.createElement("div", { className: theme.predictionItem__footer },
            React.createElement(Button, { variant: 'secondary', className: theme.predictionItem__btn, onClick: onDisagree }, buttonTitle),
            React.createElement(Button, { variant: 'action', className: theme.predictionItem__btn, onClick: onAgree }, t('Add and pay')))));
};
export default AiPredictionItemWrapper;
