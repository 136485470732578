import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExchangeStep } from '../../../types';
import { passengerIcon } from '../icons';
import { Reasons as ReasonsType } from '../../../store/types';
import CommonSelect from '../CommonSelect/CommonSelect';
import { setReason } from '../../../store/reason/actions';
import { getSelectedReason } from '../../../store/reason/selectors';
import { getOrder, getPassengers } from '../../../store/order/selectors';
var Reasons = function (_a) {
    var setStep = _a.setStep, passengersPageURL = _a.passengersPageURL;
    var t = useTranslation('Exchange').t;
    var dispatch = useDispatch();
    var history = useHistory();
    var order = useSelector(getOrder);
    var selectedReason = useSelector(getSelectedReason);
    var passengers = useSelector(getPassengers);
    var reasons = [
        { value: t('Voluntary change'), key: ReasonsType.Voluntary },
        { value: t('Involuntary change'), key: ReasonsType.Unvoluntary }
    ];
    var onChangeHandler = function (selectedValue) {
        dispatch(setReason(selectedValue.key));
    };
    var onNextHandler = function () {
        setStep(ExchangeStep.Segments);
    };
    // WEBSKY-2664: only Voluntary, skip this page
    React.useEffect(function () {
        dispatch(setReason(ReasonsType.Voluntary));
        onNextHandler();
    }, [reasons]);
    var onBackHandler = useCallback(function () {
        var _a;
        var isOnlyPassenger = passengers.length === 1;
        if (((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) && isOnlyPassenger) {
            setStep(ExchangeStep.Request);
        }
        else if (isOnlyPassenger) {
            history.push(passengersPageURL);
        }
        else {
            history.goBack();
        }
    }, [order, passengers, history, passengersPageURL]);
    return (React.createElement(CommonSelect, { name: t('Select type of change'), items: reasons, onChange: onChangeHandler, onNextHandler: onNextHandler, selectedKeys: [{ key: selectedReason }], onBackHandler: onBackHandler, icon: passengerIcon, controlType: "radio" }));
};
export default Reasons;
