import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import ModalConfirm from '../../../ModalConfirm';
var CancelModal = function (_a) {
    var passengerName = _a.passengerName, linkedPassengerNames = _a.linkedPassengerNames, isOpen = _a.isOpen, onClose = _a.onClose, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var t = useTranslation('RegisteredPassenger').t;
    var theme = useTheme('RegisteredPassenger').CancelModal;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var onConfirmHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onConfirm()];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ModalConfirm, { isOpen: isOpen, isLoading: isLoading, onClose: onClose, onConfirm: onConfirmHandler, onCancel: onCancel, title: t('You are going to cancel online check-in for passenger {{passenger}}. Are you sure about this?', {
            passenger: passengerName
        }), classes: { title: theme.title } },
        React.createElement("div", { className: theme.content }, (linkedPassengerNames === null || linkedPassengerNames === void 0 ? void 0 : linkedPassengerNames.length) > 0 && (React.createElement("div", { className: cn(theme.text, theme.linked) },
            t('Please notice that online check-in will also be cancelled for the following passengers'),
            React.createElement("ul", null, linkedPassengerNames.map(function (name) { return (React.createElement("li", { key: name }, name === null || name === void 0 ? void 0 : name.toLowerCase())); })))))));
};
export default CancelModal;
