var _a;
import { handleAction, handleActions } from 'redux-actions';
import { selectReason } from './actions';
var initialState = null;
var handleSelectReason = handleAction(selectReason, function (state, _a) {
    var payload = _a.payload;
    return payload;
}, initialState);
export default handleActions((_a = {},
    _a[selectReason.toString()] = handleSelectReason,
    _a), initialState);
