import * as PassengersWithoutSubscription from './components/PassengersWithoutSubscription/PassengersWithoutSubscription.css';
import * as ResultsSubscriptionsBanner from './components/ResultsSubscriptionsBanner/ResultsSubscriptionsBanner.css';
import * as FareGroupSubscriptionsInfo from './components/FareGroupSubscriptionsInfo/FareGroupSubscriptionsInfo.css';
import * as FareGroupSubscriptionsServices from './components/FareGroupSubscriptionsServices/FareGroupSubscriptionsServices.css';
import * as TravellerFormSubscriptionTag from './components/TravellerFormSubscriptionTag/TravellerFormSubscriptionTag.css';
import * as Subscriptions from './components/Subscriptions/Subscriptions.css';
import * as SubscriptionInfo from './components/Subscriptions/SubscriptionInfo/SubscriptionInfo.css';
import * as SubscriptionDetail from './components/Subscriptions/SubscriptionInfo/SubscriptionDetail/SubscriptionDetail.css';
import * as Members from './components/Subscriptions/Members/Members.css';
import * as MemberItem from './components/Subscriptions/Members/MemberItem/MemberItem.css';
import * as MemberItemAdd from './components/Subscriptions/Members/MemberItemAdd/MemberItemAdd.css';
import * as AddNewMemberForm from './components/Subscriptions/Members/forms/AddNewMemberForm/AddNewMemberForm.css';
import * as AddSelectedMemberForm from './components/Subscriptions/Members/forms/AddSelectedMemberForm/AddSelectedMemberForm.css';
import * as AddMember from './components/Subscriptions/Members/AddMember/AddMember.css';
import * as Submit from './components/Subscriptions/Members/forms/components/Submit/Submit.css';
import * as AddMemberFields from './components/Subscriptions/Members/forms/components/AddMemberFields/AddMemberFields.css';
import * as TravelCompanionFields from './components/Subscriptions/Members/forms/components/TravelCompanionFields/TravelCompanionFields.css';
import * as QuestionsAnswers from './components/Subscriptions/QuestionsAnswers/QuestionsAnswers.css';
import * as QuestionAnswerCard from './components/Subscriptions/QuestionsAnswers/QuestionAnswerCard/QuestionAnswerCard.css';
import * as Payments from './components/Subscriptions/Payments/Payments.css';
import * as HelpfulInformation from './components/Subscriptions/HelpfulInformation/HelpfulInformation.css';
export default {
    PassengersWithoutSubscription: PassengersWithoutSubscription,
    ResultsSubscriptionsBanner: ResultsSubscriptionsBanner,
    FareGroupSubscriptionsInfo: FareGroupSubscriptionsInfo,
    FareGroupSubscriptionsServices: FareGroupSubscriptionsServices,
    TravellerFormSubscriptionTag: TravellerFormSubscriptionTag,
    Subscriptions: Subscriptions,
    SubscriptionInfo: SubscriptionInfo,
    SubscriptionDetail: SubscriptionDetail,
    Members: Members,
    MemberItem: MemberItem,
    MemberItemAdd: MemberItemAdd,
    AddNewMemberForm: AddNewMemberForm,
    AddSelectedMemberForm: AddSelectedMemberForm,
    AddMember: AddMember,
    Submit: Submit,
    AddMemberFields: AddMemberFields,
    TravelCompanionFields: TravelCompanionFields,
    QuestionsAnswers: QuestionsAnswers,
    QuestionAnswerCard: QuestionAnswerCard,
    Payments: Payments,
    HelpfulInformation: HelpfulInformation
};
