import { __assign, __read, __spreadArray } from "tslib";
import { useMemo, useReducer } from 'react';
import { parse } from 'date-fns';
import { API_DATE_FORMAT, format, getTotalPrice } from '../../../utils';
var reducer = function (state, action) {
    switch (action.type) {
        case 'change':
            var changeState_1 = __assign({}, state);
            action.payload.segmentIds.forEach(function (segmentId) {
                var _a;
                changeState_1[segmentId] = __assign(__assign({}, changeState_1[segmentId]), (_a = {}, _a[action.payload.travellerId] = action.payload.serviceId, _a));
            });
            return changeState_1;
        case 'clear':
            var clearState = __assign({}, state);
            for (var segmentId in clearState) {
                for (var passengerId in clearState[segmentId]) {
                    clearState[segmentId][passengerId] = null;
                }
            }
            return clearState;
        default:
            return state;
    }
};
export var useAnimalState = function (services, segments, travellers) {
    var initialState = useMemo(function () {
        var state = {};
        segments.forEach(function (segment) {
            var segmentId = segment.id;
            services.forEach(function (service) {
                var allowedSegmentsSet = new Set(service.allowedSegments.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
                if (allowedSegmentsSet.has(segmentId)) {
                    travellers.forEach(function (traveller) {
                        var _a;
                        var _b, _c, _d, _e;
                        var selectedService = (_d = (_c = (_b = traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.find(function (selectedService) {
                            return selectedService.serviceId === service.id &&
                                selectedService.segmentIds.includes(segmentId);
                        });
                        state[segmentId] = __assign(__assign({}, state[segmentId]), (_a = {}, _a[traveller.id] = (_e = selectedService === null || selectedService === void 0 ? void 0 : selectedService.serviceId) !== null && _e !== void 0 ? _e : null, _a));
                    });
                }
            });
        });
        return state;
    }, [services, segments, travellers]);
    var _a = __read(useReducer(reducer, initialState), 2), state = _a[0], dispatch = _a[1];
    var servicesBySegment = useMemo(function () {
        var servicesMap = new Map();
        segments.forEach(function (segment) {
            var segmentId = segment.id;
            services.forEach(function (service) {
                var allowedSegmentMap = new Map(service.allowedSegments.map(function (ids) { return [ids[0], ids]; }));
                if (allowedSegmentMap.has(segmentId)) {
                    var allowedSegments = allowedSegmentMap.get(segmentId);
                    var direction = "".concat(segment.departure.airport.city.name, " \u2013 ").concat(segment.arrival.airport.city.name);
                    var date = format(parse(segment.departure.date, API_DATE_FORMAT, new Date()), 'dd MMM');
                    if (allowedSegments.length > 1) {
                        var firstSegmentId_1 = allowedSegments[0];
                        var lastSegmentId_1 = allowedSegments[allowedSegments.length - 1];
                        var firstSegment = segments.find(function (segment) { return segment.id === firstSegmentId_1; });
                        var lastSegment = segments.find(function (segment) { return segment.id === lastSegmentId_1; });
                        direction = "".concat(firstSegment.departure.airport.city.name, " \u2013 ").concat(lastSegment.arrival.airport.city.name);
                        date = format(parse(firstSegment.departure.date, API_DATE_FORMAT, new Date()), 'dd MMM');
                    }
                    servicesMap.set(direction, {
                        service: service,
                        segmentId: segmentId,
                        date: date,
                        segmentIds: allowedSegments,
                        serviceId: service.id,
                        price: service.price
                    });
                }
            });
        });
        return __spreadArray([], __read(servicesMap.entries()), false);
    }, [services, segments, travellers]);
    var _b = __read(useMemo(function () {
        var _a;
        var selectedServices = [];
        var servicesToSave = [];
        var _loop_1 = function (segmentId) {
            var _loop_2 = function (passengerId) {
                var serviceId = state[segmentId][passengerId];
                var initialServiceId = initialState[segmentId][passengerId];
                var actualServiceId = serviceId !== null && serviceId !== void 0 ? serviceId : initialServiceId;
                var service = (_a = servicesBySegment.find(function (_a) {
                    var _b = __read(_a, 2), _ = _b[0], service = _b[1];
                    return service.segmentId === segmentId && service.serviceId === actualServiceId;
                })) === null || _a === void 0 ? void 0 : _a[1];
                if (service) {
                    var selectedService = {
                        serviceId: actualServiceId,
                        segmentId: segmentId,
                        passengerId: passengerId,
                        service: service.service,
                        segmentIds: service.segmentIds,
                        count: !!serviceId ? 1 : 0
                    };
                    if (serviceId !== initialServiceId) {
                        servicesToSave = __spreadArray(__spreadArray([], __read(servicesToSave), false), [selectedService], false);
                    }
                    else if (serviceId) {
                        selectedServices = __spreadArray(__spreadArray([], __read(selectedServices), false), [selectedService], false);
                    }
                }
            };
            for (var passengerId in state[segmentId]) {
                _loop_2(passengerId);
            }
        };
        for (var segmentId in state) {
            _loop_1(segmentId);
        }
        return [selectedServices, servicesToSave];
    }, [initialState, state, segments, servicesBySegment]), 2), selectedServices = _b[0], servicesToSave = _b[1];
    var hasChanges = useMemo(function () { return JSON.stringify(initialState) !== JSON.stringify(state); }, [initialState, state]);
    var totalPrice = useMemo(function () {
        var filterServices = __spreadArray(__spreadArray([], __read(selectedServices), false), __read(servicesToSave), false).filter(function (service) { return service.count > 0; });
        if (filterServices.length) {
            return getTotalPrice(filterServices, function (service) { return (__assign(__assign({}, service.service.price), { amount: service.service.price.amount * service.segmentIds.length })); });
        }
        return null;
    }, [selectedServices, servicesToSave]);
    var onChange = function (payload) {
        dispatch({ type: 'change', payload: payload });
    };
    var onClear = function () {
        dispatch({ type: 'clear' });
    };
    return {
        state: state,
        servicesBySegment: servicesBySegment,
        selectedServices: selectedServices,
        servicesToSave: servicesToSave,
        hasChanges: hasChanges,
        totalPrice: totalPrice,
        onChange: onChange,
        onClear: onClear
    };
};
