import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { closeIcon } from '../icons';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer/SwipeableDrawer';
import ModalTransitionComponent from '../../ModalTransitionComponent';
var Modal = function (_a) {
    var _b, _c;
    var closeClassName = _a.closeClassName, isRounded = _a.isRounded, closeButtonRenderer = _a.closeButtonRenderer, slideUp = _a.slideUp, bottomDrawer = _a.bottomDrawer, bottomDrawerClassName = _a.bottomDrawerClassName, props = __rest(_a, ["closeClassName", "isRounded", "closeButtonRenderer", "slideUp", "bottomDrawer", "bottomDrawerClassName"]);
    var _d = __read(useState(!props.mountOnEnter), 2), dialogIsMounted = _d[0], setDialogIsMounted = _d[1];
    var css = useTheme('Modal').Modal;
    var defaultProps = {
        scroll: 'body',
        maxWidth: 'lg'
    };
    if (slideUp) {
        defaultProps.TransitionComponent = ModalTransitionComponent;
    }
    useEffect(function () {
        if (props.open && !dialogIsMounted) {
            setDialogIsMounted(true);
        }
    }, [props.open, dialogIsMounted]);
    var dialogClasses = props.classes ? props.classes : {};
    var renderContent = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            props.extraTitle && React.createElement("div", { className: cn(css.extraTitle) }, props.extraTitle),
            props.onClose && !closeButtonRenderer && (React.createElement("span", { className: cn(css.closeIcon, closeClassName, (_a = {},
                    _a[css.closeIcon_mobileFullScreen] = props.fullScreen,
                    _a)), onClick: props.onClose }, closeIcon)),
            props.onClose && closeButtonRenderer && closeButtonRenderer(props.onClose),
            dialogIsMounted && props.children));
    };
    if (bottomDrawer) {
        return (React.createElement(SwipeableDrawer, { onClose: props.onClose, onOpen: function () { }, open: props.open, anchor: "bottom", classes: __assign(__assign({}, dialogClasses), { paper: cn(css.modal, css.modal_bottom, (_b = {}, _b[css.modal_mobileFullScreen] = props.fullScreen, _b[css.modal_rounded] = isRounded, _b), dialogClasses.paper, bottomDrawerClassName) }) }, renderContent()));
    }
    return (React.createElement(Dialog, __assign({}, defaultProps, props, { classes: __assign(__assign({}, dialogClasses), { paper: cn(css.modal, (_c = {}, _c[css.modal_mobileFullScreen] = props.fullScreen, _c[css.modal_rounded] = isRounded, _c), dialogClasses.paper), scrollBody: cn(css.scrollBody, dialogClasses.scrollBody) }) }), renderContent()));
};
export default Modal;
