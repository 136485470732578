import { __read } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchangeType } from '../../../store/exchangeType/selectors';
import SearchResultsAdapter from '../SearchResultsAdapter/SearchResultsAdapter';
import { getFlightsToExchange, getNewFlightSearchParams, getSelectedPassengers } from '../../../store/selectors';
import { addFare, clearFare } from '../../../store/newFlights/actions';
import SearchFlightsComponent from '../SearchFlights/SearchFlights';
import { getOrder } from '../../../store/order/selectors';
import { ExchangeStep } from '../../../types';
import { getSelectedReason } from '../../../store/reason/selectors';
import { Reasons } from '../../../store/types';
var SearchFlights = function (props) {
    var dispatch = useDispatch();
    var exchangeType = useSelector(getExchangeType);
    var searchParams = useSelector(getNewFlightSearchParams);
    var order = useSelector(getOrder);
    var passengers = useSelector(getSelectedPassengers);
    var flightToExchange = useSelector(getFlightsToExchange);
    var selectedReason = useSelector(getSelectedReason);
    var _a = __read(React.useState(searchParams), 2), searchParamsState = _a[0], setSearchParams = _a[1];
    React.useEffect(function () {
        setSearchParams(searchParams);
    }, [searchParams]);
    var onSelectFare = function (flight, leg, legsCount) {
        dispatch(addFare(flight));
        if (leg === legsCount - 1) {
            props.setStep(ExchangeStep.Request);
        }
    };
    var exchangeParams = React.useMemo(function () {
        return {
            orderId: order.id,
            passengers: passengers.map(function (passenger) { return parseInt(passenger.id); }),
            segmentParams: flightToExchange.flightsByIds.map(function (flight, key) { return ({
                segmentGroupId: flight.groupId,
                date: searchParamsState.segments[key].date
            }); }),
            isCompelled: selectedReason === Reasons.Unvoluntary
        };
    }, [order, passengers, flightToExchange, searchParamsState]);
    var onClearFares = function () { return dispatch(clearFare()); };
    return (React.createElement(SearchResultsAdapter, { exchangeType: exchangeType, exchangeParams: exchangeParams, onSelect: onSelectFare, setSearchParams: setSearchParams, setLoading: props.setLoading, clearFares: onClearFares }, function (data, onSelect) {
        var _a, _b, _c, _d, _e, _f;
        return (React.createElement(SearchFlightsComponent, { legs: (_b = (_a = data.results) === null || _a === void 0 ? void 0 : _a.GetExchangeVariants) === null || _b === void 0 ? void 0 : _b.flightDirections[data.currentLeg].legs, loading: data.loading, currentLeg: data.currentLeg, onFareSelect: onSelect, searchParams: searchParamsState, startNewSearch: function (searchParameters) {
                setSearchParams(searchParameters);
            }, resultsSearchParams: (_d = (_c = data.results) === null || _c === void 0 ? void 0 : _c.GetExchangeVariants) === null || _d === void 0 ? void 0 : _d.searchParameters, prices: (_f = (_e = data.results) === null || _e === void 0 ? void 0 : _e.GetExchangeVariants) === null || _f === void 0 ? void 0 : _f.exchangeVariantPriceInfo, clearFares: data.clearFares, error: data.error }));
    }));
};
export default SearchFlights;
