import Schedule from './components/Schedule/Schedule.css';
import Results from './components/Results/Results.css';
import ScheduleFlight from './components/ScheduleFlight/ScheduleFlight.css';
import WeekDays from './components/WeekDays/WeekDays.css';
import Weekpicker from './components/Weekpicker/Weekpicker.css';
import DateSwitcher from './components/DateSwitcher/DateSwitcher.css';
import Locations from './components/Locations/Locations.css';
import Location from './components/Location/Location.css';
export default {
    Schedule: Schedule,
    Results: Results,
    ScheduleFlight: ScheduleFlight,
    WeekDays: WeekDays,
    Weekpicker: Weekpicker,
    DateSwitcher: DateSwitcher,
    Locations: Locations,
    Location: Location
};
