import { __read } from "tslib";
import { useState } from 'react';
import { initialStateFactory } from './initialStateFactory';
import { useMethods } from './methods';
import { useVariables } from './variables';
export * from './types';
export var useTransferSelector = function (service, parentState) {
    var _a = __read(useState(initialStateFactory(service, parentState)), 2), state = _a[0], setState = _a[1];
    var methods = useMethods(state, setState, service, parentState);
    var variables = useVariables(state, service);
    return {
        state: state,
        setState: setState,
        toggleService: methods.onToggle,
        restoreState: methods.restoreState,
        removeTransfer: methods.removeTransfer,
        totalPrice: variables.totalPrice,
        minPrice: variables.minPrice
    };
};
