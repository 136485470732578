import React, { useMemo } from 'react';
import { TravellerFieldEnum, InfoMessageTypeEnum } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { useSelector } from 'react-redux';
import { getTravellers, getTravellersValueNames } from '../../../store/order/selectors';
import { getAirticketPriceToPay, getTotalPrice } from '../../../store/orderPrice/selectors';
import { getTotalDiscount } from '../../../utils';
import cn from 'classnames';
import { InfoDetails } from '../../../../Icons';
import Tooltip from '../../../../Tooltip';
import Notification from '../../../../Notification';
import { ActionButton } from '../../../../Button';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import MobileHeader from '../../../../MobileHeader';
import MobileToolbar from '../../../../MobileToolbar';
import Modal from '../../../../Modal';
import { getTravellerItemData } from './utils';
var PromoCodes = function (_a) {
    var isOpen = _a.isOpen, onSubmit = _a.onSubmit, onCancel = _a.onCancel, promoCodesInfo = _a.promoCodesInfo, formValues = _a.formValues, error = _a.error;
    if (!isOpen) {
        return null;
    }
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').PromoCodes;
    var buttonCss = useTheme('Button').ActionButton;
    var travellersMap = useSelector(getTravellersValueNames);
    var travellers = useSelector(getTravellers);
    var _b = useMemo(function () {
        return {
            firstName: travellersMap.get(TravellerFieldEnum.FirstName),
            lastName: travellersMap.get(TravellerFieldEnum.LastName),
            promoCode: travellersMap.get(TravellerFieldEnum.PromoCode)
        };
    }, [travellersMap]), firstName = _b.firstName, lastName = _b.lastName, promoCode = _b.promoCode;
    var totalDiscount = getTotalDiscount(promoCodesInfo);
    var flightPrice = useSelector(getAirticketPriceToPay);
    var totalPrice = useSelector(getTotalPrice);
    var flightPriceWithDiscount = {
        amount: flightPrice.amount - totalDiscount,
        currency: totalPrice.currency
    };
    var totalPriceWithDiscount = {
        amount: totalPrice.amount - totalDiscount,
        currency: totalPrice.currency
    };
    var addServicesPrice = {
        amount: totalPriceWithDiscount.amount - flightPriceWithDiscount.amount,
        currency: totalPrice.currency
    };
    var PassengersRender = function (_a) {
        var children = _a.children;
        return (React.createElement(React.Fragment, null, promoCodesInfo &&
            formValues &&
            travellers.map(function (traveller) {
                var travellerFormValues = formValues['passengers'][traveller.id];
                var _a = getTravellerItemData(traveller, promoCodesInfo, error), hasError = _a.hasError, origPrice = _a.origPrice, discountPrice = _a.discountPrice;
                return children({ traveller: traveller, travellerFormValues: travellerFormValues, hasError: hasError, origPrice: origPrice, discountPrice: discountPrice });
            })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: { paper: css.modal }, fullWidth: true, maxWidth: 'md', open: true, isRounded: true, onClose: onCancel },
                React.createElement("div", { className: css.container },
                    React.createElement("div", { className: css.header }, t('Promo code')),
                    React.createElement("ul", { className: css.table },
                        React.createElement("li", { className: css.tableHeader },
                            React.createElement("span", { className: css.passengerColumnName }, t('Passenger')),
                            React.createElement("span", { className: css.promoCodeColumnName }, t('Promo code')),
                            React.createElement("span", { className: css.priceColumnName }, t('Original price')),
                            React.createElement("span", { className: css.priceColumnName }, t('Final price'))),
                        React.createElement(PassengersRender, null, function (props) {
                            var _a;
                            return (React.createElement("li", { className: cn(css.tableRow, (_a = {}, _a[css.error] = props.hasError, _a)), key: props.traveller.id },
                                React.createElement("span", { className: css.passengerColumn }, "".concat(props.travellerFormValues[firstName], " ").concat(props.travellerFormValues[lastName])),
                                React.createElement("div", { className: css.promoCodeColumn },
                                    React.createElement("span", null, props.travellerFormValues[promoCode]),
                                    props.hasError && (React.createElement(React.Fragment, null,
                                        React.createElement("span", { className: css.errorText }, t('Error')),
                                        React.createElement(Tooltip, { title: t('Check if the promo code is entered correctly or contact the call center of the airline') },
                                            React.createElement("span", { className: css.promoCodeIcon }, InfoDetails))))),
                                React.createElement(Money, { moneyClassName: css.priceColumn, money: props.origPrice }),
                                React.createElement(Money, { moneyClassName: css.priceColumn, money: props.discountPrice })));
                        })),
                    !!error && (React.createElement(Notification, { className: css.notification, titleClassName: css.notificationTitle, status: InfoMessageTypeEnum.Warning, type: 'context', title: t('Promo codes not available'), infoMessage: {
                            type: InfoMessageTypeEnum.Warning,
                            code: null,
                            title: null,
                            text: t('An error occurred while checking the promo code, change the promo code or continue without it')
                        } })),
                    React.createElement("div", { className: css.total },
                        React.createElement("div", { className: css.totalPriceContainer },
                            totalDiscount > 0 && (React.createElement(Money, { moneyClassName: css.totalPrice, money: promoCodesInfo.initialOrderPrice })),
                            React.createElement("span", { className: css.totalPriceWithDiscountLabel },
                                t('Total price'),
                                ":"),
                            React.createElement(Money, { moneyClassName: css.totalPriceWithDiscount, money: totalPriceWithDiscount }),
                            React.createElement("span", { className: css.flightPriceLabel },
                                "- ",
                                t('Flight')),
                            React.createElement(Money, { moneyClassName: css.flightPrice, money: flightPriceWithDiscount }),
                            React.createElement("span", { className: css.addServicesPriceLabel },
                                "- ",
                                t('Add services')),
                            React.createElement(Money, { moneyClassName: css.addServicesPrice, money: addServicesPrice })),
                        React.createElement("div", { className: css.buttons },
                            ((promoCodesInfo === null || promoCodesInfo === void 0 ? void 0 : promoCodesInfo.success) === false || !!error) && (React.createElement(ActionButton, { className: css.cancel, onClick: onCancel }, t('Correct promo codes'))),
                            React.createElement(ActionButton, { className: css.submit, onClick: onSubmit }, t('Continue'))))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: { paper: css.modal }, closeClassName: css.close, open: true, isRounded: true, onClose: onCancel },
                React.createElement(MobileHeader, { onBack: onCancel, classes: { header: css.header } }, t('Promo code')),
                React.createElement("ul", { className: css.table },
                    React.createElement(PassengersRender, null, function (props) {
                        var _a;
                        return (React.createElement("li", { className: cn(css.tableRow, (_a = {}, _a[css.error] = props.hasError, _a)), key: props.traveller.id },
                            React.createElement("span", { className: css.passengerColumn }, "".concat(props.travellerFormValues[firstName], " ").concat(props.travellerFormValues[lastName])),
                            React.createElement("div", { className: css.promoCodeColumn },
                                React.createElement("span", { className: css.promoCodeColumnName },
                                    t('Promo code'),
                                    ":"),
                                React.createElement("span", { className: css.promoCodeValue }, props.travellerFormValues[promoCode]),
                                props.hasError && (React.createElement(React.Fragment, null,
                                    React.createElement("span", { className: css.errorText }, t('Error')),
                                    React.createElement(Tooltip, { enterTouchDelay: 0, leaveTouchDelay: 0, title: t('Check if the promo code is entered correctly or contact the call center of the airline') },
                                        React.createElement("span", { className: css.promoCodeIcon }, InfoDetails))))),
                            React.createElement("div", { className: css.priceColumn },
                                React.createElement("span", { className: css.priceColumnName },
                                    t('Original price'),
                                    ":"),
                                React.createElement(Money, { money: props.origPrice })),
                            React.createElement("div", { className: css.priceColumn },
                                React.createElement("span", { className: css.priceColumnName },
                                    t('Final price'),
                                    ":"),
                                React.createElement(Money, { money: props.discountPrice }))));
                    })),
                !!error && (React.createElement(Notification, { className: css.notification, titleClassName: css.notificationTitle, status: InfoMessageTypeEnum.Warning, type: 'context', title: t('Promo codes not available'), infoMessage: {
                        type: InfoMessageTypeEnum.Warning,
                        code: null,
                        title: null,
                        text: t('An error occurred while checking the promo code, change the promo code or continue without it')
                    } })),
                React.createElement("div", { className: css.total },
                    totalDiscount > 0 && (React.createElement(Money, { moneyClassName: css.totalPrice, money: promoCodesInfo.initialOrderPrice })),
                    React.createElement("div", { className: css.totalPriceWithDiscountWrapper },
                        React.createElement("span", { className: css.totalPriceWithDiscountLabel },
                            t('Total price'),
                            ":"),
                        React.createElement(Money, { moneyClassName: css.totalPriceWithDiscount, money: totalPriceWithDiscount })),
                    React.createElement("div", { className: css.flightPriceWrapper },
                        React.createElement("span", { className: css.flightPriceLabel },
                            t('Flight'),
                            ":"),
                        React.createElement(Money, { moneyClassName: css.flightPrice, money: flightPriceWithDiscount })),
                    React.createElement("div", { className: css.addServicesPriceWrapper },
                        React.createElement("span", { className: css.addServicesPriceLabel },
                            t('Add services'),
                            ":"),
                        React.createElement(Money, { moneyClassName: css.addServicesPrice, money: addServicesPrice }))),
                React.createElement(MobileToolbar, { className: css.toolbar, classes: { continueButton: cn(buttonCss.button, css.toolbarContinueButton) }, onContinue: onSubmit })))));
};
export default PromoCodes;
