import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { InfoDetails } from '../../Icons';
import { useTransitTooltip } from '../hooks';
import Tooltip from '../../Tooltip';
import { useConfig } from '../../context';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { ClickAwayListener } from '@mui/material';
var TransitTooltip = function () {
    var t = useTranslation('FlightPrice').t;
    var css = useTheme('TransitTooltip').TransitTooltip;
    var transferM2ConditionURL = useConfig().global.companyInfo.transferM2ConditionURL;
    var _a = useTransitTooltip(), openTransitTooltip = _a.open, isTransitTooltipOpen = _a.isOpen, closeTransitTooltip = _a.close;
    return (React.createElement(Tooltip, { open: isTransitTooltipOpen, titleClassName: css.transitTooltip__title, popperClassName: css.transitTooltip, title: React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, onMouseOver: openTransitTooltip, onMouseLeave: closeTransitTooltip },
            React.createElement("div", null, t('Special conditions for flight delays and ticket refunds')),
            transferM2ConditionURL && (React.createElement("a", { href: transferM2ConditionURL, onClick: function (e) { return e.stopPropagation(); }, target: "_blank" },
                React.createElement("div", null, t('Full conditions'))))) },
        React.createElement("div", null,
            React.createElement(MediaQuery, { minWidth: 'tablet' },
                React.createElement("a", { href: transferM2ConditionURL || null, onClick: function (e) { return e.stopPropagation(); }, target: "_blank", className: css.transitTooltip__text, onMouseOver: openTransitTooltip, onMouseLeave: closeTransitTooltip },
                    t('Transit tariff'),
                    " ",
                    InfoDetails)),
            React.createElement(MediaQuery, { maxWidth: 'tablet' },
                React.createElement(ClickAwayListener, { onClickAway: closeTransitTooltip },
                    React.createElement("div", { className: css.transitTooltip__text, onClick: openTransitTooltip },
                        t('Transit tariff'),
                        " ",
                        InfoDetails))))));
};
export default TransitTooltip;
