import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderStatus, InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { getAdultAccompaniedMap } from '../utils';
import { useChangeLink } from '../hooks';
import Passenger from './Passenger/Passenger';
import Notification from '../../Notification';
import WarningModal from '../../WarningModal';
import { BaseButton } from '../../BaseComponents';
var errorLocale = "Could not be change the 'LinkedTo' link for traveler";
var PassengersDetails = function (_a) {
    var className = _a.className, orderId = _a.orderId, orderStatus = _a.orderStatus, travellers = _a.travellers, changeTravellersAvailability = _a.changeTravellersAvailability, goToChangePassenger = _a.goToChangePassenger, onClose = _a.onClose;
    var css = useTheme('PassengersDetails').PassengersDetails;
    var t = useTranslation('OrderCard').t;
    var _b = useChangeLink(orderId), confirmRelinking = _b.confirmRelinking, onLinkTo = _b.onLinkTo, isLoading = _b.isLoading, onSetRelinkingMode = _b.onSetRelinkingMode, isRelinkingMode = _b.isRelinkingMode, relinkingToPassengerId = _b.relinkingToPassengerId, relinkingPassenger = _b.relinkingPassenger, error = _b.error, clearError = _b.clearError;
    var isAvailableForRelinking = function (traveller) {
        var _a;
        var isAlreadyAccompanyInf = (_a = adultAccompaniedMap
            .get(traveller.id)) === null || _a === void 0 ? void 0 : _a.some(function (accompanied) { return accompanied.type === 'INF'; });
        var isCanAccompanyInf = relinkingPassenger.type !== 'INF' || !isAlreadyAccompanyInf;
        return traveller.type === 'ADT' && (traveller.id === relinkingToPassengerId || isCanAccompanyInf);
    };
    var adultAccompaniedMap = useMemo(function () { return getAdultAccompaniedMap(travellers); }, [travellers]);
    var adultsCount = useMemo(function () { return travellers.reduce(function (count, traveller) { return (traveller.type === 'ADT' ? count + 1 : count); }, 0); }, [travellers]);
    var filteredTravellers = useMemo(function () {
        return travellers.filter(function (traveller) { var _a; return isRelinkingMode ? isAvailableForRelinking(traveller) : !((_a = traveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id); });
    }, [travellers, adultAccompaniedMap, isRelinkingMode, relinkingPassenger]);
    var errorKey = useMemo(function () {
        if (error === null || error === void 0 ? void 0 : error.includes(errorLocale)) {
            return errorLocale;
        }
        return error;
    }, [error]);
    var getIsChangeAvailable = useCallback(function (travellerId) {
        var _a;
        return !!((_a = changeTravellersAvailability === null || changeTravellersAvailability === void 0 ? void 0 : changeTravellersAvailability.find(function (changeTraveller) { return changeTraveller.id === travellerId; })) === null || _a === void 0 ? void 0 : _a.available);
    }, [changeTravellersAvailability]);
    var passengerIndex = 0;
    var goToChangePassengerHandler = function (passengerId) { return function () {
        goToChangePassenger(passengerId);
    }; };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.close, onClick: onClose }),
        React.createElement("div", { className: css.title }, isRelinkingMode ? t('Select an adult for accompany') : t('Passengers details')),
        isRelinkingMode && (React.createElement(Notification, { infoMessage: {
                code: null,
                type: InfoMessageTypeEnum.Info,
                text: t('After selecting a new adult, please double-check all services. Please choose an adult who does not sit on the emergency seat row.')
            }, status: InfoMessageTypeEnum.Info, type: 'context' })),
        React.createElement("div", { className: css.passengers }, filteredTravellers.map(function (traveller) {
            var _a;
            var accompanied = adultAccompaniedMap.get(traveller.id);
            passengerIndex++;
            var isChangeAvailable = getIsChangeAvailable(traveller.id);
            return (React.createElement("div", { className: cn(css.item, (_a = {}, _a[css.item_relinking] = isRelinkingMode, _a)), key: passengerIndex },
                React.createElement(Passenger, { index: passengerIndex, traveller: traveller, isRelinkingMode: isRelinkingMode, isDisabled: isLoading, isChangeAvailable: isChangeAvailable, selectedAsAccompanying: traveller.id === relinkingToPassengerId, onLinkTo: onLinkTo, onChangePassenger: isChangeAvailable ? goToChangePassengerHandler(traveller.id) : undefined }),
                !isRelinkingMode && (accompanied === null || accompanied === void 0 ? void 0 : accompanied.length) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css.accompanies },
                        React.createElement("div", { className: css.accompanies__text }, t('accompanies'))),
                    accompanied.map(function (accompaniedPassenger) {
                        passengerIndex++;
                        var isChangeAvailable = getIsChangeAvailable(accompaniedPassenger.id);
                        return (React.createElement(Passenger, { key: passengerIndex, index: passengerIndex, traveller: accompaniedPassenger, isAccompanied: true, isRelinkingAvailable: orderStatus !== OrderStatus.Cancelled, isDisabled: isLoading, onSetRelinkingMode: adultsCount > 1 ? onSetRelinkingMode : null, onChangePassenger: isChangeAvailable
                                ? goToChangePassengerHandler(accompaniedPassenger.id)
                                : undefined }));
                    })))));
        })),
        isRelinkingMode && (React.createElement(BaseButton, { type: "submit", variant: "smallThird", size: "small", className: css.confirmRelink, onClick: confirmRelinking, isLoading: isLoading }, t('Confirm'))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: t(errorKey), onClose: clearError, onButtonClick: clearError, buttonText: t('OK'), isOpen: !!error })));
};
export default PassengersDetails;
