import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { TravellerFieldEnum, useAddTravellersDataMutation } from '@websky/graphql';
import { useDispatch } from 'react-redux';
import { fillOrder } from '../Checkout/store/order/actions';
export var useChangeLink = function (orderId) {
    var _a = __read(useAddTravellersDataMutation(), 2), addTravellersDataMutation = _a[0], loading = _a[1].loading;
    var dispatch = useDispatch();
    var _b = __read(React.useState(null), 2), relinkingPassenger = _b[0], setRelinkingPassenger = _b[1];
    var _c = __read(React.useState(null), 2), relinkingToPassengerId = _c[0], setRelinkingToPassengerId = _c[1];
    var _d = __read(React.useState(null), 2), error = _d[0], setError = _d[1];
    var onSetRelinkingMode = function (traveller) {
        setRelinkingPassenger(traveller);
        setRelinkingToPassengerId(traveller.linkedTraveller.id);
    };
    var onLinkTo = function (passengerId) {
        setRelinkingToPassengerId(passengerId);
    };
    var confirmRelinking = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addTravellersDataMutation({
                            variables: {
                                variables: {
                                    orderId: orderId,
                                    travellersData: [
                                        {
                                            id: relinkingPassenger.id,
                                            values: [
                                                {
                                                    type: TravellerFieldEnum.LinkedTraveller,
                                                    value: relinkingToPassengerId
                                                }
                                            ]
                                        }
                                    ]
                                }
                            }
                        })];
                case 1:
                    data = (_c.sent()).data;
                    if (data.SetTravellersData) {
                        dispatch(fillOrder(data.SetTravellersData));
                        setRelinkingPassenger(null);
                        setRelinkingToPassengerId(null);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _c.sent();
                    setError(((_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) || 'Error during relinking');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var clearError = function () { return setError(null); };
    return {
        isRelinkingMode: !!relinkingPassenger,
        relinkingPassenger: relinkingPassenger,
        relinkingToPassengerId: relinkingToPassengerId,
        isLoading: loading,
        error: error,
        clearError: clearError,
        onSetRelinkingMode: onSetRelinkingMode,
        onLinkTo: onLinkTo,
        confirmRelinking: confirmRelinking
    };
};
