import { __assign, __rest } from "tslib";
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
var Loader = function (_a) {
    var classes = _a.classes, _b = _a.size, size = _b === void 0 ? 19 : _b, restProps = __rest(_a, ["classes", "size"]);
    var theme = useTheme('BaseComponents').Loader;
    return (React.createElement(CircularProgress, __assign({ classes: __assign(__assign({}, classes), { colorPrimary: cn(theme.colorPrimary, classes === null || classes === void 0 ? void 0 : classes.colorPrimary) }), size: size }, restProps)));
};
export default Loader;
