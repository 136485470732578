import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import QRCode from 'qrcode.react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
import { hasSegmentBoardingPass } from '../Modules/Checkin/components/Checkin/Order/Passengers/utils';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Controls from './components/Controls/Controls';
import SelectedService from '../Modules/Checkin/components/Checkin/SelectedService/SelectedService';
initI18n('RegisteredPassenger');
var RegisteredPassenger = function (_a) {
    var name = _a.name, retryLink = _a.retryLink, onBoardingPassDownload = _a.onBoardingPassDownload, onAppleWalletPassDownload = _a.onAppleWalletPassDownload, barcode = _a.barcode, linkedPassengers = _a.linkedPassengers, services = _a.services, seats = _a.seats, onCheckinCancel = _a.onCheckinCancel, onTicketsResend = _a.onTicketsResend, isCheckedIn = _a.isCheckedIn, segments = _a.segments, segmentsFailedBoardingPasses = _a.segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses = _a.setSegmentsFailedBoardingPasses;
    var css = useTheme('RegisteredPassenger').RegisteredPassenger;
    var segmentsWithoutBoardingPasses = segments.filter(function (segment) { return !hasSegmentBoardingPass(segment, segmentsFailedBoardingPasses); });
    var canDownloadBoardingPass = segmentsWithoutBoardingPasses.length < segments.length;
    var servicesByTypes = useMemo(function () {
        var servicesByTypesMap = new Map();
        services.forEach(function (service) {
            var _a;
            var type = service.type;
            if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
                var baggageService = service;
                type = baggageService.baggageType;
            }
            servicesByTypesMap.set(type, __spreadArray(__spreadArray([], __read(((_a = servicesByTypesMap.get(type)) !== null && _a !== void 0 ? _a : [])), false), [service], false));
        });
        return __spreadArray([], __read(servicesByTypesMap.values()), false);
    }, [services]);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement(Header, { className: css.header, name: name, seats: seats, isCheckedIn: isCheckedIn, controls: isCheckedIn ? (React.createElement(Controls, { segments: segments, canDownloadBoardingPass: canDownloadBoardingPass, onBoardingPassDownload: onBoardingPassDownload, onAppleWalletPassDownload: onAppleWalletPassDownload, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses })) : null }),
        isCheckedIn && (React.createElement("div", { className: css.content },
            servicesByTypes.length > 0 && (React.createElement("div", { className: css.services }, servicesByTypes.map(function (services, index) { return (React.createElement(SelectedService, { key: index, className: css.services__item, services: services })); }))),
            barcode && (React.createElement("div", { className: css.barcode },
                React.createElement(QRCode, { value: barcode, size: 200, level: "M", renderAs: "svg" }))))),
        React.createElement(Footer, { className: css.footer, isCheckedIn: isCheckedIn, canDownloadBoardingPass: canDownloadBoardingPass, segments: segments, retryLink: retryLink, passengerName: name, linkedPassengers: linkedPassengers, onCheckinCancel: onCheckinCancel, onTicketsResend: onTicketsResend })));
};
export default RegisteredPassenger;
