import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import SeatMapService from './SeatMapService/SeatMapService';
import { useGraphQLClient } from '../../graphql-client';
import { getSelectedServices, updateSeatServices } from '../utils';
import SimpleLoader from '../../SimpleLoader';
import { OverrideComponent } from '../../renderProps';
var SeatMapComponentStore = React.memo(function (_a) {
    var className = _a.className, orderId = _a.orderId, passengers = _a.passengers, seatMap = _a.seatMap, segmentIndex = _a.segmentIndex, passengerIndex = _a.passengerIndex, runServiceRequest = _a.runServiceRequest, onProceed = _a.onProceed, setSegment = _a.setSegment, setPassenger = _a.setPassenger, segments = _a.segments, selectedExtraSeats = _a.selectedExtraSeats, fillOrder = _a.fillOrder, showRecommendations = _a.showRecommendations, travellers = _a.travellers, _b = _a.fullScreen, fullScreen = _b === void 0 ? true : _b, setSeatCallback = _a.setSeatCallback, setClearSeatCallback = _a.setClearSeatCallback, onSegmentClear = _a.onSegmentClear, onPassengerClear = _a.onPassengerClear, isLoading = _a.isLoading, confirmRecommendedSeatsWithoutDialog = _a.confirmRecommendedSeatsWithoutDialog, onClose = _a.onClose, isNewOrder = _a.isNewOrder, dialogRef = _a.dialogRef, isReturnFlight = _a.isReturnFlight;
    var seatSelectHandler = useCallback(function (passengerId, seats, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPassenger;
        return __generator(this, function (_a) {
            currentPassenger = passengers.find(function (orderPassenger) { return orderPassenger.id === passengerId; });
            if (runServiceRequest) {
                runServiceRequest(updateSeatServices(passengers, seatMap.segments[segmentId], currentPassenger.id, seats));
            }
            else {
                setSeatCallback(seats, currentPassenger.id, segmentId.toString());
            }
            return [2 /*return*/];
        });
    }); }, [runServiceRequest, passengers, seatMap]);
    var clearSeatHandler = useCallback(function (passengerId, seats, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPassenger;
        return __generator(this, function (_a) {
            currentPassenger = passengers.find(function (orderPassenger) { return orderPassenger.id === passengerId; });
            if (runServiceRequest) {
                runServiceRequest(updateSeatServices(passengers, seatMap.segments[segmentId], currentPassenger.id, seats));
            }
            else {
                setClearSeatCallback(seats, currentPassenger.id, segmentId.toString());
            }
            return [2 /*return*/];
        });
    }); }, [runServiceRequest, passengers, seatMap]);
    // do not make two request per passenger in order to clear segment seats
    var clearOnSegment = useCallback(function (segmentIndex) {
        if (runServiceRequest) {
            var passengersList_1 = [];
            var currentSegmentId_1 = seatMap.segments[segmentIndex].segmentsInfo.id;
            passengers.forEach(function (passenger, passengerIndex) {
                passengersList_1.push({
                    passengerId: passenger.id,
                    seats: getSelectedServices(passenger, seatMap.segments[segmentIndex], passengerIndex, null)
                        .filter(function (seat) { return (seat === null || seat === void 0 ? void 0 : seat.isConfirmed) || seat.segmentId !== currentSegmentId_1; })
                        .map(function (seat) { return ({
                        row: parseInt(seat.row),
                        letter: seat.letter,
                        isConfirmed: seat.isConfirmed,
                        isChangeable: seat.isChangeable,
                        segment: {
                            id: seat.id
                        },
                        seat: null,
                        product: null,
                        type: seat.seatType
                    }); })
                });
            });
            runServiceRequest(passengersList_1);
        }
        else {
            onSegmentClear(segmentIndex);
        }
    }, [runServiceRequest, passengers, seatMap]);
    return seatMap ? (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(OverrideComponent, { componentProps: {
                className: className,
                clearOnSegment: clearOnSegment,
                passengers: passengers,
                seatMaps: seatMap,
                passengerIndex: passengerIndex,
                segmentIndex: segmentIndex,
                onProceed: onProceed,
                setSegment: setSegment,
                setPassenger: setPassenger,
                onSelect: seatSelectHandler,
                fullScreen: fullScreen,
                onCancelSeat: clearSeatHandler,
                travellers: travellers,
                seatRecommendEnabled: showRecommendations && !!segments && !!travellers && !!fillOrder,
                segments: segments,
                selectedExtraSeats: selectedExtraSeats,
                orderId: orderId,
                runServiceRequest: runServiceRequest,
                setSeatCallback: setSeatCallback,
                confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog,
                onClose: onClose,
                onPassengerClear: onPassengerClear,
                isNewOrder: isNewOrder,
                dialogRef: dialogRef,
                isReturnFlight: isReturnFlight
            }, component: { SeatMapService: SeatMapService } }))) : null;
});
var SeatMapWrapperWithGql = function (props) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(SeatMapComponentStore, __assign({}, props))));
};
export default SeatMapWrapperWithGql;
