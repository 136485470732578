import * as React from 'react';
import { ButtonBase } from '@material-ui/core';
import { Add } from '../../../Icons';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useDefaultTravellerFieldsQuery } from '@websky/graphql';
import DocumentFieldsItem from './DocumentFieldsItem/DocumentFieldsItem';
import { useForm } from 'react-final-form';
var DocumentsSection = function (_a) {
    var _b, _c;
    var traveller = _a.traveller, documents = _a.documents, primaryCountryCodes = _a.primaryCountryCodes;
    var css = useTheme('TravellerForm').DocumentsSection;
    var tTraveller = useTranslation('Traveller').t;
    var form = useForm();
    var documentsValidations = useDefaultTravellerFieldsQuery().data;
    var documentValidators = (_c = (_b = documentsValidations === null || documentsValidations === void 0 ? void 0 : documentsValidations.DefaultTravellerProfile) === null || _b === void 0 ? void 0 : _b.documents) === null || _c === void 0 ? void 0 : _c[0];
    var addDocumentHandler = function () {
        return form.mutators.documentsMutator({
            type: 'add'
        });
    };
    var removeDocumentHandler = function (id) {
        form.mutators.documentsMutator({
            type: 'remove',
            index: id
        });
    };
    var documentFieldsItems = documents.map(function (document, index) { return (React.createElement(DocumentFieldsItem, { key: index, id: index, document: document, validators: documentValidators, traveller: traveller, primaryCountryCodes: primaryCountryCodes, onRemove: documents.length > 1 ? removeDocumentHandler : undefined })); });
    return (React.createElement("div", { className: css.documentsSection },
        React.createElement("div", { className: css.documentsSection__fields }, !!documents.length ? (documentFieldsItems) : (React.createElement("div", { className: css.documentsSection__emptyWarning }, tTraveller('You need to add a document')))),
        React.createElement("div", { className: css.documentsSection__controls },
            React.createElement("div", { className: css.documentsSection__addFields, onClick: addDocumentHandler },
                React.createElement(ButtonBase, { classes: {
                        root: css.buttonAdd
                    } }, Add),
                tTraveller('Add document')))));
};
export default DocumentsSection;
