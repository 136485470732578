import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { useDesktopFlightWithPopup } from '../../DesktopFlight/components/DesktopFlightPopup/hooks';
import Segment from './Segment/Segment';
import FlightPrice from './FlightPrice/FlightPrice';
import DesktopFlightModalContent from '../../DesktopFlight/components/DesktopFlightModalContent/DesktopFlightModalContent';
import Modal from '../../Modal';
import DesktopFlightModalContentSelected from '../../DesktopFlight/components/DesktopFlightModalContentSelected/DesktopFlightModalContentSelected';
import TotalDuration from './TotalDuration/TotalDuration';
import Dropdown from '../../Dropdown';
import { Chevron } from '../../Icons';
import { OverrideComponent } from '../../renderProps';
import InfoMessages from '../../InfoMessages';
var DesktopFlightRoute = function (props) {
    var _a, _b, _c, _d;
    var _e, _f, _g;
    var t = useTranslation('DesktopFlightInfo').t;
    var flight = props.flight, flightIcon = props.flightIcon, isRecommended = props.isRecommended, isSelected = props.isSelected, _h = props.isSelectorInModal, isSelectorInModal = _h === void 0 ? true : _h, singleFlight = props.singleFlight, selectedFares = props.selectedFares, canceled = props.canceled, searchParameters = props.searchParameters, onClear = props.onClear, hideOnlyPrice = props.hideOnlyPrice, onSubsidySuggestModalOpen = props.onSubsidySuggestModalOpen, onSetViewFlight = props.onSetViewFlight;
    var theme = useTheme('DesktopFlightRoute').DesktopFlightRouteStyles;
    var popupCss = useTheme('DesktopFlight').DesktopFlightPopup;
    var _j = useDesktopFlightWithPopup(props, !isSelectorInModal && singleFlight && !onSubsidySuggestModalOpen), openModal = _j.openModal, isModalOpen = _j.isModalOpen, subStatuses = _j.subStatuses, flightSegments = _j.flightSegments, lastSelectedFareId = _j.lastSelectedFare, closeModal = _j.closeModal, businessFareGroups = _j.businessFareGroups, economyFareGroups = _j.economyFareGroups, milesFareGroups = _j.milesFareGroups, getOnFareSelect = _j.getOnFareSelect, upgradeOptions = _j.upgradeOptions, isComparisonOpen = _j.isComparisonOpen, setIsComparisonOpen = _j.setIsComparisonOpen;
    var FlightContainer = React.useCallback(function (children) {
        return isSelectorInModal || props.isSelected ? (React.createElement(React.Fragment, null, children)) : (React.createElement(Dropdown, { isOpen: true, onClose: (!singleFlight && !isComparisonOpen) || !!onSubsidySuggestModalOpen ? closeModal : function () { }, outsideClickIgnoreClass: theme.fares.split(' ')[0] }, children));
    }, [props.isSelected]);
    var lastSegment = flightSegments[flightSegments.length - 1].segment;
    var isReadOnly = useMemo(function () {
        var flightInProcess = subStatuses.some(function (status) { return status === 'AwaitingExchange' || status === 'Upgrade'; });
        return props.readonly || flightInProcess;
    }, [props.readonly, subStatuses]);
    var renderTotalDuration = useCallback(function () { return React.createElement(TotalDuration, { lastSegment: lastSegment, flight: flight }); }, [
        lastSegment,
        flight
    ]);
    var lastSelectedFare = useMemo(function () {
        var _a;
        return (_a = props.flight.pricesForFareGroups.find(function (fareGroup) { return fareGroup.fareFamily && fareGroup.fareFamily.id === lastSelectedFareId; })) === null || _a === void 0 ? void 0 : _a.fareFamily;
    }, [props.flight.pricesForFareGroups, lastSelectedFareId]);
    var flightId = (_f = (_e = flight.pricesForFareGroups.find(function (price) { return (price === null || price === void 0 ? void 0 : price.fareFamily.id) === (lastSelectedFare === null || lastSelectedFare === void 0 ? void 0 : lastSelectedFare.id); })) === null || _e === void 0 ? void 0 : _e.prices) === null || _f === void 0 ? void 0 : _f[0].flight.id;
    var faresContent = isSelected ? (React.createElement(DesktopFlightModalContentSelected, { flightId: flightId, lastSelectedFare: lastSelectedFare, flight: flight, orderId: props.orderId, onClose: closeModal, hideConditionsButton: props.hideConditionsButton, isReturnFlight: props.isReturnFlight, isSelectorInModal: isSelectorInModal })) : (React.createElement(OverrideComponent, { componentProps: {
            lastSelectedFare: lastSelectedFare,
            flight: flight,
            flightId: flightId,
            orderId: props.orderId,
            economyFares: economyFareGroups,
            businessFares: businessFareGroups,
            milesFares: milesFareGroups,
            getOnFareSelect: getOnFareSelect,
            upgradeOptions: upgradeOptions,
            hidePrice: props.hidePrice,
            searchParameters: searchParameters,
            onClose: closeModal,
            preselectedFlightClass: props.preselectedFlightClass,
            isReturnFlight: props.isReturnFlight,
            isSelectorInModal: isSelectorInModal,
            setIsComparisonOpen: setIsComparisonOpen
        }, component: { DesktopFlightRouteModal: DesktopFlightModalContent } }));
    var onToggle = function () {
        if (!isReadOnly && !canceled) {
            if (onSubsidySuggestModalOpen) {
                onSubsidySuggestModalOpen(openModal);
            }
            else if (isModalOpen) {
                closeModal();
            }
            else {
                openModal();
            }
        }
    };
    useEffect(function () {
        onSetViewFlight === null || onSetViewFlight === void 0 ? void 0 : onSetViewFlight(isModalOpen ? flight : null);
    }, [isModalOpen]);
    return (React.createElement(React.Fragment, null,
        FlightContainer(React.createElement("div", { className: theme.container },
            React.createElement("div", { className: cn(theme.flight, (_a = {},
                    _a[theme.flight_readonly] = isReadOnly,
                    _a[theme.flight_canceled] = canceled,
                    _a[theme.flight_opened] = isModalOpen,
                    _a[theme.flight_dropdown] = !isSelectorInModal,
                    _a[theme.selected] = props.isSelected,
                    _a)), onClick: onToggle },
                flightIcon,
                React.createElement("div", { className: cn(theme.segments, (_b = {}, _b[theme.segments_withoutPrices] = props.hidePrice, _b)) },
                    React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.flight, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage, messages: flight.segments[0].segment.messages }),
                    flightSegments.map(function (segment, key) {
                        var _a;
                        return (React.createElement(Segment, { segment: segment, key: segment.segment.id, first: key === 0, last: key === flightSegments.length - 1, stops: ((_a = segment.segment) === null || _a === void 0 ? void 0 : _a.stops) || [], singleFlight: singleFlight, totalDuration: key === flightSegments.length - 1 && renderTotalDuration(), nextDay: key >= 1 &&
                                flightSegments[key - 1].segment.departure.date !==
                                    segment.segment.arrival.date, multipleSegments: flightSegments.length > 1, isReturnFlight: props.isReturnFlight }));
                    }),
                    (flightSegments.length > 1 || ((_g = lastSegment.stops) === null || _g === void 0 ? void 0 : _g.length) > 0) && false && (React.createElement("div", { className: theme.duration }))),
                !props.hidePrice && !subStatuses.length && (React.createElement(React.Fragment, null,
                    React.createElement(FlightPrice, { best: isRecommended, singleFlight: singleFlight, flight: flight, selectedFares: selectedFares, isSelected: isSelected, canceled: props.canceled, onClear: onClear, hideOnlyPrice: hideOnlyPrice, preselectedFlightClass: props.preselectedFlightClass, showSeatsLeftForAllFares: props.showSeatsLeftForAllFares }),
                    !isSelectorInModal && (React.createElement("div", { className: theme.dropdown__icon },
                        React.createElement("span", { className: cn(theme.arrow, (_c = {}, _c[theme.arrow_opened] = isModalOpen, _c)) }, Chevron))))),
                !!subStatuses.length && (React.createElement("div", { className: theme.subStatus },
                    React.createElement("span", null, t("SubStatus_".concat(subStatuses[0])))))),
            !isSelectorInModal && (React.createElement(Collapse, { className: theme.fares, in: isModalOpen, mountOnEnter: true }, faresContent)))),
        isSelectorInModal && (React.createElement(Modal, { closeClassName: cn((_d = {},
                _d[theme.modal__close] = !isSelected,
                _d)), isRounded: isSelected, fullScreen: true, scroll: 'paper', classes: {
                paper: cn(popupCss.paper, popupCss.paper_fullScreen),
                scrollBody: popupCss.scrollBody
            }, closeButtonRenderer: function () { return null; }, open: isModalOpen, onClose: closeModal }, faresContent))));
};
export default DesktopFlightRoute;
