import { __assign } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import BaggageItem from './BaggageItem/BaggageItem';
import Slider from 'react-slick';
import { MOBILE_MIN_WIDTH } from '../../../utils';
var BaggageList = function (_a) {
    var items = _a.items, className = _a.className, slidesToShow = _a.slidesToShow;
    var css = useTheme('BaggageList').BaggageList;
    var slidesToShowCount = slidesToShow !== null && slidesToShow !== void 0 ? slidesToShow : 1;
    return (React.createElement(Slider, { slidesToShow: Math.min(slidesToShowCount, items.length), arrows: true, infinite: false, variableWidth: false, dots: true, className: cn(css.root, className), responsive: [
            {
                breakpoint: MOBILE_MIN_WIDTH,
                settings: {
                    slidesToShow: 1
                }
            }
        ] }, items.map(function (item, i) { return (React.createElement(BaggageItem, __assign({}, item, { key: i }))); })));
};
export default BaggageList;
