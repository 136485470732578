import { __assign } from "tslib";
import * as React from 'react';
import Sortings from '../../../../../../Results/components/Results/FlightsList/Sortings/Sortings';
import { ThemeContext, ThemeProvider, useTheme } from '../../../../../../theme';
import { mergeCSS } from '../../../../../../utils';
var Sort = function (props) {
    var resultsTheme = React.useContext(ThemeContext);
    var theme = useTheme('Exchange').Sort;
    var exchangeTheme = {
        Results: {
            Sortings: __assign(__assign({}, resultsTheme.Results.Sortings), { title: theme.title, sortBy: theme.sortBy, sortings: theme.sortings, currentSorting: theme.currentSorting })
        }
    };
    var mergedTheme = mergeCSS({
        Results: {
            Sortings: __assign({}, resultsTheme.Results.Sortings)
        }
    }, exchangeTheme);
    return (React.createElement(ThemeProvider, { value: mergedTheme },
        React.createElement(Sortings, __assign({}, props))));
};
export default Sort;
