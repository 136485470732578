import * as React from 'react';
import cn from 'classnames';
import { memo } from 'react';
import { useTheme } from '../../theme';
var OfferCard = memo(function (_a) {
    var _b;
    var image = _a.image, title = _a.title, text = _a.text, wide = _a.wide, renderFooter = _a.renderFooter, _c = _a.inLanding, inLanding = _c === void 0 ? false : _c;
    var css = useTheme('OfferCard').OfferCard;
    return (React.createElement("div", { className: cn(css.card, (_b = {},
            _b[css.card_wide] = wide,
            _b[css.card_inLanding] = inLanding,
            _b)) },
        React.createElement("div", { className: css.image, style: { backgroundImage: image && "url(".concat(image, ")") } }),
        React.createElement("div", { className: css.body },
            React.createElement("div", { className: css.body__inner },
                React.createElement("h2", { className: css.title }, title),
                React.createElement("div", { className: css.text }, text)),
            renderFooter && renderFooter())));
});
OfferCard.displayName = 'OfferCard';
export default OfferCard;
