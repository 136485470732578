import { __assign, __read } from "tslib";
import { isCheckoutOrderGuard } from '../typeguards';
import { TransportLegType } from '../analytics';
import { DataLayerEvent, ItemCategory } from './types';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType, RouteType } from '@websky/graphql';
export var createEvent = function (eventName, params, ecommerce) {
    var event = initEvent(eventName);
    event = setParamsToEvent(event, params);
    return setEcommerceItems(event, ecommerce);
};
export var initEvent = function (eventName) {
    if (!!eventName) {
        return {
            event: eventName,
            ecommerce: null
        };
    }
    return {
        ecommerce: null
    };
};
export var setParamsToEvent = function (dataLayer, params) {
    if (params) {
        return __assign(__assign({}, dataLayer), params);
    }
    return dataLayer;
};
export var setEcommerceItems = function (dataLayer, ecommerce) {
    if (ecommerce) {
        return __assign(__assign({}, dataLayer), { ecommerce: __assign({}, ecommerce) });
    }
    return dataLayer;
};
export var getServiceCategory = function (serviceType, baggageType) {
    if (serviceType === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
        (baggageType === BaggageType.PetInHandCarrier ||
            baggageType === BaggageType.PetInHold ||
            baggageType === BaggageType.PetInCabin)) {
        return ItemCategory.Pets;
    }
    switch (serviceType) {
        case OrderAdditionalServiceGdsServiceServiceType.Meal:
            return ItemCategory.Meal;
        case OrderAdditionalServiceGdsServiceServiceType.Baggage:
            return ItemCategory.Baggage;
        case OrderAdditionalServiceGdsServiceServiceType.Seat:
            return ItemCategory.Seat;
        case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
            return ItemCategory.Business;
        default:
            return null;
    }
};
export var getBaggageDatalayerName = function (service) {
    var splittedWeight = service.baggageWeight.split(' ');
    var _a = __read(splittedWeight.length === 2 ? splittedWeight : [null, null], 2), amount = _a[0], measurement = _a[1];
    return "".concat(service.name).concat(amount ? "-".concat(amount).concat(measurement) : '');
};
export var animalBaggageTypes = [BaggageType.PetInCabin, BaggageType.PetInHandCarrier, BaggageType.PetInHold];
export var getTravellersTypes = function (travellers) {
    var types = {};
    travellers === null || travellers === void 0 ? void 0 : travellers.forEach(function (traveller) {
        if (traveller.type) {
            if (types[traveller.type]) {
                types[traveller.type]++;
            }
            else {
                types[traveller.type] = 1;
            }
        }
    });
    return Object.entries(types).map(function (_a) {
        var _b = __read(_a, 2), type = _b[0], count = _b[1];
        return "".concat(count === 1 ? '' : count).concat(type);
    });
};
var getFunnelDateEnd = function (flight, routeType) {
    if (routeType === RouteType.OW) {
        return null;
    }
    return flight.segmentGroups[flight.segmentGroups.length - 1].segments[0].departure.date;
};
export var getFunnelParameters = function (order) {
    var _a;
    var isCheckoutOrder = isCheckoutOrderGuard(order);
    var segments = isCheckoutOrder ? order.flight.segments : order.segments;
    var segment = isCheckoutOrder ? order.flight.segments[0].segment : order.segments[0];
    var from = segment.departure;
    var to = segment.arrival;
    var transportLeg = segments.length > 1 ? TransportLegType.Transfer : TransportLegType.Direct;
    var flightNumber = "".concat((_a = segment.operatingAirline) === null || _a === void 0 ? void 0 : _a.iata, "-").concat(segment.flightNumber);
    var routeType = !isCheckoutOrder ? null : order.flight.segmentGroups.length > 1 ? RouteType.RT : RouteType.OW;
    return {
        airport_from: from.airport.iata,
        airport_to: to.airport.iata,
        date_start: from.date,
        date_end: isCheckoutOrder ? getFunnelDateEnd(order.flight, routeType) : null,
        passenger_type: getTravellersTypes(order.travellers),
        route_type: routeType,
        flight_number: flightNumber,
        avia_operator: window.location.hostname,
        transport_leg: transportLeg,
        payment_type: null,
        auth_type: null,
        register_type: null,
        search_id: null
    };
};
export var getAiPurchasePopupGoal = function (serviceType, action) {
    var serviceTypeGoal = serviceType ? "_".concat(getServiceCategory(serviceType)) : '';
    var actionGoal = action ? "_".concat(action) : '';
    return "".concat(DataLayerEvent.PurchaseShowPopup).concat(serviceTypeGoal).concat(actionGoal);
};
