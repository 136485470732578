import { __assign } from "tslib";
import * as React from 'react';
import { Grid } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { TravellerFields } from '../../types';
import Input from '../../../BaseComponents/Input';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import PhoneInput from '../../../PhoneInput';
var ContactsSection = function () {
    var css = useTheme('TravellerForm').ContactsSection;
    var tDocumentCardForm = useTranslation('DocumentCardForm').t;
    var tTraveller = useTranslation('Traveller').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var variant = isMobile ? 'underline' : 'labelIn';
    var form = useForm();
    return (React.createElement("div", { className: css.contactsSection },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                React.createElement(Field, { name: TravellerFields.Email, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, value: input.value, label: "".concat(tTraveller('email')), error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: function (event) {
                                input.onChange(event);
                                form.mutators.fixEmptyFieldMutator({ fieldName: TravellerFields.Email });
                            } })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                React.createElement(Field, { name: TravellerFields.Phone, type: "tel", render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(PhoneInput, __assign({}, input, { className: css.phoneInput, textFieldsProps: {
                                className: css.phoneInputInput,
                                variant: 'filled',
                                label: "".concat(tTraveller('phone')),
                                error: meta.error && meta.touched,
                                helperText: meta.touched && tDocumentCardForm(meta.error),
                                onChange: function (event) {
                                    input.onChange(event);
                                    form.mutators.fixEmptyFieldMutator({ fieldName: TravellerFields.Phone });
                                }
                            }, value: input.value })));
                    } })))));
};
export default ContactsSection;
