import { __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PromoLoader from '../../../PromoLoader';
import { useRestoreOrderMutation } from '@websky/graphql';
var RestoreOrderComponent = function (props) {
    var _a = useParams(), orderId = _a.orderId, token = _a.token;
    var history = useHistory();
    var _b = __read(useRestoreOrderMutation(), 1), restoreOrder = _b[0];
    useEffect(function () {
        // call restore order once if there is same id/token
        restoreOrder({ variables: { accessToken: token, id: orderId } })
            .then(function (_a) {
            var data = _a.data;
            if (data === null || data === void 0 ? void 0 : data.RestoreOrder) {
                props.onOrderReceived(data.RestoreOrder, false);
            }
            else {
                history.push("/find/".concat(orderId));
            }
        })
            .catch(function () {
            history.push("/find/".concat(orderId));
        });
    }, [orderId, token]);
    return React.createElement(PromoLoader, null);
};
export default RestoreOrderComponent;
