import { __read, __spreadArray } from "tslib";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedBaggage } from './store/selector';
import { getTotalPrice } from '../../utils';
export var usePassengerState = function (passengerId, baggages, segmentIds, segmentId) {
    var servicesIds = baggages.map(function (baggage) { return baggage.id; });
    var selectedItems = useSelector(function (state) {
        return getSelectedBaggage(state, segmentId, passengerId, servicesIds);
    });
    var isSelected = !!selectedItems.length;
    var selectedCount = selectedItems
        .filter(function (i) { return !segmentIds || (segmentIds === null || segmentIds === void 0 ? void 0 : segmentIds.length) === 0 || i.segmentIds.includes(segmentIds[0]); })
        .reduce(function (count, item) { return count + item.count; }, 0);
    return {
        items: getEmptyServicesItems(baggages, segmentIds, segmentId, passengerId),
        selectedItems: selectedItems,
        isSelected: isSelected,
        selectedCount: selectedCount
    };
};
export var getEmptyServicesItems = function (baggages, segmentIds, segmentId, passengerId) {
    var items = [];
    var baggagesServicesWithHighestId = new Map();
    baggages.forEach(function (bag) {
        bag.segmentIds.forEach(function (segIds) {
            var segmentsKey = segIds.join('-');
            // WEBSKY-3385: In cases where multiple services have same segmentIds,
            // we should leave a service with "highest" id and mark others as disabledToAdd
            if (bag.canBeAdded &&
                (!baggagesServicesWithHighestId.has(segmentsKey) ||
                    parseInt(bag.id) > parseInt(baggagesServicesWithHighestId.get(segmentsKey).id))) {
                baggagesServicesWithHighestId.set(segmentsKey, bag);
            }
            var disabledToAdd = !bag.canBeAdded ||
                (segmentId && !segIds.includes(segmentId)) ||
                bag.allowedPassengers.indexOf(passengerId) === -1;
            items.push({
                serviceId: bag.id,
                passengerId: passengerId,
                count: 0,
                segmentIds: segIds,
                disabledToAdd: disabledToAdd
            });
        });
    });
    return items;
};
export var serviceForMultipleSegments = function (service) {
    var _a;
    return ((_a = service.segmentIds[0]) === null || _a === void 0 ? void 0 : _a.length) > 1;
};
export var useSelectedServicesBySegments = function (services, segments, selectedServices) {
    return useMemo(function () {
        var selectedServicesMap = new Map();
        services.forEach(function (service) {
            selectedServices.forEach(function (_a) {
                var serviceId = _a.serviceId, segmentIds = _a.segmentIds, passengerId = _a.passengerId, count = _a.count;
                if (service.id === serviceId) {
                    var id = "".concat(serviceId, "-").concat(passengerId);
                    var segment = segments.find(function (segment) { return segmentIds.includes(segment.id); });
                    var selectedService = {
                        service: service,
                        segment: segment,
                        segmentId: segment.id,
                        passengerId: passengerId,
                        count: count
                    };
                    selectedServicesMap.has(id)
                        ? selectedServicesMap.set(id, __spreadArray(__spreadArray([], __read(selectedServicesMap.get(id)), false), [selectedService], false))
                        : selectedServicesMap.set(id, [selectedService]);
                }
            });
        });
        var selectedItems = __spreadArray([], __read(selectedServicesMap.values()), false);
        var totalPrice = getTotalPrice(selectedItems, function (items) {
            var _a, _b;
            return items.reduce(function (acc, service) {
                var _a, _b;
                return {
                    amount: (((_a = service.service) === null || _a === void 0 ? void 0 : _a.price.amount) || 0) * service.count + +acc.amount,
                    currency: (_b = service.service) === null || _b === void 0 ? void 0 : _b.price.currency
                };
            }, { amount: 0, currency: (_b = (_a = items[0]) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.price.currency });
        });
        return {
            selectedItems: selectedItems,
            hasSelected: __spreadArray([], __read(selectedServicesMap.values()), false).length > 0,
            totalPrice: totalPrice
        };
    }, [services, segments, selectedServices]);
};
