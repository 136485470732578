import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { LoginType, useAuthenticateMutation } from '@websky/graphql';
import { useConfig } from '../../context';
import { useCurrentUser } from '../../CurrentUser/CurrentUser.hooks';
var GoogleOneTapSignIn = memo(function () {
    var googleOneTapSignInClientId = useConfig().Engine.googleOneTapSignInClientId;
    if (!googleOneTapSignInClientId) {
        return null;
    }
    var _a = __read(useAuthenticateMutation(), 1), authenticate = _a[0];
    var _b = useCurrentUser(false), accountData = _b.data, reinitialize = _b.reinitialize, loading = _b.loading;
    var _c = __read(useState(null), 2), googleAccountId = _c[0], setGoogleAccountId = _c[1];
    var handleSignInResponse = function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, authenticate({
                        variables: {
                            params: {
                                loginType: LoginType.GoogleOneTap,
                                secureCode: response.credential
                            }
                        }
                    })];
                case 1:
                    result = _c.sent();
                    if ((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.Authenticate) === null || _b === void 0 ? void 0 : _b.token) {
                        reinitialize();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClientStateChange = function (_, addedTags) {
        var _a;
        var script = (_a = addedTags === null || addedTags === void 0 ? void 0 : addedTags.scriptTags) === null || _a === void 0 ? void 0 : _a[0];
        if (script) {
            script.onload = function () {
                var _a, _b;
                if ((_b = (_a = window.google) === null || _a === void 0 ? void 0 : _a.accounts) === null || _b === void 0 ? void 0 : _b.id) {
                    setGoogleAccountId(window.google.accounts.id);
                }
            };
        }
    };
    useEffect(function () {
        var _a;
        if (googleAccountId && !((_a = accountData === null || accountData === void 0 ? void 0 : accountData.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile) && !loading) {
            googleAccountId.initialize({
                client_id: googleOneTapSignInClientId,
                callback: handleSignInResponse
            });
            googleAccountId.prompt();
        }
    }, [googleAccountId, loading, accountData]);
    return (React.createElement(Helmet, { async: true, script: [{ src: 'https://accounts.google.com/gsi/client' }], onChangeClientState: handleClientStateChange }));
});
export default GoogleOneTapSignIn;
