import * as React from 'react';
import { useTransferService } from './model';
import TransferSelector from '../TransferSelector/TransferSelector';
import { useTheme } from '../../../theme';
var TransferService = function (_a) {
    var order = _a.order, onChange = _a.onChange, activeSegment = _a.activeSegment, onStateChanged = _a.onStateChanged;
    var model = useTransferService(order, onChange, onStateChanged, activeSegment);
    var css = useTheme('Transfer').TransferService;
    return (React.createElement("div", { className: css.container }, model.activeSegmentServices.map(function (service) {
        return (React.createElement(TransferSelector, { isSelected: model.selectedServices.has(service.id), key: service.id, passengers: model.passengersById, onChange: model.onChangeService, segments: model.segmentsById, service: service, servicesState: model.services, isOpen: model.activeService === service.id, onOpen: model.setActiveService, onClose: function () { return model.setActiveService(null); } }));
    })));
};
export default TransferService;
