import { useMemo } from 'react';
import { parse } from 'date-fns';
import { API_DATE_FORMAT } from '../../../../utils';
import { useFlightsScheduleQuery } from '@websky/graphql';
import { isApolloErrorGuard } from './utils';
import { useConfig } from '../../../../context';
import { useTranslation } from 'react-i18next';
export var useAvailableDates = function (segment) {
    var _a = useFlightsScheduleQuery({
        variables: {
            parameters: {
                departure: segment === null || segment === void 0 ? void 0 : segment.departure.iata,
                arrival: segment === null || segment === void 0 ? void 0 : segment.arrival.iata
            }
        },
        skip: !segment
    }), availableDates = _a.data, refetch = _a.refetch, error = _a.error, loading = _a.loading;
    var dates = useMemo(function () {
        if (availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule.datesData) {
            return availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule.datesData.filter(function (date) { return date === null || date === void 0 ? void 0 : date.date; }).map(function (date) { return parse(date.date, API_DATE_FORMAT, new Date()); });
        }
        return [];
    }, [availableDates === null || availableDates === void 0 ? void 0 : availableDates.FlightsSchedule]);
    return {
        dates: dates,
        isLoading: loading,
        error: error,
        refetch: refetch
    };
};
export var useHtmlDescriptionOfError = function (errors, overrides) {
    var _a, _b;
    var _c = useConfig().Engine, checkInURL = _c.checkInURL, checkInEnabled = _c.checkInEnabled;
    var t = useTranslation('Exchange').t;
    var header = null, body = null;
    var error = isApolloErrorGuard(errors) ? (_b = (_a = errors.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message : errors === null || errors === void 0 ? void 0 : errors[0];
    if (!error) {
        return null;
    }
    if (overrides === null || overrides === void 0 ? void 0 : overrides[error]) {
        return overrides[error];
    }
    if (error.includes('Exchange operations with checked segments are not permitted')) {
        header = t("It is not possible to exchange the ticket on which the check-in was made for the flight");
        body = checkInEnabled
            ? "".concat(t('If you wish to exchange your ticket, please cancel your flight check-in.'), "\n\t\t\t\t").concat(t("You can do it yourself by going to <a href='{{checkInURL}}' target='_blank'>Online check-in</a>.", { checkInURL: checkInURL }), "\n\t\t\t\t").concat(t('Either select unused ticket segments for exchange.'))
            : "".concat(t('If you wish to exchange your ticket, please cancel your flight check-in.'), "\n\t\t\t\t").concat(t('Either select unused ticket segments for exchange.'));
    }
    else if (error.includes('Exchange operations with used segments are not permitted')) {
        header = t("It is not possible to exchange a used ticket");
        body = t('The ticket has been flown on, you cannot exchange the used ticket');
    }
    else if (error.includes('Exchange operation allowed only in manual mode')) {
        header = t('Partially used ticket can be exchanged only in manual mode');
        body = t("Contact the airline call-center to clarify the conditions of the exchange, as a part of the route was flown. Or select segments of an unused ticket for exchange.");
    }
    else {
        return null;
    }
    return {
        header: header,
        body: body
    };
};
