import { __assign, __read, __values } from "tslib";
import { ApolloLink } from 'apollo-boost';
import { UTM_MARKS_KEY, sessionStorageGet, sessionStorageSet } from './cache';
var paximizerUTMLink = new ApolloLink(function (operation, forward) {
    var e_1, _a, _b;
    var utmMarksFromStorage = sessionStorageGet(UTM_MARKS_KEY);
    if (!(utmMarksFromStorage === null || utmMarksFromStorage === void 0 ? void 0 : utmMarksFromStorage.source)) {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('utm_source') === 'paximizer') {
            var utmMarks = {};
            try {
                for (var _c = __values(searchParams.entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                    if (key.startsWith('utm_')) {
                        utmMarks = __assign(__assign({}, utmMarks), (_b = {}, _b[key.replace('utm_', '')] = value, _b));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            sessionStorageSet(UTM_MARKS_KEY, utmMarks);
        }
    }
    return forward(operation);
});
export default paximizerUTMLink;
