export var Gender;
(function (Gender) {
    Gender[Gender["Male"] = 0] = "Male";
    Gender[Gender["Female"] = 1] = "Female";
})(Gender || (Gender = {}));
export var CombinedDirection;
(function (CombinedDirection) {
    CombinedDirection["Left"] = "left";
    CombinedDirection["Right"] = "right";
})(CombinedDirection || (CombinedDirection = {}));
