import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { parse } from 'date-fns';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import Datepicker from '../Datepicker/Datepicker';
import { ALL_FLIGHT } from '../../../store/flightsToExchange/reducers';
import { API_DATE_FORMAT } from '../../../../../utils';
import { getNewFlightSearchParams } from '../../../store/selectors';
var Choice = function (_a) {
    var items = _a.items, selectedKeys = _a.selectedKeys, _b = _a.controlType, controlType = _b === void 0 ? 'checkbox' : _b, onChange = _a.onChange;
    var theme = useTheme('Exchange').Choice;
    var searchParameters = useSelector(getNewFlightSearchParams);
    var searchParams = useSelector(getNewFlightSearchParams);
    var _c = React.useMemo(function () {
        var _a;
        var isAllFlight = false;
        var selectedItems = [];
        selectedKeys.forEach(function (selectedKey) {
            if (!isAllFlight && selectedKey.key === ALL_FLIGHT) {
                isAllFlight = true;
            }
            else {
                selectedItems = __spreadArray(__spreadArray([], __read(selectedItems), false), [selectedKey], false);
            }
        });
        var selectedIds = selectedKeys.map(function (item) { return item.key; });
        var firstUnselectedItem = isAllFlight ? (_a = selectedItems.filter(function (item) { return !item.date; })) === null || _a === void 0 ? void 0 : _a[0] : selectedItems[0];
        return { isAllFlight: isAllFlight, selectedIds: selectedIds, firstUnselectedItem: firstUnselectedItem };
    }, [selectedKeys]), isAllFlight = _c.isAllFlight, selectedIds = _c.selectedIds, firstUnselectedItem = _c.firstUnselectedItem;
    var filterDates = React.useMemo(function () {
        var _a;
        return (_a = items.filter(function (_a) {
            var key = _a.key, date = _a.date;
            return key !== ALL_FLIGHT && date;
        })) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
            var date = _a.date;
            return date;
        });
    }, [items]);
    var selectedDates = React.useMemo(function () {
        return searchParams.segments.map(function (segment) { return parse(segment.date, API_DATE_FORMAT, new Date()); });
    }, [searchParams]);
    var onChangeHandler = React.useCallback(function (item) { return function (_, checked) { return onChange({ key: item.key }, checked); }; }, [onChange]);
    var onChangeDate = React.useCallback(function (key, checked) { return function (date) { return onChange({ key: key, date: date }, checked); }; }, [onChange]);
    var ControlComponent = controlType === 'checkbox' ? Checkbox : Radio;
    return (React.createElement("div", { className: theme.container }, items.map(function (item, index) {
        var _a;
        var _b, _c, _d;
        var isSelected = selectedIds.includes(item.key);
        var isLastSegment = items.length > 1 && index === items.length - 1;
        var selectedDate = (_b = selectedKeys.find(function (selectedItem) { return selectedItem.key === item.key; })) === null || _b === void 0 ? void 0 : _b.date;
        var segment = (_c = searchParameters.segments) === null || _c === void 0 ? void 0 : _c[0];
        if (isAllFlight && isSelected) {
            segment = (_d = searchParameters.segments) === null || _d === void 0 ? void 0 : _d[item.key];
        }
        var minDate = new Date();
        if (isLastSegment) {
            if (isAllFlight && selectedDates.length) {
                minDate = selectedDates[0];
            }
            else if (filterDates.length) {
                minDate = filterDates[0];
            }
        }
        return (React.createElement(FormControlLabel, { key: item.key, control: React.createElement(ControlComponent, { name: item.key, classes: {
                    checked: theme.button_checked,
                    root: theme.button
                }, checked: isSelected }), label: React.createElement(React.Fragment, null,
                item.value,
                item.date && (React.createElement(Datepicker, { key: selectedIds.join(''), className: theme.labelDate, segment: segment, date: selectedDate, minDate: minDate, selectedDates: selectedDates, isActive: isSelected && (firstUnselectedItem === null || firstUnselectedItem === void 0 ? void 0 : firstUnselectedItem.key) === item.key, onSelect: onChangeDate(item.key, isSelected) }))), onChange: onChangeHandler(item), checked: isSelected, classes: {
                root: cn(theme.label, (_a = {},
                    _a[theme.label_selected] = isSelected,
                    _a)),
                label: theme.labelInner
            } }));
    })));
};
export default Choice;
