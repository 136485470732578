import * as React from 'react';
import cn from 'classnames';
import Step from './Step/Step';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { useAvailableSteps } from '../hooks';
import Money from '../../Money';
import { Chevron, ShoppingCart } from '../../Icons';
initI18n('Stepbar');
var Stepbar = function (_a) {
    var steps = _a.steps, _b = _a.activeStep, activeStep = _b === void 0 ? 0 : _b, onCartClick = _a.onCartClick, totalPrice = _a.totalPrice, additional = _a.additional, renderAdditiotalStep = _a.renderAdditiotalStep, className = _a.className;
    var css = useTheme('Stepbar').Stepbar;
    var availableSteps = useAvailableSteps(steps);
    var stepsArray = [];
    for (var key in availableSteps) {
        if (availableSteps.hasOwnProperty(key)) {
            stepsArray.push(availableSteps[key]);
        }
    }
    return (React.createElement("div", { className: cn(className, css.stepbar) },
        React.createElement("div", { className: css.inner },
            React.createElement("div", { className: css.content },
                additional,
                stepsArray
                    .filter(function (step) { return !step.isHidden; })
                    .map(function (step, index) { return (React.createElement(Step, { key: index, id: index + 1, step: step, isPassed: index < activeStep, isActive: index === activeStep, isDisabled: step.isDisabled })); }),
                renderAdditiotalStep && renderAdditiotalStep()),
            onCartClick && (React.createElement("div", { onClick: onCartClick, className: css.cart },
                ShoppingCart,
                React.createElement(Money, { money: totalPrice }),
                Chevron)))));
};
export default Stepbar;
