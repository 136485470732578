import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Provider } from 'react-redux';
import Schedule from './Schedule/Schedule';
import { createStore } from '../store';
import { initI18n } from '../../utils';
initI18n('Schedule');
var Component = function (_a) {
    var defaultParameters = _a.defaultParameters, restProps = __rest(_a, ["defaultParameters"]);
    var store = createStore(defaultParameters);
    return (React.createElement(Provider, { store: store },
        React.createElement(Schedule, __assign({}, restProps))));
};
export default Component;
