import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import WarningModal from '../../../WarningModal';
export var CommunicationPageWrapper = function (_a) {
    var pagination = _a.pagination, type = _a.type, errorMessage = _a.errorMessage, children = _a.children;
    var css = useTheme('Communications').Communication;
    var t = useTranslation('Communications').t;
    if (errorMessage) {
        return (React.createElement(WarningModal, { isOpen: !!errorMessage, title: t('Oops'), content: t('Something went wrong'), errorMessage: errorMessage }));
    }
    else if (pagination.total === 0 && !pagination.hasMore) {
        return React.createElement("p", { className: css.empty }, t("Empty_".concat(type)));
    }
    return React.createElement(React.Fragment, null, children);
};
