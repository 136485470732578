import * as React from 'react';
import RequestForm from '../RequestForm/RequestForm';
import PromoLoader from '../../../PromoLoader';
import { useSteps } from '../Refund/hooks';
import { StepType } from '../../types';
import Price from '../Price/Price';
import Notes from '../Notes/Notes';
import StepsButtons from '../StepsButtons/StepsButtons';
import { useCallback } from 'react';
import { saveCustomer } from '../../store/customer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getRefundSeatReleaseMode } from '../../store/selectors';
import { ExareSeatReleaseMode } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
var RequestFormPage = function (props) {
    var css = useTheme('Refund').Refund;
    var t = useTranslation('Refund').t;
    var isLoading = props.isLoading, prices = props.prices;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var dispatch = useDispatch();
    var _a = useSteps(), currentStep = _a.currentStep, goToNextStep = _a.goToNextStep, goToPrevStep = _a.goToPrevStep;
    var seatReleaseMode = useSelector(getRefundSeatReleaseMode);
    var handleSaveCustomer = useCallback(function (_a) {
        var comments = _a.comments, email = _a.email, name = _a.name, phone = _a.phone;
        dispatch(saveCustomer({ comments: comments, name: name, email: email, phone: phone }));
        goToNextStep();
    }, [goToNextStep]);
    var isActive = currentStep.type === StepType.CreateTicket;
    var showSeatsWillBeCanceledNotice = seatReleaseMode === ExareSeatReleaseMode.Off;
    var renderNotes = useCallback(function () {
        return React.createElement(Notes, { isAutoMode: showSeatsWillBeCanceledNotice, showReturnMessage: false });
    }, [showSeatsWillBeCanceledNotice]);
    if (isLoading && isActive) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement(React.Fragment, null, isActive && (React.createElement(RequestForm, { onSubmit: handleSaveCustomer, renderPrice: !isLoading && prices ? function () { return React.createElement(Price, { className: css.prices, price: prices }); } : null, renderNotes: renderNotes, renderButtons: function (valid, handleSubmit) { return (React.createElement(StepsButtons, { className: css.buttons, position: 'center', backButton: isMobile
                ? null
                : { children: t('Back'), onClick: goToPrevStep, className: css.backButton }, nextButton: {
                children: t("Refund_".concat(currentStep.type)),
                onClick: handleSubmit,
                disabled: !valid
            } })); } }))));
};
export default RequestFormPage;
