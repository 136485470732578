import React from 'react';
import { CommunicationDetailPageWrapper } from '../CommunicationDetailPageWrapper/CommunicationDetailPageWrapper';
import { PaximizerCommunicationSectionEnum, useGetPaxOrderNotificationTeasersContentQuery } from '@websky/graphql';
import { useParams } from 'react-router-dom';
import { useCommunicationDetail, useQueryParams } from '../../hooks';
export var OrderDetailPage = function () {
    var _a;
    var detailId = useParams().detailId;
    var query = useQueryParams();
    var _b = useGetPaxOrderNotificationTeasersContentQuery({
        variables: {
            messageId: detailId,
            locatorId: query.get('locator')
        }
    }), data = _b.data, loading = _b.loading, error = _b.error;
    useCommunicationDetail(PaximizerCommunicationSectionEnum.order_notifications, data === null || data === void 0 ? void 0 : data.PaximizerCommunicationOrderNotificationContent);
    return (React.createElement(CommunicationDetailPageWrapper, { error: !!(error === null || error === void 0 ? void 0 : error.graphQLErrors.length), loading: loading, body: (_a = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationOrderNotificationContent) === null || _a === void 0 ? void 0 : _a.body }));
};
