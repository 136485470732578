import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../../theme';
import NotificationRoot from '../../../Notification';
var Notification = function (props) {
    var _a;
    var theme = useTheme('EditPassenger').Notification;
    return (React.createElement(NotificationRoot, __assign({}, props, { className: cn(theme.message, (_a = {},
            _a[theme.message_info] = props.status === InfoMessageTypeEnum.Info,
            _a[theme.message_warning] = props.status === InfoMessageTypeEnum.Warning,
            _a)), contextClassName: theme.message_context, iconClassName: theme.message__icon, titleClassName: theme.message__title, textClassName: theme.message__text })));
};
export default Notification;
