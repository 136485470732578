import { __awaiter, __generator, __read } from "tslib";
import { CheckinStep, useSteps } from '../../../utils';
import { useSelector } from 'react-redux';
import { getCheckinIsBlockedForPayment, getCheckinOrderId } from '../../../store/order/selectors';
import { useGetCheckinOrderQuery, useResetOrderPaymentBlockMutation } from '@websky/graphql';
export var useUnlockCheckinOrder = function (setLoading) {
    var checkinIsBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    var orderId = useSelector(getCheckinOrderId);
    var refetch = useGetCheckinOrderQuery({ skip: true }).refetch;
    var _a = useSteps(), setStep = _a.setStep, goToNextStep = _a.goToNextStep;
    var _b = __read(useResetOrderPaymentBlockMutation(), 1), resetOrderPaymentBlock = _b[0];
    return function () { return __awaiter(void 0, void 0, void 0, function () {
        var orderIsUnlocked;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!checkinIsBlockedForPayment) return [3 /*break*/, 4];
                    setLoading === null || setLoading === void 0 ? void 0 : setLoading(true);
                    return [4 /*yield*/, resetOrderPaymentBlock({
                            variables: {
                                id: orderId
                            }
                        })];
                case 1:
                    orderIsUnlocked = (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.ResetOrderPaymentBlock;
                    if (!orderIsUnlocked) return [3 /*break*/, 3];
                    // Fetch new order info.
                    return [4 /*yield*/, refetch({ id: orderId })];
                case 2:
                    // Fetch new order info.
                    _c.sent();
                    // Redirect to services page.
                    setStep(CheckinStep.Baggage);
                    _c.label = 3;
                case 3:
                    setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    goToNextStep(CheckinStep.Passengers);
                    _c.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
