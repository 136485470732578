import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Switch } from '../../index';
import { useTheme } from '../../theme';
import SimpleLoader from '../../SimpleLoader';
import { initI18n } from '../../utils';
import { computeServicesMap, getAvailableSegments, getIsDifferentServicesOrPassengers, getNewSelectedServices } from '../utils';
import Control from './Control/Control';
import { useToggleable } from '../../hooks';
import SegmentCollapse from '../../AdditionalServiceWrapper/components/SegmentCollapse/SegmentCollapse';
import Money from '../../Money';
import { useVipServiceState } from '../hooks';
import { useMemo } from 'react';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { BaseButton } from '../../index';
initI18n('VipServiceSelect');
var VipServiceSelect = function (_a) {
    var className = _a.className, headerClassName = _a.headerClassName, passengers = _a.passengers, services = _a.services, segments = _a.segments, header = _a.header, loading = _a.loading, showFromPrice = _a.showFromPrice, showTotalPrice = _a.showTotalPrice, onSave = _a.onSave, selectedServices = _a.selectedServices, onClose = _a.onClose, subheader = _a.subheader, _b = _a.type, type = _b === void 0 ? 'switch' : _b, renderPassenger = _a.renderPassenger, readOnly = _a.readOnly;
    var theme = useTheme('VipServiceSelect').VipServiceSelect;
    var t = useTranslation('VipServiceSelect').t;
    var servicesMap = useMemo(function () { return computeServicesMap(services, selectedServices); }, [services, selectedServices]);
    var availableSegments = useMemo(function () { return getAvailableSegments(segments, passengers, servicesMap); }, [
        segments,
        passengers,
        servicesMap
    ]);
    var isDifferentServicesOrPassengers = getIsDifferentServicesOrPassengers(servicesMap);
    var _c = useVipServiceState(passengers, services, selectedServices), state = _c.state, minPrice = _c.minPrice, totalPrice = _c.totalPrice, isDifferentState = _c.isDifferentState, dispatch = _c.dispatch;
    var _d = useToggleable(availableSegments.length > 1 || isDifferentServicesOrPassengers), differentSegment = _d.isOpen, toggleDifferentSegment = _d.toggle;
    var differentSegmentToggle = function () {
        if (differentSegment) {
            passengers.forEach(function (passenger) {
                availableSegments.forEach(function (segment) {
                    dispatch({
                        count: 0,
                        passengerId: passenger.id,
                        segmentId: segment.segmentId
                    });
                });
            });
        }
        toggleDifferentSegment();
    };
    var onSaveHandler = function () {
        if (isDifferentState) {
            var newServices = getNewSelectedServices(servicesMap, state, !differentSegment);
            return onSave(newServices);
        }
        else {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    React.useEffect(function () {
        var _a;
        if (!!(services === null || services === void 0 ? void 0 : services.length)) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.VipList, { subType: (_a = services === null || services === void 0 ? void 0 : services[0]) === null || _a === void 0 ? void 0 : _a.subType });
        }
    }, []);
    var onSetCount = function (passenger) { return function (count) {
        var segments = __spreadArray([], __read((differentSegment ? passenger.segmentId : availableSegments.map(function (segment) { return segment.segmentId; }))), false);
        segments.forEach(function (segmentId) {
            dispatch({
                count: count,
                passengerId: passenger.passengerId,
                segmentId: segmentId
            });
        });
    }; };
    var renderPassengerHandler = function (passenger) {
        var _a, _b, _c, _d, _e, _f;
        var count = (_b = (_a = state[passenger.segmentId]) === null || _a === void 0 ? void 0 : _a[passenger.passengerId]) !== null && _b !== void 0 ? _b : 0;
        var confirmedCount = (_f = (_e = (_d = (_c = selectedServices === null || selectedServices === void 0 ? void 0 : selectedServices[passenger.passengerId]) === null || _c === void 0 ? void 0 : _c[passenger.serviceId]) === null || _d === void 0 ? void 0 : _d.find(function (selectedVip) {
            return selectedVip === null || selectedVip === void 0 ? void 0 : selectedVip.segments.includes(passenger.segmentId);
        })) === null || _e === void 0 ? void 0 : _e.servicePayload) === null || _f === void 0 ? void 0 : _f.confirmedCount;
        var isConfirmed = confirmedCount > 0;
        var canBeAdded = services.find(function (service) { return service.id === passenger.serviceId; }).canBeAdded;
        var isCanModify = canBeAdded || count > confirmedCount;
        if (renderPassenger) {
            return renderPassenger({ passenger: passenger, type: type, count: count, isConfirmed: isConfirmed, onSetCount: onSetCount });
        }
        return (React.createElement(Control, { key: passenger.passengerId, type: type, count: count, disabled: isConfirmed || !isCanModify, label: React.createElement("div", { className: theme.passenger },
                React.createElement("div", { className: theme.number }, parseInt(passenger.passengerId) + 1),
                passenger.name), onSetCount: onSetCount(passenger) }));
    };
    var getDirectionName = function (segments) {
        return [segments[0].departure, segments[segments.length - 1].arrival].join(' – ');
    };
    return (React.createElement("div", { className: cn(theme.container, className) },
        loading && React.createElement(SimpleLoader, null),
        header && (React.createElement("div", { className: cn(theme.header, headerClassName) },
            header,
            showFromPrice && minPrice && (React.createElement("div", { className: theme.price_from },
                t('from'),
                React.createElement(Money, { money: minPrice }))))),
        subheader && React.createElement("div", { className: theme.subheader }, subheader),
        React.createElement("div", { className: theme.content }, (differentSegment ? availableSegments : availableSegments.slice(0, 1)).map(function (segment) { return (React.createElement(SegmentCollapse, { key: segment.segmentId, className: theme.segment, date: segment.date, info: getDirectionName(differentSegment ? [segment] : availableSegments), isActive: true }, segment.passengers.map(renderPassengerHandler))); })),
        (availableSegments.length > 1 || showTotalPrice) && (React.createElement("div", { className: theme.footer },
            availableSegments.length > 1 && (React.createElement("div", { className: theme.switch__wrapper },
                React.createElement(Switch, { switchPosition: "right", label: t('Different for other flight?'), labelClassName: theme.onlySegment, isActive: differentSegment, mainClassName: theme.onlySegment__switch, activeClassName: theme.switch__container_active, disableClassName: theme.switch__container_disable, isDisabled: isDifferentServicesOrPassengers, onClick: differentSegmentToggle }))),
            showTotalPrice && totalPrice && (React.createElement("div", { className: theme.total },
                t('Total'),
                ":",
                React.createElement(Money, { money: totalPrice }))))),
        React.createElement("div", { className: theme.controls },
            React.createElement(BaseButton, { variant: "smallSecondary", className: theme.cancel, onClick: onClose }, t('Cancel')),
            !readOnly && (React.createElement(BaseButton, { onClick: onSaveHandler, variant: "smallThird", className: theme.confirm }, t('Confirm'))))));
};
export default VipServiceSelect;
