import React from 'react';
import { useTheme } from '../../../theme';
import { Group } from './Group/Group';
import { UnreadDot } from './UnreadDot/UnreadDot';
import { Info } from './Info/Info';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import cn from 'classnames';
import Loader from '../../../Loader';
import { useTranslation } from 'react-i18next';
export var NotificationCard = function (props) {
    var _a;
    var css = useTheme('Communications').NotificationCard;
    var t = useTranslation('Communications').t;
    var onClick = function (e) {
        e.preventDefault();
        props.markAsRead();
    };
    var renderDot = function () {
        return !props.isRead && (React.createElement("div", { onClick: onClick, title: t('Mark as read'), className: css.card__dot },
            React.createElement(UnreadDot, null)));
    };
    return (React.createElement("div", { className: cn(css.card, (_a = {},
            _a[css.card_unread] = !props.isRead,
            _a)) },
        React.createElement("div", { className: css.card__wrapper },
            (props.title || props.date) && (React.createElement(Group, null,
                props.title && React.createElement("div", { className: css.card__title }, props.title),
                props.date && React.createElement(Info, { align: "right" }, props.date))),
            props.preDescRender && props.preDescRender(),
            React.createElement(Group, { className: css.card__group_content },
                props.children,
                React.createElement(MediaQuery, { maxWidth: 'mobile' }, renderDot()))),
        React.createElement(MediaQuery, { minWidth: 'mobile' }, renderDot()),
        props.loading && React.createElement(Loader, { className: css.card__loading, hideText: true })));
};
