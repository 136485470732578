var _a;
import { __read, __spreadArray } from "tslib";
import { useMemo } from 'react';
import { OrderStatus } from '@websky/graphql';
import { CheckoutSteps } from './types';
import { CartService } from '../Cart/types';
import { convertCartServiceProps, isCheckoutOrder as getIsCheckoutOrder } from './utils';
var CART_SERVICE_MAP = (_a = {},
    _a[CheckoutSteps.Flights] = CartService.Flights,
    _a[CheckoutSteps.Seats] = CartService.Seats,
    _a[CheckoutSteps.Baggage] = CartService.Baggage,
    _a[CheckoutSteps.Meal] = CartService.Meal,
    _a[CheckoutSteps.Extras] = CartService.Extra,
    _a[CheckoutSteps.Passengers] = CartService.Passengers,
    _a[CheckoutSteps.Insurance] = CartService.Insurances,
    _a);
export var useMobileStepbar = function (order, stepbarConfig) {
    var isCheckoutOrder = getIsCheckoutOrder(order);
    var isBookedOrConfirmed = useMemo(function () {
        return order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed;
    }, [order.status]);
    var availableSteps = useMemo(function () {
        return Object.values(stepbarConfig !== null && stepbarConfig !== void 0 ? stepbarConfig : {}).filter(function (step) { return !step.isHidden; });
    }, [stepbarConfig]);
    var _a = __read(useMemo(function () {
        var cartServicesSet = new Set();
        var additionalStepsSet = new Set();
        availableSteps.forEach(function (step) {
            var cartService = CART_SERVICE_MAP[step.type];
            if (cartService !== undefined) {
                cartServicesSet.add(cartService);
            }
            else {
                additionalStepsSet.add(step.type);
            }
        });
        if (isBookedOrConfirmed) {
            if (!cartServicesSet.has(CartService.Passengers) && additionalStepsSet.has(CheckoutSteps.Order)) {
                cartServicesSet.add(CartService.Passengers);
                additionalStepsSet.delete(CheckoutSteps.Order);
            }
            if (isCheckoutOrder) {
                cartServicesSet.add(CartService.Extra);
            }
        }
        return [__spreadArray([], __read(cartServicesSet), false), __spreadArray([], __read(additionalStepsSet), false)];
    }, [order, availableSteps, isBookedOrConfirmed, isCheckoutOrder]), 2), cartServices = _a[0], additionalSteps = _a[1];
    var cartServiceProps = useMemo(function () { return convertCartServiceProps(order); }, [order]);
    return {
        cartServiceProps: cartServiceProps,
        cartServices: cartServices,
        availableSteps: availableSteps,
        additionalSteps: additionalSteps,
        isBookedOrConfirmed: isBookedOrConfirmed,
        isCheckoutOrder: isCheckoutOrder
    };
};
