import { onError } from 'apollo-link-error';
import { SHOW_RECAPTCHA, captureErrorSentry, captureEventSentry } from './utils';
import { ApolloErrorType } from './types';
export var APOLLO_ERRORS_EVENT = 'apollo-errors-event';
export var ACCESS_DENIED_ERROR_EVENT = 'access-denied-error-event';
var ignoredOperations = new Set(['GetTravellerProfiles']);
var seatErrorRegExp = new RegExp("^Seat \\S+ not available");
var SeatNotAvailable = 'Selected seat is not available';
export var pnrBlockedRegExp = /The PNR is blocked by another user \(.*\). Please try again later\./m;
var pnrBlockedMessage = 'The service is temporarily unavailable. It is possible that the order is open on another device/browser or being handled by an airline specialist. Please try again later.';
var isAccessDeniedError = function (error) {
    return Array.isArray(error) ? error.some(function (e) { return /Access denied/.test(e); }) : false;
};
var isIgnoredErrors = function (graphQLErrors) {
    return graphQLErrors.some(function (err) {
        if (isAccessDeniedError(err)) {
            return true;
        }
        return false;
    });
};
var errorLink = onError(function (_a) {
    var networkError = _a.networkError, operation = _a.operation, graphQLErrors = _a.graphQLErrors, response = _a.response, forward = _a.forward;
    if (networkError && networkError['statusCode']) {
        var statusCode = parseInt(networkError['statusCode']);
        if (statusCode === 429) {
            document.dispatchEvent(new CustomEvent(SHOW_RECAPTCHA));
        }
        else if (statusCode >= 500) {
            if (!ignoredOperations.has(operation.operationName) && !isIgnoredErrors(graphQLErrors)) {
                var event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: "".concat(operation.operationName, ": ").concat(networkError.message ? networkError.message : "Server responded with code = ".concat(statusCode, "."))
                    }
                });
                captureEventSentry(event);
                document.dispatchEvent(event);
            }
        }
    }
    if ((graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.length) > 0) {
        graphQLErrors.forEach(function (err) {
            if (pnrBlockedRegExp.test(err.message)) {
                var event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: pnrBlockedMessage,
                        type: ApolloErrorType.PnrBlocked
                    }
                });
                document.dispatchEvent(event);
            }
            else if (seatErrorRegExp.test(err.message)) {
                var event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: SeatNotAvailable
                    }
                });
                document.dispatchEvent(event);
            }
            else if (isAccessDeniedError(err)) {
                var event = new window.CustomEvent(ACCESS_DENIED_ERROR_EVENT);
                document.dispatchEvent(event);
                captureErrorSentry('Access denied error');
            }
        });
    }
});
export default errorLink;
