import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money as MoneyComponent } from '../../../Money';
import { MealViewMode } from '../../types';
import { initI18n, useIsDesktop, useIsMobile } from '../../../utils';
import { useTheme } from '../../../theme';
import Modal from '../../../Modal';
import Details from '../Details/Details';
import CloseIcon from '../../../CloseIcon/components/CloseIcon';
import { OverrideComponent } from '../../../renderProps';
initI18n('Meal');
var Meal = function (_a) {
    var _b, _c;
    var meal = _a.meal, marker = _a.marker, oldPrice = _a.oldPrice, _d = _a.counters, counters = _d === void 0 ? [] : _d, onIncrease = _a.onIncrease, onDecrease = _a.onDecrease, onClear = _a.onClear, onConfirm = _a.onConfirm, readonly = _a.readonly, fullSize = _a.fullSize, isLoading = _a.isLoading, isCountChanged = _a.isCountChanged, onAddForAll = _a.onAddForAll, onPopupOpened = _a.onPopupOpened, mode = _a.mode, onClose = _a.onClose;
    var theme = useTheme('Meal').Meal;
    var t = useTranslation('Meal').t;
    var totalNumberOfMeals = counters.reduce(function (total, counter) { return counter.count + total; }, 0);
    var _e = __read(useState(false), 2), modalIsVisible = _e[0], setModalIsVisible = _e[1];
    var isMobile = useIsMobile();
    var isDesktop = useIsDesktop();
    var openModal = function () {
        setModalIsVisible(true);
        if (onPopupOpened) {
            onPopupOpened(meal);
        }
    };
    var closeModal = function () { return setModalIsVisible(false); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(theme.meal, (_b = {},
                _b[theme.meal_fullSize] = fullSize && isDesktop,
                _b)), onClick: openModal },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.image, style: meal.icon ? { backgroundImage: "url(".concat(meal.icon, ")") } : {} }, (!fullSize || !isDesktop) && marker && React.createElement("div", { className: theme.marker }, marker)),
                (!fullSize || !isDesktop) && (React.createElement("div", { className: theme.title },
                    React.createElement("div", { className: cn(theme.name, (_c = {}, _c[theme.hasWeight] = true, _c)) }, meal.name)))),
            React.createElement("div", { className: theme.footer },
                fullSize && isDesktop && marker && React.createElement("div", { className: theme.marker }, marker),
                fullSize && isDesktop && (React.createElement("div", { className: theme.title },
                    React.createElement("div", { className: theme.name }, meal.name))),
                React.createElement("div", { className: theme.footer__icons },
                    !!(oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount) && React.createElement(MoneyComponent, { moneyClassName: theme.oldPrice, money: oldPrice }),
                    !!meal.price.amount ? (React.createElement(MoneyComponent, { moneyClassName: theme.price, money: meal.price })) : (React.createElement("span", { className: theme.price }, t('Free'))),
                    totalNumberOfMeals > 0 && React.createElement("div", { className: theme.count },
                        "x",
                        totalNumberOfMeals)))),
        React.createElement(Modal, { open: modalIsVisible && mode !== MealViewMode.confirmed, maxWidth: "sm", fullScreen: isMobile, scroll: isMobile ? 'paper' : 'body', slideUp: isMobile, onClose: function () {
                onClose === null || onClose === void 0 ? void 0 : onClose(meal);
                closeModal();
            }, disableAutoFocus: true, classes: {
                scrollPaper: theme.popup__scrollPaper,
                root: theme.popup__root,
                paper: theme.popup
            }, closeButtonRenderer: function (onClick) { return (React.createElement("div", { className: theme.popup__close },
                React.createElement(CloseIcon, { onClick: onClick }))); } },
            React.createElement(OverrideComponent, { componentProps: {
                    onAddForAll: onAddForAll,
                    marker: marker,
                    meal: meal,
                    oldPrice: oldPrice,
                    counters: counters,
                    onDecrease: onDecrease,
                    onIncrease: onIncrease,
                    onClear: onClear,
                    readonly: readonly,
                    isLoading: isLoading,
                    isCountChanged: isCountChanged,
                    onSubmit: function () {
                        onConfirm(meal);
                        closeModal();
                    }
                }, component: { MealDetails: Details } }))));
};
export default Meal;
