import * as React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedServices } from '../../store/selector';
var getCount = function (selectedBaggage) { return selectedBaggage.reduce(function (count, item) { return item.count + count; }, 0); };
var DynamicBaggagePricing = function (props) {
    var selectedBaggageFromState = useSelector(getSelectedServices), lastStateBaggageCount = React.useRef(getCount(props.selectedServices));
    React.useEffect(function () {
        var baggageCount = getCount(selectedBaggageFromState);
        if (props.dynamicBaggagePricing && lastStateBaggageCount.current !== baggageCount) {
            props.onConfirm(selectedBaggageFromState);
        }
        lastStateBaggageCount.current = baggageCount;
    }, [props.dynamicBaggagePricing, selectedBaggageFromState]);
    return React.createElement(React.Fragment, null, props.children);
};
export default DynamicBaggagePricing;
