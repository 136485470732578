export var isPassengersHeightExceedViewport = function (passengersRect) {
    var segmentTabs = document.getElementById('segment-tabs');
    var toolbar = document.getElementById('toolbar');
    if (!segmentTabs || !toolbar) {
        return false;
    }
    var viewPort = toolbar.getBoundingClientRect().top - segmentTabs.getBoundingClientRect().bottom;
    var margin = 40;
    return passengersRect.height + margin > viewPort;
};
