var _a;
import { __assign } from "tslib";
import { handleAction, handleActions } from 'redux-actions';
import { saveCustomer } from './actions';
var initialState = null;
var handleSaveCustomer = handleAction(saveCustomer, function (state, _a) {
    var payload = _a.payload;
    return (__assign({}, payload));
}, initialState);
export default handleActions((_a = {},
    _a[saveCustomer.toString()] = handleSaveCustomer,
    _a), initialState);
