import { __assign } from "tslib";
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { addFlightIds, changeFlightId, removeFlightIds } from '../../../store/flightsToExchange/actions';
import { getOrder, getPassengers } from '../../../store/order/selectors';
import { getExchangeFlights, getFlightIdsToExchange, getIsExchangeFlightsSelected } from '../../../store/flightsToExchange/selectors';
import CommonSelect from '../CommonSelect/CommonSelect';
import { flightIcon } from '../icons';
import { ALL_FLIGHT } from '../../../store/flightsToExchange/reducers';
import { ExchangeStep } from '../../../types';
var Segments = function (_a) {
    var setStep = _a.setStep, passengersPageURL = _a.passengersPageURL;
    var t = useTranslation('Exchange').t;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var passengers = useSelector(getPassengers);
    var selectedFlights = useSelector(getFlightIdsToExchange);
    var exchangeFlights = useSelector(getExchangeFlights);
    var isExchangeFlightsSelected = useSelector(getIsExchangeFlightsSelected);
    var history = useHistory();
    var onChange = React.useCallback(function (value, selected) {
        var _a;
        var isAllFlightKey = value.key === ALL_FLIGHT;
        var isAllFlightFlights = selectedFlights.some(function (flight) { return flight.key === ALL_FLIGHT; });
        if (isAllFlightKey) {
            if (selected) {
                dispatch(addFlightIds(exchangeFlights.map(function (flight) { return ({ key: flight.key }); })));
            }
            else {
                dispatch(removeFlightIds(exchangeFlights.map(function (flight) { return flight.key; })));
            }
        }
        else if (isAllFlightFlights) {
            if (value.date) {
                var firstFlight_1 = (_a = selectedFlights.filter(function (flight) { return flight.date; })) === null || _a === void 0 ? void 0 : _a[0];
                var flights = selectedFlights.map(function (flight) {
                    var isFirstFlight = flight.key === (firstFlight_1 === null || firstFlight_1 === void 0 ? void 0 : firstFlight_1.key);
                    if (flight.key === value.key) {
                        return __assign(__assign({}, flight), { date: value.date });
                    }
                    else if (flight.date && !isFirstFlight) {
                        return __assign(__assign({}, flight), { date: isAfter(value.date, flight.date) ? value.date : flight.date });
                    }
                    return flight;
                });
                dispatch(addFlightIds(flights));
            }
            else {
                var filterFlights = selectedFlights.filter(function (flight) { return flight.key !== value.key; });
                if (selected) {
                    dispatch(addFlightIds(filterFlights));
                }
                else {
                    dispatch(addFlightIds([{ key: value.key }]));
                }
            }
        }
        else {
            if (value.date) {
                dispatch(changeFlightId(value));
            }
            else {
                if (selected) {
                    dispatch(addFlightIds([value]));
                }
                else {
                    dispatch(removeFlightIds([value.key]));
                }
            }
        }
    }, [selectedFlights, exchangeFlights]);
    var onNextHandler = function () {
        setStep(ExchangeStep.NewFlights);
    };
    var onBackHandler = function () {
        var _a;
        var isOnlyPassenger = passengers.length === 1;
        if (((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) && isOnlyPassenger) {
            setStep(ExchangeStep.Request);
        }
        else if (isOnlyPassenger) {
            history.push(passengersPageURL);
        }
        else {
            setStep(ExchangeStep.Passengers);
        }
    };
    return (React.createElement(CommonSelect, { icon: flightIcon, name: exchangeFlights.length > 1 ? t('Select flights') : t('Select a new date'), items: exchangeFlights, selectedKeys: selectedFlights, onChange: onChange, disableButton: !isExchangeFlightsSelected, onNextHandler: onNextHandler, onBackHandler: onBackHandler }));
};
export default Segments;
