import { REMOVE_VOUCHER, SET_VOUCHER } from './actions';
var initialState = {
    code: '',
    status: null,
    money: null
};
export var vouchersReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case REMOVE_VOUCHER:
            return initialState;
        case SET_VOUCHER:
            return action.payload;
        default:
            return state;
    }
};
