import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CloseClear as clearIcon } from '../../Icons';
import AdditionalService from '../../AdditionalServiceCard/components/AdditionalService';
import { DisplayMode } from '../../AdditionalServices/components/types';
import { alfaInsuranceLogo, UmbrellaIcon } from '../../AdditionalServices/components/Insurance/logo';
import { getCheckinOrderId, getConfirmedInsurances, getMinInsurancePrice, getTotalInsurancePrice } from '../../Modules/Checkin/store/order/selectors';
import DialogContent from '../../AdditionalServices/components/Insurance/DialogContent/DialogContent';
import DownloadInsurances from '../../AdditionalServices/components/Insurance/DownloadInsurances/DownloadInsurances';
import WarningModal from '../../WarningModal';
import Insurance from '../Insurance';
import { Money } from '../../index';
import { useInsurance } from '../../CheckoutAdditionalService/components/Insurance/hooks';
import { fillCheckinOrder } from '../../Modules/Checkin/store/order/actions';
import { useCheckinSaveOrderServicesMutation } from '@websky/graphql';
import { useTheme } from '../../theme';
import { initI18n, useIsMobile } from '../../utils';
import Modal from '../../Modal';
initI18n('Checkin');
var CheckinMultiInsurance = function (props) {
    var _a, _b;
    var displayMode = props.displayMode, insurances = props.insurances, travellers = props.travellers;
    var css = useTheme('Insurance').CheckinInsurance;
    var t = useTranslation('Checkin').t;
    var dispatch = useDispatch();
    var orderId = useSelector(getCheckinOrderId);
    if (!((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) && !((_b = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances) === null || _b === void 0 ? void 0 : _b.length)) {
        return null;
    }
    var insuranceMinPrice = useSelector(getMinInsurancePrice);
    var insuranceTotalPrice = useSelector(getTotalInsurancePrice);
    var confirmedInsurances = useSelector(getConfirmedInsurances);
    var hasConfirmedInsurances = !!confirmedInsurances.length;
    var hasSelectedInsurances = useMemo(function () { var _a; return ((_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) > 0; }, [insurances]);
    var isMobile = useIsMobile();
    var programCodesToProductsCountMap = new Map();
    insurances.selectedInsurances.forEach(function (SelectedInsuranceFragment) {
        var programCode = SelectedInsuranceFragment.insuranceProgram.code;
        programCodesToProductsCountMap.set(programCode, (programCodesToProductsCountMap.get(programCode) || 0) + 1);
    });
    var _c = __read(React.useState(null), 2), error = _c[0], setError = _c[1];
    var _d = __read(useCheckinSaveOrderServicesMutation({}), 2), saveCheckinOrderService = _d[0], loading = _d[1].loading;
    var onUpdateOrder = function (data) {
        dispatch(fillCheckinOrder(data.CheckinSaveOrderServices));
    };
    var _e = useInsurance({
        orderId: orderId,
        insurances: insurances,
        insurancePrograms: insurances.insurancePrograms,
        passengers: travellers,
        onSaveServices: saveCheckinOrderService,
        onUpdateOrder: onUpdateOrder
    }), 
    // common
    multiInsurance = _e.multiInsurance, insuranceRulesUrl = _e.insuranceRulesUrl, insuranceDocuments = _e.insuranceDocuments, passengerDocuments = _e.passengerDocuments, onSave = _e.onSave, onRemoveInsurance = _e.onRemoveInsurance, 
    // insurance modal
    insuranceModalIsOpen = _e.insuranceModalIsOpen, openInsuranceModal = _e.openInsuranceModal, closeInsuranceModal = _e.closeInsuranceModal, 
    // confirmation modal
    confirmationModalIsOpen = _e.confirmationModalIsOpen, closeConfirmationModal = _e.closeConfirmationModal, 
    // download modal
    downloadModalIsOpen = _e.downloadModalIsOpen, openDownloadModal = _e.openDownloadModal, closeDownloadModal = _e.closeDownloadModal;
    var onSaveHandler = function (state) {
        onSave(state.selectedInsurances.map(function (insurance) { return insurance.code; }, state.passengers));
    };
    var clearError = function () {
        setError(null);
    };
    var renderSelectedInsurance = useCallback(function (insurance) { return (React.createElement("div", { key: insurance.insuranceProgram.code, className: css.insurance },
        React.createElement("h3", { className: css.title },
            insurance.insuranceProgram.name,
            programCodesToProductsCountMap.has(insurance.insuranceProgram.code) && (React.createElement(React.Fragment, null,
                "\u00A0x",
                programCodesToProductsCountMap.get(insurance.insuranceProgram.code)))),
        React.createElement(Money, { money: insurance.passengerPrice }))); }, [insurances.selectedInsurances]);
    var renderServiceContent = useCallback(function () {
        if (insurances.selectedInsurances.length) {
            var uniqueSelectedInsurancesMap_1 = new Map();
            insurances.selectedInsurances.forEach(function (insurance) {
                var code = insurance.insuranceProgram.code;
                var existInsurance = uniqueSelectedInsurancesMap_1.get(code);
                if (programCodesToProductsCountMap.has(code)) {
                    if (existInsurance) {
                        uniqueSelectedInsurancesMap_1.set(code, __assign(__assign({}, existInsurance), { passengerPrice: __assign(__assign({}, existInsurance.passengerPrice), { amount: existInsurance.passengerPrice.amount + insurance.passengerPrice.amount }) }));
                    }
                    else {
                        uniqueSelectedInsurancesMap_1.set(code, insurance);
                    }
                }
            });
            var uniqueSelectedInsurances = __spreadArray([], __read(uniqueSelectedInsurancesMap_1.values()), false);
            return (React.createElement("div", null,
                uniqueSelectedInsurances.map(renderSelectedInsurance),
                uniqueSelectedInsurances.length === 1 && (React.createElement("div", { className: css.description, dangerouslySetInnerHTML: {
                        __html: uniqueSelectedInsurances[0].insuranceProgram.description
                    } }))));
        }
        return React.createElement("div", null, t('Inflight insurance or baggage insurance is your travel confidence.'));
    }, [insurances.selectedInsurances, programCodesToProductsCountMap]);
    var renderInsuranceModal = useCallback(function () {
        var _a;
        if (!multiInsurance) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Insurance, { insurances: insurances.insurancePrograms, selectedInsurances: insurances.selectedInsurances, open: insuranceModalIsOpen, rulesURL: insuranceRulesUrl, onSave: onSaveHandler, onClose: closeInsuranceModal, documentNeeds: insuranceDocuments, passengers: passengerDocuments, error: !!error, loading: loading }),
            React.createElement(WarningModal, { title: t('Oops, something went wrong'), content: t('An error occurred during the check-in process. Please try again later or contact a customer support service.'), errorMessage: t((_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : ''), isOpen: !!error, onClose: clearError, onButtonClick: clearError })));
    }, [travellers, insurances, insuranceModalIsOpen, loading]);
    var renderMobileStepbarInsurance = useCallback(function () {
        return (React.createElement("div", { className: css.priceWrapper, onClick: function () { return (hasSelectedInsurances ? onSave([], passengerDocuments) : closeInsuranceModal()); } },
            !hasSelectedInsurances && (React.createElement(React.Fragment, null,
                t('From'),
                React.createElement(Money, { moneyClassName: cn(css.price, css.insideStepBar), money: insuranceMinPrice }))),
            hasSelectedInsurances && (React.createElement("span", { className: css.iconsWrapper }, loading ? (React.createElement(CircularProgress, { size: 18, classes: { colorPrimary: css.loadingIconColor }, className: css.loadingIcon })) : (clearIcon)))));
    }, [insuranceModalIsOpen, displayMode, props.isMobileStepbar, loading, insurances]);
    var renderInsuranceTriggerCard = useCallback(function () {
        if (!props.isMobileStepbar) {
            return (React.createElement(AdditionalService, { horizontal: displayMode === DisplayMode.Horizontal, service: {
                    logo: React.createElement("div", { className: css.logo }, alfaInsuranceLogo),
                    header: 'Insurance',
                    price: hasSelectedInsurances ? insuranceTotalPrice : insuranceMinPrice,
                    subheader: null,
                    icon: UmbrellaIcon,
                    active: hasSelectedInsurances,
                    renderContent: renderServiceContent
                }, isLoading: loading, buttonText: hasConfirmedInsurances
                    ? t('Download')
                    : hasSelectedInsurances
                        ? t('Aeroexpress:Change')
                        : t('Select'), onClick: hasConfirmedInsurances ? openDownloadModal : openInsuranceModal }));
        }
        else {
            return renderMobileStepbarInsurance();
        }
    }, [props.isMobileStepbar, displayMode, insuranceMinPrice, insuranceModalIsOpen, loading]);
    return (React.createElement(React.Fragment, null,
        renderInsuranceTriggerCard(),
        renderInsuranceModal(),
        React.createElement(Modal, { maxWidth: "sm", classes: { paper: css.popup }, open: confirmationModalIsOpen, onClose: closeConfirmationModal, slideUp: isMobile },
            React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationModal, onInsuranceRemove: onRemoveInsurance, refusingIsInProgress: loading })),
        React.createElement(Modal, { maxWidth: "sm", classes: { paper: css.downloadPopup }, open: downloadModalIsOpen, onClose: closeDownloadModal, slideUp: isMobile },
            React.createElement(DownloadInsurances, { selectedInsurances: insurances.selectedInsurances }))));
};
export default CheckinMultiInsurance;
