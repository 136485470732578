import { __read, __values } from "tslib";
import { produce } from 'immer';
var reducer = function (state, _a) {
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case 'setActiveService':
            return produce(state, function (draft) {
                draft.activeService = payload;
                return draft;
            });
        case 'setService': {
            return produce(state, function (draft) {
                var e_1, _a;
                var _loop_1 = function (passenger) {
                    var e_2, _e;
                    var _loop_2 = function (segment) {
                        if (!payload[passenger][segment]) {
                            return "continue";
                        }
                        var relatedServiceIndex = draft.services[passenger][segment].findIndex(function (service) { return service.service.id === payload[passenger][segment].service.id; });
                        draft.services[passenger][segment][relatedServiceIndex] = payload[passenger][segment];
                    };
                    try {
                        for (var _f = (e_2 = void 0, __values(Object.keys(payload[passenger]))), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var _h = __read(_g.value, 1), segment = _h[0];
                            _loop_2(segment);
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_e = _f.return)) _e.call(_f);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                };
                try {
                    for (var _b = __values(Object.keys(payload)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var _d = __read(_c.value, 1), passenger = _d[0];
                        _loop_1(passenger);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return draft;
            });
        }
        case 'toggleTransfer':
            return produce(state, function (draft) {
                var stateService = state.services[payload.passengerId][payload.segmentId].find(function (service) { return service.service.id === payload.service.id; });
                stateService.selected = !stateService.selected;
                // тут можно добавить логику по добавлению на все сегменты из allowedSegments
                // но я пока не представляю в каких случаях на трансферных рейсах можно продавать такси
                // поэтому реализацию опустил
                return draft;
            });
        default:
            return state;
    }
};
export default reducer;
