import { __values } from "tslib";
import { isAfter, isBefore } from 'date-fns';
export var isSelectedOnlyReturnDate = function (selectedDates) { return !selectedDates[0] && Boolean(selectedDates[1]); };
export var isSelectedOnlyDepartureDate = function (selectedDates) { return selectedDates[0] && !selectedDates[1]; };
export var isDatesInReversedOrder = function (selectedDates) {
    return selectedDates[0] && selectedDates[1] && isAfter(selectedDates[0], selectedDates[1]);
};
export var isHoverDateBetweenSelectedDates = function (hoverDate, selectedDates) {
    if (!selectedDates[0] || !selectedDates[1]) {
        return false;
    }
    return isAfter(hoverDate, selectedDates[0]) && isBefore(hoverDate, selectedDates[1]);
};
export var getMinMaxPrice = function (prices) {
    var e_1, _a;
    var _b;
    var minPrice = null;
    var maxPrice = null;
    if (prices) {
        try {
            for (var _c = __values(Object.values(prices)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var value = _d.value;
                if (value.price) {
                    if (!minPrice || value.price.amount < minPrice) {
                        minPrice = value.price.amount;
                    }
                    if (!maxPrice || ((_b = value.price) === null || _b === void 0 ? void 0 : _b.amount) > maxPrice) {
                        maxPrice = value.price.amount;
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return [minPrice, maxPrice];
};
