import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
var Pagination = function (props) {
    var theme = useTheme('Pagination').Pagination;
    var renderItem = useCallback(function (item, index) {
        var _a;
        if (props.renderItem) {
            return props.renderItem(index, props.currentIndex === index, props.onChange);
        }
        else {
            return (React.createElement("div", { onClick: function () { return (props.onChange ? props.onChange(index) : null); }, "data-active": props.currentIndex === index, className: cn(theme.item, (_a = {}, _a[theme.item_active] = props.currentIndex === index, _a)), key: index }));
        }
    }, [props.itemsCount, props.currentIndex, props.renderItem]);
    return (React.createElement("div", { className: cn(theme.pagination, props.className) }, new Array(props.itemsCount).fill(null).map(renderItem)));
};
export default Pagination;
