import { __read, __spreadArray } from "tslib";
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../theme';
import { PhoneSuccess } from '../../../../../../Icons';
import { initI18n } from '../../../../../../utils';
initI18n('CheckinConditions');
var Conditions = function (_a) {
    var className = _a.className;
    var css = useTheme('Checkin').Conditions;
    var _b = useTranslation('CheckinConditions'), t = _b.t, i18n = _b.i18n;
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.title }, t('Check-in conditions')),
        React.createElement("div", { className: css.icon }, PhoneSuccess),
        React.createElement("ul", { className: css.conditions }, __spreadArray([], __read(Array(10)), false).map(function (_, index) {
            var key = "Rules_".concat(index);
            var isExistTranslate = i18n.exists(key, { ns: 'CheckinConditions' });
            if (!isExistTranslate) {
                return null;
            }
            return React.createElement("li", { key: index, dangerouslySetInnerHTML: { __html: t(key) } });
        }))));
};
export default Conditions;
