import { __values } from "tslib";
import { createSelector } from 'reselect';
import i18n from 'i18next';
import { getPassengersConfig } from '../passengersConfig/selectors';
export var getPassengersState = function (state) { return state.passengers; };
/**
 * Create an array of passengers info for displaying in the dropdown menu.
 */
export var getPassengers = createSelector(getPassengersState, function (passengers) {
    var result = [];
    for (var type in passengers) {
        if (passengers.hasOwnProperty(type)) {
            result.push({
                type: type,
                count: passengers[type] || 0
            });
        }
    }
    return result;
});
export var getPassengersForRequest = createSelector(getPassengers, getPassengersConfig, function (passengers, config) {
    var e_1, _a;
    var passengersForRequest = [];
    try {
        for (var passengers_1 = __values(passengers), passengers_1_1 = passengers_1.next(); !passengers_1_1.done; passengers_1_1 = passengers_1.next()) {
            var passenger = passengers_1_1.value;
            var passengerConfig = config[passenger.type];
            if (!config[passenger.type]) {
                continue;
            }
            passengersForRequest.push({
                passengerType: (passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.parentCode)
                    ? passengerConfig.parentCode
                    : passenger.type,
                extendedPassengerType: (passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.parentCode) ? passenger.type : null,
                count: passenger.count
            });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (passengers_1_1 && !passengers_1_1.done && (_a = passengers_1.return)) _a.call(passengers_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return passengersForRequest;
});
/**
 * Count a total number of the selected passengers.
 */
export var getTotalPassengersCount = createSelector(getPassengers, function (passengers) {
    return passengers.reduce(function (total, passenger) { return total + passenger.count; }, 0);
});
/**
 * Create a title for displaying total passengers number.
 */
export var getPassengersTitle = createSelector(getTotalPassengersCount, function (totalCount) {
    return "".concat(totalCount, " ").concat(i18n.t('SearchForm:passenger', {
        count: totalCount
    }));
});
