import { __assign } from "tslib";
import * as React from 'react';
import { usePaymentMethodsQuery } from '@websky/graphql';
import PaymentComponent from '../../../Modules/Exchange/components/Exchange/Payment/Payment';
import SimpleLoader from '../../../SimpleLoader';
var Payment = function (_a) {
    var className = _a.className, lastChangePaxOrder = _a.lastChangePaxOrder, onPaymentSuccess = _a.onPaymentSuccess, onPaymentFailed = _a.onPaymentFailed, onGoToRequest = _a.onGoToRequest;
    var baseUrl = window.location.href;
    var _b = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withKaspi: true,
            withItransfer: true,
            withGpay: true,
            withCredit: true,
            withCard: true,
            withQiwi: true,
            withFps: true,
            withSbol: true,
            parameters: {
                orderId: lastChangePaxOrder.id,
                backRedirectUrl: "".concat(baseUrl, "/success"),
                backRedirectUrlOnFail: "".concat(baseUrl, "/failed")
            }
        }
    }), data = _b.data, loading = _b.loading;
    var onGoToRequestHandler = function () {
        onGoToRequest(true);
    };
    if (loading) {
        return React.createElement(SimpleLoader, null);
    }
    return (React.createElement(PaymentComponent, { className: className, exchangeOrder: __assign(__assign({}, lastChangePaxOrder), { isCompelled: lastChangePaxOrder.isCompelled }), paymentMethods: data === null || data === void 0 ? void 0 : data.PaymentMethods, successPayment: onPaymentSuccess, failedPayment: onPaymentFailed, goToOrder: onGoToRequestHandler }));
};
export default Payment;
