import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import Passenger from '../../../../../../../Passenger/components/Passenger';
import { getInputType } from '../../../../../../../Checkout/components/Checkout/Form/Passengers/Passengers';
import { initialPassengersValuesFactory } from '../../../../../../../Checkout/components/Checkout/Form/utils';
import ActionButton from '../../../../../../../Button/components/ActionButton/ActionButton';
import { useTheme } from '../../../../../../../theme';
import { getTravellerName } from '../../../../../utils';
import { getUserValue, initI18n } from '../../../../../../../utils';
initI18n('CheckinAddDocuments');
var CheckinAddDocuments = function (_a) {
    var travellers = _a.travellers, onSubmit = _a.onSubmit;
    var theme = useTheme('Checkin').CheckinAddDocuments;
    var t = useTranslation('CheckinAddDocuments').t;
    var checkinTravellerValuesToFormStateValuesAdapter = function (traveller) {
        return traveller.values
            .map(function (value) {
            var hasDocNumber = getUserValue(traveller, TravellerFieldEnum.DocNumber);
            var readonly = !!value.value;
            if (value.type === TravellerFieldEnum.MiddleName) {
                readonly = !getUserValue(traveller, TravellerFieldEnum.MiddleName);
            }
            // see
            // https://youtrack.websky.aero/issue/WEBSKY-4203/Checkin-Dovnesenie-dannyh-na-CheckinSetTravellersData-ne-peredaem-type-u-polej-passa-i-peredaem-subsidii#focus=Comments-4-68883.0-0
            if ((value.type === TravellerFieldEnum.DocType || value.type === TravellerFieldEnum.Nationality) &&
                !hasDocNumber) {
                readonly = false;
            }
            return {
                defaultValue: value.value,
                readonly: readonly,
                name: value.name,
                input: getInputType(value.type),
                type: value.type,
                validations: value.validationRules,
                placeholder: value.name
            };
        })
            .filter(function (field) {
            return ![
                TravellerFieldEnum.LoyaltyNumber,
                TravellerFieldEnum.LoyaltyAirline,
                TravellerFieldEnum.PromoCode
            ].includes(field.type);
        });
    };
    var handleFormSubmit = function (values) {
        onSubmit(values.passengers);
    };
    return (React.createElement(Form, { onSubmit: handleFormSubmit, initialValues: {
            passengers: initialPassengersValuesFactory(travellers)
        }, render: function (_a) {
            var handleSubmit = _a.handleSubmit, valid = _a.valid;
            return (React.createElement(React.Fragment, null,
                React.createElement("form", { onSubmit: handleSubmit, className: theme.passengersForm },
                    travellers.map(function (traveller) { return (React.createElement(Passenger, { className: theme.passenger, key: traveller.id, id: parseInt(traveller.id), label: getTravellerName(traveller), fields: checkinTravellerValuesToFormStateValuesAdapter(traveller), passengerType: traveller.type })); }),
                    React.createElement(ActionButton, { disabled: !valid, className: theme.submit, size: 'small', type: 'submit' }, t('Submit')))));
        } }));
};
export default CheckinAddDocuments;
