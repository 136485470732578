import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '../../../../../../Icons';
import { useTheme } from '../../../../../../theme';
var Header = function (props) {
    var theme = useTheme('Exchange').Datepicker;
    var t = useTranslation('Exchange').t;
    return (React.createElement("div", { className: theme.header },
        React.createElement("div", { className: theme.header__backIcon, onClick: props.onBack }, ArrowBack),
        React.createElement("div", { className: theme.header__title }, t('Select new date'))));
};
export default Header;
