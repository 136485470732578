import { __generator } from "tslib";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SAVE_SERVICES } from './actions';
import { getSelectedServices } from './selectors';
import { CheckinSaveOrderServicesDocument } from '@websky/graphql';
import { createClient } from '../../../../graphql-client';
import { getBaggageServices, getCheckinOrderId } from '../order/selectors';
import { fillCheckinOrder } from '../order/actions';
import { createServicesForRequest } from '../../utils';
import { setLoadingOff, setLoadingOn } from '../servicesLoading/actions';
function runRequest(services) {
    var client = createClient();
    return client.mutate({
        mutation: CheckinSaveOrderServicesDocument,
        variables: {
            params: services
        }
    });
}
function saveServicesWorker() {
    var orderId, selectedServices, baggageServices, _a, data, errors;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(getCheckinOrderId)];
            case 1:
                orderId = _b.sent();
                return [4 /*yield*/, select(getSelectedServices)];
            case 2:
                selectedServices = _b.sent();
                return [4 /*yield*/, select(getBaggageServices)];
            case 3:
                baggageServices = _b.sent();
                return [4 /*yield*/, put(setLoadingOn())];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(runRequest, {
                        id: orderId,
                        services: createServicesForRequest(selectedServices, baggageServices)
                    })];
            case 5:
                _a = _b.sent(), data = _a.data, errors = _a.errors;
                if (!errors) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setLoadingOff())];
            case 6:
                _b.sent();
                throw errors;
            case 7:
                if (!(data === null || data === void 0 ? void 0 : data.CheckinSaveOrderServices)) return [3 /*break*/, 10];
                return [4 /*yield*/, put(fillCheckinOrder(data.CheckinSaveOrderServices))];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(setLoadingOff())];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [2 /*return*/];
        }
    });
}
export function saveServicesSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(SAVE_SERVICES, saveServicesWorker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
