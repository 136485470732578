import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '../../../theme';
import { ExareStatus } from '@websky/graphql';
import Price from '../Price/Price';
import MobileStep from '../MobileStep/MobileStep';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import StepsButtons from '../StepsButtons/StepsButtons';
import RefundInfoModal from '../Modals/RefundInfoModal/RefundInfoModal';
import { Document as DocumentIcon, Person, Refundable } from '../../../Icons';
import { getPassengerFirstName, getPassengerLastName } from '../../../Checkout/store/passengers/selectors';
import { capitalizeFirstLetter, getPassengerNames, initI18n } from '../../../utils';
import { Unvoluntary } from '../../types';
import { useToggleable } from '../../../hooks';
import TicketRequest from '../../../TicketRequest';
import Notes from '../Notes/Notes';
import Note from '../Note/Note';
import { useHtmlDescriptionOfError } from '../Refund/hooks';
initI18n('Exchange');
var Ticket = function (props) {
    var _a;
    var refundOrder = props.refundOrder, isLoading = props.isLoading, isAutoMode = props.isAutoMode, renderNotesFromProps = props.renderNotes;
    var isModalOpen = useToggleable(false).isOpen;
    var _b = useToggleable(false), rejectModalOpen = _b.isOpen, openRejectModal = _b.open, closeRejectModal = _b.close;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').Ticket;
    var handleConfirm = useCallback(function () {
        if (isLoading) {
            return;
        }
        props.onConfirm(refundOrder.id);
    }, [refundOrder, isLoading]);
    var handleReject = useCallback(function () {
        if (isLoading) {
            return;
        }
        props.onReject(refundOrder.id);
    }, [refundOrder, isLoading]);
    var renderHeader = useCallback(function () {
        var _a;
        var breadcrumbs = [];
        var travellerNames = refundOrder.travellers.map(function (traveller) {
            var _a = getPassengerNames(traveller), lastName = _a.lastName, firstName = _a.firstName;
            return [lastName, firstName].map(capitalizeFirstLetter).join(' ');
        });
        breadcrumbs = __spreadArray(__spreadArray([], __read(breadcrumbs), false), __read(travellerNames.map(function (name) { return ({ title: name }); })), false);
        var reason = (_a = refundOrder.reason) !== null && _a !== void 0 ? _a : Unvoluntary.Unvoluntary;
        var reasonText = reason === Unvoluntary.Unvoluntary ? t('Voluntary refund') : t('Involuntary refund');
        if (reason !== Unvoluntary.Unvoluntary) {
            reasonText += " - ".concat(t(refundOrder.reason));
        }
        breadcrumbs = __spreadArray(__spreadArray([], __read(breadcrumbs), false), [{ title: reasonText }], false);
        return (React.createElement(React.Fragment, null,
            React.createElement(TicketRequest, { className: css.refund__request, breadcrumbsClassName: css.breadcrumbs, requestId: refundOrder.id, status: refundOrder.status, createdDate: new Date(refundOrder.createDate), breadcrumbs: breadcrumbs }),
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement(MobileStep, { className: css.step, isActive: false, renderTitle: function () { return (React.createElement(React.Fragment, null,
                        t('For'),
                        "\u00A0",
                        travellerNames.join(', '))); }, icon: Person }),
                React.createElement(MobileStep, { className: css.step, isActive: false, renderTitle: function () { return (React.createElement("span", { className: css.reasonHeader }, !refundOrder.isCompelled ? (t('Voluntary refund')) : (React.createElement(React.Fragment, null,
                        t('Involuntary refund'),
                        React.createElement("p", { className: css.reasonDescr }, t(refundOrder.reason.toString())))))); }, icon: Refundable }))));
    }, [refundOrder]);
    var renderPrices = useCallback(function () { return React.createElement(Price, { className: css.price, price: refundOrder.prices }); }, [refundOrder]);
    var renderComments = useCallback(function () {
        return refundOrder.userComment ? (React.createElement(Paper, { square: true, elevation: 0, className: css.comments },
            React.createElement("h6", { className: css.commentsTitle }, t('Your comments')),
            React.createElement("p", { className: css.commentsText }, refundOrder.userComment))) : null;
    }, [refundOrder]);
    var renderFile = useCallback(function (file) { return (React.createElement("div", { className: css.file, key: Math.random() },
        React.createElement("span", { className: css.fileIcon }, DocumentIcon),
        capitalizeFirstLetter(getPassengerFirstName(refundOrder.travellers.find(function (traveller) { return traveller.id === file.passengerId; })).value.toLowerCase()),
        "\u00A0",
        capitalizeFirstLetter(getPassengerLastName(refundOrder.travellers.find(function (traveller) { return traveller.id === file.passengerId; })).value.toLowerCase()),
        ":\u00A0",
        t(file.type))); }, [refundOrder]);
    var renderFiles = useCallback(function () {
        if (!refundOrder.loadedFiles.length) {
            return null;
        }
        return (React.createElement(Paper, { square: true, elevation: 0, className: css.files },
            React.createElement("h6", { className: css.filesTitle }, t('Files')),
            React.createElement("div", { className: css.filesContent }, refundOrder.loadedFiles.map(renderFile))));
    }, [refundOrder]);
    var renderButtons = useCallback(function () {
        var status = refundOrder.status, refuseAllowed = refundOrder.refuseAllowed, needConfirm = status === ExareStatus.NeedConfirm, newRequestAllowed = status === ExareStatus.Refused || status === ExareStatus.Rejected || ExareStatus.Finished;
        var nextButtonProps = {};
        if (needConfirm) {
            nextButtonProps = {
                disabled: isLoading,
                children: t('Confirm'),
                className: cn(css.confirm, css.button),
                onClick: handleConfirm
            };
        }
        else if (newRequestAllowed) {
            nextButtonProps = {
                children: t('Add new request'),
                className: cn(css.confirm, css.button),
                onClick: props.onNewRequest
            };
        }
        return (React.createElement("div", { className: css.buttons },
            React.createElement(StepsButtons, { position: 'right', nextButton: nextButtonProps, backButton: refuseAllowed
                    ? {
                        disabled: isLoading,
                        children: t(needConfirm ? 'Reject' : 'Delete request'),
                        className: cn(css.button, css.reject),
                        onClick: openRejectModal
                    }
                    : null })));
    }, [refundOrder.status, refundOrder.refuseAllowed]);
    var renderRejectModal = function () { return (React.createElement(RefundInfoModal, { open: rejectModalOpen, onAgree: handleReject, onDisagree: closeRejectModal, renderText: function () { return t('The request was deleted. Want to create a new request?'); }, renderAgreeText: function () { return t('Yes'); }, renderDisagreeText: function () { return t('No'); } })); };
    var renderModals = useCallback(function () {
        var status = refundOrder.status;
        switch (status) {
            default:
                return null;
        }
    }, [refundOrder, isModalOpen, handleConfirm]);
    var renderNotes = useCallback(function () {
        var _a, _b;
        var isZeroPrice = ((_b = (_a = refundOrder.prices) === null || _a === void 0 ? void 0 : _a.priceToPay) === null || _b === void 0 ? void 0 : _b.amount) && Math.abs(refundOrder.prices.priceToPay.amount) <= 0;
        var showReturnMessage = !isZeroPrice && refundOrder.status === ExareStatus.Finished;
        if (renderNotesFromProps) {
            renderNotesFromProps(isAutoMode);
        }
        return React.createElement(Notes, { isAutoMode: isAutoMode, showReturnMessage: showReturnMessage });
    }, [isAutoMode, refundOrder]);
    var htmlDescriptionOfWarning = useHtmlDescriptionOfError((_a = refundOrder.warnings) === null || _a === void 0 ? void 0 : _a[0]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, { square: true, elevation: 0, className: css.ticket },
            renderHeader(),
            renderComments(),
            renderFiles()),
        !(refundOrder.status === ExareStatus.InProcess && !refundOrder.automaticMode) && (React.createElement(Paper, { square: true, elevation: 0, className: cn(css.prices) }, renderPrices())),
        refundOrder.status !== ExareStatus.Rejected && refundOrder.status !== ExareStatus.Refused && renderNotes(),
        htmlDescriptionOfWarning && (React.createElement(Note, { title: htmlDescriptionOfWarning.header, type: 'info', iconColor: 'gray', content: React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlDescriptionOfWarning.body } }) })),
        renderButtons(),
        renderModals(),
        renderRejectModal()));
};
export default Ticket;
