import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import cn from 'classnames';
import ErrorBoundary from '../../../../../ErrorBoundary/ErrorBoundary';
import { Button } from '../../../../../index';
import WarningModal from '../../../../../WarningModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
import { CheckinFatalError, CheckinUnavailableSeatMapError } from './CheckinErrors';
var CheckinErrorBoundary = function (props) {
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').CheckinErrorBoundary;
    var _a = __read(useState(null), 2), checkinError = _a[0], setCheckinError = _a[1];
    // const oldEngineURL
    var _b = useConfig().global, locale = _b.locale, reserveEngines = _b.reserveEngines;
    var checkinUrl = (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)
        ? "".concat(reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin, "?lastName=").concat(props.lastName, "&ticketNumber=").concat(props.ticketNumber, "&lang=").concat(locale)
        : null;
    var fallbackRenderer = useCallback(function (error) {
        if (error instanceof CheckinFatalError) {
            var errorText = t((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)
                ? "But don't worry, all of our systems have been duplicated several times. You can register on the backup version of the site."
                : 'An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.');
            if (error instanceof CheckinUnavailableSeatMapError) {
                errorText = error.message;
            }
            setCheckinError(errorText);
        }
        else {
            return null;
        }
        return (React.createElement(WarningModal, { className: css.modal, isOpen: !!checkinError, title: t('Oops, something went wrong'), content: React.createElement("div", null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: checkinError } }),
                React.createElement("div", { className: css.buttons },
                    (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.siteUrl) && (React.createElement(Button, { className: css.button, onClick: function () { return window.open(reserveEngines.siteUrl); } }, t('Go to home'))),
                    React.createElement(Button, { className: css.button, onClick: function () { return window.location.reload(); } }, t('Try again')),
                    checkinUrl && (React.createElement(Button, { className: cn(css.button, css.active), onClick: function () { return window.open(checkinUrl, '_blank'); } }, t('Go'))))) }));
    }, [checkinError, setCheckinError]);
    return React.createElement(ErrorBoundary, { renderFallback: fallbackRenderer }, props.children);
};
export default CheckinErrorBoundary;
