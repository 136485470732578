import { __generator } from "tslib";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getSelectedServices } from './selectors';
import { ADD_SERVICE, REMOVE_SERVICE, SAVE_SERVICES, SET_SERVICE, DELETE_ALL_TYPES_ON_SEGMENT, SET_MULTIPLE_SELECTED_SERVICES } from './actions';
import { createClient } from '../../../graphql-client';
import { getIsBookedOrConfirmed, getOrderId, getOrder } from '../order/selectors';
import { fillOrder } from '../order/actions';
import { setOrderPrice } from '../orderPrice/actions';
import { setServicesIsSaving, setServicesSaved } from '../servicesIsSaving/actions';
import { OrderDocument, SaveOrderServicesDocument, OrderPriceQueryDocument } from '@websky/graphql';
function priceBreakdownRequest(services, orderId) {
    var client = createClient();
    return client.mutate({
        mutation: OrderPriceQueryDocument,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        }
    });
}
function saveSelectedServicesRequest(services, orderId, context) {
    var client = createClient();
    return client.mutate({
        mutation: SaveOrderServicesDocument,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        },
        context: context
    });
}
function actualOrderRequest(orderId) {
    var client = createClient();
    return new Promise(function (resolve) {
        client
            .mutate({
            mutation: OrderDocument,
            variables: {
                id: orderId
            }
        })
            .then(function (response) {
            var _a;
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.Order) {
                resolve(response.data.Order);
            }
            else {
                resolve(null);
            }
        })
            .catch(function () {
            resolve(null);
        });
    });
}
function getActualOrder(orderId) {
    var actualOrder, previousOrderState;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(actualOrderRequest, orderId)];
            case 1:
                actualOrder = _a.sent();
                if (actualOrder) {
                    return [2 /*return*/, actualOrder];
                }
                return [4 /*yield*/, select(getOrder)];
            case 2:
                previousOrderState = _a.sent();
                return [2 /*return*/, previousOrderState];
        }
    });
}
function saveServiceWorker(action) {
    var selectedServices, orderId, isBookedOrConfirmedOrder, orderPrice, e_1, request, actualOrder, e_2, actualOrder;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getSelectedServices)];
            case 1:
                selectedServices = _a.sent();
                return [4 /*yield*/, select(getOrderId)];
            case 2:
                orderId = _a.sent();
                return [4 /*yield*/, select(getIsBookedOrConfirmed)];
            case 3:
                isBookedOrConfirmedOrder = _a.sent();
                if (!!isBookedOrConfirmedOrder) return [3 /*break*/, 12];
                return [4 /*yield*/, put(setServicesIsSaving())];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5:
                _a.trys.push([5, 10, , 12]);
                return [4 /*yield*/, call(priceBreakdownRequest, selectedServices, orderId)];
            case 6:
                orderPrice = _a.sent();
                if (!(orderPrice.data.OrderPrice && !orderPrice.errors)) return [3 /*break*/, 8];
                return [4 /*yield*/, put(setOrderPrice(orderPrice.data.OrderPrice))];
            case 7:
                _a.sent();
                _a.label = 8;
            case 8: return [4 /*yield*/, put(setServicesSaved())];
            case 9:
                _a.sent();
                return [3 /*break*/, 12];
            case 10:
                e_1 = _a.sent();
                console.log(e_1);
                return [4 /*yield*/, put(setServicesSaved())];
            case 11:
                _a.sent();
                return [3 /*break*/, 12];
            case 12: return [4 /*yield*/, put(setServicesIsSaving())];
            case 13:
                _a.sent();
                _a.label = 14;
            case 14:
                _a.trys.push([14, 22, , 26]);
                return [4 /*yield*/, call(saveSelectedServicesRequest, selectedServices, orderId, action.meta)];
            case 15:
                request = _a.sent();
                if (!(request.data.SaveOrderServices && !request.errors)) return [3 /*break*/, 17];
                return [4 /*yield*/, put(fillOrder(request.data.SaveOrderServices))];
            case 16:
                _a.sent();
                return [3 /*break*/, 20];
            case 17: return [4 /*yield*/, call(getActualOrder, orderId)];
            case 18:
                actualOrder = _a.sent();
                return [4 /*yield*/, put(fillOrder(actualOrder))];
            case 19:
                _a.sent();
                _a.label = 20;
            case 20: return [4 /*yield*/, put(setServicesSaved())];
            case 21:
                _a.sent();
                return [3 /*break*/, 26];
            case 22:
                e_2 = _a.sent();
                return [4 /*yield*/, call(getActualOrder, orderId)];
            case 23:
                actualOrder = _a.sent();
                return [4 /*yield*/, put(fillOrder(actualOrder))];
            case 24:
                _a.sent();
                return [4 /*yield*/, put(setServicesSaved())];
            case 25:
                _a.sent();
                return [3 /*break*/, 26];
            case 26: return [2 /*return*/];
        }
    });
}
export function saveServicesSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest([
                    ADD_SERVICE,
                    REMOVE_SERVICE,
                    SAVE_SERVICES,
                    SET_SERVICE,
                    DELETE_ALL_TYPES_ON_SEGMENT,
                    SET_MULTIPLE_SELECTED_SERVICES
                ], saveServiceWorker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
