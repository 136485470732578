export var Measurement;
(function (Measurement) {
    Measurement["Kilograms"] = "kg";
    Measurement["Pieces"] = "pcs";
})(Measurement || (Measurement = {}));
export var AdditionalTypes;
(function (AdditionalTypes) {
    AdditionalTypes[AdditionalTypes["Overweight"] = 0] = "Overweight";
    AdditionalTypes[AdditionalTypes["Sport"] = 1] = "Sport";
    AdditionalTypes[AdditionalTypes["HandLuggageExcess"] = 2] = "HandLuggageExcess";
})(AdditionalTypes || (AdditionalTypes = {}));
