import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useConfig } from '../../../context';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import Notification from '../../../Notification';
import Link from '../../../Link';
initI18n('Checkout');
export var MobileBaggageRules = function () {
    var classes = useTheme('BaggagePage').MobileBaggageRules;
    var t = useTranslation().t;
    var companyInfo = useConfig().global.companyInfo;
    return (React.createElement(Link, { className: classes.wrapper, target: "_blank", action: companyInfo.baggage },
        React.createElement(Notification, { type: "context", status: InfoMessageTypeEnum.Info, infoMessage: {
                text: t('Checkout:Baggage rules'),
                code: 'baggage-rules',
                type: InfoMessageTypeEnum.Info
            } })));
};
