import { ApolloLink } from 'apollo-boost';
var ignoredErrors = [new RegExp('NUMBER OF SERVICES MISMATCH WITH SSR SEAT')];
var ignoredErrorsLink = new ApolloLink(function (operation, forward) {
    return forward(operation).map(function (response) {
        var _a;
        if (!!((_a = response === null || response === void 0 ? void 0 : response.errors) === null || _a === void 0 ? void 0 : _a.length)) {
            var ignoredErrorsCount_1 = 0;
            response.errors.forEach(function (error) {
                if (ignoredErrors.some(function (regError) { return regError.test(error.message); })) {
                    ignoredErrorsCount_1++;
                }
            });
            if (response.errors.length === ignoredErrorsCount_1) {
                delete response.errors;
            }
        }
        return response;
    });
});
export default ignoredErrorsLink;
