import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useReducer } from 'react';
import { getTotalPrice } from '../utils';
var initialState = [];
var insuranceReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action.type === 'toggle') {
        if (state.includes(action.payload)) {
            return state.filter(function (code) { return code !== action.payload; });
        }
        return __spreadArray(__spreadArray([], __read(state), false), [action.payload], false);
    }
    return state;
};
var createInitialState = function (selectedInsurances) {
    return selectedInsurances.map(function (insurance) { return insurance.insuranceProgram.code; });
};
export var useInsuranceState = function (insurances, selectedInsurances) {
    var initialState = createInitialState(selectedInsurances);
    var _a = __read(useReducer(insuranceReducer, initialState), 2), state = _a[0], dispatch = _a[1];
    var confirmedCodes = React.useMemo(function () {
        return state.filter(function (code) {
            return selectedInsurances.some(function (insurance) { var _a; return !!insurance.policyNumber && ((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code) === code; });
        });
    }, [state, selectedInsurances]);
    var selectedWithoutConfirmedCodes = React.useMemo(function () {
        return state.filter(function (code) { return !confirmedCodes.includes(code); });
    }, [state, confirmedCodes]);
    var totalPrice = React.useMemo(function () {
        var insurancePrograms = insurances.filter(function (insurance) {
            return selectedWithoutConfirmedCodes.includes(insurance.code);
        });
        var totalPrice = getTotalPrice(insurancePrograms, function (insurance) { return insurance.totalPrice; });
        if (totalPrice.amount > 0) {
            return totalPrice;
        }
        return null;
    }, [insurances, selectedWithoutConfirmedCodes]);
    var hasChanges = React.useMemo(function () {
        return JSON.stringify(initialState.sort()) !== JSON.stringify(state.sort());
    }, [initialState, state]);
    var onToggle = useCallback(function (code) {
        dispatch({ type: 'toggle', payload: code });
    }, [state]);
    return {
        state: state,
        dispatch: dispatch,
        confirmedCodes: confirmedCodes,
        selectedWithoutConfirmedCodes: selectedWithoutConfirmedCodes,
        totalPrice: totalPrice,
        hasChanges: hasChanges,
        onToggle: onToggle
    };
};
