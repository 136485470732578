import { OrderStatus } from '@websky/graphql';
export var findUpcomingFlight = function (orders) {
    if (!(orders === null || orders === void 0 ? void 0 : orders.length)) {
        return null;
    }
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var upcomingOrders = orders.filter(function (order) {
        return order.status === OrderStatus.Confirmed &&
            order.flight.segmentGroups.some(function (group) {
                return group.segments.some(function (segment) { return new Date(segment.departure.date).getTime() >= today.getTime(); });
            });
    });
    if (upcomingOrders.length === 0) {
        return null;
    }
    var upcomingOrderWithMinDate = upcomingOrders.reduce(function (minDateAndOrder, order) {
        var minDepartureDate = order.flight.segmentGroups.reduce(function (minDate, group) {
            var _a, _b;
            var departureTimestamp = new Date((_b = (_a = group.segments[0]) === null || _a === void 0 ? void 0 : _a.departure) === null || _b === void 0 ? void 0 : _b.date).getTime();
            if (departureTimestamp < minDate) {
                return departureTimestamp;
            }
            return minDate;
        }, Infinity);
        if (minDepartureDate < minDateAndOrder.minTimestamp) {
            return {
                minTimestamp: minDepartureDate,
                order: order
            };
        }
        return minDateAndOrder;
    }, {
        minTimestamp: Infinity,
        order: orders[0]
    });
    return upcomingOrderWithMinDate.order;
};
