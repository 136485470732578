import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import IMask from 'imask';
import Input from '../../Input';
import { Mask } from '../mask';
var PhoneInput = React.forwardRef(function (_a, ref) {
    var onChange = _a.onChange, props = __rest(_a, ["onChange"]);
    var _b = __read(useState(props.value || ''), 2), value = _b[0], setValue = _b[1];
    var onChangeHandler = useCallback(function (e) {
        var value = e.target.value.replace(/[-()\s]/g, '');
        var mask = IMask.createMask(Mask.International);
        if (value.startsWith('79') ||
            value.startsWith('+79') ||
            value.startsWith('89') ||
            value.startsWith('+89')) {
            mask = IMask.createMask(Mask.RU);
        }
        setValue(mask.resolve(value));
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    }, [onChange]);
    return React.createElement(Input, __assign({ type: "tel" }, props, { value: value, onChange: onChangeHandler, ref: ref }));
});
export default PhoneInput;
