import * as React from 'react';
import Money from '../Money';
import { useTheme } from '../theme';
var ServiceSummary = function (_a) {
    var _b;
    var group = _a.group, children = _a.children;
    var css = useTheme('ServiceSummary').ServiceSummary;
    if (React.Children.toArray(children).every(function (children) { return !children; })) {
        return null;
    }
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.header },
            React.createElement("span", { className: css.header__number }),
            React.createElement("span", { className: css.header__icon }, group.icon),
            ' ',
            React.createElement("span", { className: css.header__title }, group.header),
            ((_b = group.totalPrice) === null || _b === void 0 ? void 0 : _b.amount) > 0 && (React.createElement("span", { className: css.header__price },
                React.createElement(Money, { money: group.totalPrice })))),
        React.createElement("div", { className: css.content }, children)));
};
export default ServiceSummary;
