import { __assign } from "tslib";
import * as React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Select } from '../Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { concatPassengerNames } from '../../../../utils';
export var LinkedTraveller = function (props) {
    var t = useTranslation('Passenger').t;
    var formState = useFormState();
    var options = props.options.map(function (option) {
        return {
            key: option.label,
            value: option.value
        };
    });
    var valueRenderer = React.useCallback(function (value) {
        var _a, _b, _c;
        var passenger = formState.values.passengers[value];
        var name = "".concat(t('Adult'), " ").concat(parseInt(value, 10) + 1);
        if (((_a = passenger.firstName) === null || _a === void 0 ? void 0 : _a.trim()) && ((_b = passenger.lastName) === null || _b === void 0 ? void 0 : _b.trim())) {
            name = concatPassengerNames(passenger.lastName.trim(), passenger.firstName.trim(), (_c = passenger.middleName) === null || _c === void 0 ? void 0 : _c.trim());
        }
        return name;
    }, [props.value, formState.values.passengers]);
    return (React.createElement(Select, __assign({}, props, { renderValue: valueRenderer, renderOption: function (option) {
            return (React.createElement(MenuItem, { key: option.key, value: option.key }, valueRenderer(option.value)));
        }, readonly: options.length === 1, menuProps: { disableScrollLock: true }, value: props.value, options: options, name: props.name, label: props.label, className: props.className, onChange: props.onChange, onBlur: props.onBlur, error: props.error, disabled: props.disabled, selectFirstOptionByDefault: true })));
};
