import { __values } from "tslib";
export var insuranceInitialStateFactory = function (order) {
    var e_1, _a, e_2, _b, e_3, _c;
    var _d, _e, _f, _g, _h, _j;
    var initialState = {
        availableInsurances: {},
        selectedInsurances: {},
        confirmedInsurances: {},
        orderTravellers: [],
        isLoading: false
    };
    var selectedInsurances = (_f = (_e = (_d = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _d === void 0 ? void 0 : _d.insurances) === null || _e === void 0 ? void 0 : _e.selectedInsurances) !== null && _f !== void 0 ? _f : [];
    var availableInsurances = (_j = (_h = (_g = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _g === void 0 ? void 0 : _g.insurances) === null || _h === void 0 ? void 0 : _h.insurancePrograms) !== null && _j !== void 0 ? _j : [];
    try {
        for (var selectedInsurances_1 = __values(selectedInsurances), selectedInsurances_1_1 = selectedInsurances_1.next(); !selectedInsurances_1_1.done; selectedInsurances_1_1 = selectedInsurances_1.next()) {
            var insurance = selectedInsurances_1_1.value;
            initialState.selectedInsurances[insurance.insuranceProgram.code] = insurance;
            if (insurance.policyNumber) {
                initialState.confirmedInsurances[insurance.insuranceProgram.code] = insurance;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (selectedInsurances_1_1 && !selectedInsurances_1_1.done && (_a = selectedInsurances_1.return)) _a.call(selectedInsurances_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    try {
        for (var availableInsurances_1 = __values(availableInsurances), availableInsurances_1_1 = availableInsurances_1.next(); !availableInsurances_1_1.done; availableInsurances_1_1 = availableInsurances_1.next()) {
            var insurance = availableInsurances_1_1.value;
            initialState.availableInsurances[insurance.code] = insurance;
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (availableInsurances_1_1 && !availableInsurances_1_1.done && (_b = availableInsurances_1.return)) _b.call(availableInsurances_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    try {
        for (var _k = __values(order.travellers), _l = _k.next(); !_l.done; _l = _k.next()) {
            var traveller = _l.value;
            initialState.orderTravellers.push(traveller.id);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return initialState;
};
export var saveOrderServicesParamsFactory = function (travellersIds, selectedInsuranceCodes) {
    return {
        params: {
            insurances: [
                {
                    selectedPrograms: selectedInsuranceCodes,
                    selectedProgramsByTravellers: travellersIds.map(function (travellerId) { return ({
                        travellerId: travellerId,
                        programCodes: selectedInsuranceCodes,
                        travellerDocumentNumber: ''
                    }); })
                }
            ]
        }
    };
};
