import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isActionRoute, isPathRoute } from '../utils';
import { useTheme } from '../../theme';
import { Badge } from '../../Badge/Badge';
var Sidebar = function (_a) {
    var routes = _a.routes, selectedRoute = _a.selectedRoute, userInfo = _a.userInfo;
    var css = useTheme('Sidebar').Sidebar;
    var t = useTranslation('Account').t;
    var title = !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.firstName) && !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.lastName) ? "".concat(userInfo.firstName, " ").concat(userInfo.lastName) : t('My cabinet');
    return (React.createElement(List, { className: css.sidebar, disablePadding: true, classes: {
            root: css.root
        } },
        React.createElement("div", { className: css.title }, title),
        routes.map(function (route, index) {
            var isSelected = false;
            if (isPathRoute(route)) {
                var match = matchPath(selectedRoute, {
                    path: route.pathname,
                    exact: !route.hasChild
                });
                isSelected = !!match;
            }
            var item = (React.createElement(ListItem, { button: true, selected: isSelected, classes: {
                    root: css.sidebar__item,
                    selected: css.sidebar__item_selected
                } },
                React.createElement(ListItemIcon, { className: css.icon },
                    React.createElement("div", { className: css.svg }, route.icon)),
                React.createElement(ListItemText, { className: css.item__text, primary: t(route.title) }),
                route.badgeContent > 0 && React.createElement(Badge, { value: route.badgeContent, className: css.badge })));
            if (isActionRoute(route)) {
                return (React.createElement("div", { key: index, onClick: route.action }, item));
            }
            else if (route.isRedirect) {
                return (React.createElement("a", { key: index, href: route.pathname, target: "_blank" }, item));
            }
            else {
                return (React.createElement(Link, { key: index, to: route.pathname }, item));
            }
        })));
};
export default Sidebar;
