import { __read, __spreadArray } from "tslib";
import { Tabs } from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { filterFareGroups } from '../Results/utils';
import { useConfig } from '../context';
import { FlightClass } from '@websky/graphql';
export var useDesktopFlight = function (componentProps) {
    var selectedFares = componentProps.selectedFares, flight = componentProps.flight, isPreOpen = componentProps.isPreOpen, onSelect = componentProps.onSelect;
    var showAvailableFaresOnly = useConfig().Engine.showAvailableFaresOnly;
    var flightBlock = useRef(null);
    var _a = __read(useState(null), 2), activeTab = _a[0], openTab = _a[1];
    var lastSelectedFare = useMemo(function () {
        var _a, _b;
        return (_b = (_a = selectedFares === null || selectedFares === void 0 ? void 0 : selectedFares[selectedFares.length - 1]) === null || _a === void 0 ? void 0 : _a.fareGroupId) !== null && _b !== void 0 ? _b : null;
    }, [selectedFares]);
    // #fixme: вынести во Flight
    var economyFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Economy, showAvailableFaresOnly);
    var businessFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Business, showAvailableFaresOnly);
    var milesFareGroups = filterFareGroups(flight.pricesForFareGroups, selectedFares !== null && selectedFares !== void 0 ? selectedFares : [], FlightClass.Miles, showAvailableFaresOnly);
    var filteredPrices = useMemo(function () {
        return economyFareGroups.concat(businessFareGroups);
    }, [economyFareGroups, businessFareGroups]);
    var subStatuses = useMemo(function () {
        var subStatusSet = new Set();
        flight.segments.forEach(function (segment) {
            var _a;
            if ((_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.subStatus) {
                var subStatus = segment.segment.subStatus;
                subStatusSet.add(subStatus);
            }
        });
        return __spreadArray([], __read(subStatusSet), false);
    }, [flight.segments]);
    var flightSegments = useMemo(function () {
        if (subStatuses.includes('Upgrade') || subStatuses.includes('AwaitingExchange')) {
            return flight.segments.filter(function (segment) {
                var _a;
                var subStatus = (_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.subStatus;
                if (!subStatus) {
                    return true;
                }
                return !['Upgrade', 'AwaitingExchange'].includes(subStatus);
            });
        }
        return flight.segments;
    }, [flight.segments, subStatuses]);
    useEffect(function () {
        activeTab
            ? setTimeout(function () {
                var _a;
                (_a = flightBlock === null || flightBlock === void 0 ? void 0 : flightBlock.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }, 120)
            : null;
    }, [activeTab]);
    useEffect(function () {
        if (isPreOpen) {
            if (economyFareGroups.some(function (fareGroup) { return fareGroup.prices; })) {
                openTab(Tabs.EconomyFares);
            }
            else if (businessFareGroups.some(function (fareGroup) { return fareGroup.prices; })) {
                openTab(Tabs.BusinessFares);
            }
        }
    }, [isPreOpen]);
    var onOpenFlightInfoTab = useCallback(function (tab) { return function () { return openTab(function (active) { return (active !== tab ? tab : null); }); }; }, []);
    var getFareGroupTabHandler = useCallback(function (serviceClass) {
        if (serviceClass === FlightClass.Business) {
            return onOpenFlightInfoTab(Tabs.BusinessFares);
        }
        return onOpenFlightInfoTab(Tabs.EconomyFares);
    }, [onOpenFlightInfoTab]);
    // #fixme: this thing needs to be isolated
    var getOnFareSelect = function (group) {
        return function () {
            if (onSelect) {
                onSelect({
                    fareGroupId: group.fareFamily.id,
                    fareFamily: group.fareFamily,
                    fareName: group.fareFamily.title,
                    prices: group.prices,
                    flightInfo: {
                        id: group.prices[0].flight.id,
                        segments: flight.segments
                    }
                });
                openTab(null);
            }
        };
    };
    return {
        // data
        lastSelectedFare: lastSelectedFare,
        subStatuses: subStatuses,
        flightSegments: flightSegments,
        flightBlock: flightBlock,
        activeTab: activeTab,
        flight: flight,
        filteredPrices: filteredPrices,
        economyFareGroups: economyFareGroups,
        businessFareGroups: businessFareGroups,
        milesFareGroups: milesFareGroups,
        // methods
        getOnFareSelect: getOnFareSelect,
        getFareGroupTabHandler: getFareGroupTabHandler,
        handleFlightInfoTab: onOpenFlightInfoTab(Tabs.FlightInfo),
        handleEconomyFareGroupsTab: onOpenFlightInfoTab(Tabs.EconomyFares),
        handleBusinessFareGroupsTab: onOpenFlightInfoTab(Tabs.BusinessFares),
        handleMilesFareGroupsTab: onOpenFlightInfoTab(Tabs.MilesFares)
    };
};
