import { __assign, __read, __spreadArray } from "tslib";
import { ADD_SERVICE, REMOVE_SERVICE, ADD_SERVICE_NS, REMOVE_SERVICE_NS, DELETE_ALL_TYPES_ON_SEGMENT, DELETE_ALL_TYPES_ON_SEGMENT_NS, SET_ALL_SELECTED_SERVICES, SET_MULTIPLE_SELECTED_SERVICES, SET_MULTIPLE_SELECTED_SERVICES_NS, SET_SERVICE } from './actions';
import { getRelatedSegments } from '../../../Modules/Checkin/utils';
import { setsAreEqual } from '../../../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useSelector } from 'react-redux';
export var getNewCount = function (currentCount, countToDelete, confirmedCount) {
    var result = currentCount - countToDelete;
    return confirmedCount > result ? confirmedCount : result;
};
var getConfirmedCount = function (servicePayload) {
    if (servicePayload.type === OrderAdditionalServiceGdsServiceServiceType.Seat) {
        return servicePayload.isConfirmed ? 1 : 0;
    }
    else {
        return servicePayload.confirmedCount
            ? servicePayload.confirmedCount
            : 0;
    }
};
var pushServiceToState = function (passengerState, service) {
    var _a;
    var segmentIds = service.allowedSegments
        ? getRelatedSegments(service.allowedSegments, service.segmentId)
        : new Set(service.segmentId);
    return __assign(__assign({}, passengerState), (_a = {}, _a[service.serviceId] = __spreadArray(__spreadArray([], __read((passengerState && passengerState[service.serviceId] ? passengerState[service.serviceId] : [])), false), [
        {
            segments: __spreadArray([], __read(segmentIds), false),
            servicePayload: service.service,
            number: service.count >= 0 ? service.count : 1
        }
    ], false), _a));
};
var setServicesByPassengers = function (state, services) {
    var newState = __assign({}, state);
    services.forEach(function (service) {
        var _a;
        newState = __assign(__assign({}, newState), (_a = {}, _a[service.passengerId] = mergeServicesWithSameId(newState[service.passengerId] || {}, service, true), _a));
    });
    return newState;
};
export function useGetState() {
    return useSelector(function (state) { return state; });
}
export var selectedServicesReducer = function (state, action) {
    var _a, _b, _c, _d, _e, _f;
    if (state === void 0) { state = {}; }
    if (action.type === ADD_SERVICE || action.type === ADD_SERVICE_NS) {
        var payload = action.payload;
        if (isServiceIdExists(state, payload)) {
            return __assign(__assign({}, state), (_a = {}, _a[payload.passengerId] = __assign({}, mergeServicesWithSameId(state[payload.passengerId], payload)), _a));
        }
        else {
            return __assign(__assign({}, state), (_b = {}, _b[payload.passengerId] = __assign({}, pushServiceToState(state[payload.passengerId], payload)), _b));
        }
    }
    else if (action.type === REMOVE_SERVICE || action.type === REMOVE_SERVICE_NS) {
        var payload = action.payload;
        return __assign(__assign({}, state), (_c = {}, _c[payload.passengerId] = __assign({}, removeService(state[payload.passengerId], payload)), _c));
    }
    else if (action.type === SET_SERVICE) {
        var payload = action.payload;
        return __assign(__assign({}, state), (_d = {}, _d[payload.passengerId] = __assign({}, mergeServicesWithSameId(state[payload.passengerId], payload, true)), _d));
    }
    else if (action.type === SET_ALL_SELECTED_SERVICES) {
        var payload = action.payload;
        return payload;
    }
    else if (action.type === SET_MULTIPLE_SELECTED_SERVICES || action.type === SET_MULTIPLE_SELECTED_SERVICES_NS) {
        var payload = action.payload;
        return setServicesByPassengers(state, payload);
    }
    else if (action.type === DELETE_ALL_TYPES_ON_SEGMENT || action.type === DELETE_ALL_TYPES_ON_SEGMENT_NS) {
        // Удаляем все услуги с заданным типом у всех пассажиров на заданном сегменте или на всех сегментах
        var payload_1 = action.payload;
        var newState = state;
        for (var passengerId in state) {
            if (state.hasOwnProperty(passengerId)) {
                for (var passengerServiceId in state[passengerId]) {
                    if (state[passengerId].hasOwnProperty(passengerServiceId)) {
                        newState = __assign(__assign({}, newState), (_e = {}, _e[passengerId] = __assign(__assign({}, newState[passengerId]), (_f = {}, _f[passengerServiceId] = newState[passengerId][passengerServiceId].map(function (service) {
                            if (service.servicePayload.id === payload_1.serviceId) {
                                if (payload_1.deleteOnAllSegments) {
                                    return __assign(__assign({}, service), { number: 0 });
                                }
                                if (service.segments.includes(payload_1.segmentId)) {
                                    return __assign(__assign({}, service), { number: 0 });
                                }
                                return __assign({}, service);
                            }
                            return service;
                        }), _f)), _e));
                    }
                }
            }
        }
        return newState;
    }
    return state;
};
var isServiceIdExists = function (state, service) {
    var _a;
    return !!((_a = state[service.passengerId]) === null || _a === void 0 ? void 0 : _a[service.serviceId]);
};
var mergeServicesWithSameId = function (passengerServices, newService, setCountOfServices) {
    var _a;
    var existServices = passengerServices[newService.serviceId];
    var relatedSegments = newService.allowedSegments
        ? getRelatedSegments(newService.allowedSegments, newService.segmentId)
        : new Set(newService.segmentId);
    // Находим услугу, у которой такой же набор сегментов.
    var sameServiceIndex = existServices === null || existServices === void 0 ? void 0 : existServices.findIndex(function (service) {
        return setsAreEqual(new Set(service.segments), relatedSegments);
    });
    // если такая услуга есть, то увеличиваем кол-во на 1
    if (sameServiceIndex >= 0) {
        return __assign(__assign({}, passengerServices), (_a = {}, _a[newService.serviceId] = passengerServices[newService.serviceId].map(function (service, index) {
            var _a;
            if (index === sameServiceIndex) {
                return __assign(__assign({}, service), { number: setCountOfServices
                        ? (_a = newService.count) !== null && _a !== void 0 ? _a : service.number + (newService.count >= 0 ? newService.count : 1)
                        : service.number + (newService.count >= 0 ? newService.count : 1) });
            }
            return service;
        }), _a));
    }
    else {
        // если нет, то добавляем как новую
        return __assign({}, pushServiceToState(passengerServices, newService));
    }
};
var removeService = function (passengerState, service) {
    var _a;
    var _b;
    var existServicesIndex = (_b = passengerState[service.serviceId]) === null || _b === void 0 ? void 0 : _b.findIndex(function (existService) {
        return existService.segments.includes(service.segmentId);
    });
    if (existServicesIndex >= 0) {
        var existService = passengerState[service.serviceId][existServicesIndex];
        if (existService.number > 0) {
            return __assign(__assign({}, passengerState), (_a = {}, _a[service.serviceId] = passengerState[service.serviceId].map(function (state, index) {
                if (index === existServicesIndex) {
                    return __assign(__assign({}, state), { number: getNewCount(state.number, service.count ? service.count : 1, getConfirmedCount(state.servicePayload)) });
                }
                return state;
            }), _a));
        }
    }
    return passengerState;
};
