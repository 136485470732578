import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { APOLLO_ERRORS_EVENT } from '../../../../apolloErrorLink';
import { useToggleable } from '../../../../hooks';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var OPERATION_NAME = 'SaveOrderServices';
var SeatMapError = function (props) {
    var theme = useTheme('SeatMap').SeatMapError;
    var t = useTranslation('SeatMap').t;
    var _a = __read(useState(null), 2), msg = _a[0], setMsg = _a[1];
    var _b = useToggleable(false), isOpen = _b.isOpen, close = _b.close, open = _b.open;
    React.useEffect(function () {
        var eventHandler = function (event) {
            var _a;
            var customEvent = event;
            if (customEvent.detail.operationName === OPERATION_NAME && ((_a = customEvent.detail.message) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                setMsg(t(customEvent.detail.message));
                open();
            }
        };
        document.addEventListener(APOLLO_ERRORS_EVENT, eventHandler);
        return function () { return document.removeEventListener(APOLLO_ERRORS_EVENT, eventHandler); };
    }, []);
    return (React.createElement(Snackbar, __assign({ anchorOrigin: { vertical: 'top', horizontal: 'center' }, className: cn(props.className, theme.root), ContentProps: { className: theme.content, classes: { message: theme.message } }, open: isOpen, onClose: close, message: msg }, props)));
};
export default SeatMapError;
