import { __read } from "tslib";
import * as React from 'react';
import { getAirlinesList, getDurationString, getFlightDuration } from '../Results/utils';
import { getTransferShortLabel } from './utils';
import { format, removeDotsFromDate } from '../utils';
import { FlightSegmentStatus } from '@websky/graphql';
export var useMobileSegmentInfo = function (componentProps) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var DEPARTURE_DATE_FORMAT = 'dd MMM, EEEEEE';
    var segments = componentProps.segments, showFullInfo = componentProps.showFullInfo;
    var _q = __read(React.useState(false), 2), detailsOpen = _q[0], toggleDetails = _q[1];
    var firstSegment = segments[0].segment;
    var lastSegment = segments[segments.length - 1].segment;
    var allAirlines = getAirlinesList(segments).map(function (airline) { return airline.name; });
    var isNextDayArrival = firstSegment.departure.date !== lastSegment.arrival.date;
    var transferString = getTransferShortLabel(segments);
    var departureTime = firstSegment.departure.time.substr(0, 5);
    var departureDate = removeDotsFromDate(format(firstSegment.departure.date, DEPARTURE_DATE_FORMAT));
    var arrivalTime = lastSegment.arrival.time.substr(0, 5);
    var durationTime = getDurationString(getFlightDuration(segments, showFullInfo));
    var arrivalDate = removeDotsFromDate(format(lastSegment.arrival.date, DEPARTURE_DATE_FORMAT));
    var firstSegmentCity = (_g = (_d = (_c = (_b = (_a = firstSegment.departure) === null || _a === void 0 ? void 0 : _a.airport) === null || _b === void 0 ? void 0 : _b.city) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (_f = (_e = firstSegment === null || firstSegment === void 0 ? void 0 : firstSegment.departure) === null || _e === void 0 ? void 0 : _e.airport) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : firstSegment.departure.airport.iata;
    var lastSegmentCity = (_p = (_l = (_k = (_j = (_h = lastSegment.arrival) === null || _h === void 0 ? void 0 : _h.airport) === null || _j === void 0 ? void 0 : _j.city) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : (_o = (_m = lastSegment === null || lastSegment === void 0 ? void 0 : lastSegment.arrival) === null || _m === void 0 ? void 0 : _m.airport) === null || _o === void 0 ? void 0 : _o.name) !== null && _p !== void 0 ? _p : lastSegment.arrival.airport.iata;
    var canceled = firstSegment.status === FlightSegmentStatus.Canceled;
    return {
        detailsOpen: detailsOpen,
        toggleDetails: toggleDetails,
        allAirlines: allAirlines,
        isNextDayArrival: isNextDayArrival,
        transferString: transferString,
        firstSegment: firstSegment,
        lastSegment: lastSegment,
        departureTime: departureTime,
        arrivalTime: arrivalTime,
        durationTime: durationTime,
        departureDate: departureDate,
        firstSegmentCity: firstSegmentCity,
        lastSegmentCity: lastSegmentCity,
        arrivalDate: arrivalDate,
        canceled: canceled
    };
};
