import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { useIsMobile } from '../../utils';
import { useToggleable } from '../../hooks';
import SimpleLoader from '../../SimpleLoader';
import Modal from '../../Modal';
import Button from '../../Button';
var SelectSegments = function (_a) {
    var _b;
    var className = _a.className, segments = _a.segments, title = _a.title, onClick = _a.onClick, renderTrigger = _a.renderTrigger, renderButton = _a.renderButton, renderSegment = _a.renderSegment;
    var css = useTheme('SelectSegments').SelectSegments;
    var t = useTranslation('SelectSegments').t;
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useToggleable(false), isOpen = _d.isOpen, open = _d.open, close = _d.close;
    var isMobile = useIsMobile();
    var content = React.useMemo(function () {
        return (React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.title }, title),
            renderSegment
                ? segments.map(renderSegment)
                : segments.map(function (segment) { return (React.createElement("div", { key: segment.id, className: css.segment },
                    React.createElement("div", { className: css.routes },
                        React.createElement("span", { className: css.iata },
                            segment.departure.airport.iata,
                            " - ",
                            segment.arrival.airport.iata),
                        React.createElement("span", { className: css.cities },
                            segment.departure.airport.name,
                            " - ",
                            segment.arrival.airport.name)),
                    React.createElement("div", { className: css.controls }, renderButton(segment, close)))); })));
    }, [segments, title, renderSegment, renderButton]);
    var onClickHandler = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(segments.length > 1)) return [3 /*break*/, 1];
                        open();
                        return [3 /*break*/, 3];
                    case 1:
                        setIsLoading(true);
                        return [4 /*yield*/, onClick.apply(void 0, __spreadArray([], __read(args), false))];
                    case 2:
                        _a.sent();
                        setIsLoading(false);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }, [segments, onClick, open, isLoading]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null), (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ onClick: onClickHandler, isLoading: isLoading })) !== null && _b !== void 0 ? _b : (React.createElement(Button, { className: className, onClick: onClickHandler, isLoading: isLoading }, t('Download'))),
        React.createElement(Modal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, open: isOpen, onClose: close, maxWidth: "md", slideUp: isMobile }, content)));
};
export default SelectSegments;
