import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { useTheme } from '../theme';
import { amenitiesIcons, leafIcon } from './icons';
var Amenities = React.memo(function (_a) {
    var amenities = _a.amenities, isEco = _a.isEco, _b = _a.filterAvailable, filterAvailable = _b === void 0 ? true : _b, className = _a.className;
    var css = useTheme('Amenities').Amenities;
    var t = useTranslation().t;
    if (!(amenities === null || amenities === void 0 ? void 0 : amenities.length)) {
        return null;
    }
    var filterAmenities = amenities.filter(function (amenity) { return amenitiesIcons === null || amenitiesIcons === void 0 ? void 0 : amenitiesIcons[amenity.type]; });
    if (filterAvailable) {
        filterAmenities = filterAmenities.filter(function (amenity) { return amenity.available; });
    }
    if (!filterAmenities.length) {
        return null;
    }
    filterAmenities = __spreadArray([], __read(filterAmenities), false).sort(function (a, b) {
        return a.available === b.available ? 0 : a.available && b.available ? -1 : 1;
    });
    return (React.createElement("div", { className: cn(css.amenities, className) },
        filterAmenities.map(function (amenity) {
            var _a;
            return (React.createElement("span", { key: amenity.type, className: cn(css.amenities__item, (_a = {},
                    _a[css.amenities__item_unavailable] = !amenity.available,
                    _a)) },
                React.createElement(Tooltip, { key: amenity.type, title: amenity.available
                        ? amenity.description
                        : t('DesktopFlightInfo:Service is unavailable on this flight'), enterTouchDelay: 0 }, amenitiesIcons[amenity.type])));
        }),
        isEco && (React.createElement(Tooltip, { title: t('DesktopFlightInfo:Eco-friendly') },
            React.createElement("span", { className: css.amenities__item }, leafIcon)))));
});
export default Amenities;
