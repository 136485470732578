import * as React from 'react';
import Segment from './Segment/Segment';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { BaseButton } from '../../BaseComponents';
initI18n('DesktopFlightInfo');
var FlightInfo = function (_a) {
    var flight = _a.flight, onButtonClick = _a.onButtonClick, _b = _a.buttonText, buttonText = _b === void 0 ? '' : _b, _c = _a.buttonType, buttonType = _c === void 0 ? 'common' : _c, renderPreFooter = _a.renderPreFooter;
    var css = useTheme('DesktopFlightInfo').DesktopFlightInfo;
    var getNextSegment = function (index) { return flight.segments[index + 1].segment; };
    return (React.createElement("div", { className: css.info },
        React.createElement("div", { className: css.segments }, flight.segments.map(function (_a, index) {
            var segment = _a.segment, transferDuration = _a.transferDuration;
            return (React.createElement(Segment, { key: index, segment: segment, transferDuration: transferDuration, nextSegment: transferDuration ? getNextSegment(index) : undefined }));
        })),
        renderPreFooter && renderPreFooter(),
        onButtonClick && (React.createElement("div", { className: css.footer },
            React.createElement(BaseButton, { variant: "optionEdit", onClick: onButtonClick, classes: { root: css.button_default } }, buttonText)))));
};
export default FlightInfo;
