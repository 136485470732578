import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
import { useTheme } from '../../../theme';
import SeatInfoGroup from './SeatInfoGroup/SeatInfoGroup';
import FreeSeatInfo from './FreeSeatInfo/FreeSeatInfo';
import { OverrideComponent } from '../../../renderProps';
var SeatsInfo = function (props) {
    var theme = useTheme('SeatMap').SeatsInfoStyles;
    var seatsByClassesMap = React.useMemo(function () {
        var seatsByClasses = new Map();
        props.seatsInfo.forEach(function (seat) {
            seatsByClasses.set(seat.serviceClass, seatsByClasses.has(seat.serviceClass) ? __spreadArray(__spreadArray([], __read(seatsByClasses.get(seat.serviceClass)), false), [seat], false) : [seat]);
        });
        return seatsByClasses;
    }, [props.seatsInfo]);
    var seatsByClasses = React.useMemo(function () {
        var seatsByClasses = [];
        seatsByClassesMap.forEach(function (seats, classType) {
            var _a;
            seatsByClasses.push({
                classType: classType,
                seats: seats,
                image: (_a = seats.find(function (seat) { return !!seat.serviceImage; })) === null || _a === void 0 ? void 0 : _a.serviceImage
            });
        });
        return seatsByClasses;
    }, [seatsByClassesMap]);
    var renderSeatsByClasses = function (mobile) {
        var seatsInfoGroups = seatsByClasses.map(function (seats) {
            return (React.createElement("div", { key: seats.classType },
                React.createElement(OverrideComponent, { componentProps: {
                        seatService: seats.seats,
                        fullInfo: mobile,
                        hideImage: false,
                        segmentId: props.segmentId
                    }, component: { SeatInfoGroup: SeatInfoGroup } })));
        });
        if (props.hasFreeSeats) {
            seatsInfoGroups.push(React.createElement(FreeSeatInfo, { key: seatsInfoGroups.length }));
        }
        return seatsInfoGroups;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIDDLE_WIDTH + 1 },
            React.createElement("aside", { className: cn(theme.info) }, renderSeatsByClasses())),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH }, renderSeatsByClasses(true))));
};
export default SeatsInfo;
