import * as React from 'react';
import { useTheme } from '../theme';
import { initI18n } from '../../utils';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
initI18n('Loader');
var Loader = function (props) {
    var css = useTheme().Loader, t = useTranslation('Loader').t;
    return (React.createElement("div", { className: cn(css.loader__content, props.className) },
        React.createElement("div", { className: css.loader },
            React.createElement("div", { className: cn(css.loader_spinner, props.spinnerClassName) })),
        ' ',
        !props.hideText && "".concat(t('loading'), "...")));
};
export default Loader;
