import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { TravellerFields } from '../../../../TravellerForm/types';
import { useTranslation } from 'react-i18next';
import { overridedIcons } from '../../../../Icons';
var Header = function (_a) {
    var _b;
    var passengerId = _a.passengerId, label = _a.label, numberIcon = _a.numberIcon, correct = _a.correct, onClick = _a.onClick, passengerFieldsMap = _a.passengerFieldsMap, open = _a.open, showErrorNotice = _a.showErrorNotice;
    var theme = useTheme('PassengerForm').Header;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var t = useTranslation('Passenger').t;
    return (React.createElement("div", { className: cn(theme.header, (_b = {},
            _b[theme.filled] = correct,
            _b[theme.collapsed] = !open,
            _b[theme.header_error] = showErrorNotice,
            _b)), onClick: onClick },
        React.createElement("div", { className: theme.number }, numberIcon || passengerId + 1),
        React.createElement("div", { className: theme.payload },
            React.createElement("div", { className: theme.passengerName }, label),
            isMobile && showErrorNotice && React.createElement("div", { className: theme.error }, t('Please, check passenger data')),
            isMobile && correct && passengerFieldsMap && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    passengerFieldsMap[TravellerFields.Gender],
                    ",",
                    ' ',
                    passengerFieldsMap[TravellerFields.BirthDate],
                    ",",
                    ' ',
                    passengerFieldsMap[TravellerFields.Nationality]),
                passengerFieldsMap['docType'] && (React.createElement("div", null,
                    passengerFieldsMap['docType'],
                    ": ",
                    passengerFieldsMap['docNumber'])),
                passengerFieldsMap['docExpiryDate'] && (React.createElement("div", null,
                    t('docExpiryDate'),
                    ": ",
                    passengerFieldsMap['docExpiryDate'])),
                passengerFieldsMap['loyaltyNumber'] && (React.createElement("div", { className: theme.loyalty_number },
                    t('loyaltyNumber'),
                    ": ",
                    passengerFieldsMap['loyaltyNumber']))))),
        correct && !numberIcon && overridedIcons['Included']));
};
export default Header;
