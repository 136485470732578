import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page404 from '../../../../Page404/Page404';
import Initial from './Initial/Initial';
import OrderWrapper from './OrderWrapper';
import { createDataLayer } from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../../DataLayerAnalytics/OrderDataLayer/types';
import { useCurrentUser } from '../../../../CurrentUser/CurrentUser.hooks';
var Checkin = function () {
    createDataLayer(OrderType.Checkin);
    useCurrentUser();
    var pnr = '[0-9БВГДКЛМНПРСТФХЦЖШA-Z]{6,6}';
    var ticketNumber = '[0-9A-ZА-Я]{13,13}';
    var lastName = '[\\wА-яA-z\\s-—]+';
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/", exact: true, component: Initial }),
        React.createElement(Route, { path: "/:id(\\d+)", render: function (_a) {
                var match = _a.match;
                return React.createElement(OrderWrapper, { orderId: match.params.id });
            } }),
        React.createElement(Route, { path: [
                "/search/:PNR(".concat(pnr, ")?/:lastName(").concat(lastName, ")?"),
                "/search/:PNR(".concat(ticketNumber, ")?/:lastName(").concat(lastName, ")?"),
                "/search/:PNR(".concat(pnr, ")?/:lastName(").concat(lastName, ")?/submit"),
                "/search/:PNR(".concat(ticketNumber, ")?/:lastName(").concat(lastName, ")?/submit")
            ], exact: true, component: Initial }),
        React.createElement(Page404, { onClick: function () { return (window.location.href = '/'); } })));
};
export default Checkin;
