import * as React from 'react';
import { API_DATE_FORMAT, format, removeDotsFromDate } from '../../../utils';
import { parse } from 'date-fns';
import SummaryItem from '../Summary/SummaryItem';
var Segments = function (_a) {
    var order = _a.order;
    return (React.createElement(React.Fragment, null, order.flight.segmentGroups.map(function (group) {
        var firstSegment = group.segments[0], lastSegment = group.segments[group.segments.length - 1], date = removeDotsFromDate(format(parse(group.segments[0].departure.date, API_DATE_FORMAT, new Date()), 'dd MMM, yyyy'));
        return (React.createElement(SummaryItem, { key: group.groupId, header: "".concat(firstSegment.departure.airport.city.name, " \u2014 ").concat(lastSegment.arrival.airport.city.name) },
            date,
            ", ",
            group.segments[0].departure.time));
    })));
};
export default Segments;
