import AirportTooltip from './components/AirportTooltip/AirportTooltip.css';
import FlightTooltip from './components/FlightTooltip/FlightTooltip.css';
import Header from './components/Header/Header.css';
import FlightListItem from './components/FlightListItem/FlightListItem.css';
import FlightStatus from './components/FlightStatus/FlightStatus.css';
import BoardingInfo from './components/BoardingInfo/BoardingInfo.css';
import Map from './components/Map/Map.css';
export default {
    AirportTooltip: AirportTooltip,
    FlightTooltip: FlightTooltip,
    Header: Header,
    FlightListItem: FlightListItem,
    FlightStatus: FlightStatus,
    Map: Map,
    BoardingInfo: BoardingInfo
};
