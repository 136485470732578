import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useLocationsQuery } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { sortLocationsByName } from '../../utils';
import Location from '../Location/Location';
var Locations = function (_a) {
    var className = _a.className, _b = _a.additionalGroups, additionalGroups = _b === void 0 ? [] : _b, departure = _a.departure, _c = _a.locationsSorting, locationsSorting = _c === void 0 ? sortLocationsByName : _c, onSelect = _a.onSelect;
    var theme = useTheme('CoreSchedule').Locations;
    var t = useTranslation('Schedule').t;
    var _d = useLocationsQuery({
        variables: {
            parameters: {
                additionalCities: [],
                additionalGroups: additionalGroups,
                departureFrom: (departure === null || departure === void 0 ? void 0 : departure.iata) || null,
                query: '',
                userPosition: null,
                directOnly: true
            }
        }
    }), data = _d.data, loading = _d.loading;
    var locations = useMemo(function () {
        var cities = [];
        if (data === null || data === void 0 ? void 0 : data.LocationSuggestions) {
            data.LocationSuggestions.forEach(function (location) {
                var _a;
                if ((_a = location.country) === null || _a === void 0 ? void 0 : _a.name) {
                    cities = __spreadArray(__spreadArray([], __read(cities), false), __read(location.cities.map(function (city) { return (__assign(__assign({}, city), { country: location.country.name })); })), false);
                }
            });
        }
        return cities.sort(locationsSorting);
    }, [data === null || data === void 0 ? void 0 : data.LocationSuggestions, loading, locationsSorting]);
    var onClickHandler = function (iata) { return function () { return onSelect(iata); }; };
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement(LinearProgress, { classes: {
                root: theme.progress,
                barColorPrimary: theme.barColorPrimary,
                colorPrimary: theme.colorPrimary
            } })),
        locations.length > 0 && (React.createElement("div", { className: cn(theme.container, className) },
            React.createElement("div", { className: theme.title }, t("Where are you flying ".concat(departure ? 'to' : 'from'), { city: departure === null || departure === void 0 ? void 0 : departure.name })),
            React.createElement("div", { className: theme.locations }, locations.map(function (location) { return (React.createElement(Location, { key: "".concat(location.country, "-").concat(location.name), className: theme.location, country: location.country, city: location.name, onClick: onClickHandler(location), disabled: loading })); }))))));
};
export default Locations;
