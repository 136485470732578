import { __read } from "tslib";
import * as React from 'react';
import * as icons from './Icons';
import { OverrideComponent } from '../renderProps';
var overridedIcons = {};
Object.entries(icons).forEach(function (_a) {
    var _b;
    var _c = __read(_a, 2), name = _c[0], icon = _c[1];
    overridedIcons[name] = React.createElement(OverrideComponent, { componentProps: null, component: (_b = {}, _b[name] = function () { return icon; }, _b) });
});
export { overridedIcons };
export * from './Icons';
