import { __assign } from "tslib";
import * as React from 'react';
import Checkbox from '../../../BaseComponents/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { isCheckinFlightGuard, isCheckinSegmentGuard } from '../../store';
import { SelectableCheckoutSegment } from '../SelectableSegment/SelectableSegment';
import { useCallback } from 'react';
import { Fade } from '@mui/material';
import { SelectableCheckinSegment } from '../SelectableSegment/CheckinSelectableSegment';
var FlightSelector = function (_a) {
    var _b;
    var flights = _a.flights, selectedSegments = _a.selectedSegments, onChange = _a.onChange, type = _a.type, transferInfo = _a.transferInfo;
    var _c = useTheme('UpgradeFlightClass'), css = _c.FlightSelector, selectableSegmentStyles = _c.SelectableSegment;
    var handleToggle = useCallback(function (segmentId) {
        if (type === 'select' && onChange) {
            onChange(segmentId);
        }
    }, [selectedSegments, onChange]);
    var renderSegment = useCallback(function (segment, index, flight) {
        var _a;
        var isMultipleSegmentsOnFlightSelected;
        if (flight) {
            isMultipleSegmentsOnFlightSelected =
                ((_a = flight.segments.filter(function (flightSegment) {
                    return selectedSegments.includes(flightSegment.id);
                })) === null || _a === void 0 ? void 0 : _a.length) > 1;
        }
        return (React.createElement(Fade, { key: segment.id, in: type === 'select' || selectedSegments.includes(segment.id), unmountOnExit: true },
            React.createElement("div", { key: segment.id },
                React.createElement(FormControlLabel, { className: css.formControl, classes: {
                        label: css.label
                    }, control: type === 'select' ? (React.createElement(Checkbox, { defaultChecked: selectedSegments.includes(segment.id), onChange: function () { return handleToggle(segment.id); }, className: css.checkbox })) : (React.createElement("div", null)), label: isCheckinSegmentGuard(segment) ? (React.createElement(SelectableCheckinSegment, { showStopsInfo: type === 'select' || isMultipleSegmentsOnFlightSelected, segment: segment, index: index, highlight: type === 'select' && selectedSegments.includes(segment.id), parentFlight: {
                            id: null,
                            price: null,
                            flightNumber: null,
                            arrival: null,
                            departure: null,
                            duration: null,
                            stops: [],
                            operatingAirline: null,
                            marketingAirline: null,
                            checkinInfo: null,
                            segments: [
                                __assign(__assign({}, segment), { messages: [] })
                            ] // todo: write adapter
                        }, transferInfo: transferInfo[segment.id] })) : (React.createElement(SelectableCheckoutSegment, { showStopsInfo: type === 'select' || isMultipleSegmentsOnFlightSelected, segment: segment, index: index, highlight: type === 'select' && selectedSegments.includes(segment.id), parentFlight: flight, transferInfo: transferInfo[segment.id] })) }))));
    }, [selectedSegments, flights, type, transferInfo]);
    if (isCheckinFlightGuard(flights[0])) {
        return (React.createElement("div", { className: cn(selectableSegmentStyles.flight, (_b = {},
                _b[selectableSegmentStyles.transfer] = flights.length > 1,
                _b)) }, flights.map(function (segment, index) { return renderSegment(segment, index, null); })));
    }
    else {
        return (React.createElement(React.Fragment, null, flights.map(function (flight) {
            var _a;
            var aviaFlight = flight;
            return (React.createElement("div", { key: aviaFlight.flightId + aviaFlight.groupId, className: cn(selectableSegmentStyles.flight, (_a = {},
                    _a[selectableSegmentStyles.transfer] = aviaFlight.segments.length > 1,
                    _a)) }, aviaFlight.segments.map(function (segment, index) { return renderSegment(segment, index, flight); })));
        })));
    }
};
export default FlightSelector;
