import { __read, __spreadArray } from "tslib";
import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import sagaMiddlewareFactory from 'redux-saga';
import { isBefore, parseISO, startOfToday } from 'date-fns';
import { rootReducer } from './reducers';
import rootSaga from './sagas';
import { get, SEARCH_FORM_STATE_KEY, set } from '../../cache';
import { setPassengers } from './passengers/actions';
import { setMultiCity, setSegments } from './segments/actions';
import { convertPassengersSearchParameters, convertSegmentsSearchParameters } from '../utils';
import memes from '../../memes';
import { getLocale } from '../../utils';
import { setRedeemMiles } from './redeemMiles/actions';
import { offerHotelsAction } from './offerHotels/actions';
import { useConfig } from '../../context';
var parseCachedDate = function (date) { return (typeof date === 'string' ? parseISO(date) : date); };
var actualizeDate = function (date) { return (isBefore(date, startOfToday()) ? startOfToday() : date); };
export var createStore = memes(function (defaultParameters, passengersConfig, proMode, enableOfferHotels) {
    var _a, _b;
    var searchFormState = get(SEARCH_FORM_STATE_KEY, {});
    var saga = sagaMiddlewareFactory({
        context: {
            config: useConfig()
        }
    });
    var defaultState = {};
    var middleware = [saga];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    // Use cached state only if the cached locale is equal to the current user locale.
    if (searchFormState && searchFormState.locale === getLocale()) {
        // Convert strings from cache to Date
        if (searchFormState.state) {
            searchFormState.state.segments.multiCitySegments.forEach(function (segment) {
                segment.date = actualizeDate(parseCachedDate(segment.date));
                segment.dateBack = actualizeDate(parseCachedDate(segment.dateBack));
            });
            var simpleRoute = searchFormState.state.segments.simpleRouteSegment;
            simpleRoute.date = actualizeDate(parseCachedDate(simpleRoute.date));
            simpleRoute.dateBack = actualizeDate(parseCachedDate(simpleRoute.dateBack));
        }
        defaultState = searchFormState.state;
    }
    if (passengersConfig) {
        var passengersConfigState_1 = {};
        if (!defaultState.passengers) {
            defaultState.passengers = {};
        }
        passengersConfig.forEach(function (passenger) {
            var _a;
            var count = 0;
            if (defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.passengers) {
                count =
                    ((_a = defaultParameters.passengers.find(function (pass) { return pass.passengerType === passenger.code; })) === null || _a === void 0 ? void 0 : _a.count) || 0;
            }
            else if (passenger.defaultCount) {
                count = passenger.defaultCount;
            }
            defaultState.passengers[passenger.code] = count;
            passengersConfigState_1[passenger.code] = passenger;
        });
        defaultState.passengersConfig = passengersConfigState_1;
    }
    var store = originalCreateStore(rootReducer, defaultState, applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false)));
    // Some predefined values.
    if (defaultParameters && defaultParameters.passengers) {
        store.dispatch(setPassengers(convertPassengersSearchParameters(defaultParameters.passengers, defaultState.passengersConfig)));
    }
    if (proMode) {
        store.dispatch(setMultiCity(true));
    }
    store.dispatch(offerHotelsAction(!!enableOfferHotels));
    if ((_a = defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.ffpMode) !== null && _a !== void 0 ? _a : (_b = searchFormState === null || searchFormState === void 0 ? void 0 : searchFormState.state) === null || _b === void 0 ? void 0 : _b.redeemMiles) {
        store.dispatch(setRedeemMiles(true));
    }
    else {
        store.dispatch(setRedeemMiles(false));
    }
    saga.run(rootSaga);
    if (defaultParameters && defaultParameters.segments) {
        store.dispatch(setSegments(convertSegmentsSearchParameters(defaultParameters.segments)));
    }
    // Cache form state on every update.
    store.subscribe(function () {
        return set(SEARCH_FORM_STATE_KEY, {
            locale: getLocale(),
            state: store.getState()
        });
    });
    return store;
});
