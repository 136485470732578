export var vouchersList = [
    {
        number: 562341,
        expires: new Date(2019, 8, 23),
        value: 20
    },
    {
        number: 123123,
        expires: new Date(2019, 7, 11),
        value: 15
    },
    {
        number: 999999,
        expires: new Date(2019, 5, 17),
        value: 20
    },
    {
        number: 456234,
        expires: new Date(2019, 4, 12),
        value: 20
    },
    {
        number: 876879,
        expires: new Date(2019, 5, 14),
        value: 20
    }
];
