import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isSameDay } from 'date-fns';
import { useTheme } from '../theme';
import { format, initI18n } from '../utils';
import Notification from '../Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
initI18n('PaymentTimeLimit');
var PaymentTimeLimit = function (props) {
    var _a;
    var theme = useTheme('PaymentTimeLimit').PaymentTimeLimit;
    var t = useTranslation('PaymentTimeLimit').t;
    var isToday = isSameDay(props.date, new Date());
    // const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var messageText = React.useMemo(function () {
        var message = props.orderIsBooked ? t('Booking must be paid') : t('Booking must be completed');
        message += isToday
            ? " ".concat(t('today before'), " ").concat(format(props.date, 'HH:mm'))
            : " ".concat(t('before'), " ").concat(format(props.date, 'dd MMMM, HH:mm'));
        return message;
    }, [isToday, props.date]);
    return (React.createElement(Notification, { infoMessage: {
            text: messageText,
            type: InfoMessageTypeEnum.Info,
            code: null
        }, status: InfoMessageTypeEnum.Info, type: "context", className: cn(props.className, theme.wrapper, (_a = {},
            _a[theme.wrapper_outlined] = props.variant === 'outlined',
            _a)), actionText: props.orderIsBooked && props.onCancelOrder && t('Cancel order'), onClickAction: props.onCancelOrder ? props.onCancelOrder : null }));
};
export default PaymentTimeLimit;
