import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var BaggageCount = function (_a) {
    var className = _a.className, count = _a.count;
    var css = useTheme('BaggageList').BaggageCount;
    if (count === 0) {
        return null;
    }
    return React.createElement("div", { className: cn(css.root, className) },
        "x",
        count);
};
export default BaggageCount;
