import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useAddTravellersDataMutation, useSaveOrderServicesMutation } from '@websky/graphql';
import { useToggleable } from '../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setPassengerValues } from '../Checkout/store/passengers/actions';
import { getIsPriceFreezeProductSelected, getIsTravellersDataFilled, getOrder } from '../Checkout/store/order/selectors';
import { getPassengerState } from '../Checkout/store/passengers/selectors';
import { isFareLockService } from './utils';
export var useFareLock = function (order, onOrderLoaded, setLoading) {
    var _a, _b, _c;
    var _d = useToggleable(false), isSelected = _d.isOpen, toggle = _d.toggle, open = _d.open, close = _d.close;
    var fareLockService = useMemo(function () { var _a; return (_a = order === null || order === void 0 ? void 0 : order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services.find(isFareLockService); }, [
        order
    ]);
    var _e = __read(useSaveOrderServicesMutation(), 2), serviceRequest = _e[0], loading = _e[1].loading;
    useEffect(function () {
        if (setLoading) {
            setLoading(loading);
        }
    }, [loading]);
    var handleChangeFareLock = useCallback(function () {
        var travellerServices = [];
        toggle();
        if (loading) {
            return;
        }
        order.travellers.forEach(function (traveller) {
            var gdsServices = {};
            var allowedPassengers = Array.prototype.concat.apply([], fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.allowedPassengers);
            if (!allowedPassengers.includes(traveller.id)) {
                return;
            }
            if (!isSelected) {
                gdsServices.addServices = [
                    {
                        count: 1,
                        segmentIds: Array.prototype.concat.apply([], fareLockService.allowedSegments),
                        serviceId: fareLockService.id
                    }
                ];
            }
            else {
                gdsServices.setServices = [
                    {
                        count: 0,
                        segmentIds: Array.prototype.concat.apply([], fareLockService.allowedSegments),
                        serviceId: fareLockService.id
                    }
                ];
            }
            travellerServices.push({
                travellerId: traveller.id,
                gdsServices: gdsServices
            });
        });
        serviceRequest({
            variables: {
                params: {
                    id: order.id,
                    services: travellerServices
                }
            }
        }).then(function (data) {
            var _a;
            if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.SaveOrderServices) {
                onOrderLoaded(data.data.SaveOrderServices);
            }
        });
    }, [isSelected, order, fareLockService, serviceRequest, loading]);
    var isAddedToOrder = (_a = order === null || order === void 0 ? void 0 : order.travellers) === null || _a === void 0 ? void 0 : _a.some(function (traveller) { var _a, _b, _c; return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id); }); });
    useEffect(function () {
        if (isAddedToOrder) {
            open();
        }
        else {
            close();
        }
    }, [order]);
    var isConfirmed = (_b = order === null || order === void 0 ? void 0 : order.travellers) === null || _b === void 0 ? void 0 : _b.some(function (traveller) {
        var _a, _b, _c;
        return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id) && (service === null || service === void 0 ? void 0 : service.confirmedCount); });
    });
    return {
        handleChangeFareLock: handleChangeFareLock,
        isSelected: isSelected,
        isConfirmed: isConfirmed,
        toggle: toggle,
        loading: loading,
        isAddedToOrder: isAddedToOrder,
        fareLockService: fareLockService,
        price: {
            amount: ((_c = fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.price) === null || _c === void 0 ? void 0 : _c.amount) *
                (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.allowedPassengers.length) *
                Array.prototype.concat.apply([], fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.allowedSegments).length,
            currency: fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.price.currency
        }
    };
};
export var useUpdateDocumentsFreezeView = function () {
    var _a = __read(useState('review'), 2), state = _a[0], setState = _a[1];
    var toAddDocuments = function () { return setState('update'); };
    var toReview = function () { return setState('review'); };
    return {
        mode: state,
        toAddDocuments: toAddDocuments,
        toReview: toReview
    };
};
export var useFareLockBookingPage = function () { };
/**
 *
 * get formState and call SetTravellersData mutation with new values
 */
export var useUpdateTravellers = function () {
    var _a = useFormState(), values = _a.values, valid = _a.valid;
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var passengers = useSelector(getPassengerState);
    var isPriceFreezeSelected = useSelector(getIsPriceFreezeProductSelected);
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    useEffect(function () {
        if (!valid) {
            return;
        }
        order.travellers.forEach(function (traveller, index) {
            var passengerValues = traveller.values.map(function (value) {
                return {
                    name: value.name,
                    type: value.type,
                    value: values['passengers'][index][value.name]
                };
            });
            dispatch(setPassengerValues(index, passengerValues));
        });
    }, [values, valid, order.travellers]);
    var _b = __read(useAddTravellersDataMutation(), 2), setTravellersData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    var handleSubmit = useCallback(function (passengersValues) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isPriceFreezeSelected || isTravellersDataFilled) {
                        return [2 /*return*/, null];
                    }
                    return [4 /*yield*/, setTravellersData({
                            variables: {
                                variables: {
                                    orderId: order.id,
                                    travellersData: passengersValues
                                }
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [passengers, isPriceFreezeSelected, isTravellersDataFilled]);
    return {
        handleSubmit: handleSubmit,
        loading: loading,
        data: data,
        valid: valid
    };
};
