import { createTheme } from '../utils';
import * as QuickSearchForm from './components/QuickSearchForm.css';
import * as Summary from './components/Summary/Summary.css';
import * as DummySummary from './components/DummySummary/DummySummary.css';
export var defaultTheme = {
    QuickSearchForm: QuickSearchForm,
    Summary: Summary,
    DummySummary: DummySummary
};
export default defaultTheme;
export var useTheme = createTheme(defaultTheme).useTheme;
