import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { ArrowForward } from '../../../Icons';
import FullscreenDialogClose from '../../../FullscreenDialog/components/Close/Close';
var DesktopFlightModalHeader = function (_a) {
    var className = _a.className, title = _a.title, onClose = _a.onClose;
    var css = useTheme('DesktopFlight').DesktopFlightModalHeader;
    var t = useTranslation('FlightInfoModalContent').t;
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.arrow, onClick: onClose }, ArrowForward),
            React.createElement("div", { className: css.title }, title),
            React.createElement(FullscreenDialogClose, { text: t('Back'), onClose: onClose, closeClassName: css.close }))));
};
export default DesktopFlightModalHeader;
