import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { Metasearch } from '../../types';
import { useConfig } from '../../context';
import { iataCodeLogoMap } from './logotypesMap';
import { useRenderers } from '../../renderProps';
import ActionButton from '../../Button/components/ActionButton/ActionButton';
initI18n('MetasearchLoader');
var MetasearchLoader = function (_a) {
    var _b, _c;
    var _d, _e;
    var _f = _a.variant, variant = _f === void 0 ? Metasearch.Aviasales : _f, _g = _a.hasError, hasError = _g === void 0 ? false : _g, onRetry = _a.onRetry;
    var css = useTheme('MetasearchLoader').MetasearchLoader;
    var t = useTranslation('MetasearchLoader').t;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var engineRenderers = useRenderers();
    var logo = (_e = (_d = engineRenderers.MetasearchLoaderLogo) !== null && _d !== void 0 ? _d : iataCodeLogoMap.get(iataCode)) !== null && _e !== void 0 ? _e : null;
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.wrapper_aviasales] = variant === Metasearch.Aviasales,
            _b)) },
        React.createElement("div", { className: css.holder },
            React.createElement("div", { className: css.logo }, logo),
            React.createElement("div", { className: css.loader }, !hasError && variant === Metasearch.Aviasales && React.createElement("div", { className: css.aviasalesLoader })),
            React.createElement("div", { className: cn(css.footer, (_c = {},
                    _c[css.footer_withErrors] = hasError,
                    _c)) },
                hasError && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: css.text }, t('Booking service is temporary unavailable')),
                    React.createElement("p", { className: css.text }, t('Please reload the page')),
                    React.createElement(ActionButton, { className: css.retryButton, onClick: onRetry }, t('Reload')))),
                !hasError && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: css.text }, t('Checking seats availability')),
                    React.createElement("p", { className: css.text }, t('Just a few seconds...'))))))));
};
export default MetasearchLoader;
