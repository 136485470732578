import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Button from '../../../../Button';
import Dialog from '../../../../Modules/Exchange/components/Exchange/Dialog/Dialog';
var Controls = function (_a) {
    var className = _a.className, onRefuse = _a.onRefuse, onGoToPayment = _a.onGoToPayment, onGoToNewRequest = _a.onGoToNewRequest, onConfirm = _a.onConfirm;
    var t = useTranslation('EditPassenger').t;
    var theme = useTheme('EditPassenger').Controls;
    var _b = __read(React.useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var onLoadingRequest = React.useCallback(function (request) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, request()];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }; }, [setIsLoading]);
    return (React.createElement("div", { className: cn(theme.container, className) }, !isLoading ? (React.createElement(React.Fragment, null,
        onRefuse && !isLoading && (React.createElement(Dialog, { text: t('The change request was deleted. Want to create a new request?'), acceptText: t('Yes'), cancelText: t('No'), onAccept: onLoadingRequest(onRefuse), onCancel: function () { } }, function (_a) {
            var open = _a.open;
            return (React.createElement(Button, { className: cn(theme.button, theme.button_delete), onClick: open }, t('Delete request')));
        })),
        onGoToPayment && (React.createElement(Button, { className: theme.button, onClick: onGoToPayment }, t('Payment change'))),
        onGoToNewRequest && (React.createElement(Button, { className: theme.button, onClick: onGoToNewRequest }, t('Create new request'))),
        onConfirm && (React.createElement(Button, { className: theme.button, onClick: onLoadingRequest(onConfirm) }, t('Confirm'))))) : (React.createElement(Button, { className: theme.button, isLoading: true }))));
};
export default Controls;
