import { __assign, __read, __values } from "tslib";
import * as React from 'react';
import CommonSummary from './CommonSummary';
import { Transfer } from '../../Icons';
import { getActualProduct } from '../utils';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartService } from '../../Cart/types';
var TransferSummary = function (props) {
    var onDeleteHandler = function (travellerId, serviceId, segmentId, allSegments) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Extra, {
            type: OrderAdditionalServiceGdsServiceServiceType.Vip,
            passengerId: travellerId,
            segmentId: segmentId,
            serviceId: serviceId,
            allSegments: allSegments
        });
    };
    return (React.createElement(CommonSummary, { groupType: OrderAdditionalServiceGdsServiceServiceType.Vip, serviceFilterPredicate: function (service) {
            return service.subType ===
                GdsServiceVipSubType.GroundTransportation;
        }, flight: props.flight, travellers: props.travellers, services: props.services, onServiceDelete: console.log, travellersById: props.travellersById, serviceOptionGroupFactory: function (_a) {
            var e_1, _b, e_2, _c;
            var items = _a.items, travellerId = _a.travellerId;
            var allSegmentsItems = items.filter(function (item) { return item.segment === 'allSegments'; });
            var segmentsItems = items.filter(function (item) { return item.segment !== 'allSegments'; });
            var bySegmentMap = {};
            try {
                for (var segmentsItems_1 = __values(segmentsItems), segmentsItems_1_1 = segmentsItems_1.next(); !segmentsItems_1_1.done; segmentsItems_1_1 = segmentsItems_1.next()) {
                    var segmentItem = segmentsItems_1_1.value;
                    if (bySegmentMap[segmentItem.segment]) {
                        bySegmentMap[segmentItem.segment].push(segmentItem);
                    }
                    else {
                        bySegmentMap[segmentItem.segment] = [segmentItem];
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (segmentsItems_1_1 && !segmentsItems_1_1.done && (_b = segmentsItems_1.return)) _b.call(segmentsItems_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var groups = [];
            if (allSegmentsItems.length) {
                groups.push({
                    traveller: props.travellersById.get(travellerId),
                    allSegments: true,
                    headerType: 'travellerName',
                    items: allSegmentsItems
                });
            }
            if (segmentsItems.length) {
                var _loop_1 = function (segmentId, value) {
                    groups.push({
                        traveller: props.travellersById.get(travellerId),
                        direction: props.flight.segments.find(function (segment) { return segment.segment.id === segmentId; }).segment,
                        allSegments: false,
                        headerType: 'travellerName',
                        items: value
                    });
                };
                try {
                    for (var _d = __values(Object.entries(bySegmentMap)), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var _f = __read(_e.value, 2), segmentId = _f[0], value = _f[1];
                        _loop_1(segmentId, value);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_c = _d.return)) _c.call(_d);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
            return groups;
        }, serviceOptionItemFactory: function (_a) {
            var selectedService = _a.selectedService, key = _a.key, travellerId = _a.travellerId;
            var actualProduct = getActualProduct(selectedService.products);
            return {
                name: "".concat(selectedService.service.name),
                segment: key,
                id: selectedService.service.id,
                included: selectedService.count === selectedService.confirmedCount,
                price: key === 'allSegments'
                    ? __assign(__assign({}, selectedService.service.price), { amount: selectedService.service.price.amount * props.flight.segments.length }) : selectedService.service.price,
                icon: Transfer,
                status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                onDelete: function () { return onDeleteHandler(travellerId, selectedService.serviceId, key, key === 'allSegments'); }
            };
        } }, function (children) { return props.children(children); }));
};
export default TransferSummary;
