import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useFormState } from 'react-final-form';
import { DateField } from '../Date/Date';
import { Collapse } from '@material-ui/core';
var DocExpiryDate = function (_a) {
    var checkFieldIsRequired = _a.checkFieldIsRequired, props = __rest(_a, ["checkFieldIsRequired"]);
    var values = useFormState().values;
    var isRequired = checkFieldIsRequired(values);
    var label = isRequired ? "".concat(props.label, "*") : props.label;
    var disabled = !isRequired || props.disabled;
    var needToDisplay = React.useMemo(function () {
        return isRequired || !!props.value;
    }, [isRequired, props.value]);
    if (props.disabled && !!props.value) {
        return React.createElement(DateField, __assign({}, props, { disabled: true, label: label }));
    }
    else {
        return (React.createElement(Collapse, { className: props.className, in: needToDisplay },
            React.createElement(DateField, __assign({}, props, { disabled: disabled, label: label }))));
    }
};
export default DocExpiryDate;
