import { addDays, compareAsc, toDate } from 'date-fns';
export var createDatesArray = function (minDate, maxDate) {
    var date = toDate(minDate);
    var datesArray = [];
    datesArray.push(minDate);
    while (compareAsc(maxDate, date) === 1) {
        date = addDays(date, 1);
        datesArray.push(date);
    }
    return datesArray;
};
