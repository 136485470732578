import { __assign, __read, __spreadArray } from "tslib";
import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import logger from 'redux-logger';
import { rootReducer } from './reducers';
import memes from '../../memes';
export var createStore = memes(function (defaultParameters) {
    var defaultState = {};
    var middlewares = [];
    if (defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.segments) {
        defaultState = __assign(__assign({}, defaultState), { segments: defaultParameters.segments[0] });
    }
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }
    return originalCreateStore(rootReducer, defaultState, applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false)));
});
