import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { Details, NotIncluded, Time } from '../../../Icons';
import { useTheme } from '../../../theme';
var Note = function (props) {
    var _a;
    var title = props.title, content = props.content, type = props.type, icon = props.icon, iconColor = props.iconColor;
    var css = useTheme('Refund').Note;
    var renderIcon = useCallback(function () {
        if (!type && !icon) {
            return null;
        }
        if (icon) {
            return icon;
        }
        if (type === 'info') {
            return Details;
        }
        if (type === 'restriction') {
            return NotIncluded;
        }
        if (type === 'timelimit') {
            return Time;
        }
        return icon;
    }, [type, icon]);
    var noteType = css.info;
    switch (type) {
        case 'restriction':
            noteType = css.restriction;
            break;
        case 'timelimit':
            noteType = css.timelimit;
            break;
    }
    return (React.createElement("div", { className: cn(css.note, props.className, noteType) },
        React.createElement("span", { className: cn(css.icon, (_a = {},
                _a[css.red] = iconColor === 'red',
                _a[css.gray] = iconColor === 'gray',
                _a)) }, renderIcon()),
        React.createElement("h6", { className: css.title }, title),
        React.createElement("p", { className: css.text }, content)));
};
export default Note;
