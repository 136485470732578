import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { LoginType, useAuthenticateMutation, useExistentEmailByFfpQuery, useSendSecureCodeMutation } from '@websky/graphql';
import SubmitButton from '../SubmitButton/SubmitButton';
import Resend from '../../../../Contacts/components/Resend/Resend';
import Input from '../../../../Input';
import { useTheme } from '../../../../theme';
import { STAY_LOGGED_IN } from '../../../../utils';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
import { AUTH_DUPLICATES, sessionStorageSet } from '../../../../cache';
import { Steps } from '../../../types';
var CodeForm = function (_a) {
    var login = _a.login, loginType = _a.loginType, stayLoggedIn = _a.stayLoggedIn, onSuccess = _a.onSuccess, onLoginFromEmailOrFfp = _a.onLoginFromEmailOrFfp, setStep = _a.setStep;
    var _b = useTheme('LoginPage'), css = _b.CodeForm, baseCss = _b.BaseComponent;
    var t = useTranslation('LoginPage').t;
    var _c = __read(useState(false), 2), incorrectCode = _c[0], setIncorrectCode = _c[1];
    var _d = __read(useState(false), 2), codeSent = _d[0], setCodeSent = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var _f = __read(useAuthenticateMutation(), 1), authenticate = _f[0];
    var _g = __read(useSendSecureCodeMutation(), 2), sendCode = _g[0], codeSending = _g[1].loading;
    var userExistentEmailByFfp = useExistentEmailByFfpQuery({
        skip: true
    }).refetch;
    var resend = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCodeSent(false);
                    return [4 /*yield*/, sendCode({
                            variables: {
                                params: {
                                    login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                                    loginType: loginType
                                }
                            }
                        })];
                case 1:
                    result = _a.sent();
                    if (result) {
                        setCodeSent(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [login, loginType]);
    var validate = useCallback(function (values) {
        var errors = {};
        if (!values.code) {
            errors['code'] = t('Required');
        }
        return errors;
    }, []);
    var onAuthorize = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    login: loginType === LoginType.Phone ? clearPhoneNumber(login) : login,
                                    loginType: loginType,
                                    secureCode: values.code,
                                    oneSession: !stayLoggedIn
                                }
                            }
                        })];
                case 1:
                    _b.sent();
                    setIncorrectCode(false);
                    onSuccess();
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    setIncorrectCode(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [login, loginType, stayLoggedIn, onSuccess, authenticate]);
    var onUserExistentEmailByFfp = useCallback(function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var data, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIncorrectCode(false);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, userExistentEmailByFfp({ login: login, password: code })];
                case 2:
                    data = (_d.sent()).data;
                    return [2 /*return*/, (_c = (_b = data === null || data === void 0 ? void 0 : data.ExistentEmailByFfp) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : ''];
                case 3:
                    _a = _d.sent();
                    setIncorrectCode(true);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [login, userExistentEmailByFfp]);
    var onSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var existentEmailByFfp, authDuplicates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    setIncorrectCode(false);
                    if (stayLoggedIn) {
                        localStorage.setItem(STAY_LOGGED_IN, 'true');
                    }
                    else {
                        localStorage.removeItem(STAY_LOGGED_IN);
                    }
                    if (!(loginType === LoginType.FFP)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onUserExistentEmailByFfp(values.code)];
                case 1:
                    existentEmailByFfp = _a.sent();
                    if (existentEmailByFfp !== null) {
                        authDuplicates = {
                            login: login,
                            isExistDuplicate: !!existentEmailByFfp,
                            code: values.code
                        };
                        sessionStorageSet(AUTH_DUPLICATES, authDuplicates);
                        if (existentEmailByFfp) {
                            // change step to login type selector
                            onLoginFromEmailOrFfp(existentEmailByFfp, values.code);
                            return [2 /*return*/];
                        }
                    }
                    _a.label = 2;
                case 2: return [4 /*yield*/, onAuthorize(values)];
                case 3:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [login, loginType, stayLoggedIn, onAuthorize, onUserExistentEmailByFfp]);
    var message = useMemo(function () {
        switch (loginType) {
            case LoginType.Email:
                return t('An e-mail with code has been sent to you.');
            case LoginType.Phone:
                return t('Code sent to your phone.');
            default:
                return null;
        }
    }, [loginType]);
    var onBack = function () {
        setStep(Steps.LogIn);
    };
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title }, t("Enter code_".concat(loginType))),
        React.createElement("div", { className: css.message, dangerouslySetInnerHTML: { __html: message } }),
        React.createElement(Form, { validate: validate, onSubmit: onSubmit, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: css.row },
                        React.createElement("div", { className: css.input_wrapper },
                            React.createElement(Field, { name: 'code', render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    var error = incorrectCode ? t('Please, check the code') : meta.error;
                                    var notValid = !!error && meta.touched;
                                    return (React.createElement(Input, { label: t("Verification code_".concat(loginType)), TextFieldProps: __assign(__assign({}, input), { onChange: function (ev) {
                                                if (incorrectCode) {
                                                    setIncorrectCode(false);
                                                }
                                                input.onChange(ev);
                                            }, error: notValid, helperText: notValid ? error : null, InputProps: {
                                                classes: {
                                                    input: baseCss.input
                                                }
                                            } }) }));
                                } })),
                        React.createElement("div", { className: css.button_wrapper },
                            React.createElement(SubmitButton, { loading: isLoading }, t("Continue_".concat(loginType)))))));
            } }),
        loginType !== LoginType.FFP && (React.createElement(Resend, { className: css.resend, labelClassName: css.resend__label, sendCode: resend, codeSent: codeSent, codeSending: codeSending, onBack: onBack }))));
};
export default CodeForm;
