import { useTheme } from '../../../../theme';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleFlightPrice } from '../SimpleFlightPrice/SimpleFlightPrice';
import cn from 'classnames';
import Money from '../../../../Money/components/Money';
import * as React from 'react';
import PriceInMiles from '../../../../PriceInMiles/PriceInMiles';
export var MinFlightPrice = function (_a) {
    var _b, _c;
    var price = _a.price, originalPrice = _a.originalPrice, best = _a.best, singleFlight = _a.singleFlight, canBePaidByMiles = _a.canBePaidByMiles, priceInMiles = _a.priceInMiles, hideOnlyPrice = _a.hideOnlyPrice, subsidized = _a.subsidized, seatsLeft = _a.seatsLeft, flightMessages = _a.flightMessages;
    var css = useTheme('DesktopFlightRoute').FlightPriceStyles;
    var t = useTranslation('DesktopFlightInfo').t;
    var isOriginalPriceIsTooBig = useMemo(function () { return originalPrice && originalPrice.amount > 100000; }, [originalPrice]);
    return (React.createElement(SimpleFlightPrice, { singleFlight: singleFlight, best: best, subsidized: subsidized, originalPriceOrLabel: React.createElement(React.Fragment, null,
            !originalPrice && !hideOnlyPrice && (React.createElement("div", { className: cn(css.selectFrom_best) }, t(singleFlight ? 'from' : 'Select from'))),
            originalPrice && isOriginalPriceIsTooBig && React.createElement("div", null, t(singleFlight ? 'from' : 'Select from')),
            originalPrice && React.createElement(Money, { money: originalPrice, moneyClassName: css.price_original })), price: !hideOnlyPrice ? (React.createElement("div", { className: cn(css.prices__wrp) },
            !priceInMiles && (React.createElement(React.Fragment, null,
                originalPrice && !isOriginalPriceIsTooBig && (React.createElement("div", { className: cn((_b = {},
                        _b[css.selectFrom_best] = !singleFlight && best && !originalPrice,
                        _b)) }, t(singleFlight ? 'from' : 'Select from'))),
                React.createElement(Money, { moneyClassName: cn(css.price__money, (_c = {},
                        _c[css.price__money_withPromoCode] = originalPrice,
                        _c[css.price__money_tooBig] = isOriginalPriceIsTooBig,
                        _c)), money: price }))),
            priceInMiles > 0 && (React.createElement(PriceInMiles, { amount: priceInMiles, moneyClassName: cn(css.price__money, css.price_miles), currencyClassName: css.currency })))) : (t('Select')), additionalTaxes: priceInMiles > 0 && (React.createElement("div", { className: css.miles__taxes },
            t('taxes and fees'),
            ": ",
            React.createElement(Money, { money: price }))), seatsLeft: seatsLeft, flightMessages: flightMessages }));
};
