import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import AiPredictionItemWrapper from '../AiPredictionItemWrapper/AiPredictionItemWrapper';
import { convertBaggageObject } from '../../../Checkout/types';
import BaggageIcon from '../../../Baggage/Baggage/components/BaggageIcon/BaggageIcon';
var AiPurchasePredictionBaggage = function (_a) {
    var item = _a.item, onChange = _a.onChange, onDisagree = _a.onDisagree, onAgree = _a.onAgree;
    var theme = useTheme('AIPurchasePredictionService').AiPredictionItemWrapper;
    var t = useTranslation('AIPurchasePredictionService').t;
    var baggage = useMemo(function () {
        return convertBaggageObject(item);
    }, [item]);
    return (React.createElement(AiPredictionItemWrapper, { headerTitle: t('Looks like you forgot to add your baggage'), buttonTitle: t('Continue without baggage'), service: {
            price: baggage.price,
            name: baggage.name,
            renderHead: function () {
                return React.createElement(BaggageIcon, { className: theme.predictionCard__baggage, realSize: true, baggage: baggage });
            },
            renderDesc: function () {
                return React.createElement("span", null, baggage.description);
            }
        }, onDisagree: onDisagree, onAgree: onAgree, onChange: onChange }));
};
export default AiPurchasePredictionBaggage;
