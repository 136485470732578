import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { usePassengerState } from '../../../hooks';
import Radio from '../../../../../Radio';
import { useAvailableCountForTravellerOnSegment } from '../../../../../AdditionalServices/hooks';
var PassengerBaggageRadio = function (_a) {
    var _b;
    var className = _a.className, passengerId = _a.passengerId, baggages = _a.baggages, segmentIds = _a.segmentIds, segmentId = _a.segmentId, disabled = _a.disabled, onChange = _a.onChange;
    var css = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var _c = usePassengerState(passengerId, baggages, segmentIds, segmentId), items = _c.items, selectedCount = _c.selectedCount;
    var availableCount = useAvailableCountForTravellerOnSegment((_b = baggages[0]) === null || _b === void 0 ? void 0 : _b.id, passengerId, segmentIds[0]);
    var isChecked = !!selectedCount;
    var isDisabled = disabled || (availableCount === 0 && !isChecked);
    var onChangeHandler = useCallback(function (_) {
        var changedItems = items
            .filter(function (item) { return !segmentId || (segmentId && item.segmentIds.includes(segmentId)); })
            .map(function (item) { return (__assign(__assign({}, item), { count: isChecked ? 0 : 1 })); });
        onChange(changedItems);
    }, [isChecked, segmentId, items, onChange]);
    return (React.createElement("div", { className: cn(css.checkbox_wrapper, className) },
        React.createElement(Radio, { classes: {
                root: css.checkbox_root,
                checked: css.checkbox_rootChecked
            }, checked: isChecked, disabled: isDisabled, onClick: onChangeHandler })));
};
export default PassengerBaggageRadio;
