import { __assign, __rest } from "tslib";
import * as React from 'react';
import Modal from '../Modal';
import ActionButton from '../Button/components/ActionButton/ActionButton';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { useUpsaleServicesModal } from './hooks';
import { useIsMobile } from '../utils';
var UpsaleServicesModal = function (props) {
    var _a, _b, _c, _d;
    var defaultIcon = props.defaultIcon, onRefuseServices = props.onRefuseServices, travellers = props.travellers, additionalServices = props.additionalServices, serviceType = props.serviceType, descriptionRender = props.descriptionRender, onAgreeServices = props.onAgreeServices, restProps = __rest(props, ["defaultIcon", "onRefuseServices", "travellers", "additionalServices", "serviceType", "descriptionRender", "onAgreeServices"]);
    var t = useTranslation('Checkout').t;
    var css = useTheme('UpsaleModal').UpsaleModal;
    var isMobile = useIsMobile();
    var _e = useUpsaleServicesModal(travellers, additionalServices, serviceType, onRefuseServices, onAgreeServices), isOpen = _e.isOpen, upsaleServices = _e.upsaleServices, agreeHandler = _e.agreeHandler, refuseHandler = _e.refuseHandler;
    var upsaleServicesIdx = 0;
    if (!upsaleServices[upsaleServicesIdx]) {
        return null;
    }
    var icon = (_b = (_a = upsaleServices[upsaleServicesIdx]) === null || _a === void 0 ? void 0 : _a.icon) !== null && _b !== void 0 ? _b : defaultIcon;
    var extraTitleShowDates = (_c = upsaleServices[upsaleServicesIdx]) === null || _c === void 0 ? void 0 : _c.extraTitleShowDates;
    return (React.createElement(Modal, __assign({ classes: {
            scrollBody: css.scrollBody,
            paper: css.paper
        }, open: isOpen, maxWidth: 'sm' }, restProps, { onClose: refuseHandler, slideUp: isMobile, extraTitle: new Date(extraTitleShowDates[0]).getTime() <= Date.now() &&
            Date.now() <= new Date(extraTitleShowDates[1]).getTime() &&
            t((_d = upsaleServices[upsaleServicesIdx]) === null || _d === void 0 ? void 0 : _d.extraTitle) }),
        React.createElement("div", { className: css.container },
            icon && React.createElement("div", { className: css.icon }, icon),
            React.createElement("div", { className: css.description }, descriptionRender(upsaleServices[upsaleServicesIdx].service, upsaleServices[upsaleServicesIdx].description)),
            React.createElement("div", { className: css.buttons },
                React.createElement(ActionButton, { className: css.refuseBtn, onClick: refuseHandler }, t('Refuse')),
                React.createElement(ActionButton, { className: css.agreeBtn, onClick: agreeHandler }, t('Agree'))))));
};
export default UpsaleServicesModal;
