import { Measurement } from '../BaggageService/types';
export { StepsEnum as StepType } from '../types';
export var convertMealObject = function (service) { return ({
    id: service.id,
    name: service.name,
    description: service.description,
    price: service.price,
    imageURL: service.image,
    icon: service.icon,
    category: service.category,
    weight: service.mealWeight,
    allergens: service.allergens ? [service.allergens] : null,
    segmentsId: service.allowedSegments.map(function (group) { return group[0]; }),
    allowedPassengers: service.allowedPassengers.map(function (group) { return group[0]; }),
    canBeAdded: service.canBeAdded,
    allowedSegments: service.allowedSegments
}); };
export var convertBaggageObject = function (service) { return ({
    id: service.id,
    allowedPassengers: service.allowedPassengers.map(function (group) { return group[0]; }),
    segmentIds: service.allowedSegments,
    type: service.baggageType,
    value: _getBaggageWeightValue(service.baggageWeight),
    size: service.size,
    included: 0,
    price: {
        amount: service.price.amount,
        currency: service.price.currency
    },
    name: service.name,
    description: service.description,
    canBeAdded: service.canBeAdded,
    rfics: service.rfisc,
    maxServiceCountPerPassenger: service.maxServiceCountPerPassenger,
    isReplacedBy: service.isReplacedBy
}); };
var _getBaggageWeightValue = function (baggage) {
    if (!baggage) {
        return { amount: null, measurement: Measurement.Kilograms };
    }
    return {
        prefix: baggage.substring(0, baggage.match(/\d/).index).trim(),
        amount: parseInt(baggage.match(/\d/g).join('')),
        measurement: Measurement.Kilograms
    };
};
