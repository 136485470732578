import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BaggagePage from '../../../../BaggagePage/components/BaggagePage';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { getPassengerLabels, getSelectedBaggage } from '../../../store/passengers/selectors';
import { getAdditionalBaggage, getBaggage, getBonusForFlight, getBrandIncludedBaggage, getIsConfirmed, getIsOddOrderId, getOrder, getOverweightBaggage, getPriceInMiles, getSegmentsForBaggage, getSpecialEquipment, getUpgradeBaggage, hasSeatsServices } from '../../../store/order/selectors';
import { setSplitTest, SplitTest } from '../../../../analytics';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getBaggagePriceToPay, getTotalPriceToPay } from '../../../store/orderPrice/selectors';
import { addServiceAction, removeServiceAction, setMultipleSelectedServices, setServiceAction } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { getBaggageReduxCallbacks } from '../../../utils';
import { useConfig } from '../../../../context';
import { usePreselectedBaggageSelect } from '../../../hooks';
import { OrderAdditionalServiceGdsServiceServiceType, OrderStatus } from '@websky/graphql';
import UpsaleServicesModal from '../../../../UpsaleServicesModal/UpsaleServicesModal';
import { isServicesSaving } from '../../../store/servicesIsSaving/selectors';
import InfoMessagesContextProvider from '../../../../InfoMessages/context';
var Baggage = function (_a) {
    var goToPrevStep = _a.goToPrevStep, setNextStep = _a.goToNextStep;
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').CheckoutStyles;
    var isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    var order = useSelector(getOrder);
    var segments = useSelector(getSegmentsForBaggage);
    var isOddOrderId = useSelector(getIsOddOrderId);
    var selectedBaggage = useSelector(getSelectedBaggage);
    var baggage = useSelector(getBaggage);
    var includedBaggage = useSelector(getBrandIncludedBaggage);
    var upgradeBaggage = useSelector(getUpgradeBaggage);
    var additionalBaggage = useSelector(getAdditionalBaggage);
    var specialBaggage = useSelector(getSpecialEquipment);
    var overweightBaggage = useSelector(getOverweightBaggage);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMiles = useSelector(getPriceInMiles);
    var isLoading = useSelector(isServicesSaving);
    var isConfirmed = useSelector(getIsConfirmed);
    var passengers = useSelector(getPassengerStateCompatible);
    var totalPrice = useSelector(getTotalPriceToPay);
    var hasSeatsStep = useSelector(hasSeatsServices);
    var passengerLabels = useSelector(getPassengerLabels);
    var baggageTotalSum = useSelector(getBaggagePriceToPay);
    var dispatch = useDispatch();
    var addService = function (payload) { return dispatch(addServiceAction(payload)); };
    var removeService = function (payload) { return dispatch(removeServiceAction(payload)); };
    var setService = function (payload) { return dispatch(setServiceAction(payload)); };
    var disable = order.status === OrderStatus.Cancelled || !order.canAddServices;
    var _b = useConfig().global, maxBaggage = _b.maxBaggage, maxBaggagePerPassenger = _b.maxBaggagePerPassenger, dynamicBaggagePricing = _b.dynamicBaggagePricing, iataCode = _b.companyInfo.iataCode;
    usePreselectedBaggageSelect(['N4', '5G', 'JI', 'WZ'].includes(iataCode));
    var simpleBaggageSelector = !maxBaggage && !maxBaggagePerPassenger && isOddOrderId && (segments.length === 1 || isMobile);
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    var baggageCallbacks = useMemo(function () { return getBaggageReduxCallbacks(passengers, baggage, segments, addService, removeService, setService); }, [passengers, baggage, segments, addService, removeService, setService]);
    var setAdditionalBaggage = useCallback(function (services) {
        dispatch(setMultipleSelectedServices(services));
    }, []);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice: totalPrice,
            onButtonClick: setNextStep,
            onBackButtonClick: hasSeatsStep ? goToPrevStep : null,
            additional: baggageTotalSum.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Baggage'),
                ": ",
                React.createElement(Money, { money: baggageTotalSum }))) : null,
            miles: bonusForFlight,
            priceInMiles: priceInMiles
        }, mobileToolbarProps: {
            onContinue: setNextStep,
            onBack: goToPrevStep
        } },
        React.createElement(InfoMessagesContextProvider, { value: { messages: order.messages } },
            React.createElement(BaggagePage, __assign({ passengers: passengers, passengerLabels: passengerLabels, segments: segments, baggage: baggage, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, simpleBaggageSelector: simpleBaggageSelector, setAdditionalBaggage: setAdditionalBaggage, readonly: isConfirmed, dynamicBaggagePricing: dynamicBaggagePricing, isLoading: isLoading }, baggageCallbacks, { disabled: disable }))),
        React.createElement(UpsaleServicesModal, { travellers: order.travellers, additionalServices: order.additionalServices, serviceType: OrderAdditionalServiceGdsServiceServiceType.Baggage, onRefuseServices: function (payload) {
                if (payload.length) {
                    payload.forEach(removeService);
                }
            }, descriptionRender: function (upsaleService, description) {
                return t(description !== null && description !== void 0 ? description : 'We recommend using extra baggage', { name: upsaleService === null || upsaleService === void 0 ? void 0 : upsaleService.name });
            } })));
};
export default Baggage;
