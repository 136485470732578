import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { checkIconBig } from '../../Icons';
import { useTranslation } from 'react-i18next';
var List = function (_a) {
    var categories = _a.categories, selectedCategory = _a.selectedCategory, onSelect = _a.onSelect;
    var css = useTheme('MealService').List;
    var t = useTranslation('MealService').t;
    return (React.createElement("div", { className: css.wrapper }, categories.map(function (item) {
        var _a;
        return (React.createElement("div", { key: item.title, title: t(item.title), className: cn(css.item, (_a = {},
                _a[css.item_selected] = selectedCategory === item.id,
                _a)), onClick: function () { return onSelect(item.id); } },
            selectedCategory === item.id && React.createElement("span", { className: css.item__icon }, checkIconBig),
            t(item.title)));
    })));
};
export default List;
