import { __read } from "tslib";
import { useMemo, useReducer, useRef } from 'react';
import produce from 'immer';
import { insuranceInitialStateFactory, saveOrderServicesParamsFactory } from './utils';
import { getCurrency } from '../../cache';
var reducer = function (state, _a) {
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case 'setInsuranceState':
            return payload;
        case 'setLoading': {
            return produce(state, function (draft) {
                draft.isLoading = payload;
                return draft;
            });
        }
        case 'selectInsurance':
            return produce(state, function (draft) {
                draft.selectedInsurances[payload.code] = {
                    passengerPrice: payload.price,
                    insuranceProgram: payload
                };
                return draft;
            });
        case 'removeInsurance': {
            return produce(state, function (draft) {
                draft.selectedInsurances[payload.code] = null;
                return draft;
            });
        }
        default:
            return state;
    }
};
export var useInsuranceState = function (order) {
    var initialSelectedPrograms = useRef([]);
    var _a = __read(useReducer(reducer, order, function (order) {
        var initialState = insuranceInitialStateFactory(order);
        initialSelectedPrograms.current = Object.values(initialState.selectedInsurances)
            .filter(Boolean)
            .map(function (_a) {
            var code = _a.insuranceProgram.code;
            return code;
        });
        return initialState;
    }), 2), state = _a[0], dispatch = _a[1];
    // selectors
    var availableInsurances = useMemo(function () { return Object.values(state.availableInsurances); }, [state]);
    var selectedInsurances = useMemo(function () { return Object.values(state.selectedInsurances).filter(Boolean); }, [state]);
    var availableProgramsCodes = useMemo(function () { return availableInsurances.map(function (_a) {
        var code = _a.code;
        return code;
    }); }, [state]);
    var selectedProgramsCodes = useMemo(function () { return selectedInsurances.map(function (_a) {
        var code = _a.insuranceProgram.code;
        return code;
    }); }, [
        state
    ]);
    var mainPrograms = useMemo(function () {
        var insurances = Object.values(state.availableInsurances);
        // if there is only one insurance, display it as the main one
        if (insurances.length === 1) {
            return insurances;
        }
        return Object.values(state.availableInsurances).filter(function (_a) {
            var important = _a.important;
            return important;
        });
    }, [state]);
    var insurancesWithExcludedMainPrograms = useMemo(function () { return availableInsurances.filter(function (_a) {
        var code = _a.code;
        return !mainPrograms.some(function (program) { return program.code === code; });
    }); }, [state, mainPrograms]);
    var saveOrderServicesParams = useMemo(function () {
        var selectedInsuranceProgramsCodes = selectedInsurances.map(function (_a) {
            var code = _a.insuranceProgram.code;
            return code;
        });
        return saveOrderServicesParamsFactory(state.orderTravellers, selectedInsuranceProgramsCodes);
    }, [state, selectedInsurances]);
    var totalPrice = useMemo(function () {
        return selectedInsurances.reduce(function (totalPrice, current) {
            return {
                amount: totalPrice.amount += current.insuranceProgram.totalPrice.amount,
                currency: current.insuranceProgram.totalPrice.currency
            };
        }, {
            amount: 0,
            currency: getCurrency()
        });
    }, [selectedInsurances]);
    var initialStateChanged = useMemo(function () {
        return JSON.stringify(initialSelectedPrograms.current) !== JSON.stringify(selectedProgramsCodes);
    }, [state]);
    return {
        state: state,
        availableInsurances: availableInsurances,
        insurancesWithExcludedMainPrograms: insurancesWithExcludedMainPrograms,
        selectedInsurances: selectedInsurances,
        availableProgramsCodes: availableProgramsCodes,
        selectedProgramsCodes: selectedProgramsCodes,
        mainPrograms: mainPrograms,
        saveOrderServicesParams: saveOrderServicesParams,
        totalPrice: totalPrice,
        initialStateChanged: initialStateChanged,
        dispatch: dispatch
    };
};
