import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import { AircraftInfoParameterType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { ArrowForward, Chevron, Overweight, Passenger, Planet } from '../../../Icons';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import { SeatIcon } from '../../Icons';
var availableParameters = [
    AircraftInfoParameterType.CruisingSpeed,
    AircraftInfoParameterType.MaxFlightRange,
    AircraftInfoParameterType.PassengerCapacity
];
initI18n('Aircraft');
var AircraftPopupContent = function (_a) {
    var _b;
    var _c, _d, _e, _f, _g;
    var className = _a.className, name = _a.name, aircraftInfo = _a.aircraftInfo, onClose = _a.onClose;
    var css = useTheme('AircraftPopup').AircraftPopupContent;
    var t = useTranslation('Aircraft').t;
    var Arrow = function (_a) {
        var _b;
        var className = _a.className, style = _a.style, isPrev = _a.isPrev, props = __rest(_a, ["className", "style", "isPrev"]);
        var currentSlide = props.currentSlide, slideCount = props.slideCount, restProps = __rest(props, ["currentSlide", "slideCount"]);
        return (React.createElement("div", __assign({ className: cn(css.arrow, (_b = {},
                _b[css.arrow_prev] = isPrev,
                _b)) }, restProps), Chevron));
    };
    var sliderSettings = {
        slidesToShow: 1,
        infinite: true,
        speed: 600,
        dots: true,
        prevArrow: React.createElement(Arrow, { isPrev: true }),
        nextArrow: React.createElement(Arrow, null),
        appendDots: function (dots) { return React.createElement("ul", null, dots); }
    };
    var icons = (_b = {},
        _b[AircraftInfoParameterType.CruisingSpeed] = Overweight,
        _b[AircraftInfoParameterType.MaxFlightRange] = Planet,
        _b[AircraftInfoParameterType.PassengerCapacity] = Passenger,
        _b);
    var filteredParameters = useMemo(function () {
        var _a, _b, _c;
        if ((_a = aircraftInfo.parameters) === null || _a === void 0 ? void 0 : _a.length) {
            return (_c = (_b = aircraftInfo.parameters) === null || _b === void 0 ? void 0 : _b.filter(function (param) { return availableParameters.includes(param.type); })) !== null && _c !== void 0 ? _c : [];
        }
        return [];
    }, [aircraftInfo.parameters]);
    var renderParameters = useCallback(function () { return (React.createElement("div", { className: css.groups }, filteredParameters.map(function (parameter, index) {
        var _a;
        var isNanValue = isNaN(+parameter.value.replace(/\s+/gm, ''));
        return (React.createElement("div", { className: cn(css.groups_item, css[parameter.type]), key: index },
            React.createElement("div", { className: css.groups_itemTitle },
                React.createElement("div", { className: css.groups_itemIcon }, icons[parameter.type]),
                React.createElement("span", null, parameter.title)),
            React.createElement("div", { className: css.groups_itemDescription },
                React.createElement("span", { className: cn(css.groups_itemValue, (_a = {},
                        _a[css.groups_itemValue_text] = isNanValue,
                        _a)) }, parameter.value))));
    }))); }, [filteredParameters]);
    var hasImagesOrSchema = ((_c = aircraftInfo.images) === null || _c === void 0 ? void 0 : _c.length) > 0 || aircraftInfo.scheme;
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.back, onClick: onClose }, ArrowForward),
            React.createElement("div", { className: css.title }, (_d = aircraftInfo.title) !== null && _d !== void 0 ? _d : name),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, renderParameters())),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Slider, __assign({}, sliderSettings, { className: css.slider, dotsClass: css.slider_dots }), (_e = aircraftInfo.images) === null || _e === void 0 ? void 0 :
                _e.map(function (image, index) { return (React.createElement("div", { className: css.image, key: index },
                    React.createElement("img", { src: image, alt: "" }))); }),
                aircraftInfo.scheme && (React.createElement("div", { className: css.image },
                    React.createElement("img", { src: aircraftInfo.scheme, alt: "" }))),
                !hasImagesOrSchema && React.createElement("div", { className: cn(css.image, css.default_aircraft) }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: css.content },
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, renderParameters()), (_f = aircraftInfo.images) === null || _f === void 0 ? void 0 :
                _f.map(function (image, index) { return (React.createElement("div", { key: index, className: css.image },
                    React.createElement("img", { src: image, alt: "" }))); }),
                aircraftInfo.scheme && (React.createElement("div", { className: css.schema },
                    React.createElement("img", { src: aircraftInfo.scheme, alt: "" }))),
                !hasImagesOrSchema && React.createElement("div", { className: cn(css.image, css.default_aircraft) })),
            aircraftInfo.scheme && (React.createElement("div", { className: css.footer },
                React.createElement("div", { className: css.cabins }, (_g = aircraftInfo.cabins) === null || _g === void 0 ? void 0 : _g.map(function (cabin) {
                    var _a;
                    return (React.createElement("div", { className: css.cabins_item, key: cabin.title },
                        React.createElement("div", { className: cn(css.cabins_itemIcon, (_a = {},
                                _a[css[cabin.title]] = css === null || css === void 0 ? void 0 : css[cabin.title],
                                _a)) }, SeatIcon),
                        React.createElement("div", { className: css.cabins_itemTitle }, t("".concat(cabin.title)))));
                })))))));
};
export default AircraftPopupContent;
