import { filterFareGroups } from '../Results/utils';
export var useFareGroupsMap = function (flight, previouslySelectedFares) {
    var fareGroupMap = new Map();
    var nonEmptyFlightClassesCount = 0;
    flight.pricesForFareGroups.forEach(function (priceForFareGroup) {
        var _a, _b;
        if (((_a = priceForFareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.category) && !fareGroupMap.has(priceForFareGroup.fareFamily.category)) {
            if (priceForFareGroup.prices) {
                nonEmptyFlightClassesCount += 1;
            }
            fareGroupMap.set(priceForFareGroup.fareFamily.category, filterFareGroups(flight.pricesForFareGroups, previouslySelectedFares, (_b = priceForFareGroup.fareFamily.category) !== null && _b !== void 0 ? _b : null, true));
        }
    });
    return { fareGroupMap: fareGroupMap, nonEmptyFlightClassesCount: nonEmptyFlightClassesCount };
};
