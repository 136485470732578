import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Autocomplete, Sort } from '../../../../index';
import { TravellerFieldEnum } from '@websky/graphql';
import { ThemeProvider, useTheme } from '../../../../theme';
import { getAutocompleteLabel as initialGetAutocompleteLabel } from '../../../../utils';
import { filterProfileByBirthDate } from '../utils';
import { useRenderers } from '../../../../renderProps';
var LastName = memo(function (_a) {
    var _b;
    var label = _a.label, field = _a.field, birthDateField = _a.birthDateField, fieldState = _a.fieldState, onTravellerSelect = _a.onTravellerSelect, onChange = _a.onChange, className = _a.className, error = _a.error, loadTravellers = _a.loadTravellers, onBlur = _a.onBlur, disabled = _a.disabled, travellerProfiles = _a.travellerProfiles, refetchTravellers = _a.refetchTravellers, variant = _a.variant, autofocus = _a.autofocus, onSwap = _a.onSwap;
    var theme = useTheme('Passenger').LastName;
    var inputCss = useTheme('Input').Input;
    var getCustomAutocompletePassengerLabel = useRenderers().getCustomAutocompletePassengerLabel;
    var getAutocompleteLabel = getCustomAutocompletePassengerLabel || initialGetAutocompleteLabel;
    var isLastName = field.type === TravellerFieldEnum.LastName;
    var options = useMemo(function () {
        var _a;
        if ((_a = travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.TravellerProfiles) === null || _a === void 0 ? void 0 : _a.length) {
            return travellerProfiles.TravellerProfiles.filter(function (traveller) {
                var filterByBirthDate = filterProfileByBirthDate(traveller, birthDateField === null || birthDateField === void 0 ? void 0 : birthDateField.validations);
                var isFilledLastNameAndFirstName = traveller.values.some(function (value) {
                    return (value.type === TravellerFieldEnum.LastName ||
                        value.type === TravellerFieldEnum.FirstName) &&
                        !value.value;
                });
                return filterByBirthDate && !isFilledLastNameAndFirstName;
            });
        }
        return [];
    }, [travellerProfiles === null || travellerProfiles === void 0 ? void 0 : travellerProfiles.TravellerProfiles, birthDateField]);
    var onChangeHandler = useCallback(function (event) {
        var value = event.target.value;
        if (loadTravellers) {
            // clearTimeout(debounceTimeout);
            // debounceTimeout = window.setTimeout(() => travellerProfiles.refetch({ search: value }), 500);
            refetchTravellers(value);
            if (onChange) {
                onChange(field.name, value);
            }
        }
        fieldState.input.onChange(value);
        onBlur && onBlur();
    }, [onChange, fieldState]);
    var autocompleteTheme = useMemo(function () { return ({
        Autocomplete: {
            Autocomplete: {
                disabled: inputCss.disabled,
                inner_input: inputCss.input
            }
        }
    }); }, []);
    return (React.createElement(ThemeProvider, { value: autocompleteTheme },
        React.createElement("div", { className: cn(theme.field, (_b = {},
                _b[theme.field_swapRight] = onSwap && isLastName,
                _b[theme.field_swapLeft] = onSwap && !isLastName,
                _b)) },
            React.createElement(Autocomplete, { name: field.name, label: label, variant: variant, autofocus: autofocus, inputValue: fieldState.input.value, onSelect: function (travellerId) {
                    onTravellerSelect(travellerProfiles.TravellerProfiles.find(function (traveller) { return traveller.id === travellerId; }));
                    if (onBlur) {
                        onBlur();
                    }
                }, onInputChange: onChangeHandler, className: className, error: error, options: options
                    ? options.map(function (traveller) { return ({
                        label: getAutocompleteLabel(traveller),
                        value: traveller.id
                    }); })
                    : [], disabled: disabled }),
            onSwap && isLastName && (React.createElement("div", { className: theme.swap, onClick: onSwap }, Sort)))));
});
export default LastName;
