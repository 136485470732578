import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../../theme';
import ConditionsModal from '../../../ConditionsModal/ConditionsModal';
var PassengersHeader = function (_a) {
    var className = _a.className;
    var css = useTheme('Checkin').PassengersHeader;
    var t = useTranslation('Checkin').t;
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.title }, t('Online check-in')),
            React.createElement(ConditionsModal, null))));
};
export default PassengersHeader;
