import { __read } from "tslib";
import * as React from 'react';
import { LocationGroupType } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';
export var useNationality = function (_a) {
    var options = _a.options, onChange = _a.onChange, field = _a.field, fieldState = _a.fieldState;
    var t = useTranslation('Passenger').t;
    var _b = __read(React.useState(options), 2), optionsList = _b[0], setOptionsList = _b[1];
    var _c = __read(React.useState(''), 2), value = _c[0], setValue = _c[1];
    var mounted = useRef(false);
    var filterOptions = function (value) {
        return options.filter(function (option) { return option.label.toLowerCase().includes(value.toLowerCase()); });
    };
    // selectors
    var optionsGroup = React.useMemo(function () {
        var countries = [[], optionsList];
        countries[0] = optionsList.filter(function (option) { return option.priority > 0; }).sort(function (a, b) { return b.priority - a.priority; });
        return countries.map(function (option, index) { return ({
            label: option[0] ? (index === 0 ? t('Popular countries') : t('Other countries')) : null,
            options: countries[index],
            type: index === 0 ? LocationGroupType.Popular : LocationGroupType.Country
        }); });
    }, [optionsList]);
    var isValueInOptions = useMemo(function () { return filterOptions(value).length; }, [value, options]);
    var isLastOptionAvailable = useMemo(function () { return optionsList.length === 1; }, [optionsList]);
    // effects
    useEffect(function () {
        if (isValueInOptions) {
            setOptionsList(filterOptions(value));
        }
        else {
            setOptionsList(options);
        }
    }, [value, options, isValueInOptions, setOptionsList]);
    useEffect(function () {
        if (!mounted.current) {
            mounted.current = true;
            setValue(options.find(function (option) { return option.value === fieldState.input.value; }).label);
        }
    }, [mounted]);
    // methods
    var isValueFullyInOptions = function (value) {
        return !!optionsList.find(function (option) { return option.label.toLowerCase() === value.toLowerCase(); });
    };
    var handleFocus = useCallback(function (e) {
        setValue('');
    }, []);
    var handleParentChange = function (value) {
        onChange(field.name, value);
    };
    var handleChange = useCallback(function (event) {
        var value = event.target.value;
        setValue(value);
    }, [options, setOptionsList]);
    var handleSelect = useCallback(function (value) {
        var relatedOption = options.find(function (option) { return option.value === value; });
        handleParentChange(relatedOption.value);
        setValue(relatedOption.label);
    }, [onChange, setValue]);
    var handleBlur = useCallback(function () {
        if (isLastOptionAvailable) {
            setValue(optionsList[0].label);
            handleParentChange(optionsList[0].value);
        }
        if (isValueFullyInOptions(value)) {
            return;
        }
        else {
            setValue('');
            handleParentChange(null);
        }
    }, [optionsList, isLastOptionAvailable, value, isValueFullyInOptions]);
    return {
        options: optionsGroup,
        handleChange: handleChange,
        handleSelect: handleSelect,
        handleBlur: handleBlur,
        handleFocus: handleFocus,
        value: value
    };
};
