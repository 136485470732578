import { __assign, __read, __spreadArray } from "tslib";
import { format, parse, parseISO } from 'date-fns';
import { TravellerDocumentFields, TravellerFields } from './types';
import { InputType } from '../Passenger/types';
import { initialTravellerDocument } from './consts';
import { TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../utils';
export var convertDocuments = function (documentsList) {
    return documentsList.map(function (document) {
        var firstName = getUserValue(document, TravellerFieldEnum.FirstName);
        var lastName = getUserValue(document, TravellerFieldEnum.LastName);
        var middleName = getUserValue(document, TravellerFieldEnum.MiddleName);
        var docNumber = getUserValue(document, TravellerFieldEnum.DocNumber);
        var docType = getUserValue(document, TravellerFieldEnum.DocType);
        var options = document.values
            .find(function (value) { return value.name === 'docType'; })
            .validationRules[0].options.map(function (option) { return option.value; });
        var docExpiryDate = null;
        var docExpiryDateField = getUserValue(document, TravellerFieldEnum.DocExpiryDate);
        if (docExpiryDateField) {
            try {
                docExpiryDate = format(parseISO(docExpiryDateField), 'dd.MM.yyyy');
            }
            catch (e) {
                try {
                    docExpiryDate = format(parse(docExpiryDateField, 'dd.MM.yyyy', new Date()), 'dd.mm.yyyy');
                }
                catch (e) {
                    docExpiryDate = format(parseISO(docExpiryDateField), 'dd-MM-yyyy');
                }
            }
        }
        var citizenship = getUserValue(document, TravellerFieldEnum.Citizenship);
        return {
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            docNumber: docNumber,
            docType: docType,
            docExpiryDate: docExpiryDate,
            citizenship: citizenship,
            options: options
        };
    });
};
export var getInputType = function (fieldName) {
    if (fieldName === TravellerFields.BirthDate) {
        return InputType.Date;
    }
    if (fieldName === TravellerFields.Gender) {
        return InputType.Switch;
    }
    if (fieldName === TravellerDocumentFields.Nationality) {
        return InputType.Switch;
    }
    if (fieldName === TravellerFields.Phone) {
        return InputType.Phone;
    }
    return InputType.Text;
};
export var documentsMutator = function (params, state, utils) {
    var documents = state.formState.values[TravellerFields.Documents];
    if (params[0].type === 'add') {
        utils.changeValue(state, TravellerFields.Documents, function () { return __spreadArray(__spreadArray([], __read(state.formState.values[TravellerFields.Documents]), false), [
            initialTravellerDocument
        ], false); });
    }
    else if (params[0].type === 'remove') {
        var updatedDocuments_1 = documents.filter(function (_, index) { return index !== params[0].index; });
        utils.changeValue(state, TravellerFields.Documents, function () { return updatedDocuments_1; });
    }
};
export var documentMutator = function (params, state, utils) {
    var firstDocument = state.formState.values[TravellerFields.Documents][0];
    var fieldName = params[0].fieldName;
    utils.changeValue(state, fieldName, function () { return firstDocument[fieldName]; });
};
export var docTypeMutator = function (params, state, utils) {
    var documents = state.formState.values[TravellerFields.Documents];
    utils.changeValue(state, TravellerFields.Documents, function () {
        return documents.map(function (document, index) {
            if (index === params[0].index) {
                return __assign(__assign({}, document), { docExpiryDate: '' });
            }
            return document;
        });
    });
};
export var citizenshipMutator = function (params, state, utils) {
    var documents = state.formState.values[TravellerFields.Documents];
    utils.changeValue(state, TravellerFields.Documents, function () {
        return documents.map(function (document, index) {
            if (index === params[0].index) {
                return __assign(__assign({}, document), { docType: '' });
            }
            return document;
        });
    });
    utils.changeValue(state, TravellerFields.Nationality, function () { return documents[0].citizenship; });
};
export var fixEmptyFieldMutator = function (params, state, utils) {
    var fieldName = params[0].fieldName;
    if (!(fieldName in state.formState.values)) {
        utils.changeValue(state, fieldName, function () { return (state.formState.values[fieldName] = ''); });
    }
};
export var fixEmptyFieldDocumentMutator = function (params, state, utils) {
    var doc = state.formState.values[TravellerFields.Documents][params[0].index];
    var fieldName = params[0].fieldName;
    if (!(fieldName in doc)) {
        utils.changeValue(state, fieldName, function () { return (doc[fieldName] = ''); });
    }
};
