import { __assign } from "tslib";
import * as React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Input from '../../../../BaseComponents/Input';
import { MOBILE_MIN_WIDTH, initI18n } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { DocTypes } from '@websky/graphql';
import { useTheme } from '../../../../theme';
import { InputType } from '../../../../Passenger/types';
import { validate } from '../../../../Passenger/utils';
import Dropdown from '../../../../BaseComponents/Dropdown';
import { useMediaQuery } from 'react-responsive';
import { TravellerDocumentFields, TravellerFields } from '../../../types';
import { Grid } from '@mui/material';
import { getAvailabilityDocumentExpireDate, getDocumentTypes, getCitizenships } from './utils';
import { RemoveIcon } from '../../../Icons';
import { DateMaskedInput } from '../../../../DateMaskedInput/DateMaskedInput';
import { ButtonBase } from '@material-ui/core';
initI18n('DocumentCardForm');
var DocumentFieldsItem = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id, traveller = _a.traveller, primaryCountryCodes = _a.primaryCountryCodes, validators = _a.validators, onRemove = _a.onRemove;
    var css = useTheme('TravellerForm').DocumentFieldsItem;
    var tDocumentCardForm = useTranslation('DocumentCardForm').t;
    var tPassenger = useTranslation('Passenger').t;
    var tTraveller = useTranslation('Traveller').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var formState = useFormState();
    var form = useForm();
    var variant = isMobile ? 'underline' : 'labelIn';
    var selectedDocType = (_c = (_b = formState.values[TravellerFields.Documents]) === null || _b === void 0 ? void 0 : _b[id]) === null || _c === void 0 ? void 0 : _c[TravellerDocumentFields.DocType];
    var selectedCitizenship = (_e = (_d = formState.values[TravellerFields.Documents]) === null || _d === void 0 ? void 0 : _d[id]) === null || _e === void 0 ? void 0 : _e[TravellerDocumentFields.Citizenship];
    var citizenships = getCitizenships(traveller, primaryCountryCodes);
    var documentTypes = getDocumentTypes(selectedCitizenship, validators);
    var isDocExpireDateHidden = getAvailabilityDocumentExpireDate(selectedDocType, validators);
    var isBirthRegDocument = selectedDocType === DocTypes.BirthRegDocument;
    var onRemoveHandler = function () {
        onRemove === null || onRemove === void 0 ? void 0 : onRemove(id);
    };
    var docTypeValidate = function (value) {
        if (!value) {
            return 'Required';
        }
        return undefined;
    };
    var docNumberValidate = function (value, allValues) {
        var error = undefined;
        var validation = validators === null || validators === void 0 ? void 0 : validators.values.find(function (validation) { return validation.name === TravellerDocumentFields.DocNumber; });
        error = validate(value, __assign(__assign({}, allValues[TravellerFields.Documents][id]), { citizenships: formState.values[TravellerFields.Documents][id][TravellerDocumentFields.Citizenship] }), InputType.Text, validation === null || validation === void 0 ? void 0 : validation.validationRules);
        return error;
    };
    var docExpireDateValidate = function (value, allValues) {
        var error = undefined;
        var validation = validators === null || validators === void 0 ? void 0 : validators.values.find(function (validation) { return validation.name === TravellerDocumentFields.DocExpiryDate; });
        error = validate(value, __assign(__assign({}, allValues[TravellerFields.Documents][id]), { nationality: formState.values[TravellerFields.Documents][id][TravellerDocumentFields.Nationality] }), InputType.Date, validation === null || validation === void 0 ? void 0 : validation.validationRules);
        return error;
    };
    return (React.createElement("div", { className: css.documentFieldsItem },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.LastName), render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        var onChange = function (event) {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.LastName,
                                index: id
                            });
                            form.mutators.documentMutator({
                                fieldName: TravellerDocumentFields.LastName,
                                index: id
                            });
                        };
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, label: "".concat(tTraveller('lastName'), "*"), error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: onChange })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.FirstName), render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        var onChange = function (event) {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.FirstName,
                                index: id
                            });
                            form.mutators.documentMutator({
                                fieldName: TravellerDocumentFields.FirstName,
                                index: id
                            });
                        };
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, label: "".concat(tTraveller('firstName'), "*"), error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: onChange })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.MiddleName), render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        var onChange = function (event) {
                            input.onChange(event);
                            form.mutators.fixEmptyFieldDocumentMutator({
                                fieldName: TravellerDocumentFields.MiddleName,
                                index: id
                            });
                            form.mutators.documentMutator({
                                fieldName: TravellerDocumentFields.MiddleName,
                                index: id
                            });
                        };
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, label: "".concat(tTraveller('middleName')), error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), onChange: onChange })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.Citizenship), render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        var options = citizenships
                            .sort(function (a, b) { return b.priority - a.priority; })
                            .map(function (option) { return ({
                            label: tPassenger(option.label),
                            value: option.value
                        }); });
                        var selectedOpt = citizenships.find(function (_a) {
                            var value = _a.value;
                            return value === input.value;
                        });
                        var onChange = function (_event, value) {
                            input.onChange(value === null ? '' : value.value);
                            form.mutators.citizenshipMutator({ index: id });
                        };
                        return (React.createElement(Dropdown, { classes: { inputRoot: css.dropdown }, options: options, value: selectedOpt, onChange: onChange, disableClearable: true, isOptionEqualToValue: function (opt, value) { return opt.value === value.value; }, InputProps: __assign(__assign({}, input), { variant: variant, label: tTraveller('nationality'), error: meta.error && meta.touched, helperText: meta.touched && tTraveller(meta.error) }) }));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.DocType), validate: docTypeValidate, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        var options = documentTypes.map(function (document) { return ({
                            label: tPassenger(document),
                            value: document
                        }); });
                        var selectedOpt = options.find(function (_a) {
                            var value = _a.value;
                            return value === input.value;
                        });
                        var onChange = function (_event, value) {
                            input.onChange(value === null ? '' : value.value);
                            form.mutators.docTypeMutator({ index: id });
                        };
                        return (React.createElement(Dropdown, { classes: { inputRoot: css.dropdown }, options: options, value: selectedOpt, onChange: onChange, disableClearable: true, InputProps: __assign(__assign({}, input), { variant: variant, label: tDocumentCardForm('Document type'), error: meta.error && meta.touched, helperText: meta.touched && tPassenger(meta.error) }) }));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.DocNumber), validate: docNumberValidate, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, label: "".concat(tDocumentCardForm('Document number'), "*"), error: meta.error && (meta.modified || meta.submitFailed), helperText: (meta.modified || meta.submitFailed) && tPassenger(meta.error), onChange: function (event) {
                                if (isBirthRegDocument) {
                                    event.target.value = event.target.value.toUpperCase();
                                }
                                input.onChange(event);
                                form.mutators.fixEmptyFieldDocumentMutator({
                                    fieldName: TravellerDocumentFields.DocNumber,
                                    index: id
                                });
                            } })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4, hidden: isDocExpireDateHidden },
                React.createElement(Field, { name: "documents[".concat(id, "].").concat(TravellerDocumentFields.DocExpiryDate), validate: docExpireDateValidate, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, label: "".concat(tDocumentCardForm('Expiry date'), "*"), helperText: meta.touched && tPassenger(meta.error), error: meta.error && meta.touched, onChange: function (event) {
                                input.onChange(event);
                            }, InputProps: {
                                inputComponent: DateMaskedInput
                            } })));
                    } }))),
        onRemove && (React.createElement("div", { className: css.documentFieldsItem__controls },
            React.createElement("div", { className: css.documentFieldsItem__remove, onClick: onRemoveHandler }, isMobile ? (tDocumentCardForm('Remove')) : (React.createElement(ButtonBase, { classes: {
                    root: css.buttonRemove
                } }, RemoveIcon)))))));
};
export default DocumentFieldsItem;
