import * as React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { createStore } from '../store';
import SearchForm from './SearchForm/SearchForm';
import { useGraphQLClient } from '../../graphql-client';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import ExternalLocaleResources from '../../ExternalLocaleResources/ExternalLocaleResources';
import Analytics from '../../Engine/components/Analytics';
var store = null;
var Component = function (props) {
    var client = useGraphQLClient();
    var _a = useConfig().SearchForm, proMode = _a.proMode, passengersConfig = _a.passengersConfig, offerHotels = _a.offerHotels;
    store = createStore(props.defaultParameters, passengersConfig, proMode, offerHotels);
    return (React.createElement(Provider, { store: store },
        React.createElement(ApolloProvider, { client: client },
            React.createElement(ExternalLocaleResources, null),
            React.createElement(Analytics, null),
            React.createElement(OverrideComponent, { component: { SearchFormWithStore: SearchForm }, componentProps: props }))));
};
export { store };
export default Component;
