import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { usePassengers } from '../../hooks';
import { OrderCardContext } from '../OrderCard';
import { Passengers } from '../../../Icons';
import { useToggleable } from '../../../hooks';
import CommonItem from '../CommonItem/CommonItem';
import Modal from '../../../Modal';
import PassengersDetails from '../../../PassengersDetails';
var OrderPassengers = function (_a) {
    var _b;
    var _c;
    var className = _a.className, goToChangePassenger = _a.goToChangePassenger;
    var css = useTheme('OrderCard').OrderPassengers;
    var t = useTranslation('OrderCard').t;
    var _d = useContext(OrderCardContext), order = _d.order, type = _d.type;
    var passengers = usePassengers(order);
    var _e = useToggleable(false), isOpen = _e.isOpen, open = _e.open, close = _e.close;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonItem, { icon: (type === 'review' || isMobile) && Passengers, type: type, text: React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    t('Passengers'),
                    ": "),
                passengers), className: cn(css.root, className, (_b = {},
                _b[css.account] = type === 'account',
                _b)), buttons: type === 'review' && (React.createElement("span", { className: css.control, onClick: open }, t('Details'))) }),
        type === 'review' && (React.createElement(Modal, { classes: {
                paper: css.paper
            }, open: isOpen, bottomDrawer: isMobile, onClose: close, closeButtonRenderer: function () { return null; }, maxWidth: 'sm' },
            React.createElement(PassengersDetails, { orderId: order.id, orderStatus: order.status, travellers: order.travellers, changeTravellersAvailability: (_c = order.exareInfo) === null || _c === void 0 ? void 0 : _c.changeTravellerDataAvailability, goToChangePassenger: goToChangePassenger, onClose: close })))));
};
export default OrderPassengers;
