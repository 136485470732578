import { __assign, __rest } from "tslib";
import React, { useCallback } from 'react';
import cn from 'classnames';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '../../../theme';
import Input from '../../Input';
var Dropdown = function (_a) {
    var classes = _a.classes, InputProps = _a.InputProps, renderInput = _a.renderInput, restProps = __rest(_a, ["classes", "InputProps", "renderInput"]);
    var theme = useTheme('BaseComponents').Dropdown;
    var renderInputHandler = useCallback(function (params) {
        var _a;
        var inputClasses = InputProps.classes, restInputProps = __rest(InputProps, ["classes"]);
        return ((_a = renderInput === null || renderInput === void 0 ? void 0 : renderInput(params)) !== null && _a !== void 0 ? _a : (React.createElement(Input, __assign({ classes: __assign(__assign({}, inputClasses), { labelIn: cn(theme.labelIn, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.labelIn), withoutLabel: cn(theme.withoutLabel, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.withoutLabel), outlined: cn(theme.outlined, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.outlined) }) }, params, restInputProps))));
    }, [renderInput, InputProps]);
    return (React.createElement(Autocomplete, __assign({ classes: __assign(__assign({}, classes), { root: cn(theme.root, classes === null || classes === void 0 ? void 0 : classes.root), input: cn(theme.input, classes === null || classes === void 0 ? void 0 : classes.input), paper: cn(theme.paper, classes === null || classes === void 0 ? void 0 : classes.paper), listbox: cn(theme.listbox, classes === null || classes === void 0 ? void 0 : classes.listbox), option: cn(theme.option, classes === null || classes === void 0 ? void 0 : classes.option), endAdornment: cn(theme.endAdornment, classes === null || classes === void 0 ? void 0 : classes.endAdornment), popupIndicator: cn(theme.popupIndicator, classes === null || classes === void 0 ? void 0 : classes.popupIndicator) }), renderInput: renderInputHandler }, restProps)));
};
export default Dropdown;
