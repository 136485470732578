import { __assign, __decorate, __extends } from "tslib";
import * as React from 'react';
import { withTheme } from '../theme';
import { Outbound } from './Outbound/Outbound';
import { addDays, toDate } from 'date-fns';
import { Matrix } from './Matrix/Matrix';
import { createDatesArray } from '../utils';
export var HeaderType;
(function (HeaderType) {
    HeaderType[HeaderType["Outbound"] = 0] = "Outbound";
    HeaderType[HeaderType["Return"] = 1] = "Return";
})(HeaderType || (HeaderType = {}));
var memoize = require('memoize-one');
var PriceMatrix = /** @class */ (function (_super) {
    __extends(PriceMatrix, _super);
    function PriceMatrix(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.minOutboundDate = Infinity;
        _this.maxOutboundDate = 0;
        _this.minReturnDate = Infinity;
        _this.maxReturnDate = 0;
        _this.minPrice = {
            amount: Infinity,
            currency: null
        };
        _this.maxPrice = {
            amount: 0,
            currency: null
        };
        _this.memoizedFill = typeof memoize === 'function' ? memoize(_this.fill) : memoize.default(_this.fill);
        _this.state = (_a = {},
            _a[HeaderType.Outbound] = null,
            _a[HeaderType.Return] = null,
            _a);
        _this.getDatesRange = _this.getDatesRange.bind(_this);
        _this.arrowHandler = _this.arrowHandler.bind(_this);
        return _this;
    }
    PriceMatrix.prototype.getDatesRange = function (type) {
        var minDateFromState = this.state[type], minDateFromClass = type === HeaderType.Outbound ? this.minOutboundDate : this.minReturnDate, minDate = minDateFromState ? minDateFromState : toDate(minDateFromClass);
        return {
            minDate: minDate,
            maxDate: addDays(minDate, this.props.size - 1)
        };
    };
    PriceMatrix.prototype.getDatesMatrix = function () {
        var outboundDatesRange = this.getDatesRange(HeaderType.Outbound), returnDatesRange = this.getDatesRange(HeaderType.Return), outboundDatesArray = createDatesArray(outboundDatesRange.minDate, outboundDatesRange.maxDate), returnDatesArray = createDatesArray(returnDatesRange.minDate, returnDatesRange.maxDate);
        return outboundDatesArray.map(function (date) {
            return {
                outboundDate: date,
                returnDates: returnDatesArray
            };
        });
    };
    PriceMatrix.prototype.checkDatesRange = function (type, startDate) {
        var minDate = type === HeaderType.Outbound ? this.minOutboundDate : this.minReturnDate, maxDate = type === HeaderType.Outbound ? this.maxOutboundDate : this.maxReturnDate, startDateTimestamp = startDate.getTime(), endDateTimestamp = addDays(startDate, this.props.size - 1).getTime();
        return startDateTimestamp >= minDate && endDateTimestamp <= maxDate;
    };
    PriceMatrix.prototype.arrowHandler = function (type, direction) {
        var _this = this;
        var datesRange = this.getDatesRange(type), newStartDate = addDays(datesRange.minDate, direction === 'next' ? 1 : -1);
        this.checkDatesRange(type, newStartDate) &&
            this.setState(function () {
                var _a;
                if (_this.props.onRangeChanges) {
                    _this.props.onRangeChanges(type === HeaderType.Outbound ? 'vertical' : 'horizontal', newStartDate, addDays(newStartDate, _this.props.size - 1));
                }
                return _a = {},
                    _a[type] = newStartDate,
                    _a;
            });
    };
    PriceMatrix.prototype.fill = function (prices) {
        var _this = this;
        var pricesMap = {};
        prices.map(function (price) {
            var returnTimestamp = price.dateBack.getTime(), outboundTimestamp = price.dateTo.getTime();
            if (returnTimestamp > _this.maxReturnDate) {
                _this.maxReturnDate = returnTimestamp;
            }
            if (returnTimestamp < _this.minReturnDate) {
                _this.minReturnDate = returnTimestamp;
            }
            if (outboundTimestamp > _this.maxOutboundDate) {
                _this.maxOutboundDate = outboundTimestamp;
            }
            if (outboundTimestamp < _this.minOutboundDate) {
                _this.minOutboundDate = outboundTimestamp;
            }
            if (price.price && price.price.amount < _this.minPrice.amount) {
                _this.minPrice.amount = price.price.amount;
            }
            if (price.price && price.price.amount > _this.maxPrice.amount) {
                _this.maxPrice.amount = price.price.amount;
            }
            pricesMap["".concat(price.dateTo.getTime(), "-").concat(price.dateBack.getTime())] = {
                money: price.price,
                isLoading: price.isLoading
            };
        });
        return pricesMap;
    };
    PriceMatrix.prototype.render = function () {
        var _this = this;
        var pricesMap = this.memoizedFill(this.props.prices), styles = this.props.theme.PriceMatrixStyles;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Matrix, { dates: this.getDatesMatrix(), prices: pricesMap, minPrice: this.minPrice, maxPrice: this.maxPrice, onDateSelect: this.props.onDateSelect, verticalHeader: function (ref) { return (React.createElement(Outbound, __assign({}, _this.getDatesRange(HeaderType.Outbound), { type: HeaderType.Outbound, onArrowClick: _this.arrowHandler, innerRef: ref }))); }, horizontalHeader: function (ref) { return (React.createElement(Outbound, __assign({}, _this.getDatesRange(HeaderType.Return), { type: HeaderType.Return, onArrowClick: _this.arrowHandler, innerRef: ref }))); } })));
    };
    PriceMatrix.displayName = 'PriceMatrix';
    PriceMatrix = __decorate([
        withTheme
    ], PriceMatrix);
    return PriceMatrix;
}(React.Component));
export { PriceMatrix };
export default PriceMatrix;
