import * as React from 'react';
import SpecialDocumentField from './SpecialDocumentField/SpecialDocumentField';
var SpecialDocument = function (props) {
    var regex = props.passengers[0].regex;
    var setValue = function (id, value) {
        props.dispatcher({
            type: 'set_document',
            payload: {
                id: id,
                value: value,
                isValid: !regex || new RegExp(regex.pattern).test(value)
            }
        });
    };
    return (React.createElement("div", null, props.passengers
        .filter(function (doc) { return doc.isRequired; })
        .map(function (passenger) { return (React.createElement(SpecialDocumentField, { passenger: passenger, setValue: setValue, key: passenger.id, documentError: props.documentError })); })));
};
export default SpecialDocument;
