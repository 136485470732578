import { useCallback } from 'react';
export var usePassengerNames = function () {
    var getPassengerName = useCallback(function (passenger) {
        var _a;
        var childrenName = '';
        if ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) {
            childrenName += ", ".concat(passenger.children.map(function (children) { return children.name; }).join(', '));
        }
        return passenger.name + childrenName;
    }, []);
    return { getPassengerName: getPassengerName };
};
