import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckinSaveOrderServicesMutation, useGetCheckinOrderQuery, useUpdateOrderMutation } from '@websky/graphql';
import { CartService } from '../../Cart/types';
import { getCheckinOrder, getHasRequestedOrderServices } from './store/order/selectors';
import { removeService, saveServices } from './store/selectedServices/actions';
import { fillCheckinOrder, setCheckinOrder } from './store/order/actions';
import { CheckinStep, useServiceRequest } from './utils';
import { useConditionalPolling } from '../../hooks';
export var useCheckinOrderServices = function (onRefetchOrder, onRequestError, setStep) {
    var dispatch = useDispatch();
    var order = useSelector(getCheckinOrder);
    var _a = __read(useCheckinSaveOrderServicesMutation(), 1), saveOrderServices = _a[0];
    var runServiceRequest = useServiceRequest().runServiceRequest;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var onRequest = useCallback(function (passengers) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1, apolloError;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, runServiceRequest(passengers)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, onRefetchOrder()];
                case 2:
                    e_1 = _b.sent();
                    apolloError = e_1;
                    if ((_a = apolloError === null || apolloError === void 0 ? void 0 : apolloError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                        onRequestError(apolloError);
                    }
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var onSaveServices = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2, apolloError;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveOrderServices({ variables: { params: params } })];
                case 1:
                    data = (_b.sent()).data;
                    if (data.CheckinSaveOrderServices) {
                        dispatch(setCheckinOrder(data.CheckinSaveOrderServices));
                    }
                    return [2 /*return*/, data.CheckinSaveOrderServices];
                case 2:
                    e_2 = _b.sent();
                    apolloError = e_2;
                    if ((_a = apolloError === null || apolloError === void 0 ? void 0 : apolloError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                        onRequestError(apolloError);
                    }
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [dispatch]);
    var onSaveInsurance = useCallback(function (insuranceCode) { return __awaiter(void 0, void 0, void 0, function () {
        var insurances, selectedInsurances, selectedTravellers, selectedPrograms;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    insurances = order.additionalServices.insurances;
                    selectedInsurances = insurances === null || insurances === void 0 ? void 0 : insurances.selectedInsurances.filter(function (selected) { return selected.insuranceProgram.code !== insuranceCode; }).map(function (selected) { return selected.insuranceProgram; });
                    selectedTravellers = (insurances === null || insurances === void 0 ? void 0 : insurances.travellerExtraValues) || [];
                    selectedPrograms = selectedInsurances.map(function (selected) { return selected.code; });
                    return [4 /*yield*/, onSaveServices({
                            id: order.id,
                            insurances: [
                                {
                                    selectedPrograms: selectedPrograms,
                                    selectedProgramsByTravellers: selectedTravellers.map(function (traveller) { return ({
                                        travellerId: traveller.traveller.id,
                                        programCodes: selectedPrograms,
                                        travellerDocumentNumber: ''
                                    }); })
                                }
                            ]
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [order]);
    var onSaveAeroexpress = useCallback(function (tripId) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedTrips;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    selectedTrips = ((_b = (_a = order.additionalServices.transfer) === null || _a === void 0 ? void 0 : _a.aeroexpress) === null || _b === void 0 ? void 0 : _b.selectedTrips) || [];
                    return [4 /*yield*/, onSaveServices({
                            id: order.id,
                            aeroexpress: {
                                selectedTrips: selectedTrips.filter(function (trip) { return trip.id !== tripId; }).map(function (trip) { return trip.id; })
                            }
                        })];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [order]);
    var onDeleteItem = useCallback(function (type, service) { return __awaiter(void 0, void 0, void 0, function () {
        var services, cartService_1, gdsService_1, segmentIds, cartService_2, saveTravellers, cartServiceInsurance, cartServiceAeroexpress;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsLoading(true);
                    services = ((_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) || [];
                    if (![CartService.Baggage, CartService.Meal, CartService.BusinessLounge].includes(type)) return [3 /*break*/, 1];
                    cartService_1 = service;
                    gdsService_1 = services.find(function (gdsService) { return gdsService.id === cartService_1.serviceId; });
                    segmentIds = cartService_1.allSegments
                        ? order.segments.map(function (segment) { return segment.id; })
                        : [cartService_1.segmentId];
                    segmentIds.forEach(function (segmentId) {
                        dispatch(removeService({
                            service: gdsService_1,
                            passengerId: cartService_1.passengerId,
                            segmentId: segmentId
                        }));
                    });
                    dispatch(saveServices());
                    return [3 /*break*/, 7];
                case 1:
                    if (!(type === CartService.Seats)) return [3 /*break*/, 3];
                    cartService_2 = service;
                    saveTravellers = order.travellers.map(function (traveller) { return ({
                        passengerId: traveller.id,
                        seats: cartService_2.passengerId === traveller.id ? null : traveller.services.seats,
                        setServices: null
                    }); });
                    setStep(CheckinStep.Seats);
                    return [4 /*yield*/, onRequest(saveTravellers)];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 7];
                case 3:
                    if (!(type === CartService.Insurances)) return [3 /*break*/, 5];
                    cartServiceInsurance = service;
                    return [4 /*yield*/, onSaveInsurance(cartServiceInsurance.code)];
                case 4:
                    _c.sent();
                    return [3 /*break*/, 7];
                case 5:
                    if (!(type === CartService.Aeroexpress)) return [3 /*break*/, 7];
                    cartServiceAeroexpress = service;
                    return [4 /*yield*/, onSaveAeroexpress(cartServiceAeroexpress.tripId)];
                case 6:
                    _c.sent();
                    _c.label = 7;
                case 7:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [order.additionalServices, dispatch]);
    return { isLoading: isLoading, onDeleteItem: onDeleteItem };
};
export var useUpdateCheckinTravellerServices = function () {
    var dispatch = useDispatch();
    var order = useSelector(getCheckinOrder);
    var hasRequestedOrderServices = useSelector(getHasRequestedOrderServices);
    var _a = __read(useUpdateOrderMutation(), 1), updateOrder = _a[0];
    var fetchCheckinOrder = useGetCheckinOrderQuery({ skip: true }).refetch;
    var onUpdateOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var updateOrderResponse, checkinOrderResponse;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, updateOrder({ variables: { id: order.id } })];
                case 1:
                    updateOrderResponse = _c.sent();
                    if (!((_a = updateOrderResponse === null || updateOrderResponse === void 0 ? void 0 : updateOrderResponse.data) === null || _a === void 0 ? void 0 : _a.UpdateOrder)) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchCheckinOrder({ id: order.id })];
                case 2:
                    checkinOrderResponse = _c.sent();
                    if ((_b = checkinOrderResponse === null || checkinOrderResponse === void 0 ? void 0 : checkinOrderResponse.data) === null || _b === void 0 ? void 0 : _b.CheckinOrder) {
                        dispatch(fillCheckinOrder(checkinOrderResponse.data.CheckinOrder));
                    }
                    _c.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [order === null || order === void 0 ? void 0 : order.id, updateOrder, fetchCheckinOrder]);
    useConditionalPolling(onUpdateOrder, hasRequestedOrderServices, {
        interval: 5000,
        progressiveIntervalPerQuery: true
    });
};
