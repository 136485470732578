import { __assign, __read, __spreadArray } from "tslib";
import { createSelector } from 'reselect';
import { AviaPassengerType, BaggageType, CheckinOrderStatus, GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum } from '@websky/graphql';
import { convertBaggageObject, convertMealObject } from '../../../../Checkout/types';
import { getTravellerName } from '../../utils';
import { get, MEAL_RECOMMENDATION_SHOWN } from '../../../../cache';
import { checkAllSeatsAreSelected } from '../../../../SeatMap/utils';
import { parseIncludedBaggage } from '../../../../BaggagePage/utils';
import { useConfig } from '../../../../context';
import { getIncludedBaggageFromOrderTravellers } from '../../../../Checkout/utils';
import { getHasRequestedServices, getTotalPrice as getTotalPriceUtil, getUserValue } from '../../../../utils';
var getState = function (state) { return state; };
export var getCheckinOrder = createSelector(getState, function (_a) {
    var order = _a.order;
    return order;
});
export var getTravellers = createSelector(getCheckinOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.travellers) !== null && _a !== void 0 ? _a : []; });
export var getIsContactsInfoEmpty = createSelector(getCheckinOrder, function (_a) {
    var customer = _a.customer;
    return customer.contactInfoIsEmpty;
});
export var getDeprecatedPassengersState = createSelector(getTravellers, function (travellers) {
    return travellers.map(function (traveller) {
        var _a, _b, _c;
        return ({
            id: traveller.id,
            type: traveller.type,
            values: traveller.values,
            services: (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) !== null && _c !== void 0 ? _c : [],
            seats: traveller.services.seats &&
                traveller.services.seats.map(function (seat) {
                    var _a;
                    return ({
                        id: (_a = seat.seat.seatService) === null || _a === void 0 ? void 0 : _a.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        segmentId: seat.segment.id,
                        isConfirmed: seat.isConfirmed,
                        row: seat.row.toString(),
                        letter: seat.letter,
                        price: seat.seat.price,
                        number: seat.seat.number,
                        rfisc: seat.seat.rfisc
                    });
                })
        });
    });
});
export var getTravellersWithVisa = createSelector(getCheckinOrder, function (_a) {
    var travellers = _a.travellers;
    return travellers.filter(function (traveller) { return traveller.visaRequired && traveller.visaValues.length; });
});
export var getPassengerIdsWithLinkedPassengers = createSelector(getCheckinOrder, function (order) {
    var passengerIdsWithLinkedPassengersMap = new Map();
    order.travellers.forEach(function (traveller) {
        var _a;
        if (traveller.linkedTraveller) {
            var linkedTravellerId = traveller.linkedTraveller.id;
            passengerIdsWithLinkedPassengersMap.set(linkedTravellerId, __spreadArray(__spreadArray([], __read(((_a = passengerIdsWithLinkedPassengersMap.get(linkedTravellerId)) !== null && _a !== void 0 ? _a : [])), false), [
                traveller
            ], false));
        }
    });
    passengerIdsWithLinkedPassengersMap.forEach(function (travellers, travellerId) {
        travellers.forEach(function (linkedTraveller) {
            var mainTraveller = order.travellers.find(function (traveller) { return traveller.id === travellerId; });
            if (mainTraveller) {
                var linkedTravellers = __spreadArray(__spreadArray([], __read(travellers.filter(function (traveller) { return traveller.id !== linkedTraveller.id; })), false), [
                    mainTraveller
                ], false);
                passengerIdsWithLinkedPassengersMap.set(linkedTraveller.id, linkedTravellers);
            }
        });
    });
    return passengerIdsWithLinkedPassengersMap;
});
export var getPassengersNames = createSelector(getTravellers, function (travellers) {
    return travellers.map(function (traveller) { return ({
        id: traveller.id,
        name: getTravellerName(traveller).toString()
    }); });
});
export var getInsurance = createSelector(getCheckinOrder, function (order) { return order.additionalServices.insurances; });
export var getMinInsurancePrice = createSelector(getInsurance, function (insurances) {
    var _a, _b;
    return ({
        amount: Math.min.apply(Math, __spreadArray([], __read(insurances.insurancePrograms.map(function (program) { return program.totalPrice.amount; })), false)),
        currency: (_b = (_a = insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.totalPrice) === null || _b === void 0 ? void 0 : _b.currency
    });
});
export var getTotalInsurancePrice = createSelector(getInsurance, function (insurances) {
    var _a;
    if ((_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
        return getTotalPriceUtil(insurances.selectedInsurances, function (insurance) { return insurance.passengerPrice; });
    }
    return null;
});
export var getConfirmedInsurances = createSelector(getInsurance, function (insurance) {
    return insurance.selectedInsurances.filter(function (insurance) { return !!insurance.policyUrl; });
});
export var getTotalPrice = createSelector(getCheckinOrder, function (_a) {
    var priceToPay = _a.priceToPay;
    return (__assign({}, priceToPay.total));
});
export var getCheckinOrderId = createSelector(getCheckinOrder, function (_a) {
    var id = _a.id;
    return id;
});
export var getCheckinSegments = createSelector([getCheckinOrder], function (order) { return order.segments; });
export var getCheckinNotStarted = createSelector(getState, function (_a) {
    var order = _a.order;
    return order.status === CheckinOrderStatus.New;
});
export var getCheckinIsBlockedForPayment = createSelector(getCheckinOrder, function (_a) {
    var isBlockedForPayment = _a.isBlockedForPayment;
    return isBlockedForPayment;
});
export var getCheckinHasStarted = createSelector(getState, function (_a) {
    var order = _a.order;
    return (order === null || order === void 0 ? void 0 : order.status) === CheckinOrderStatus.Started;
});
export var getPriceToPay = createSelector(getCheckinOrder, function (_a) {
    var priceToPay = _a.priceToPay;
    return priceToPay;
});
export var getTotalPriceToPay = createSelector(getPriceToPay, function (_a) {
    var total = _a.total;
    return total !== null && total !== void 0 ? total : { amount: 0, currency: total.currency };
});
export var getCurrentCurrencyFromCheckinOrder = createSelector(getTotalPrice, function (totalPrice) { return totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.currency; });
export var getInsuranceMinOrTotalPrice = createSelector(getInsurance, getMinInsurancePrice, getCurrentCurrencyFromCheckinOrder, function (insurances, insuranceMinPrice, currentCurrency) {
    var _a, _b;
    var currency = (_b = (_a = insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.totalPrice) === null || _b === void 0 ? void 0 : _b.currency;
    var price;
    if (insurances.selectedInsurances.length) {
        price = {
            currency: currency !== null && currency !== void 0 ? currency : currentCurrency,
            amount: insurances.selectedInsurances.reduce(function (previousValue, selectedInsurance) {
                return previousValue + selectedInsurance.insuranceProgram.totalPrice.amount;
            }, 0)
        };
        return price;
    }
    return insuranceMinPrice;
});
export var getNeedToFillContactsInfo = createSelector(getTotalPriceToPay, getIsContactsInfoEmpty, function (totalPrice, contactsInfoIsEmpty) {
    console.log(totalPrice, contactsInfoIsEmpty);
    return (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount) > 0 && contactsInfoIsEmpty;
});
export var getCheckinInfoParams = createSelector(getCheckinOrder, function (_a) {
    var _b, _c;
    var travellers = _a.travellers;
    return ({
        secret: getUserValue(travellers[0], TravellerFieldEnum.LastName),
        id: (_c = (_b = travellers[0]) === null || _b === void 0 ? void 0 : _b.tickets[0]) === null || _c === void 0 ? void 0 : _c.number
    });
});
export var getCheckinHasCompleted = createSelector(getState, function (_a) {
    var order = _a.order;
    return order.status === CheckinOrderStatus.Confirmed;
});
export var isExpired = createSelector(getState, function (_a) {
    var order = _a.order;
    return order.status === CheckinOrderStatus.Expired;
});
export var getAllServices = createSelector(getCheckinOrder, function (_a) {
    var _b, _c;
    var additionalServices = _a.additionalServices;
    return (_c = (_b = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) !== null && _c !== void 0 ? _c : [];
});
export var makeGetGdsServicesByType = function (type) {
    return createSelector([getAllServices], function (checkinGdsServices) {
        return checkinGdsServices.filter(function (service) { return service.type === type; });
    });
};
export var getExtraSeatServices = createSelector(getAllServices, function (services) {
    return services.filter(function (service) {
        return service.rfisc === 'EXST' &&
            service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            (service === null || service === void 0 ? void 0 : service.subType) ===
                GdsServiceVipSubType.ExtraSeat;
    });
});
export var getUpgradeServices = createSelector(getAllServices, function (services) {
    return services.filter(function (service) {
        return service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            service.subType ===
                GdsServiceVipSubType.Upgrade;
    });
});
export var getBaggageServices = createSelector(getAllServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; });
});
export var getMealServices = createSelector(getAllServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Meal; });
});
export var getMeals = createSelector(getMealServices, function (meals) { return meals.map(convertMealObject); });
export var getBaggage = createSelector(getBaggageServices, function (baggage) { return baggage.map(convertBaggageObject); });
export var getRecommendedMeals = createSelector(getMeals, getTravellers, function (meals, travellers) {
    var result = [];
    travellers.forEach(function (traveller) {
        var _a, _b;
        (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.forEach(function (service) {
            var meal = meals.find(function (meal) { return meal.id === service.serviceId; });
            if (meal) {
                result.push({
                    meal: meal,
                    travellerId: traveller.id,
                    segmentIds: service.segmentIds,
                    count: service.count
                });
            }
        });
    });
    return result;
});
export var showMealRecommendations = createSelector(getCheckinOrderId, getRecommendedMeals, getTravellers, function (orderId, recommendedMeals, passengers) {
    return !!recommendedMeals.length &&
        !passengers.some(function (passenger) {
            var _a, _b;
            return (_b = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.some(function (service) {
                return recommendedMeals.some(function (meal) { return meal.meal.id === service.serviceId; });
            });
        }) &&
        !get("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId));
});
export var getSeatMap = createSelector(getCheckinOrder, function (_a) {
    var seatMap = _a.seatMap;
    return seatMap;
});
export var getIsSeatMapUnavailable = createSelector(getSeatMap, getTravellers, function (seatMap, travellers) {
    var hasSegmentsWithZeroAvailableSeats = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments.some(function (segment) { return !segment.availableSeatsCount; });
    var allTravellersHaveNotSeats = travellers.every(function (traveller) { var _a, _b; return !((_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length); });
    return hasSegmentsWithZeroAvailableSeats && allTravellersHaveNotSeats;
});
export var getInsurances = createSelector(getCheckinOrder, function (_a) {
    var additionalServices = _a.additionalServices;
    return additionalServices.insurances;
});
export var getTransfer = createSelector(getCheckinOrder, function (_a) {
    var additionalServices = _a.additionalServices;
    return additionalServices.transfer;
});
export var hasBaggageServices = createSelector(getBaggageServices, function (baggage) { return !!baggage.length; });
export var hasUpgradeServices = createSelector(getUpgradeServices, function (upgradeServices) {
    return upgradeServices.some(function (service) { return service.canBeAdded; });
});
export var hasMealServices = createSelector(getMealServices, function (meal) { return !!meal.length; });
export var hasSeatMap = createSelector(getSeatMap, function (seatMap) {
    var _a, _b, _c;
    return !!((_a = seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(function (segment) { return segment.isAnySeatExistsAndFree; })) ||
        !!((_c = (_b = seatMap.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.length);
});
export var getHasAvailableSeats = createSelector(getSeatMap, function (seatMap) { var _a; return (_a = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(function (segment) { return segment === null || segment === void 0 ? void 0 : segment.availableSeatsCount; }); });
export var hasInsurances = createSelector(getInsurances, function (insurances) { var _a; return !!((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length); });
export var hasTransfers = createSelector(getTransfer, function (transfer) { var _a, _b; return !!((_b = (_a = transfer === null || transfer === void 0 ? void 0 : transfer.aeroexpress) === null || _a === void 0 ? void 0 : _a.trips) === null || _b === void 0 ? void 0 : _b.length); });
export var getSegments = createSelector(getCheckinOrder, function (order) { return order.segments; });
export var getFareFamiliesMap = createSelector(getSegments, function (segmentsFromOrder) {
    var fareFamiliesMap = new Map();
    segmentsFromOrder.forEach(function (_a) {
        var id = _a.id, fareFamilies = _a.fareFamilies;
        var fareFamily = fareFamilies.length > 0 ? fareFamilies[0].fareFamily : null;
        if (fareFamily) {
            if (!fareFamiliesMap.has(fareFamily.id)) {
                fareFamiliesMap.set(fareFamily.id, {
                    fareFamily: fareFamily,
                    segmentIds: []
                });
            }
            fareFamiliesMap.get(fareFamily.id).segmentIds.push(id);
        }
    });
    return fareFamiliesMap;
});
export var getCheckinIncludedBaggage = createSelector(getFareFamiliesMap, getTravellers, useConfig, function (fareFamiliesMap, travellers, config) {
    var _a, _b;
    if (((_b = (_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.companyInfo) === null || _b === void 0 ? void 0 : _b.iataCode) === 'N4') {
        // filter out all included baggage for INF and INS
        var travellersWithoutInfants = travellers.filter(function (traveller) { return traveller.type !== AviaPassengerType.INF && traveller.type !== AviaPassengerType.INS; });
        return fareFamiliesMap
            ? parseIncludedBaggage(fareFamiliesMap, travellersWithoutInfants.map(function (_a) {
                var id = _a.id;
                return id;
            }))
            : [];
    }
    return parseIncludedBaggage(fareFamiliesMap, travellers.map(function (_a) {
        var id = _a.id;
        return id;
    }));
});
export var getCheckinBrandIncludedBaggage = createSelector(getTravellers, function (travellers) {
    return getIncludedBaggageFromOrderTravellers(travellers.map(function (traveller) { return (__assign(__assign({}, traveller), { needOthsSsr: null, fares: [] })); }));
});
export var getSegmentsForServices = createSelector(getSegments, function (segments) { return segments; });
export var getPriceBreakdown = createSelector(getCheckinOrder, function (_a) {
    var priceBreakdown = _a.priceBreakdown;
    return priceBreakdown;
});
export var getMealsPrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, function (_a, currentCurrency) {
    var priceToPay = _a.priceToPay;
    return priceToPay.meals ? __assign(__assign({}, priceToPay.meals), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency };
});
export var getBaggagePrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, function (_a, currentCurrency) {
    var priceToPay = _a.priceToPay;
    return priceToPay.baggage
        ? __assign(__assign({}, priceToPay.baggage), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency };
});
export var getSeatsPrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, function (_a, currentCurrency) {
    var priceToPay = _a.priceToPay;
    return priceToPay.seats ? __assign(__assign({}, priceToPay.seats), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency };
});
export var getInsurancePrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, function (_a, currentCurrency) {
    var priceToPay = _a.priceToPay;
    return priceToPay.insurance
        ? __assign(__assign({}, priceToPay.insurance), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency };
});
export var getAeroexpressPrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, function (_a, currentCurrency) {
    var priceToPay = _a.priceToPay;
    return priceToPay.aeroexpress
        ? __assign(__assign({}, priceToPay.aeroexpress), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency };
});
export var getHasSelectedAeroexpress = createSelector(getCheckinOrder, function (order) { var _a, _b, _c, _d; return !!((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.length); });
export var getHasConfirmedAeroexpress = createSelector(getCheckinOrder, function (order) {
    var _a, _b, _c, _d;
    return !!((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.some(function (trip) { var _a; return (_a = trip === null || trip === void 0 ? void 0 : trip.tickets) === null || _a === void 0 ? void 0 : _a.some(function (ticket) { return ticket === null || ticket === void 0 ? void 0 : ticket.imageUrl; }); }));
});
export var getExtrasPrice = createSelector(getAeroexpressPrice, getInsurancePrice, getCurrentCurrencyFromCheckinOrder, function (aeroexpress, insurance, currentCurrency) { return ({
    amount: aeroexpress.amount + insurance.amount,
    currency: currentCurrency
}); });
export var getIsAllTravellersHavePreseating = createSelector(getCheckinOrder, function (order) { return order.allTravellersHavePreseating; });
export var getIsCheckinCompletedOrPreseating = createSelector(getCheckinHasCompleted, getIsAllTravellersHavePreseating, function (isCheckinCompleted, isAllTravellersHavePreseating) { return isCheckinCompleted || isAllTravellersHavePreseating; });
export var getConfirmedServices = createSelector(getTravellers, function (travellers) {
    var result = {};
    travellers.forEach(function (traveller) {
        var _a, _b, _c;
        var services = (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) !== null && _c !== void 0 ? _c : [];
        services.forEach(function (_a) {
            var confirmedCount = _a.confirmedCount, serviceId = _a.serviceId, segmentIds = _a.segmentIds;
            if (confirmedCount > 0) {
                if (!result[traveller.id]) {
                    result[traveller.id] = {};
                }
                if (!result[traveller.id][serviceId]) {
                    result[traveller.id][serviceId] = {};
                }
                segmentIds.forEach(function (segmentId) {
                    result[traveller.id][serviceId][segmentId] = confirmedCount;
                });
            }
        });
    });
    return result;
});
export var getAllRequiredSeatsAreSelected = createSelector(getDeprecatedPassengersState, getSeatMap, function (passengersState, seatMap) {
    return checkAllSeatsAreSelected(passengersState, seatMap.segments.filter(function (segment) { return segment.availableSeatsCount > 0; }).length);
});
export var getUpgradeBaggage = createSelector(getBaggage, getTravellers, function (baggageItems, travellers) {
    var travellersByIdMap = new Map(travellers.map(function (traveller) {
        var _a, _b;
        if (!((_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return [traveller.id, null];
        }
        var travellerGdsServicesById = new Map(traveller.services.gdsServices.services.map(function (service) { return [
            service.serviceId,
            service.count > service.confirmedCount
        ]; }));
        return [traveller.id, travellerGdsServicesById];
    }));
    var isCanBeAddedOrRemoved = function (item) {
        var canBeRemoved = item.allowedPassengers.some(function (passengerId) { var _a; return (_a = travellersByIdMap.get(passengerId)) === null || _a === void 0 ? void 0 : _a.get(item.id); });
        return item.canBeAdded || canBeRemoved;
    };
    return baggageItems.filter(function (item) {
        return item.type === BaggageType.HandLuggageExcess && isCanBeAddedOrRemoved(item);
    });
});
export var getAdditionalBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return item.type === BaggageType.CheckedBaggage; });
});
export var getSpecialEquipment = createSelector(getBaggage, function (items) {
    return items.filter(function (item) {
        return [
            BaggageType.SportingEquipment,
            BaggageType.PetInCabin,
            BaggageType.PetInHold,
            BaggageType.MusicalInstrument
        ].includes(item.type);
    });
});
export var getOverweightBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return item.type === BaggageType.BaggageExcess; });
});
export var getIsTravellerDataFilled = createSelector(getCheckinOrder, function (order) { return order.isTravellersDataFilled; });
export var getCheckinPassengerLabels = createSelector(getPassengersNames, function (passengers) {
    var result = {};
    passengers.forEach(function (passenger) {
        result[passenger.id] = passenger.name;
    });
    return result;
});
export var getHasRequestedOrderServices = createSelector(getCheckinHasStarted, getTravellers, function (checkinStarted, travellers) {
    return checkinStarted && getHasRequestedServices(travellers);
});
export var getIsSkipSeatSelectionAllowed = createSelector(getCheckinOrder, function (order) { return order.isSkipSeatSelectionAllowed; });
