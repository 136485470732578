import * as React from 'react';
import { OrderStatus } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
var StepbarPayload = React.memo(function (props) {
    var t = useTranslation('Stepbar').t;
    var theme = useTheme('Checkout').Stepbar;
    if (props.orderStatus === OrderStatus.Confirmed) {
        var features = [];
        if (props.canAddServices) {
            features.push(t('add services'));
        }
        if (props.exchangeAvailable) {
            features.push(t('change dates'));
        }
        if (!!features.length) {
            return React.createElement("div", { className: theme.additional }, t('You can {{payload}}', { payload: features.join(', ') }));
        }
    }
    return null;
});
export default StepbarPayload;
