import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../utils';
import { useConfig } from '../../../context';
import SummaryItem from '../Summary/SummaryItem';
import Money from '../../../Money';
var PriceParts = function (_a) {
    var prices = _a.prices;
    var t = useTranslation('PaymentForm').t;
    var showZeroCharges = useConfig().global.showZeroCharges;
    return (React.createElement(React.Fragment, null, Object.keys(prices)
        .filter(function (priceKey) {
        var _a;
        if (priceKey === 'charge' && showZeroCharges) {
            return true;
        }
        else {
            return ((_a = prices[priceKey]) === null || _a === void 0 ? void 0 : _a.amount) > 0;
        }
    })
        .map(function (priceKey) { return (React.createElement(SummaryItem, { key: priceKey, price: React.createElement(Money, { money: prices[priceKey] }) },
        t(capitalizeFirstLetter(priceKey)),
        ":")); })));
};
export default PriceParts;
