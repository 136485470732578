import { addMonths, addYears, endOfYear, getDay, getDaysInMonth, startOfYear } from 'date-fns';
/**
 * Is the date was selected?
 * @param {Date} date
 * @param {SelectedTimestampDaysMap} selectedTimestampDays
 * @return {boolean}
 */
export var isDaySelected = function (date, selectedTimestampDays) {
    var newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return selectedTimestampDays.hasOwnProperty(newDate.getTime());
};
/**
 * Return timestamp the beginning of the day (00:00:00).
 * @param {Date} date
 * @return {number}
 */
export var getTimestamp = function (date) {
    var tmp = new Date(date);
    return tmp.setHours(0, 0, 0, 0);
};
/**
 * Parsing array of selected days. And looking min & max values of the array.
 * @param {Date[]} dates
 */
export var parseSelectedDays = function (dates) {
    var selectedTimestampDays = {};
    dates.map(function (date) {
        var newDate = new Date(date), time = newDate.setHours(0, 0, 0, 0);
        if (selectedTimestampDays.hasOwnProperty(time)) {
            selectedTimestampDays[time].count++;
        }
        else {
            selectedTimestampDays[time] = {
                count: 1
            };
        }
    });
    return selectedTimestampDays;
};
/**
 * Convert dates map array to plain map with timestamps
 */
export var parseHighlightedDates = function (highlightedDates) {
    if (highlightedDates === void 0) { highlightedDates = {}; }
    var highlightedDatesMap = {};
    var _loop_1 = function (classNameGroup) {
        if (highlightedDates.hasOwnProperty(classNameGroup)) {
            highlightedDates[classNameGroup].map(function (date) {
                var newDate = new Date(date.getTime());
                var timestamp = newDate.setHours(0, 0, 0, 0);
                if (highlightedDatesMap.hasOwnProperty(timestamp)) {
                    highlightedDatesMap[timestamp] += " ".concat(classNameGroup);
                }
                else {
                    highlightedDatesMap[timestamp] = classNameGroup;
                }
            });
        }
    };
    for (var classNameGroup in highlightedDates) {
        _loop_1(classNameGroup);
    }
    return highlightedDatesMap;
};
/**
 * Return true when first date is after second date
 *
 * @param {number} time1
 * @param {number} time2
 * @return {boolean}
 */
export var timestampsCompare = function (time1, time2) {
    if ((time1 >= 0 && time2 >= 0) || (time1 < 0 && time2 < 0)) {
        return time1 > time2;
    }
    return time1 > 0 && time2 < 0;
};
/**
 * Get all available dates with one year interval.
 * Example: ['2000-01-01', '2001-01-01', ... '2020-01-01']
 * @return {Date[]}
 */
export var getAllYears = function (minTimestamp, maxTimestamp) {
    var years = [startOfYear(new Date(minTimestamp))];
    var nextYear = startOfYear(new Date(minTimestamp));
    while (true) {
        nextYear = addYears(nextYear, 1);
        years.push(nextYear);
        if (endOfYear(nextYear).getTime() >= maxTimestamp) {
            return years;
        }
    }
};
/**
 * Is the date month available with min & max dates restrictions ?
 * @param {Date} date
 * @param {number} offset - month offset
 * @param {Date} minDate
 * @param {Date} maxDate
 * @return {boolean}
 */
export var isMonthAvailable = function (date, offset, minDate, maxDate) {
    if (offset === void 0) { offset = 0; }
    var newDate = addMonths(date, offset), time = newDate.getTime(), minTimestamp = getTimestamp(minDate), maxTimestamp = getTimestamp(maxDate);
    newDate.setHours(0, 0, 0, 0);
    if (time >= minTimestamp && time <= maxTimestamp) {
        return true;
    }
    return (time < minTimestamp &&
        newDate.getFullYear() === minDate.getFullYear() &&
        newDate.getMonth() === minDate.getMonth());
};
/**
 * Checking that selected date satisfies the min and max dates.
 * @param {Date} date
 * @param {number} minTimestamp
 * @param {number} maxTimestamp
 * @return {Date}
 */
export var correctDate = function (date, minTimestamp, maxTimestamp) {
    var time = date.getTime();
    var suggestDate = date;
    if (timestampsCompare(minTimestamp, time)) {
        suggestDate = new Date(minTimestamp);
    }
    else if (timestampsCompare(time, maxTimestamp)) {
        suggestDate = new Date(maxTimestamp);
    }
    return suggestDate;
};
/**
 * Get array with dates
 * @param {Date} focusedDate
 * @param {number} monthCount
 * @return {Date[]}
 */
export var getMonthsList = function (focusedDate, monthCount) {
    var dates = [];
    for (var monthIndex = 0; monthIndex < monthCount; monthIndex++) {
        dates.push(addMonths(focusedDate, monthIndex));
    }
    return dates;
};
/**
 * Get selected dates count
 * @param {SelectedTimestampDaysMap} datesMap
 * @return {number}
 */
export var getSelectedDatesCount = function (datesMap) {
    var count = 0;
    for (var date in datesMap) {
        if (datesMap.hasOwnProperty(date)) {
            count += datesMap[date].count;
        }
    }
    return count;
};
export var diffInDays = function (timestamp1, timestamp2) {
    return Math.round(Math.abs((timestamp1 - timestamp2) / (24 * 60 * 60 * 1000)));
};
export var getWeeksInMonth = function (startOfMonthDate) {
    // change of the first working day to Monday
    var daysOffset = (getDay(startOfMonthDate) + 6) % 7;
    var daysInMonth = getDaysInMonth(startOfMonthDate);
    return Math.ceil((daysInMonth + daysOffset) / 7);
};
