import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route as RouteComponent, Switch, useHistory, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import MediaQuery from 'react-responsive';
import { initI18n, TABLET_MIN_WIDTH } from '../../utils';
import Sidebar from '../../Sidebar';
import Loader from '../../Loader';
import SimpleLoader from '../../SimpleLoader';
import { Aircraft, Communications, Group, LogoutIcon, MyCabinet, Settings, Star } from '../../Modules/Account/Icons';
import TravelCompanions from '../../TravelCompanions';
import { useConfig } from '../../context';
import LoginPage from '../../LoginPage';
import MobileMenu from '../../Sidebar/components/MobileMenu/MobileMenu';
import { reachGoal, UserGoal } from '../../analytics';
import Logout from './Logout/Logout';
import Loyalty from '../../Loyalty';
import Orders from './Orders/Orders';
import { useTheme } from '../../theme';
import { LoginType, useGetAccountInfoQuery } from '@websky/graphql';
import WarningModal from '../../WarningModal';
import HomePage from './HomePage';
import { renderType } from '../../TravelCompanions/components/TravelCompanions';
import SettingsPage from './Settings/Settings';
import CommunicationPage from '../../Communications/components/CommunicationBase';
import { useLogout } from '../../Modules/Account/hooks';
import { useHasLoyaltyProgram } from '../../hooks';
import { useCurrentUser } from '../../CurrentUser/CurrentUser.hooks';
import { useSelector } from 'react-redux';
import { getUnreadTotal } from '../../Communications/store/selectors';
import { OverrideComponent } from '../../renderProps';
import { AUTH_DUPLICATES, sessionStorageGet } from '../../cache';
import { tinkoffCallbackUrlSuffix } from '../../SocialAuth/components/utils';
initI18n('Account');
var routes = [
    {
        title: i18n.t('My office'),
        pathname: '/account',
        icon: MyCabinet
    },
    {
        title: i18n.t('Loyalty program'),
        pathname: '/account/loyalty',
        icon: Star
    },
    {
        title: i18n.t('Orders'),
        pathname: '/account/orders',
        icon: Aircraft
    },
    {
        title: i18n.t('Passengers'),
        pathname: '/account/companions',
        icon: Group
    },
    {
        title: i18n.t('Profile'),
        pathname: '/account/settings',
        icon: Settings
    },
    {
        title: i18n.t('Communications'),
        pathname: '/account/communications',
        icon: Communications,
        hasChild: true
    }
    // {
    // 	title: i18n.t('Logout'),
    // 	pathname: '/account/logout',
    // 	icon: LogoutIcon
    // }
    // {
    // 	title: i18n.t('Client support'),
    // 	pathname: '/account/support',
    // 	icon: Support
    // }
];
var LoginStages;
(function (LoginStages) {
    LoginStages[LoginStages["LoggedIn"] = 0] = "LoggedIn";
    LoginStages[LoginStages["Loading"] = 1] = "Loading";
    LoginStages[LoginStages["NotLoggedIn"] = 2] = "NotLoggedIn";
})(LoginStages || (LoginStages = {}));
var SYSTEM_PAGES = [tinkoffCallbackUrlSuffix];
var Account = function (_a) {
    var location = _a.location;
    var _b = __read(useState(LoginStages.Loading), 2), isLoggedIn = _b[0], setLoggedIn = _b[1];
    var _c = __read(useState(null), 2), accountInfoError = _c[0], setAccountInfoError = _c[1];
    var hasLoyaltyProgram = useHasLoyaltyProgram();
    var communicationUnreadTotal = useSelector(getUnreadTotal);
    var history = useHistory();
    var css = useTheme('AccountModule').Account;
    var t = useTranslation('Account').t;
    var checkoutT = useTranslation('Checkout').t;
    var currentUser = useGetAccountInfoQuery({
        variables: {
            skipOrdersList: true
        },
        fetchPolicy: 'no-cache'
    });
    var _d = useLogout('/account'), onLogout = _d.onLogout, logoutLoading = _d.loading;
    var _e = __read(React.useState(false), 2), isLoading = _e[0], setLoading = _e[1];
    var _f = useConfig(), _g = _f.Account, engineURL = _g.engineURL, showCommunications = _g.showCommunications, companyInfo = _f.global.companyInfo;
    useCurrentUser();
    useEffect(function () {
        var _a, _b;
        if ((_b = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) {
            setLoggedIn(LoginStages.LoggedIn);
        }
        else if (currentUser.loading) {
            setLoggedIn(LoginStages.Loading);
        }
        else {
            setLoggedIn(LoginStages.NotLoggedIn);
        }
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.error) {
            setAccountInfoError(currentUser.error.message);
        }
    }, [currentUser]);
    var _h = __read(useState(null), 2), userSettingsError = _h[0], setUserSettingsError = _h[1];
    var getUserInfo = function () {
        var userValues = {
            email: '',
            firstName: '',
            lastName: ''
        };
        if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) && !currentUser.loading && currentUser.data.CurrentUser) {
            currentUser.data.CurrentUser.userProfile.values.forEach(function (value) {
                if (value.name === 'lastName' || value.name === 'firstName' || value.name === 'email') {
                    userValues[value.name] = value.value;
                }
            });
        }
        return userValues;
    };
    var refetchAccountInfo = function () {
        setLoading(true);
        currentUser.refetch().finally(function () { return setLoading(false); });
    };
    var clearUserSettingsError = useCallback(function () {
        setUserSettingsError(null);
    }, []);
    var onLoyaltyCardSaved = function () {
        setTimeout(function () {
            refetchAccountInfo();
        }, 2000);
        return new Promise(function (res) { return res(true); });
    };
    routes = routes.filter(function (route) {
        var pathRoute = route;
        if (pathRoute.pathname === '/account/loyalty') {
            return hasLoyaltyProgram;
        }
        if (pathRoute.pathname === '/account/communications') {
            return showCommunications;
        }
        return true;
    });
    routes.find(function (route) {
        var _a, _b;
        var pathRoute = route;
        if (pathRoute.pathname === '/account/loyalty') {
            route.title = checkoutT(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName);
            if ((_a = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _a === void 0 ? void 0 : _a.redirectTo) {
                pathRoute.pathname = (_b = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _b === void 0 ? void 0 : _b.redirectTo;
                pathRoute.isRedirect = true;
            }
        }
        if (pathRoute.pathname === '/account/communications') {
            pathRoute.badgeContent = communicationUnreadTotal;
        }
    });
    useEffect(function () {
        routes = __spreadArray(__spreadArray([], __read(routes), false), [
            {
                title: i18n.t('Logout'),
                icon: LogoutIcon,
                action: onLogout
            }
        ], false);
    }, []);
    useEffect(function () {
        if (isLoggedIn === LoginStages.NotLoggedIn && !SYSTEM_PAGES.some(function (path) { return location.pathname.includes(path); })) {
            history.replace('/account');
        }
    }, [isLoggedIn]);
    var onCloseAccountInfoError = function () {
        setAccountInfoError(null);
    };
    var onGoToSettings = function () {
        history.push('/account/settings');
    };
    useEffect(function () {
        var _a;
        if (!!sessionStorageGet(AUTH_DUPLICATES) && ((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser)) {
            onGoToSettings();
        }
    }, [currentUser]);
    return (React.createElement(React.Fragment, null,
        logoutLoading && React.createElement(SimpleLoader, null),
        isLoggedIn === LoginStages.LoggedIn && (React.createElement("div", { className: css.wrapper },
            React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                React.createElement(Sidebar, { routes: routes, selectedRoute: location.pathname, userInfo: getUserInfo() })),
            React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                React.createElement(MobileMenu, { routes: routes, selectedRoute: location.pathname, userInfo: getUserInfo() })),
            React.createElement("div", { className: css.active__route },
                React.createElement(Switch, null,
                    React.createElement(RouteComponent, { path: "/account", exact: true, render: function () { return (React.createElement(HomePage, { currentUser: currentUser.data.CurrentUser.userProfile })); } }),
                    React.createElement(RouteComponent, { path: "/account/orders", exact: true, render: function () { return React.createElement(Orders, null); } }),
                    React.createElement(RouteComponent, { path: "/account/profile", exact: true, render: function () { return React.createElement(Redirect, { to: "/account/companions" }); } }),
                    React.createElement(RouteComponent, { path: "/account/settings", render: function () {
                            var _a;
                            return (React.createElement(SettingsPage, { currentUser: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser, setUserSettingsError: setUserSettingsError, refetchAccountInfo: refetchAccountInfo }));
                        } }),
                    React.createElement(RouteComponent, { path: "/account/logout", exact: true, render: function () {
                            if (isLoggedIn === LoginStages.LoggedIn) {
                                return React.createElement(Logout, null);
                            }
                            else if (isLoggedIn === LoginStages.NotLoggedIn) {
                                return React.createElement(Redirect, { to: "/account" });
                            }
                            else {
                                return React.createElement(Loader, null);
                            }
                        } }),
                    hasLoyaltyProgram && (React.createElement(RouteComponent, { path: "/account/loyalty", exact: true, render: function () {
                            var _a, _b, _c, _d, _e;
                            return (React.createElement(React.Fragment, null,
                                React.createElement("h1", { className: css.route__title }, checkoutT(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName)),
                                React.createElement(OverrideComponent, { componentProps: {
                                        isAuthorizationNeeded: (_b = (_a = currentUser.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded,
                                        links: (_c = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyalty) === null || _c === void 0 ? void 0 : _c.links,
                                        ffpInfo: (_e = (_d = currentUser.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.userFfpInfo,
                                        loyaltyName: checkoutT(companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.loyaltyProgramName),
                                        onLoyaltyCardSaved: onLoyaltyCardSaved,
                                        userInfo: getUserInfo(),
                                        onGoToSettings: onGoToSettings,
                                        isLoading: isLoading
                                    }, component: { AccountLoyalty: Loyalty } })));
                        } })),
                    React.createElement(RouteComponent, { path: "/account/companions", exact: true, render: function () { return (React.createElement(React.Fragment, null,
                            React.createElement(TravelCompanions, { format: renderType.Companions }))); } }),
                    showCommunications && (React.createElement(RouteComponent, { path: "/account/communications", render: function () { return React.createElement(CommunicationPage, null); } })),
                    React.createElement(RouteComponent, { path: "/", render: function () {
                            window.location.href = "".concat(engineURL, "#/404");
                            return null;
                        } }))))),
        isLoggedIn === LoginStages.NotLoggedIn && (React.createElement("div", { className: css.loginWrapper },
            React.createElement(LoginPage, { loginType: LoginType.Email, onSuccess: function (isRegistration) {
                    reachGoal(isRegistration ? UserGoal.Registration : UserGoal.Login);
                    window.location.reload();
                } }),
            React.createElement(WarningModal, { isOpen: !!accountInfoError, onClose: onCloseAccountInfoError, onButtonClick: onCloseAccountInfoError, errorMessage: accountInfoError, title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), buttonText: t('Ok') }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: userSettingsError ? userSettingsError.message : '', onClose: clearUserSettingsError, onButtonClick: clearUserSettingsError, buttonText: t('OK, thank you'), isOpen: !!userSettingsError })));
};
export default withRouter(Account);
