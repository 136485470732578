export var LocationType;
(function (LocationType) {
    LocationType["Departure"] = "departure";
    LocationType["Arrival"] = "arrival";
})(LocationType || (LocationType = {}));
export var Mode;
(function (Mode) {
    Mode[Mode["Booking"] = 0] = "Booking";
    Mode[Mode["Checkin"] = 1] = "Checkin";
})(Mode || (Mode = {}));
export var Locale;
(function (Locale) {
    Locale["Georgian"] = "ka";
    Locale["English"] = "en";
    Locale["Russian"] = "ru";
    Locale["German"] = "de";
    Locale["Kazakh"] = "kk";
    Locale["French"] = "fr";
    Locale["Chinese"] = "zh";
    Locale["Armenian"] = "hy";
    Locale["Turkish"] = "tr";
    Locale["Tajik"] = "tg";
    Locale["Czech"] = "cs";
    Locale["Belarusian"] = "be";
})(Locale || (Locale = {}));
export var Metasearch;
(function (Metasearch) {
    Metasearch["Aviasales"] = "aviasales";
})(Metasearch || (Metasearch = {}));
export var ApolloErrorType;
(function (ApolloErrorType) {
    ApolloErrorType["PnrBlocked"] = "pnrBlocked";
})(ApolloErrorType || (ApolloErrorType = {}));
export var StepsEnum;
(function (StepsEnum) {
    StepsEnum["Flights"] = "Flights";
    StepsEnum["Seats"] = "Seats";
    StepsEnum["Baggage"] = "Baggage";
    StepsEnum["Meal"] = "Meal";
    StepsEnum["Passengers"] = "Passengers";
    StepsEnum["Insurance"] = "Insurance";
    StepsEnum["Order"] = "Order";
    StepsEnum["Extras"] = "Extras";
    StepsEnum["Payment"] = "Payment";
    StepsEnum["BoardingPass"] = "BoardingPass";
})(StepsEnum || (StepsEnum = {}));
export var WebskyEventType;
(function (WebskyEventType) {
    WebskyEventType["NearestAirportsLoaded"] = "nearestAirportsLoaded";
    WebskyEventType["LanguageChanged"] = "languageChanged";
})(WebskyEventType || (WebskyEventType = {}));
