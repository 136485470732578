import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import CheckoutAdditionalService from '../..';
import { useTheme, ThemeProvider } from '../../../theme';
import Modal from '../../../Modal';
import { getCapsuleHotel, getSegments } from '../../../Checkout/store/order/selectors';
import { getCalculatedCapsuleHotelPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useOrderService } from '../hooks';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import VipServiceSelect from '../../../VipServiceSelect';
import Control from '../../../VipServiceSelect/components/Control/Control';
import Money from '../../../Money';
import Close from '../Close/Close';
import { useIsCanModifyServices } from '../../hooks';
var CapsuleHotel = function () {
    var theme = useTheme('CheckoutAdditionalService').CapsuleHotel;
    var t = useTranslation('Checkout').t;
    var segments = useSelector(getSegments);
    var capsuleHotelServices = useSelector(getCapsuleHotel);
    var price = useSelector(getCalculatedCapsuleHotelPrice);
    // TODO: refactor this when php sends pictures
    var slides = [
        'https://icdn.lenta.ru/images/2019/12/29/16/20191229162041144/pwa_list_rect_1280_35b06e2bd3403fb2d4748e36b1e25189.png',
        'https://icdn.lenta.ru/images/2019/12/29/16/20191229162041144/pwa_list_rect_1280_35b06e2bd3403fb2d4748e36b1e25189.png',
        'https://icdn.lenta.ru/images/2019/12/29/16/20191229162041144/pwa_list_rect_1280_35b06e2bd3403fb2d4748e36b1e25189.png'
    ];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _a = useOrderService(capsuleHotelServices, OrderAdditionalServiceGdsServiceServiceType.Vip), isSelected = _a.isSelected, selectedServices = _a.selectedServices, onOpen = _a.onOpen, onClose = _a.onClose, setOpened = _a.setOpened, onSaveHandler = _a.onSaveHandler, passengers = _a.passengers, opened = _a.opened;
    var checkoutAdditionalServiceTheme = React.useMemo(function () { return ({
        CheckoutAdditionalService: {
            CheckoutAdditionalService: {
                main: theme.main
            }
        }
    }); }, []);
    var isCanModifyServices = useIsCanModifyServices(capsuleHotelServices);
    if (capsuleHotelServices.length === 0) {
        return null;
    }
    var sliderOptions = {
        className: theme.slider,
        dotsClass: theme.dots,
        slidesToShow: 1,
        speed: 600,
        arrows: false,
        infinite: false,
        dots: true
    };
    var renderClose = function (onClick) { return React.createElement(Close, { onClick: onClick }); };
    var renderPassenger = function (_a) {
        var _b;
        var passenger = _a.passenger, type = _a.type, count = _a.count, isConfirmed = _a.isConfirmed, onSetCount = _a.onSetCount;
        var price = (_b = capsuleHotelServices.find(function (service) { return service.id === passenger.serviceId; })) === null || _b === void 0 ? void 0 : _b.price;
        return (React.createElement("div", { className: theme.passenger },
            React.createElement(Control, { type: type, count: count, label: React.createElement("div", { className: theme.label }, passenger.name), disabled: isConfirmed, onSetCount: onSetCount(passenger) }),
            React.createElement("div", { className: theme.price }, count > 0 && React.createElement(Money, { money: price }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ThemeProvider, { value: checkoutAdditionalServiceTheme },
            React.createElement(CheckoutAdditionalService, { header: t('Capsule hotel'), description: capsuleHotelServices[0].description, className: theme.capsuleHotel, priceFrom: price, priceTotal: price, isSelected: isSelected, addClick: onOpen, descriptionClassName: theme.descriptions, buttons: [
                    {
                        action: function () { return setOpened(true); },
                        text: isCanModifyServices ? t('Change') : t('More details')
                    }
                ], addButtonText: !isCanModifyServices ? t('More details') : null }, capsuleHotelServices[0].description)),
        React.createElement(Modal, { classes: {
                root: theme.modal_root,
                paper: theme.paper,
                scrollBody: theme.modal_scrollBody
            }, maxWidth: "md", slideUp: isMobile, open: opened, closeButtonRenderer: renderClose, onClose: onClose },
            React.createElement(VipServiceSelect, { header: t('Capsule hotel'), headerClassName: theme.header, subheader: React.createElement(React.Fragment, null,
                    slides.length > 0 && (React.createElement(Slider, __assign({}, sliderOptions), slides.map(function (image, index) { return (React.createElement("div", { className: theme.slide, key: index },
                        React.createElement("img", { src: image, alt: "" }))); }))),
                    capsuleHotelServices[0].description), passengers: passengers, services: capsuleHotelServices, segments: segments, selectedServices: selectedServices, type: "checkbox", onSave: onSaveHandler, onClose: onClose, renderPassenger: renderPassenger, readOnly: !isCanModifyServices, showTotalPrice: true, showFromPrice: true }))));
};
export default CapsuleHotel;
