import React from 'react';
import { CategoryCard } from './CategoryCard/CategoryCard';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MediaQuery from '../../../MediaQuery/MediaQuery';
export var CategoriesPage = function (props) {
    var css = useTheme('Communications').Communication;
    var t = useTranslation('Communications').t;
    var url = useRouteMatch().url;
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'tablet' },
            React.createElement("h1", { className: css.title }, t('Communications'))),
        React.createElement("div", { className: css.list }, props.categories.map(function (item, index) { return (React.createElement(Link, { key: item.id, to: "".concat(url, "/").concat(item.path) },
            React.createElement(CategoryCard, { title: t(item.title), icon: item.icon, unreadCount: item.unreadMessagesCount, lastMessage: item.lastMessage }))); }))));
};
