import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { useGraphQLClient } from '../../../graphql-client';
import Account from '../../../Account';
import Analytics from '../../../Engine/components/Analytics';
import ExternalLocaleResources from '../../../ExternalLocaleResources/ExternalLocaleResources';
import IsDemo from '../../../Engine/components/IsDemo';
import CommunicationUnreadTotalProvider from '../../../Communications/components/CommunicationUnreadTotalProvider';
export var Module = function () {
    var client = useGraphQLClient();
    var t = useTranslation('Title').t;
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(CommunicationUnreadTotalProvider, null,
            React.createElement(Helmet, null,
                React.createElement("title", null,
                    t('Title:companyName'),
                    " \u2013 ",
                    t('Account'))),
            React.createElement(ExternalLocaleResources, null),
            React.createElement(Analytics, null),
            React.createElement(IsDemo, null),
            React.createElement(Account, null))));
};
export default Module;
