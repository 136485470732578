import { __assign, __read } from "tslib";
import * as React from 'react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Autocomplete from '../../Autocomplete';
import { parseLocationGroups } from '../utils';
import MobileDialog from './MobileDialog/MobileDialog';
import { getUserLocation, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { LocationType } from '../../types';
import OptionComponent from './Option/Option';
import Dropdown from './Dropdown/Dropdown';
import Group from './Group/Group';
import { ThemeProvider, useTheme } from '../../theme';
import { useConfig } from '../../context';
import { LocationAdditionalGroupType, LocationGroupType, useLocationsQuery } from '@websky/graphql';
initI18n('LocationAutocomplete');
var optionRenderer = function (option, key, onClick) { return (React.createElement(OptionComponent, { key: key, option: option, onClick: onClick })); };
var optionGroupRenderer = function (group, key, optionRenderer) { return React.createElement(Group, { key: key, group: group, optionRenderer: optionRenderer }); };
var LocationAutocomplete = forwardRef(function (props, ref) {
    var theme = useTheme('LocationAutocomplete').Autocomplete;
    var _a = __read(useState(''), 2), query = _a[0], setQuery = _a[1];
    var _b = __read(useState(), 2), lastSuggestion = _b[0], setLastSuggestion = _b[1];
    var _c = __read(useState(false), 2), delayLoading = _c[0], setDelayLoading = _c[1];
    var _d = __read(useState(null), 2), coordinates = _d[0], setCoordinates = _d[1];
    var _e = props.recentSearches, recentSearches = _e === void 0 ? [] : _e, _f = props.locations, locations = _f === void 0 ? {} : _f;
    var requestCoordintates = useConfig().SearchForm.requestCoordintates;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var autoCompleteTheme = useMemo(function () { return ({
        Autocomplete: {
            Autocomplete: {
                autocomplete: theme.autocomplete,
                input: theme.input
            }
        }
    }); }, []);
    if ((isMobile ? requestCoordintates.mobile : requestCoordintates.desktop) && !coordinates) {
        getUserLocation(setCoordinates);
    }
    var _g = useLocationsQuery({
        variables: {
            parameters: {
                additionalCities: recentSearches.length ? recentSearches.map(function (location) { return location.iata; }) : [],
                additionalGroups: [
                    LocationAdditionalGroupType.Nearest,
                    LocationAdditionalGroupType.AdditionalCities,
                    LocationAdditionalGroupType.Popular
                ],
                query: query,
                userPosition: coordinates,
                departureFrom: props.type !== LocationType.Departure && locations.departure ? locations.departure.iata : null
            }
        }
    }), data = _g.data, loading = _g.loading;
    useEffect(function () {
        var nearestAirport = data &&
            data.LocationSuggestions &&
            data.LocationSuggestions.find(function (location) { return location.type === LocationGroupType.Nearest; });
        if (props.type === LocationType.Departure && !props.locations.departure && nearestAirport) {
            props.onSelect(nearestAirport.cities[0]);
        }
    }, [props.type]);
    useEffect(function () {
        var _a, _b;
        if (props.onNearestAirports &&
            ((_a = lastSuggestion === null || lastSuggestion === void 0 ? void 0 : lastSuggestion.LocationSuggestions) === null || _a === void 0 ? void 0 : _a.some(function (location) { return location.type === LocationGroupType.Nearest; }))) {
            var locations_1 = [];
            (_b = lastSuggestion.LocationSuggestions.filter(function (location) { return location.type === LocationGroupType.Nearest; })) === null || _b === void 0 ? void 0 : _b.forEach(function (location) {
                location.cities.forEach(function (city) { return locations_1.push(city); });
            });
            props.onNearestAirports(locations_1);
        }
    }, [lastSuggestion]);
    var onInputChange = function (event) { return setQuery(event.target.value); };
    useEffect(function () {
        setDelayLoading(true);
        if (query.length >= 0 && data && data.LocationSuggestions && data.LocationSuggestions.length) {
            setLastSuggestion(data);
        }
        else if (query.length > 0 && (!data || !data.LocationSuggestions || !data.LocationSuggestions.length)) {
            setTimeout(function () {
                setDelayLoading(false);
                setLastSuggestion(null);
            }, 700);
        }
        else {
            setDelayLoading(false);
        }
    }, [data]);
    var onSelect = function (airport) {
        setQuery('');
        if (props.onSelect) {
            props.onSelect(airport);
        }
    };
    var onBlur = function (event) {
        var _a;
        setQuery('');
        (_a = props === null || props === void 0 ? void 0 : props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
    };
    var handleOptions = function (data) {
        return parseLocationGroups(data && data.LocationSuggestions ? data.LocationSuggestions : [], query, props.type);
    };
    var dropdownRenderer = function (dropdownProps) { return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, __assign({}, dropdownProps, { isOpen: dropdownProps.isOpen, hasInputData: !!query.trim(), onClickOutsideProps: props.onClickOutsideProps, onSelect: onSelect }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileDialog, __assign({}, dropdownProps, { type: props.type }))))); };
    return (React.createElement(ThemeProvider, { value: autoCompleteTheme },
        React.createElement(Autocomplete, __assign({ innerRef: ref }, props, { isLoading: loading, onSelect: onSelect, options: loading || delayLoading ? handleOptions(lastSuggestion) : handleOptions(data), inputValue: query, onInputChange: onInputChange, optionRenderer: optionRenderer, groupRenderer: optionGroupRenderer, dropdownRenderer: dropdownRenderer, onBlur: onBlur }))));
});
export default LocationAutocomplete;
