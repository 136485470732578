import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import Money from '../../../../Money';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { isBaggageExcess as getIsBaggageExcess } from '../../utils';
var BaggageSegment = function (_a) {
    var _b, _c;
    var _d, _e;
    var segment = _a.segment, mobileSelectedServiceId = _a.mobileSelectedServiceId, baggageByRfics = _a.baggageByRfics, baggageTab = _a.baggageTab, isAvailable = _a.isAvailable;
    var from = (_d = segment.departure.airport.city) === null || _d === void 0 ? void 0 : _d.name;
    var to = (_e = segment.arrival.airport.city) === null || _e === void 0 ? void 0 : _e.name;
    var theme = useTheme('UpgradeBaggageDialog').BaggageSegment;
    var t = useTranslation('IncludedBaggage').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isUnavailable = isAvailable === false;
    var baggagesWithOtherPrices = React.useMemo(function () {
        var _a;
        if (baggageByRfics.length) {
            var firstBaggage_1 = baggageByRfics[0][0];
            var baggageWithOtherPrice = baggageByRfics.find(function (baggages) {
                return baggages.some(function (baggage) { return (baggage === null || baggage === void 0 ? void 0 : baggage.price.amount) !== firstBaggage_1.price.amount; });
            });
            return !!baggageWithOtherPrice || ((_a = baggageByRfics[0]) === null || _a === void 0 ? void 0 : _a.length) === 1;
        }
        return !!baggageTab;
    }, [baggageByRfics]);
    var selectedBaggage = React.useMemo(function () {
        var _a;
        var selectedTab = isMobile ? baggageTab !== null && baggageTab !== void 0 ? baggageTab : mobileSelectedServiceId : baggageTab;
        if (selectedTab) {
            // ищем доступный багаж с мин ценой
            var actualBaggage_1 = null;
            var withFrom_1 = false;
            (_a = baggageByRfics
                .find(function (baggages) { return baggages.find(function (bagggage) { return bagggage.rfics === selectedTab; }); })) === null || _a === void 0 ? void 0 : _a.forEach(function (bag) {
                var isTargetSegment = bag.segmentIds.some(function (segments) {
                    return segments.some(function (segmentId) { return segmentId === segment.id; });
                });
                if (isTargetSegment && bag.canBeAdded) {
                    if (actualBaggage_1 &&
                        (actualBaggage_1.price.amount > bag.price.amount ||
                            actualBaggage_1.price.amount < bag.price.amount)) {
                        withFrom_1 = true;
                    }
                    if (!actualBaggage_1 || bag.price.amount < actualBaggage_1.price.amount) {
                        actualBaggage_1 = bag;
                    }
                }
            });
            if (!actualBaggage_1 || (actualBaggage_1 && getIsBaggageExcess(actualBaggage_1))) {
                return null;
            }
            return __assign(__assign({}, actualBaggage_1), { withPriceFrom: withFrom_1 });
        }
        return null;
    }, [baggageTab, mobileSelectedServiceId, baggageByRfics, segment, isMobile]);
    return (React.createElement("div", { className: cn(theme.root, (_b = {}, _b[theme.disabled] = isUnavailable, _b)) },
        React.createElement("div", { className: cn(theme.name, (_c = {},
                _c[theme.name_withPrices] = baggagesWithOtherPrices,
                _c)) },
            from,
            " \u2014 ",
            to),
        (baggagesWithOtherPrices || isMobile) && (React.createElement("div", { className: theme.prices },
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, baggageByRfics.map(function (baggagesWithRfisc) {
                // ищем доступный багаж с мин ценой
                var baggage = null;
                var withFrom = false;
                baggagesWithRfisc.forEach(function (bag) {
                    var isTargetSegment = isUnavailable ||
                        (!isUnavailable &&
                            bag.segmentIds.some(function (segments) {
                                return segments.some(function (segmentId) { return segmentId === segment.id; });
                            }));
                    if (isTargetSegment && bag.canBeAdded) {
                        if (baggage &&
                            (bag.price.amount < baggage.price.amount ||
                                bag.price.amount > baggage.price.amount)) {
                            withFrom = true;
                        }
                        if (!baggage || bag.price.amount < baggage.price.amount) {
                            baggage = bag;
                        }
                    }
                });
                if (!baggage) {
                    return React.createElement("div", { className: theme.price__empty });
                }
                var isBaggageExcess = getIsBaggageExcess(baggage);
                var hidePrice = isBaggageExcess;
                return (React.createElement("div", { className: theme.price }, !hidePrice && (React.createElement(React.Fragment, null,
                    withFrom && t('from'),
                    " ",
                    React.createElement(Money, { money: baggage.price })))));
            })),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: selectedBaggage ? theme.price : theme.price__empty }, selectedBaggage && (React.createElement(React.Fragment, null,
                    selectedBaggage.withPriceFrom && t('from'),
                    ' ',
                    React.createElement(Money, { money: selectedBaggage === null || selectedBaggage === void 0 ? void 0 : selectedBaggage.price }))))))),
        !baggagesWithOtherPrices && !isMobile && (React.createElement("div", { className: theme.price },
            (selectedBaggage === null || selectedBaggage === void 0 ? void 0 : selectedBaggage.withPriceFrom) && t('from'),
            " ",
            React.createElement(Money, { money: selectedBaggage === null || selectedBaggage === void 0 ? void 0 : selectedBaggage.price })))));
};
export default BaggageSegment;
