import { __read } from "tslib";
import * as React from 'react';
export var usePagination = function (arrayItems, itemsOnPage) {
    var _a = __read(React.useState(1), 2), page = _a[0], setPage = _a[1];
    var handleChangePage = function (page) {
        setPage(page);
    };
    var itemsCount = Array.isArray(arrayItems) ? arrayItems === null || arrayItems === void 0 ? void 0 : arrayItems.length : arrayItems;
    var pagesCount = Math.ceil(itemsCount / itemsOnPage);
    var itemsPerPage = {
        startValue: itemsOnPage * (page - 1),
        endValue: page * itemsOnPage
    };
    var paginationAvailable = pagesCount && pagesCount > 1;
    return { itemsPerPage: itemsPerPage, paginationAvailable: paginationAvailable, pagesCount: pagesCount, handleChangePage: handleChangePage, page: page };
};
