import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Location from '../Location/Location';
import { getDurationString } from '../../../Results/utils';
import FlightCard from '../../../FlightCard';
import TransferInfo from '../../../TransferInfo';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../utils';
var FlightInfoSegment = function (_a) {
    var _b;
    var _c, _d, _e, _f, _g;
    var className = _a.className, cardClassName = _a.cardClassName, transferClassName = _a.transferClassName, segment = _a.segment, nextSegment = _a.nextSegment, isMobileParam = _a.isMobile, isReturnFlight = _a.isReturnFlight;
    var css = useTheme('DesktopFlight').FlightInfoSegment;
    var currentSegment = segment.segment;
    var mobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var isMobile = mobile || isMobileParam;
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.isMobile] = isMobile,
            _b)) },
        React.createElement("div", { className: cn(css.card, cardClassName) },
            React.createElement("div", { className: css.locations },
                React.createElement(Location, __assign({ type: "departure", className: css.location, isMobile: isMobile }, currentSegment.departure)),
                React.createElement(Location, __assign({ type: "arrival", className: css.location, isMobile: isMobile }, currentSegment.arrival))),
            React.createElement(FlightCard, { className: css.flight, airline: currentSegment.operatingAirline, flightNumber: "".concat((_c = currentSegment.marketingAirline) === null || _c === void 0 ? void 0 : _c.iata, "-").concat(currentSegment.flightNumber), duration: getDurationString(currentSegment.duration), aircraft: currentSegment.aircraft, amenities: (_d = currentSegment.flightInfo) === null || _d === void 0 ? void 0 : _d.amenities, markers: (_e = currentSegment.flightInfo) === null || _e === void 0 ? void 0 : _e.markers, statistics: (_f = currentSegment.flightInfo) === null || _f === void 0 ? void 0 : _f.statistics, isReturnFlight: isReturnFlight })),
        (segment.transferDuration || ((_g = currentSegment === null || currentSegment === void 0 ? void 0 : currentSegment.stops) === null || _g === void 0 ? void 0 : _g.length) > 0) && (React.createElement(TransferInfo, { className: cn(css.transfer, transferClassName), segment: currentSegment, nextSegment: nextSegment, transferDuration: segment.transferDuration, showStops: true }))));
};
export default FlightInfoSegment;
