import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
export var getServicesWithPurchasePredictions = function (services) {
    var _a;
    return (((_a = services === null || services === void 0 ? void 0 : services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) || []).filter(function (service) {
        return !!service.aiPurchasePrediction &&
            [
                OrderAdditionalServiceGdsServiceServiceType.Baggage,
                OrderAdditionalServiceGdsServiceServiceType.Meal
            ].includes(service.type);
    });
};
export var getServiceWithMaxPrediction = function (services) {
    var servicesWithPredictions = getServicesWithPurchasePredictions(services);
    if (!servicesWithPredictions.length) {
        return null;
    }
    return servicesWithPredictions.reduce(function (prev, current) {
        var _a, _b, _c, _d;
        return ((_b = (_a = prev.aiPurchasePrediction) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.probability) > ((_d = (_c = current.aiPurchasePrediction) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.probability)
            ? prev
            : current;
    });
};
