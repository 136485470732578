import { createSelector } from 'reselect';
import { Unvoluntary } from '../types';
import { ExareReason } from '@websky/graphql';
import { getCustomer } from './customer/selectors';
import { getSelectedPassengersWithChildrenIds } from './passengers/selectors';
import { getSelectedReason } from './reason/selectors';
import { getRefundFiles } from './documents/selectors';
export var getState = function (state) { return state; };
export var getRefundMode = createSelector(getState, function (state) { return state.mode; });
export var isAutoRefundMode = createSelector(getSelectedReason, function (reason) { return reason === Unvoluntary.Unvoluntary; });
export var getIsRefundVoluntary = createSelector(getState, function (state) { return !!ExareReason[state.reason]; });
export var getRefundSeatReleaseMode = createSelector(getState, function (state) { return state.refundInfo.seatReleaseMode; });
export var createGetCreateRefundParams = function (orderId) {
    return createSelector(getCustomer, getSelectedPassengersWithChildrenIds, isAutoRefundMode, getSelectedReason, getRefundFiles, function (customer, selectedPassengersIds, isAutoRefundMode, selectedReason, refundFiles) {
        var _a;
        if (!customer) {
            return null;
        }
        var isVoluntary = selectedReason === Unvoluntary.Unvoluntary;
        return {
            orderId: orderId,
            isCompelled: !isVoluntary,
            passengers: selectedPassengersIds,
            email: customer.email,
            name: customer.name,
            phone: customer.phone,
            comment: (_a = customer.comments) !== null && _a !== void 0 ? _a : '',
            files: isVoluntary ? [] : refundFiles,
            reason: isVoluntary ? null : selectedReason
        };
    });
};
