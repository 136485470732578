import { TravellerDocumentFields } from '../../../types';
import { TravellerFieldEnum } from '@websky/graphql';
import { CisCountries } from '../../../consts';
export var generateCountriesMap = function (traveller, primaryCountryCodes) {
    var map = new Map();
    var countries = traveller === null || traveller === void 0 ? void 0 : traveller.values.find(function (field) { return field.name === TravellerFieldEnum.Nationality.toLowerCase(); }).validationRules[0].options;
    var filteredCISCountries = countries === null || countries === void 0 ? void 0 : countries.filter(function (country) { return CisCountries.includes(country.value); });
    if (primaryCountryCodes === null || primaryCountryCodes === void 0 ? void 0 : primaryCountryCodes.length) {
        var primaryCountries = countries === null || countries === void 0 ? void 0 : countries.filter(function (country) { return primaryCountryCodes.includes(country.value); }).reverse();
        primaryCountries === null || primaryCountries === void 0 ? void 0 : primaryCountries.forEach(function (country, index) { return map.set(country.label, index); });
    }
    var position = (primaryCountryCodes === null || primaryCountryCodes === void 0 ? void 0 : primaryCountryCodes.length) || 0;
    filteredCISCountries === null || filteredCISCountries === void 0 ? void 0 : filteredCISCountries.forEach(function (country, index) {
        if (!map.has(country.label)) {
            map.set(country.label, position + index);
        }
    });
    return map;
};
export var getCitizenships = function (traveller, primaryCountryCodes) {
    var _a;
    var cisCountriesMap = generateCountriesMap(traveller, primaryCountryCodes);
    var sortCountries = function (_a, _b) {
        var a = _a.label;
        var b = _b.label;
        if (cisCountriesMap.has(a) && !cisCountriesMap.has(b)) {
            return -1;
        }
        else if (cisCountriesMap.has(a) && cisCountriesMap.has(b)) {
            return cisCountriesMap.get(a) - cisCountriesMap.get(b);
        }
        return 0;
    };
    return (_a = traveller === null || traveller === void 0 ? void 0 : traveller.values.find(function (field) { return field.name === TravellerFieldEnum.Nationality.toLowerCase(); }).validationRules[0].options) === null || _a === void 0 ? void 0 : _a.sort(sortCountries);
};
export var getDocumentTypes = function (citizenship, validators) {
    if (!validators) {
        return [];
    }
    var docType = validators.values.find(function (value) { return value.name === TravellerDocumentFields.DocType; });
    var documentsForCitizenship = docType === null || docType === void 0 ? void 0 : docType.validationRules.find(function (rule) {
        var _a;
        return (_a = rule.with) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.name === TravellerDocumentFields.Citizenship && item.value === citizenship; });
    });
    if (documentsForCitizenship) {
        return documentsForCitizenship.options.map(function (item) { return item.value; });
    }
    else {
        return docType === null || docType === void 0 ? void 0 : docType.validationRules.find(function (rule) { return !rule.with; }).options.map(function (item) { return item.value; });
    }
};
export var getAvailabilityDocumentExpireDate = function (documentType, validators) {
    var _a, _b;
    var docExpireValidations = (_b = (_a = validators === null || validators === void 0 ? void 0 : validators.values) === null || _a === void 0 ? void 0 : _a.find(function (value) { return value.name === TravellerDocumentFields.DocExpiryDate; })) === null || _b === void 0 ? void 0 : _b.validationRules, docTypeRule = docExpireValidations === null || docExpireValidations === void 0 ? void 0 : docExpireValidations.find(function (validation) { var _a; return (_a = validation.with) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.name === TravellerDocumentFields.DocType && item.value === documentType; }); });
    return !(docTypeRule === null || docTypeRule === void 0 ? void 0 : docTypeRule.required);
};
