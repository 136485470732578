import { __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { getAeroexpress, getOrder } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import { ThemeProvider, useTheme } from '../../../theme';
import { currentCurrency, useIsMobile } from '../../../utils';
import icons from '../../icons';
import Modal from '../../../Modal';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { useAeroexpress } from '../../../Aeroexpress/components/hooks';
import { getAeroexpressPrice } from '../../../Checkout/store/orderPrice/selectors';
import AeroexpressPopup from '../AeroexpressPopup/AeroexpressPopup';
import { useRenderers } from '../../../renderProps';
import Close from '../Close/Close';
import AeroexpressSummary from '../../../ServiceSummary/services/AeroexpressSummary';
var AeroexpressAdditionalService = function () {
    var _a, _b, _c;
    var t = useTranslation('Checkout').t;
    var cardStyles = useTheme('CheckoutAdditionalService').Aeroexpress;
    var css = useTheme('Aeroexpress').Aeroexpress;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var aeroexpress = useSelector(getAeroexpress);
    var isSelected = ((_a = useSelector(getAeroexpressPrice)) === null || _a === void 0 ? void 0 : _a.amount) > 0;
    var selectedTrips = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.selectedTrips;
    var isMobile = useIsMobile();
    var minPrice = useMemo(function () {
        var e_1, _a;
        var _b, _c, _d, _e, _f;
        var minAmount = (_c = (_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips[0]) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount;
        try {
            for (var _g = __values(aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips), _h = _g.next(); !_h.done; _h = _g.next()) {
                var trip = _h.value;
                if (((_d = trip === null || trip === void 0 ? void 0 : trip.price) === null || _d === void 0 ? void 0 : _d.amount) < minAmount) {
                    minAmount = trip.price.amount;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_h && !_h.done && (_a = _g.return)) _a.call(_g);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return {
            amount: minAmount,
            currency: ((_f = (_e = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.price.currency) || currentCurrency
        };
    }, [aeroexpress]);
    var selectedTripsPrice = selectedTrips.reduce(function (price, selectedTrip) { return ({ amount: selectedTrip.price.amount + price.amount, currency: currentCurrency }); }, {
        amount: 0,
        currency: currentCurrency
    });
    var _d = __read(useSaveOrderServicesMutation(), 2), saveOrderServicesMutation = _d[0], loading = _d[1].loading;
    var selectAeroexpress = function (ids) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveOrderServicesMutation({
                            variables: {
                                params: {
                                    id: order.id,
                                    aeroexpress: { selectedTrips: __spreadArray([], __read(ids), false) }
                                }
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
                    close();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.log(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _e = useAeroexpress(selectAeroexpress, order.id), showWarning = _e.showWarning, servicesRedirectURL = _e.servicesRedirectURL, closeWarning = _e.closeWarning, buttonText = _e.buttonText, isOpen = _e.isOpen, close = _e.close, handleOpenModalClick = _e.handleOpenModalClick;
    var onAeroexpressItemRemove = function (_, payload) {
        if (aeroexpress.selectedTrips.length) {
            var filterTripIds = aeroexpress.selectedTrips
                .filter(function (trip) { return trip.id !== payload.tripId; })
                .map(function (trip) { return trip.id; });
            selectAeroexpress(filterTripIds);
        }
    };
    var renderClose = function (onClick) { return React.createElement(Close, { className: css.close, onClick: onClick }); };
    var AdditionalServiceRender = (_c = (_b = useRenderers()) === null || _b === void 0 ? void 0 : _b.CheckoutAdditionalService) === null || _c === void 0 ? void 0 : _c.CheckoutAdditionalService;
    var ServiceWrapper = AdditionalServiceRender !== null && AdditionalServiceRender !== void 0 ? AdditionalServiceRender : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(AeroexpressSummary, { travellers: order.travellers, selectedTrips: aeroexpress.selectedTrips, onServiceDelete: onAeroexpressItemRemove }, function (content) { return (React.createElement(ServiceWrapper, { isLoading: loading, className: cardStyles.aeroexpress, header: t('Aeroexpress'), isSelected: isSelected, priceFrom: minPrice, priceTotal: selectedTripsPrice, description: t('Aeroexpress:No worries about traffic jams, arrival at the airport strictly on schedule'), addClick: handleOpenModalClick, icon: icons.transferIcon, buttons: [
                {
                    action: handleOpenModalClick,
                    isLoading: loading,
                    text: buttonText,
                    buttonType: 'change'
                },
                !loading && {
                    action: function () { return open('https://aeroexpress.ru', '_blank'); },
                    text: t('Aeroexpress:View schedule')
                }
            ] },
            React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option: css.option
                        }
                    }
                } }, content))); }),
        React.createElement(Modal, { maxWidth: false, open: isOpen, slideUp: isMobile, classes: { scrollBody: css.aeroexpress__scrollBody, paper: css.aeroexpress_paper }, onClose: close, closeButtonRenderer: renderClose },
            React.createElement(AeroexpressPopup, { trips: aeroexpress.trips, selectedTrips: aeroexpress.selectedTrips, fromPrice: minPrice, isLoading: loading, onSubmit: selectAeroexpress, onClose: close })),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning })));
};
export default AeroexpressAdditionalService;
