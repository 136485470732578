import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPassengers } from '../../../store/order/selectors';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { addPassengerIds, removePassengerIds, setPassengerIds } from '../../../store/passengers/actions';
import CommonSelect from '../CommonSelect/CommonSelect';
import { passengerIcon } from '../icons';
import { getPassengerName } from '../utils';
import { ExchangeStep } from '../../../types';
var Passengers = function (_a) {
    var setStep = _a.setStep, skipPassengersStep = _a.skipPassengersStep;
    var t = useTranslation('Exchange').t;
    var dispatch = useDispatch();
    var passengers = useSelector(getPassengers);
    var selectedPassengerIds = useSelector(getSelectedPassengerIds);
    useEffect(function () {
        if (passengers.length === 1) {
            dispatch(setPassengerIds([passengers[0].id]));
            setStep(ExchangeStep.Reasons);
        }
        else if (skipPassengersStep && passengers.length) {
            dispatch(addPassengerIds(passengers.map(function (passenger) { return passenger.id; })));
            setStep(ExchangeStep.Segments);
        }
    }, [skipPassengersStep, passengers]);
    var selectedPassengerIdsExchange = React.useMemo(function () {
        return selectedPassengerIds.map(function (id) { return ({ key: id }); });
    }, [selectedPassengerIds]);
    var passengersAsItems = React.useMemo(function () {
        var passengersWithoutLink = passengers
            .filter(function (passenger) { return !passenger.linkedTraveller; })
            .map(function (passenger) { return ({
            value: getPassengerName(passenger),
            key: passenger.id,
            ids: [passenger.id]
        }); });
        passengers
            .filter(function (passengers) { return passengers.linkedTraveller; })
            .map(function (passenger) {
            var parent = passengersWithoutLink.find(function (parent) { return parent.key === passenger.linkedTraveller.id; });
            parent.value += ", ".concat(getPassengerName(passenger));
            parent.ids.push(passenger.id);
        });
        return passengersWithoutLink;
    }, [passengers]);
    var onChangeHandler = function (value, selected) {
        var passenger = passengersAsItems.find(function (passenger) { return passenger.key === value.key; });
        if (selected) {
            dispatch(addPassengerIds(passenger.ids));
        }
        else {
            dispatch(removePassengerIds(passenger.ids));
        }
    };
    var onNextHandler = function () { return setStep(ExchangeStep.Reasons); };
    return (React.createElement(CommonSelect, { name: t('Select passengers'), items: passengersAsItems, onChange: onChangeHandler, onNextHandler: onNextHandler, selectedKeys: selectedPassengerIdsExchange, icon: passengerIcon }));
};
export default Passengers;
