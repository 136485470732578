import { __read } from "tslib";
import { useCallback, useState } from 'react';
import { PromoCodeStatus } from './types';
export var usePromoCode = function (initialState) {
    var _a = __read(useState(false), 2), isFormOpen = _a[0], setFormOpen = _a[1];
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(initialState !== null && initialState !== void 0 ? initialState : PromoCodeStatus.Empty), 2), status = _c[0], setStatus = _c[1];
    var setLoading = useCallback(function () {
        setIsLoading(true);
    }, [isLoading]);
    var setNotLoading = useCallback(function () {
        setIsLoading(false);
    }, [isLoading]);
    var openForm = useCallback(function () {
        setFormOpen(true);
    }, [isFormOpen]);
    var closeForm = useCallback(function () {
        setFormOpen(false);
    }, [isFormOpen]);
    return {
        isFormOpen: isFormOpen,
        setFormOpen: setFormOpen,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        setLoading: setLoading,
        setNotLoading: setNotLoading,
        openForm: openForm,
        closeForm: closeForm,
        status: status,
        setStatus: setStatus
    };
};
