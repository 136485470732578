import { __values } from "tslib";
import { AviaPassengerType, TravellerFieldEnum } from '@websky/graphql';
export var getTravellerInfo = function (customer, travellers, travellerIds) {
    var customerValues = new Map(customer.values.map(function (v) { return [v.type, v.value]; }));
    var customerPhone = customerValues.get(TravellerFieldEnum.Phone);
    var customerEmail = customerValues.get(TravellerFieldEnum.Email);
    if ((customerPhone === null || customerPhone === void 0 ? void 0 : customerPhone.length) || (customerEmail === null || customerEmail === void 0 ? void 0 : customerEmail.length)) {
        var travellerInfo = {
            name: '',
            phone: customerPhone,
            email: customerEmail
        };
        var firstTraveller = getFilteredTravellers(travellers, travellerIds)[0];
        if (firstTraveller) {
            var firstTravellerValues = new Map(firstTraveller.values.map(function (v) { return [v.type, v.value]; }));
            travellerInfo.name = getTravellerName(firstTravellerValues.get(TravellerFieldEnum.FirstName), firstTravellerValues.get(TravellerFieldEnum.LastName));
        }
        return travellerInfo;
    }
    return getValidTravellerInfo(travellers, travellerIds);
};
function getFilteredTravellers(travellers, travellerIds) {
    return travellers.filter(function (t) { return travellerIds.includes(t.id) && t.type === AviaPassengerType.ADT; });
}
/** return first traveller, which contain phone or email*/
function getValidTravellerInfo(travellers, travellerIds) {
    var e_1, _a, e_2, _b;
    var _c;
    var returnValue = {
        name: '',
        phone: '',
        email: ''
    };
    var filledFields = [
        TravellerFieldEnum.FirstName,
        TravellerFieldEnum.LastName,
        TravellerFieldEnum.Phone,
        TravellerFieldEnum.Email
    ];
    var targetFields = [TravellerFieldEnum.Phone, TravellerFieldEnum.Email];
    var travellerFields = new Map();
    var travellerIsValid = false;
    try {
        for (var _d = __values(getFilteredTravellers(travellers, travellerIds)), _e = _d.next(); !_e.done; _e = _d.next()) {
            var traveller = _e.value;
            travellerFields.clear();
            try {
                for (var _f = (e_2 = void 0, __values(traveller.values)), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var value = _g.value;
                    if (filledFields.includes(value.type)) {
                        travellerFields.set(value.type, value.value);
                        if (targetFields.includes(value.type) && ((_c = value.value) === null || _c === void 0 ? void 0 : _c.length)) {
                            travellerIsValid = true;
                        }
                        if (travellerFields.size === filledFields.length) {
                            break;
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_2) throw e_2.error; }
            }
            if (travellerIsValid) {
                returnValue.name = getTravellerName(travellerFields.get(TravellerFieldEnum.FirstName), travellerFields.get(TravellerFieldEnum.LastName));
                returnValue.phone = travellerFields.get(TravellerFieldEnum.Phone);
                returnValue.email = travellerFields.get(TravellerFieldEnum.Email);
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return returnValue;
}
function getTravellerName(firstName, lastName) {
    return "".concat(lastName, " ").concat(firstName);
}
