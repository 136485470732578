import { __assign, __read, __spreadArray } from "tslib";
import { TravellerFieldEnum } from '@websky/graphql';
import { API_DATE_FORMAT, format, getUserValue } from '../utils';
import { parse } from 'date-fns';
export var getAvailableSegments = function (segments, passengers, servicesMap) {
    var availableSegments = [];
    var servicesSegmentIds = Object.keys(servicesMap);
    segments.forEach(function (segment) {
        if (servicesSegmentIds.includes(segment.id)) {
            var passengersArray_1 = [];
            var passengerIds_1 = Object.keys(servicesMap[segment.id]);
            var direction = [segment.departure.airport.city.name, segment.arrival.airport.city.name].join(' – ');
            passengers.forEach(function (passenger) {
                if (passengerIds_1.includes(passenger.id)) {
                    var firstName = getUserValue(passenger, TravellerFieldEnum.FirstName), lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
                    var name = [firstName === null || firstName === void 0 ? void 0 : firstName.toLowerCase(), lastName === null || lastName === void 0 ? void 0 : lastName.toLowerCase()].filter(Boolean).join(' ');
                    passengersArray_1 = __spreadArray(__spreadArray([], __read(passengersArray_1), false), [
                        {
                            passengerId: passenger.id,
                            segmentId: segment.id,
                            serviceId: servicesMap[segment.id][passenger.id],
                            name: name
                        }
                    ], false);
                }
            });
            availableSegments = __spreadArray(__spreadArray([], __read(availableSegments), false), [
                {
                    segmentId: segment.id,
                    passengers: passengersArray_1,
                    direction: direction,
                    departure: segment.departure.airport.city.name,
                    arrival: segment.arrival.airport.city.name,
                    date: format(parse(segment.departure.date, API_DATE_FORMAT, new Date()), 'dd MMM')
                }
            ], false);
        }
    });
    return availableSegments;
};
export var computeServicesMap = function (services, selectedServices) {
    var servicesMap = {};
    var _loop_1 = function (passengerId) {
        var _loop_2 = function (serviceId) {
            var includeService = services.some(function (service) { return service.id === serviceId; });
            if (!includeService) {
                return "continue";
            }
            selectedServices[passengerId][serviceId].forEach(function (selectedService) {
                selectedService.segments.forEach(function (segmentId) {
                    var _a;
                    servicesMap[segmentId] = __assign(__assign({}, servicesMap[segmentId]), (_a = {}, _a[passengerId] = serviceId, _a));
                });
            });
        };
        for (var serviceId in selectedServices[passengerId]) {
            _loop_2(serviceId);
        }
    };
    for (var passengerId in selectedServices) {
        _loop_1(passengerId);
    }
    services.forEach(function (service) {
        var segmentIds = new Set(service.allowedSegments.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
        var passengerIds = new Set(service.allowedPassengers.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
        segmentIds.forEach(function (segmentId) {
            passengerIds.forEach(function (passengerId) {
                var _a;
                var _b;
                var existServiceId = (_b = servicesMap[segmentId]) === null || _b === void 0 ? void 0 : _b[passengerId];
                if (!existServiceId || existServiceId !== service.id) {
                    servicesMap[segmentId] = __assign(__assign({}, servicesMap[segmentId]), (_a = {}, _a[passengerId] = service.id, _a));
                }
            });
        });
    });
    return servicesMap;
};
export var getNewSelectedServices = function (serviceMap, selectedServicesState, allSegments) {
    var selectedServices = [];
    var selectedSegments = Object.keys(selectedServicesState);
    var availableSegments = Object.keys(serviceMap);
    if (allSegments) {
        var firstSegment_1 = selectedSegments[0];
        var selectedPassengers = (selectedServicesState === null || selectedServicesState === void 0 ? void 0 : selectedServicesState[firstSegment_1])
            ? Object.keys(selectedServicesState[firstSegment_1])
            : [];
        selectedPassengers.forEach(function (passenger) {
            availableSegments.forEach(function (segmentId) {
                selectedServices.push({
                    count: selectedServicesState[firstSegment_1][passenger],
                    serviceId: serviceMap[firstSegment_1][passenger],
                    segmentIds: [segmentId],
                    passengerId: passenger
                });
            });
        });
    }
    else {
        availableSegments.forEach(function (segment) {
            var passengers = Object.keys(serviceMap[segment]);
            passengers.forEach(function (passenger) {
                var _a;
                if ((_a = selectedServicesState[segment]) === null || _a === void 0 ? void 0 : _a.hasOwnProperty(passenger)) {
                    selectedServices.push({
                        serviceId: serviceMap[segment][passenger],
                        segmentIds: [segment],
                        count: selectedServicesState[segment][passenger],
                        passengerId: passenger
                    });
                }
            });
        });
    }
    return selectedServices;
};
export var getIsDifferentServicesOrPassengers = function (servicesMap) {
    var _a, _b, _c;
    var serviceIdsBySegmentMap = new Map();
    var passengerIdsBySegmentMap = new Map();
    if (((_a = Object.keys(servicesMap !== null && servicesMap !== void 0 ? servicesMap : {})) === null || _a === void 0 ? void 0 : _a.length) > 1) {
        for (var segmentId in servicesMap) {
            for (var passengerId in servicesMap[segmentId]) {
                var serviceId = servicesMap[segmentId][passengerId];
                var serviceIds = __spreadArray([], __read(((_b = serviceIdsBySegmentMap.get(segmentId)) !== null && _b !== void 0 ? _b : [])), false);
                var passengerIds = __spreadArray([], __read(((_c = passengerIdsBySegmentMap.get(segmentId)) !== null && _c !== void 0 ? _c : [])), false);
                serviceIdsBySegmentMap.set(segmentId, new Set(__spreadArray(__spreadArray([], __read(serviceIds), false), [serviceId], false)));
                passengerIdsBySegmentMap.set(segmentId, new Set(__spreadArray(__spreadArray([], __read(passengerIds), false), [passengerId], false)));
            }
        }
        var serviceIdsBySegment_1 = __spreadArray([], __read(serviceIdsBySegmentMap.values()), false);
        var isDifferentServices = serviceIdsBySegment_1.some(function (serviceIds) { return JSON.stringify(__spreadArray([], __read(serviceIds), false)) !== JSON.stringify(__spreadArray([], __read(serviceIdsBySegment_1[0]), false)); });
        if (isDifferentServices) {
            return true;
        }
        var passengerIdsBySegment_1 = __spreadArray([], __read(passengerIdsBySegmentMap.values()), false);
        var isDifferentPassengers = passengerIdsBySegment_1.some(function (passengerIds) { return JSON.stringify(__spreadArray([], __read(passengerIds), false)) !== JSON.stringify(__spreadArray([], __read(passengerIdsBySegment_1[0]), false)); });
        if (isDifferentPassengers) {
            return true;
        }
    }
    return false;
};
export var getVipServiceStateKeys = function (state) {
    var keys = new Set();
    for (var segmentId in state) {
        for (var passengerId in state[segmentId]) {
            var serviceCount = state[segmentId][passengerId];
            keys.add("".concat(segmentId, "-").concat(passengerId, "-").concat(serviceCount));
        }
    }
    return keys;
};
