export var PromoCodeStatus;
(function (PromoCodeStatus) {
    PromoCodeStatus[PromoCodeStatus["Empty"] = 0] = "Empty";
    PromoCodeStatus[PromoCodeStatus["Applied"] = 1] = "Applied";
    PromoCodeStatus[PromoCodeStatus["NotApplied"] = 2] = "NotApplied";
    PromoCodeStatus[PromoCodeStatus["Provided"] = 3] = "Provided";
})(PromoCodeStatus || (PromoCodeStatus = {}));
export var PromoCodeFieldType;
(function (PromoCodeFieldType) {
    PromoCodeFieldType["Text"] = "text";
    PromoCodeFieldType["Checkbox"] = "checkbox";
})(PromoCodeFieldType || (PromoCodeFieldType = {}));
