import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { ExareStatus, useUpdateOrderMutation } from '@websky/graphql';
import { getOrder, getTravellers } from '../Checkout/store/order/selectors';
export var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Failed"] = "failed";
})(PaymentResult || (PaymentResult = {}));
export var useEditPassenger = function (refetchOrder, returnToOrder, passengerId) {
    var order = useSelector(getOrder);
    var travellers = useSelector(getTravellers);
    var _a = __read(useUpdateOrderMutation(), 1), updateOrder = _a[0];
    var history = useHistory();
    var match = useRouteMatch();
    var baseUrl = match.url;
    var requestUrl = "".concat(baseUrl, "/request");
    var paymentUrl = "".concat(baseUrl, "/payment");
    var successUrl = "".concat(baseUrl, "/payment/success");
    var failedUrl = "".concat(baseUrl, "/payment/failed");
    var _b = __read(useState(function () {
        var _a, _b, _c;
        if ((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder) {
            var changeOrder = order.exareInfo.lastChangeTravellerDataOrder;
            if (((_c = (_b = changeOrder.travellers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) === passengerId) {
                return changeOrder;
            }
        }
        return null;
    }), 2), changePaxOrder = _b[0], setChangePaxOrder = _b[1];
    useEffect(function () {
        var _a, _b;
        var isInProcessOrAwaitingPayment = [ExareStatus.InProcess, ExareStatus.AwaitingPayment].includes((_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder) === null || _b === void 0 ? void 0 : _b.status);
        if (isInProcessOrAwaitingPayment) {
            onRefetchOrder();
        }
    }, []);
    var _c = __read(useState(null), 2), reason = _c[0], setReason = _c[1];
    var _d = __read(React.useState(null), 2), changeTravellers = _d[0], setChangeTravellers = _d[1];
    var _e = __read(React.useState(null), 2), prices = _e[0], setPrices = _e[1];
    var _f = __read(React.useState(null), 2), paymentStatus = _f[0], setPaymentStatus = _f[1];
    var _g = __read(React.useState(false), 2), isLoading = _g[0], setIsLoading = _g[1];
    var isRefused = (changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.status) === ExareStatus.Refused;
    var isFinished = (changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.status) === ExareStatus.Finished;
    var isAwaitingPayment = (changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.status) === ExareStatus.AwaitingPayment;
    var isPassengerRouteAvailable = !changePaxOrder || isRefused || isFinished;
    var isRequestRouteAvailable = (!!changePaxOrder || !!(changeTravellers === null || changeTravellers === void 0 ? void 0 : changeTravellers.length)) && !(isRefused || isFinished);
    var isPaymentRouteAvailable = !!changePaxOrder && isAwaitingPayment;
    var passenger = React.useMemo(function () {
        var _a;
        if ((_a = changePaxOrder === null || changePaxOrder === void 0 ? void 0 : changePaxOrder.travellers) === null || _a === void 0 ? void 0 : _a.length) {
            return travellers.find(function (traveller) { return traveller.id === changePaxOrder.travellers[0].id; });
        }
        return travellers.find(function (traveller) { return traveller.id === passengerId; });
    }, [travellers, passengerId, changePaxOrder]);
    var onRefetchOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order, lastChangeTravellerOrder;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, refetchOrder()];
                case 1:
                    order = _d.sent();
                    lastChangeTravellerOrder = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder;
                    if (lastChangeTravellerOrder && ((_c = (_b = lastChangeTravellerOrder.travellers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) === passengerId) {
                        setChangePaxOrder(lastChangeTravellerOrder);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onReturnToOrder = function (refetch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastChangeTravellerDataOrder) || changePaxOrder || refetch)) return [3 /*break*/, 5];
                    setIsLoading(true);
                    if (!refetch) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateOrder({
                            variables: { id: order.id }
                        })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [4 /*yield*/, refetchOrder()];
                case 3:
                    _b.sent();
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                case 4:
                    _b.sent();
                    setIsLoading(false);
                    _b.label = 5;
                case 5:
                    returnToOrder();
                    return [2 /*return*/];
            }
        });
    }); };
    var onGoToForm = function () {
        setChangePaxOrder(null);
        history.push(baseUrl);
    };
    var onGoToRequest = function (refetch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!refetch) return [3 /*break*/, 2];
                    return [4 /*yield*/, onRefetchOrder()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    history.push(requestUrl);
                    return [2 /*return*/];
            }
        });
    }); };
    var onGoToPayment = function () {
        history.push(paymentUrl);
    };
    var onFillOrder = function (travellers, prices) {
        if (changePaxOrder) {
            setChangePaxOrder(null);
        }
        if (travellers) {
            setChangeTravellers(travellers);
        }
        if (prices) {
            setPrices(prices);
        }
        onGoToRequest();
    };
    return {
        order: order,
        travellers: travellers,
        urls: {
            baseUrl: baseUrl,
            requestUrl: requestUrl,
            paymentUrl: paymentUrl,
            successUrl: successUrl,
            failedUrl: failedUrl
        },
        availableRoutes: {
            passenger: isPassengerRouteAvailable,
            request: isRequestRouteAvailable,
            payment: isPaymentRouteAvailable
        },
        passenger: passenger,
        changePaxOrder: changePaxOrder,
        changeTravellers: changeTravellers,
        prices: prices,
        paymentStatus: paymentStatus,
        reason: reason,
        isLoading: isLoading,
        onRefetchOrder: onRefetchOrder,
        onReturnToOrder: onReturnToOrder,
        onFillOrder: onFillOrder,
        onGoToForm: onGoToForm,
        onGoToRequest: onGoToRequest,
        onGoToPayment: onGoToPayment,
        setChangePaxOrder: setChangePaxOrder,
        setPaymentStatus: setPaymentStatus,
        setReason: setReason
    };
};
