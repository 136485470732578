import { __assign, __decorate, __extends } from "tslib";
import * as React from 'react';
import { withTheme } from '../../theme';
import classnames from 'classnames';
import { Money } from '../../../Money';
var Matrix = /** @class */ (function (_super) {
    __extends(Matrix, _super);
    function Matrix(props) {
        var _this = _super.call(this, props) || this;
        _this.refsArray = [];
        _this.rowIdHoveredCell = -1;
        _this.colIdHoveredRow = -1;
        _this.hoverCalculate = _this.hoverCalculate.bind(_this);
        _this.outsideHandler = _this.outsideHandler.bind(_this);
        _this.rowClick = _this.rowClick.bind(_this);
        return _this;
    }
    Matrix.prototype.hoverCalculate = function (e) {
        var item = document.elementFromPoint(e.clientX, e.clientY).closest(".".concat(this.props.theme.MatrixStyles.day)), itemRowId = parseInt(item.getAttribute('row-id')), itemColId = parseInt(item.getAttribute('col-id'));
        if (this.rowIdHoveredCell === itemRowId && this.colIdHoveredRow === itemColId) {
            return;
        }
        this.rowIdHoveredCell = itemRowId;
        this.colIdHoveredRow = itemColId;
        if (item.classList.contains(this.props.theme.MatrixStyles.day) &&
            !item.classList.contains(this.props.theme.MatrixStyles.empty)) {
            this.fillBackground(itemRowId, itemColId);
        }
        else {
            this.fillBackground(-1, -1);
        }
    };
    Matrix.prototype.outsideHandler = function () {
        this.fillBackground(-1, -1);
    };
    Matrix.prototype.fillBackground = function (itemRowId, itemColId) {
        var _this = this;
        if (this.refsArray) {
            this.refsArray.map(function (items, rowId) {
                if (_this.vertical && _this.vertical.childNodes[rowId]) {
                    var node = _this.vertical.childNodes[rowId];
                    if (rowId === itemRowId) {
                        node.classList.add(_this.props.theme.MatrixStyles.highlighted);
                    }
                    else {
                        node.classList.remove(_this.props.theme.MatrixStyles.highlighted);
                    }
                }
                if (_this.horizontal && _this.horizontal.childNodes[rowId]) {
                    var node = _this.horizontal.childNodes[rowId];
                    if (rowId === itemColId) {
                        node.classList.add(_this.props.theme.MatrixStyles.highlighted);
                    }
                    else {
                        node.classList.remove(_this.props.theme.MatrixStyles.highlighted);
                    }
                }
                items.map(function (dateDiv, colId) {
                    if (dateDiv) {
                        if ((rowId <= itemRowId && colId === itemColId) ||
                            (rowId === itemRowId && colId <= itemColId)) {
                            dateDiv.classList.add(_this.props.theme.MatrixStyles.highlighted);
                        }
                        else {
                            dateDiv.classList.remove(_this.props.theme.MatrixStyles.highlighted);
                        }
                    }
                });
            });
        }
    };
    Matrix.prototype.componentDidMount = function () {
        if (this.containerRef) {
            this.containerRef.addEventListener('mousemove', this.hoverCalculate);
            this.containerRef.addEventListener('mouseleave', this.outsideHandler);
        }
    };
    Matrix.prototype.componentWillUnmount = function () {
        if (this.containerRef) {
            this.containerRef.removeEventListener('mousemove', this.hoverCalculate);
            this.containerRef.removeEventListener('mouseleave', this.outsideHandler);
        }
    };
    Matrix.prototype.rowClick = function (outboundDate, returnDate, price) {
        if (price.money && !price.isLoading && this.props.onDateSelect) {
            this.props.onDateSelect(outboundDate, returnDate);
        }
    };
    Matrix.prototype.render = function () {
        var _this = this;
        this.refsArray = [];
        var averagePrice = (this.props.maxPrice.amount + this.props.minPrice.amount) / 2, style = this.props.theme.MatrixStyles;
        return (React.createElement("div", { className: style.matrix },
            React.createElement("div", { className: style.header }, "Price for round trip"),
            this.props.horizontalHeader(function (ref) { return (_this.horizontal = ref); }),
            this.props.verticalHeader(function (ref) { return (_this.vertical = ref); }),
            React.createElement("div", { className: style.container, ref: function (ref) { return (_this.containerRef = ref); } }, this.props.dates.map(function (outboundDate, outboundIndex) {
                _this.refsArray.push([]);
                return (React.createElement("div", { key: outboundIndex, className: style.wrapper }, outboundDate.returnDates.map(function (returnDate, returnIndex) {
                    var _a, _b, _c;
                    var dateStr = "".concat(outboundDate.outboundDate.getTime(), "-").concat(returnDate.getTime()), price = _this.props.prices.hasOwnProperty(dateStr)
                        ? _this.props.prices[dateStr]
                        : null, money = price ? price.money : null, attr = { 'row-id': outboundIndex, 'col-id': returnIndex };
                    return (React.createElement("div", __assign({ key: returnIndex, ref: function (ref) {
                            if (_this.refsArray.length > outboundIndex) {
                                if (_this.refsArray[outboundIndex].length > returnIndex) {
                                    _this.refsArray[outboundIndex][returnIndex] = ref;
                                }
                                else {
                                    _this.refsArray[outboundIndex].push(ref);
                                }
                            }
                        }, className: classnames(style.day, (_a = {},
                            _a[style.lowerThanAverage] = money && money.amount < averagePrice,
                            _a), (_b = {},
                            _b[style.empty] = !price || !money,
                            _b), (_c = {},
                            _c[style.day_loading] = price && price.isLoading,
                            _c)) }, attr, { onClick: function () { return _this.rowClick(outboundDate.outboundDate, returnDate, price); } }), money && React.createElement(Money, { money: price.money, moneyClassName: style.money })));
                })));
            }))));
    };
    Matrix = __decorate([
        withTheme
    ], Matrix);
    return Matrix;
}(React.Component));
export { Matrix };
