import { __assign } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { iataWithAdditionalQueryForServices, useAdditionalQueryForServices, useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { useSelector } from 'react-redux';
import { getAnimalServices, getBaggageMinPrice, getBaggageServices, getBrandIncludedBaggage, getOrder, getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { useRenderers } from '../../../renderProps';
import { AdditionalServiceType } from '../../types';
import { getTravellersMap, initI18n } from '../../../utils';
import BaggageSummary from '../../../ServiceSummary/services/BaggageSummary';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useConfig } from '../../../context';
import { useIsCanModifyServices } from '../../hooks';
import BaggageContextProvider from '../../../Baggage/context';
import { useCartPrices } from '../../../Cart/hooks';
import SimpleLoader from '../../../SimpleLoader';
import { useIataCode } from '../../../hooks';
initI18n('BaggageService');
var BaggageAdditionalService = function () {
    var _a;
    var t = useTranslation('Checkout').t;
    var minPrice = useSelector(getBaggageMinPrice);
    var css = useTheme('CheckoutAdditionalService').CheckoutAdditionalServiceBaggage;
    var servicesAvailable = useConfig().global.servicesAvailable;
    var iataCode = useIataCode();
    var order = useSelector(getOrder);
    var baggageServices = useSelector(getBaggageServices);
    var includedBrandServices = useSelector(getBrandIncludedBaggage);
    var servicePageProps = useServicePageProps(ServiceType.Baggage);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var _b = useUpsaleWarning(), showWarning = _b.showWarning, closeWarning = _b.closeWarning, toggleWarningIfOrderConfirmed = _b.toggleWarningIfOrderConfirmed;
    var handleClick = shouldRedirect
        ? toggleWarningIfOrderConfirmed
        : servicePageProps.toggleModal.bind(null, ServiceType.Baggage);
    var animalServices = useSelector(getAnimalServices);
    var baggageTotal = useCartPrices().baggage;
    var onDeleteItem = useCart().onDeleteItem;
    var isCanModifyServices = useIsCanModifyServices(baggageServices);
    var _c = useAdditionalQueryForServices(), isAdditionalServicesLoading = _c.isLoading, loadAndDispatchAdditionalServices = _c.loadAndDispatch;
    var buttons = [
        {
            text: isCanModifyServices ? t('Change') : t('More details'),
            action: function () {
                if (iataWithAdditionalQueryForServices.includes(iataCode)) {
                    loadAndDispatchAdditionalServices();
                }
                servicePageProps.toggleModal(ServiceType.Baggage);
            },
            buttonType: 'change'
        }
    ];
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    if (!!(servicesAvailable === null || servicesAvailable === void 0 ? void 0 : servicesAvailable.animal) &&
        !includedBrandServices.length &&
        baggageServices.length === animalServices.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(BaggageSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onDeleteItem }, function (content) { return (React.createElement(ServiceWrapper, { addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, buttons: buttons, icon: icons.baggageIcon, className: css.baggage, priceFrom: minPrice, isSelected: !!content, header: t('Baggage'), description: t('Transportation of impressions no limitations'), priceTotal: baggageTotal, serviceType: AdditionalServiceType.Baggage, addButtonText: !isCanModifyServices ? t('More details') : null }, content)); }),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        isAdditionalServicesLoading && React.createElement(SimpleLoader, null),
        React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(BaggageContextProvider, { value: { isReadOnly: !isCanModifyServices } },
            React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: isAdditionalServicesLoading ? null : servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels, analyticsMeta: servicePageProps.analyticsMeta }, childrenProps, { baggageTotalPrice: baggageTotal })))); })));
};
export default BaggageAdditionalService;
