import { __assign, __rest } from "tslib";
import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import cn from 'classnames';
import { useComputedStyleProperty } from '../../hooks';
import { Included } from '../../../Icons';
import { useTheme } from '../../../theme';
var Input = function (_a) {
    var _b, _c;
    var className = _a.className, _d = _a.variant, variant = _d === void 0 ? 'labelIn' : _d, label = _a.label, InputLabelProps = _a.InputLabelProps, InputProps = _a.InputProps, FormHelperTextProps = _a.FormHelperTextProps, isValid = _a.isValid, classes = _a.classes, restProps = __rest(_a, ["className", "variant", "label", "InputLabelProps", "InputProps", "FormHelperTextProps", "isValid", "classes"]);
    var theme = useTheme('BaseComponents').Input;
    var _e = useComputedStyleProperty('--variant', variant), inputVariant = _e.value, elRef = _e.elRef;
    var labelClasses = InputLabelProps === null || InputLabelProps === void 0 ? void 0 : InputLabelProps.classes;
    var inputClasses = InputProps === null || InputProps === void 0 ? void 0 : InputProps.classes;
    var helperTextClasses = FormHelperTextProps === null || FormHelperTextProps === void 0 ? void 0 : FormHelperTextProps.classes;
    var muiVariant = inputVariant === 'underline' ? 'filled' : 'outlined';
    var variantClasses = useMemo(function () {
        if (muiVariant === 'outlined') {
            return {
                notchedOutline: cn(theme.notchedOutline, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.notchedOutline)
            };
        }
        return {};
    }, [muiVariant]);
    return (React.createElement(TextField, __assign({ className: cn(className, theme[inputVariant], (_b = {},
            _b[classes === null || classes === void 0 ? void 0 : classes.underline] = inputVariant === 'underline',
            _b[classes === null || classes === void 0 ? void 0 : classes.labelIn] = inputVariant === 'labelIn',
            _b[classes === null || classes === void 0 ? void 0 : classes.labelOut] = inputVariant === 'labelOut',
            _b[classes === null || classes === void 0 ? void 0 : classes.labelOnLine] = inputVariant === 'labelOnLine',
            _b[classes === null || classes === void 0 ? void 0 : classes.withoutLabel] = !label,
            _b[theme.withoutLabel] = !label,
            _b)), variant: muiVariant, label: label, InputLabelProps: __assign(__assign({}, InputLabelProps), { classes: {
                root: cn(theme.label, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.root),
                focused: cn(theme.focused, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.focused),
                disabled: cn(theme.disabled, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.disabled),
                error: cn(theme.error, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.error),
                shrink: cn(theme.shrink, labelClasses === null || labelClasses === void 0 ? void 0 : labelClasses.shrink)
            } }), InputProps: __assign(__assign({}, InputProps), { classes: __assign({ root: cn(theme.input, theme[muiVariant], inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.root, (_c = {},
                    _c[classes === null || classes === void 0 ? void 0 : classes.outlined] = muiVariant === 'outlined',
                    _c[classes === null || classes === void 0 ? void 0 : classes.filled] = muiVariant === 'filled',
                    _c[theme.valid] = isValid,
                    _c)), focused: cn(theme.focused, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.focused), disabled: cn(theme.disabled, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.disabled), error: cn(theme.error, inputClasses === null || inputClasses === void 0 ? void 0 : inputClasses.error) }, variantClasses), endAdornment: (InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment) ? (React.createElement(React.Fragment, null,
                isValid && React.createElement("div", { className: theme.valid_icon }, Included),
                InputProps.endAdornment)) : undefined }), FormHelperTextProps: __assign(__assign({}, FormHelperTextProps), { classes: {
                root: cn(theme.helperText, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.root),
                focused: cn(theme.focused, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.root),
                disabled: cn(theme.disabled, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.root),
                error: cn(theme.error, helperTextClasses === null || helperTextClasses === void 0 ? void 0 : helperTextClasses.error)
            } }), ref: elRef }, restProps)));
};
export default Input;
