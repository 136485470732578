import { __assign } from "tslib";
import { ADD_PASSENGER, REMOVE_PASSENGER, SET_PASSENGERS } from './actions';
export var MAX_PASSENGERS_COUNT = 9;
var defaultState = {
    ADT: 1,
    PDA: 0,
    CLD: 0,
    INF: 0,
    INS: 0
};
export var passengersReducer = function (state, _a) {
    var _b, _c;
    if (state === void 0) { state = defaultState; }
    var payload = _a.payload, type = _a.type;
    switch (type) {
        case ADD_PASSENGER:
            return __assign(__assign({}, state), (_b = {},
                _b[payload] = state[payload] =
                    (state[payload] || 0) + 1,
                _b));
        case REMOVE_PASSENGER:
            return __assign(__assign({}, state), (_c = {}, _c[payload] = state[payload] -= 1, _c));
        case SET_PASSENGERS:
            return __assign(__assign({}, state), payload);
        default:
            return state;
    }
};
