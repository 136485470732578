import { __assign } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import Input from '../../Input';
import { IconButton } from '../../index';
import { useTheme } from '../../theme';
import * as icons from '../../Icons';
import { PromoCodeStatus } from '../types';
import FormField from './Field/Field';
var PromoCodeForm = function (props) {
    var t = useTranslation('PromoCode').t;
    var theme = useTheme('PromoCode').PromoCodeForm;
    return (React.createElement(Form, { onSubmit: props.onSubmit }, function (_a) {
        var _b, _c;
        var _d;
        var handleSubmit = _a.handleSubmit;
        return (React.createElement("form", { onSubmit: handleSubmit, className: theme.form },
            React.createElement(Field, { name: 'promoCode', validate: function (value) {
                    if (!value || !value.trim()) {
                        return t('Required');
                    }
                    else if (value.trim().length > 10) {
                        return t('The number of characters must not exceed {{number}}', { number: 10 });
                    }
                } }, function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(Input, { absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, input), { label: t('Enter a promo code'), type: 'input', onChange: function (e) {
                            var _a;
                            input.onChange(e);
                            (_a = props.onChangePromoCode) === null || _a === void 0 ? void 0 : _a.call(props, e.target.value);
                        }, error: meta.touched && !meta.valid, helperText: meta.touched && !meta.valid ? meta.error : null }) }));
            }),
            ((_d = props.additionalFields) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement("div", { className: theme.form_additionalFields }, props.additionalFields.map(function (field, index) { return (React.createElement(FormField, __assign({ key: index }, field))); }))),
            React.createElement("div", { className: cn(theme.statusMessage, (_b = {},
                    _b[theme.statusMessage_applied] = props.status === PromoCodeStatus.Applied,
                    _b[theme.statusMessage_notApplied] = props.status === PromoCodeStatus.NotApplied,
                    _b)) },
                React.createElement(IconButton, { type: 'submit', className: cn(theme.button, (_c = {},
                        _c[theme.button_empty] = props.status === PromoCodeStatus.Empty,
                        _c[theme.button_applied] = props.status === PromoCodeStatus.Applied,
                        _c)), isLoading: props.isLoading, isSuccess: props.status === PromoCodeStatus.Applied, "data-promocode-submit-button": true }, (props.status === PromoCodeStatus.Empty || props.status === PromoCodeStatus.NotApplied) &&
                    icons.ArrowForward),
                !props.isLoading && (React.createElement("span", { className: theme.statusText }, props.status === PromoCodeStatus.Applied && t('Promo code applied'))))));
    }));
};
export default PromoCodeForm;
