import * as React from 'react';
import Switch from '../../../../../Switch';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
var StayLoggedIn = function (_a) {
    var isActive = _a.isActive, setActive = _a.setActive;
    var theme = useTheme('LoginPage').StayLoggedIn, t = useTranslation('LoginPage').t;
    var onClickHandler = function () {
        setActive(!isActive);
    };
    return (React.createElement(Switch, { mainClassName: theme.rememberMe, labelClassName: theme.rememberMeLabel, activeClassName: theme.rememberMeIsActive, switchPosition: "right", label: t('Remember me'), isActive: isActive, onClick: onClickHandler }));
};
export default StayLoggedIn;
