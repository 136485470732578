export var AdditionalServiceType;
(function (AdditionalServiceType) {
    AdditionalServiceType[AdditionalServiceType["Seat"] = 0] = "Seat";
    AdditionalServiceType[AdditionalServiceType["Meal"] = 1] = "Meal";
    AdditionalServiceType[AdditionalServiceType["Baggage"] = 2] = "Baggage";
    AdditionalServiceType[AdditionalServiceType["Transfer"] = 3] = "Transfer";
    AdditionalServiceType[AdditionalServiceType["Insurance"] = 4] = "Insurance";
    AdditionalServiceType[AdditionalServiceType["BusinessLounge"] = 5] = "BusinessLounge";
    AdditionalServiceType[AdditionalServiceType["PriorityBoarding"] = 6] = "PriorityBoarding";
})(AdditionalServiceType || (AdditionalServiceType = {}));
