import { __read } from "tslib";
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import AddPassengerForm from '../../../../../AddPassenger/components/AddPassengerForm/AddPassengerForm';
import SlideBottom from '../../../../../SlideBottom/components/SlideBottom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
var AddPassengerWrapper = function (props) {
    var _a = __read(React.useState(props.isOpen || false), 2), open = _a[0], setOpen = _a[1];
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var t = useTranslation('Checkout').t;
    var theme = useTheme('Checkout').GroupOrder;
    var onLabelClick = function (e) {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };
    var initialValues = props.passenger
        ? {
            lastName: props.passenger.passengerName,
            id: props.passenger.passengerId
        }
        : null;
    return open && !isMobile ? (React.createElement("div", { className: theme.editPassenger },
        React.createElement(AddPassengerForm, { onSubmit: function (lastName, id) {
                var _a;
                return props.onAddPassenger(lastName, id, (_a = props.passenger) === null || _a === void 0 ? void 0 : _a.id);
            }, onSuccess: function () { return setOpen(false); }, initialValues: initialValues, hideIcon: true, buttonText: t(props.passenger ? 'Done' : 'Add'), validationType: "ticketNumber" }))) : (React.createElement(React.Fragment, null,
        isMobile && (React.createElement(SlideBottom, { isOpen: open, onClose: function () { return setOpen(false); } },
            React.createElement(AddPassengerForm, { onSubmit: function (lastName, id) {
                    var _a;
                    return props.onAddPassenger(lastName, id, (_a = props.passenger) === null || _a === void 0 ? void 0 : _a.id);
                }, onSuccess: function () { return setOpen(false); }, initialValues: initialValues, hideIcon: true, buttonText: t(props.passenger ? 'Done' : 'Add'), validationType: "ticketNumber" }))),
        React.createElement("div", { onClick: onLabelClick }, props.children)));
};
export default AddPassengerWrapper;
