import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTranslation } from 'react-i18next';
import { Money } from '../../Money';
import { Currency } from '../../enums';
import { initI18n } from '../../utils';
import { useTheme } from '../../theme';
import { arrowIcon } from '../../Passengers/icons';
import { FlightClass } from '@websky/graphql';
initI18n('FlightPrice');
var FlightPrice = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.variant, variant = _f === void 0 ? 'common' : _f, _g = _a.isActive, isActive = _g === void 0 ? false : _g, _h = _a.serviceClass, serviceClass = _h === void 0 ? FlightClass.Economy : _h, onClick = _a.onClick, price = _a.price, className = _a.className, singleFare = _a.singleFare;
    var css = useTheme('FlightPrice').FlightPrice;
    var t = useTranslation('FlightPrice').t;
    return (React.createElement(ButtonBase, { onClick: onClick, disableRipple: variant === 'arrowed' || !price, className: cn(css.wrapper, (_b = {},
            _b[css.wrapper_arrowed] = variant === 'arrowed',
            _b[css.wrapper_flat] = variant === 'flat',
            _b[css.wrapper_economy] = serviceClass === FlightClass.Economy,
            _b[css.wrapper_business] = serviceClass === FlightClass.Business,
            _b[css.wrapper_active] = isActive,
            _b[css.wrapper_disabled] = !price,
            _b[css.wrapper_singleFare] = singleFare,
            _b), className) },
        React.createElement("div", { className: css.titles },
            React.createElement("div", { className: cn(css.title, (_c = {},
                    _c[css.title_singleFare] = singleFare,
                    _c)) }, t(serviceClass)),
            React.createElement("div", { className: cn(css.prefix, (_d = {},
                    _d[css.prefix_noSeats] = !price,
                    _d[css.prefix_singleFare] = singleFare,
                    _d)) }, price ? t('from') : t('NO SEATS'))),
        price && (React.createElement("div", { className: cn(css.price, (_e = {},
                _e[css.price_RUB] = price.currency === Currency.RUB,
                _e[css.price_arrowed] = variant === 'arrowed',
                _e[css.price_singleFare] = singleFare,
                _e)) },
            React.createElement(Money, { money: price }),
            React.createElement("span", { className: css.price__icon }, arrowIcon)))));
};
export default FlightPrice;
