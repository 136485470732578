import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { SwitchV2 } from '../../../../Switch';
import { getDifferentForReturnFlight, getDisabled, getIsAllServicesCanAddedToEntireFlight } from '../../store/selector';
import { differentForReturnFlight } from '../../store/actions';
import HeaderBaggageItem from './HeaderBaggageItem/HeaderBaggageItem';
import MobileBaggageSelector from './MobileBaggageSelector/MobileBaggageSelector';
import { getBaggageTab, getSelectedServices } from '../../store/selector';
import { setBaggageTab } from '../../store/actions';
import HeaderEquipmentItem from './HeaderEquipmentItem/HeaderEquipmentItem';
import Money from '../../../../Money';
import { getRelatedSegments, isActualBaggage } from '../../utils';
import PromoMessage from '../../../AdditionalBaggage/components/PromoMessage/PromoMessage';
import { OverrideComponent } from '../../../../renderProps';
var BaggageDialogHeader = function (props) {
    var _a, _b, _c;
    var theme = useTheme('UpgradeBaggageDialog').BaggageDialogHeader;
    var t = useTranslation('IncludedBaggage').t;
    var dispatch = useDispatch();
    var selectedServices = useSelector(getSelectedServices);
    var baggageTab = useSelector(getBaggageTab);
    var disabled = useSelector(getDisabled);
    var isDifferentForReturnFlight = useSelector(getDifferentForReturnFlight);
    var isMultipleSegments = props.segments.length > 1;
    var availableToEntireFlight = useSelector(getIsAllServicesCanAddedToEntireFlight);
    var handlerClickSwitcher = function () { return dispatch(differentForReturnFlight()); };
    var onChangeBaggageTab = function (type) {
        dispatch(setBaggageTab(type));
    };
    var sliderSettings = {
        slidesToShow: 6.5,
        slidesToScroll: 5,
        dots: false,
        speed: 500,
        infinite: false,
        arrows: false
    };
    var serviceByRfics = React.useMemo(function () {
        if (baggageTab) {
            // ищем багаж с максимальным id
            var actualService_1 = null;
            var servicesByRfisc = props.services
                .filter(function (services) { return services.find(function (service) { return service.rfics === baggageTab; }); })
                .reduce(function (acc, services) { return __spreadArray(__spreadArray([], __read(acc), false), __read(services), false); }, []);
            if (servicesByRfisc.length === 1) {
                actualService_1 = servicesByRfisc[0];
            }
            else {
                props.services.forEach(function (services) {
                    services.forEach(function (service) {
                        if (service.rfics === baggageTab &&
                            service.price.amount > 0 &&
                            isActualBaggage(service, actualService_1)) {
                            actualService_1 = service;
                        }
                    });
                });
            }
            return actualService_1;
        }
        return null;
    }, [props.services, baggageTab]);
    var servicePrice = React.useMemo(function () {
        if (serviceByRfics) {
            var price = {
                amount: serviceByRfics.price.amount,
                currency: serviceByRfics.price.currency
            };
            if (!isDifferentForReturnFlight) {
                var relatedSegments = getRelatedSegments(serviceByRfics.segmentIds, null);
                price.amount = price.amount * relatedSegments.size;
            }
            return price;
        }
        return null;
    }, [serviceByRfics, isDifferentForReturnFlight]);
    var renderCategory = useCallback(function (services) {
        var actualService = services[0];
        var baggageRfics = actualService.rfics;
        var baggageType = actualService.type;
        var isSelected = !!selectedServices.find(function (_a) {
            var serviceId = _a.serviceId, count = _a.count;
            return serviceId === actualService.id && count > 0;
        });
        return (React.createElement(HeaderEquipmentItem, { key: baggageRfics, className: theme.categories__item, rfics: baggageRfics, baggageType: baggageType, isActive: baggageTab === baggageRfics, isSelected: isSelected, onClick: function () { return onChangeBaggageTab(baggageRfics); } }));
    }, [selectedServices, baggageTab]);
    var renderCategories = useCallback(function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH + 1 },
                React.createElement("div", { className: theme.categories }, props.services.map(renderCategory))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(Slider, __assign({ className: theme.categories }, sliderSettings), props.services.map(renderCategory))),
            React.createElement("div", { className: theme.category__wrapper },
                React.createElement("div", { className: theme.category_info },
                    React.createElement("span", { className: theme.category_title }, serviceByRfics.name),
                    React.createElement("span", { className: theme.category_description, dangerouslySetInnerHTML: { __html: serviceByRfics.description } })),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Money, { money: servicePrice, moneyClassName: theme.category__price })))));
    }, [baggageTab, selectedServices, props.services, isDifferentForReturnFlight]);
    return (React.createElement("div", { className: cn(theme.row) },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            baggageTab && renderCategories(),
            React.createElement("div", { className: cn(theme.info, (_a = {},
                    _a[theme.equipment] = baggageTab,
                    _a)) },
                React.createElement(PromoMessage, { className: theme.promoMessage }),
                props.headerProps.description && (React.createElement("div", { className: theme.titleContainer },
                    React.createElement("div", { className: theme.description }, props.headerProps.description))),
                React.createElement("div", { className: theme.info_price },
                    React.createElement("div", { className: cn(theme.price_label, (_b = {},
                            _b[theme.perFlight] = isDifferentForReturnFlight,
                            _b[theme.price_label_withPrice] = servicePrice,
                            _b)) }, isDifferentForReturnFlight ? (t('Price per flight')) : (React.createElement(React.Fragment, null,
                        t('Price for all flights'),
                        servicePrice && (React.createElement("div", { className: theme.price_amount },
                            React.createElement(Money, { money: servicePrice })))))),
                    isMultipleSegments && availableToEntireFlight && (React.createElement(SwitchV2, { label: React.createElement("span", { className: theme.label }, t('Choose for each flight')), switchPosition: 'right', isActive: isDifferentForReturnFlight, onClick: handlerClickSwitcher, isDisabled: disabled })))),
            !baggageTab && (React.createElement("div", { className: theme.baggage__container }, props.services.map(function (service) { return (React.createElement(HeaderBaggageItem, { services: service, key: service[0].rfics, allSegments: !isDifferentForReturnFlight })); })))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            baggageTab ? (renderCategories()) : (React.createElement(OverrideComponent, { component: { MobileBaggageSelector: MobileBaggageSelector }, componentProps: {
                    services: props.services,
                    selectedBaggageId: props.mobileSelectedServiceId,
                    setSelectedBaggageId: props.setMobileServiceId,
                    allSegments: !isDifferentForReturnFlight,
                    segments: props.segments
                } })),
            React.createElement("div", { className: theme.toggle__wrapper },
                React.createElement("div", { className: cn(theme.price_label, (_c = {}, _c[theme.perFlight] = isDifferentForReturnFlight, _c)) }, t(isDifferentForReturnFlight ? 'Price per flight' : 'Price for all flights')),
                isMultipleSegments && availableToEntireFlight && (React.createElement(SwitchV2, { label: React.createElement("span", { className: theme.label }, t('Choose for each flight')), switchPosition: 'right', isActive: isDifferentForReturnFlight, onClick: handlerClickSwitcher, isDisabled: disabled }))))));
};
export default BaggageDialogHeader;
