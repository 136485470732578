import { __assign, __rest } from "tslib";
import * as React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../../theme';
import { format, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../../../utils';
import { IconCalendar } from '../../../../../Icons';
import Modal from '../../../../../Modal';
import DatepickerComponent from '../SearchFlights/Calendar/Datepicker/Datepicker';
import { useAvailableDates } from '../hooks';
import { useToggleable } from '../../../../../hooks';
import Header from './Header/Header';
var Datepicker = function (_a) {
    var _b;
    var className = _a.className, date = _a.date, minDate = _a.minDate, onSelect = _a.onSelect, segment = _a.segment, isActive = _a.isActive, renderTrigger = _a.renderTrigger, props = __rest(_a, ["className", "date", "minDate", "onSelect", "segment", "isActive", "renderTrigger"]);
    var t = useTranslation('Exchange').t;
    var _c = useTheme('Exchange'), theme = _c.Datepicker, themeCalendar = _c.Calendar;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    var dates = useAvailableDates(segment).dates;
    var _d = useToggleable(false), isOpen = _d.isOpen, open = _d.open, close = _d.close;
    React.useEffect(function () {
        if (!date && isActive) {
            open();
        }
    }, [isActive]);
    var onOpenHandler = function (e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        open();
    };
    var onSelectDate = function (date) {
        close();
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(date);
    };
    var datepickerStyles = React.useMemo(function () { return ({
        Datepicker: {
            PopupDatepickerStyles: {
                block: themeCalendar.datepicker__modal
            },
            DayStyles: {
                day: themeCalendar.day,
                day_selected: themeCalendar.day_selected,
                day__inside: themeCalendar.day_inside,
                day_startPeriod: themeCalendar.day_startPeriod,
                day_endPeriod: themeCalendar.day_endPeriod,
                day_inPeriod: themeCalendar.day_inPeriod,
                day_notAvailable: themeCalendar.day_notAvailable,
                day_weekend: themeCalendar.day_weekend
            },
            MonthStyles: {
                month: themeCalendar.month_month
            },
            MonthsWrapperStyles: {
                wrapper: themeCalendar.monthsWrapper__wrapper,
                nav_next: themeCalendar.monthsWrapper__navNext,
                nav_prev: themeCalendar.monthsWrapper__navPrev,
                monthsList: themeCalendar.monthsList
            },
            MonthHeaderStyles: {
                monthHeader: themeCalendar.monthHeader__header,
                monthHeader__select_month: themeCalendar.monthHeader__select_month,
                monthHeader__select_year: themeCalendar.monthHeader__select_month
            },
            WeeklyHeaderStyles: {
                dayOfWeekName: themeCalendar.weeklyHeader__dayOfWeekName
            }
        },
        Modal: {
            Modal: {
                modal: themeCalendar.modal,
                closeIcon: themeCalendar.closeIcon
            }
        }
    }); }, []);
    var datepickerProps = __assign({ minDate: minDate, openDate: minDate !== null && minDate !== void 0 ? minDate : date, onSelect: onSelectDate, monthCount: isTablet ? (isMobile ? 12 : 2) : 3, monthsToScroll: isTablet ? 2 : 3, availableDates: (dates === null || dates === void 0 ? void 0 : dates.length) ? dates : [], highlightClassName: themeCalendar.day_highlight, popupDatepickerStyles: theme.monthsWrapperStyles_wrapper }, props);
    return (React.createElement(ThemeProvider, { value: datepickerStyles }, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger(onOpenHandler)) !== null && _b !== void 0 ? _b : (React.createElement("div", { className: cn(className, theme.wrapper), onClick: onOpenHandler },
        IconCalendar,
        React.createElement("span", null, date ? format(date, 'd MMM') : t('Select date')))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.picker },
                React.createElement(Modal, { open: isOpen, onClose: close },
                    React.createElement(Header, { onBack: close }),
                    React.createElement(DatepickerComponent, __assign({}, datepickerProps))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: isOpen, onClose: close },
                React.createElement("div", null,
                    React.createElement(Header, { onBack: close }),
                    React.createElement(DatepickerComponent, __assign({}, datepickerProps)))))));
};
export default Datepicker;
