import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import { baggageIcon, mealIcon, carryonIcon, sportBaggageIcon } from '../../../Icons';
var SelectedService = function (_a) {
    var className = _a.className, services = _a.services;
    var css = useTheme('Checkin').SelectedService;
    var t = useTranslation('Checkin').t;
    var service = services[0];
    var serviceIcon;
    var description;
    var baggageType;
    switch (service.type) {
        case OrderAdditionalServiceGdsServiceServiceType.Meal:
            serviceIcon = mealIcon;
            description = services.map(function (service) { return "".concat(service.count, " x ").concat(service.name); }).join(', ');
            break;
        case OrderAdditionalServiceGdsServiceServiceType.Baggage:
            var baggageService = services[0];
            serviceIcon = baggageIcon;
            baggageType = baggageService.baggageType;
            if (baggageType === BaggageType.CarryOn) {
                serviceIcon = carryonIcon;
            }
            else if (baggageType === BaggageType.SportingEquipment) {
                serviceIcon = sportBaggageIcon;
            }
            if (services.length > 1) {
                description = services
                    .map(function (service) {
                    var baggageService = service;
                    return "".concat(service.count, " x ").concat(baggageService.baggageWeight, ", ").concat(baggageService.name);
                })
                    .join(', ');
            }
            else {
                description = "".concat(service.count > 1 ? "".concat(service.count, " x ") : '').concat(baggageService.baggageWeight);
            }
            break;
    }
    return (React.createElement("div", { className: cn(css.service, className) },
        serviceIcon && React.createElement("div", { className: css.service__icon }, serviceIcon),
        React.createElement("div", { className: css.service__info },
            React.createElement("span", { className: css.service__name }, t(baggageType !== null && baggageType !== void 0 ? baggageType : service.type)),
            React.createElement("span", { className: css.service__description }, description))));
};
export default SelectedService;
