import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money } from '../../../Money';
import { useTheme } from '../../../theme';
import { expandIcon } from '../../Icons';
var StepbarHeader = function (_a) {
    var _b, _c;
    var _d;
    var className = _a.className, currentStep = _a.currentStep, steps = _a.steps, totalPrice = _a.totalPrice, isOpen = _a.isOpen, onToggle = _a.onToggle;
    var t = useTranslation('MobileStepbar').t;
    var css = useTheme('MobileStepbar').StepbarHeader;
    return (React.createElement("div", { className: cn(className, css.header, (_b = {}, _b[css.header_zeroPrice] = totalPrice.amount === 0, _b)), onClick: onToggle },
        currentStep >= 0 && ((_d = steps[currentStep]) === null || _d === void 0 ? void 0 : _d.label) && (React.createElement("div", { className: css.stepIndex },
            currentStep + 1,
            "/",
            steps.length,
            ":",
            React.createElement("span", { className: css.stepIndex__label }, steps[currentStep].label))),
        React.createElement("div", { className: css.price },
            React.createElement("div", { className: css.price__wrapper },
                React.createElement("span", { className: css.price_title }, t('Total')),
                React.createElement(Money, { money: totalPrice })),
            React.createElement("span", { className: cn(css.expand, (_c = {}, _c[css.expand_open] = isOpen, _c)) }, expandIcon))));
};
export default StepbarHeader;
