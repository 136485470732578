import { __read, __spreadArray } from "tslib";
import { ADD_SELECTED_FARE, SLICE_SELECTED_FARES } from './actions';
export var selectedFaresReducer = function (state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case ADD_SELECTED_FARE:
            return __spreadArray(__spreadArray([], __read(state), false), [action.payload], false);
        case SLICE_SELECTED_FARES:
            return state.slice(0, action.payload);
        default:
            return state;
    }
};
