import { __assign } from "tslib";
import { DataLayerEvent, DataLayerItemsListName, ItemCategory, OrderStep, Recommendation } from '../../../../DataLayerAnalytics/types';
import getOrderDataLayer from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../../DataLayerAnalytics/OrderDataLayer/types';
import { getMinPrices } from '../../../utils';
export var setDataLayerParams = function (currency, promotionCode) {
    if (promotionCode === void 0) { promotionCode = null; }
    getOrderDataLayer(OrderType.SearchResults).init(currency, promotionCode);
};
var getTravellersTypeCount = function (searchParameters) {
    try {
        return searchParameters.passengers.map(function (passenger) { return "".concat(passenger.count).concat(passenger.passengerType); });
    }
    catch (e) {
        return [];
    }
};
var getFlightMinPrices = function (flight, selectedFares) {
    try {
        return getMinPrices(flight.pricesForFareGroups, true, selectedFares, false);
    }
    catch (e) {
        return [];
    }
};
var getFlightInfo = function (flight, searchParameters) {
    return {
        item_id: "".concat(flight.segments[0].segment.marketingAirline.iata, "-").concat(flight.segments[0].segment.flightNumber),
        item_name: "".concat(flight.segments.map(function (segment) { return segment.segment.departure.airport.iata; }).join('-'), "-").concat(flight.segments[flight.segments.length - 1].segment.arrival.airport.iata),
        item_category4: flight.segments[0].segment.departure.date,
        item_category: ItemCategory.Flight,
        item_category2: OrderStep.Booking,
        item_category5: getTravellersTypeCount(searchParameters)
    };
};
export var analytics_setFareGroupsListItems = function (fareGroup, flight, searchParameters) {
    var items = fareGroup
        .filter(function (fare) { var _a; return !!((_a = fare.prices) === null || _a === void 0 ? void 0 : _a.length); })
        .map(function (fare, key) {
        var _a, _b, _c, _d;
        return __assign(__assign({}, getFlightInfo(flight, searchParameters)), { item_brand: (_a = fare.fareFamily) === null || _a === void 0 ? void 0 : _a.title, price: (_c = (_b = fare.prices[0]) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount, item_category3: ((_d = fare.fareFamily) === null || _d === void 0 ? void 0 : _d.isRecommended)
                ? Recommendation.Recommended
                : Recommendation.NoRecommended, quantity: 1, index: key + 1 });
    });
    var dataLayer = getOrderDataLayer();
    if (dataLayer.getOrderType() === OrderType.SearchResults) {
        dataLayer.getDataLayer().pushToDataLayer(DataLayerEvent.ViewItemList, items, {}, DataLayerItemsListName.Fares);
    }
};
export var analytics_setFlightsListItems = function (flights, selectedFares, searchParameters) {
    var items = flights.map(function (flight, key) {
        var _a, _b, _c, _d;
        var minPrices = getFlightMinPrices(flight, selectedFares);
        return __assign(__assign({}, getFlightInfo(flight, searchParameters)), { item_brand: (_b = (_a = minPrices[0]) === null || _a === void 0 ? void 0 : _a.fareFamily.title) !== null && _b !== void 0 ? _b : '', item_category3: 'no recommendation', quantity: 1, price: (_d = (_c = minPrices[0]) === null || _c === void 0 ? void 0 : _c.price.amount) !== null && _d !== void 0 ? _d : 0, index: key + 1 });
    });
    var dataLayer = getOrderDataLayer();
    if (dataLayer.getOrderType() === OrderType.SearchResults) {
        dataLayer
            .getDataLayer()
            .pushToDataLayer(DataLayerEvent.ViewItemList, items, {}, DataLayerItemsListName.SearchResults);
    }
};
export var analytics_fareAddToCart = function (fare, index, flight, searchParameters) {
    var _a, _b, _c, _d, _e;
    var item = __assign(__assign({}, getFlightInfo(flight, searchParameters)), { item_brand: (_a = fare.fareFamily) === null || _a === void 0 ? void 0 : _a.title, price: (_d = (_c = (_b = fare.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.amount, item_category3: ((_e = fare.fareFamily) === null || _e === void 0 ? void 0 : _e.isRecommended) ? Recommendation.Recommended : Recommendation.NoRecommended, quantity: 1, index: index });
    var dataLayer = getOrderDataLayer();
    if (dataLayer.getOrderType() === OrderType.SearchResults) {
        dataLayer.getDataLayer().pushToDataLayer(DataLayerEvent.AddToCart, [item], {}, DataLayerItemsListName.Fares);
    }
};
export var analytics_fareRemoveFromCart = function (fareName, farePrice, legId, isRecommended, flight, searchParameters) {
    var item = __assign(__assign({}, getFlightInfo(flight, searchParameters)), { item_brand: fareName, price: farePrice, item_category3: isRecommended ? Recommendation.Recommended : Recommendation.NoRecommended, quantity: 1, index: legId });
    var dataLayer = getOrderDataLayer(OrderType.SearchResults);
    dataLayer.getDataLayer().pushToDataLayer(DataLayerEvent.RemoveFromCart, [item], {}, DataLayerItemsListName.Fares);
};
