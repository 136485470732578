import { useMemo } from 'react';
import { useToggleable } from '../hooks';
export var usePaymentMethodsError = function (errors) {
    var _a;
    var _b = useToggleable(false), isOpen = _b.isOpen, setOpen = _b.setOpen, close = _b.close;
    useMemo(function () { return !!errors && setOpen(true); }, [errors]);
    var errorMessage = (_a = errors === null || errors === void 0 ? void 0 : errors.graphQLErrors.find(function (item) {
        var _a;
        return (_a = item.message) === null || _a === void 0 ? void 0 : _a.startsWith('Sirena2_');
    })) === null || _a === void 0 ? void 0 : _a.message;
    return {
        isOpen: isOpen,
        errorMessage: errorMessage,
        close: close
    };
};
