import * as React from 'react';
import { useTheme } from '../../../../theme';
var ListTransition = function (props) {
    var innerRef = React.useRef(null), outerRef = React.useRef(null), theme = useTheme('Contacts').ListTransitionStyles;
    React.useEffect(function () {
        innerRef.current.childNodes.forEach(function (child, index) {
            child.classList.add(theme.item);
            if (index === props.visible) {
                child.classList.remove(theme.item_gone);
            }
            else {
                child.classList.add(theme.item_gone);
            }
        });
        var height = outerRef.current.offsetHeight;
        innerRef.current.style.transform = "translateY(-".concat(height * props.visible, "px)");
    }, [props.visible]);
    return (React.createElement("div", { ref: outerRef, className: theme.container },
        React.createElement("div", { ref: innerRef, className: theme.list }, props.children)));
};
export default ListTransition;
