import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { format, initI18n } from '../../utils';
import { getAbsoluteDate } from '../../Engine/components/FastSearch/utils';
import { Plane } from '../../Icons';
initI18n('Segment');
var BoardingPassMob = function (_a) {
    var _b;
    var _c, _d;
    var className = _a.className, segment = _a.segment, _e = _a.isCheckedIn, isCheckedIn = _e === void 0 ? true : _e;
    var css = useTheme('MobileSegment').MobileSegment;
    var t = useTranslation('Segment').t;
    var fareFamily = (_c = segment.fareFamilies[0]) === null || _c === void 0 ? void 0 : _c.fareFamily;
    return (React.createElement("div", { className: cn(css.wrapper, className, (_b = {},
            _b[css.disabled] = !isCheckedIn,
            _b)) },
        React.createElement("div", { className: cn(css.row, css.header) },
            ((_d = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _d === void 0 ? void 0 : _d.icon) && (React.createElement("div", { className: css.header__logo },
                React.createElement("img", { src: fareFamily.airline.icon, alt: "" }))),
            segment.operatingAirline.iata,
            " ",
            segment.flightNumber,
            ",",
            ' ',
            format(segment.departure.date, new Date(segment.departure.date).getFullYear() - new Date().getFullYear() < 1
                ? 'dd MMMM'
                : 'dd MMM, yyyy')),
        React.createElement("div", { className: cn(css.row, css.info) },
            React.createElement("div", { className: css.info__col },
                React.createElement("div", { className: css.info__iata }, segment.departure.airport.iata),
                React.createElement("div", { className: css.info__cities },
                    React.createElement("span", null, segment.departure.airport.city.name),
                    React.createElement("span", null, segment.departure.airport.title)),
                React.createElement("div", { className: css.info__time }, segment.departure.time.substr(0, 5))),
            React.createElement("div", { className: css.info__plane }, Plane),
            React.createElement("div", { className: css.info__col },
                React.createElement("div", { className: css.info__iata }, segment.arrival.airport.iata),
                React.createElement("div", { className: css.info__cities },
                    React.createElement("span", null, segment.arrival.airport.city.name),
                    React.createElement("span", null, segment.arrival.airport.title)),
                React.createElement("div", { className: css.info__time }, segment.arrival.time.substr(0, 5)))),
        React.createElement("div", { className: cn(css.row, css.arrivalInfo) },
            segment.departure.terminal && (React.createElement("div", { className: css.arrivalInfo__item },
                React.createElement("div", { className: css.arrivalInfo__itemLabel }, t('Terminal')),
                React.createElement("div", { className: css.arrivalInfo__itemValue }, segment.departure.terminal))),
            React.createElement("div", { className: css.arrivalInfo__item },
                React.createElement("div", { className: css.arrivalInfo__itemLabel }, t('Boarding')),
                React.createElement("div", { className: css.arrivalInfo__itemValue },
                    format(getAbsoluteDate(segment.checkinInfo.boardingOpenTime), 'HH:mm'),
                    " \u2014",
                    ' ',
                    format(getAbsoluteDate(segment.checkinInfo.boardingCloseTime), 'HH:mm'))))));
};
export default BoardingPassMob;
