import Disclaimer from '../../../Disclaimer/Disclaimer';
import * as React from 'react';
import cn from 'classnames';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
initI18n('SeatMap');
var SeatMapDisclaimer = function (_a) {
    var children = _a.children;
    var theme = useTheme('SeatMap').SeatMapDisclaimerStyles;
    return (React.createElement(Disclaimer, { wrapperClassName: theme.disclaimer__wrp, className: cn(theme.disclaimer_columnContent, theme.disclaimer) }, children));
};
export default SeatMapDisclaimer;
