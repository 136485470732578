import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import TravellerForm from '../../TravellerForm';
import { TravellerTypes } from '../types';
import { useTheme } from '../../theme';
import { getUserValue, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import UserCard from '../../UserCard/components/UserCard';
import MediaQuery from 'react-responsive';
import Modal from '../../Modal';
import { ChevronLeft } from '../../Icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TravellerFieldEnum } from '@websky/graphql';
import { OverrideComponent } from '../../renderProps';
initI18n('Traveller');
var Traveller = function (props) {
    var _a;
    var css = useTheme('Traveller').Traveller;
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var expandIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z", fill: "#9A9A9A" })));
    var t = useTranslation('Account').t;
    var userLabel = React.useMemo(function () {
        if (props.type === TravellerTypes.CompanionEmpty) {
            return t('Add companion');
        }
        var firstName = getUserValue(props.traveller, TravellerFieldEnum.FirstName), lastName = getUserValue(props.traveller, TravellerFieldEnum.LastName);
        return "".concat(lastName, " ").concat(firstName);
    }, [props.type, props.traveller]);
    var travellerFormProps = {
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.onSubmit(__assign({}, values))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); },
        onDelete: function () {
            return props.onDelete(props.traveller.id);
        },
        type: props.type,
        traveller: props.traveller && props.traveller.id !== '0' ? props.traveller : __assign(__assign({}, props.traveller), { documents: [] })
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Accordion, { className: css.wrapper, expanded: props.redirectToPassengersPage || props.expanded, classes: {
                    expanded: css.expanded
                }, onChange: props.handleChange },
                React.createElement(AccordionSummary, { expandIcon: expandIcon, className: cn((_a = {}, _a[css.header__redirect] = props.redirectToPassengersPage, _a), css.header) },
                    React.createElement(UserCard, { profile: props.traveller, editText: props.type === TravellerTypes.PersonalDetails ? t('Edit') : null, inline: true, type: props.type, className: css.userCard, headerClassName: css.summary })),
                !props.redirectToPassengersPage && (React.createElement(AccordionDetails, { className: css.content__traveller },
                    React.createElement(OverrideComponent, { componentProps: __assign(__assign({}, travellerFormProps), { onClose: props.handleChange }), component: { TravellerForm: TravellerForm } }))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(UserCard, { profile: props.traveller, type: props.type, className: css.userCard, headerClassName: css.summary, onClick: function () { return (props.redirectToPassengersPage ? props.handleChange() : setOpen(true)); } }),
            React.createElement(Modal, { open: open, onClose: function () { return setOpen(false); }, closeButtonRenderer: function (onClick) {
                    return (React.createElement("div", { className: css.popup__header, onClick: onClick },
                        ' ',
                        ChevronLeft,
                        " ",
                        userLabel));
                } },
                React.createElement(OverrideComponent, { componentProps: __assign(__assign({}, travellerFormProps), { onClose: function () { return setOpen(false); } }), component: { TravellerForm: TravellerForm } })))));
};
export default Traveller;
