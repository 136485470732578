import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { NotificationCard } from '../../NotificationCard/NotificationCard';
import { format } from '../../../../utils';
import { Group } from '../../NotificationCard/Group/Group';
import { Info } from '../../NotificationCard/Info/Info';
import cn from 'classnames';
import { NOTIFICATION_DATE_FORMAT } from '../../../constants';
import { fromUTCToLocalTimeZone } from '../../../utils';
export var AppealCard = function (props) {
    var css = useTheme('Communications').AppealCard;
    var t = useTranslation('Communications').t;
    var datePrefix = props.updatedAt ? t('Updated') : t('Created');
    var date = fromUTCToLocalTimeZone(props.updatedAt ? props.updatedAt : props.createdAt);
    var dateFormat = "".concat(format(date, NOTIFICATION_DATE_FORMAT));
    return (React.createElement(NotificationCard, { isRead: props.isRead, title: props.title, date: "".concat(datePrefix, ": ").concat(dateFormat), preDescRender: function () {
            var _a;
            return (React.createElement(Group, null,
                React.createElement(Info, null,
                    t('Status'),
                    ":",
                    React.createElement("span", { className: cn(css.status, (_a = {},
                            _a[css.status__solved] = props.status === 'Solved',
                            _a[css.status__new] = props.status === 'New',
                            _a[css.status__progress] = props.status === 'InProgress',
                            _a[css.status__declined] = props.status === 'Rejected',
                            _a[css.status__awaiting] = props.status === 'WaitingAnswer',
                            _a)) }, t(props.status))),
                React.createElement(Info, { align: "right" },
                    t('ID'),
                    ": ",
                    props.id)));
        }, markAsRead: props.markAsRead },
        React.createElement("p", null, props.message)));
};
