import { __assign } from "tslib";
import * as React from 'react';
import { overridedIcons } from '../../../../../../index';
import { Field } from '../../../../../../Passenger/components/Field/Field';
import { useTheme } from '../../../../../../theme';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox/Checkbox';
import { AdditionalTravellerFieldEnum } from '../../../../../../Passenger/types';
var Accompanying = function (props) {
    var _a;
    var theme = useTheme('PassengerForm').Accompanying;
    var t = useTranslation('Passenger').t;
    if (!((_a = props.accompanyingFields) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return (React.createElement("div", { className: theme.accompanying },
        React.createElement("div", { className: theme.accompanying__header }, t('Booking details of the accompanying passenger')),
        React.createElement("div", { className: theme.accompanying__info },
            overridedIcons['InfoDetails'],
            React.createElement("div", null, t('Fill in the fields with the booking details of the accompanying passenger over 18 years of age. Please note that the flight dates, flight and class of service for both passengers must be the same.'))),
        React.createElement("div", { className: theme.fields }, props.accompanyingFields
            .filter(function (field) { return field.type !== AdditionalTravellerFieldEnum.AccompanyingCheck; })
            .map(function (field, index) { return (React.createElement(Field, { disabled: field.readonly || props.disabled, key: "".concat(field.name, "_").concat(index), field: __assign(__assign({}, field), { name: "customer.".concat(field.name) }), passengerId: props.passengerId, passengerType: props.passengerType })); })),
        React.createElement(Checkbox, { accompanyingFields: props.accompanyingFields })));
};
export default Accompanying;
