import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CartService } from '../types';
import Flights from './Flights';
import Baggage from './Baggage';
import Seats from './Seats';
import Meals from './Meals';
import Passengers from './Passengers';
import Extra from './Extra';
import Insurance from './Extra/Insurance';
var CartFactory = function (_a) {
    var type = _a.type, props = __rest(_a, ["type"]);
    switch (type) {
        case CartService.Flights:
            return React.createElement(Flights, __assign({}, props));
        case CartService.Baggage:
            return React.createElement(Baggage, __assign({}, props));
        case CartService.Seats:
            return React.createElement(Seats, __assign({}, props));
        case CartService.Meal:
            return React.createElement(Meals, __assign({}, props));
        case CartService.Passengers:
            return React.createElement(Passengers, __assign({}, props));
        case CartService.Extra:
            return React.createElement(Extra, __assign({}, props));
        case CartService.Insurances:
            return React.createElement(Insurance, __assign({}, props));
        default:
            return null;
    }
};
export default CartFactory;
