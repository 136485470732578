import { __assign, __read, __spreadArray, __values } from "tslib";
import { BaggageType } from '@websky/graphql';
export var detectDifferentForReturnFlight = function (segments, selectedServices) {
    var e_1, _a, e_2, _b;
    var servicesCount = new Map(), segmentsOfServices = new Map();
    try {
        for (var selectedServices_1 = __values(selectedServices), selectedServices_1_1 = selectedServices_1.next(); !selectedServices_1_1.done; selectedServices_1_1 = selectedServices_1.next()) {
            var selectedService = selectedServices_1_1.value;
            var serviceId = selectedService.serviceId, passengerId = selectedService.passengerId, key = "".concat(passengerId, ":").concat(serviceId);
            if (!servicesCount.has(key)) {
                servicesCount.set(key, selectedService.count);
            }
            // detect case if different service count
            if (servicesCount.get(key) !== selectedService.count) {
                return true;
            }
            // collect all segments where service is active
            segmentsOfServices.set(key, __spreadArray(__spreadArray([], __read((segmentsOfServices.get(key) || [])), false), __read(selectedService.segmentIds), false));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (selectedServices_1_1 && !selectedServices_1_1.done && (_a = selectedServices_1.return)) _a.call(selectedServices_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    try {
        for (var _c = __values(segmentsOfServices.values()), _d = _c.next(); !_d.done; _d = _c.next()) {
            var segmentOfService = _d.value;
            if (new Set(segmentOfService).size !== segments.length) {
                return true;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return false;
};
export var joinServicesByRfics = function (baggage) {
    var servicesByRficsMap = new Map();
    baggage.forEach(function (baggage) {
        if (servicesByRficsMap.has(baggage.rfics)) {
            servicesByRficsMap.get(baggage.rfics).push(baggage);
        }
        else {
            servicesByRficsMap.set(baggage.rfics, [baggage]);
        }
    });
    return __spreadArray([], __read(servicesByRficsMap.values()), false);
};
export var joinIncludedBaggageByRfics = function (includedServices) {
    var includedServiceMap = new Map();
    includedServices.forEach(function (service) {
        var _a;
        var key = "".concat(service.confirmedCount, "_").concat((_a = service.baggage) === null || _a === void 0 ? void 0 : _a.rfics);
        if (includedServiceMap.has(key)) {
            includedServiceMap.get(key).push(service);
        }
        else {
            includedServiceMap.set(key, [service]);
        }
    });
    var includedBaggageByRfics = [];
    includedServiceMap.forEach(function (items) {
        includedBaggageByRfics.push(items);
    });
    return includedBaggageByRfics;
};
export var filterIncludedBaggageByRfics = function (baggageServices, includedBaggage, selectedBaggage) {
    var selectedBaggageService = new Map();
    var filteredIncludedServices = [];
    selectedBaggage.forEach(function (baggage) {
        baggage.segmentIds.forEach(function (segmentId) {
            var service = baggageServices.find(function (baggageService) { return baggageService.id === baggage.serviceId; });
            if (service) {
                var key = "".concat(service.rfics, "_").concat(baggage.passengerId, "_").concat(segmentId);
                selectedBaggageService.set(key, service);
            }
        });
    });
    includedBaggage.forEach(function (baggage) {
        if (Array.isArray(baggage.segmentIds)) {
            baggage.segmentIds.forEach(function (segmentId) {
                var _a;
                var isReplaced = (_a = baggage.baggage.isReplacedBy) === null || _a === void 0 ? void 0 : _a.some(function (rfics) {
                    var key = "".concat(rfics, "_").concat(baggage.passengerId, "_").concat(segmentId);
                    return !!selectedBaggageService.get(key);
                });
                if (baggage.baggage && !isReplaced) {
                    filteredIncludedServices.push(baggage);
                }
            });
        }
    });
    return filteredIncludedServices;
};
export var getRelatedSegments = function (groups, segmentId) {
    if (segmentId === void 0) { segmentId = null; }
    var result = new Set();
    if (segmentId === null) {
        groups.forEach(function (group) {
            group.forEach(function (segmentId) { return result.add(segmentId); });
        });
    }
    else {
        groups.some(function (group) {
            if (group.includes(segmentId)) {
                group.forEach(function (segmentId) { return result.add(segmentId); });
                return true;
            }
            return false;
        });
    }
    return result;
};
export var getMinPriceInBaggageGroup = function (baggages) {
    var price = {
        amount: baggages[0].price.amount,
        currency: baggages[0].price.currency
    };
    baggages.forEach(function (baggage) {
        if (baggage.price.amount < price.amount) {
            price.amount = baggage.price.amount;
        }
    });
    return price;
};
export var getActualPriceInBaggageGroup = function (baggages, multipleSegments) {
    var price = {
        amount: baggages[0].price.amount,
        currency: baggages[0].price.currency,
        withFrom: false
    };
    var actualBaggage = null;
    baggages.forEach(function (bag) {
        var lessActualPrice = !actualBaggage || bag.price.amount < (actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.price.amount);
        if (isActualBaggage(bag, actualBaggage) && lessActualPrice) {
            actualBaggage = bag;
        }
        if (bag.price.amount < (actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.price.amount) || bag.price.amount > (actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.price.amount)) {
            price.withFrom = true;
        }
    });
    if (actualBaggage) {
        var segmentsCount = multipleSegments ? getRelatedSegments(actualBaggage.segmentIds).size : 1;
        price.amount = actualBaggage.price.amount * segmentsCount;
    }
    return price;
};
export var getSegmentsInBaggageGroup = function (baggages) {
    var segmentsSet = new Set();
    baggages.forEach(function (baggage) {
        return baggage.segmentIds.forEach(function (segments) { return segments.forEach(function (segment) { return segmentsSet.add(segment); }); });
    });
    return segmentsSet;
};
export var getBaggageCountInSegment = function (services, segmentId) {
    var count = 0;
    services.forEach(function (service) {
        if (!segmentId || service.segmentIds.find(function (segmentGroup) { return segmentGroup.includes(segmentId); })) {
            count += service.count;
        }
    });
    return count;
};
export var findMaxCountServicesInFlight = function (services) {
    var segmentCountsMap = new Map();
    services.forEach(function (service) {
        service.segmentIds.forEach(function (segmentId) {
            segmentCountsMap.set(segmentId, (segmentCountsMap.get(segmentId) || 0) + service.count);
        });
    });
    var maxCount = 0;
    segmentCountsMap.forEach(function (count) {
        if (count > maxCount) {
            maxCount = count;
        }
    });
    return maxCount;
};
export var getMaxBaggageCount = function (defaultMaxBaggage, bagLimitConfig, baggageType, selectedCount, selectedPassengerBaggageByTypeCount, selectedPassengerBaggageCount) {
    var maxBaggagePerPassengerByType = defaultMaxBaggage;
    var maxBaggagePerPassenger = defaultMaxBaggage;
    if (bagLimitConfig) {
        if (bagLimitConfig.baggageByTypeLimit) {
            var maxBaggageByType = bagLimitConfig.baggageByTypeLimit.find(function (i) { return i.baggageType === baggageType; });
            if (maxBaggageByType) {
                maxBaggagePerPassengerByType = maxBaggageByType.maxBaggagePerPassenger;
            }
        }
        if (bagLimitConfig.maxBaggagePerPassenger >= 0) {
            maxBaggagePerPassenger = bagLimitConfig.maxBaggagePerPassenger;
        }
    }
    maxBaggagePerPassengerByType -= selectedPassengerBaggageByTypeCount - selectedCount;
    maxBaggagePerPassenger -= selectedPassengerBaggageCount - selectedCount;
    return Math.min(maxBaggagePerPassengerByType, maxBaggagePerPassenger);
};
export var getMinBaggageCount = function (passenger, baggageId, segmentId) {
    var _a;
    return (_a = passenger.services.find(function (s) { return (!segmentId || s.segmentIds.includes(segmentId)) && baggageId.map(function (b) { return b.id; }).includes(s.serviceId); })) === null || _a === void 0 ? void 0 : _a.confirmedCount;
};
export var isActualBaggage = function (currBaggage, actualBaggage, onlyAvailable) {
    if (onlyAvailable === void 0) { onlyAvailable = true; }
    var actualBaggageById = !actualBaggage || currBaggage.id > actualBaggage.id;
    if (onlyAvailable) {
        return currBaggage.canBeAdded && actualBaggageById;
    }
    return actualBaggageById;
};
export var getActualBaggage = function (baggage, segmentId, onlyAvailable) {
    if (onlyAvailable === void 0) { onlyAvailable = true; }
    var actualBaggage = null;
    baggage.forEach(function (bag) {
        if (isActualBaggage(bag, actualBaggage, onlyAvailable) &&
            (!segmentId || (segmentId && bag.segmentIds.some(function (ids) { return ids.some(function (id) { return id === segmentId; }); })))) {
            actualBaggage = bag;
        }
    });
    return actualBaggage;
};
export var convertToBaggageAdapter = function (item, canBeAdded) {
    if (canBeAdded === void 0) { canBeAdded = false; }
    return item
        ? __assign(__assign({}, item), { id: item.serviceId, canBeAdded: canBeAdded }) : null;
};
export var updateSelectedItems = function (currCount, newCount, items, // empty items we can fill by current counter
selectedItems // items previously selected by user for current segment, rfics and passenger
) {
    var dCount = newCount - currCount;
    // count > 1 can be booked by multiple services with same ids
    // Join services with same segment ids
    var selectedServicesBySegments = new Map();
    selectedItems.forEach(function (selected) {
        var key = selected.segmentIds.sort().join('-');
        selectedServicesBySegments.set(key, __spreadArray(__spreadArray([], __read((selectedServicesBySegments.get(key) || [])), false), [selected], false));
    });
    if (dCount > 0) {
        items.forEach(function (item) {
            var _a;
            if (!item.disabledToAdd) {
                var key = item.segmentIds.sort().join('-');
                var existServicesBySegment = (_a = selectedServicesBySegments.get(key)) !== null && _a !== void 0 ? _a : [];
                var existServiceById = existServicesBySegment.find(function (service) { return service.serviceId === item.serviceId; });
                if (existServiceById) {
                    selectedServicesBySegments.set(key, existServicesBySegment.map(function (service) {
                        if (service.serviceId === item.serviceId) {
                            return __assign(__assign({}, service), { count: service.count + dCount });
                        }
                        return service;
                    }));
                }
                else {
                    var existServicesCount = existServicesBySegment.reduce(function (acc, service) { return +acc + +service.count; }, 0);
                    selectedServicesBySegments.set(key, __spreadArray(__spreadArray([], __read(existServicesBySegment), false), [
                        __assign(__assign({}, item), { count: newCount - existServicesCount })
                    ], false));
                }
            }
        });
    }
    else {
        // Change the count only first service in segment and reset the rest
        selectedServicesBySegments.forEach(function (services, key) {
            var servicesWithCount = services.filter(function (service) { return service.count > 0; });
            selectedServicesBySegments.set(key, servicesWithCount.map(function (service, index) {
                var isLastService = index === servicesWithCount.length - 1;
                if (isLastService) {
                    return __assign(__assign({}, service), { count: service.count + dCount });
                }
                return service;
            }));
        });
    }
    return __spreadArray([], __read(selectedServicesBySegments.values()), false).reduce(function (acc, services) { return __spreadArray(__spreadArray([], __read(acc), false), __read(services), false); }, []);
};
export var isBaggageExcess = function (baggage) {
    return baggage.type === BaggageType.BaggageExcess;
};
export var isCanModifyServices = function (services, passenger) {
    return services.some(function (baggage) {
        var _a;
        if (!baggage.allowedPassengers.includes(passenger.id)) {
            return false;
        }
        var service = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.find(function (service) { return service.serviceId === baggage.id; });
        var canBeRemoved = !service || service.count > service.confirmedCount;
        return baggage.canBeAdded || canBeRemoved;
    });
};
export var getSelectedServicesKeys = function (services) {
    var selectedServicesSet = new Set();
    services.forEach(function (service) {
        if (service.count) {
            var key = [service.serviceId, service.passengerId, service.segmentIds.join(''), service.count].join('-');
            selectedServicesSet.add(key);
        }
    });
    return __spreadArray([], __read(selectedServicesSet), false).sort();
};
export var getHasChangesServices = function (initialServices, selectedServices) {
    var initialKeys = getSelectedServicesKeys(initialServices);
    var selectedKeys = getSelectedServicesKeys(selectedServices);
    if (initialKeys.length !== selectedKeys.length) {
        return true;
    }
    return JSON.stringify(initialKeys) !== JSON.stringify(selectedKeys);
};
