import { __assign, __read, __spreadArray } from "tslib";
import { ADD_FLIGHT_IDS, CHANGE_FLIGHT_ID, REMOVE_FLIGHT_IDS } from './actions';
export var ALL_FLIGHT = 'ALL_FLIGHT';
var initialState = [];
export var flightsToExchangeReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ADD_FLIGHT_IDS:
            return __spreadArray([], __read(action.payload), false);
        case REMOVE_FLIGHT_IDS:
            return state.filter(function (exchangeFlight) { return !action.payload.find(function (key) { return key === exchangeFlight.key; }); });
        case CHANGE_FLIGHT_ID:
            return state.map(function (exchangeFlight) {
                if (exchangeFlight.key === action.payload.key) {
                    return __assign(__assign({}, exchangeFlight), action.payload);
                }
                return exchangeFlight;
            });
        default:
            return state;
    }
};
