import { __assign, __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import cn from 'classnames';
import AdditionalServiceWrapper from '../../AdditionalServiceWrapper';
import InsuranceCard from './InsuranceCard/InsuranceCard';
import { useTheme } from '../../theme';
import { Money } from '../../Money';
import { MOBILE_MIN_WIDTH } from '../../utils';
import { Chevron } from '../../Icons';
import { useInsuranceState } from '../hooks';
import { analytics_addInsurance, analytics_removeInsurance } from '../../Insurance/utils';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
var InsurancePopup = function (_a) {
    var _b;
    var className = _a.className, header = _a.header, description = _a.description, insurances = _a.insurances, selectedInsurances = _a.selectedInsurances, onClose = _a.onClose, onSave = _a.onSave, isLoading = _a.isLoading, order = _a.order;
    var css = useTheme('InsurancePopup').InsurancePopup;
    var t = useTranslation('Checkout').t;
    var _c = __read(useState(), 2), slider = _c[0], setSlider = _c[1];
    var _d = useInsuranceState(insurances, selectedInsurances), state = _d.state, totalPrice = _d.totalPrice, hasChanges = _d.hasChanges, onToggle = _d.onToggle;
    var oneInsurance = insurances.length === 1 ? insurances[0] : null;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var isSmall = !isMobile && insurances.length < 3;
    var sliderOptions = {
        className: cn(css.slider, (_b = {},
            _b[css.small] = isSmall,
            _b)),
        slidesToShow: isSmall ? insurances.length : 3.14,
        speed: 600,
        infinite: false,
        variableWidth: isSmall,
        arrows: false,
        responsive: [
            {
                breakpoint: MOBILE_MIN_WIDTH,
                settings: {
                    slidesToShow: 1.3
                }
            }
        ]
    };
    var getPolicyUrlByInsurance = React.useCallback(function (code) {
        var _a;
        return (_a = selectedInsurances.find(function (selectedInsurance) { return selectedInsurance.insuranceProgram.code === code; })) === null || _a === void 0 ? void 0 : _a.policyUrl;
    }, [selectedInsurances]);
    var onToggleHandler = function (insurance) { return function () {
        onToggle(insurance.code);
        var isSelected = selectedInsurances.find(function (selectedInsurance) { var _a; return ((_a = selectedInsurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code) === insurance.code; });
        if (isSelected) {
            analytics_removeInsurance(insurance, order.travellers);
        }
        else {
            analytics_addInsurance(insurance, order.travellers);
        }
    }; };
    var onSaveHandler = function () {
        if (hasChanges) {
            onSave(state);
        }
        else {
            onClose();
        }
    };
    useEffect(function () {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.InsurancesList, {
            servicesIds: oneInsurance ? [oneInsurance.code] : insurances.map(function (insurance) { return insurance.code; })
        });
    }, []);
    return (React.createElement(AdditionalServiceWrapper, { className: className, headerClassName: css.header, textClassName: css.text, wrapperClassName: css.wrapper, contentClassName: css.content, footerClassName: css.footer, header: header, text: React.createElement("div", { dangerouslySetInnerHTML: { __html: description } }), onConfirm: onSaveHandler, onCancel: onClose, isLoading: isLoading },
        insurances.length > 3 && (React.createElement("div", { className: css.slider_buttons },
            React.createElement("div", { className: cn(css.slider_button, css.slider_button_prev), onClick: function () { return slider.slickPrev(); } }, Chevron),
            React.createElement("div", { className: css.slider_button, onClick: function () { return slider.slickNext(); } }, Chevron))),
        oneInsurance ? (React.createElement(InsuranceCard, { className: css.card, insurance: oneInsurance, policyUrl: getPolicyUrlByInsurance(oneInsurance.code), isSelected: state.includes(oneInsurance.code), onClick: onToggleHandler(oneInsurance) })) : (React.createElement(Slider, __assign({ ref: setSlider }, sliderOptions), insurances.map(function (insurance) { return (React.createElement(InsuranceCard, { insurance: insurance, policyUrl: getPolicyUrlByInsurance(insurance.code), isSelected: state.includes(insurance.code), onClick: onToggleHandler(insurance) })); }))),
        totalPrice && (React.createElement("div", { className: css.total },
            t('Total'),
            ":",
            React.createElement(Money, { money: totalPrice })))));
};
export default InsurancePopup;
