import * as InsuranceCardStyles from './InsuranceCard/InsuranceCard.css';
import * as InsuranceStyles from './Insurance.css';
import * as MobileScreenStyles from './MobileScreen/MobileScreen.css';
import * as SpecialDocumentField from './InsuranceInner/SpecialDocument/SpecialDocumentField/SpecialDocumentField.css';
import * as CheckinInsurance from './CheckinInsurance/CheckinInsurance.css';
export default {
    InsuranceCardStyles: InsuranceCardStyles,
    InsuranceStyles: InsuranceStyles,
    MobileScreenStyles: MobileScreenStyles,
    SpecialDocumentField: SpecialDocumentField,
    CheckinInsurance: CheckinInsurance
};
