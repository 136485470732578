import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import Dialog from '../../Modules/Exchange/components/Exchange/Dialog/Dialog';
import PassengerForm from './PassengerForm/PassengerForm';
import FullscreenDialog from '../../FullscreenDialog';
import PassengerTicket from './PassengerTicket/PassengerTicket';
import ProtectedRoute from '../../ProtectedRoute/ProtectedRoute';
import SimpleLoader from '../../SimpleLoader';
import Payment from './Payment/Payment';
import { PaymentResult, useEditPassenger } from '../hooks';
import { CloseClear } from '../../Icons';
initI18n('EditPassenger');
var EditPassenger = function (_a) {
    var _b;
    var passengerId = _a.passengerId, refetchOrder = _a.refetchOrder, returnToOrder = _a.returnToOrder;
    var t = useTranslation('EditPassenger').t;
    var theme = useTheme('EditPassenger').EditPassenger;
    var _c = useEditPassenger(refetchOrder, returnToOrder, passengerId), urls = _c.urls, availableRoutes = _c.availableRoutes, passenger = _c.passenger, changePaxOrder = _c.changePaxOrder, changeTravellers = _c.changeTravellers, prices = _c.prices, paymentStatus = _c.paymentStatus, reason = _c.reason, isLoading = _c.isLoading, onRefetchOrder = _c.onRefetchOrder, onReturnToOrder = _c.onReturnToOrder, onFillOrder = _c.onFillOrder, onGoToForm = _c.onGoToForm, onGoToRequest = _c.onGoToRequest, onGoToPayment = _c.onGoToPayment, setChangePaxOrder = _c.setChangePaxOrder, setPaymentStatus = _c.setPaymentStatus, setReason = _c.setReason;
    var onReturnToOrderHandler = function () {
        onReturnToOrder();
    };
    if (!passenger) {
        return null;
    }
    return (React.createElement(FullscreenDialog, { contentClassName: theme.content, title: t('Passenger information update'), isOpen: !!passenger, onClose: onReturnToOrderHandler, fadeTransition: true },
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(Switch, null,
            React.createElement(ProtectedRoute, { exact: true, isAccessible: availableRoutes.passenger, redirectTo: urls.requestUrl, path: urls.baseUrl, render: function () { return (React.createElement(PassengerForm, { passenger: passenger, reason: reason, onFillOrder: onFillOrder, setReason: setReason })); } }),
            React.createElement(ProtectedRoute, { exact: true, isAccessible: availableRoutes.request, redirectTo: urls.baseUrl, path: urls.requestUrl, render: function () { return (React.createElement(PassengerTicket, { passenger: passenger, changeTravellers: changeTravellers, lastChangePaxData: changePaxOrder, prices: prices, reason: reason, onRefetchOrder: onRefetchOrder, onGoToForm: onGoToForm, onGoToPayment: onGoToPayment, onReturnToOrder: onReturnToOrder, setChangePaxOrder: setChangePaxOrder })); } }),
            React.createElement(ProtectedRoute, { exact: true, isAccessible: availableRoutes.payment, redirectTo: urls.requestUrl, render: function () { return (React.createElement(React.Fragment, null,
                    React.createElement(Payment, { className: theme.payment, lastChangePaxOrder: changePaxOrder, onPaymentSuccess: function () { return setPaymentStatus(PaymentResult.Success); }, onPaymentFailed: function () { return setPaymentStatus(PaymentResult.Failed); }, onGoToRequest: onGoToRequest }),
                    React.createElement(Route, { path: urls.successUrl, render: function () {
                            setPaymentStatus(PaymentResult.Success);
                            onReturnToOrder(true);
                            return React.createElement(Redirect, { to: urls.requestUrl });
                        } }),
                    React.createElement(Route, { path: urls.failedUrl, render: function () {
                            setPaymentStatus(PaymentResult.Failed);
                            onRefetchOrder();
                            return React.createElement(Redirect, { to: urls.requestUrl });
                        } }))); } })),
        paymentStatus && (React.createElement(Dialog, { text: t(paymentStatus === PaymentResult.Success
                ? 'Exchange:Successful payment'
                : 'Exchange:Failed payment'), iconClassName: cn((_b = {},
                _b[theme.paymentIcon_failed] = paymentStatus === PaymentResult.Failed,
                _b)), icon: paymentStatus === PaymentResult.Failed ? CloseClear : null, acceptText: t('OK'), onAccept: function () { return setPaymentStatus(null); }, open: true }))));
};
export default EditPassenger;
