import { LoginInputType } from './types';
import { emailRegex } from '../utils';
import { isCorrectPhoneNumber } from '../PhoneInput/utils';
export var detectInputType = function (value) {
    if (emailRegex.test(value)) {
        return LoginInputType.Email;
    }
    else if (isCorrectPhoneNumber(value)) {
        return LoginInputType.Phone;
    }
    else {
        return LoginInputType.Text;
    }
};
export var getLoginTypeValue = function (loginTypes, loginType) {
    var _a;
    return (_a = loginTypes.find(function (_a) {
        var type = _a.type;
        return type === loginType;
    })) === null || _a === void 0 ? void 0 : _a.value;
};
