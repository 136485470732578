import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../../theme';
import { InfoMessageTypeEnum, CheckinOrderStatus, useCheckinRulesLazyQuery, useCheckinCreateOrderMutation, useResetOrderPaymentBlockMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import RetrieveBooking from '../../../../../RetrieveBooking';
import Modal from '../../../../../Modal';
import { fillCheckinOrder } from '../../../store/order/actions';
import CheckinRules from '../../../../../CheckinRules';
import { Mode } from '../../../../../RetrieveBooking/components/RetrieveBooking';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../../../../../context';
import { OverrideComponent } from '../../../../../renderProps';
import { useToggleable } from '../../../../../hooks';
import Conditions from '../ConditionsModal/Conditions/Conditions';
var Initial = function (_a) {
    var match = _a.match, history = _a.history;
    var css = useTheme('Checkin').Inital;
    var t = useTranslation('Checkin').t;
    var dispatch = useDispatch();
    var params = match.params;
    var location = useLocation();
    var isAutoSubmit = location.pathname.includes('submit');
    var _b = useToggleable(false), rulesModalIsOpen = _b.isOpen, openModal = _b.open, closeModal = _b.close;
    var _c = __read(useCheckinCreateOrderMutation(), 1), createCheckin = _c[0];
    var _d = __read(useResetOrderPaymentBlockMutation(), 1), resetOrderPaymentBlock = _d[0];
    var _e = __read(useCheckinRulesLazyQuery(), 2), getCheckin = _e[0], rules = _e[1].data;
    var getOrder = useGetCheckinOrderQuery({ skip: true, fetchPolicy: 'no-cache' }).refetch;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var order;
    var getCheckinRules = function (orderId) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getCheckin({ variables: { id: orderId } })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var openCheckinPage = function () {
        if (order) {
            dispatch(fillCheckinOrder(order));
            history.push("/".concat(order.id));
        }
    };
    var onSumbit = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                    var _a, data, errors, result_1, _b, data_1, errors_1, e_2;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 6, , 7]);
                                return [4 /*yield*/, createCheckin({ variables: { params: params } })];
                            case 1:
                                _a = _c.sent(), data = _a.data, errors = _a.errors;
                                result_1 = !errors && (data === null || data === void 0 ? void 0 : data.CheckinCreateOrder);
                                if (!(result_1 === null || result_1 === void 0 ? void 0 : result_1.isBlockedForPayment)) return [3 /*break*/, 4];
                                return [4 /*yield*/, resetOrderPaymentBlock({ variables: { id: result_1.id } })];
                            case 2:
                                _c.sent();
                                return [4 /*yield*/, getOrder({ id: result_1.id })];
                            case 3:
                                _b = _c.sent(), data_1 = _b.data, errors_1 = _b.errors;
                                if (!errors_1 && data_1.CheckinOrder) {
                                    result_1 = data_1.CheckinOrder;
                                    resolve(data_1.CheckinOrder);
                                }
                                return [3 /*break*/, 5];
                            case 4:
                                resolve(data.CheckinCreateOrder);
                                _c.label = 5;
                            case 5:
                                if (result_1) {
                                    order = result_1;
                                    setTimeout(function () {
                                        if (order.status === CheckinOrderStatus.Confirmed || iataCode === 'N4') {
                                            openCheckinPage();
                                        }
                                        else if (!result_1.isBlockedForPayment) {
                                            getCheckinRules(order.id).then(openModal);
                                        }
                                    });
                                }
                                return [3 /*break*/, 7];
                            case 6:
                                e_2 = _c.sent();
                                reject(e_2);
                                return [3 /*break*/, 7];
                            case 7: return [2 /*return*/];
                        }
                    });
                }); })];
        });
    }); }, []);
    var acceptRules = useCallback(function () {
        closeModal();
        openCheckinPage();
    }, []);
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content },
            React.createElement(Conditions, { className: css.conditions }),
            React.createElement(RetrieveBooking, { mode: Mode.Checkin, className: css.form, title: t('Online check-in'), initialId: params.PNR, initialSecret: params.lastName, submitWithInitialValues: isAutoSubmit, onSubmit: onSumbit, idTitle: t('Ticket number or booking reference'), secretTitle: t('Passenger last name'), hideSecretHint: true })),
        React.createElement(OverrideComponent, { componentProps: {
                infoMessage: {
                    code: null,
                    type: InfoMessageTypeEnum.Promo,
                    text: t('Для регистрации на рейс авиакомпании «Ямал», пожалуйста, посетите их официальный сайт')
                },
                type: 'global',
                status: InfoMessageTypeEnum.Promo,
                actionText: 'Перейти',
                onClickAction: function () { return window.open('https://yamal.aero/check-in/?wx#/'); }
            }, component: { CheckinNotification: function () { return null; } } }),
        React.createElement(OverrideComponent, { componentProps: { className: css.hint }, component: { renderCheckinHint: function () { return null; } } }),
        iataCode !== 'N4' && (React.createElement(Modal, { open: rulesModalIsOpen, onClose: closeModal, maxWidth: "md", classes: {
                container: css.modal,
                paper: css.modal__paper
            } }, !!rules && React.createElement(CheckinRules, { onSumbit: acceptRules, rules: rules.CheckinRules })))));
};
export default withRouter(Initial);
