import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { Choosed, DeleteIcon } from '../../../../Icons';
import { Statuses } from '../../../types';
import { LoginType, useAccountExistenceQuery, useConfirmMethodMutation, useSaveMethodMutation, useSendPersonalDetailsMutation, useSendSecureCodeMutation } from '@websky/graphql';
import { clearPhoneNumber } from '../../../../PhoneInput/utils';
import { useIataCode } from '../../../../hooks';
import { BaseButton } from '../../../../BaseComponents';
import SimpleLoader from '../../../../SimpleLoader';
import AuthDialog from '../AuthDialog/AuthDialog';
export var ModalTypesEnum;
(function (ModalTypesEnum) {
    ModalTypesEnum["Remove"] = "Remove";
    ModalTypesEnum["Connect"] = "Connect";
    ModalTypesEnum["Change"] = "Change";
})(ModalTypesEnum || (ModalTypesEnum = {}));
var AuthMethod = function (props) {
    var _a;
    var css = useTheme('Account').InfoField, t = useTranslation('Account').t;
    var iataCode = useIataCode();
    var _b = __read(useState(props.value), 2), inputValue = _b[0], setInputValue = _b[1], _c = __read(useState(null), 2), loyaltyCard = _c[0], setLoyaltyCard = _c[1], _d = __read(useState(null), 2), error = _d[0], setError = _d[1], _e = __read(useState(null), 2), verificationError = _e[0], setVerificationError = _e[1], _f = __read(useState(null), 2), modalType = _f[0], setModalType = _f[1], _g = __read(useState(false), 2), isLoading = _g[0], setIsLoading = _g[1];
    var _h = __read(useSaveMethodMutation(), 1), saveMethod = _h[0];
    var _j = __read(useSendPersonalDetailsMutation(), 1), updateUserProfile = _j[0];
    var _k = __read(useConfirmMethodMutation(), 1), confirmMethod = _k[0];
    var _l = __read(useSendSecureCodeMutation(), 1), sendCode = _l[0];
    var checkAccountExistence = useAccountExistenceQuery({
        skip: true
    });
    var errors = { error: error, verificationError: verificationError };
    var cleanInputValue = props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue;
    var isSubmitAvailable = !!inputValue
        ? props.type === LoginType.Phone
            ? clearPhoneNumber(props.value) !== clearPhoneNumber(inputValue)
            : props.value !== inputValue
        : false;
    var onSuccess = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, props.refetchInfo()];
                case 1:
                    _c.sent();
                    (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.VerificationSuccess);
                    (_b = props.onConnectResult) === null || _b === void 0 ? void 0 : _b.call(props, true);
                    return [2 /*return*/];
            }
        });
    }); }, [props.refetchInfo, props.setStatus, props.onConnectResult]);
    var onError = useCallback(function (error) {
        var _a, _b;
        setVerificationError(error);
        (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.VerificationError);
        (_b = props.onConnectResult) === null || _b === void 0 ? void 0 : _b.call(props, false);
    }, [setVerificationError, props.setStatus, props.onConnectResult]);
    var onUpdateUserProfileQuery = useCallback(function (loyaltyCard) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, updateUserProfile({
                            variables: {
                                params: {
                                    loyaltyCards: [loyaltyCard]
                                }
                            }
                        })];
                case 2:
                    data = (_a.sent()).data;
                    if (!(data === null || data === void 0 ? void 0 : data.UpdateUserProfile)) {
                        onError(Error(t('Incorrect card number or password')));
                        setIsLoading(false);
                    }
                    if (!(modalType === ModalTypesEnum.Change)) return [3 /*break*/, 4];
                    return [4 /*yield*/, props.onDelete(props.id)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, onSuccess()];
                case 5:
                    _a.sent();
                    setIsLoading(false);
                    onCloseModal();
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _a.sent();
                    onError(e_1);
                    setIsLoading(false);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [props.id, modalType, props.onDelete, updateUserProfile, onSuccess, onError, setIsLoading]);
    var onUpdateUserProfile = useCallback(function (pinCode) { return onUpdateUserProfileQuery(__assign(__assign({}, loyaltyCard), { pinCode: pinCode })); }, [loyaltyCard, onUpdateUserProfileQuery]);
    var onConfirmedMethod = useCallback(function (secureCode) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, confirmMethod({
                            variables: {
                                params: {
                                    loginType: props.type,
                                    login: cleanInputValue,
                                    secureCode: secureCode
                                }
                            }
                        })];
                case 1:
                    data = (_c.sent()).data;
                    if (!((_a = data === null || data === void 0 ? void 0 : data.ConfirmAuthMethod) === null || _a === void 0 ? void 0 : _a.result)) {
                        onError(Error((_b = data === null || data === void 0 ? void 0 : data.ConfirmAuthMethod) === null || _b === void 0 ? void 0 : _b.message));
                        return [2 /*return*/];
                    }
                    if (!(modalType === ModalTypesEnum.Change)) return [3 /*break*/, 3];
                    return [4 /*yield*/, props.onDelete(props.id)];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3: return [4 /*yield*/, onSuccess()];
                case 4:
                    _c.sent();
                    onCloseModal();
                    return [3 /*break*/, 6];
                case 5:
                    e_2 = _c.sent();
                    onError(e_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [props.type, modalType, cleanInputValue, confirmMethod, onSuccess, onError]);
    var onSubmit = useCallback(function (field) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    (_a = props.setStatus) === null || _a === void 0 ? void 0 : _a.call(props, Statuses.VerificationLoading);
                    if (!(props.type === LoginType.FFP)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onUpdateUserProfile(field.confirmationCode)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, onConfirmedMethod(field.confirmationCode)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [props.type, onUpdateUserProfile, onConfirmedMethod, props.setStatus]);
    var onContinueClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accountExistence, _a, data, errors_1, error_1;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return __generator(this, function (_p) {
            switch (_p.label) {
                case 0:
                    _p.trys.push([0, 5, , 6]);
                    (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.Loading);
                    return [4 /*yield*/, checkAccountExistence.refetch({
                            params: {
                                login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue,
                                loginType: props.type
                            }
                        })];
                case 1:
                    accountExistence = _p.sent();
                    if ((_d = (_c = accountExistence === null || accountExistence === void 0 ? void 0 : accountExistence.data) === null || _c === void 0 ? void 0 : _c.AccountExistence) === null || _d === void 0 ? void 0 : _d.result) {
                        (_e = props.onConnectResult) === null || _e === void 0 ? void 0 : _e.call(props, false);
                        throw Error("".concat(props.type, " is already in use by another account"));
                    }
                    if (!(props.type === LoginType.FFP)) return [3 /*break*/, 2];
                    setLoyaltyCard({ airlineCode: iataCode, cardNumber: inputValue });
                    (_f = props.setStatus) === null || _f === void 0 ? void 0 : _f.call(props, Statuses.Verification);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, saveMethod({
                        variables: {
                            params: {
                                loginType: props.type,
                                login: props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue
                            }
                        }
                    })];
                case 3:
                    _a = _p.sent(), data = _a.data, errors_1 = _a.errors;
                    if (errors_1 === null || errors_1 === void 0 ? void 0 : errors_1.length) {
                        setError(errors_1[0]);
                        (_g = props.onConnectResult) === null || _g === void 0 ? void 0 : _g.call(props, false);
                    }
                    else if ((_h = data === null || data === void 0 ? void 0 : data.SaveAuthMethod) === null || _h === void 0 ? void 0 : _h.result) {
                        (_j = props.setStatus) === null || _j === void 0 ? void 0 : _j.call(props, Statuses.Verification);
                    }
                    else {
                        (_k = props.onConnectResult) === null || _k === void 0 ? void 0 : _k.call(props, false);
                        throw Error((_l = data === null || data === void 0 ? void 0 : data.SaveAuthMethod) === null || _l === void 0 ? void 0 : _l.message);
                    }
                    _p.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _p.sent();
                    setError(error_1);
                    (_m = props.setStatus) === null || _m === void 0 ? void 0 : _m.call(props, Statuses.default);
                    (_o = props.onConnectResult) === null || _o === void 0 ? void 0 : _o.call(props, false);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (props.autoConnectValue) {
            if (props.type === LoginType.FFP && props.autoConnectCode) {
                onUpdateUserProfileQuery({
                    airlineCode: iataCode,
                    cardNumber: props.autoConnectValue,
                    pinCode: props.autoConnectCode
                });
            }
            else if (props.type === LoginType.Email) {
                setInputValue(props.autoConnectValue);
                if (inputValue) {
                    setModalType(ModalTypesEnum.Connect);
                    onContinueClick();
                }
            }
        }
    }, [inputValue, props.autoConnectValue]);
    var isSocialAuth = useMemo(function () {
        return [LoginType.Facebook, LoginType.Google, LoginType.Vkontakte, LoginType.TinkoffID].includes(props.type);
    }, [props.type]);
    var resendCode = useCallback(function () {
        var login = props.type === LoginType.Phone ? clearPhoneNumber(inputValue) : inputValue;
        return sendCode({
            variables: {
                params: {
                    login: login,
                    loginType: props.type
                }
            }
        });
    }, [props.type, inputValue]);
    var onOpenModal = useCallback(function (type) {
        setModalType(type);
    }, []);
    var onCloseModal = useCallback(function () {
        var _a, _b;
        setError(null);
        setVerificationError(null);
        setModalType(null);
        setInputValue(props.value);
        if (props.type === LoginType.Email && props.autoConnectValue) {
            (_a = props.onConnectResult) === null || _a === void 0 ? void 0 : _a.call(props, false);
        }
        (_b = props.setStatus) === null || _b === void 0 ? void 0 : _b.call(props, Statuses.default);
    }, [props.type, props.value, props.autoConnectValue, props.onConnectResult, props.setStatus]);
    var onDelete = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onDelete(props.id)];
                case 1:
                    _a.sent();
                    onCloseModal();
                    return [2 /*return*/];
            }
        });
    }); }, [props.id, props.onDelete, onCloseModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.field, props.className), "data-type": props.type },
            React.createElement("div", { className: css.field__info },
                props.icon && React.createElement("div", { className: css.field__icon }, props.icon),
                React.createElement("div", null,
                    React.createElement("div", { className: css.field__name },
                        t(props.type),
                        " ",
                        props.confirmed && Choosed),
                    React.createElement("div", { className: css.field__user },
                        props.value && React.createElement("span", { className: css.field__value }, props.value),
                        React.createElement("span", { className: css.field__status }, props.confirmed ? t('Connected') : t('Not connected'))))),
            React.createElement("div", { className: css.field__buttons }, props.confirmed ? (React.createElement(React.Fragment, null,
                !isSocialAuth && (React.createElement(BaseButton, { variant: "smallSecondary", className: cn(css.field__button, css.field__change), onClick: function () { return onOpenModal(ModalTypesEnum.Change); } }, t('Edit'))),
                props.isRemovable && (React.createElement("span", { className: css.field__delete, onClick: function () { return onOpenModal(ModalTypesEnum.Remove); } }, (_a = props.deleteIcon) !== null && _a !== void 0 ? _a : DeleteIcon)))) : (React.createElement(BaseButton, { variant: "smallNeitral", className: cn(css.field__button, css.field__connect), onClick: function () { return onOpenModal(ModalTypesEnum.Connect); } }, t('Connect')))),
            React.createElement(AuthDialog, { type: modalType, isOpen: !!modalType, onClose: onCloseModal, onSubmit: onSubmit, onDelete: onDelete, onChange: setInputValue, status: props.status, fieldType: props.type, fieldValue: inputValue, errors: errors, setStatus: props.setStatus, setError: setError, setVerificationError: setVerificationError, resendCode: resendCode, onContinue: onContinueClick, isSubmitAvailable: isSubmitAvailable, isAutoConnect: !!props.autoConnectValue })),
        isLoading && props.autoConnectValue && React.createElement(SimpleLoader, null)));
};
export default AuthMethod;
