var _a;
import * as React from 'react';
import cn from 'classnames';
import { checkIcon, dollarIcon, noIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import { FareFeaturePaymentType } from '@websky/graphql';
var availabilityIcons = (_a = {},
    _a[FareFeaturePaymentType.Free] = checkIcon,
    _a[FareFeaturePaymentType.Pay] = dollarIcon,
    _a[FareFeaturePaymentType.NotAvailable] = noIcon,
    _a);
var Cell = function (_a) {
    var _b, _c;
    var cell = _a.cell, isFullFareCondition = _a.isFullFareCondition, customAvailabilityIcons = _a.customAvailabilityIcons;
    var css = useTheme('CompareFares').CompareFares;
    var icons = customAvailabilityIcons !== null && customAvailabilityIcons !== void 0 ? customAvailabilityIcons : availabilityIcons;
    if (cell === null) {
        return (React.createElement("div", { className: cn(css.cell, (_b = {},
                _b[css.cell_notFullFareConditions] = !isFullFareCondition,
                _b)) },
            React.createElement("div", { className: css.cellContent }, '')));
    }
    var description = cell.description, value = cell.value, availability = cell.availability, shortDescription = cell.shortDescription;
    return (React.createElement("div", { className: cn(css.cell, (_c = {},
            _c[css.cell_notFullFareConditions] = !isFullFareCondition,
            _c)) },
        isFullFareCondition && (React.createElement("div", { className: css.col },
            React.createElement("div", { className: cn(css.cellContent, css.cellContent_fullDescr) }, description))),
        !isFullFareCondition && (React.createElement("div", { className: css.cellContent },
            value && React.createElement("span", null, value),
            !value &&
                (availability === FareFeaturePaymentType.Free ? (React.createElement("div", { className: css.availabilityIcon }, icons[availability])) : (React.createElement("div", { className: css.cellContent }, shortDescription)))))));
};
export default Cell;
