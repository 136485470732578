import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useForm } from 'react-final-form';
import { toggleMultiCity } from '../../../../store/segments/actions';
import { isMultiCity } from '../../../../store/segments/selectors';
import { useTheme } from '../../../../../theme';
var MultiCity = memo(function (props) {
    var t = useTranslation('SearchForm').t;
    var css = useTheme('SearchForm').MultiCity;
    var onMultiCityChange = function () { return props.toggleMultiCity(); };
    var form = useForm();
    var onChange = function () {
        onMultiCityChange();
        form.reset();
    };
    return (React.createElement("div", { className: css.multiCity },
        React.createElement("label", { className: css.label },
            React.createElement("input", { className: css.input, type: "checkbox", onChange: onChange, checked: props.isMultiCity }),
            React.createElement("span", { className: css.caption }, t(props.isMultiCity ? 'Set simple route' : 'Set multi city route')))));
});
var mapStateToProps = function (state) {
    return {
        isMultiCity: isMultiCity(state)
    };
};
var mapDispatchToProps = {
    toggleMultiCity: toggleMultiCity
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiCity);
