import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { groupRefundServicesByPassenger, groupServicesByType } from '../utils';
import Baggage from './Services/Baggage/Baggage';
import Meal from './Services/Meal/Meal';
import { useTheme } from '../../theme';
import Seat from './Services/Seat/Seat';
import Notice from '../components/Notice/Notice';
import Stub from './Services/Stub/Stub';
import { useCallback } from 'react';
import { isCheckoutOrderGuard } from '../../typeguards';
var RefundSummary = function (_a) {
    var className = _a.className, order = _a.order, gdsServicesRefund = _a.gdsServicesRefund, notification = _a.notification, isLoading = _a.isLoading, customWarningMessage = _a.customWarningMessage;
    var t = useTranslation('Exchange').t;
    var groupServices = React.useMemo(function () {
        var _a, _b;
        if ((_a = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _a === void 0 ? void 0 : _a.gdsServicesRefundInfo) {
            var servicesByType = groupServicesByType((_b = gdsServicesRefund === null || gdsServicesRefund === void 0 ? void 0 : gdsServicesRefund.GetGdsServicesRefundInfo) === null || _b === void 0 ? void 0 : _b.gdsServicesRefundInfo);
            var refundServicesByType_1 = new Map();
            servicesByType.forEach(function (value, key) {
                refundServicesByType_1.set(key, groupRefundServicesByPassenger(value));
            });
            return refundServicesByType_1;
        }
        return null;
    }, [gdsServicesRefund]);
    var getServiceCardByType = function (refundServices, type) {
        var props = {
            passengers: order.travellers,
            services: refundServices,
            flight: isCheckoutOrderGuard(order) ? order.flight : order.segments
        };
        switch (type) {
            case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                return React.createElement(Baggage, __assign({}, props));
            case OrderAdditionalServiceGdsServiceServiceType.Meal:
                return React.createElement(Meal, __assign({}, props));
            case OrderAdditionalServiceGdsServiceServiceType.Seat:
                return React.createElement(Seat, __assign({}, props));
        }
        return null;
    };
    var renderServicesCard = React.useMemo(function () {
        var services = [];
        groupServices === null || groupServices === void 0 ? void 0 : groupServices.forEach(function (value, key) {
            if (Object.keys(value).length > 0) {
                services.push(getServiceCardByType(value, key));
            }
        });
        if (services.length) {
            return services.map(function (component, key) {
                return React.createElement(React.Fragment, { key: key }, component);
            });
        }
        return null;
    }, [groupServices]);
    var theme = useTheme('RefundSummary').RefundSummary;
    var WarningMessage = useCallback(function () {
        if (customWarningMessage) {
            return customWarningMessage;
        }
        return (React.createElement("div", null,
            React.createElement(Notice, { type: 'error', header: t('Exchange restrictions'), text: t('All services issued on one electronic document will be canceled, even if you change itinerary partial. Money refund will be carried out by the airline in accordance with the terms of the Puplic Offer') }),
            React.createElement("div", { className: cn(theme.selectedServices, className) }, renderServicesCard)));
    }, [isLoading, renderServicesCard, customWarningMessage]);
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement("div", { className: cn(theme.container, className) },
            React.createElement(Stub, null),
            React.createElement(Stub, null),
            React.createElement(Stub, null))),
        !isLoading && !!renderServicesCard && React.createElement(WarningMessage, null)));
};
export default RefundSummary;
