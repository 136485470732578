import { __extends } from "tslib";
var EmptySearchResultSegmentsError = /** @class */ (function (_super) {
    __extends(EmptySearchResultSegmentsError, _super);
    function EmptySearchResultSegmentsError() {
        var _this = _super.call(this) || this;
        _this.name = 'EmptySearchResultSegmentsError';
        Object.setPrototypeOf(_this, EmptySearchResultSegmentsError.prototype);
        return _this;
    }
    return EmptySearchResultSegmentsError;
}(Error));
export { EmptySearchResultSegmentsError };
