import { __read } from "tslib";
import { useState } from 'react';
export var useSeatMapToolbarProps = function (seatMap) {
    var _a = __read(useState(0), 2), segmentIndex = _a[0], setSegment = _a[1];
    var _b = __read(useState(0), 2), passengerIndex = _b[0], setPassenger = _b[1];
    var onToolbarButtonClick = function (callback) {
        if (segmentIndex + 1 < seatMap.segments.length) {
            setSegment(segmentIndex + 1);
            setPassenger(0);
            return;
        }
        callback();
    };
    return {
        segmentIndex: segmentIndex,
        passengerIndex: passengerIndex,
        setSegment: setSegment,
        setPassenger: setPassenger,
        onToolbarButtonClick: onToolbarButtonClick
    };
};
