import * as React from 'react';
import cn from 'classnames';
import { Link, Money } from '../../../index';
import { useTheme } from '../../../theme';
import { useCallback } from 'react';
import { useToggleable } from '../../../hooks';
var FareSelect = function (props) {
    var fares = props.fares, onSelect = props.onSelect, onOpenModal = props.onOpenModal;
    var css = useTheme('FlightSelect').FareSelect;
    var handleSelect = useCallback(function (fare) {
        var select = onSelect(fare);
        select();
    }, [fares, onSelect]);
    // https://youtrack.websky.aero/issue/WEBSKY-5478/optimizaciya-zaprosov-na-poiskovoj-vydache
    // const renderTooltipContent = useCallback(
    // 	(fare: FareFamilyPriceFragment) => {
    // 		return (
    // 			<>
    // 				{fare.prices[0]?.flight.fares[0].passengerFares.map(passengerFare => (
    // 					<div key={passengerFare.totalFare.amount}>
    // 						{passengerFare.taxes.map(tax => (
    // 							<div key={tax.code} className={css.fee}>
    // 								Fee {tax.code} <Money money={tax.price} />
    // 							</div>
    // 						))}
    // 						<div className={cn(css.fee, css.active)}>
    // 							Tariff <Money money={passengerFare.baseFare} />
    // 						</div>
    // 						<div className={cn(css.fee, css.active)}>
    // 							Total <Money money={passengerFare.totalFare} />
    // 						</div>
    // 					</div>
    // 				))}
    // 			</>
    // 		);
    // 	},
    // 	[fares]
    // );
    var renderFareInfo = useCallback(function (fare, i) {
        var _a;
        var _b, _c, _d;
        // const { isOpen, open, close } = useToggleable(false);
        var _e = useToggleable(false), open = _e.open, close = _e.close;
        if (!fare.prices || !((_b = fare.prices[0]) === null || _b === void 0 ? void 0 : _b.price)) {
            return null;
        }
        return (React.createElement("div", { onMouseEnter: open, onMouseLeave: close, className: css.fare, key: fare.fareFamily.title },
            React.createElement("span", { className: css.code }, (_c = fare.prices[0]) === null || _c === void 0 ? void 0 : _c.flight.fares[0].passengerFares[0].priceClasses[0].classCode),
            React.createElement(Link, { className: css.name, action: function (e) {
                    // do not show modal if the user clicks on a specific tariff
                    e.stopPropagation();
                    handleSelect(fare);
                } },
                React.createElement(Money, { money: (_d = fare.prices[0]) === null || _d === void 0 ? void 0 : _d.price, moneyClassName: cn(css.price, (_a = {}, _a[css.bold] = i === 0, _a)) }))));
    }, [fares, onSelect]);
    return (React.createElement("div", { onClick: onOpenModal, className: css.wrapper }, fares.map(renderFareInfo)));
};
export default FareSelect;
