import { __assign } from "tslib";
import { useDesktopFlight } from '../../hooks';
import { useToggleable } from '../../../hooks';
import { initI18n } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { FareFamilyOptionsType, FareFeaturePaymentType } from '@websky/graphql';
var OFFER_UPGRADE_IF_UNAVAILABLE = [
    FareFamilyOptionsType.Exchangeable,
    FareFamilyOptionsType.Baggage,
    FareFamilyOptionsType.Refundable
];
initI18n('DesktopFlight');
export var useDesktopFlightWithPopup = function (props, defaultModalState) {
    if (defaultModalState === void 0) { defaultModalState = false; }
    var _a = useToggleable(defaultModalState), isModalOpen = _a.isOpen, openModal = _a.open, closeModal = _a.close;
    var _b = useToggleable(false), isComparisonOpen = _b.isOpen, setIsComparisonOpen = _b.setOpen;
    var upgradeOptions = new Map();
    var desktopFlightData = useDesktopFlight(props);
    var t = useTranslation('DesktopFlight').t;
    // wrap openModal with function, which implement interface of getOnFareClickHandler
    // but call openModal instead of previous logic
    var handleModalOpen = function (serviceClass) { return openModal; };
    props.flight.pricesForFareGroups.forEach(function (priceForFareGroup, index) {
        var _a, _b, _c, _d;
        if (!priceForFareGroup.prices) {
            return;
        }
        var nextFare = props.flight.pricesForFareGroups[index + 1];
        if (!nextFare || desktopFlightData.lastSelectedFare) {
            return;
        }
        // collect all unavailable options on current fare
        var fareUnavailableServicesTypes = (_c = (_b = (_a = priceForFareGroup.fareFamily) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.filter(function (option) { return option.availability === FareFeaturePaymentType.NotAvailable; })) === null || _c === void 0 ? void 0 : _c.map(function (option) { return option.type; });
        // collect all available options on next fare
        // options which affected, listed in OFFER_UPGRADE_IF_UNAVAILABLE array
        var availableOptionsOnNextFare = Array.from(new Set((_d = nextFare.fareFamily) === null || _d === void 0 ? void 0 : _d.options.filter(function (option) {
            return option.availability !== FareFeaturePaymentType.NotAvailable &&
                (fareUnavailableServicesTypes === null || fareUnavailableServicesTypes === void 0 ? void 0 : fareUnavailableServicesTypes.includes(option.type)) &&
                OFFER_UPGRADE_IF_UNAVAILABLE.includes(option.type);
        }).map(function (option) { return option.type; })));
        if (availableOptionsOnNextFare.length) {
            var upgradeOffer = t("Need_".concat(availableOptionsOnNextFare[0] === FareFamilyOptionsType.Baggage ? "m" : "f", " ")) +
                availableOptionsOnNextFare
                    .map(function (availableOptionOnNextSegment, index) {
                    switch (availableOptionOnNextSegment) {
                        case FareFamilyOptionsType.Baggage:
                            return t('baggage');
                        case FareFamilyOptionsType.Exchangeable:
                            return t('the ability to exchange ticket');
                        case FareFamilyOptionsType.Refundable:
                            return t('the ability to refund ticket');
                        default:
                            return '';
                    }
                })
                    .join(t('and'));
            var upgradeOption = {
                canUpgradeTo: nextFare,
                optionsAvailableOnNextFare: availableOptionsOnNextFare,
                upgradeOffer: upgradeOffer
            };
            upgradeOptions.set(priceForFareGroup.fareFamily.id, upgradeOption);
        }
    });
    return __assign(__assign({}, desktopFlightData), { isModalOpen: isModalOpen, openModal: openModal, getFareGroupTabHandler: handleModalOpen, closeModal: closeModal, upgradeOptions: upgradeOptions, isComparisonOpen: isComparisonOpen, setIsComparisonOpen: setIsComparisonOpen });
};
