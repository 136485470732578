import { __assign } from "tslib";
import { handleAction } from 'redux-actions';
import { SET_PRICE } from './action';
import { Currency } from '../../../enums';
export var initialPriceBreakdownState = {
    totalPrice: {
        amount: 0,
        currency: Currency.USD
    },
    servicePrice: {
        amount: 0,
        currency: Currency.USD
    },
    upsalePrice: {
        amount: 0,
        currency: Currency.USD
    }
};
export var priceBreakdownReducer = handleAction(SET_PRICE, function (state, action) {
    if (action.payload) {
        return __assign(__assign({}, state), action.payload);
    }
    return state;
}, initialPriceBreakdownState);
