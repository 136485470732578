import { __assign, __read, __values } from "tslib";
import { useSelector } from 'react-redux';
import { getOrderId, getTravellers, getIsNew } from '../../../../store/order/selectors';
import { useOrderSubsidyInfoQuery } from '@websky/graphql';
import { useCallback, useMemo, useState } from 'react';
export var useSubsidies = function () {
    var orderId = useSelector(getOrderId), travellers = useSelector(getTravellers), isNewOrder = useSelector(getIsNew), isSubsidiesAvailable = travellers.every(function (traveller) { var _a, _b; return !!((_b = (_a = traveller.subsidyInfo) === null || _a === void 0 ? void 0 : _a.subsidies) === null || _b === void 0 ? void 0 : _b.length); }), _a = __read(useState(travellers.map(function (traveller) { return ({
        traveller: traveller,
        subsidyId: null,
        failedSegment: null,
        rejectMessage: null,
        isSubsidyApplied: false,
        subsidies: []
    }); })), 2), subsidyTravellers = _a[0], setSubsidyTravellers = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var refetch = useOrderSubsidyInfoQuery({
        skip: true
    }).refetch;
    var isSubsidiesRejected = useMemo(function () {
        return subsidyTravellers.some(function (traveller) { return traveller.isSubsidyRejected; });
    }, [subsidyTravellers]);
    var checkSubsidy = useCallback(function (traveller) {
        setLoading(true);
        return new Promise(function (resolve) {
            refetch({
                id: orderId,
                travellers: [
                    __assign({}, traveller)
                ]
            })
                .then(function (_a) {
                var e_1, _b;
                var _c;
                var data = _a.data;
                var failedSegment = null, rejectMessage = null, isSubsidyApplied = false, newSubsidyTraveller = {
                    values: traveller.values,
                    id: traveller.id
                };
                var subsidies = [];
                if (data.OrderSubsidyInfo) {
                    isSubsidyApplied = true;
                    try {
                        for (var _d = __values(data.OrderSubsidyInfo.segments), _e = _d.next(); !_e.done; _e = _d.next()) {
                            var segment = _e.value;
                            var travellerWithSubsidy = segment.travellers.find(function (passenger) { return passenger.traveller.id === traveller.id; });
                            newSubsidyTraveller = travellerWithSubsidy.traveller;
                            if (!!((_c = travellerWithSubsidy === null || travellerWithSubsidy === void 0 ? void 0 : travellerWithSubsidy.subsidyInfo) === null || _c === void 0 ? void 0 : _c.rejectMessage)) {
                                rejectMessage = travellerWithSubsidy.subsidyInfo.rejectMessage;
                                failedSegment = segment.segment;
                                isSubsidyApplied = false;
                                break;
                            }
                            subsidies.push({
                                segmentId: segment.segment.id,
                                subsidyId: travellerWithSubsidy.subsidyInfo.subsidyId
                            });
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                setSubsidyTravellers(function (subsidyTravellers) {
                    return subsidyTravellers.map(function (subsidyTraveller) {
                        if (subsidyTraveller.traveller.id === traveller.id) {
                            return {
                                traveller: __assign({}, newSubsidyTraveller),
                                failedSegment: failedSegment,
                                rejectMessage: rejectMessage,
                                subsidyId: traveller.subsidyId,
                                isSubsidyApplied: isSubsidyApplied,
                                isSubsidyRejected: !isSubsidyApplied,
                                subsidies: subsidies
                            };
                        }
                        return subsidyTraveller;
                    });
                });
                resolve({
                    isSubsidyApplied: isSubsidyApplied,
                    failedSegment: failedSegment,
                    rejectMessage: rejectMessage,
                    traveller: __assign({}, newSubsidyTraveller)
                });
            })
                .catch(function (error) { })
                .finally(function () { return setLoading(false); });
        });
    }, []);
    return useMemo(function () {
        return {
            travellers: subsidyTravellers,
            isLoading: loading,
            isSubsidiesAvailable: isSubsidiesAvailable,
            checkSubsidy: checkSubsidy,
            isSubsidiesRejected: isSubsidiesRejected,
            canCheckSubsidies: isNewOrder && isSubsidiesAvailable && !isSubsidiesRejected
        };
    }, [subsidyTravellers, loading, isSubsidiesAvailable, checkSubsidy, isSubsidiesRejected, isNewOrder]);
};
