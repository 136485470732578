import { __read } from "tslib";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAeroexpress, getOrder } from '../../../store/order/selectors';
import { CartService } from '../../../../Cart/types';
import { setServiceAction } from '../../../store/selectedServices/actions';
import { useInsurance } from '../../../../CheckoutAdditionalService/components/Insurance/hooks';
import { getPassengerState } from '../../../store/passengers/selectors';
import { fillOrder } from '../../../store/order/actions';
import { isServicesSaving } from '../../../store/servicesIsSaving/selectors';
import { useSaveOrderServicesMutation } from '@websky/graphql';
export var useCart = function () {
    var _a, _b;
    var order = useSelector(getOrder), dispatch = useDispatch();
    var _c = __read(useSaveOrderServicesMutation(), 2), saveOrderServicesMutation = _c[0], _d = _c[1], data = _d.data, loading = _d.loading;
    var servicesSaving = useSelector(isServicesSaving);
    var passengers = useSelector(getPassengerState);
    var aeroexpress = useSelector(getAeroexpress);
    var onSave = useInsurance({
        orderId: order.id,
        insurances: order.additionalServices.insurances,
        insurancePrograms: (_a = order.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms,
        passengers: passengers,
        onSaveServices: saveOrderServicesMutation
    }).onSave;
    var aeroexpressSelectedIds = (_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.selectedTrips) === null || _b === void 0 ? void 0 : _b.map(function (trip) { return trip.id; });
    useEffect(function () {
        if (!loading && data && data.SaveOrderServices) {
            dispatch(fillOrder(data === null || data === void 0 ? void 0 : data.SaveOrderServices));
        }
    }, [data, loading]);
    var onDeleteItem = function (type, service) {
        // GdsServices
        if (type === CartService.Baggage ||
            type === CartService.Meal ||
            type === CartService.Seats ||
            type === CartService.Extra ||
            type === CartService.BusinessLounge) {
            var gdsService_1 = service;
            var segmentIds = gdsService_1.allSegments
                ? order.flight.segments.map(function (segment) { return segment.segment.id; })
                : [gdsService_1.segmentId];
            segmentIds.forEach(function (segmentId) {
                var _a;
                dispatch(setServiceAction({
                    allowedSegments: gdsService_1.allowedSegments,
                    passengerId: gdsService_1.passengerId,
                    segmentId: segmentId,
                    serviceId: gdsService_1.serviceId,
                    count: (_a = gdsService_1.count) !== null && _a !== void 0 ? _a : 0
                }));
            });
        }
        else if (type === CartService.Insurances) {
            onSave(order.additionalServices.insurances.selectedInsurances
                .filter(function (selected) { return selected.insuranceProgram.code !== service.code; })
                .map(function (selected) { return selected.insuranceProgram.code; }));
        }
        else if (type === CartService.Aeroexpress) {
            var aeroexpress_1 = service;
            saveOrderServicesMutation({
                variables: {
                    params: {
                        id: order.id,
                        aeroexpress: { selectedTrips: aeroexpressSelectedIds.filter(function (id) { return id !== aeroexpress_1.tripId; }) }
                    }
                }
            });
        }
    };
    return {
        onDeleteItem: onDeleteItem,
        loading: loading || servicesSaving
    };
};
