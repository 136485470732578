export var isAllPassengersSelector = function (passenger, travellersCount) {
    var _a;
    return ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) === travellersCount - 1;
};
export var getSelectorsInfo = function (passengers, travellersCount) {
    var isAllPassengersSelectorChecked = false;
    var hasNotAllPassengersSelectorChecked = false;
    passengers.forEach(function (passenger) {
        if (isAllPassengersSelector(passenger, travellersCount)) {
            if (passenger.selected) {
                isAllPassengersSelectorChecked = true;
            }
        }
        else {
            if (passenger.selected) {
                hasNotAllPassengersSelectorChecked = true;
            }
        }
    });
    return {
        isAllPassengersSelectorChecked: isAllPassengersSelectorChecked,
        hasNotAllPassengersSelectorChecked: hasNotAllPassengersSelectorChecked
    };
};
