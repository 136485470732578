import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import PaymentForm from '../../../../PaymentForm';
import PromoLoader from '../../../../PromoLoader/components/PromoLoader';
import Modal from '../../../../Modal';
import PaymentResultComponent from '../../../../PaymentResult/PaymentResult';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import MobileToolbar from '../../../../MobileToolbar';
import SlideBottom from '../../../../SlideBottom';
import { PaymentType } from '../../../../PaymentForm/types';
import { PaymentResultStatus } from '../Checkout';
import SimpleLoader from '../../../../SimpleLoader';
import { OverrideComponent } from '../../../../renderProps';
import { PaymentResult, useCheckoutPayment } from './Payment.hook';
import WarningModal from '../../../../WarningModal';
export var PAYMENT_KEY_TO_TYPE_MAP = new Map([
    ['card', PaymentType.Card],
    ['credit', PaymentType.Credit],
    ['kaspi', PaymentType.Kaspi],
    ['itransfer', PaymentType.Itransfer],
    ['gpay', PaymentType.Gpay],
    ['fps', PaymentType.Fps],
    ['sbol', PaymentType.Sbol]
]);
var Payment = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var theme = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation('Title').t;
    var model = useCheckoutPayment(props);
    var renderPaymentResult = useCallback(function () { return (React.createElement(PaymentResultComponent, { isModal: true, result: model.paymentResult, onClick: model.paymentResult === PaymentResult.Success ? model.onPaymentComplete : model.closePaymentResult, onBackToOrder: props.onBackToOrder })); }, [model.paymentResult, model.onPaymentComplete, props.onBackToOrder, model.closePaymentResult]);
    return model.loading ? (React.createElement(PromoLoader, { text: t('Checkout:Proceeding to payment...') })) : (React.createElement("div", { className: cn(theme.checkout, theme.checkout_narrow, theme.checkout_margined) },
        model.completePaymentLoading && React.createElement(SimpleLoader, null),
        React.createElement(Helmet, null,
            React.createElement("title", null,
                t('Title:companyName'),
                " \u2013 ",
                t('Payment'))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: !!model.paymentResult, onClose: model.closePaymentResult, className: theme.payment__slideBottom }, renderPaymentResult())),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: !!model.paymentResult, onClose: model.closePaymentResult, classes: {
                    paper: theme.payment__result
                } }, renderPaymentResult())),
        props.timelimit && (React.createElement("div", { className: theme.timelimit },
            React.createElement(OverrideComponent, { component: { CheckoutCurrencyWarning: function () { return null; } }, componentProps: { total: props.priceToPay.total } }))),
        React.createElement(OverrideComponent, { componentProps: {
                openFrameInNewTab: model.openFrameInNewTab,
                paymentPage: model.selectedPaymentPage,
                onPaymentMethodSelected: model.handleSelectPaymentMethod,
                onTestPayment: !model.testPaymentInProgress && model.testPaymentEnabled ? model.onTestPayment : null,
                kaspi: (_a = model.data) === null || _a === void 0 ? void 0 : _a.PaymentMethods.kaspi,
                card: (_b = model.data) === null || _b === void 0 ? void 0 : _b.PaymentMethods.card,
                googlePay: (_c = model.data) === null || _c === void 0 ? void 0 : _c.PaymentMethods.gpay,
                credit: (_d = model.data) === null || _d === void 0 ? void 0 : _d.PaymentMethods.credit,
                itransfer: (_e = model.data) === null || _e === void 0 ? void 0 : _e.PaymentMethods.itransfer,
                cash: (_f = model.data) === null || _f === void 0 ? void 0 : _f.PaymentMethods.cash,
                invoice: (_g = model.data) === null || _g === void 0 ? void 0 : _g.PaymentMethods.invoice,
                qiwi: (_h = model.data) === null || _h === void 0 ? void 0 : _h.PaymentMethods.qiwi,
                sbol: (_j = model.data) === null || _j === void 0 ? void 0 : _j.PaymentMethods.sbol,
                fps: (_k = model.data) === null || _k === void 0 ? void 0 : _k.PaymentMethods.fps,
                onPaymentFrameLoaded: props.onPaymentFrameLoaded,
                priceToPay: props.priceToPay,
                onSuccess: function () { return model.setPaymentResult(PaymentResult.Success); },
                onFail: function () { return model.setPaymentResult(PaymentResult.Fail); },
                onBackToOrder: props.onBackToOrder,
                order: props.order,
                onCompletePayment: model.onCompletePayment,
                isPaymentAvailable: model.orderPaymentResult === PaymentResultStatus.Success || !!((_l = model.data) === null || _l === void 0 ? void 0 : _l.PaymentMethods),
                orderIsRefetching: props.orderIsRefetching
            }, component: { CheckoutPaymentForm: PaymentForm } }),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileToolbar, { onBack: props.goToPrevStep })),
        React.createElement(WarningModal, { title: t('Checkout:Oops...'), content: t('Checkout:An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.'), errorMessage: t('PaymentForm:The payment with this method is unavailable.'), onButtonClick: model.onClearError, buttonText: t('Checkout:OK, thank you'), isOpen: !!model.requestError })));
};
export default Payment;
