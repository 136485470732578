import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../theme';
var sadIcon = (React.createElement("svg", { width: "40", height: "40", viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9835 3.33325C10.7835 3.33325 3.3335 10.7999 3.3335 19.9999C3.3335 29.1999 10.7835 36.6666 19.9835 36.6666C29.2002 36.6666 36.6668 29.1999 36.6668 19.9999C36.6668 10.7999 29.2002 3.33325 19.9835 3.33325ZM14.1668 18.3333C15.5475 18.3333 16.6668 17.214 16.6668 15.8333C16.6668 14.4525 15.5475 13.3333 14.1668 13.3333C12.7861 13.3333 11.6668 14.4525 11.6668 15.8333C11.6668 17.214 12.7861 18.3333 14.1668 18.3333ZM25.8335 18.3333C27.2142 18.3333 28.3335 17.214 28.3335 15.8333C28.3335 14.4525 27.2142 13.3333 25.8335 13.3333C24.4528 13.3333 23.3335 14.4525 23.3335 15.8333C23.3335 17.214 24.4528 18.3333 25.8335 18.3333ZM11.4668 29.1666C12.8002 25.7499 16.1168 23.3333 20.0002 23.3333C23.8835 23.3333 27.2002 25.7499 28.5335 29.1666H25.7502C24.5835 27.1833 22.4668 25.8333 20.0002 25.8333C17.5335 25.8333 15.4002 27.1833 14.2502 29.1666H11.4668ZM6.66683 19.9999C6.66683 27.3666 12.6335 33.3333 20.0002 33.3333C27.3668 33.3333 33.3335 27.3666 33.3335 19.9999C33.3335 12.6333 27.3668 6.66659 20.0002 6.66659C12.6335 6.66659 6.66683 12.6333 6.66683 19.9999Z", fill: "#CDCDCD" }),
    React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "3", width: "34", height: "34" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.9835 3.33325C10.7835 3.33325 3.3335 10.7999 3.3335 19.9999C3.3335 29.1999 10.7835 36.6666 19.9835 36.6666C29.2002 36.6666 36.6668 29.1999 36.6668 19.9999C36.6668 10.7999 29.2002 3.33325 19.9835 3.33325ZM14.1668 18.3333C15.5475 18.3333 16.6668 17.214 16.6668 15.8333C16.6668 14.4525 15.5475 13.3333 14.1668 13.3333C12.7861 13.3333 11.6668 14.4525 11.6668 15.8333C11.6668 17.214 12.7861 18.3333 14.1668 18.3333ZM25.8335 18.3333C27.2142 18.3333 28.3335 17.214 28.3335 15.8333C28.3335 14.4525 27.2142 13.3333 25.8335 13.3333C24.4528 13.3333 23.3335 14.4525 23.3335 15.8333C23.3335 17.214 24.4528 18.3333 25.8335 18.3333ZM11.4668 29.1666C12.8002 25.7499 16.1168 23.3333 20.0002 23.3333C23.8835 23.3333 27.2002 25.7499 28.5335 29.1666H25.7502C24.5835 27.1833 22.4668 25.8333 20.0002 25.8333C17.5335 25.8333 15.4002 27.1833 14.2502 29.1666H11.4668ZM6.66683 19.9999C6.66683 27.3666 12.6335 33.3333 20.0002 33.3333C27.3668 33.3333 33.3335 27.3666 33.3335 19.9999C33.3335 12.6333 27.3668 6.66659 20.0002 6.66659C12.6335 6.66659 6.66683 12.6333 6.66683 19.9999Z", fill: "white" })),
    React.createElement("g", { mask: "url(#mask0)" })));
var ResultsError = function (_a) {
    var _b;
    var className = _a.className, header = _a.header, body = _a.body, _c = _a.withArrow, withArrow = _c === void 0 ? false : _c;
    var css = useTheme('ResultsError').ResultsError;
    return (React.createElement("div", { className: cn(className, css.wrapper, className, (_b = {},
            _b[css.wrapper_arrowed] = withArrow,
            _b)) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.icon }, sadIcon),
            React.createElement("span", null, header)),
        React.createElement("div", { className: css.body }, body)));
};
export default ResultsError;
