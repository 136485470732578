import * as React from 'react';
import { useEffect, useRef } from 'react';
var Button = function (_a) {
    var button = _a.button;
    var buttonHandler = useRef();
    useEffect(function () {
        if (buttonHandler) {
            buttonHandler.current.appendChild(button);
        }
    }, [buttonHandler, button]);
    return React.createElement("div", { ref: buttonHandler });
};
export default Button;
