export var getValueByName = function (values, name) {
    var _a, _b;
    if (!values.length) {
        return null;
    }
    return (_b = (_a = values.find(function (_a) {
        var valueName = _a.name;
        return valueName === name;
    })) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
};
export var getFfpInfo = function (userFfpInfo) {
    var _a;
    return {
        cardNumber: (_a = userFfpInfo === null || userFfpInfo === void 0 ? void 0 : userFfpInfo.cardNumber) !== null && _a !== void 0 ? _a : null,
        currentLevel: (userFfpInfo === null || userFfpInfo === void 0 ? void 0 : userFfpInfo.cardNumber) ? userFfpInfo.currentLevel : null,
        numberOfMiles: (userFfpInfo === null || userFfpInfo === void 0 ? void 0 : userFfpInfo.cardNumber) ? userFfpInfo.numberOfMiles : 0
    };
};
export var currentUserFactory = function (currentUser) {
    var _a, _b;
    if (!currentUser) {
        return null;
    }
    var findValueByName = getValueByName.bind(null, (_b = (_a = currentUser.userProfile) === null || _a === void 0 ? void 0 : _a.values) !== null && _b !== void 0 ? _b : []);
    return {
        id: currentUser.userProfile.id,
        firstName: findValueByName('firstName'),
        lastName: findValueByName('lastName'),
        gender: findValueByName('gender'),
        ffpInfo: getFfpInfo(currentUser.userFfpInfo)
    };
};
