import * as React from 'react';
import { useContext } from 'react';
import { getPassengerValuesMap } from '../utils';
import { CartContext } from '../Cart';
import { Notification, Person } from '../../Icons';
import ServiceSummary from '../../ServiceSummary';
import ServiceSummaryOption from '../../ServiceSummary/components/ServiceSummaryOption';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import ServiceSummaryDivider from '../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getUserValue } from '../../utils';
import { hideText } from '../../Passenger/utils';
import { CheckoutSteps } from '../../MobileStepbar/types';
var Passengers = function (_a) {
    var travellers = _a.travellers, showPassengers = _a.showPassengers, customer = _a.customer, renderService = _a.renderService;
    var cartContext = useContext(CartContext);
    var t = useTranslation('Cart').t, passengerT = useTranslation('Passenger').t;
    var items = React.useMemo(function () {
        return travellers.map(function (traveller) {
            var valuesMap = getPassengerValuesMap(traveller);
            var docType = passengerT(valuesMap.get(TravellerFieldEnum.DocType));
            var docNumber = hideText(valuesMap.get(TravellerFieldEnum.DocNumber));
            var gender = valuesMap.get(TravellerFieldEnum.Gender);
            var birthDate = valuesMap.get(TravellerFieldEnum.BirthDate);
            var loyalty = valuesMap.get(TravellerFieldEnum.LoyaltyNumber);
            var main = [
                t(traveller.type),
                gender ? (gender === 'M' ? passengerT('Male') : passengerT('Female')) : null,
                birthDate
            ].filter(Boolean);
            var documents = [docType, docNumber].filter(Boolean);
            var loyaltyInfo = [passengerT('loyaltyName'), loyalty];
            var travellerValues = {
                header: cartContext.travellersById.get(traveller.id).name,
                headerIcon: Person,
                items: [
                    { id: "main_".concat(traveller.id), name: main.join(' • ') },
                    { id: "document_".concat(traveller.id), name: documents.join(' • ') }
                ]
            };
            if (loyalty) {
                travellerValues.items.push({
                    id: "loyalty_".concat(traveller.id),
                    name: loyaltyInfo.join(' • ')
                });
            }
            return travellerValues;
        });
    }, [travellers]);
    var customerItems = React.useMemo(function () {
        if (customer === null || customer === void 0 ? void 0 : customer.values) {
            var email = getUserValue(customer, TravellerFieldEnum.Email), phone = getUserValue(customer, TravellerFieldEnum.Phone);
            var items_1 = [];
            if (email) {
                items_1.push({
                    id: 'email',
                    name: email
                });
            }
            if (phone) {
                items_1.push({
                    id: 'phone',
                    name: phone
                });
            }
            return {
                header: t('Contacts'),
                headerIcon: Notification,
                items: items_1
            };
        }
        return null;
    }, [customer]);
    var group = {
        header: t('Passengers'),
        icon: Person
    };
    var content = (React.createElement(React.Fragment, null,
        React.createElement(ServiceSummaryDivider, null, items.map(function (item, index) { return (React.createElement(ServiceSummaryOption, { groupOption: item, key: index })); })),
        customerItems && (React.createElement(ServiceSummaryDivider, null,
            React.createElement(ServiceSummaryOption, { groupOption: customerItems })))));
    if (!showPassengers) {
        return null;
    }
    if (renderService) {
        return renderService(CheckoutSteps.Passengers, group, content);
    }
    return React.createElement(ServiceSummary, { group: group }, content);
};
export default Passengers;
