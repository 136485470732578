import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DocTypes, ExareReason, ExareReasonDocs, TravellerFieldEnum, useChangeTravelerDataInfoQuery, useChangeTravellerDataPriceQuery } from '@websky/graphql';
import { getOrder } from '../../../Modules/Exchange/store/order/selectors';
import { getUserValue } from '../../../utils';
export var usePassengerForm = function (passenger, onFillOrder, reason) {
    var order = useSelector(getOrder);
    var _a = __read(React.useState(null), 2), requestError = _a[0], setRequestError = _a[1];
    var _b = __read(React.useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useChangeTravelerDataInfoQuery({
        fetchPolicy: 'no-cache',
        variables: {
            parameters: {
                orderId: order.id,
                travellerId: passenger.id,
                reason: reason
            }
        }
    }), data = _c.data, isTravellerDataLoading = _c.loading;
    var changeTravellerDataPrice = useChangeTravellerDataPriceQuery({ skip: true }).refetch;
    var editableFields = useMemo(function () {
        var _a, _b, _c, _d;
        var editableFieldsMap = new Map();
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.ChangeTravellerDataInfo) === null || _a === void 0 ? void 0 : _a.travellers) === null || _b === void 0 ? void 0 : _b.length) {
            var changeTravellerById = (_d = (_c = data === null || data === void 0 ? void 0 : data.ChangeTravellerDataInfo) === null || _c === void 0 ? void 0 : _c.travellers) === null || _d === void 0 ? void 0 : _d.find(function (traveller) { return traveller.id === passenger.id; });
            if (changeTravellerById) {
                changeTravellerById.values.forEach(function (value) { return editableFieldsMap.set(value.type, value); });
            }
        }
        return editableFieldsMap;
    }, [data === null || data === void 0 ? void 0 : data.ChangeTravellerDataInfo, passenger]);
    var editablePassenger = useMemo(function () {
        var values = passenger.values.map(function (value) {
            var editableField = editableFields.get(value.type);
            return __assign(__assign({}, value), { validationRules: (editableField === null || editableField === void 0 ? void 0 : editableField.validationRules) || value.validationRules, isEditable: editableFields.has(value.type) });
        });
        return __assign(__assign({}, passenger), { values: values });
    }, [passenger, editableFields]);
    var allowSwapNames = useMemo(function () {
        var _a, _b;
        var firstNameEditable = (_a = editablePassenger.values.find(function (value) { return value.type === TravellerFieldEnum.FirstName; })) === null || _a === void 0 ? void 0 : _a.isEditable;
        var lastNameEditable = (_b = editablePassenger.values.find(function (value) { return value.type === TravellerFieldEnum.LastName; })) === null || _b === void 0 ? void 0 : _b.isEditable;
        return firstNameEditable && lastNameEditable;
    }, [editablePassenger]);
    var onRequestError = useCallback(function (e) {
        var error = e;
        var graphQLErrors = error.graphQLErrors;
        if (graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.length) {
            setRequestError(graphQLErrors[0]);
        }
    }, []);
    var onClearError = useCallback(function () { return setRequestError(null); }, []);
    var onSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var valuesState, valuesPassenger_1, passengerFiles, changedDocType, isPassport_1, isAutoMode, travellers, data_1, e_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setIsLoading(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 5, , 6]);
                    valuesState = values;
                    valuesPassenger_1 = valuesState.passengers[0];
                    passengerFiles = ((_a = valuesPassenger_1 === null || valuesPassenger_1 === void 0 ? void 0 : valuesPassenger_1.files) === null || _a === void 0 ? void 0 : _a.changeFiles) || [];
                    changedDocType = getUserValue(editablePassenger, TravellerFieldEnum.DocType);
                    isPassport_1 = changedDocType === DocTypes.Passport;
                    isAutoMode = !!((_d = (_c = (_b = order.exareInfo) === null || _b === void 0 ? void 0 : _b.changeTravellerDataAvailability) === null || _c === void 0 ? void 0 : _c.find(function (traveller) { return traveller.id === editablePassenger.id; })) === null || _d === void 0 ? void 0 : _d.autoMode);
                    travellers = [
                        {
                            id: editablePassenger.id,
                            values: editablePassenger.values
                                .filter(function (value) { return value.isEditable; })
                                .map(function (value) {
                                var actualValue = valuesPassenger_1[value.name];
                                // workaround to prevent the document expiration date from changing when switching the document type select
                                if (value.type === TravellerFieldEnum.DocExpiryDate && isPassport_1 && !actualValue) {
                                    actualValue = getUserValue(editablePassenger, TravellerFieldEnum.DocExpiryDate);
                                }
                                return {
                                    name: value.name,
                                    type: value.type,
                                    value: actualValue
                                };
                            }),
                            files: passengerFiles.map(function (file) { return ({
                                content: file.content,
                                originalFileName: file.name,
                                type: ExareReasonDocs.Other
                            }); }),
                            isCompelled: reason === ExareReason.DocumentReplacement,
                            reason: reason
                        }
                    ];
                    if (!isAutoMode) return [3 /*break*/, 3];
                    return [4 /*yield*/, changeTravellerDataPrice({
                            parameters: {
                                orderId: order.id,
                                travellers: travellers.map(function (traveller) { return ({
                                    id: traveller.id,
                                    values: traveller.values,
                                    isCompelled: traveller.isCompelled
                                }); })
                            }
                        })];
                case 2:
                    data_1 = (_e.sent()).data;
                    onFillOrder(travellers, data_1.ChangeTravellerDataPrice);
                    return [3 /*break*/, 4];
                case 3:
                    onFillOrder(travellers);
                    _e.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _e.sent();
                    onRequestError(e_1);
                    return [3 /*break*/, 6];
                case 6:
                    setIsLoading(true);
                    return [2 /*return*/];
            }
        });
    }); }, [order, editablePassenger, onFillOrder, onRequestError, changeTravellerDataPrice]);
    return {
        // data
        editablePassenger: editablePassenger,
        requestError: requestError,
        isLoading: isLoading,
        isTravellerDataLoading: isTravellerDataLoading,
        allowSwapNames: allowSwapNames,
        // methods
        onSubmit: onSubmit,
        onClearError: onClearError
    };
};
