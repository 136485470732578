import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { format } from '../../../utils';
import { planeIcon } from '../../../BoardingPassDesk/Icons';
var MobileSegment = function (_a) {
    var _b;
    var className = _a.className, segment = _a.segment;
    var css = useTheme('RetrieveBooking').MobileSegment;
    var fareFamily = segment.fareFamily;
    var firstSegment = segment.segments[0];
    var lastSegment = segment.segments[segment.segments.length - 1];
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        React.createElement("div", { className: css.fareGroup },
            React.createElement("div", { className: css.fareGroup__img, style: {
                    backgroundImage: "url(".concat((_b = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _b === void 0 ? void 0 : _b.icon, ")")
                } }),
            React.createElement("div", { className: css.fareGroup__info },
                firstSegment.operatingAirline.iata,
                " ",
                firstSegment.flightNumber,
                ",",
                ' ',
                format(firstSegment.departure.date, new Date(firstSegment.departure.date).getFullYear() - new Date().getFullYear() < 1
                    ? 'dd MMMM'
                    : 'dd MMM, yyyy'))),
        React.createElement("div", { className: css.flightInfo },
            React.createElement("div", { className: css.flightInfo__iatas },
                React.createElement("span", null, firstSegment.departure.airport.iata),
                React.createElement("span", { className: css.planeIcon }, planeIcon),
                React.createElement("span", null, lastSegment.arrival.airport.iata)),
            React.createElement("div", { className: css.flightInfo__cities },
                React.createElement("span", null,
                    firstSegment.departure.airport.city.name,
                    React.createElement("br", null),
                    firstSegment.departure.airport.title),
                React.createElement("span", null,
                    lastSegment.arrival.airport.city.name,
                    React.createElement("br", null),
                    lastSegment.arrival.airport.title)),
            React.createElement("div", { className: css.flightInfo__time },
                React.createElement("span", null, firstSegment.departure.time.substr(0, 5)),
                React.createElement("span", null, lastSegment.arrival.time.substr(0, 5)))),
        React.createElement("div", { className: css.arrivalInfo })));
};
export default MobileSegment;
