export var getSeatAvailability = function (params) {
    var _a;
    var seat = params.seat, passenger = params.passenger, segmentId = params.segmentId;
    var firstService = (_a = seat.services) === null || _a === void 0 ? void 0 : _a[0];
    var isAllowedForSegment = firstService && firstService.allowedSegments.some(function (allowedSegment) { return allowedSegment[0] === segmentId; });
    var isAllowedForPassenger = firstService && firstService.allowedPassengers.some(function (allowedPassenger) { return allowedPassenger[0] === passenger.id; });
    var isAvailable = seat.isAvailable && seat.isExistent;
    if (seat.seatService) {
        isAvailable = isAvailable && isAllowedForSegment && isAllowedForPassenger;
    }
    return { isAvailable: isAvailable };
};
export var getAllowedPassengerSeatServices = function (passengerServices, seat) {
    var _a, _b;
    if (!passengerServices || Object.keys(passengerServices).length === 0) {
        return null;
    }
    var services = [];
    // если у места несколько услуг, сохраняем доступные
    if (!!((_a = seat.seatServices) === null || _a === void 0 ? void 0 : _a.length)) {
        seat.seatServices.forEach(function (service) {
            if (passengerServices[service.id] && passengerServices[service.id].canBeAdded) {
                services.push(passengerServices[service.id]);
            }
        });
        return services;
    }
    else if (!!((_b = seat.seatService) === null || _b === void 0 ? void 0 : _b.id)) {
        return [passengerServices[seat.seatService.id]];
    }
    return null;
};
