import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { useConfig } from '../../../../../context';
var DummyWeekCalendar = function () {
    var _a;
    var css = useTheme('Results').DummyWeekCalendar;
    var calendarDaysCount = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.Engine.calendarDaysCount;
    return (React.createElement("div", { className: css.wrapper }, __spreadArray([], __read(Array(calendarDaysCount).keys()), false).map(function (i) { return (React.createElement("div", { key: i, className: css.item },
        React.createElement("div", { className: css.item__head },
            React.createElement("span", null)),
        React.createElement("div", { className: css.item__middle },
            React.createElement("span", null)))); })));
};
export default DummyWeekCalendar;
