import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../Money';
import Link from '../../../Link';
import { initI18n } from '../../../utils';
import Button from '../../../Button';
import { CloseClear } from '../../../Icons';
initI18n('InsurancePage');
export var InsurancePrice = function (_a) {
    var _b;
    var className = _a.className, contentClassName = _a.contentClassName, selected = _a.selected, price = _a.price, variant = _a.variant, onClick = _a.onClick, onRemove = _a.onRemove, hideForAllPassengersTextBecauseSinglePassenger = _a.hideForAllPassengersTextBecauseSinglePassenger;
    var t = useTranslation('InsurancePage').t;
    var theme = useTheme('InsurancePage').InsurancePrice;
    return (React.createElement("div", { className: cn(theme.wrapper, (_b = {},
            _b[theme.mostPopular] = variant === 'mostPopular',
            _b[theme.forAllPassengers] = !hideForAllPassengersTextBecauseSinglePassenger,
            _b), className) }, variant === 'mostPopular' ? (React.createElement("div", { className: cn(theme.content, contentClassName) },
        !hideForAllPassengersTextBecauseSinglePassenger && (React.createElement("span", { className: theme.light }, t('For all passengers'))),
        React.createElement("span", { className: theme.bold },
            React.createElement(Money, { money: price, moneyClassName: theme.bold })))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: theme.content },
            selected
                ? onRemove && (React.createElement(Link, { action: onRemove, className: cn(theme.light, theme.link) },
                    CloseClear,
                    t('Remove')))
                : onClick && (React.createElement(Button, { className: theme.button, onClick: onClick }, t('Add'))),
            React.createElement("div", { className: theme.price },
                !hideForAllPassengersTextBecauseSinglePassenger && (React.createElement("span", { className: cn(theme.light, theme.label) }, t('For all passengers'))),
                React.createElement(Money, { money: price, moneyClassName: cn(theme.bold, theme.money) })))))));
};
