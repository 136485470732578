import { __assign, __read, __spreadArray } from "tslib";
import { SET_LOCATION, SET_MULTI_CITY, SET_SEGMENTS, SWAP_LOCATIONS, SWAP_MULTI_LOCATIONS } from './actions';
import { ADD_SEGMENT, CLEAR_DATES, REMOVE_SEGMENT, SET_DATE, SET_DATE_MULTI_CITY, TOGGLE_MULTI_CITY } from './actions';
export var MIN_SEGMENTS_NUMBER = 1;
export var MAX_SEGMENTS_NUMBER = 6;
export var defaultState = {
    simpleRouteSegment: {
        departure: null,
        arrival: null,
        date: null,
        dateBack: null
    },
    multiCitySegments: [],
    isMultiCity: false
};
export var segmentsReducer = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case TOGGLE_MULTI_CITY:
            var multiCitySegments = [];
            if (!state.isMultiCity && !state.multiCitySegments.length) {
                multiCitySegments.push(__assign(__assign({}, state.simpleRouteSegment), { dateBack: null }));
            }
            return __assign(__assign({}, state), { isMultiCity: !state.isMultiCity, multiCitySegments: __spreadArray([], __read(multiCitySegments), false) });
        case SET_MULTI_CITY:
            var multiCitySegmentsArray = state.multiCitySegments;
            var value = action.payload;
            if (value && !state.multiCitySegments.length) {
                multiCitySegmentsArray.push(__assign(__assign({}, state.simpleRouteSegment), { dateBack: null }));
            }
            return __assign(__assign({}, state), { isMultiCity: value, multiCitySegments: __spreadArray([], __read(multiCitySegmentsArray), false) });
        case ADD_SEGMENT:
            var numOfSegments = state.multiCitySegments.length;
            var newMultiCitySegments = [];
            if (numOfSegments < MAX_SEGMENTS_NUMBER) {
                // Create a new segment object and use the airport of arrival from the previous segment
                // as the departure airport of the new segment.
                newMultiCitySegments.push({
                    departure: state.multiCitySegments[numOfSegments - 1]
                        ? state.multiCitySegments[numOfSegments - 1].arrival
                        : null,
                    arrival: null,
                    date: null,
                    dateBack: null
                });
            }
            return __assign(__assign({}, state), { multiCitySegments: __spreadArray(__spreadArray([], __read(state.multiCitySegments), false), __read(newMultiCitySegments), false) });
        case REMOVE_SEGMENT:
            if (state.multiCitySegments.length > MIN_SEGMENTS_NUMBER) {
                var previousMultiCitySegments = __spreadArray([], __read(state.multiCitySegments), false);
                previousMultiCitySegments.splice(action.payload, 1);
                return __assign(__assign({}, state), { multiCitySegments: __spreadArray([], __read(previousMultiCitySegments), false) });
            }
            return __assign({}, state);
        case SET_DATE:
            if (action.payload.isBackDate) {
                return __assign(__assign({}, state), { simpleRouteSegment: __assign(__assign({}, state.simpleRouteSegment), { dateBack: action.payload.date }) });
            }
            else {
                return __assign(__assign({}, state), { simpleRouteSegment: __assign(__assign({}, state.simpleRouteSegment), { date: action.payload.date }) });
            }
        case SET_DATE_MULTI_CITY:
            var oldMultiCitySegments = __spreadArray([], __read(state.multiCitySegments), false);
            var currentMultiCitySegment = oldMultiCitySegments[action.payload.segmentIndex];
            oldMultiCitySegments[action.payload.segmentIndex] = __assign(__assign({}, currentMultiCitySegment), { date: action.payload.date });
            return __assign(__assign({}, state), { multiCitySegments: oldMultiCitySegments });
        case CLEAR_DATES:
            var newDateState = {
                date: state.simpleRouteSegment.date,
                dateBack: state.simpleRouteSegment.dateBack
            };
            if (state.simpleRouteSegment.dateBack) {
                newDateState.dateBack = null;
            }
            else if (state.simpleRouteSegment.date) {
                newDateState.date = null;
            }
            return __assign(__assign({}, state), { simpleRouteSegment: __assign(__assign({}, state.simpleRouteSegment), newDateState) });
        case SET_LOCATION:
            // Is simple route
            if (typeof action.payload.segmentIndex === 'undefined') {
                return __assign(__assign({}, state), { simpleRouteSegment: __assign(__assign({}, state.simpleRouteSegment), (_a = {}, _a[action.payload.type] = action.payload.location, _a)) });
            }
            else {
                var currentMultiCitySegment_1 = __assign(__assign({}, state.multiCitySegments[action.payload.segmentIndex]), (_b = {}, _b[action.payload.type] = __assign({}, action.payload.location), _b));
                var oldMultiCitySegments_1 = __spreadArray([], __read(state.multiCitySegments), false);
                oldMultiCitySegments_1[action.payload.segmentIndex] = __assign({}, currentMultiCitySegment_1);
                return __assign(__assign({}, state), { multiCitySegments: __spreadArray([], __read(oldMultiCitySegments_1), false) });
            }
        case SWAP_LOCATIONS:
            return __assign(__assign({}, state), { simpleRouteSegment: __assign(__assign({}, state.simpleRouteSegment), { arrival: state.simpleRouteSegment.departure ? __assign({}, state.simpleRouteSegment.departure) : null, departure: state.simpleRouteSegment.arrival ? __assign({}, state.simpleRouteSegment.arrival) : null }) });
        case SWAP_MULTI_LOCATIONS:
            return __assign(__assign({}, state), { multiCitySegments: state.multiCitySegments.map(function (segment, index) {
                    if (index === action.payload) {
                        return __assign(__assign({}, segment), { departure: segment.arrival ? __assign({}, segment.arrival) : null, arrival: segment.departure ? __assign({}, segment.departure) : null });
                    }
                    return segment;
                }) });
        case SET_SEGMENTS:
            return action.payload;
        default:
            return state;
    }
};
