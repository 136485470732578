import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import SvgIcon from '@material-ui/core/SvgIcon';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { airportInfoIcon, weatherIcons } from '../icons';
import { getTemperaturePrefix } from '../utils';
import { TemperatureUnits } from '@websky/graphql';
var Weather = React.memo(function (_a) {
    var _b, _c;
    var _d, _e, _f;
    var airport = _a.airport, weatherInfo = _a.weatherInfo, className = _a.className;
    var theme = useTheme('Weather').Weather;
    var cityImage = (_f = (_e = (_d = airport === null || airport === void 0 ? void 0 : airport.city) === null || _d === void 0 ? void 0 : _d.images) === null || _e === void 0 ? void 0 : _e.panorama) === null || _f === void 0 ? void 0 : _f.url;
    var weatherIcon = (weatherInfo === null || weatherInfo === void 0 ? void 0 : weatherInfo.type) && weatherIcons[weatherInfo.type];
    var hasImage = !!cityImage;
    return (React.createElement(Card, { classes: { root: cn(theme.card, className, (_b = {}, _b[theme.card_withoutPanorama] = !hasImage, _b)) } },
        React.createElement(CardActionArea, { classes: { focusHighlight: theme.card__focusHighlight } },
            hasImage && (React.createElement(CardMedia, { classes: { root: theme.card__panorama }, image: cityImage, title: airport.name })),
            React.createElement("div", { className: theme.card__content },
                weatherInfo && (React.createElement(Card, { classes: { root: theme.card__weather } },
                    weatherIcon && (React.createElement(SvgIcon, { classes: { root: theme.card__weatherIcon } }, weatherIcon)),
                    React.createElement("div", { className: theme.card__temperature },
                        getTemperaturePrefix(weatherInfo.temperature),
                        Math.abs(Math.round(weatherInfo.temperature)),
                        "\u00B0",
                        weatherInfo.measurement === TemperatureUnits.Fahrenheit && 'F'))),
                React.createElement("div", { className: cn(theme.card__airportName, (_c = {}, _c[theme.card__airportName_withIATA] = !hasImage, _c)) },
                    !hasImage && React.createElement("div", { className: theme.card__airportIATA }, airport.iata),
                    React.createElement("span", { className: theme.card__airportLink },
                        airport.name,
                        React.createElement("span", { className: theme.card__chevronIcon }, airportInfoIcon)))))));
}, function (oldProps, newProps) {
    return oldProps.weatherInfo && newProps.weatherInfo && oldProps.weatherInfo.type === newProps.weatherInfo.type;
});
export default Weather;
