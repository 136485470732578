import React from 'react';
import Button from '../../../../BaseComponents/Button';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Money from '../../../../Money/components/Money';
import { overridedIcons } from '../../../../Icons';
import PriceInMiles from '../../../../PriceInMiles/PriceInMiles';
var FareButton = function (_a) {
    var _b, _c;
    var priceInMiles = _a.priceInMiles, price = _a.price, subscription = _a.subscription, originalPrice = _a.originalPrice, onClick = _a.onClick, disabled = _a.disabled, selected = _a.selected, className = _a.className, onTouchEndCapture = _a.onTouchEndCapture;
    var theme = useTheme('Flights').FareButton;
    var t = useTranslation('FareGroup').t;
    var showOriginalPrice = originalPrice && originalPrice.amount !== price.amount;
    var hideSelectText = originalPrice && originalPrice.amount !== price.amount && originalPrice.amount.toString().length > 5;
    return (React.createElement(Button, { classes: {
            root: cn(theme.root, className),
            disabled: theme.disabled,
            endIcon: theme.endIcon,
            startIcon: theme.startIcon
        }, className: cn((_b = {}, _b[theme.selected] = selected, _b[theme.subscription] = subscription, _b)), disabled: disabled, onClick: onClick, startIcon: selected && overridedIcons['Choosed'], endIcon: disabled && overridedIcons['NotIncluded'], onTouchEndCapture: onTouchEndCapture, disableRipple: selected }, !disabled ? (React.createElement(React.Fragment, null,
        !hideSelectText && React.createElement("span", { className: theme.title }, t(selected ? 'Selected' : 'Select')),
        priceInMiles ? (React.createElement(PriceInMiles, { amount: priceInMiles, amountClassName: theme.miles_amount, moneyClassName: theme.miles })) : (React.createElement("div", { className: cn(theme.money_wrapper, (_c = {}, _c[theme.money_wrapper_center] = hideSelectText, _c)) },
            showOriginalPrice && React.createElement(Money, { moneyClassName: theme.money_original, money: originalPrice }),
            React.createElement(Money, { moneyClassName: theme.money, money: price }))))) : (React.createElement(React.Fragment, null, t('Unavailable')))));
};
export default FareButton;
