import { __assign, __read, __spreadArray } from "tslib";
import memes from '../../../../memes';
import { addDays, format, isAfter, isBefore, max, min } from 'date-fns';
import { getDateWithoutTimezone } from '../../../../Engine/components/FastSearch/utils';
import { API_DATE_FORMAT } from '../../../../utils';
export var isFlightsDatesInRightOrder = memes(function (prev, curr, index, segments) {
    var currentSegment = segments[index];
    var nextSegment = segments[index + 1];
    if (nextSegment && !prev) {
        var currentSegmentDate = new Date(currentSegment.date);
        var nextSegmentDate = new Date(nextSegment.date);
        return isAfter(currentSegmentDate, nextSegmentDate);
    }
    return prev;
});
export var isWrongOrder = function (segments, reduceRight) {
    if (reduceRight === void 0) { reduceRight = false; }
    return reduceRight
        ? segments.reduceRight(isFlightsDatesInRightOrder, false)
        : segments.reduce(isFlightsDatesInRightOrder, false);
};
export var sortSegments = memes(function (legId, date, segments) {
    var segmentsCopy = __spreadArray([], __read(segments), false);
    var DATE_FORMAT = 'yyyy-MM-dd';
    if (legId === 0 && isWrongOrder(segmentsCopy)) {
        var maxDate_1 = max(segmentsCopy.map(function (segment) { return getDateWithoutTimezone(segment.date); }));
        segmentsCopy.forEach(function (segment) {
            segment.date = format(maxDate_1, DATE_FORMAT);
        });
    }
    else if (legId > 0 && isWrongOrder(segmentsCopy, true)) {
        var minDate_1 = min(__spreadArray(__spreadArray([], __read(segmentsCopy.map(function (segment) { return getDateWithoutTimezone(segment.date); })), false), [date], false));
        segmentsCopy.forEach(function (segment) {
            segment.date = format(minDate_1, DATE_FORMAT);
        });
    }
    return segmentsCopy;
});
export var getSegmentsWithOffset = function (segments, legId, offset) {
    var currentSegmentDate = addDays(getDateWithoutTimezone(segments[legId].date), offset);
    return segments.map(function (segment, segmentIndex) {
        var date = getDateWithoutTimezone(segment.date);
        if (segmentIndex === legId) {
            date = currentSegmentDate;
        }
        else if (segmentIndex < legId && isBefore(currentSegmentDate, date)) {
            date = currentSegmentDate;
        }
        else if (segmentIndex > legId && isAfter(currentSegmentDate, date)) {
            date = currentSegmentDate;
        }
        return __assign(__assign({}, segment), { date: format(date, API_DATE_FORMAT) });
    });
};
