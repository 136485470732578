import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { deleteIcon } from '../../Icons';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DeleteAccountDialog from '../DeleteAccountDialog/DeleteAccountDialog';
import { SlideBottom } from '../../../index';
import { useRemoveAccountMutation } from '@websky/graphql';
import { ButtonBase } from '@material-ui/core';
var DeleteAccount = function (props) {
    var _a = __read(useState(false), 2), isOpenDeleteDialog = _a[0], setOpenDeleteDialog = _a[1];
    var theme = useTheme('Account').Account, t = useTranslation('Account').t;
    var _b = __read(useRemoveAccountMutation(), 1), removeAccount = _b[0];
    var onRemoveAccount = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeAccount()];
                case 1:
                    res = _a.sent();
                    if (res.errors && res.errors.length) {
                        props.onError(res.errors[0]);
                    }
                    else if (res.data.RemoveAccount.result) {
                        window.location.reload();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    props.onError(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonBase, { onClick: function () {
                setOpenDeleteDialog(true);
            }, className: theme.deleteButton },
            deleteIcon,
            t('Delete my account')),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { maxWidth: 'md', classes: { paper: theme.account__deleteModal }, open: isOpenDeleteDialog },
                React.createElement(DeleteAccountDialog, { onClose: function () {
                        setOpenDeleteDialog(false);
                    }, onRemoveAccount: onRemoveAccount }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpenDeleteDialog, onClose: function () { return setOpenDeleteDialog(false); } },
                React.createElement(DeleteAccountDialog, { onClose: function () { return setOpenDeleteDialog(false); }, onRemoveAccount: onRemoveAccount })))));
};
export default DeleteAccount;
