import { __assign } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import { getShowRenderersInConsole } from './cache';
var merge = require('lodash.merge');
var RenderersContext = React.createContext({});
export var RenderersProvider = React.memo(function (_a) {
    var value = _a.value, children = _a.children;
    var currentRenderers = useContext(RenderersContext);
    return React.createElement(RenderersContext.Provider, { value: merge(currentRenderers, value) }, children);
});
export var RenderersConsumer = RenderersContext.Consumer;
export { RenderersContext };
export var useRenderers = function () { return useContext(RenderersContext); };
export var OverrideComponent = function (props) {
    var rerenders = useContext(RenderersContext);
    var componentName = Object.keys(props.component)[0];
    if (rerenders.hasOwnProperty(componentName) && rerenders[componentName]) {
        var Component_1 = rerenders[componentName];
        if (getShowRenderersInConsole()) {
            console.log("%c Override component ".concat(componentName, " "), 'background: #222; color: #bada55; font-size: 16px');
        }
        return React.createElement(Component_1, __assign({}, props.componentProps));
    }
    var Component = props.component[componentName];
    return React.createElement(Component, __assign({}, props.componentProps));
};
