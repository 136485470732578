import { __read } from "tslib";
import { useState } from 'react';
import { useToggleable } from '../hooks';
export var useTransitTooltip = function () {
    var _a = useToggleable(false), open = _a.open, isOpen = _a.isOpen, close = _a.close;
    var _b = __read(useState(null), 2), timerId = _b[0], setTimerId = _b[1];
    var openTransitTooltipHandler = function () {
        clearTimeout(timerId);
        open();
    };
    var closeTransitTooltipHandler = function () {
        var timerId = setTimeout(function () {
            close();
        }, 150);
        setTimerId(timerId);
    };
    return {
        isOpen: isOpen,
        open: openTransitTooltipHandler,
        close: closeTransitTooltipHandler
    };
};
