import i18n from 'i18next';
import { capitalizeFirstLetter } from '../../../../../utils';
import { CheckinStep, useSteps } from '../../../utils';
import { CheckoutSteps } from '../../../../../MobileStepbar/types';
export var useStepsToStepbarStepsAdapter = function (steps) {
    var setStep = useSteps().setStep;
    var stebBarSteps = {};
    steps.forEach(function (step) {
        stebBarSteps[step] = {
            label: step === CheckinStep.Passengers
                ? i18n.t('Checkin:Passengers')
                : i18n.t("Checkin:".concat(capitalizeFirstLetter(step))),
            onClick: function () { return setStep(step); },
            type: step
        };
    });
    stebBarSteps['boardingPass'] = {
        label: i18n.t('Checkin:Boarding pass'),
        type: CheckoutSteps.BoardingPass
    };
    return stebBarSteps;
};
export var useGetGoBack = function (currentStep) {
    var _a = useSteps(), getPreviousStepEnum = _a.getPreviousStepEnum, previousStep = _a.previousStep;
    return getPreviousStepEnum(currentStep) !== CheckinStep.Passengers ? function () { return previousStep(currentStep); } : undefined;
};
