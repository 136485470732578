var _a;
import { __assign } from "tslib";
import { handleAction, handleActions } from 'redux-actions';
import * as actions from './actions';
var initialState = {};
var handleTogglePassenger = handleAction(actions.togglePassenger, function (state, _a) {
    var _b;
    var passengerId = _a.payload;
    return __assign(__assign({}, state), (_b = {}, _b[passengerId] = __assign(__assign({}, state[passengerId]), { selected: !state[passengerId].selected }), _b));
}, initialState);
export default handleActions((_a = {},
    _a[actions.togglePassenger.toString()] = handleTogglePassenger,
    _a), initialState);
