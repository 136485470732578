import * as React from 'react';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import { useTransferServiceSummary } from './model';
var TransferServiceSummary = function (_a) {
    var servicesState = _a.servicesState, segments = _a.segments, passengers = _a.passengers, service = _a.service;
    var model = useTransferServiceSummary(service, segments, passengers, servicesState);
    return (React.createElement(React.Fragment, null, model.serviceSummaryOptions.map(function (summaryGroup) {
        return React.createElement(ServiceSummaryOption, { key: summaryGroup.header, groupOption: summaryGroup });
    })));
};
export default TransferServiceSummary;
