import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import PromoLoader from '../PromoLoader';
import { useConfig } from '../../../context';
var DelayedPromoLoader = function (_a) {
    var loading = _a.loading, children = _a.children;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var isF7 = iataCode === 'F7';
    var showDefaultPromoLoader = !isF7;
    if (showDefaultPromoLoader) {
        return loading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, children);
    }
    var updateId = useRef(0);
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    useEffect(function () {
        var timeoutId;
        if (loading === true) {
            setIsLoading(true);
            updateId.current++;
        }
        if (loading === false && updateId.current > 0) {
            timeoutId = setTimeout(function () {
                setIsLoading(false);
            }, 2000);
        }
        return function () {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [loading]);
    return isLoading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, children);
};
export default DelayedPromoLoader;
