import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCheckinAddTravellerMutation, useCheckinCancelMutation, useCheckinRemoveTravellerMutation, useCheckinStartMutation, useSelectCheckinSegmentsMutation } from '@websky/graphql';
import { getCheckinHasStarted, getCheckinOrder, getCheckinOrderId, getPassengerIdsWithLinkedPassengers, getSegments } from '../../../../store/order/selectors';
import { fillCheckinOrder } from '../../../../store/order/actions';
import { setCheckinOrder } from '../../../../store/order/actions';
import { CheckinGoal, reachGoal } from '../../../../../../analytics';
import { isDifferentCurrenciesError } from './utils';
export var useSegmentSelect = function (setLoading, setError, onCheckinStart) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var _b = __read(useState(new Set()), 2), selectedSegments = _b[0], setSelectedSegments = _b[1];
    var dispatch = useDispatch();
    var orderId = useSelector(getCheckinOrderId);
    var segments = useSelector(getSegments);
    var checkinHasStarted = useSelector(getCheckinHasStarted);
    // segments[1] = { ...segments[0] };
    // segments[1].id = '30';
    var _c = __read(useSelectCheckinSegmentsMutation(), 2), selectSegments = _c[0], _d = _c[1], selectSegmentsError = _d.error, selectSegmentsData = _d.data, selectSegmentsLoading = _d.loading;
    var _e = __read(useCheckinStartMutation(), 2), startCheckin = _e[0], _f = _e[1], startCheckinErorr = _f.error, startCheckinLoading = _f.loading, startCheckinData = _f.data;
    useEffect(function () {
        var _a;
        setLoading(startCheckinLoading);
        if (!startCheckinLoading && !((_a = startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) && (startCheckinData === null || startCheckinData === void 0 ? void 0 : startCheckinData.CheckinStart)) {
            dispatch(fillCheckinOrder(startCheckinData === null || startCheckinData === void 0 ? void 0 : startCheckinData.CheckinStart));
            setTimeout(onCheckinStart, 0);
        }
    }, [startCheckinLoading, startCheckinErorr, startCheckinData, checkinHasStarted]); // checkinHasStarted in dependencies because we use it inside onCheckinStart
    useEffect(function () {
        if (startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors[0]) {
            setError(startCheckinErorr === null || startCheckinErorr === void 0 ? void 0 : startCheckinErorr.graphQLErrors[0]);
        }
    }, [startCheckinErorr]);
    useEffect(function () {
        var _a;
        setLoading(selectSegmentsLoading);
        if (!selectSegmentsLoading &&
            !((_a = selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) &&
            (selectSegmentsData === null || selectSegmentsData === void 0 ? void 0 : selectSegmentsData.CheckinSelectSegments)) {
            startCheckin({ variables: { id: orderId } });
        }
    }, [selectSegmentsLoading, selectSegmentsError, selectSegmentsData]);
    useEffect(function () {
        if (selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors[0]) {
            setError(selectSegmentsError === null || selectSegmentsError === void 0 ? void 0 : selectSegmentsError.graphQLErrors[0]);
        }
    }, [selectSegmentsError]);
    var onSelect = useCallback(function (segment) {
        if (selectedSegments.has(segment.id)) {
            selectedSegments.delete(segment.id);
            setSelectedSegments(new Set(selectedSegments));
        }
        else {
            selectedSegments.add(segment.id);
            setSelectedSegments(new Set(selectedSegments));
        }
    }, []);
    var onStart = function () {
        if (segments.length > 1 && !(selectedSegments === null || selectedSegments === void 0 ? void 0 : selectedSegments.size)) {
            setOpen(true);
        }
        if (segments.length > 1 && selectedSegments.size) {
            setOpen(false);
            selectSegments({ variables: { params: { orderId: orderId, segments: __spreadArray([], __read(selectedSegments), false) } } });
        }
    };
    return { isSegmentsSelectionOpen: isOpen, onSelect: onSelect, setOpen: setOpen, selectedSegments: selectedSegments, setSelectedSegments: setSelectedSegments, onStart: onStart };
};
export var useCheckinCancelModal = function () {
    var dispatch = useDispatch();
    var _a = __read(useState(), 2), checkinCancelResponse = _a[0], setCheckinCancelResponse = _a[1];
    var _b = __read(useState(false), 2), isCheckinCancelModalOpen = _b[0], setCheckinCancelModalOpen = _b[1];
    var showCheckinCancelModalIfNeeded = useCallback(function (response) {
        if (response.travellers.every(function (traveller) { return traveller.isConfirmed === false; })) {
            setCheckinCancelResponse(response);
            setCheckinCancelModalOpen(true);
        }
        else {
            dispatch(setCheckinOrder(response));
            reachGoal(CheckinGoal.Cancelled);
        }
    }, []);
    var handleAgree = useCallback(function () {
        setCheckinCancelModalOpen(false);
        dispatch(setCheckinOrder(checkinCancelResponse));
        reachGoal(CheckinGoal.Cancelled);
    }, [checkinCancelResponse, isCheckinCancelModalOpen]);
    return { showCheckinCancelModalIfNeeded: showCheckinCancelModalIfNeeded, handleAgree: handleAgree, isCheckinCancelModalOpen: isCheckinCancelModalOpen };
};
export var useCheckinTraveller = function (showCheckinCancelModalIfNeeded, setError) {
    var t = useTranslation('Checkin').t;
    var dispatch = useDispatch();
    var order = useSelector(getCheckinOrder);
    var passengerIdsWithLinkedPassengers = useSelector(getPassengerIdsWithLinkedPassengers);
    var _a = __read(useCheckinAddTravellerMutation(), 2), addTraveller = _a[0], addTravellerLoading = _a[1].loading;
    var _b = __read(useCheckinRemoveTravellerMutation(), 2), removeTraveller = _b[0], removeTravellerLoading = _b[1].loading;
    var _c = __read(useCheckinCancelMutation(), 2), cancelCheckin = _c[0], cancelCheckinLoading = _c[1].loading;
    var onError = useCallback(function (message, error) { return setError({ content: message, error: error }); }, [setError]);
    var onAddTraveller = useCallback(function (lastName, id, extraSecret) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, e_1, hasDifferentCurrenciesError;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addTraveller({
                            variables: {
                                params: { id: id, secret: lastName, orderId: order.id, extraSecret: extraSecret }
                            }
                        })];
                case 1:
                    _a = _c.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinAddTraveller);
                    if (result) {
                        reachGoal(CheckinGoal.AddTraveller);
                        dispatch(fillCheckinOrder(result));
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, false];
                case 2:
                    e_1 = _c.sent();
                    hasDifferentCurrenciesError = (_b = e_1 === null || e_1 === void 0 ? void 0 : e_1.graphQLErrors) === null || _b === void 0 ? void 0 : _b.some(isDifferentCurrenciesError);
                    if (hasDifferentCurrenciesError) {
                        onError(t('You cannot add a passenger because the currency of order is different from the currency of orders of previously added passengers.\nYou can check-in the passenger separately.'), e_1);
                    }
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [order.id, onError]);
    var onRemoveTraveller = useCallback(function (traveller) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, result, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeTraveller({
                            variables: {
                                params: { orderId: order.id, passengerId: traveller.id }
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    result = !errors && (data === null || data === void 0 ? void 0 : data.CheckinRemoveTraveller);
                    if (result) {
                        reachGoal(CheckinGoal.RemoveTraveller);
                        dispatch(fillCheckinOrder(result));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _b.sent();
                    console.error(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [order.id]);
    var onCancelCheckinRequest = useCallback(function (segmentIds, travellerIds) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setError(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, cancelCheckin({
                            variables: {
                                params: { orderId: order.id, segmentIds: segmentIds, travellerIds: travellerIds }
                            }
                        })];
                case 2:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.CheckinCancel) {
                        showCheckinCancelModalIfNeeded(data.CheckinCancel);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    onError(t('Unfortunately, check-in cancellation is prohibited for this flight. Please contact a customer support service for further details.'), e_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [order.id, cancelCheckin, onError]);
    var onCancelCheckin = useCallback(function () {
        var segmentIds = order.segments.map(function (segment) { return segment.id; });
        var travellerIds = order.travellers.map(function (traveller) { return traveller.id; });
        return onCancelCheckinRequest(segmentIds, travellerIds);
    }, [order, onCancelCheckinRequest]);
    var onCancelCheckinPassenger = useCallback(function (passengerId, segmentsIds) {
        if (segmentsIds === void 0) { segmentsIds = []; }
        var travellerIds = new Set(passengerId);
        var linkedTraveller = passengerIdsWithLinkedPassengers.get(passengerId);
        if (linkedTraveller === null || linkedTraveller === void 0 ? void 0 : linkedTraveller.length) {
            linkedTraveller.forEach(function (traveller) { return travellerIds.add(traveller.id); });
        }
        return onCancelCheckinRequest(segmentsIds !== null && segmentsIds !== void 0 ? segmentsIds : order.segments.map(function (segment) { return segment.id; }), __spreadArray([], __read(travellerIds), false));
    }, [order.segments, passengerIdsWithLinkedPassengers, onCancelCheckinRequest]);
    return {
        // data
        passengerIdsWithLinkedPassengers: passengerIdsWithLinkedPassengers,
        isLoading: addTravellerLoading || removeTravellerLoading || cancelCheckinLoading,
        // methods
        onAddTraveller: onAddTraveller,
        onRemoveTraveller: onRemoveTraveller,
        onCancelCheckin: onCancelCheckin,
        onCancelCheckinPassenger: onCancelCheckinPassenger
    };
};
