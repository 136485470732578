import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { BaseButton } from '../BaseComponents';
import { initI18n, useIsMobile } from '../utils';
import AddPassengerForm from './components/AddPassengerForm/AddPassengerForm';
import { useToggleable } from '../hooks';
import { Add, InfoDetails } from '../Icons';
import ModalX from '../BaseComponents/ModalX';
import ConditionsModal from '../Modules/Checkin/components/Checkin/ConditionsModal/ConditionsModal';
initI18n('AddPassenger');
var AddPassenger = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation('AddPassenger').t;
    var css = useTheme('AddPassenger').AddPassenger;
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var isMobile = useIsMobile();
    return (React.createElement("div", { className: css.wrapper },
        (!isOpen || isMobile) && (React.createElement(BaseButton, { variant: "smallNeitral", className: css.addButton, startIcon: !isMobile ? React.createElement("div", { className: css.addButton__icon }, Add) : null, onClick: open }, t('Add passenger'))),
        isOpen && !isMobile && (React.createElement("div", { className: css.form__wrapper },
            React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: close }))),
        React.createElement(ModalX, { keepMounted: false, title: React.createElement("div", { className: css.form__title },
                t('Add passenger'),
                React.createElement(ConditionsModal, { renderTrigger: function (_a) {
                        var onClick = _a.onClick;
                        return (React.createElement(BaseButton, { variant: "text", className: css.conditionButton, onClick: onClick, startIcon: InfoDetails }, t('CheckinConditions:Check-in conditions')));
                    } })), isOpen: isOpen && isMobile, onClose: close },
            React.createElement("div", { className: css.form__wrapper },
                React.createElement(AddPassengerForm, { onSubmit: onSubmit, onSuccess: close, inputVariant: "underline" })))));
};
export default AddPassenger;
