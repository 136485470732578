import * as React from 'react';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { WarningRounded, Included, ArrowForward } from '../../Icons';
import { BaseButton } from '../../index';
var Notification = function (_a) {
    var _b;
    var className = _a.className, iconClassName = _a.iconClassName, contentClassName = _a.contentClassName, titleClassName = _a.titleClassName, textClassName = _a.textClassName, contextClassName = _a.contextClassName, globalClassName = _a.globalClassName, htmlClassName = _a.htmlClassName, title = _a.title, image = _a.image, icon = _a.icon, status = _a.status, type = _a.type, infoMessage = _a.infoMessage, actionText = _a.actionText, onClickAction = _a.onClickAction, renderMessagesWithoutWrapper = _a.renderMessagesWithoutWrapper;
    var css = useTheme('Notification').Notification;
    var isSuccess = ![InfoMessageTypeEnum.Info, InfoMessageTypeEnum.Warning, InfoMessageTypeEnum.Promo].includes(status);
    var isHtmlType = infoMessage.type === InfoMessageTypeEnum.HtmlContent;
    var isContextType = type === 'context';
    var isGlobalType = type === 'global';
    if (renderMessagesWithoutWrapper) {
        return React.createElement("div", { className: htmlClassName, dangerouslySetInnerHTML: { __html: infoMessage.text } });
    }
    return (React.createElement("div", { className: cn(css.wrapper, className, (_b = {},
            _b[css.context] = !isHtmlType && isContextType,
            _b[css.global] = !isHtmlType && isGlobalType,
            _b[css.success] = isSuccess && !isHtmlType,
            _b[css.info] = status === InfoMessageTypeEnum.Info,
            _b[css.warning] = status === InfoMessageTypeEnum.Warning,
            _b[contextClassName] = isContextType && contextClassName,
            _b[globalClassName] = isGlobalType && globalClassName,
            _b)), "data-code": infoMessage.code },
        React.createElement("div", { className: css.wrapper__inner }, isHtmlType ? (React.createElement("div", { className: htmlClassName, dangerouslySetInnerHTML: { __html: infoMessage.text } })) : (React.createElement(React.Fragment, null,
            image ? (React.createElement("img", { className: css.image, src: image, alt: "" })) : (React.createElement("div", { className: cn(css.icon, iconClassName) }, icon ? icon : isSuccess ? Included : WarningRounded)),
            React.createElement("div", { className: cn(css.content, contentClassName) },
                title && (React.createElement("div", { className: cn(css.title, titleClassName), dangerouslySetInnerHTML: { __html: title } })),
                React.createElement("div", { className: cn(css.text, textClassName), dangerouslySetInnerHTML: { __html: infoMessage.text } }))))),
        actionText && !isHtmlType && (React.createElement(React.Fragment, null,
            React.createElement(BaseButton, { variant: "optionEdit", className: css.action, onClick: onClickAction },
                actionText,
                ArrowForward)))));
};
export default Notification;
