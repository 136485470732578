import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../../../Money';
import { useTheme } from '../../../../../theme';
import BaggageComponent from '../../../../Baggage';
import { serviceForMultipleSegments } from '../../../hooks';
import { BaggageType } from '@websky/graphql';
import { getRelatedSegments, getActualPriceInBaggageGroup } from '../../../utils';
var HeaderBaggageItem = function (_a) {
    var services = _a.services, allSegments = _a.allSegments;
    var theme = useTheme('UpgradeBaggageDialog').BaggageDialogHeader;
    var t = useTranslation('IncludedBaggage').t;
    var multipleSegments = React.useMemo(function () { return serviceForMultipleSegments(services[0]); }, [services]);
    var price = React.useMemo(function () {
        var _a;
        if (allSegments || multipleSegments) {
            var pricesForPassengers_1 = new Map();
            services.forEach(function (service) {
                if (service.canBeAdded) {
                    var segments_1 = getRelatedSegments(service.segmentIds);
                    service.allowedPassengers.forEach(function (passengerId) {
                        pricesForPassengers_1.set(passengerId, (pricesForPassengers_1.get(passengerId) || 0) + service.price.amount * segments_1.size);
                    });
                }
            });
            var prices = Array.from(pricesForPassengers_1.values()), minPrice_1 = (prices === null || prices === void 0 ? void 0 : prices.length) ? Math.min.apply(Math, __spreadArray([], __read(prices), false)) : 0;
            return {
                currency: (_a = services[0]) === null || _a === void 0 ? void 0 : _a.price.currency,
                amount: minPrice_1,
                withFrom: prices.some(function (price) { return price > minPrice_1; })
            };
        }
        return getActualPriceInBaggageGroup(services, multipleSegments);
    }, [services, allSegments, multipleSegments]);
    var isShort = [BaggageType.BaggageExcess].includes(services[0].type);
    return (React.createElement("div", { className: theme.baggage_wrapper, key: services[0].id },
        React.createElement(BaggageComponent, { baggage: services[0], selected: false, realIconSize: true, onlyIcon: false, short: isShort }),
        React.createElement("div", { className: theme.price_wrapper },
            price.withFrom && t('from'),
            React.createElement(Money, { money: {
                    currency: price.currency,
                    amount: price.amount
                } })),
        !allSegments && multipleSegments && (React.createElement("div", { className: theme.segmentNotice }, t('Added for all flights')))));
};
export default HeaderBaggageItem;
