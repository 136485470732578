import { __read, __spreadArray } from "tslib";
export function getSelectedInsuranceSaveCodes(saveOrderServicesParams, availableInsuranceCodes) {
    var _a, _b;
    var selectedSaveServicesCodesSet = new Set();
    if ((_b = (_a = saveOrderServicesParams === null || saveOrderServicesParams === void 0 ? void 0 : saveOrderServicesParams.params) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.length) {
        saveOrderServicesParams === null || saveOrderServicesParams === void 0 ? void 0 : saveOrderServicesParams.params.insurances.forEach(function (insurance) {
            var selectedCodes = insurance.selectedPrograms;
            selectedCodes.forEach(function (code) {
                // filter by available insurance programs
                if (!(availableInsuranceCodes === null || availableInsuranceCodes === void 0 ? void 0 : availableInsuranceCodes.length) || availableInsuranceCodes.includes(code)) {
                    selectedSaveServicesCodesSet.add(code);
                }
            });
        });
    }
    return __spreadArray([], __read(selectedSaveServicesCodesSet), false);
}
