import { __assign } from "tslib";
import React from 'react';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import AiPurchasePredictionItem from './AiPurchasePredictionItem/AiPurchasePredictionItem';
import ModalX from '../../BaseComponents/ModalX';
initI18n('AIPurchasePredictionService');
var AiPurchasePredictionService = function (props) {
    var theme = useTheme('AIPurchasePredictionService').AIPurchasePredictionService;
    if (!props.service) {
        return null;
    }
    return (React.createElement(ModalX, { isOpen: !!props.service, classes: {
            paper: theme.paper,
            dialogContent_root: theme.modal
        } },
        React.createElement(AiPurchasePredictionItem, __assign({}, props))));
};
export default AiPurchasePredictionService;
