import { __read, __values } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { Form, FormSpy } from 'react-final-form';
import Field from './Field/Field';
import { useTranslation } from 'react-i18next';
import { FfpAccountFieldEnum, Gender } from '@websky/graphql';
import { InputType } from '../../../../../Passenger/types';
import cn from 'classnames';
import ModalConfirm from '../../../../../ModalConfirm';
import { ItemIncluded, WarningRounded } from '../../../../../Icons';
import Tooltip from '../../../../../Tooltip';
import { API_DATE_FORMAT, format } from '../../../../../utils';
import { parse } from 'date-fns';
var CreateAccount = function (props) {
    var _a, _b, _c, _d;
    var theme = useTheme('Loyalty').LoyaltyConnect;
    var t = useTranslation('Loyalty').t;
    var getFieldType = function (type) {
        switch (type) {
            case FfpAccountFieldEnum.BirthDate:
            case FfpAccountFieldEnum.DocExpiryDate:
            case FfpAccountFieldEnum.DocIssued:
                return InputType.Date;
            case FfpAccountFieldEnum.Phone:
                return InputType.Phone;
            case FfpAccountFieldEnum.Country:
            case FfpAccountFieldEnum.DocType:
                return InputType.Select;
            case FfpAccountFieldEnum.Gender:
                return InputType.Switch;
            case FfpAccountFieldEnum.City:
                return InputType.Text;
            default:
                return InputType.Text;
        }
    };
    var getOptions = function (validationRules, values, type) {
        var e_1, _a;
        var options;
        try {
            for (var validationRules_1 = __values(validationRules), validationRules_1_1 = validationRules_1.next(); !validationRules_1_1.done; validationRules_1_1 = validationRules_1.next()) {
                var rule = validationRules_1_1.value;
                if (!rule.with || rule.with.every(function (condition) { return values[condition.name] === condition.value; })) {
                    options = rule.options;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (validationRules_1_1 && !validationRules_1_1.done && (_a = validationRules_1.return)) _a.call(validationRules_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return options.map(function (option) {
            return {
                key: option.value,
                value: option.label
            };
        });
    };
    var fieldList = (_b = (_a = props.initialValues) === null || _a === void 0 ? void 0 : _a.FfpDefaultAccount) === null || _b === void 0 ? void 0 : _b.values;
    var blocks = [
        {
            title: 'Your name, Cyrillic',
            groups: [[FfpAccountFieldEnum.FirstName], [FfpAccountFieldEnum.LastName], [FfpAccountFieldEnum.MiddleName]]
        },
        {
            title: 'Your name, Latin',
            subtitle: 'Please enter Full name in Latin transcription',
            groups: [
                [FfpAccountFieldEnum.LatinFirstName],
                [FfpAccountFieldEnum.LatinLastName],
                [FfpAccountFieldEnum.LatinMiddleName]
            ]
        },
        {
            title: 'Document',
            groups: [
                [FfpAccountFieldEnum.Country],
                [FfpAccountFieldEnum.DocType],
                [FfpAccountFieldEnum.DocNumber],
                [FfpAccountFieldEnum.DocExpiryDate],
                [FfpAccountFieldEnum.DocIssued],
                [FfpAccountFieldEnum.DocIssuedBy]
            ]
        },
        {
            title: 'Contacts',
            subtitle: 'How to Connect with You?',
            groups: [[FfpAccountFieldEnum.Email], [FfpAccountFieldEnum.Phone], [FfpAccountFieldEnum.City]]
        }
    ];
    var BirthdayGenderFieldGroup = [FfpAccountFieldEnum.BirthDate, FfpAccountFieldEnum.Gender]
        .map(function (fieldType) {
        return fieldList === null || fieldList === void 0 ? void 0 : fieldList.find(function (param) { return (param === null || param === void 0 ? void 0 : param.type) === fieldType; });
    })
        .filter(function (p) { return p !== undefined; });
    var formData = React.useMemo(function () {
        if (!fieldList) {
            return null;
        }
        var data = blocks === null || blocks === void 0 ? void 0 : blocks.map(function (block, i) {
            return {
                title: block.title,
                subtitle: block.subtitle || false,
                groups: block.groups.map(function (group) {
                    return group
                        .map(function (fieldType) {
                        return fieldList.find(function (param) { return (param === null || param === void 0 ? void 0 : param.type) === fieldType; });
                    })
                        .filter(function (p) { return p !== undefined; });
                })
            };
        });
        data.forEach(function (block) {
            block.groups = block.groups.filter(function (group) { return group.length > 0; });
        });
        var filtered = data.filter(function (block) { return block.groups.length > 0; });
        if (BirthdayGenderFieldGroup.length > 0) {
            filtered[0].groups.push(BirthdayGenderFieldGroup);
        }
        return filtered;
    }, [fieldList]);
    var _e = __read(React.useState({}), 2), formValidationErrors = _e[0], setValidationErrors = _e[1];
    var formBlocksValidationData = React.useMemo(function () {
        if (!formData) {
            return null;
        }
        var list = new Map(Object.entries(formValidationErrors));
        var blocksList = formData.map(function (block) {
            var isValidBLock = block.groups.find(function (gr) {
                var groupHasErrors = gr.find(function (val) {
                    return list.get(val.name);
                });
                return !!groupHasErrors;
            });
            return !!!isValidBLock;
        });
        return blocksList;
    }, [fieldList, formValidationErrors]);
    var onSubmit = function (data) {
        var _a, _b, _c, _d;
        (_a = props.onSubmitForm) === null || _a === void 0 ? void 0 : _a.call(props);
        var params = [];
        var _loop_1 = function (param) {
            if (data.hasOwnProperty(param)) {
                var defaultValue = (_c = (_b = props.initialValues) === null || _b === void 0 ? void 0 : _b.FfpDefaultAccount) === null || _c === void 0 ? void 0 : _c.values.find(function (value) { return value.name === param; });
                var value = (_d = data[param]) !== null && _d !== void 0 ? _d : '';
                params.push({
                    name: param,
                    value: value,
                    type: defaultValue.type
                });
            }
        };
        for (var param in data) {
            _loop_1(param);
        }
        return props.onCreateAccount(params);
    };
    var initialValues = {};
    (_d = (_c = props.initialValues) === null || _c === void 0 ? void 0 : _c.FfpDefaultAccount) === null || _d === void 0 ? void 0 : _d.values.forEach(function (value) {
        if (value.type === FfpAccountFieldEnum.Gender) {
            initialValues[value.name] = value.value === 'M' ? Gender.Male : Gender.Female;
        }
        else {
            initialValues[value.name] = value.value;
        }
        if (getFieldType(value.type) === 'date' && !!value.value) {
            try {
                initialValues[value.name] = format(parse(value.value, API_DATE_FORMAT, new Date()), 'dd.MM.yyyy');
            }
            catch (e) {
                initialValues[value.name] = format(parse(value.value, 'dd.MM.yyyy', new Date()), 'dd.MM.yyyy');
            }
        }
    });
    React.useEffect(function () {
        if (!props.isOpen && props.formRef.current) {
            props.formRef.current.reset();
        }
    }, [props.isOpen]);
    if (!formData) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: theme.loyaltyConnect },
        React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues, validateOnBlur: true }, function (_a) {
            var handleSubmit = _a.handleSubmit, values = _a.values, form = _a.form;
            props.formRef.current = form;
            return (React.createElement(React.Fragment, null,
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: theme.form__content }, formData.map(function (block, index) {
                        var _a;
                        return (React.createElement("div", { className: theme.block, key: index },
                            React.createElement("div", { className: theme.block__headerWrapper },
                                React.createElement("div", { className: theme.block__header },
                                    React.createElement("div", { className: theme.block__header__titleWrapper },
                                        React.createElement("div", { className: theme.block__header__titleNumber }, index + 1),
                                        React.createElement("div", { className: theme.block__header__title }, t(block.title))),
                                    formBlocksValidationData[index] && (React.createElement("div", { className: theme.block__header__valid }, ItemIncluded)),
                                    !formBlocksValidationData[index] && (React.createElement(Tooltip, { title: t('Show errors') },
                                        React.createElement("div", { className: theme.block__header__invalid, onClick: function () {
                                                block.groups.forEach(function (gr) {
                                                    gr.forEach(function (it) {
                                                        form.blur(it.name);
                                                    });
                                                });
                                            } }, WarningRounded)))),
                                block.subtitle && (React.createElement("div", { className: theme.block__header__subtitle }, t(block.subtitle.toString())))), (_a = block === null || block === void 0 ? void 0 : block.groups) === null || _a === void 0 ? void 0 :
                            _a.map(function (group, index) {
                                return (React.createElement("div", { className: theme.groupCreate, key: index }, group === null || group === void 0 ? void 0 : group.map(function (field) {
                                    var _a;
                                    var type = getFieldType(field.type);
                                    var isEmail = field.type === FfpAccountFieldEnum.Email;
                                    var isDisabled = isEmail && !!initialValues['email'];
                                    return (React.createElement("div", { "data-field-name": field.name, className: cn(theme.groupCreate__field, (_a = {},
                                            _a[theme.groupCreate__field_switch] = field.type === FfpAccountFieldEnum.Gender,
                                            _a)), key: field.name },
                                        React.createElement(Field, { name: field.name, label: field.name, type: type, value: field.value || '', validationRules: field.validationRules, options: type === InputType.Select &&
                                                getOptions(field.validationRules, values, field.type), translateValue: field.type === FfpAccountFieldEnum.DocType, disabled: isDisabled })));
                                })));
                            })));
                    })),
                    React.createElement(FormSpy, { subscription: {
                            errors: true,
                            hasValidationErrors: true,
                            values: true,
                            modified: true,
                            active: true
                        }, onChange: function (_a) {
                            var errors = _a.errors, hasValidationErrors = _a.hasValidationErrors;
                            setTimeout(function () {
                                var _a;
                                setValidationErrors(errors);
                                (_a = props.onErrorsChanges) === null || _a === void 0 ? void 0 : _a.call(props, hasValidationErrors);
                            }, 0);
                        } })),
                props.error && (React.createElement(ModalConfirm, { isOpen: !!props.error, size: "medium", title: t('Cannot create an account'), onClose: props.closeError, children: props.error, confirmBtn: t('Close'), onConfirm: props.closeError }))));
        })));
};
export default CreateAccount;
