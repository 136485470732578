export var Statuses;
(function (Statuses) {
    Statuses[Statuses["default"] = 0] = "default";
    Statuses[Statuses["Loading"] = 1] = "Loading";
    Statuses[Statuses["Success"] = 2] = "Success";
    Statuses[Statuses["Verification"] = 3] = "Verification";
    Statuses[Statuses["VerificationLoading"] = 4] = "VerificationLoading";
    Statuses[Statuses["VerificationError"] = 5] = "VerificationError";
    Statuses[Statuses["VerificationSuccess"] = 6] = "VerificationSuccess";
})(Statuses || (Statuses = {}));
export var UserSettings;
(function (UserSettings) {
    UserSettings["TermOfUse"] = "TermOfUse";
    UserSettings["TravellerID"] = "TravellerID";
    UserSettings["Subscribe"] = "Subscribe";
})(UserSettings || (UserSettings = {}));
