import * as React from 'react';
import Segment from './Segment/Segment';
import * as css from './MobileFlightInfo.css';
var MobileFlightInfo = function (_a) {
    var flight = _a.flight;
    var getNextSegment = function (index) { return flight.segments[index + 1].segment; };
    return (React.createElement("div", { className: css.info }, flight.segments.map(function (_a, index) {
        var segment = _a.segment, transferDuration = _a.transferDuration;
        return (React.createElement(Segment, { key: index, segment: segment, transferDuration: transferDuration, nextSegment: transferDuration ? getNextSegment(index) : undefined }));
    })));
};
export default MobileFlightInfo;
