import { __generator } from "tslib";
import { all, put, takeLatest } from 'redux-saga/effects';
import { FILL_CHECKIN_ORDER, setCheckinOrder } from './order/actions';
import { setSelectedServices } from './selectedServices/actions';
import { saveServicesSaga } from './selectedServices/sagas';
import getOrderDataLayer from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
export function fillOrderSagaWorker(action) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                getOrderDataLayer(OrderType.Checkin).setOrder(action.payload);
                return [4 /*yield*/, put(setCheckinOrder(action.payload))];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(setSelectedServices(action.payload.travellers))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function fillOrderSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(FILL_CHECKIN_ORDER, fillOrderSagaWorker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([fillOrderSaga(), saveServicesSaga()])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
