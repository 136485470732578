import * as React from 'react';
import { useMemo } from 'react';
import { BaggageList } from '../../../BaggageList';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { convertBaggageOnBaggageItem } from '../../utils';
import { Included as IncludedIcon } from '../../../../Icons';
var Included = function (_a) {
    var className = _a.className, segments = _a.segments, includedBaggage = _a.includedBaggage, sliderToShow = _a.sliderToShow;
    var css = useTheme('IncludedBaggage').IncludedBaggage;
    var t = useTranslation('IncludedBaggage').t;
    var items = useMemo(function () {
        return includedBaggage.map(function (baggage) { return convertBaggageOnBaggageItem(baggage, segments); });
    }, [includedBaggage, segments]);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: css.header },
            React.createElement("h2", null,
                React.createElement("span", null, IncludedIcon),
                t('Included'))),
        React.createElement("div", { className: css.body },
            React.createElement(BaggageList, { items: items, slidesToShow: sliderToShow }))));
};
export default Included;
