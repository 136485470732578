export var useMethods = function (dispatch) {
    var setActiveService = function (serviceId) { return dispatch({ type: 'setActiveService', payload: serviceId }); };
    var toggleService = function (passengerId, segmentId, service) {
        dispatch({
            type: 'toggleTransfer',
            payload: {
                service: service,
                passengerId: passengerId,
                segmentId: segmentId
            }
        });
    };
    var setServiceState = function (serviceState) {
        dispatch({
            type: 'setService',
            payload: serviceState
        });
    };
    return {
        setActiveService: setActiveService,
        toggleService: toggleService,
        setServiceState: setServiceState
    };
};
