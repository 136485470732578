import { __read } from "tslib";
import { useLayoutEffect, useRef, useState } from 'react';
/** Return computed style property */
export var useComputedStyleProperty = function (propertyName, initValue) {
    var _a = __read(useState(initValue), 2), value = _a[0], setValue = _a[1];
    var elRef = useRef(null);
    useLayoutEffect(function () {
        if (elRef.current) {
            var value_1 = getComputedStyle(elRef.current).getPropertyValue(propertyName).trim();
            if (value_1.length > 0) {
                setValue(value_1);
            }
        }
    }, []);
    return { value: value, elRef: elRef };
};
