import * as React from 'react';
export var Aircraft = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6 20H8L13 11.5789H18.5C19.33 11.5789 20 10.8737 20 10C20 9.12632 19.33 8.42105 18.5 8.42105H13L8 0L6 0L8.5 8.42105L3 8.42105L1.5 6.31579H0L1 10L0 13.6842H1.5L3 11.5789L8.5 11.5789L6 20Z", fill: "currentColor" })));
export var Group = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5 8.5C12.5 10.43 10.93 12 9 12C7.07 12 5.5 10.43 5.5 8.5C5.5 6.57 7.07 5 9 5C10.93 5 12.5 6.57 12.5 8.5ZM10.5 8.5C10.5 7.67 9.83 7 9 7C8.17 7 7.5 7.67 7.5 8.5C7.5 9.33 8.17 10 9 10C9.83 10 10.5 9.33 10.5 8.5ZM9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM9 15.75C7.21 15.75 5.18 16.42 4.34 17H13.66C12.82 16.42 10.79 15.75 9 15.75ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.93 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.67 14.5 8.5C14.5 7.33 14.13 6.24 13.5 5.35C13.96 5.13 14.46 5 15 5C16.93 5 18.5 6.57 18.5 8.5Z", fill: "currentColor" })));
export var Person = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M7.00132 6C8.77015 6 10.2066 7.43643 10.2066 9.20525C10.2066 10.9741 8.77015 12.4105 7.00132 12.4105C5.2325 12.4105 3.79607 10.9741 3.79607 9.20525C3.79607 7.43643 5.2325 6 7.00132 6ZM0 18.7499V17.6815C0 15.3073 4.74853 14.0014 7.12279 14.0014C9.49705 14.0014 14.2456 15.3073 14.2456 17.6815V18.7499H0ZM24 6.75H14.25V8.25H24V6.75ZM14.25 9.75H24V11.25H14.25V9.75ZM21 12.75H14.25V14.25H21V12.75Z", fill: "currentColor" })));
export var Settings = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M19.8837 10.5644L17.991 10.099C17.8773 9.74021 17.7309 9.39229 17.5544 9.05949C17.8386 8.58647\n\t\t\t 18.4357 7.59089 18.4357 7.59089C18.55 7.40066 18.5198 7.15706 18.363 7.00034L16.9959 5.63322C16.8392\n\t\t\t 5.47602 16.5961 5.44583 16.4054 5.56052L14.9344 6.44188C14.6035 6.26673 14.2574 6.12181 13.901\n\t\t\t 6.00899L13.4356 4.11635C13.3817 3.90108 13.1887 3.75 12.9668 3.75H11.0332C10.8113 3.75 10.6178\n\t\t\t 3.90108 10.5644 4.11632C10.5644 4.11632 10.242 5.43729 10.0994 6.0085C9.71942 6.12887 9.35217 6.28513\n\t\t\t 9.00235 6.47585L7.4771 5.56052C7.28638 5.44721 7.04375 5.47651 6.88655 5.63322L5.51943 7.00034C5.36271\n\t\t\t 7.15706 5.33249 7.40066 5.44673 7.59089L6.38994 9.16619C6.23792 9.46596 6.11047 9.77798 6.00899 10.0976L4.11635 10.5644C3.90108 10.6183 3.75 10.8113 3.75 11.0332V12.9668C3.75 13.1887 3.90108 13.3822 4.11632 13.4356L6.00944 13.901C6.12416 14.265 6.27331 14.6186 6.45413 14.958L5.61009 16.3647C5.49584 16.555 5.52607 16.7986 5.68279 16.9553L7.0499 18.3224C7.20617 18.4787 7.44974 18.5084 7.64046 18.3951C7.64046 18.3951 8.58695 17.8277 9.05053 17.5501C9.38617 17.729 9.73737 17.8768 10.0995 17.9915L10.5645 19.8836C10.6178 20.099 10.8113 20.25 11.0332 20.25H12.9668C13.1887 20.25 13.3817 20.0989 13.4356 19.8837L13.9006 17.9915C14.273 17.8735 14.6318 17.721 14.9731 17.5359C15.4494 17.8215 16.4053 18.3951 16.4053 18.3951C16.5956 18.5089 16.8392 18.4796 16.9959 18.3224L18.363 16.9553C18.5197 16.7986 18.55 16.555 18.4357 16.3647L17.5666 14.9169C17.738 14.5916 17.8801 14.2513 17.991 13.901L19.8836 13.4356C20.099 13.3817 20.25 13.1887 20.25 12.9668V11.0332C20.25 10.8113 20.099 10.6183 19.8837 10.5644ZM12 15.3838C10.1344 15.3838 8.61621 13.8656 8.61621 12C8.61621 10.1344 10.1344 8.61621 12 8.61621C13.8656 8.61621 15.3838 10.1344 15.3838 12C15.3838 13.8656 13.8656 15.3838 12 15.3838Z", fill: "currentColor" })));
export var Star = (React.createElement("svg", { width: "20", height: "18", viewBox: "0 0 20 18", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M9.06845 1.13645C9.39966 0.28795 10.6003 0.287949 10.9315 1.13645L12.6234 5.47067C12.7655 5.83477 13.1068 6.0827 13.497 6.10536L18.1419 6.37506C19.0512 6.42786 19.4222 7.56976 18.7176 8.14696L15.1183 11.0954C14.816 11.343 14.6856 11.7442 14.7847 12.1223L15.9635 16.6232C16.1943 17.5043 15.2229 18.2101 14.4562 17.7183L10.5399 15.2063C10.2109 14.9953 9.7891 14.9953 9.4601 15.2063L5.54377 17.7183C4.77708 18.2101 3.80572 17.5043 4.0365 16.6232L5.21535 12.1223C5.31438 11.7442 5.18404 11.343 4.88167 11.0954L1.2824 8.14696C0.577777 7.56976 0.948804 6.42786 1.85812 6.37506L6.50302 6.10536C6.89323 6.0827 7.23447 5.83477 7.3766 5.47067L9.06845 1.13645Z", fill: "currentColor" })));
export var Support = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M12 1C7.03 1 3 5.03 3 10V17C3 18.66 4.34 20 6 20H9V12H5V10C5 6.13 8.13 3 12 3C15.87 3 19 6.13 19 10V12H15V20H19V21H12V23H18C19.66 23 21 21.66 21 20V10C21 5.03 16.97 1 12 1Z", fill: "currentColor" }),
    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "1", width: "18", height: "22" },
        React.createElement("path", { d: "M12 1C7.03 1 3 5.03 3 10V17C3 18.66 4.34 20 6 20H9V12H5V10C5 6.13 8.13 3 12 3C15.87 3 19 6.13 19 10V12H15V20H19V21H12V23H18C19.66 23 21 21.66 21 20V10C21 5.03 16.97 1 12 1Z", fill: "currentColor" })),
    React.createElement("g", { mask: "url(#mask0)" })));
export var MyCabinet = (React.createElement("svg", { width: "20", height: "17", viewBox: "0 0 20 17", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z", fill: "currentColor" })));
export var MenuIcon = (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.5 9.33333V7H24.5V9.33333H3.5ZM3.5 15.1667H24.5V12.8333H3.5V15.1667ZM3.5 21H24.5V18.6667H3.5V21Z", fill: "currentColor" }))));
export var AccountIcon = (React.createElement("svg", { viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM6 15.98C7.29 17.92 9.5 19.2 12 19.2C14.5 19.2 16.71 17.92 18 15.98C17.97 13.99 13.99 12.9 12 12.9C10 12.9 6.03 13.99 6 15.98Z", fill: "currentColor" }))));
export var LogoutIcon = (React.createElement("svg", { width: "21", height: "22", viewBox: "0 0 21 22", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 2H2V20H14V22H2C0.895432 22 0 21.1046 0 20V2C0 0.895431 0.89543 0 2 0H14V2ZM16 16L14.59 14.59L17.17 12H7.5V10H17.17L14.59 7.41L16 6L21 11L16 16Z", fill: "currentColor" })));
export var Communications = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 9C16 5.93 14.36 3.36 11.5 2.68V2C11.5 1.17 10.83 0.5 10 0.5C9.17003 0.5 8.50003 1.17 8.50003 2V2.68C5.63003 3.36 4.00003 5.92 4.00003 9V14L2.00003 16V17H18V16L16 14V9ZM4.15003 0.65L5.58003 2.08C3.54003 3.53 2.18003 5.85 2.03003 8.5H0.0300293C0.170029 5.3 1.75003 2.48 4.15003 0.65ZM19.97 8.5H17.97C17.82 5.85 16.45 3.53 14.43 2.08L15.85 0.65C18.24 2.48 19.82 5.3 19.97 8.5ZM10.4 19.96C10.27 19.99 10.14 20 10 20C8.89003 20 8.00003 19.1 7.99003 18H11.99C11.99 18.28 11.94 18.54 11.84 18.78C11.58 19.38 11.05 19.82 10.4 19.96Z", fill: "currentColor" })));
