import { __assign } from "tslib";
import { CartService } from '../../Cart/types';
import * as React from 'react';
var InsuranceSummary = function (props) {
    var onDeleteHandler = function (insuranceCode) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Insurances, {
            code: insuranceCode
        });
    };
    var items = React.useMemo(function () {
        var _a, _b, _c, _d;
        var insurancesMap = new Map(), availableProgramCodes = new Set((_b = (_a = props.services.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.map(function (program) { return program.code; }));
        (_d = (_c = props.services.insurances) === null || _c === void 0 ? void 0 : _c.selectedInsurances) === null || _d === void 0 ? void 0 : _d.forEach(function (insurance) {
            if (availableProgramCodes.has(insurance.insuranceProgram.code)) {
                insurancesMap.set(insurance.insuranceProgram.code, __assign(__assign({}, insurance.insuranceProgram), { count: 1, policyUrl: insurance.policyUrl }));
            }
        });
        var items = [];
        insurancesMap.forEach(function (insurance) {
            items.push({
                id: insurance.code,
                name: "".concat(insurance.count, "x ").concat(insurance.name),
                price: insurance.totalPrice,
                onDelete: props.onServiceDelete ? function () { return onDeleteHandler(insurance.code); } : null,
                included: props.isConfirmed && (!props.onServiceDelete || !!insurance.policyUrl),
                downloadUrl: props.isConfirmed && props.isDownload ? insurance.urlToOffer : null
            });
        });
        return items;
    }, [props.services, props.isConfirmed]);
    return props.children(items);
};
export default InsuranceSummary;
