import { __read } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import SocialAuth from '../../SocialAuth';
import Button from '../../Button';
import { useTheme } from '../../theme';
import LoginPage from '../../LoginPage';
initI18n('MobileAuthForm');
var MobileAuthForm = function (_a) {
    var onSuccess = _a.onSuccess, onReject = _a.onReject, onSocialAuthClick = _a.onSocialAuthClick;
    var _b = __read(useState(false), 2), showLoginForm = _b[0], setShowLoginForm = _b[1];
    var css = useTheme('MobileAuthForm').MobileAuthForm;
    var t = useTranslation('MobileAuthForm').t;
    var openLoginForm = function () { return setShowLoginForm(true); };
    return !showLoginForm ? (React.createElement("div", { className: css.wrapper },
        React.createElement("h1", { className: css.title }, t('Sign in')),
        React.createElement("p", { className: css.sub },
            t('and fill in passengers info with a single click'),
            "."),
        React.createElement("div", { className: css.social },
            React.createElement(SocialAuth, { onClick: onSocialAuthClick, variant: "square" })),
        React.createElement("p", { className: css.email, onClick: openLoginForm }, t('Sign in with email')),
        React.createElement(Button, { className: css.button, size: "large", variant: "flat", onClick: onReject }, t('Continue as a guest')))) : (React.createElement("div", { className: css.wrapper },
        React.createElement(LoginPage, { onSuccess: onSuccess, variant: "compact" })));
};
export default MobileAuthForm;
