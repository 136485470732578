import * as React from 'react';
import CommonItem from '../CommonItem/CommonItem';
import { Date } from '../../../Icons';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../index';
import { useTheme } from '../../../theme';
var OrderChange = function (_a) {
    var exchangeAvailable = _a.exchangeAvailable, refundAvailable = _a.refundAvailable, onClick = _a.onClick;
    var t = useTranslation('OrderCard').t;
    var theme = useTheme('OrderCard').OrderChange;
    return (React.createElement(CommonItem, { icon: Date, text: t('Changed your plans?'), buttons: React.createElement(React.Fragment, null,
            exchangeAvailable && (React.createElement(Button, { onClick: function () { return onClick('exchange'); }, className: theme.button, "data-exchange-button": true }, t('Exchange'))),
            refundAvailable && (React.createElement(Button, { onClick: function () { return onClick('refund'); }, className: theme.button, "data-refund-button": true }, t('Refund')))) }));
};
export default OrderChange;
