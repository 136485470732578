import * as React from 'react';
import Gateway from '../Gateway';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useCallback, useEffect, useMemo } from 'react';
import SimpleLoader from '../../../../SimpleLoader';
import { useToggleable } from '../../../../hooks';
import { useConfig } from '../../../../context';
import { OverrideComponent } from '../../../../renderProps';
var Iframe = function (_a) {
    var _b;
    var type = _a.type, frameInfo = _a.frameInfo, onClick = _a.onClick, _c = _a.isPreOpened, isPreOpened = _c === void 0 ? false : _c, children = _a.children, headerTitle = _a.headerTitle, gatewayTitle = _a.gatewayTitle, onPaymentFrameLoaded = _a.onPaymentFrameLoaded, isLoading = _a.isLoading, variant = _a.variant, onStartRedirecting = _a.onStartRedirecting, openFrameInNewTab = _a.openFrameInNewTab;
    var css = useTheme('PaymentForm').Card;
    var t = useTranslation('PaymentForm').t;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var frameRef = React.useRef();
    // Ipad fix
    var isIpad = navigator.userAgent.indexOf('iPad') !== -1;
    var _d = useToggleable(false), changeFrameState = _d.toggle, frameChanged = _d.isOpen;
    (_b = frameRef === null || frameRef === void 0 ? void 0 : frameRef.current) === null || _b === void 0 ? void 0 : _b.setAttribute('allowpaymentrequest', '');
    useEffect(function () {
        var interval = undefined;
        if (frameRef.current) {
            interval = setInterval(function () {
                var _a;
                try {
                    if (!isIpad && ((_a = frameRef === null || frameRef === void 0 ? void 0 : frameRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow)) {
                        if (frameRef.current.contentWindow.location.hash) {
                            clearInterval(interval);
                            window.location = frameRef.current.contentWindow.location;
                        }
                    }
                }
                catch (_b) { }
            }, 100);
        }
        return function () {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [frameRef, isLoading, frameInfo.frameUrl, frameChanged]);
    var iframeSandbox = useMemo(function () {
        if (iataCode === 'DV') {
            return null;
        }
        return 'allow-downloads allow-same-origin allow-popups allow-top-navigation allow-forms allow-scripts allow-payment-request';
    }, [iataCode]);
    var handleOnClick = useCallback(function () {
        if (isIpad || (variant === 'page' && !!frameInfo.frameUrl)) {
            onStartRedirecting === null || onStartRedirecting === void 0 ? void 0 : onStartRedirecting();
            window.location.href = frameInfo.frameUrl;
        }
        else {
            onClick({
                type: type,
                title: isPreOpened ? t('Payment') : headerTitle !== null && headerTitle !== void 0 ? headerTitle : t('Pay by credit or debit card'),
                content: isLoading ? (React.createElement(SimpleLoader, null)) : (frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.frameUrl) ? (React.createElement("iframe", { onLoad: function () {
                        onPaymentFrameLoaded === null || onPaymentFrameLoaded === void 0 ? void 0 : onPaymentFrameLoaded();
                        changeFrameState();
                    }, ref: frameRef, src: frameInfo.frameUrl, sandbox: iframeSandbox, className: css.iframe, height: frameInfo.height })) : null
            });
        }
    }, [onClick, frameInfo, variant, iframeSandbox]);
    useEffect(function () {
        if (openFrameInNewTab && (frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.frameUrl)) {
            window.location.href = frameInfo.frameUrl;
        }
    }, [openFrameInNewTab, frameInfo]);
    useEffect(function () {
        if (isPreOpened) {
            handleOnClick();
        }
    }, [isPreOpened]);
    return (React.createElement(OverrideComponent, { componentProps: {
            onClick: handleOnClick,
            header: children,
            type: gatewayTitle !== null && gatewayTitle !== void 0 ? gatewayTitle : t('Credit or debit card'),
            paymentType: type
        }, component: { Gateway: Gateway } }));
};
export default Iframe;
