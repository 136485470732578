import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Gateway from '../Gateway';
import { PaymentType } from '../../../types';
import * as css from './Kaspi.css';
import CopyCode from '../../CopyCode/CopyCode';
var Kaspi = function (_a) {
    var frameInfo = _a.frameInfo, headerTitle = _a.headerTitle, onClick = _a.onClick, children = _a.children, isPreOpened = _a.isPreOpened;
    var t = useTranslation('PaymentForm').t;
    var onClickGateway = useCallback(function () {
        var paymentCode = frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.paymentCode;
        onClick({
            title: headerTitle,
            type: PaymentType.Kaspi,
            content: (React.createElement("div", { className: css.root }, paymentCode ? (React.createElement(React.Fragment, null,
                t('Payment code'),
                ":",
                React.createElement(CopyCode, { className: css.copy, code: paymentCode }),
                React.createElement("div", { className: css.info }, t('Please, click «Edit Order» after successful payment in Kaspi.kz APP to open your order.')))) : (t('Failed to get billing code'))))
        });
    }, [frameInfo, onClick]);
    useEffect(function () {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { onClick: onClickGateway, header: children, type: 'Kaspi', paymentType: PaymentType.Kaspi });
};
export default Kaspi;
