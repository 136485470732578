import { __assign } from "tslib";
import * as React from 'react';
import VipServiceSelect from '../../../VipServiceSelect';
import CheckinBusinessLoungeAdapter from './CheckinBusinessLoungeAdapter';
import { useToggleable } from '../../../hooks';
import Modal from '../../../Modal';
import BusinessAdditionalService from './components/BusinessAdditionalService';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { SlideBottom } from '../../../index';
var Business = function () {
    var _a = useToggleable(false), open = _a.open, isOpen = _a.isOpen, close = _a.close;
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckinBusinessLoungeAdapter, { onClose: close }, function (props) { return (React.createElement(React.Fragment, null,
            React.createElement(BusinessAdditionalService, __assign({}, props, { onOpen: open })),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Modal, { open: isOpen, onClose: props.onClose, maxWidth: 'sm' },
                    React.createElement(VipServiceSelect, __assign({}, props)))),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(SlideBottom, { isOpen: isOpen, onClose: props.onClose },
                    React.createElement(VipServiceSelect, __assign({}, props)))))); })));
};
export default Business;
