import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
var ThanksBanner = function () {
    var t = useTranslation('Checkout').t;
    var css = useTheme('Checkout').ThanksBanner;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper },
            React.createElement("h1", { className: css.title },
                React.createElement("span", null, "\uD83D\uDE0A"),
                t('Thank you for your booking!')))));
};
export default ThanksBanner;
