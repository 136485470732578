import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Money } from '../../../Money';
import { BaseButton } from '../../../index';
var BaggageCard = function (_a) {
    var _b, _c;
    var className = _a.className, classNameIcon = _a.classNameIcon, icon = _a.icon, title = _a.title, description = _a.description, children = _a.children, hasSelected = _a.hasSelected, fromPrice = _a.fromPrice, totalPrice = _a.totalPrice, onAdd = _a.onAdd, onEdit = _a.onEdit, readOnly = _a.readOnly;
    var css = useTheme('BaggageCard').BaggageCard;
    var t = useTranslation('IncludedBaggage').t;
    var onClick = function () { return (hasSelected ? onEdit === null || onEdit === void 0 ? void 0 : onEdit() : onAdd === null || onAdd === void 0 ? void 0 : onAdd()); };
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: cn(css.header, (_b = {},
                _b[css.selected] = hasSelected,
                _b)) },
            !hasSelected && React.createElement("div", { className: cn(css.icon, classNameIcon) }, icon),
            React.createElement("div", { className: css.info },
                title && React.createElement("div", { className: css.title, dangerouslySetInnerHTML: { __html: title } }),
                !hasSelected && description && (React.createElement("span", { className: css.description, dangerouslySetInnerHTML: { __html: description } })))),
        children,
        React.createElement("div", { className: css.footer },
            React.createElement("span", { className: css.price },
                hasSelected ? t('total') : t('from'),
                React.createElement(Money, { money: hasSelected ? totalPrice : fromPrice, roundType: "ceil" })),
            React.createElement(BaseButton, { variant: "optionEdit", className: cn(css.button, (_c = {},
                    _c[css.button_edit] = hasSelected && !readOnly,
                    _c)), onClick: onClick }, readOnly ? t('Checkout:More details') : t(hasSelected ? 'Edit' : 'Add')))));
};
export default BaggageCard;
