import * as React from 'react';
import BaggagePassenger from '../../BaggagePassenger/BaggagePassenger';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
var AllFlight = function (props) {
    var _a;
    var commonTheme = useTheme('UpgradeBaggageDialog').UpgradeBaggageDialog;
    return (React.createElement("div", { className: cn(commonTheme.body, (_a = {}, _a[commonTheme.withTabs] = !!props.baggageTab, _a)) }, props.passengers.map(function (passenger, i) {
        var label = props.passengerLabels[passenger.id];
        var number = i + 1;
        return (React.createElement(BaggagePassenger, { name: label, number: number, key: passenger.id, includedSegment: true, baggageByRfics: props.upgradeBaggages, mobileSelectedServiceId: props.mobileSelectedServiceId, passenger: passenger }));
    })));
};
export default AllFlight;
