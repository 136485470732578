import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import Button from '../../Button';
import { useTheme } from '../../../theme';
var TabButton = function (props) {
    var _a;
    var css = useTheme('TabButton').TabButton;
    return (React.createElement(Button, __assign({ variant: 'outline', classes: {
            root: cn(css.button, (_a = {},
                _a[css.active] = props.active,
                _a[css.uppercase] = props.uppercase,
                _a[css.icon] = props.icon,
                _a[css.small] = props.size === 'small',
                _a[css.common] = !props.size,
                _a[css.large] = props.size === 'large',
                _a[css.inverted] = props.inverted,
                _a), props.className),
            disabled: css.disabled
        } }, props), props.children));
};
export default TabButton;
