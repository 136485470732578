var _a;
import { __read, __values } from "tslib";
import { InfoMessagePositionsEnum } from '@websky/graphql';
import { createAction, handleActions } from 'redux-actions';
var setInfoMessages = createAction('SET_INFO_MESSAGES');
var infoMessagesReducer = handleActions((_a = {},
    _a[setInfoMessages.toString()] = function (state, action) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        var _e;
        var newState = { byDirections: {} };
        var payload = action.payload;
        try {
            for (var _f = __values(payload.flightDirections.entries()), _g = _f.next(); !_g.done; _g = _f.next()) {
                var _h = __read(_g.value, 2), directionIndex = _h[0], direction = _h[1];
                try {
                    for (var _j = (e_2 = void 0, __values(direction.legs)), _k = _j.next(); !_k.done; _k = _j.next()) {
                        var leg = _k.value;
                        if (!newState.byDirections[directionIndex]) {
                            newState.byDirections[directionIndex] = {};
                        }
                        try {
                            for (var _l = (e_3 = void 0, __values(leg.segments)), _m = _l.next(); !_m.done; _m = _l.next()) {
                                var segment = _m.value;
                                newState.byDirections[directionIndex][segment.segment.flightNumber] = [];
                                try {
                                    for (var _o = (e_4 = void 0, __values(segment.segment.messages)), _p = _o.next(); !_p.done; _p = _o.next()) {
                                        var message = _p.value;
                                        if ((_e = message.positions) === null || _e === void 0 ? void 0 : _e.some(function (position) {
                                            return [
                                                InfoMessagePositionsEnum.upsaleEconomy,
                                                InfoMessagePositionsEnum.flight,
                                                InfoMessagePositionsEnum.flightPrice
                                            ].includes(position);
                                        })) {
                                            newState.byDirections[directionIndex][segment.segment.flightNumber].push(message);
                                        }
                                    }
                                }
                                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                                finally {
                                    try {
                                        if (_p && !_p.done && (_d = _o.return)) _d.call(_o);
                                    }
                                    finally { if (e_4) throw e_4.error; }
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_m && !_m.done && (_c = _l.return)) _c.call(_l);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_k && !_k.done && (_b = _j.return)) _b.call(_j);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return newState;
    },
    _a), {
    byDirections: {}
});
export default infoMessagesReducer;
export { setInfoMessages };
