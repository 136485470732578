import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { LoginType, useGetAccountInfoQuery, useRemoveAuthMethodMutation } from '@websky/graphql';
import { useConfig } from '../../../context';
import { Statuses } from '../../types';
import Loader from '../../../Loader';
import WarningModal from '../../../WarningModal';
import AuthMethod from './AuthMethod/AuthMethod';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useEmailDuplication } from '../../hooks';
import { getIsCorporateNumber, getCorporateCardNumberAsNormal } from '../../../Loyalty/utils';
initI18n('Account');
var AuthMethods = function () {
    var _a, _b;
    var css = useTheme('Account').Account, t = useTranslation('Account').t, _c = __read(useState(Statuses.default), 2), authStatus = _c[0], setAuthStatus = _c[1], _d = __read(useState(null), 2), userSettingsError = _d[0], setUserSettingsError = _d[1];
    var authMethods = useConfig().global.authMethods;
    var accountInfo = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache'
    });
    var _e = __read(useRemoveAuthMethodMutation(), 1), removeMethod = _e[0];
    var refetchAccountInfo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, accountInfo.refetch()];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data === null || data === void 0 ? void 0 : data.CurrentUser];
            }
        });
    }); }, [accountInfo]);
    var clearUserSettingsError = useCallback(function () {
        setUserSettingsError(null);
    }, []);
    var onRemoveAuthMethod = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, removeMethod({
                            variables: {
                                params: id
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!(errors === null || errors === void 0 ? void 0 : errors.length)) return [3 /*break*/, 2];
                    setUserSettingsError(errors[0]);
                    return [3 /*break*/, 4];
                case 2:
                    if (!(data.DeleteAuthMethod && data.DeleteAuthMethod.result)) return [3 /*break*/, 4];
                    return [4 /*yield*/, refetchAccountInfo()];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _b.sent();
                    setUserSettingsError(error_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var _f = __read(React.useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var phone = (_c = (_b = (_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.find(function (authM) { return authM.loginType === LoginType.Phone && !!authM.value && authM.confirmed; }), email = (_f = (_e = (_d = accountInfo.data) === null || _d === void 0 ? void 0 : _d.CurrentUser) === null || _e === void 0 ? void 0 : _e.authMethods) === null || _f === void 0 ? void 0 : _f.find(function (authM) { return authM.loginType === LoginType.Email && !!authM.value && authM.confirmed; }), ffp = (_j = (_h = (_g = accountInfo.data) === null || _g === void 0 ? void 0 : _g.CurrentUser) === null || _h === void 0 ? void 0 : _h.authMethods) === null || _j === void 0 ? void 0 : _j.find(function (authM) { return authM.loginType === LoginType.FFP && !!authM.value && authM.confirmed; });
        return [phone, email, ffp];
    }, [accountInfo]), 3), accountPhone = _f[0], accountEmail = _f[1], accountFFP = _f[2];
    var emailDuplication = useEmailDuplication((_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.CurrentUser);
    var isRemovableMethod = React.useMemo(function () {
        return [accountPhone, accountEmail, accountFFP].filter(function (method) { return method === null || method === void 0 ? void 0 : method.confirmed; }).length > 1;
    }, [accountPhone, accountEmail, accountFFP]);
    if (accountInfo.loading || emailDuplication.isLoading) {
        return (React.createElement("div", { className: css.loader__wrapper },
            React.createElement(Loader, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.account__content },
            authMethods.email && (React.createElement(AuthMethod, { id: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.id, className: css.account__field, status: authStatus, type: LoginType.Email, value: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.value, setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountEmail === null || accountEmail === void 0 ? void 0 : accountEmail.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod, autoConnectValue: emailDuplication.connectEmail, onConnectResult: emailDuplication.onConnectResult })),
            authMethods.phone && (React.createElement(AuthMethod, { id: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.id, className: css.account__field, status: authStatus, type: LoginType.Phone, value: formatPhoneNumberIntl("+".concat(accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.value)), setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountPhone === null || accountPhone === void 0 ? void 0 : accountPhone.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod })),
            authMethods.ffp && (React.createElement(AuthMethod, { id: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.id, className: css.account__field, status: authStatus, type: LoginType.FFP, value: getIsCorporateNumber(accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value)
                    ? getCorporateCardNumberAsNormal(accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value)
                    : accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.value, setStatus: setAuthStatus, refetchInfo: refetchAccountInfo, confirmed: accountFFP === null || accountFFP === void 0 ? void 0 : accountFFP.confirmed, isRemovable: isRemovableMethod, onDelete: onRemoveAuthMethod, autoConnectValue: emailDuplication.connectFfp, autoConnectCode: (_b = emailDuplication.authDuplicates) === null || _b === void 0 ? void 0 : _b.code, onConnectResult: emailDuplication.onConnectResult }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), errorMessage: userSettingsError ? userSettingsError.message : '', onClose: clearUserSettingsError, onButtonClick: clearUserSettingsError, buttonText: t('OK, thank you'), isOpen: !!userSettingsError })));
};
export default AuthMethods;
