import * as React from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../Input/Input';
import { DateField } from '../../../Passenger/components/Field/Date/Date';
import { validate } from '../../../Passenger/utils';
import { InputType } from '../../../Passenger/types';
import { Select } from '../../../Passenger/components/Field/Select/Select';
var Field = function (_a) {
    var field = _a.field, fieldType = _a.fieldType, travellerId = _a.travellerId;
    var t = useTranslation('VisaForm').t;
    var fieldName = "travellers[".concat(travellerId, "].").concat(field.name);
    var escapedT = function (label) {
        return typeof label !== 'undefined'
            ? t(label, {
                // This is necessary to avoid colon problems in error messages.
                nsSeparator: '|',
                keySeparator: '>'
            })
            : label;
    };
    var fieldState = useField(fieldName, {
        defaultValue: field.value,
        type: fieldType,
        validate: function (value, allValues) {
            return escapedT(validate(value, allValues, fieldType, field.validationRules));
        }
    });
    var error = fieldState.meta.error && (fieldState.meta.submitFailed || (fieldState.meta.touched && fieldState.meta.modified))
        ? fieldState.meta.error
        : fieldState.meta.submitError && !fieldState.meta.dirtySinceLastSubmit
            ? fieldState.meta.submitError
            : '';
    if (fieldType === InputType.Select) {
        var options = field.validationRules[0].options.map(function (option) { return ({
            key: option.value,
            value: option.label
        }); });
        return (React.createElement(Select, { name: fieldName, label: t(field.name), options: options, error: error, onChange: function (key, e) {
                fieldState.input.onChange(e);
            }, value: fieldState.input.value }));
    }
    if (fieldType === InputType.Date) {
        return (React.createElement(DateField, { label: t(field.name), name: fieldName, absoluteHintPosition: true, error: error, onChange: function (e) {
                fieldState.input.onChange(e);
            }, onBlur: function (e) {
                fieldState.input.onBlur(e);
            }, value: fieldState.input.value }));
    }
    return (React.createElement(Input, { label: t(field.name), name: fieldName, absoluteHintPosition: true, value: fieldState.input.value, TextFieldProps: {
            onChange: function (e) {
                fieldState.input.onChange(e);
            },
            onBlur: function (e) {
                fieldState.input.onBlur(e);
            },
            helperText: error,
            error: !!error
        } }));
};
export default Field;
