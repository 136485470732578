import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Included } from '../../../Icons';
import { Money } from '../../../Money';
import { useRenderers } from '../../../renderProps';
import { BaseButton } from '../../../index';
var InsuranceCard = function (_a) {
    var _b;
    var _c;
    var className = _a.className, insurance = _a.insurance, policyUrl = _a.policyUrl, isSelected = _a.isSelected, onClick = _a.onClick;
    var css = useTheme('InsurancePopup').InsuranceCard;
    var t = useTranslation('Checkout').t;
    var renderInsuranceCardOptions = useRenderers().renderInsuranceCardOptions;
    var isConfirmed = !!policyUrl;
    var onDownload = function () {
        if (policyUrl) {
            window.open(policyUrl);
        }
    };
    return (React.createElement("div", { className: cn(className, css.wrapper, (_b = {},
            _b[css.active] = isSelected,
            _b[css.confirmed] = isConfirmed,
            _b)) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.header_info },
                React.createElement("span", { className: css.header_rate }, insurance.name),
                React.createElement("span", { className: css.header_description }, t('price for all persons'))),
            React.createElement("span", { className: css.header_price },
                React.createElement(Money, { money: insurance.totalPrice }),
                isSelected && Included)),
        React.createElement("div", { className: css.content },
            React.createElement("ul", null, (_c = renderInsuranceCardOptions === null || renderInsuranceCardOptions === void 0 ? void 0 : renderInsuranceCardOptions(insurance.options)) !== null && _c !== void 0 ? _c : insurance.options.map(function (_a, index) {
                var name = _a.name;
                return (React.createElement("li", { key: index, dangerouslySetInnerHTML: { __html: name } }));
            })),
            policyUrl ? (React.createElement(BaseButton, { className: css.button, onClick: onDownload, variant: "optionEdit" }, t('Download'))) : isSelected ? (React.createElement(BaseButton, { className: css.button_reject, onClick: onClick, variant: "smallSecondary" }, t('Reject'))) : (React.createElement(BaseButton, { className: css.button, onClick: onClick, variant: "optionEdit" }, t('Select'))))));
};
export default InsuranceCard;
