import { useCallback, useRef } from 'react';
import { useToggleable } from '../../../hooks';
import { scrollTo } from '../../../utils';
export var useConsentsCheckbox = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.needConfirm, needConfirm = _c === void 0 ? false : _c;
    var checkboxRef = useRef(null);
    var _d = useToggleable(false), isChecked = _d.isOpen, setChecked = _d.setOpen;
    var _e = useToggleable(false), isOpenTooltip = _e.isOpen, onOpenTooltip = _e.open, onCloseTooltip = _e.close;
    var isTooltipOpen = isOpenTooltip && !isChecked;
    var isNeedConfirm = !isChecked && needConfirm;
    var onChange = useCallback(function (_, checked) { return setChecked(checked); }, [
        setChecked
    ]);
    var onConfirm = useCallback(function () {
        if (!isNeedConfirm) {
            return;
        }
        scrollTo(checkboxRef.current.offsetTop);
        onOpenTooltip();
    }, [isNeedConfirm, checkboxRef, onOpenTooltip]);
    return {
        // data
        checkboxRef: checkboxRef,
        isChecked: isChecked,
        isTooltipOpen: isTooltipOpen,
        isNeedConfirm: isNeedConfirm,
        // methods
        setChecked: setChecked,
        onOpenTooltip: onOpenTooltip,
        onCloseTooltip: onCloseTooltip,
        onChange: onChange,
        onConfirm: onConfirm
    };
};
