import * as React from 'react';
import cn from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTheme } from '../../../theme';
var Reason = function (props) {
    var _a;
    var css = useTheme('Refund').Reason;
    return (React.createElement(FormControlLabel, { className: cn(css.label, (_a = {}, _a[css.active] = props.checked, _a), props.className), checked: props.checked, value: props.value, control: React.createElement(Radio, { classes: { root: cn(css.radio), checked: cn(css.radio, css.active) } }), label: props.label }));
};
export default Reason;
