import { __extends } from "tslib";
import OrderDataLayer from './OrderDataLayer';
var StubOrderDataLayer = /** @class */ (function (_super) {
    __extends(StubOrderDataLayer, _super);
    function StubOrderDataLayer() {
        return _super.call(this, null) || this;
    }
    StubOrderDataLayer.prototype.getOrderType = function () {
        return null;
    };
    StubOrderDataLayer.prototype.getFlightSegments = function () {
        return [];
    };
    StubOrderDataLayer.prototype.getOrderStatus = function () {
        return undefined;
    };
    StubOrderDataLayer.prototype.isInitialized = function () {
        return false;
    };
    StubOrderDataLayer.prototype.setOrder = function (order) {
        // Not implemented
    };
    return StubOrderDataLayer;
}(OrderDataLayer));
export default StubOrderDataLayer;
