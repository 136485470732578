import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import Subscribe from '../../Checkout/components/Checkout/Upsale/Subscribe/Subscribe';
import Aeroexpress from '../../Aeroexpress';
import { AdditionalServiceType } from '../types';
import { useTheme } from '../../theme';
import { DisplayMode } from './types';
import CheckinMultiInsurance from '../../Insurance/CheckinInsurance/CheckinInsurance';
import Insurance from './Insurance/Insurance';
import { ModeContext, useConfig } from '../../context';
import { Mode } from '../../types';
import Business from './Business/Business';
import { makeGetGdsServicesByType } from '../../Modules/Checkin/store/order/selectors';
import { isCheckinOrderServicesResponse, isOrderServicesResponse } from '../../typeguards';
// #fixme: connect to redux
export default function AdditionalServices(_a) {
    var _b;
    var _this = this;
    var _c, _d, _e, _f, _g;
    var displayMode = _a.displayMode, _h = _a.isUpsaleVisible, isUpsaleVisible = _h === void 0 ? false : _h, orderId = _a.orderId, additionalServices = _a.additionalServices, onSetOrder = _a.onSetOrder, travellers = _a.travellers, inMobileStepbar = _a.inMobileStepbar, className = _a.className, saveOrderServices = _a.saveOrderServices, isBlockForPayment = _a.isBlockForPayment;
    var theme = useTheme('AdditionalServices').AdditionalServicesStyles;
    var mode = useContext(ModeContext);
    var _j = __read(useState(false), 2), isLoading = _j[0], setIsLoading = _j[1];
    var updateServices = function (type, codes) { return __awaiter(_this, void 0, void 0, function () {
        var params, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    params = {
                        id: orderId
                    };
                    if (type === AdditionalServiceType.Insurance) {
                        params.insurances = __spreadArray(__spreadArray([], __read(params.insurances), false), [
                            {
                                selectedPrograms: codes
                            }
                        ], false);
                    }
                    if (type === AdditionalServiceType.Transfer) {
                        params.aeroexpress = __assign(__assign({}, params.aeroexpress), { selectedTrips: codes });
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, saveOrderServices(params)];
                case 2:
                    data = _a.sent();
                    if (data && isOrderServicesResponse(data)) {
                        onSetOrder(data.SaveOrderServices);
                    }
                    else if (data && isCheckinOrderServicesResponse(data)) {
                        onSetOrder(data.CheckinSaveOrderServices);
                    }
                    else {
                        throw new Error('Не удалось добавить сервис');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var updateInsurances = updateServices.bind(null, AdditionalServiceType.Insurance);
    var updateTransfer = updateServices.bind(null, AdditionalServiceType.Transfer);
    var multiInsurance = useConfig().global.companyInfo.multiInsurance;
    var renderInsurance = useCallback(function () {
        var _a, _b, _c;
        var selectedInsurances = ((_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances)
            ? additionalServices.insurances.selectedInsurances
            : [];
        var selectedProgram = ((_b = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances) === null || _b === void 0 ? void 0 : _b.insurancePrograms.find(function (program) {
            return selectedInsurances.find(function (insurace) { return program.code === insurace.insuranceProgram.code; });
        })) || ((_c = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances) === null || _c === void 0 ? void 0 : _c.insurancePrograms.slice(0, 1)[0]);
        var singleInsurance = (React.createElement(Insurance, { displayMode: !inMobileStepbar && displayMode, insurance: selectedProgram, selectedInsurances: selectedInsurances, onClick: updateInsurances, isMobileStepbar: inMobileStepbar === 'insurance', isBlockForPayment: isBlockForPayment }));
        var multiInsuranceElement = (React.createElement(CheckinMultiInsurance, { travellers: travellers, insurances: additionalServices.insurances, displayMode: !inMobileStepbar && displayMode, isMobileStepbar: inMobileStepbar === 'insurance' }));
        if (inMobileStepbar === 'insurance') {
            if (multiInsurance && mode === Mode.Checkin) {
                return multiInsuranceElement;
            }
            else {
                return React.createElement("span", null, singleInsurance);
            }
        }
        else {
            if (multiInsurance) {
                return multiInsuranceElement;
            }
            else {
                return singleInsurance;
            }
        }
    }, [additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances, inMobileStepbar, multiInsurance, isBlockForPayment]);
    var businessLoungeSelector = makeGetGdsServicesByType(OrderAdditionalServiceGdsServiceServiceType.BusinessLounge);
    var businessLoungeServices = useSelector(businessLoungeSelector);
    return (React.createElement(React.Fragment, null,
        inMobileStepbar === 'insurance' && renderInsurance(),
        inMobileStepbar === 'aeroexpress' && !!((_d = (_c = additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.trips.length) && (React.createElement(Aeroexpress, { isMobileStepbar: true, orderId: orderId, aeroexpress: additionalServices.transfer.aeroexpress, travellers: travellers, updateServices: updateTransfer, isLoading: isLoading, isBlockForPayment: isBlockForPayment })),
        !inMobileStepbar && (React.createElement("div", { className: cn(theme.services, className, (_b = {},
                _b[theme.services_horizontal] = displayMode === DisplayMode.Horizontal,
                _b)) },
            !!((_g = (_f = (_e = additionalServices.transfer) === null || _e === void 0 ? void 0 : _e.aeroexpress) === null || _f === void 0 ? void 0 : _f.trips) === null || _g === void 0 ? void 0 : _g.length) && (React.createElement(Aeroexpress, { displayMode: displayMode, orderId: orderId, aeroexpress: additionalServices.transfer.aeroexpress, travellers: travellers, updateServices: updateTransfer, isLoading: isLoading })),
            renderInsurance(),
            (businessLoungeServices === null || businessLoungeServices === void 0 ? void 0 : businessLoungeServices.length) > 0 && React.createElement(Business, null),
            isUpsaleVisible && React.createElement(Subscribe, null)))));
}
