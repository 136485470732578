import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentType } from '../../../types';
import { useTheme } from '../../../../theme';
import Gateway from '../Gateway';
import CopyCode from '../../CopyCode/CopyCode';
var Qiwi = function (_a) {
    var frameInfo = _a.frameInfo, headerTitle = _a.headerTitle, isPreOpened = _a.isPreOpened, children = _a.children, onClick = _a.onClick;
    var css = useTheme('PaymentForm').Qiwi;
    var t = useTranslation('PaymentForm').t;
    var onClickGateway = useCallback(function () {
        var paymentCode = frameInfo === null || frameInfo === void 0 ? void 0 : frameInfo.paymentCode;
        onClick({
            title: headerTitle,
            type: PaymentType.Qiwi,
            content: (React.createElement("div", { className: css.root }, paymentCode ? (React.createElement(React.Fragment, null,
                t('Payment code'),
                ":",
                React.createElement(CopyCode, { className: css.copy, code: paymentCode }),
                React.createElement("div", { className: css.info }, t('Please, click «Edit Order» after successful payment in QIWI app to open your order.')))) : (t('Failed to get billing code'))))
        });
    }, [frameInfo, onClick]);
    useEffect(function () {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return (React.createElement(Gateway, { onClick: onClickGateway, header: children, type: t('Payment by QIWI'), paymentType: PaymentType.Qiwi }));
};
export default Qiwi;
