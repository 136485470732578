import { __assign, __read, __values } from "tslib";
import { useMemo } from 'react';
export var useVariables = function (state, service) {
    var totalPrice = useMemo(function () {
        var e_1, _a, e_2, _b;
        var _c, _d;
        var price = __assign({}, service.price);
        price.amount = 0;
        try {
            for (var _e = __values(Object.keys(state)), _f = _e.next(); !_f.done; _f = _e.next()) {
                var _g = __read(_f.value, 1), passengerId = _g[0];
                try {
                    for (var _h = (e_2 = void 0, __values(Object.keys(state[passengerId]))), _j = _h.next(); !_j.done; _j = _h.next()) {
                        var _k = __read(_j.value, 1), segmentId = _k[0];
                        if (((_c = state[passengerId][segmentId]) === null || _c === void 0 ? void 0 : _c.selected) && !((_d = state[passengerId][segmentId]) === null || _d === void 0 ? void 0 : _d.confirmed)) {
                            price.amount += service.price.amount;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return price;
    }, [state]);
    return {
        totalPrice: totalPrice,
        minPrice: service.price
    };
};
