import { __read, __spreadArray } from "tslib";
import { createSelector } from 'reselect';
export var getPassengers = function (refundState) {
    return Object.entries(refundState.passengers).map(function (_a) {
        var _b = __read(_a, 2), value = _b[1];
        return value;
    });
};
export var getPassengersWithChildren = createSelector(getPassengers, function (passengers) {
    return passengers.filter(function (passenger) { var _a; return ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) > 0; });
});
export var getSelectedPassengers = createSelector(getPassengers, function (passengers) {
    return passengers.filter(function (passenger) { return passenger.selected; });
});
export var getHasSelectedPassengers = createSelector(getSelectedPassengers, function (passengers) { return passengers.length > 0; });
export var getSelectedPassengersWithChildrenIds = createSelector(getSelectedPassengers, function (selectedPassengers) {
    var selectedIds = new Set();
    selectedPassengers.forEach(function (passenger) {
        var _a;
        var passengerId = Number(passenger.id);
        selectedIds.add(passengerId);
        if ((_a = passenger.children) === null || _a === void 0 ? void 0 : _a.length) {
            passenger.children.forEach(function (child) { return selectedIds.add(Number(child.id)); });
        }
    });
    return __spreadArray([], __read(selectedIds), false);
});
