import { __read, __spreadArray } from "tslib";
import i18n from 'i18next';
import { TravellerFieldEnum, DocTypes } from '@websky/graphql';
import { concatPassengerNames, getPassengerTypeLabel } from '../utils';
import { hideText } from '../Passenger/utils';
export var collectPassengerInfo = function (traveller) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var travellerName;
    var travellerMainInfo = [];
    var travellerAdditionalInfo = [];
    var travellerLoyaltyInfo = [];
    var travellerValues = new Map(traveller.values.map(function (params) { return [params.type, params]; }));
    if (travellerValues.has(TravellerFieldEnum.LastName)) {
        travellerName = concatPassengerNames((_a = travellerValues.get(TravellerFieldEnum.LastName)) === null || _a === void 0 ? void 0 : _a.value, (_b = travellerValues.get(TravellerFieldEnum.FirstName)) === null || _b === void 0 ? void 0 : _b.value, (_c = travellerValues.get(TravellerFieldEnum.MiddleName)) === null || _c === void 0 ? void 0 : _c.value);
    }
    else {
        travellerName = getPassengerTypeLabel(traveller.type, traveller.supplierTravellerType);
    }
    if (travellerValues.has(TravellerFieldEnum.Gender)) {
        var genderValue_1 = travellerValues.get(TravellerFieldEnum.Gender).value;
        var genderName = (_f = (_e = (_d = travellerValues
            .get(TravellerFieldEnum.Gender)) === null || _d === void 0 ? void 0 : _d.validationRules) === null || _e === void 0 ? void 0 : _e[0].options.find(function (_a) {
            var value = _a.value;
            return value === genderValue_1;
        })) === null || _f === void 0 ? void 0 : _f.label;
        if (genderName) {
            travellerMainInfo.push(i18n.t("OrderCard:Gender_".concat(genderName)));
        }
    }
    if (travellerValues.has(TravellerFieldEnum.BirthDate)) {
        travellerMainInfo.push(travellerValues.get(TravellerFieldEnum.BirthDate).value);
    }
    var nationalityValue = travellerValues.has(TravellerFieldEnum.Nationality)
        ? travellerValues.get(TravellerFieldEnum.Nationality).value
        : null;
    if (nationalityValue) {
        var nationalityName = (_j = (_h = (_g = travellerValues
            .get(TravellerFieldEnum.Nationality)) === null || _g === void 0 ? void 0 : _g.validationRules) === null || _h === void 0 ? void 0 : _h[0].options.find(function (_a) {
            var value = _a.value;
            return value === nationalityValue;
        })) === null || _j === void 0 ? void 0 : _j.label;
        if (nationalityName) {
            travellerMainInfo.push(nationalityName);
        }
    }
    if (travellerValues.has(TravellerFieldEnum.DocType)) {
        var docType = travellerValues.get(TravellerFieldEnum.DocType).value;
        var docNumber = (_k = travellerValues.get(TravellerFieldEnum.DocNumber)) === null || _k === void 0 ? void 0 : _k.value;
        var isPassport = docType === DocTypes.Passport;
        var isBirthRegDocument = docType === DocTypes.BirthRegDocument;
        var isHideDocExpiryDate = isPassport || isBirthRegDocument;
        if (docNumber) {
            travellerAdditionalInfo.push("".concat(i18n.t("Passenger:".concat(docType)), ": ").concat(hideText(docNumber)));
        }
        if (!isHideDocExpiryDate && ((_l = travellerValues.get(TravellerFieldEnum.DocExpiryDate)) === null || _l === void 0 ? void 0 : _l.value)) {
            var expireDate = travellerValues.get(TravellerFieldEnum.DocExpiryDate).value;
            travellerAdditionalInfo.push("".concat(i18n.t('OrderCard:Expired date'), ": ").concat(expireDate));
        }
    }
    if (travellerValues.has(TravellerFieldEnum.LoyaltyNumber) &&
        ((_m = travellerValues.get(TravellerFieldEnum.LoyaltyNumber)) === null || _m === void 0 ? void 0 : _m.value)) {
        var loyaltyNumber = travellerValues.get(TravellerFieldEnum.LoyaltyNumber).value;
        travellerLoyaltyInfo.push("".concat(i18n.t('Passenger:loyaltyName'), ": ").concat(loyaltyNumber));
    }
    return {
        travellerName: travellerName,
        mainInfo: travellerMainInfo,
        additionalInfo: travellerAdditionalInfo,
        loyaltyInfo: travellerLoyaltyInfo
    };
};
// ключ - кто сопровождает, значение - кого сопровождают
export var getAdultAccompaniedMap = function (travellers) {
    var passengerAccompaniedMap = new Map();
    travellers.forEach(function (traveller) {
        var _a, _b;
        if ((_a = traveller.linkedTraveller) === null || _a === void 0 ? void 0 : _a.id) {
            passengerAccompaniedMap.set(traveller.linkedTraveller.id, __spreadArray(__spreadArray([], __read(((_b = passengerAccompaniedMap.get(traveller.linkedTraveller.id)) !== null && _b !== void 0 ? _b : [])), false), [
                traveller
            ], false));
        }
    });
    return passengerAccompaniedMap;
};
