import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Bottom from './Bottom/Bottom';
import Controls from './Controls/Controls';
import Segments from './Segments/Segments';
import { generateOfferHotelLink, setFieldDataMutator } from '../../utils';
import { redirectToFastSearch, submitForm } from '../../store/actions';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { useRenderers } from '../../../renderProps';
import { isOfferHotels } from '../../store/offerHotels/selectors';
import { getSegments, isMultiCity } from '../../store/segments/selectors';
import { getPassengersForRequest, getTotalPassengersCount } from '../../store/passengers/selectors';
import SimpleLoader from '../../../SimpleLoader';
import { useCurrentUser } from '../../../CurrentUser/CurrentUser.hooks';
var LastSearch = React.lazy(function () { return import('./LastSearch/LastSearch'); });
initI18n('SearchForm');
var SearchForm = React.memo(function (_a) {
    var _b;
    var _c, _d;
    var className = _a.className, submitForm = _a.submitForm, redirectToFastSearch = _a.redirectToFastSearch, onSubmit = _a.onSubmit, renderPromoCode = _a.renderPromoCode, promoCode = _a.promoCode, segments = _a.segments, offerHotels = _a.offerHotels, passengersCount = _a.passengersCount, passengers = _a.passengers, isMultiCity = _a.isMultiCity;
    useCurrentUser();
    var _e = useConfig(), iataCode = _e.global.companyInfo.iataCode, _f = _e.SearchForm, engineURL = _f.engineURL, proMode = _f.proMode, offerHotelsLink = _f.offerHotelsLink, offerHotelsSupplier = _f.offerHotelsSupplier, offerHotelsAdditionalId = _f.offerHotelsAdditionalId, offerHotelsUtm = _f.offerHotelsUtm;
    var css = useTheme('SearchForm').SearchForm;
    var _g = __read(React.useState(false), 2), loading = _g[0], setLoading = _g[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var generatedHotelLink;
        return __generator(this, function (_a) {
            if (offerHotels) {
                setLoading(true);
                generatedHotelLink = generateOfferHotelLink(offerHotelsLink, segments, passengersCount, passengers, iataCode, offerHotelsSupplier, offerHotelsAdditionalId, offerHotelsUtm);
                redirectToFastSearch({
                    engineUrl: engineURL,
                    hotelsUrl: generatedHotelLink,
                    openOfferHotelsStrategy: 'currentTab'
                });
                return [2 /*return*/];
            }
            if (onSubmit) {
                submitForm(onSubmit);
            }
            else if (engineURL) {
                redirectToFastSearch({
                    engineUrl: engineURL
                });
            }
            return [2 /*return*/];
        });
    }); };
    var BeforeAdornment = (_d = (_c = useRenderers().SearchFormRenderers) === null || _c === void 0 ? void 0 : _c.BeforeAdornment) !== null && _d !== void 0 ? _d : (function () { return null; });
    return (React.createElement("div", { className: cn(css.searchForm, className, (_b = {},
            _b[css.searchForm_advanced] = proMode,
            _b[css.searchForm_multiCity] = isMultiCity,
            _b)) },
        React.createElement("div", { className: css.wrapper },
            BeforeAdornment && (React.createElement("div", { className: css.beforeAdornment__wrapper },
                React.createElement(BeforeAdornment, null))),
            React.createElement(Form, { onSubmit: handleSubmit, mutators: { setFieldData: setFieldDataMutator }, subscription: {} }, function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (React.createElement("form", { onSubmit: handleSubmit, "data-search-form": true },
                    React.createElement("div", { className: css.row },
                        React.createElement(Segments, null),
                        React.createElement(Controls, null)),
                    React.createElement(Bottom, { renderPromoCode: renderPromoCode, promoCode: promoCode })));
            })),
        proMode && (React.createElement(React.Suspense, { fallback: "Loading..." },
            React.createElement(LastSearch, null))),
        loading && React.createElement(SimpleLoader, null)));
});
var mapStateToProps = function (state) { return ({
    offerHotels: isOfferHotels(state),
    segments: getSegments(state),
    passengersCount: getTotalPassengersCount(state),
    passengers: getPassengersForRequest(state),
    isMultiCity: isMultiCity(state)
}); };
var mapDispatchToProps = {
    submitForm: submitForm,
    redirectToFastSearch: redirectToFastSearch
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
