import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Payment from '../../../../../../Checkout/components/Checkout/routes/Payment';
import { getCheckinOrderId } from '../../../../store/order/selectors';
import { CheckinStep, useSteps } from '../../../../utils';
import SimpleLoader from '../../../../../../SimpleLoader';
import { useUnlockCheckinOrder } from '../hooks';
import { useCartPrices } from '../../../../../../Cart/hooks';
import { useGetCheckinOrderQuery } from '@websky/graphql';
var PaymentPage = memo(function () {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var location = useLocation();
    var orderId = useSelector(getCheckinOrderId);
    var _b = useSteps(), previousStep = _b.previousStep, setStep = _b.setStep;
    var refetch = useGetCheckinOrderQuery({ skip: true }).refetch;
    var priceToPay = useCartPrices();
    useEffect(function () {
        var refetchInterval;
        if (orderId) {
            refetchInterval = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, refetch({ id: orderId })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, 60000 * 3);
        }
        return function () { return clearInterval(refetchInterval); };
    }, [orderId]);
    var unlockOrder = useUnlockCheckinOrder(setIsLoading);
    var onSuccessPayment = useCallback(function (reload) {
        setStep(CheckinStep.Passengers);
        if (reload) {
            window.location.reload();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Payment, { orderId: orderId, priceToPay: priceToPay, goToPrevStep: function () { return previousStep(CheckinStep.Payment); }, onSuccess: onSuccessPayment, redirectPathname: location.pathname, onPaymentMethodsLoaded: refetch, onBackToOrder: unlockOrder }),
        isLoading && React.createElement(SimpleLoader, null)));
});
export default PaymentPage;
