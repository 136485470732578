import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { icons } from '../../icons';
var FareGroupIcon = function (_a) {
    var className = _a.className, optionType = _a.optionType;
    var css = useTheme('FareGroup').FareGroupIcon;
    return React.createElement("span", { className: cn(css.container, className) }, icons[optionType]);
};
export default FareGroupIcon;
