import { __assign } from "tslib";
import { SET_PASSENGER_VALUES, SET_PASSENGERS_STATE } from './actions';
export var passengersReducer = function (state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case SET_PASSENGERS_STATE:
            return action.payload;
        case SET_PASSENGER_VALUES:
            var valuesData_1 = action.payload;
            return state.map(function (passenger, index) { return (__assign(__assign({}, passenger), { values: index === valuesData_1.id ? valuesData_1.values : passenger.values })); });
        /*case SET_PASSENGER_SERVICES:
            const servicesData = (action as SetPassengerServicesAction).payload;

            return state.map((passenger, index) => ({
                ...passenger,
                services:
                    index === servicesData.id
                        ? servicesData.services
                                .filter(service => service.count > 0)
                                .map(service => ({
                                    serviceId: service.serviceId,
                                    count: service.count,
                                    segmentIds: service.segmentIds,
                                    confirmedCount: service.confirmedCount
                                }))
                        : passenger.services
            }));

        case SET_PASSENGER_SEATS:
            const seatsData = (action as SetPassengerSeatsAction).payload;

            return state.map((passenger, index) => ({
                ...passenger,
                seats: index === seatsData.id ? seatsData.seats : passenger.seats
            }));*/
    }
    return state;
};
