import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Form, Field } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import RefundPaper from '../RefundPaper/RefundPaper';
import PhoneInput from '../../../PhoneInput';
import { FormControlLabel } from '@material-ui/core';
import { useRequestFormValidation } from './hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import Input from '../../../Input';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { getSelectedPassengers } from '../../store/passengers/selectors';
import InfoLinksLabel from '../../../InfoLinksLabel';
import { getAviaOrderTravellers, getCustomer } from '../../store/aviaOrder/selectors';
import { getTravellerInfo } from '../../store/aviaOrder/utils';
var RequestForm = function (props) {
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').RequestForm;
    var _a = useRequestFormValidation(), validateEmail = _a.validateEmail, validateAgreeButton = _a.validateAgreeButton, validateComments = _a.validateComments, validateName = _a.validateName;
    var selectedPassengers = useSelector(getSelectedPassengers);
    var travellers = useSelector(getAviaOrderTravellers);
    var refundState = useSelector(function (state) { return state; });
    var customer = useSelector(getCustomer);
    var travellerInfo = React.useMemo(function () {
        return getTravellerInfo(customer, travellers, selectedPassengers.map(function (p) { return p.id; }));
    }, [customer, travellers, selectedPassengers]);
    return (React.createElement("div", { className: css.wrapper },
        props.renderPrice && props.renderPrice(),
        React.createElement("div", { className: css.notes }, props.renderNotes && props.renderNotes()),
        React.createElement(Form, { onSubmit: props.onSubmit }, function (_a) {
            var handleSubmit = _a.handleSubmit, valid = _a.valid, errors = _a.errors;
            return (React.createElement(React.Fragment, null,
                React.createElement(RefundPaper, { className: props.className },
                    React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(FormGroup, null,
                            React.createElement(FormLabel, { className: css.formLabel }, t('Create request')),
                            React.createElement(Field, { name: 'name', initialValue: travellerInfo.name, type: 'string', validate: validateName, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(Input, { className: css.formName, absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, input), { classes: { root: css.control }, error: !!(meta.touched && meta.error), helperText: meta.touched && t(meta.error) }), label: t('Your name') }));
                                } }),
                            React.createElement(Field, { name: 'email', initialValue: travellerInfo.email, type: 'string', validate: validateEmail, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(Input, { className: css.formEmail, TextFieldProps: __assign(__assign({}, input), { classes: { root: css.control }, error: !!(meta.error && meta.touched), helperText: meta.touched && t(meta.error) }), label: t('Your email') }));
                                } }),
                            React.createElement(Field, { name: 'phone', type: 'string', initialValue: travellerInfo.phone, render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(PhoneInput, __assign({ className: cn(css.control, css.formTel) }, input, { textFieldsProps: {
                                            error: !!(meta.error && meta.touched),
                                            helperText: meta.touched && t(meta.error),
                                            label: t('Your phone'),
                                            fullWidth: true,
                                            onChange: function (event) {
                                                input.onChange(event);
                                            }
                                        } })));
                                } }),
                            React.createElement(Field, { name: 'comments', validate: validateComments, type: 'string', render: function (_a) {
                                    var input = _a.input, meta = _a.meta;
                                    return (React.createElement(Input, { className: css.formComment, TextFieldProps: __assign(__assign({}, input), { classes: { root: css.control }, error: !!(meta.error && meta.touched), helperText: meta.touched && t(meta.error) }), label: t('Your comments') }));
                                } })))),
                React.createElement(Field, { name: 'agree', type: 'checkbox', validate: validateAgreeButton, render: function (_a) {
                        var input = _a.input;
                        var type = input.type, restInputProps = __rest(input, ["type"]);
                        return (React.createElement(FormControlLabel, { className: css.agree, control: React.createElement(Checkbox, __assign({ classes: { root: css.checkbox, checked: cn(css.checkbox, css.active) } }, restInputProps)), label: React.createElement(InfoLinksLabel, { order: refundState, type: 'refund' }) }));
                    } }),
                props.renderButtons && props.renderButtons(valid, handleSubmit)));
        })));
};
export default RequestForm;
