import { __assign, __read, __spreadArray } from "tslib";
import { createStore as originalCreateStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { rootReducer } from './reducers';
import { get, SEARCH_FORM_STATE_KEY } from '../../cache';
import { getLocale } from '../../utils';
import memes from '../../memes';
export var createStore = memes(function (initialState, defaultParameters) {
    if (initialState === void 0) { initialState = {}; }
    var defaultState = {};
    var middlewares = [];
    var searchFormState = get(SEARCH_FORM_STATE_KEY, {});
    if (searchFormState && searchFormState.state && searchFormState.locale === getLocale()) {
        defaultState = __assign(__assign({}, defaultState), { segments: searchFormState.state.segments.simpleRouteSegment });
    }
    if (defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.segments) {
        defaultState = __assign(__assign({}, defaultState), { segments: defaultParameters.segments[0] });
    }
    if (initialState) {
        defaultState = __assign(__assign({}, defaultState), initialState);
    }
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }
    return originalCreateStore(rootReducer, defaultState, applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false)));
});
