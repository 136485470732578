import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import cn from 'classnames';
import DownloadAeroexpress from '../RegisteredPassenger/DownloadAeroexpress/DownloadAeroexpress';
import SelectedPassenger from '../../../../../../../SelectedPassenger';
import MediaQuery from '../../../../../../../MediaQuery/MediaQuery';
import AddPassenger from '../../../../../../../AddPassenger/AddPassenger';
import BoardingPassMob from '../../../../../../../BoardingPassMob';
import RegisteredPassenger from '../RegisteredPassenger/RegisteredPassenger';
import { getCheckinHasCompleted, getCheckinNotStarted, getCheckinOrder, getHasConfirmedAeroexpress, getIsAllTravellersHavePreseating, getPassengerIdsWithLinkedPassengers } from '../../../../../store/order/selectors';
import { useTheme } from '../../../../../../../theme';
var PassengersContent = function (_a) {
    var _b;
    var className = _a.className, segmentsFailedBoardingPasses = _a.segmentsFailedBoardingPasses, onAddTraveller = _a.onAddTraveller, onRemoveTraveller = _a.onRemoveTraveller, onCheckinCancel = _a.onCheckinCancel, setIsLoading = _a.setIsLoading, setSegmentsFailedBoardingPasses = _a.setSegmentsFailedBoardingPasses;
    var css = useTheme('Checkin').PassengersContent;
    var order = useSelector(getCheckinOrder);
    var passengerIdsWithLinkedPassengers = useSelector(getPassengerIdsWithLinkedPassengers);
    var hasConfirmedAeroexpress = useSelector(getHasConfirmedAeroexpress);
    var isConfirmed = useSelector(getCheckinHasCompleted);
    var isNotStarted = useSelector(getCheckinNotStarted);
    var isAllTravellersHavePreseating = useSelector(getIsAllTravellersHavePreseating);
    var registeredPassengers = order.travellers.map(function (traveller, index) {
        return (React.createElement("div", { className: css.registeredPassenger },
            React.createElement(MediaQuery, { maxWidth: "mobile" }, order.segments.map(function (segment, index) { return (React.createElement(BoardingPassMob, { key: index, className: css.segment, segment: segment, isCheckedIn: traveller.isConfirmed })); })),
            React.createElement(RegisteredPassenger, { key: index, passenger: traveller, linkedPassengers: passengerIdsWithLinkedPassengers.get(traveller.id), segments: order.segments, segmentsFailedBoardingPasses: segmentsFailedBoardingPasses, setSegmentsFailedBoardingPasses: setSegmentsFailedBoardingPasses, onCheckinCancel: onCheckinCancel })));
    });
    var sliderOptions = {
        className: css.slider,
        slidesToShow: order.travellers.length > 1 ? 1.1 : 1.03,
        dots: true,
        arrows: false,
        infinite: false,
        useCSS: true
    };
    return (React.createElement("div", { className: cn(className, css.passengers, (_b = {},
            _b[css.passengers_confirmed] = isConfirmed,
            _b[css.passengers_started] = !isNotStarted,
            _b)) },
        isConfirmed && hasConfirmedAeroexpress && (React.createElement(DownloadAeroexpress, { travellers: order.travellers.map(function (traveller) { return ({
                id: traveller.id
            }); }), orderId: order.id, setLoading: setIsLoading })),
        isConfirmed ? (React.createElement("div", null,
            React.createElement(MediaQuery, { minWidth: "mobile" }, registeredPassengers),
            React.createElement(MediaQuery, { maxWidth: "mobile" },
                React.createElement(Slider, __assign({}, sliderOptions), registeredPassengers)))) : (React.createElement("div", null, order.travellers.map(function (traveller, index) {
            var canRemove = isNotStarted && order.travellers.length > 1 && traveller.canBeDeleted;
            return (React.createElement(SelectedPassenger, { key: index, className: css.passenger, passenger: traveller, onDelete: canRemove ? onRemoveTraveller : undefined }));
        }))),
        isNotStarted && !isAllTravellersHavePreseating && React.createElement(AddPassenger, { onSubmit: onAddTraveller })));
};
export default PassengersContent;
