import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var PROMO_ALIAS = 'Buy now and save 20-30%';
var PromoMessage = function (_a) {
    var className = _a.className;
    var css = useTheme('AdditionalBaggage').PromoMessage;
    var _b = useTranslation('AdditionalBaggage'), t = _b.t, i18n = _b.i18n;
    var message = t(PROMO_ALIAS);
    var existMessage = i18n.exists(PROMO_ALIAS, { ns: 'AdditionalBaggage' });
    if (!message || !existMessage) {
        return null;
    }
    return React.createElement("div", { className: cn(css.root, className) }, message);
};
export default PromoMessage;
