import * as PassengerStyles from './components/Passenger.css';
import * as Switch from './components/Field/Switch/Switch.css';
import * as Select from './components/Field/Select/Select.css';
import * as LastName from './components/Field/LastName/LastName.css';
export default {
    PassengerStyles: PassengerStyles,
    Switch: Switch,
    Select: Select,
    LastName: LastName
};
