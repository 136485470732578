import { __assign, __decorate, __extends } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import Dropdown from '../../Dropdown/Dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Input from '../../Input';
import { withTheme } from '../withThemeDecorator';
// TODO: rewrite with functional component
// @ts-ignore
var Autocomplete = /** @class */ (function (_super) {
    __extends(Autocomplete, _super);
    function Autocomplete(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isOpen: false
        };
        _this.onFocus = _this.onFocus.bind(_this);
        _this.onBlur = _this.onBlur.bind(_this);
        _this.onClose = _this.onClose.bind(_this);
        _this.renderGroup = _this.renderGroup.bind(_this);
        _this.renderOption = _this.renderOption.bind(_this);
        _this.renderDropdown = _this.renderDropdown.bind(_this);
        return _this;
    }
    Autocomplete.prototype.onFocus = function (event) {
        var _a, _b;
        (_b = (_a = this.props).onFocus) === null || _b === void 0 ? void 0 : _b.call(_a, event);
        this.setState({
            isOpen: true
        });
    };
    Autocomplete.prototype.onBlur = function (event) {
        var _this = this;
        setTimeout(function () {
            _this.setState({
                isOpen: false
            });
        }, 200);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };
    Autocomplete.prototype.onClose = function () {
        this.setState({
            isOpen: false
        });
    };
    Autocomplete.prototype.getOnSelect = function (item) {
        var _this = this;
        return function () {
            _this.props.onSelect(item);
            _this.onClose();
        };
    };
    Autocomplete.prototype.renderInput = function () {
        var css = this.props.theme.Autocomplete.Autocomplete;
        var _a = this.props, inputValue = _a.inputValue, onInputChange = _a.onInputChange;
        var inputProps = {
            type: 'text',
            className: css.input,
            value: inputValue,
            onChange: onInputChange,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            spellCheck: false,
            autoComplete: 'off',
            autoCorrect: 'off'
        };
        // Custom renderer.
        if (this.props.inputRenderer) {
            return this.props.inputRenderer(this.state.isOpen, this.props.isLoading, inputProps);
        }
        return (React.createElement(Input, { label: this.props.label, value: inputValue, name: this.props.name || '', className: css.input, autoFocus: this.props.autofocus, disabled: this.props.disabled, variant: this.props.variant, TextFieldProps: {
                error: !!this.props.error,
                helperText: this.props.error,
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                autoComplete: inputProps.autoComplete,
                InputProps: {
                    onChange: onInputChange,
                    endAdornment: this.props.endAdornment || '',
                    autoComplete: inputProps.autoComplete,
                    className: css.inner_input
                }
            }, absoluteHintPosition: true }));
    };
    Autocomplete.prototype.renderGroup = function (group, key) {
        var css = this.props.theme.Autocomplete.Autocomplete;
        // Custom renderer.
        if (this.props.groupRenderer) {
            return this.props.groupRenderer(group, key, this.renderOption);
        }
        return (!!group.options.length && (React.createElement("div", { key: key, className: css.group },
            React.createElement("div", { className: css.group__label }, group.label),
            React.createElement("div", { className: css.group__options }, group.options.map(this.renderOption)))));
    };
    Autocomplete.prototype.renderOption = function (option, key) {
        var css = this.props.theme.Autocomplete.Autocomplete;
        // Custom renderer.
        if (this.props.optionRenderer) {
            return this.props.optionRenderer(option, key, this.getOnSelect(option.value));
        }
        if (this.props.callOnSelectOn === 'mouseDown') {
            return (React.createElement(MenuItem, { key: key, className: css.option, onMouseDown: this.getOnSelect(option.value) }, option.label));
        }
        return (React.createElement(MenuItem, { key: key, className: css.option, onClick: this.getOnSelect(option.value) }, option.label));
    };
    Autocomplete.prototype.renderDropdown = function () {
        var _this = this;
        var _a = this.props, dropdownRenderer = _a.dropdownRenderer, options = _a.options, onClickOutsideProps = _a.onClickOutsideProps, onInputChange = _a.onInputChange, onSelect = _a.onSelect;
        var css = this.props.theme.Autocomplete.Autocomplete;
        // Custom renderer.
        if (dropdownRenderer) {
            return dropdownRenderer({
                options: options,
                isOpen: this.state.isOpen,
                onClose: this.onClose,
                onInputChange: onInputChange,
                onSelect: onSelect,
                renderGroup: this.renderGroup,
                renderOption: this.renderOption
            });
        }
        return (React.createElement(Dropdown, __assign({}, onClickOutsideProps, { isOpen: options.length && this.state.isOpen, onClose: this.onClose }),
            React.createElement(Paper, { className: css.options, "data-test-id": "options" }, options.map(function (item, index) {
                return 'value' in item ? _this.renderOption(item, index) : _this.renderGroup(item, index);
            }))));
    };
    Autocomplete.prototype.render = function () {
        var _a = this.props, className = _a.className, innerRef = _a.innerRef;
        var css = this.props.theme.Autocomplete.Autocomplete;
        return (React.createElement("div", { ref: innerRef, className: cn(css.autocomplete, className) },
            this.renderInput(),
            this.renderDropdown()));
    };
    Autocomplete.displayName = 'Autocomplete';
    Autocomplete = __decorate([
        withTheme
    ], Autocomplete);
    return Autocomplete;
}(React.PureComponent));
export default Autocomplete;
