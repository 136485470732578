import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import Results from '../../../Results';
import ResultsError from '../../../ResultsError';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmptySearchResultSegmentsError } from '../../../Engine/components/errors';
import { BaseButton } from '../../../index';
export var ResultsWrapper = React.memo(function (_a) {
    var loadResults = _a.loadResults, resultId = _a.resultId, isLoading = _a.isLoading, error = _a.error, restProps = __rest(_a, ["loadResults", "resultId", "isLoading", "error"]);
    var t = useTranslation().t;
    var history = useHistory();
    useEffect(function () {
        if (!isLoading && !restProps.searchResults && !error) {
            loadResults(resultId);
        }
    }, []);
    if (isLoading) {
        return null;
    }
    var isEmptySearchResults = !restProps.searchResults;
    var isEmptySearchResultSegments = error && error instanceof EmptySearchResultSegmentsError;
    if (isEmptySearchResults || isEmptySearchResultSegments) {
        return (React.createElement(ResultsError, { header: t('It looks like something went wrong'), body: React.createElement("div", null,
                t('Please reload the page or start a new search'),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(BaseButton, { variant: 'optionAction', onClick: function () {
                        history.push('/');
                    } }, t('Go to main page'))) }));
    }
    return React.createElement(Results, __assign({}, restProps));
});
