import { __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useGetAviaOrdersListQuery } from '@websky/graphql';
import NextFlight from '../NextFlight';
import ProfileControls from '../../../ProfileControls';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import UserCard from '../../../UserCard/components/UserCard';
import { TravellerTypes } from '../../../Traveller/types';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH, redirectToOrder } from '../../../utils';
import { findUpcomingFlight } from './utils';
import { useConfig } from '../../../context';
var HomePage = function (_a) {
    var currentUser = _a.currentUser;
    var css = useTheme('AccountModule').Account;
    var t = useTranslation('Account').t;
    var history = useHistory();
    var engineURL = useConfig().Account.engineURL;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _b = useGetAviaOrdersListQuery({ fetchPolicy: 'no-cache' }), orders = _b.data, refetch = _b.refetch;
    var upcomingOrder = React.useMemo(function () { return findUpcomingFlight(orders === null || orders === void 0 ? void 0 : orders.AviaOrdersList); }, [orders === null || orders === void 0 ? void 0 : orders.AviaOrdersList]);
    var onOrderClickHandler = function (orderId) {
        redirectToOrder(engineURL, orderId);
    };
    var onDeleted = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, refetch()];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: css.route__title }, t('My office')),
        React.createElement(UserCard, { className: css.homeUserCard, type: TravellerTypes.PersonalDetails, profile: currentUser, onClick: function () { return history.push('/account/settings'); }, editText: !isMobile ? t('Edit') : null, showArrow: true }),
        upcomingOrder && (React.createElement(NextFlight, { order: upcomingOrder, onClick: onOrderClickHandler, onDeleted: onDeleted })),
        React.createElement(ProfileControls, null)));
};
export default HomePage;
