import * as ServiceWrapper from './components/ServiceWrapper/ServiceWrapper.css';
import * as CategoriesStyles from './components/Categories/Categories.css';
import * as List from './components/Categories/List/List.module.css';
import * as Warning from './components/Warning/Warning.module.css';
import { createTheme } from '../utils';
export var defaultTheme = {
    ServiceWrapper: ServiceWrapper,
    CategoriesStyles: CategoriesStyles,
    List: List,
    Warning: Warning
};
export var useTheme = createTheme(defaultTheme).useTheme;
export default defaultTheme;
