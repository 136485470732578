import { __extends } from "tslib";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { Sorting } from '../../../../enums';
import { ThemeConsumer } from '../../../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import SlideBottom from '../../../../../SlideBottom';
import { Switch, BaseButton, CloseButton } from '../../../../../index';
var sortings = [Sorting.Price, Sorting.FlightTime, Sorting.Departure, Sorting.Arrival];
var Sortings = /** @class */ (function (_super) {
    __extends(Sortings, _super);
    function Sortings(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isOpen: false
        };
        _this.getTitleRef = _this.getTitleRef.bind(_this);
        _this.onToggle = _this.onToggle.bind(_this);
        _this.onClose = _this.onClose.bind(_this);
        return _this;
    }
    Sortings.prototype.getTitleRef = function (element) {
        this.titleRef = element;
    };
    Sortings.prototype.getOnClick = function (sorting) {
        var _this = this;
        return function () {
            _this.props.onSelect(sorting);
        };
    };
    Sortings.prototype.onToggle = function () {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };
    Sortings.prototype.onClose = function () {
        this.setState({
            isOpen: false
        });
    };
    Sortings.prototype.render = function () {
        var _a;
        var _this = this;
        var t = this.props.t;
        var sortingLabels = (_a = {},
            _a[Sorting.Price] = t('Price'),
            _a[Sorting.Departure] = t('Departure time'),
            _a[Sorting.FlightTime] = t('Flight time'),
            _a[Sorting.Arrival] = t('Arrival'),
            _a);
        return (React.createElement(ThemeConsumer, null, function (_a) {
            var _b, _c, _d, _e;
            var css = _a.Results.Sortings;
            return (React.createElement("div", { className: css.sortings },
                React.createElement("span", { className: cn(css.title, (_b = {}, _b[css.title_open] = _this.state.isOpen, _b)), ref: _this.getTitleRef, "data-test-id": "title", onClick: _this.onToggle },
                    React.createElement("span", { className: css.sortBy },
                        t('Sort by'),
                        ": "),
                    React.createElement("span", { className: css.currentSorting }, sortingLabels[_this.props.currentSort])),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("div", null,
                        _this.props.showDirectFlightsSwitch && (React.createElement(BaseButton, { variant: "optionAction", className: cn(css.flightType, (_c = {},
                                _c[css.flightType__active] = _this.props.onlyDirect,
                                _c)), onClick: _this.props.onFilterChange }, t('Nonstop'))),
                        _this.props.showBusinessClass && (React.createElement(BaseButton, { variant: "optionAction", className: cn(css.flightType, (_d = {},
                                _d[css.flightType__active] = _this.props.onlyBusiness,
                                _d)), onClick: _this.props.onBusinessClassChange }, t('Business Class')))),
                    React.createElement(Menu, { anchorEl: _this.titleRef, open: _this.state.isOpen, onClose: _this.onClose, classes: {
                            paper: css.paper
                        } }, sortings.map(function (sorting) {
                        var _a;
                        return (React.createElement(MenuItem, { key: sorting, onClick: _this.getOnClick(sorting), classes: {
                                gutters: cn(css.listItem, (_a = {},
                                    _a[css.listItem_active] = sorting === _this.props.currentSort,
                                    _a))
                            } }, sortingLabels[sorting]));
                    }))),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, ReactDOM.createPortal(React.createElement(SlideBottom, { className: css.slideBottom, isOpen: _this.state.isOpen, onClose: _this.onClose },
                    React.createElement("div", { className: css.mobile },
                        React.createElement("ul", { className: cn(css.mobile__list, (_e = {}, _e[css.mobile__list_sorting] = true, _e)) },
                            React.createElement("div", { className: css.mobile__list__title },
                                t('Sorting'),
                                ' ',
                                React.createElement(CloseButton, { className: css.mobile__list__close, onClick: _this.onClose, variant: "closePopup" })),
                            sortings.map(function (sorting) {
                                var _a;
                                return (React.createElement("li", { key: sorting, className: css.mobile__list__item },
                                    React.createElement(BaseButton, { variant: "optionAction", onClick: _this.getOnClick(sorting), className: cn(css.mobile__list__button, (_a = {},
                                            _a[css.mobile__list__button_inactive] = sorting !== _this.props.currentSort,
                                            _a)) }, sortingLabels[sorting])));
                            })),
                        (_this.props.showDirectFlightsSwitch || _this.props.showBusinessClass) && (React.createElement("div", { className: css.switchWrp },
                            _this.props.showDirectFlightsSwitch && (React.createElement(Switch, { labelClassName: css.switch__label, mainClassName: css.switch__component, activeClassName: css.switch_active, label: t('Only direct flights'), isActive: _this.props.onlyDirect, onClick: _this.props.onFilterChange, switchPosition: 'right' })),
                            _this.props.showBusinessClass && (React.createElement(Switch, { labelClassName: css.switch__label, mainClassName: css.switch__component, activeClassName: css.switch_active, label: t('Only business class'), isActive: _this.props.onlyBusiness, onClick: _this.props.onBusinessClassChange, switchPosition: 'right' })))),
                        React.createElement("div", { className: css.mobile__actions },
                            React.createElement(BaseButton, { className: css.mobile__closeButton, variant: "text", onClick: _this.onClose }, t('Ok'))))), document.body))));
        }));
    };
    Sortings.defaultProps = {
        currentSort: Sorting.Price,
        onlyDirect: false,
        showBusinessClass: false
    };
    return Sortings;
}(React.Component));
export default withTranslation('Results')(Sortings);
