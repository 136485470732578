import { __read, __spreadArray } from "tslib";
import { applyMiddleware } from 'redux';
import { createStore as originalCreateStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import memes from '../../memes';
import rootReducer from './reducers';
import rootSaga from './sagas';
export var createStore = memes(function (initialState) {
    var saga = createSagaMiddleware();
    var middlewares = [saga];
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(require('redux-logger').default);
    }
    var store = originalCreateStore(rootReducer, initialState, applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false)));
    saga.run(rootSaga);
    // @ts-ignore
    return store;
});
