import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { LockIcon } from '../../Icons';
import { initI18n } from '../../../utils';
import { Choosed } from '../../../Icons';
import { useConfig } from '../../../context';
import { OverrideComponent, useRenderers } from '../../../renderProps';
initI18n('FareLock');
var DescriptionBlock = function (_a) {
    var _b;
    var className = _a.className, title = _a.title, description = _a.description, showConfirmedIcon = _a.showConfirmedIcon, freezeUntil = _a.freezeUntil;
    var _c = useTranslation('FareLock'), t = _c.t, i18n = _c.i18n;
    var css = useTheme('FareLock').DescriptionBlock;
    var companyInfo = useConfig().global.companyInfo;
    var RenderWarningDescription = useRenderers().RenderWarningDescription;
    var existWarningDescription = i18n.exists('WarningDescription', { ns: 'FareLock' });
    return (React.createElement("div", { className: cn(css.root, className) },
        React.createElement("div", { className: css.icon },
            React.createElement(OverrideComponent, { componentProps: {}, component: { RenderFareLockIcon: function () { return LockIcon; } } })),
        React.createElement("div", { className: css.title },
            showConfirmedIcon && React.createElement("span", { className: css.confirmedIcon }, Choosed), (_b = title()) !== null && _b !== void 0 ? _b : t('Fixation the cost of the air ticket')),
        React.createElement("div", { className: css.description }, description !== null && description !== void 0 ? description : t('No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}', {
            hours: freezeUntil,
            plural: t('hours', { count: freezeUntil })
        }),
            existWarningDescription && (React.createElement("div", { className: css.warning, dangerouslySetInnerHTML: { __html: t('WarningDescription') } })),
            RenderWarningDescription && !existWarningDescription && (React.createElement("div", { className: css.warning },
                React.createElement(RenderWarningDescription, null))),
            companyInfo.farelockURL && (React.createElement("a", { href: companyInfo.farelockURL, className: css.rules, target: "_blank" }, t('Rules of service provision'))))));
};
export default DescriptionBlock;
