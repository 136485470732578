import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../../Link';
import Button from '../../../Button';
import { useTheme } from '../../../theme';
var LoginBlock = function (_a) {
    var onClick = _a.onClick, onSkipClick = _a.onSkipClick;
    var css = useTheme('Contacts').LoginBlock;
    var t = useTranslation('Contacts').t;
    var onSkipButtonClick = function (event) {
        event.preventDefault();
        onSkipClick();
    };
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.text }, t('Want to speed up passenger data entry? Sign in and continue.')),
        React.createElement("div", { className: css.signIn },
            React.createElement(Link, { className: css.signIn__skip, action: onSkipButtonClick }, t('Continue without signing in')),
            React.createElement(Button, { onClick: onClick }, t('Sign in')))));
};
export default LoginBlock;
