// States
export var isCheckoutOrderGuard = function (order) {
    return order.hasOwnProperty('locator');
};
export var isExchangeStateGuard = function (state) {
    return 'flightsToExchange' in state;
};
export var isResultsStateGuard = function (state) {
    return 'legs' in state;
};
export var isCheckoutStateGuard = function (state) {
    return 'passengers' in state;
};
// Responses
export var isOrderServicesResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.SaveOrderServices) !== undefined;
};
export var isCheckinOrderServicesResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.CheckinSaveOrderServices) !== undefined;
};
export var isOrderResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.Order) !== undefined;
};
export var isCheckinOrderResponse = function (response) {
    return (response === null || response === void 0 ? void 0 : response.CheckinOrder) !== undefined;
};
export var isCheckinFlightGuard = function (flight) {
    return (flight === null || flight === void 0 ? void 0 : flight.checkinInfo) !== undefined;
};
