import { __extends } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { getProgressLevel } from '../../utils';
import { Money as MoneyComponent } from '../../../Money';
import { createWithThemeDecorator } from '../../../utils';
import { ThemeConsumer } from '../../../theme';
var Legends = /** @class */ (function (_super) {
    __extends(Legends, _super);
    function Legends() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Legends.prototype.componentDidMount = function () {
        if (this.childrenRef) {
            this.legendsWrapperRef.style.height = "".concat(this.childrenRef.offsetHeight, "px");
        }
    };
    Legends.prototype.renderLine = function (price, level, className, specialText) {
        var theme = this.props.theme.PriceGraph.LegendsStyles;
        return (React.createElement("div", { className: classnames(theme.line, className), style: {
                bottom: "".concat(level, "%")
            } },
            React.createElement("div", { className: theme.label },
                React.createElement("span", { className: theme.label__text }, specialText && React.createElement("span", null, specialText)),
                React.createElement(MoneyComponent, { moneyClassName: theme.money, money: {
                        amount: price.amount,
                        currency: price.currency
                    } }))));
    };
    Legends.prototype.render = function () {
        var _this = this;
        var _a = this.props, maxPrice = _a.maxPrice, minPrice = _a.minPrice, theme = _a.theme.PriceGraph.LegendsStyles;
        var averagePrice = {
            amount: Math.round(maxPrice.amount / 2),
            currency: maxPrice.currency
        };
        // Show price lines only if the difference between prices is noticeable.
        var averageLineIsVisible = (averagePrice.amount - minPrice.amount) / averagePrice.amount > 0.4;
        var maxLineIsVisible = (maxPrice.amount - minPrice.amount) / maxPrice.amount > 0.2;
        var isMinAndMaxPricesEqual = minPrice.amount === maxPrice.amount;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.legends, ref: function (ref) { return (_this.legendsWrapperRef = ref); } },
                (maxLineIsVisible || isMinAndMaxPricesEqual) &&
                    this.renderLine(this.props.maxPrice, 100, theme.line_max),
                averageLineIsVisible && this.renderLine(averagePrice, 50),
                !isMinAndMaxPricesEqual &&
                    this.renderLine(this.props.minPrice, getProgressLevel(this.props.minPrice.amount, this.props.maxPrice.amount), theme.line_min)),
            this.props.children(function (ref) { return (_this.childrenRef = ref); })));
    };
    return Legends;
}(React.Component));
export default createWithThemeDecorator(ThemeConsumer)(withTranslation('PriceGraph')(Legends));
