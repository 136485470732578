import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { getLocale } from '../../../utils';
import Money from '../../../Money';
import { isZeroPrice as getIsZeroPrice } from '../../utils';
var SeatPrice = function (_a) {
    var className = _a.className, priceFromClassName = _a.priceFromClassName, moneyClassName = _a.moneyClassName, amountClassName = _a.amountClassName, currencyClassName = _a.currencyClassName, priceFrom = _a.priceFrom, props = __rest(_a, ["className", "priceFromClassName", "moneyClassName", "amountClassName", "currencyClassName", "priceFrom"]);
    var t = useTranslation('SeatMap').t;
    var theme = useTheme('SeatMap').SeatPrice;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var locale = getLocale();
    var showFromAfterMoney = ['A9', 'RM'].includes(iataCode) && ['ka', 'hy'].includes(locale);
    var isZeroPrice = getIsZeroPrice(props.money);
    var money = (React.createElement(React.Fragment, null,
        priceFrom && !showFromAfterMoney && React.createElement("span", { className: priceFromClassName }, t('from')),
        React.createElement(Money, __assign({ moneyClassName: cn(moneyClassName, theme.money), amountClassName: cn(amountClassName, theme.amount), currencyClassName: cn(currencyClassName, theme.currency) }, props)),
        priceFrom && showFromAfterMoney && React.createElement("span", { className: priceFromClassName }, t('from'))));
    if (!isZeroPrice) {
        return React.createElement("span", { className: className }, money);
    }
    return (React.createElement("span", { className: cn(theme.root, theme.root_zeroPrice, className) },
        isZeroPrice && React.createElement("span", { className: theme.root__text }, t('Free seat')),
        React.createElement("span", { className: theme.root__money }, money)));
};
export default SeatPrice;
