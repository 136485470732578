import { __read, __spreadArray } from "tslib";
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCombinedNumber, getOppositeDirection } from './utils';
import { addServiceAction, removeServiceAction } from '../Checkout/store/selectedServices/actions';
import { getExtraSeatServices } from '../Modules/Checkin/store/order/selectors';
import { findPassengerWithThisSeat } from './components/SeatMap/Seat/utils';
export var useDoubleSeat = function (activeDoubleSeats, segmentId, seats, passengers, onSelect) {
    var _a = __read(useState(null), 2), combineWith = _a[0], setCombineWith = _a[1];
    var _b = __read(useState(null), 2), prevSeatNumber = _b[0], setPrevSeatNumber = _b[1];
    var selectedDoubleSeatsBySegments = useMemo(function () {
        var doubleSeatsBySegments = new Map();
        passengers.forEach(function (passenger) {
            var _a;
            if (((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                passenger.seats.forEach(function (selectedSeat) {
                    var seatsNumbers = passenger.seats
                        .filter(function (seat) { return seat.segmentId === selectedSeat.segmentId; })
                        .map(function (seat) { return seat.number; });
                    if (seatsNumbers.length > 1) {
                        var existSegment_1 = doubleSeatsBySegments.get(selectedSeat.segmentId);
                        if (existSegment_1) {
                            seatsNumbers.map(function (number) { return existSegment_1.set(number, seatsNumbers); });
                            doubleSeatsBySegments.set(selectedSeat.segmentId, existSegment_1);
                        }
                        else {
                            doubleSeatsBySegments.set(selectedSeat.segmentId, new Map(seatsNumbers.map(function (number) { return [number, seatsNumbers]; })));
                        }
                    }
                });
            }
        });
        return doubleSeatsBySegments;
    }, [passengers]);
    var onSelectHandler = useCallback(function (seat, passengerId) {
        var _a, _b;
        var seatOccupiedBy = findPassengerWithThisSeat(seat, passengers, segmentId), seatOccupiedByPassengerId = seatOccupiedBy ? seatOccupiedBy.id : passengerId, doubleSeats = (_b = (_a = selectedDoubleSeatsBySegments === null || selectedDoubleSeatsBySegments === void 0 ? void 0 : selectedDoubleSeatsBySegments.get(segmentId)) === null || _a === void 0 ? void 0 : _a.get(seat.number)) !== null && _b !== void 0 ? _b : [];
        if (seatOccupiedByPassengerId !== passengerId) {
            onSelect((doubleSeats === null || doubleSeats === void 0 ? void 0 : doubleSeats.length) ? seats.filter(function (seat) { return doubleSeats.includes(seat.number); }) : [seat], seatOccupiedByPassengerId);
        }
        else if (activeDoubleSeats) {
            var combinedNumber_1 = getCombinedNumber(seat, combineWith !== null && combineWith !== void 0 ? combineWith : seat.combinedWith);
            var combinedSeat_1 = seats.find(function (seat) { return seat.number === combinedNumber_1; });
            if (combinedSeat_1) {
                onSelect([seat, combinedSeat_1], seatOccupiedByPassengerId);
            }
            else {
                onSelect([seat], seatOccupiedByPassengerId);
            }
        }
        else {
            onSelect([seat], seatOccupiedByPassengerId);
        }
    }, [seats, onSelect, activeDoubleSeats, combineWith, selectedDoubleSeatsBySegments]);
    var onMouseEnter = useCallback(function (seat, segmentId) {
        var _a, _b, _c;
        var _d, _e, _f, _g, _h, _j, _k;
        if (activeDoubleSeats && seat.combinedWith) {
            var _l = __read((_d = Object.entries(seat.combinedWith)) === null || _d === void 0 ? void 0 : _d[0], 2), firstCombineWithNumber = _l[0], firstCombineWithDirection = _l[1];
            var doubleSeats = (_e = selectedDoubleSeatsBySegments.get(segmentId)) === null || _e === void 0 ? void 0 : _e.get(seat.number);
            var prevDirection = (_f = seat.combinedWith) === null || _f === void 0 ? void 0 : _f[prevSeatNumber];
            if (doubleSeats === null || doubleSeats === void 0 ? void 0 : doubleSeats.length) {
                var combinedSeatNumber = (_g = doubleSeats === null || doubleSeats === void 0 ? void 0 : doubleSeats.filter(function (doubleSeat) { return doubleSeat !== seat.number; })) === null || _g === void 0 ? void 0 : _g[0];
                var combinedDirection = (_h = seat.combinedWith) === null || _h === void 0 ? void 0 : _h[combinedSeatNumber];
                setCombineWith((_a = {},
                    _a[seat.number] = getOppositeDirection(combinedDirection),
                    _a[combinedSeatNumber] = combinedDirection,
                    _a));
            }
            else if (prevSeatNumber &&
                seat.number !== prevSeatNumber &&
                prevDirection &&
                !((_j = selectedDoubleSeatsBySegments.get(segmentId)) === null || _j === void 0 ? void 0 : _j.has(firstCombineWithNumber))) {
                setCombineWith((_b = {},
                    _b[seat.number] = getOppositeDirection(prevDirection),
                    _b[prevSeatNumber] = prevDirection,
                    _b));
            }
            else if (!((_k = selectedDoubleSeatsBySegments.get(segmentId)) === null || _k === void 0 ? void 0 : _k.has(firstCombineWithNumber))) {
                setCombineWith((_c = {},
                    _c[seat.number] = getOppositeDirection(firstCombineWithDirection),
                    _c[firstCombineWithNumber] = firstCombineWithDirection,
                    _c));
            }
            setPrevSeatNumber(seat.number);
        }
    }, [activeDoubleSeats, combineWith, selectedDoubleSeatsBySegments]);
    var onMouseLeave = useCallback(function () { return setCombineWith(null); }, [combineWith]);
    var combinedSeat = useMemo(function () {
        var _a;
        if (combineWith) {
            var firstCombineWithNumber_1 = (_a = Object.keys(combineWith)) === null || _a === void 0 ? void 0 : _a[1];
            return seats.find(function (seat) { return seat.number === firstCombineWithNumber_1; });
        }
        return null;
    }, [seats, combineWith]);
    return {
        combineWith: combineWith,
        prevSeatNumber: prevSeatNumber,
        selectedDoubleSeatsBySegments: selectedDoubleSeatsBySegments,
        combinedSeat: combinedSeat,
        setCombineWith: setCombineWith,
        setPrevSeatNumber: setPrevSeatNumber,
        onSelectHandler: onSelectHandler,
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave
    };
};
export var useExtraSeat = function (segments, passengerId, segmentIndex) {
    var dispatch = useDispatch();
    var extraSeatServices = useSelector(getExtraSeatServices);
    var extraSeatService = useMemo(function () {
        var actualService = null;
        var servicesByPassenger = extraSeatServices.filter(function (service) {
            return new Set(service.allowedPassengers.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, [])).has(passengerId);
        });
        servicesByPassenger.forEach(function (service) {
            if (service.canBeAdded && (!actualService || service.id > actualService.id)) {
                actualService = service;
            }
        });
        return actualService;
    }, [extraSeatServices, passengerId]);
    var toggleExtraSeatService = useCallback(function (active, passengerId) {
        if (active && extraSeatService) {
            dispatch(addServiceAction({
                segmentId: segments[0].segmentInfo.id,
                segmentIds: segments.map(function (segment) { return segment.segmentInfo.id; }),
                allowedSegments: extraSeatService.allowedSegments,
                passengerId: passengerId,
                serviceId: extraSeatService.id,
                service: {
                    id: extraSeatService.id,
                    type: extraSeatService.type
                }
            }));
        }
        else if (!active && extraSeatService) {
            segments.forEach(function (segment) {
                dispatch(removeServiceAction({
                    segmentId: segment.segmentInfo.id,
                    passengerId: passengerId,
                    serviceId: extraSeatService.id,
                    service: {
                        id: extraSeatService.id,
                        type: extraSeatService.type
                    },
                    count: 0
                }));
            });
        }
    }, [extraSeatService, segments, dispatch]);
    var showDoubleSeats = useMemo(function () {
        var _a;
        var hasDoubleSeats = (_a = segments === null || segments === void 0 ? void 0 : segments[segmentIndex].decks) === null || _a === void 0 ? void 0 : _a.some(function (deck) {
            return deck.rows.some(function (row) {
                return row.seats.some(function (seat) { return seat.combinedWith && Object.keys(seat.combinedWith).length > 0; });
            });
        });
        return !!extraSeatService && hasDoubleSeats;
    }, [segments, segmentIndex, extraSeatService]);
    return {
        showDoubleSeats: showDoubleSeats,
        extraSeatService: extraSeatService,
        toggleExtraSeatService: toggleExtraSeatService
    };
};
