import * as React from 'react';
import { useContext, useMemo } from 'react';
import BaggageIcon from './BaggageIcon/BaggageIcon';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { OverrideComponent } from '../../../renderProps';
import { BaggageContext } from '../../context';
var Baggage = function (_a) {
    var _b;
    var _c;
    var baggage = _a.baggage, selected = _a.selected, _d = _a.realIconSize, realIconSize = _d === void 0 ? false : _d, onlyIcon = _a.onlyIcon, short = _a.short, iconClassName = _a.iconClassName;
    var css = useTheme('Baggage').Baggage;
    var baggageIconProps = useMemo(function () { return ({
        baggage: baggage,
        className: cn(css.icon, iconClassName),
        realSize: realIconSize,
        onlyIcon: onlyIcon
    }); }, [baggage, selected, onlyIcon, short, realIconSize]);
    var baggageIconClickHandler = (_c = useContext(BaggageContext)) === null || _c === void 0 ? void 0 : _c.onBaggageIconClick;
    return (React.createElement("div", { className: cn(css.root, (_b = {},
            _b[css.selected] = selected,
            _b)) },
        React.createElement("div", { className: baggageIconClickHandler ? css.iconWrapper_pointer : null, onClick: baggageIconClickHandler },
            React.createElement(OverrideComponent, { componentProps: baggageIconProps, component: { BaggageIcon: BaggageIcon } })),
        !short && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.name }, baggage.name),
            React.createElement("div", { className: css.size }, baggage.size)))));
};
export default Baggage;
