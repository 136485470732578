import { __assign, __read } from "tslib";
import { useMemo, useState } from 'react';
import { addDays, format, startOfWeek } from 'date-fns';
import { useFullFlightsScheduleLazyQuery, AviaPassengerType } from '@websky/graphql';
import { API_DATE_FORMAT } from '../utils';
import { useAvailableDates } from '../Modules/Exchange/components/Exchange/hooks';
export var useSchedule = function () {
    var _a = __read(useState(startOfWeek(new Date(), { weekStartsOn: 1 })), 2), startDate = _a[0], setStartDate = _a[1];
    var _b = __read(useFullFlightsScheduleLazyQuery({ fetchPolicy: 'no-cache' }), 2), getFlightsSchedule = _b[0], _c = _b[1], data = _c.data, loading = _c.loading;
    var getSchedule = function (parameters, date) {
        if (date === void 0) { date = startDate; }
        setStartDate(date);
        getFlightsSchedule({
            variables: {
                parameters: [parameters],
                period: {
                    startDate: format(date, API_DATE_FORMAT),
                    endDate: format(addDays(date, 6), API_DATE_FORMAT)
                },
                groupEqualFlights: true
            }
        });
    };
    var segmentParameters = useMemo(function () {
        var _a, _b;
        var segment = (_b = (_a = data === null || data === void 0 ? void 0 : data.FullFlightsSchedule) === null || _a === void 0 ? void 0 : _a.searchParameters.segments) === null || _b === void 0 ? void 0 : _b[0];
        if (segment) {
            return {
                departure: {
                    iata: segment.departure.iata
                },
                arrival: {
                    iata: segment.arrival.iata
                },
                date: format(startDate, API_DATE_FORMAT)
            };
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.FullFlightsSchedule, startDate]);
    var _d = useAvailableDates(segmentParameters), dates = _d.dates, isLoading = _d.isLoading;
    var defaultParameters = useMemo(function () {
        if (data === null || data === void 0 ? void 0 : data.FullFlightsSchedule.searchParameters) {
            return {
                passengers: [
                    {
                        passengerType: AviaPassengerType.ADT,
                        count: 1
                    }
                ],
                segments: data.FullFlightsSchedule.searchParameters.segments.map(function (segment) { return (__assign(__assign({}, segment), { date: format(startDate, API_DATE_FORMAT) })); })
            };
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.FullFlightsSchedule, startDate]);
    return [
        getSchedule,
        {
            schedule: data === null || data === void 0 ? void 0 : data.FullFlightsSchedule,
            defaultParameters: defaultParameters,
            availableDates: dates,
            isLoading: loading || isLoading
        }
    ];
};
