import * as React from 'react';
import { useContext } from 'react';
import { useTheme } from '../theme';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import Notification from '../Notification';
import { InfoMessagesContext } from './context';
import { filterMessageByPosition } from './utils';
var InfoMessages = function (props) {
    var _a, _b, _c;
    var theme = useTheme('InfoMessages').InfoMessages;
    var messagesFromContext = (_a = useContext(InfoMessagesContext)) === null || _a === void 0 ? void 0 : _a.messages;
    var messages = (_c = ((_b = props.messages) !== null && _b !== void 0 ? _b : messagesFromContext)) === null || _c === void 0 ? void 0 : _c.filter(function (message) { return filterMessageByPosition(props.position, message); }).filter(function (_a) {
        var code = _a.code;
        return code !== 'chamomile';
    });
    if (!(messages === null || messages === void 0 ? void 0 : messages.length)) {
        return null;
    }
    var renderNotification = function (message, index) {
        var _a;
        return (React.createElement(Notification, { renderMessagesWithoutWrapper: props.renderMessagesWithoutWrapper, key: index, title: message.title, image: message.image, status: message.type, infoMessage: message, type: 'context', className: cn(theme.notification, props.itemClassName, (_a = {},
                _a[props.itemHtmlClassName] = message.type === InfoMessageTypeEnum.HtmlContent,
                _a[theme.notification__html] = message.type === InfoMessageTypeEnum.HtmlContent,
                _a)), contentClassName: cn(theme.notification__content, props.contentClassName), htmlClassName: props.htmlClassName }));
    };
    if (props.renderMessagesWithoutWrapper) {
        return React.createElement(React.Fragment, null, messages.map(renderNotification));
    }
    return (React.createElement("div", { className: cn(theme.container, props.className) },
        React.createElement("div", { className: cn(theme.notifications, props.notificationsClassName) }, messages.map(renderNotification))));
};
export default InfoMessages;
