import { __extends } from "tslib";
import * as React from 'react';
import { captureErrorSentry } from '../utils';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            error: null
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return {
            error: error
        };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        captureErrorSentry("".concat(error === null || error === void 0 ? void 0 : error.name, ": ").concat(error === null || error === void 0 ? void 0 : error.message));
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.props, children = _a.children, renderFallback = _a.renderFallback;
        var error = this.state.error;
        if (error) {
            return renderFallback(error);
        }
        return children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
