import { __assign, __read } from "tslib";
import { isInfant } from '../../components/SearchForm/Passengers/utils';
export var isResetSelectedOnlyInfants = function (state, passengersConfig) {
    var isReset = true;
    Object.entries(state).forEach(function (_a) {
        var _b = __read(_a, 2), passengerType = _b[0], count = _b[1];
        var passengerConfig = passengersConfig.find(function (config) { return config.code === passengerType; });
        if ((isInfant(passengerConfig) && count > 0 && count <= (passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.maxCountWithoutAdult)) ||
            (!isInfant(passengerConfig) && count > 0)) {
            isReset = false;
        }
    });
    return isReset;
};
export var getPassengerCountByType = function (passengers, searchingType) {
    return Object.entries(passengers).reduce(function (sum, _a) {
        var _b = __read(_a, 2), type = _b[0], count = _b[1];
        return (type === searchingType ? sum + (count || 0) : sum);
    }, 0);
};
export var setPassengerCountLessOrEqualAdt = function (state, adultCount, passengersConfig, passengerCode) {
    var newState = __assign({}, state);
    var passengerConfig = passengersConfig.find(function (config) { return config.code === passengerCode || config.parentCode === passengerCode; });
    var currentCount = getPassengerCountByType(newState, passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.code);
    if (newState[passengerCode] || newState[passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.code]) {
        var passengerPath = newState[passengerCode] ? passengerCode : passengerConfig === null || passengerConfig === void 0 ? void 0 : passengerConfig.code;
        newState[passengerPath] = adultCount <= currentCount ? adultCount : currentCount;
    }
    return newState;
};
