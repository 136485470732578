import { __read, __spreadArray } from "tslib";
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrderStatus } from '@websky/graphql';
import { Mode } from '../../types';
import { useIataCode, useToggleable } from '../../hooks';
import { ModeContext } from '../../context';
import { getIsFareLockSelected, getOrder } from '../../Checkout/store/order/selectors';
import { getCheckinOrder } from '../../Modules/Checkin/store/order/selectors';
import { useConsentsCheckbox } from './Checkbox/Checkbox.hook';
import { isFlightFareM2 } from '../../utils';
var UNAVAILABLE_IATAS = ['A9', 'RM', 'DV', 'JI', '5G', 'SZ', '2S', 'WZ'];
var SOGAZ_INSURANCE_CODES = ['gen_trans_sogaz_avia_rw'];
export var useConsents = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var t = useTranslation('Consents').t;
    var _k = useToggleable(false), isFareModalOpen = _k.isOpen, toggleFareModal = _k.toggle, closeFareModal = _k.close;
    var mode = useContext(ModeContext);
    var iataCode = useIataCode();
    var order;
    var isFareLockSelected = false;
    var isIncludeTransferTariff = false;
    if (mode === Mode.Checkin) {
        order = useSelector(getCheckinOrder);
    }
    else {
        order = useSelector(getOrder);
        isFareLockSelected = useSelector(getIsFareLockSelected);
        isIncludeTransferTariff = (_a = order.flight.fares) === null || _a === void 0 ? void 0 : _a.some(function (fare) {
            return isFlightFareM2(fare);
        });
    }
    var services = order.priceToPay;
    var isNeedToShowGeneralRules = ((_b = services.total) === null || _b === void 0 ? void 0 : _b.amount) > 0;
    var isNeedToShowInsurancesRules = ((_c = services.insurance) === null || _c === void 0 ? void 0 : _c.amount) > 0;
    var insurancesProductsLinks = useMemo(function () {
        var _a, _b, _c;
        if (!((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length)) {
            return '';
        }
        var linksMap = new Map();
        order.additionalServices.insurances.selectedInsurances.forEach(function (insurance) {
            var _a;
            var code = insurance.insuranceProgram.code;
            if (!linksMap.has(code)) {
                var insuranceName = t('agreement_additionalInsuranceName', {
                    name: insurance.insuranceProgram.name
                });
                var formatInsurance = t('agreement_additionalInsuranceRules', {
                    link: "<a href='".concat((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.urlToOffer, "' target=\"_blank\">").concat(insuranceName, "</a>")
                });
                linksMap.set(code, "<span>".concat(formatInsurance, "</span>"));
            }
        });
        return __spreadArray([], __read(linksMap.values()), false).join('');
    }, [(_e = (_d = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _d === void 0 ? void 0 : _d.insurances) === null || _e === void 0 ? void 0 : _e.selectedInsurances]);
    var isAlphaInsuranceSelected = (_g = (_f = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _f === void 0 ? void 0 : _f.insurances) === null || _g === void 0 ? void 0 : _g.selectedInsurances.some(function (insurance) { var _a; return !SOGAZ_INSURANCE_CODES.includes((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code); }), isSogazInsuranceSelected = (_j = (_h = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _h === void 0 ? void 0 : _h.insurances) === null || _j === void 0 ? void 0 : _j.selectedInsurances.some(function (insurance) { var _a; return SOGAZ_INSURANCE_CODES.includes((_a = insurance.insuranceProgram) === null || _a === void 0 ? void 0 : _a.code); });
    var isNeedToShowGdsRules = [
        services === null || services === void 0 ? void 0 : services.vip,
        services === null || services === void 0 ? void 0 : services.businessLounge,
        services === null || services === void 0 ? void 0 : services.seats,
        services === null || services === void 0 ? void 0 : services.meals,
        services === null || services === void 0 ? void 0 : services.baggage
    ].some(function (price) { return (price === null || price === void 0 ? void 0 : price.amount) > 0; });
    var interpolatedRulesLink = t('agreement_interpolation', {
        generalRules: isNeedToShowGeneralRules ? t('agreement_generalRules') : '',
        insurancesRules: isNeedToShowInsurancesRules
            ? t('agreement_insurancesRules', {
                insurancesProductsLinks: insurancesProductsLinks,
                // will be ignored unless custom translate contains aliases below (WZ theme)
                alphaGeneralRules: isAlphaInsuranceSelected ? t('agreement_insurancesRules_alpha') : '',
                sogazGeneralRules: isSogazInsuranceSelected ? t('agreement_insurancesRules_sogaz') : ''
            })
            : '',
        additionalServiceRules: isNeedToShowGdsRules ? t('agreement_gdsRules') : ''
    });
    var rules = interpolatedRulesLink ? t('agreement_rules', { links: interpolatedRulesLink }) : '';
    var transferRules = t('agreement_transferRules', { links: t('agreement_transferLink') });
    var isNeedToShowRules = useMemo(function () {
        var availableOrderStatus = order.status !== OrderStatus.Cancelled;
        var availableByIata = !UNAVAILABLE_IATAS.includes(iataCode);
        return availableOrderStatus && availableByIata && !!rules;
    }, [iataCode, rules, order.status]);
    var isNeedToShowTransferRules = useMemo(function () {
        return order.status === OrderStatus.Booked && isIncludeTransferTariff && !!transferRules;
    }, [transferRules, isIncludeTransferTariff, order.status]);
    var commonConsentsCheckbox = useConsentsCheckbox({ needConfirm: isNeedToShowRules && !isFareLockSelected });
    var transferConsentsCheckbox = useConsentsCheckbox({ needConfirm: isIncludeTransferTariff });
    var onClick = function (e) {
        var target = e.target;
        if (target.hasAttribute('data-type')) {
            e.preventDefault();
            var linkType = target.getAttribute('data-type');
            if (linkType === 'termsOfTariff') {
                toggleFareModal();
            }
        }
    };
    var onConfirm = useCallback(function () {
        if (commonConsentsCheckbox.isNeedConfirm) {
            commonConsentsCheckbox.onConfirm();
        }
        if (transferConsentsCheckbox.isNeedConfirm) {
            transferConsentsCheckbox.onConfirm();
        }
    }, [
        commonConsentsCheckbox.isNeedConfirm,
        commonConsentsCheckbox.onConfirm,
        transferConsentsCheckbox.isNeedConfirm,
        transferConsentsCheckbox.onConfirm
    ]);
    return {
        // data
        order: order,
        rules: rules,
        transferRules: transferRules,
        isNeedToShowRules: isNeedToShowRules,
        isNeedToShowTransferRules: isNeedToShowTransferRules,
        isFareModalOpen: isFareModalOpen,
        // checkboxes
        commonConsentsCheckbox: commonConsentsCheckbox,
        transferConsentsCheckbox: transferConsentsCheckbox,
        // methods
        onClick: onClick,
        onConfirm: commonConsentsCheckbox.isNeedConfirm || transferConsentsCheckbox.isNeedConfirm ? onConfirm : null,
        toggleFareModal: toggleFareModal,
        closeFareModal: closeFareModal
    };
};
