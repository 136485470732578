import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import PaymentForm from '../../../../../PaymentForm';
import { format } from '../../../../../utils';
import { useTheme } from '../../../../../theme';
import { PAYMENT_KEY_TO_TYPE_MAP } from '../../../../../Checkout/components/Checkout/routes/Payment';
import { OverrideComponent } from '../../../../../renderProps';
var errorIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z", fill: "#E02222" })));
var Payment = function (_a) {
    var className = _a.className, exchangeOrder = _a.exchangeOrder, goToOrder = _a.goToOrder, successPayment = _a.successPayment, failedPayment = _a.failedPayment, paymentMethods = _a.paymentMethods;
    var priceParts = React.useMemo(function () {
        return {
            total: exchangeOrder.prices.priceToPay,
            charge: exchangeOrder.prices.agencyChargesForExare
        };
    }, [exchangeOrder.prices]);
    var t = useTranslation('Exchange').t;
    var theme = useTheme('Exchange').Payment;
    var timelimitDate = new Date(Date.parse(exchangeOrder.timelimit));
    var paymentPage = React.useMemo(function () {
        var existsGateways = paymentMethods
            ? Object.keys(paymentMethods).filter(function (key) { return paymentMethods[key] !== null && PAYMENT_KEY_TO_TYPE_MAP.has(key); })
            : [];
        if (existsGateways.length === 1) {
            return {
                type: PAYMENT_KEY_TO_TYPE_MAP.get(existsGateways[0]),
                content: null,
                title: ''
            };
        }
        else {
            return null;
        }
    }, [paymentMethods]);
    return (React.createElement("div", { className: cn(className, theme.container) },
        React.createElement("div", { className: theme.timelimit },
            errorIcon,
            t('Order must be paid by {{time}} on {{date}}', {
                time: format(timelimitDate, 'HH:mm'),
                date: format(timelimitDate, 'd MMMM yyyy')
            })),
        React.createElement(OverrideComponent, { componentProps: __assign(__assign({}, paymentMethods), { priceToPay: priceParts, onSuccess: successPayment, onFail: failedPayment, paymentPage: paymentPage, isPaymentAvailable: !!paymentMethods, onBackToOrder: goToOrder, onPaymentMethodSelected: function () { return null; } }), component: { ExchangePaymentForm: PaymentForm } })));
};
export default Payment;
