import { createSelector } from 'reselect';
import { ExareStatus, TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../../../../utils';
export var getOrder = function (state) { return state.order; };
export var getPassengers = createSelector([getOrder], function (order) { return (order ? order.travellers : []); });
export var getSegments = createSelector([getOrder], function (order) { return ((order === null || order === void 0 ? void 0 : order.flight) ? order.flight.segmentGroups : []); });
export var getLastExchange = createSelector([getOrder], function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange; });
export var isOpenedExchange = createSelector([getLastExchange], function (order) {
    return (order &&
        order.status !== ExareStatus.Refused &&
        order.status !== ExareStatus.Rejected &&
        order.status !== ExareStatus.Finished);
});
export var isAutoMode = createSelector([getOrder], function (order) {
    return order === null || order === void 0 ? void 0 : order.exareInfo.exchangeAutoMode;
});
export var getCustomerData = createSelector([getOrder], function (order) {
    var customer = order === null || order === void 0 ? void 0 : order.customer;
    if (!customer.values) {
        return null;
    }
    var firstName = getUserValue(customer, TravellerFieldEnum.FirstName), lastName = getUserValue(customer, TravellerFieldEnum.LastName);
    return {
        name: firstName ? "".concat(lastName, " ").concat(firstName) : '',
        phone: getUserValue(customer, TravellerFieldEnum.Phone),
        email: getUserValue(customer, TravellerFieldEnum.Email)
    };
});
