var isSelectedItems = function (values) {
    return Object.values(values !== null && values !== void 0 ? values : {}).some(function (isChecked) { return isChecked; });
};
export var validateDisabilities = function (passengerValues) {
    if (typeof passengerValues['disabilities'] !== 'object') {
        return null;
    }
    var hasSelectedItems = isSelectedItems(passengerValues['disabilities']['values']);
    if (passengerValues['disabilities']['enabled'] && !hasSelectedItems) {
        return 'Select at least one service from the list below';
    }
    return null;
};
