import { __read } from "tslib";
export var getPassengerAbbreviation = function (name) {
    var _a = __read(name.split(' '), 2), lastName = _a[0], firstName = _a[1];
    var result = '';
    if (lastName) {
        result += lastName[0];
    }
    if (firstName) {
        result += firstName[0];
    }
    return result;
};
