import { __extends, __read, __spreadArray } from "tslib";
import WebskyServicesAnalytics from '../WebskyServicesAnalytics';
import Base from './Base';
var Aeroexpress = /** @class */ (function (_super) {
    __extends(Aeroexpress, _super);
    function Aeroexpress(orderBeforeChange, orderAfterChange) {
        return _super.call(this, orderBeforeChange, orderAfterChange) || this;
    }
    Aeroexpress.prototype.diff = function () {
        var _a, _b, _c, _d;
        var added = [];
        var removed = [];
        var _e = this.getTravellersServicesDiff((_b = (_a = this.orderBeforeChange.additionalServices.transfer) === null || _a === void 0 ? void 0 : _a.aeroexpress) === null || _b === void 0 ? void 0 : _b.selectedTrips, (_d = (_c = this.orderAfterChange.additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.selectedTrips, function (service) { return service.id; }), aeroexpressAdded = _e.added, aeroexpressRemoved = _e.removed;
        added.push.apply(added, __spreadArray([], __read(WebskyServicesAnalytics.aeroexpress(aeroexpressAdded, this.orderAfterChange.travellers)), false));
        removed.push.apply(removed, __spreadArray([], __read(WebskyServicesAnalytics.aeroexpress(aeroexpressRemoved, this.orderAfterChange.travellers)), false));
        return { added: added, removed: removed };
    };
    return Aeroexpress;
}(Base));
export default Aeroexpress;
