import { __assign } from "tslib";
import * as React from 'react';
import OrderCard from '../../../OrderCard';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
var NextFlight = function (props) {
    var t = useTranslation('Account').t;
    var css = useTheme('Account').NextFlight;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.title }, t('Next flights')),
        React.createElement(OrderCard, __assign({ type: 'account' }, props))));
};
export default NextFlight;
