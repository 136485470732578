import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CartService } from '../../types';
import { Aeroexpress as AeroexpressIcon } from '../../../Icons';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { getAeroexpressTripLabel } from '../../../CheckoutAdditionalService/utils';
var Aeroexpress = function (_a) {
    var services = _a.services, onServiceDelete = _a.onServiceDelete;
    var t = useTranslation('Cart').t;
    var onDeleteHandler = function (tripId) {
        onServiceDelete(CartService.Aeroexpress, {
            tripId: tripId
        });
    };
    var items = React.useMemo(function () {
        var _a, _b, _c;
        if ((_c = (_b = (_a = services.transfer) === null || _a === void 0 ? void 0 : _a.aeroexpress) === null || _b === void 0 ? void 0 : _b.selectedTrips) === null || _c === void 0 ? void 0 : _c.length) {
            return services.transfer.aeroexpress.selectedTrips.map(function (trip) {
                var label = "".concat(getAeroexpressTripLabel(trip), ", ").concat(t("Checkout:Class_".concat(trip.class)));
                return {
                    id: trip.id,
                    name: label,
                    price: trip.price,
                    onDelete: function () { return onDeleteHandler(trip.id); }
                };
            });
        }
        return [];
    }, [services]);
    if (!items.length) {
        return null;
    }
    return (React.createElement(ServiceSummaryDivider, null,
        React.createElement(ServiceSummaryOption, { groupOption: {
                header: t('Aeroexpress'),
                headerIcon: AeroexpressIcon,
                items: items
            } })));
};
export default Aeroexpress;
