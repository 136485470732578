import { __awaiter, __generator, __read } from "tslib";
import { useEffect, useState } from 'react';
import { useCreateOrderMutation } from '@websky/graphql';
export var useCreateOrder = function (params) {
    var _a = __read(useState(null), 2), order = _a[0], setOrder = _a[1];
    var _b = __read(useState(null), 2), orderError = _b[0], setOrderError = _b[1];
    var _c = __read(useCreateOrderMutation(), 2), createOrder = _c[0], loading = _c[1].loading;
    var loadOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createOrder({
                            variables: {
                                params: params
                            }
                        })];
                case 1:
                    data = _a.sent();
                    if (data.errors && data.errors.length) {
                        setOrderError(data.errors[0]);
                    }
                    else if (data && data.data && data.data.CreateOrder) {
                        setOrder(data.data.CreateOrder);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setOrderError(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        loadOrder();
    }, [params]);
    return { loading: loading, order: order, orderError: orderError };
};
