import * as React from 'react';
import { useCallback, useContext } from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { format, MOBILE_MIN_WIDTH } from '../../../../../../../utils';
import { DatepickerTab } from '../Datepicker';
import { useTheme } from '../../../../../../../theme';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../../../../../context';
import { tryParseDate } from './utils';
import ManualInput from './ManualInput/ManualInput';
import { CalendarIcon, CloseClear } from '../../../../../../../Icons';
var Value = function (_a) {
    var _b, _c, _d;
    var className = _a.className, isOpen = _a.isOpen, open = _a.open, close = _a.close, setRouteType = _a.setRouteType, segmentId = _a.segmentId, withDateBack = _a.withDateBack, routeType = _a.routeType, dateBack = _a.dateBack, dateTo = _a.dateTo, onClear = _a.onClear, onDateSelect = _a.onDateSelect, setIsRouteTypeSetManually = _a.setIsRouteTypeSetManually, onClickDatepickerTabReturn = _a.onClickDatepickerTabReturn, onClickDatepickerTabOutbound = _a.onClickDatepickerTabOutbound;
    var css = useTheme('SearchForm').Datepicker;
    var t = useTranslation('SearchForm').t;
    var alternative = useContext(ConfigContext).SearchForm.mobileFormType !== 'common';
    var proMode = React.useContext(ConfigContext).SearchForm.proMode;
    var onManualDateEnter = function (value) {
        var parseDate = tryParseDate(value);
        if (parseDate) {
            onDateSelect(parseDate, routeType === DatepickerTab.Return);
        }
    };
    var handleRouteTypeChange = useCallback(function (e, routeType) {
        if (isOpen) {
            e.preventDefault();
            e.stopPropagation();
            setRouteType(routeType);
            setIsRouteTypeSetManually(true);
        }
        if (routeType === DatepickerTab.Return && onClickDatepickerTabReturn) {
            onClickDatepickerTabReturn();
        }
        if (routeType === DatepickerTab.Outbound && onClickDatepickerTabOutbound) {
            onClickDatepickerTabOutbound();
        }
    }, [routeType, isOpen, dateTo, dateBack]);
    return (React.createElement("div", { className: cn(className, css.dates, "".concat(css.dates, "_").concat(segmentId), (_b = {},
            _b[css.oneDate] = !withDateBack,
            _b[css.dates_focused] = isOpen,
            _b[css.alternative] = alternative,
            _b)), onClick: function () {
            setRouteType(DatepickerTab.Outbound);
            isOpen ? close() : open();
        } },
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement("div", { className: cn(css.dates__to, (_c = {},
                    _c[css.dates__to_focused] = isOpen && routeType === DatepickerTab.Outbound,
                    _c)) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Outbound, inputClassName: css.date__input },
                    React.createElement("span", { className: css.dates__icon }, CalendarIcon),
                    dateTo && (React.createElement("div", { className: css.date__inputWrapper, onClick: function (e) {
                            handleRouteTypeChange(e, DatepickerTab.Outbound);
                        } },
                        format(dateTo, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, dateBack || isOpen ? format(dateTo, 'eeeeee') : format(dateTo, 'eeee')))),
                    !dateTo && isOpen && (React.createElement("span", { onClick: function (e) { return handleRouteTypeChange(e, DatepickerTab.Outbound); } }, t('Outbound'))),
                    !dateTo && !dateBack && !isOpen && React.createElement("span", { className: css.placeholder }, t('Dates')))),
            withDateBack && (isOpen || dateBack) && (React.createElement("div", { className: cn(css.dates__back, (_d = {},
                    _d[css.dates__back_focused] = isOpen && routeType === DatepickerTab.Return,
                    _d)) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Return, inputClassName: cn(css.date__input, css.date__input_back) }, dateBack ? (React.createElement("div", { onClick: function (e) {
                        handleRouteTypeChange(e, DatepickerTab.Return);
                    } },
                    format(dateBack, 'dd MMM'),
                    React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee')))) : (React.createElement("span", { onClick: function (e) { return handleRouteTypeChange(e, DatepickerTab.Return); } }, t('Return')))))),
            (dateTo || dateBack) && isOpen && (React.createElement("div", { className: css.returnDelete, onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    onClear();
                } }, CloseClear))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(css.dates__to) },
                React.createElement("span", { className: css.dates__to_text },
                    dateTo && (React.createElement("span", { className: css.dates__to_text },
                        format(dateTo, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, format(dateTo, 'eeeeee')))),
                    dateBack && (React.createElement("span", { className: css.dates__to_text }, " \u2014 ".concat(format(dateBack, 'dd MMM')),
                        React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee'))))),
                alternative && dateTo && (React.createElement("div", { className: css.alternative_dow },
                    React.createElement("span", null, format(dateTo, 'eeee')),
                    dateBack && React.createElement("span", null, " \u2014 ".concat(format(dateBack, 'eeee'))))),
                !dateTo &&
                    (isOpen ? (t('Outbound')) : (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: css.dates__icon }, CalendarIcon),
                        React.createElement("span", { className: css.placeholder }, t('Dates')))))))));
};
export default Value;
