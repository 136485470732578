import * as React from 'react';
import { format } from '../../../../utils';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import ImageWithFallback from '../../../../ImageWithFallback';
import { OverrideComponent } from '../../../../renderProps';
import { useFlightIconFallbackSrc } from './hooks';
var FlightInfo = function (_a) {
    var _b;
    var _c, _d;
    var segment = _a.segment, singleFlight = _a.singleFlight, nextDay = _a.nextDay;
    var theme = useTheme('DesktopFlightRoute').SegmentStyles;
    var fallbackSrc = useFlightIconFallbackSrc();
    return (React.createElement("div", { className: cn(theme.flightInfo, (_b = {},
            _b[theme.flightInfo__singleFlight] = singleFlight,
            _b[theme.flightInfo__nextDay] = nextDay,
            _b)) },
        React.createElement("div", { className: theme.flightInfo__container },
            React.createElement("div", { className: cn(theme.flightInfo__icon) },
                React.createElement(OverrideComponent, { componentProps: {
                        src: (_c = segment.operatingAirline) === null || _c === void 0 ? void 0 : _c.icon,
                        fallbackSrc: fallbackSrc,
                        alt: (_d = segment.operatingAirline) === null || _d === void 0 ? void 0 : _d.name
                    }, component: {
                        renderFlightInfoIcon: ImageWithFallback
                    } })),
            React.createElement("div", { className: theme.flightInfo__date }, format(segment.departure.date, 'dd MMM, eeeeee').replace('.', ''))),
        React.createElement("div", { className: theme.flightInfo__airline }, segment.operatingAirline.name)));
};
export default FlightInfo;
