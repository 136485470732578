import { __values } from "tslib";
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { isCheckoutOrderGuard } from '../../../../typeguards';
export var initialStateFactory = function (order) {
    var e_1, _a, e_2, _b;
    var _c, _d, _e, _f, _g;
    var state = {
        activeService: null,
        services: {}
    };
    var transferServices = [];
    try {
        for (var _h = __values((_d = (_c = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services), _j = _h.next(); !_j.done; _j = _h.next()) {
            var service = _j.value;
            if (service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
                service.subType ===
                    GdsServiceVipSubType.GroundTransportation) {
                transferServices.push(service);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_j && !_j.done && (_a = _h.return)) _a.call(_h);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (isCheckoutOrderGuard(order)) {
        var _loop_1 = function (transferService) {
            var e_3, _k, e_4, _l, e_5, _m, e_6, _o;
            try {
                for (var _p = (e_3 = void 0, __values(transferService.allowedSegments)), _q = _p.next(); !_q.done; _q = _p.next()) {
                    var serviceSegmentGroup = _q.value;
                    try {
                        for (var serviceSegmentGroup_1 = (e_4 = void 0, __values(serviceSegmentGroup)), serviceSegmentGroup_1_1 = serviceSegmentGroup_1.next(); !serviceSegmentGroup_1_1.done; serviceSegmentGroup_1_1 = serviceSegmentGroup_1.next()) {
                            var segmentId = serviceSegmentGroup_1_1.value;
                            try {
                                for (var _r = (e_5 = void 0, __values(transferService.allowedPassengers)), _s = _r.next(); !_s.done; _s = _r.next()) {
                                    var allowedPassengerGroup = _s.value;
                                    var _loop_2 = function (allowedPassenger) {
                                        var traveller = order.travellers.find(function (traveller) { return traveller.id === allowedPassenger; });
                                        var travellerTransferService = (_g = (_f = (_e = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _e === void 0 ? void 0 : _e.gdsServices) === null || _f === void 0 ? void 0 : _f.services) === null || _g === void 0 ? void 0 : _g.find(function (travellerService) { return travellerService.serviceId === transferService.id; });
                                        if (!state.services[allowedPassenger]) {
                                            state.services[allowedPassenger] = {};
                                        }
                                        if (!state.services[allowedPassenger][segmentId]) {
                                            state.services[allowedPassenger][segmentId] = [
                                                {
                                                    service: transferService,
                                                    selected: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.count) > 0,
                                                    confirmed: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.confirmedCount) > 0
                                                }
                                            ];
                                        }
                                        else {
                                            state.services[allowedPassenger][segmentId].push({
                                                service: transferService,
                                                selected: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.count) > 0,
                                                confirmed: (travellerTransferService === null || travellerTransferService === void 0 ? void 0 : travellerTransferService.confirmedCount) > 0
                                            });
                                        }
                                    };
                                    try {
                                        for (var allowedPassengerGroup_1 = (e_6 = void 0, __values(allowedPassengerGroup)), allowedPassengerGroup_1_1 = allowedPassengerGroup_1.next(); !allowedPassengerGroup_1_1.done; allowedPassengerGroup_1_1 = allowedPassengerGroup_1.next()) {
                                            var allowedPassenger = allowedPassengerGroup_1_1.value;
                                            _loop_2(allowedPassenger);
                                        }
                                    }
                                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                                    finally {
                                        try {
                                            if (allowedPassengerGroup_1_1 && !allowedPassengerGroup_1_1.done && (_o = allowedPassengerGroup_1.return)) _o.call(allowedPassengerGroup_1);
                                        }
                                        finally { if (e_6) throw e_6.error; }
                                    }
                                }
                            }
                            catch (e_5_1) { e_5 = { error: e_5_1 }; }
                            finally {
                                try {
                                    if (_s && !_s.done && (_m = _r.return)) _m.call(_r);
                                }
                                finally { if (e_5) throw e_5.error; }
                            }
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (serviceSegmentGroup_1_1 && !serviceSegmentGroup_1_1.done && (_l = serviceSegmentGroup_1.return)) _l.call(serviceSegmentGroup_1);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_q && !_q.done && (_k = _p.return)) _k.call(_p);
                }
                finally { if (e_3) throw e_3.error; }
            }
        };
        try {
            for (var transferServices_1 = __values(transferServices), transferServices_1_1 = transferServices_1.next(); !transferServices_1_1.done; transferServices_1_1 = transferServices_1.next()) {
                var transferService = transferServices_1_1.value;
                _loop_1(transferService);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (transferServices_1_1 && !transferServices_1_1.done && (_b = transferServices_1.return)) _b.call(transferServices_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    else {
    }
    return state;
};
