import { __read } from "tslib";
import * as React from 'react';
import { useGetExchangeVariantsMutation } from '@websky/graphql';
// adapter for flights list for manual exchange mode
export var getNewFlightsForManualMode = function (exchangeParams, setSearchParams, clearFare, onError) {
    var _a = __read(React.useState(0), 2), currentLeg = _a[0], setCurrentLeg = _a[1];
    var _b = __read(useGetExchangeVariantsMutation(), 2), runSearch = _b[0], _c = _b[1], loading = _c.loading, data = _c.data, error = _c.error;
    var runNewSearch = function () {
        clearFares();
        return runSearch({
            variables: {
                params: exchangeParams
            }
        });
    };
    React.useEffect(function () {
        runNewSearch().catch(onError);
    }, [exchangeParams]);
    var startNewSearch = function (params) {
        setSearchParams(params);
    };
    var clearFares = function () {
        clearFare();
        setCurrentLeg(0);
    };
    return {
        loading: loading,
        results: data,
        error: error,
        currentLeg: currentLeg,
        setLeg: setCurrentLeg,
        startNewSearch: startNewSearch,
        clearFares: clearFares
    };
};
