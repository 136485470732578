import { __read } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getIsConfirmed, getOrder } from '../../../store/order/selectors';
import { getPassengerName } from '../../../../Modules/Exchange/components/Exchange/utils';
import OrderRefundStatus from '../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import ExchangeCheckoutStatus from '../../../../Modules/Exchange/components/Exchange/ExchangeCheckoutStatus/ExchangeCheckoutStatus';
import ParentOrderStatus from '../ParentOrderStatus/ParentOrderStatus';
import ChangePaxOrderStatus from '../ChangePaxOrderStatus/ChangePaxOrderStatus';
var OrderExareStatus = function (_a) {
    var _b, _c, _d;
    var goToRefund = _a.goToRefund, goToExchange = _a.goToExchange, goToChangePassenger = _a.goToChangePassenger;
    var order = useSelector(getOrder);
    var isConfirmed = useSelector(getIsConfirmed);
    var showRefundStatus = isConfirmed && !!order.exareInfo.lastRefund;
    var showExchangeStatus = isConfirmed && !!order.exareInfo.lastExchange;
    var splittedOrderId = React.useMemo(function () {
        var _a;
        if ((_a = order === null || order === void 0 ? void 0 : order.splittedOrdersIds) === null || _a === void 0 ? void 0 : _a.length) {
            return order.splittedOrdersIds[order.splittedOrdersIds.length - 1];
        }
        return null;
    }, [order === null || order === void 0 ? void 0 : order.splittedOrdersIds]);
    var _e = __read(React.useMemo(function () {
        var _a, _b, _c, _d;
        var refundNames;
        var exchangeNames;
        if (order === null || order === void 0 ? void 0 : order.exareInfo) {
            if ((_b = (_a = order.exareInfo.lastRefund) === null || _a === void 0 ? void 0 : _a.travellers) === null || _b === void 0 ? void 0 : _b.length) {
                refundNames = order.exareInfo.lastRefund.travellers.map(getPassengerName).join(', ');
            }
            if ((_d = (_c = order.exareInfo.lastExchange) === null || _c === void 0 ? void 0 : _c.travellers) === null || _d === void 0 ? void 0 : _d.length) {
                exchangeNames = order.exareInfo.lastExchange.travellers
                    .map(function (passenger) { return getPassengerName(passenger); })
                    .join(', ');
            }
        }
        return [refundNames, exchangeNames];
    }, [order === null || order === void 0 ? void 0 : order.exareInfo]), 2), refundPassengerNames = _e[0], exchangePassengerNames = _e[1];
    var goToChangePassengerHandler = function () {
        var _a, _b;
        if ((_b = (_a = order.exareInfo.lastChangeTravellerDataOrder) === null || _a === void 0 ? void 0 : _a.travellers) === null || _b === void 0 ? void 0 : _b.length) {
            goToChangePassenger(order.exareInfo.lastChangeTravellerDataOrder.travellers[0].id);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OrderRefundStatus, { show: showRefundStatus, splittedOrderId: splittedOrderId, status: (_b = order.exareInfo.lastRefund) === null || _b === void 0 ? void 0 : _b.status, onGoToTicket: goToRefund, passengersNames: refundPassengerNames }),
        React.createElement(ExchangeCheckoutStatus, { show: showExchangeStatus, splittedOrderId: splittedOrderId, status: (_c = order.exareInfo.lastExchange) === null || _c === void 0 ? void 0 : _c.status, passengersNames: exchangePassengerNames, onGoToTicket: goToExchange }),
        React.createElement(ParentOrderStatus, { show: !!order.parentOrderId, parentOrderId: order.parentOrderId }),
        ((_d = order.exareInfo) === null || _d === void 0 ? void 0 : _d.lastChangeTravellerDataOrder) && (React.createElement(ChangePaxOrderStatus, { order: order, lastChangePaxOrder: order.exareInfo.lastChangeTravellerDataOrder, onGoToTicket: goToChangePassengerHandler }))));
};
export default OrderExareStatus;
