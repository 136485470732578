import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import AddPassengerWrapper from './AddPassengerWrapper';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Add } from '../../../../../Icons';
var Passenger = function (props) {
    var _a;
    var theme = useTheme('Checkout').GroupOrder;
    var t = useTranslation('Checkout').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var onChangeHandler = function () {
        props.onSelect(props.passenger, !props.passenger.selected);
    };
    var renderLabel = function () {
        return (React.createElement(AddPassengerWrapper, { passenger: props.passenger, onAddPassenger: props.onAddPassenger },
            React.createElement("div", null, props.passenger.passengerName),
            props.passenger.error && (React.createElement("span", { className: theme.errorHint }, t('Passenger not found. Please, check passenger last name and ticket number')))));
    };
    if (props.type === 'add') {
        return (React.createElement("div", { className: cn(theme.passenger, theme.addPassenger) },
            React.createElement(AddPassengerWrapper, { onAddPassenger: props.onAddPassenger, isOpen: props.isOpen }, isMobile ? (React.createElement(ButtonBase, { className: theme.addButton },
                t('Add passenger'),
                " ",
                Add)) : (React.createElement("label", { className: theme.add },
                React.createElement("span", null, t('Add your travel companion to the order? Add ticket')),
                React.createElement("span", { className: theme.addIcon }, Add))))));
    }
    return (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { classes: {
                root: theme.checkbox,
                colorSecondary: theme.checkboxColor,
                checked: theme.checked
            } }), onChange: onChangeHandler, className: cn(theme.passenger, (_a = {},
            _a[theme.error] = props.passenger.error,
            _a)), checked: props.passenger.selected, label: renderLabel(), classes: {
            label: theme.passenger__label
        } }));
};
export default Passenger;
