import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LoginType, useAccountExistenceQuery, useGetUserSettingsQuery } from '@websky/graphql';
import { UserSettings } from './types';
import { getUserSettingsByName } from './utils';
import { AUTH_DUPLICATES, removeFromSessionStorage, sessionStorageGet } from '../cache';
import { Log } from '../sentry';
export var useUserSettings = function (options) {
    var _a = useGetUserSettingsQuery(options), data = _a.data, loading = _a.loading, refetch = _a.refetch;
    return {
        userSettings: {
            travellerId: getUserSettingsByName(data === null || data === void 0 ? void 0 : data.UserSettings, UserSettings.TravellerID),
            termOfUse: getUserSettingsByName(data === null || data === void 0 ? void 0 : data.UserSettings, UserSettings.TermOfUse),
            subscribed: getUserSettingsByName(data === null || data === void 0 ? void 0 : data.UserSettings, UserSettings.Subscribe) === 'true'
        },
        refetchUserSettings: refetch,
        loading: loading
    };
};
export var useEmailDuplication = function (accountInfo) {
    var emailIsChecked = useRef(false);
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var checkAccountExistence = useAccountExistenceQuery({ skip: true }).refetch;
    var authDuplicates = sessionStorageGet(AUTH_DUPLICATES);
    var _b = useMemo(function () {
        var _a, _b, _c;
        var authMethods = (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.authMethods) !== null && _a !== void 0 ? _a : [];
        return {
            emailMethod: authMethods.find(function (method) { return method.loginType === LoginType.Email; }),
            ffpMethod: authMethods.find(function (method) { return method.loginType === LoginType.FFP; }),
            ffpEmail: (_c = (_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.userFfpInfo) === null || _b === void 0 ? void 0 : _b.registerEmail) !== null && _c !== void 0 ? _c : null
        };
    }, [accountInfo]), emailMethod = _b.emailMethod, ffpMethod = _b.ffpMethod, ffpEmail = _b.ffpEmail;
    var isNeedConnectFfp = useMemo(function () {
        if (!authDuplicates || !authDuplicates.isExistDuplicate || isLoading) {
            return false;
        }
        return !ffpMethod || (ffpMethod && !ffpMethod.confirmed);
    }, [ffpMethod, authDuplicates, isLoading]);
    var isNeedConnectEmail = useMemo(function () {
        if (!authDuplicates || authDuplicates.isExistDuplicate || isLoading) {
            return false;
        }
        return !emailMethod || (emailMethod && !emailMethod.confirmed);
    }, [emailMethod, authDuplicates, isLoading]);
    var connectFfp = isNeedConnectFfp ? authDuplicates === null || authDuplicates === void 0 ? void 0 : authDuplicates.login : null;
    var connectEmail = isNeedConnectEmail ? ffpEmail : null;
    var onCheckEmailExistence = useCallback(function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, checkAccountExistence({
                            params: {
                                loginType: LoginType.Email,
                                login: email
                            }
                        })];
                case 1:
                    data = (_b.sent()).data;
                    if ((_a = data === null || data === void 0 ? void 0 : data.AccountExistence) === null || _a === void 0 ? void 0 : _a.result) {
                        removeFromSessionStorage(AUTH_DUPLICATES);
                    }
                    emailIsChecked.current = true;
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [emailIsChecked, checkAccountExistence]);
    var onConnectResult = useCallback(function (result) {
        var method = isNeedConnectFfp ? 'fpp card' : 'email';
        if (result) {
            Log({ level: 'info', message: "successful linking the ".concat(method) });
        }
        else {
            Log({ level: 'error', message: "unsuccessful linking the ".concat(method) });
        }
        removeFromSessionStorage(AUTH_DUPLICATES);
    }, [isNeedConnectFfp]);
    useEffect(function () {
        if (authDuplicates && (emailMethod === null || emailMethod === void 0 ? void 0 : emailMethod.confirmed) && (ffpMethod === null || ffpMethod === void 0 ? void 0 : ffpMethod.confirmed)) {
            removeFromSessionStorage(AUTH_DUPLICATES);
        }
        // Before opening the form to bind the email to the account, we send a request
        // to check if the email exists. If it is already used in another account,
        // we do not open the modal window (clear session storage).
        if (!emailIsChecked.current && !(authDuplicates === null || authDuplicates === void 0 ? void 0 : authDuplicates.isExistDuplicate) && isNeedConnectEmail && connectEmail) {
            onCheckEmailExistence(connectEmail);
        }
    }, [authDuplicates]);
    return {
        // data
        authDuplicates: authDuplicates,
        isLoading: isLoading,
        isNeedConnectFfp: isNeedConnectFfp,
        isNeedConnectEmail: isNeedConnectEmail,
        connectFfp: connectFfp,
        connectEmail: connectEmail,
        // methods
        onConnectResult: onConnectResult
    };
};
