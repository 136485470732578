import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertScheduleSearchParams } from '../../utils';
import { LocationType } from '../../../types';
import { setLocation, switchLocation } from '../../store/segments/actions';
import { getSegments } from '../../store/segments/selectors';
import ScheduleForm from '../../../ScheduleForm';
import Results from '../Results/Results';
var Schedule = function (_a) {
    var schedule = _a.schedule, _b = _a.availableDates, availableDates = _b === void 0 ? [] : _b, onStartSearch = _a.onStartSearch, onScheduleSearch = _a.onScheduleSearch;
    var dispatch = useDispatch();
    var segments = useSelector(getSegments);
    var onSubmitHandler = useCallback(function (parameters) { return onScheduleSearch(parameters, null, !!segments.date); }, [segments, onScheduleSearch]);
    var onStartSearchHandler = useCallback(function (date) { return onStartSearch(convertScheduleSearchParams(schedule.searchParameters, date), false); }, [onStartSearch, schedule === null || schedule === void 0 ? void 0 : schedule.searchParameters]);
    var onSelect = useCallback(function (locations) { return function (location, locationType) {
        var _a;
        var type = locationType || (segments.departure ? LocationType.Arrival : LocationType.Departure);
        dispatch(setLocation({ location: location, locationType: type }));
        if (locations && type === LocationType.Arrival && ((_a = segments.departure) === null || _a === void 0 ? void 0 : _a.iata)) {
            onScheduleSearch({
                departure: segments.departure.iata,
                arrival: location.iata
            });
        }
    }; }, [segments, onScheduleSearch]);
    var onClear = useCallback(function () { return function (locationType) {
        var type = locationType || (segments.departure ? LocationType.Arrival : LocationType.Departure);
        dispatch(setLocation({ location: null, locationType: type }));
    }; }, [segments, onScheduleSearch]);
    var onChangeDateHandler = useCallback(function (date) {
        onScheduleSearch({
            departure: segments.departure.iata,
            arrival: segments.arrival.iata
        }, date);
    }, [segments, onScheduleSearch]);
    var onSwitch = function () { return dispatch(switchLocation()); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ScheduleForm, { initialState: { segments: segments }, onSubmit: onSubmitHandler, onSelect: onSelect(), onSwitch: onSwitch, onClear: onClear() }),
        React.createElement(Results, { schedule: schedule, onStartSearch: onStartSearchHandler, availableDates: availableDates, onSelect: onSelect(true), onChangeDate: onChangeDateHandler })));
};
export default Schedule;
