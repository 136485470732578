import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MealPage from '../../../../../../MealPage';
import { getCheckinOrder, getCheckinOrderId, getMeals, getMealServices, getMealsPrice, getPassengersNames, getRecommendedMeals, getSegmentsForServices, getTotalPrice, hasBaggageServices, showMealRecommendations } from '../../../../store/order/selectors';
import { CheckinStep, useSteps } from '../../../../utils';
import { Money } from '../../../../../../Money';
import { useTheme } from '../../../../../../theme';
import { MEAL_RECOMMENDATION_SHOWN, set } from '../../../../../../cache';
import { setServices, addService, clearService, removeService, saveServices } from '../../../../store/selectedServices/actions';
import { getSelectedMeals, getSelectedServices } from '../../../../store/selectedServices/selectors';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import AvailableServicesCountContextProvider from '../../../../../../AdditionalServices/context';
import { useGetGoBack } from '../utils';
var Meal = function (_a) {
    var isLoading = _a.isLoading;
    var orderId = useSelector(getCheckinOrderId);
    var order = useSelector(getCheckinOrder);
    var selectedServices = useSelector(getSelectedServices);
    var totalPrice = useSelector(getTotalPrice);
    var mealsPriceToPay = useSelector(getMealsPrice);
    var originalMealServices = useSelector(getMealServices);
    var meals = useSelector(getMeals);
    var isBaggageServiceAvailable = useSelector(hasBaggageServices);
    var selectedMeals = useSelector(getSelectedMeals);
    var recommendedMeals = useSelector(getRecommendedMeals);
    var showRecommendations = useSelector(showMealRecommendations);
    var passengersNames = useSelector(getPassengersNames);
    var segments = useSelector(getSegmentsForServices);
    var dispatch = useDispatch();
    var _b = useSteps(), goToNextStep = _b.goToNextStep, previousStep = _b.previousStep;
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').Checkin;
    // Do not show recommendations twice.
    var cacheRecommendationState = function () { return set("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId), true); };
    var onMealClear = useCallback(function (meal, segmentId) {
        var service = originalMealServices.find(function (_a) {
            var id = _a.id;
            return id === meal.id;
        });
        passengersNames.forEach(function (_a) {
            var id = _a.id;
            dispatch(clearService({
                service: service,
                segmentId: segmentId,
                passengerId: id
            }));
        });
    }, [passengersNames, originalMealServices]);
    var onMealAdd = useCallback(function (meal, segmentId, passengerId, count) {
        if (count === void 0) { count = 1; }
        dispatch(addService({
            service: originalMealServices.find(function (_a) {
                var id = _a.id;
                return id === meal.id;
            }),
            segmentId: segmentId,
            passengerId: passengerId,
            count: count
        }));
    }, [originalMealServices]);
    var onMealRemove = useCallback(function (meal, segmentId, passengerId) {
        dispatch(removeService({
            service: originalMealServices.find(function (_a) {
                var id = _a.id;
                return id === meal.id;
            }),
            segmentId: segmentId,
            passengerId: passengerId
        }));
    }, [originalMealServices]);
    var onMeadAddForPassengers = useCallback(function (meal, segmentId, passengerIds) {
        var service = originalMealServices.find(function (_a) {
            var id = _a.id;
            return id === meal.id;
        });
        dispatch(setServices(passengerIds.map(function (passengerId) { return ({
            passengerId: passengerId,
            segmentId: segmentId,
            service: service,
            serviceId: service.id,
            count: 1
        }); })));
    }, [originalMealServices]);
    var onMealConfirm = useCallback(function () {
        dispatch(saveServices());
    }, [originalMealServices]);
    var onProceed = useCallback(function () { return goToNextStep(CheckinStep.Meal); }, [goToNextStep]);
    var goBack = useGetGoBack(CheckinStep.Meal);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice: totalPrice,
            onButtonClick: onProceed,
            onBackButtonClick: goBack,
            additional: mealsPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                t('Meal'),
                ": ",
                React.createElement(Money, { money: mealsPriceToPay }))) : null
        } },
        React.createElement(AvailableServicesCountContextProvider, { value: { order: order, selectedServices: selectedServices } },
            React.createElement(MealPage, { showRecommendations: showRecommendations, meals: meals, passengers: passengersNames, segments: segments, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals, onMealClear: onMealClear, onMealAdd: onMealAdd, onMealRemove: onMealRemove, onMealAddForPassengers: onMeadAddForPassengers, onMobileProceed: onProceed, onMobileBack: isBaggageServiceAvailable ? function () { return previousStep(CheckinStep.Meal); } : null, onMealConfirm: onMealConfirm, onRecommendationConfirm: function () {
                    // #TODO
                    cacheRecommendationState();
                }, onRecommendationDisagree: cacheRecommendationState, isLoading: isLoading }))));
};
export default Meal;
