import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PromoLoader from '../PromoLoader/components/PromoLoader';
import FindOrder from '../Checkout/components/Checkout/FindOrder/FindOrder';
import { useRefuseExchangeMutation, ExareStatus } from '@websky/graphql';
import { isCheckinOrderResponse, isOrderResponse } from '../typeguards';
export default function OrderGuard(_a) {
    var _this = this;
    var children = _a.children, onOrderLoad = _a.onOrderLoad, loadOrder = _a.loadOrder, orderId = _a.orderId, orderLoaded = _a.orderLoaded, onOrderAccessGranted = _a.onOrderAccessGranted;
    var _b = __read(useState(true), 2), userHasAccess = _b[0], setUserHasAccess = _b[1];
    var _c = loadOrder({ skip: true, fetchPolicy: 'no-cache' }), refetch = _c.refetch, loading = _c.loading;
    var _d = __read(useRefuseExchangeMutation(), 1), refuseExchange = _d[0];
    var load = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, data, errors, _b, order, errors_1, error_1;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setUserHasAccess(true);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 10, , 11]);
                    return [4 /*yield*/, refetch({ id: orderId })];
                case 2:
                    _a = _e.sent(), data = _a.data, errors = _a.errors;
                    if (!errors) return [3 /*break*/, 3];
                    throw errors;
                case 3:
                    if (!(data && isOrderResponse(data))) return [3 /*break*/, 8];
                    if (!(((_d = (_c = data.Order.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange) === null || _d === void 0 ? void 0 : _d.status) === ExareStatus.TimelimitExpired)) return [3 /*break*/, 6];
                    return [4 /*yield*/, refuseExchange({ variables: data.Order.exareInfo.lastExchange })];
                case 4:
                    _e.sent();
                    return [4 /*yield*/, refetch()];
                case 5:
                    _b = _e.sent(), order = _b.data, errors_1 = _b.errors;
                    if (errors_1) {
                        throw errors_1;
                    }
                    if (isOrderResponse(order)) {
                        onOrderLoad(order.Order);
                    }
                    return [3 /*break*/, 7];
                case 6:
                    onOrderLoad(data.Order);
                    _e.label = 7;
                case 7: return [3 /*break*/, 9];
                case 8:
                    if (data && isCheckinOrderResponse(data)) {
                        onOrderLoad(data.CheckinOrder);
                    }
                    else {
                        setUserHasAccess(false);
                    }
                    _e.label = 9;
                case 9: return [3 /*break*/, 11];
                case 10:
                    error_1 = _e.sent();
                    console.error(error_1);
                    setUserHasAccess(false);
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var orderAccessGrantedHandler = useCallback(function (order) {
        onOrderAccessGranted(order);
        setUserHasAccess(true);
    }, [onOrderAccessGranted]);
    useEffect(function () {
        if (!orderLoaded) {
            load();
        }
    }, [orderLoaded]);
    if (!userHasAccess) {
        return React.createElement(FindOrder, { initialId: orderId, onSuccess: orderAccessGrantedHandler });
    }
    return !orderLoaded || loading ? React.createElement(PromoLoader, { innerPromoLoader: true }) : React.createElement(React.Fragment, null, children);
}
