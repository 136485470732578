import { compareAsc, toDate, parseISO } from 'date-fns';
export var safeAddDays = function (date, days) {
    var newDate = date instanceof Date ? toDate(date) : parseISO(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};
export var createDatesArray = function (minDate, maxDate) {
    var date = toDate(minDate);
    var datesArray = [];
    datesArray.push(minDate);
    while (compareAsc(maxDate, date) === 1) {
        date = safeAddDays(date, 1);
        datesArray.push(date);
    }
    return datesArray;
};
