import { __read } from "tslib";
import * as React from 'react';
import { Field } from '../../../../../../Passenger/components/Field/Field';
import { useTheme } from '../../../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useConfig } from '../../../../../../context';
import Link from '../../../../../../Link';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import Switch from '../../../../../../PassengerForm/components/components/Switch/Switch';
var LoyaltyGroup = function (props) {
    var _a, _b;
    var _c;
    var _d = __read(React.useState(!!((_c = props.fields[0]) === null || _c === void 0 ? void 0 : _c.defaultValue)), 2), open = _d[0], setOpen = _d[1];
    var groupTheme = useTheme('PassengerForm').Group;
    var theme = useTheme('PassengerForm').LoyaltyGroup;
    var t = useTranslation('Passenger').t;
    var config = useConfig().global.companyInfo;
    var form = useForm();
    useEffect(function () {
        if (!!props.fields[0].defaultValue) {
            setOpen(true);
        }
    }, [props.fields]);
    useEffect(function () {
        if (!open) {
            form.change(props.fields[0].name, '');
        }
    }, [open]);
    var onToggle = function () {
        setOpen(!open);
    };
    return (React.createElement("div", { className: cn(groupTheme.container, theme.container_loyalty, (_a = {}, _a[theme.withIcons] = !!props.groupIcon, _a)) },
        props.groupIcon && React.createElement("div", { className: groupTheme.icon }, props.groupIcon),
        React.createElement("div", { className: cn(theme.loyalty_switch, (_b = {}, _b[theme.loyalty_switch_open] = open, _b)) },
            React.createElement(Switch, { label: t('I have a loyalty card'), isActive: open, onClick: onToggle, isDisabled: props.fields[0].readonly || props.disabled }),
            !open && (React.createElement("p", { className: theme.loyalty__desc },
                t("Don't have a card yet?"),
                ' ',
                React.createElement(Link, { target: "_blank", action: props.onSignUpLoyaltyProgram
                        ? props.onSignUpLoyaltyProgram
                        : config.loyaltyProgramRegistrationUrl },
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: t('Register now') } }))))),
        React.createElement("div", { className: theme.loyalty, style: { display: open ? 'block' : 'none' } },
            React.createElement(Field, { field: props.fields[0], passengerId: props.passengerId, passengerType: props.passengerType, disabled: props.fields[0].readonly || props.disabled }))));
};
export default LoyaltyGroup;
