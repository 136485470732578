import { Checkbox } from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { AirplaneIcon } from '../icons';
import { format, initI18n } from '../../utils';
initI18n('SelectSegment');
var SelectableSegment = function (props) {
    var _a;
    var segment = props.segment, selected = props.selected, isLoading = props.isLoading, isDisabled = props.isDisabled, onChange = props.onChange;
    var t = useTranslation('SelectSegment').t;
    var css = useTheme('SelectableSegment').SelectableSegmentDesktop;
    var handleSelect = useCallback(function () {
        onChange(segment);
    }, [segment, selected, onChange]);
    return (React.createElement("div", { className: cn(css.segment, (_a = {},
            _a[css.segment_disabled] = isDisabled,
            _a)) },
        React.createElement("div", { className: css.dateWrp },
            React.createElement("span", { className: css.time }, segment.departure.time.substr(0, 5)),
            React.createElement("span", null, format(segment.departure.date, 'dd MMM yyyy'))),
        React.createElement("div", { className: css.flightInfo },
            React.createElement("div", { className: css.flight },
                t('Flight'),
                ": ",
                segment.flightNumber),
            React.createElement("div", { className: css.route },
                React.createElement("div", null,
                    segment.departure.airport.city.name,
                    "\u00A0",
                    React.createElement("span", { className: css.iata },
                        "(",
                        segment.departure.airport.iata,
                        ")")),
                React.createElement("span", { className: css.airplane },
                    React.createElement(AirplaneIcon, null)),
                React.createElement("div", null,
                    segment.arrival.airport.city.name,
                    "\u00A0",
                    React.createElement("span", { className: css.iata },
                        "(",
                        segment.arrival.airport.iata,
                        ")")))),
        React.createElement("div", { className: css.selectWrp },
            React.createElement("label", { className: css.selectLabel },
                t('Select'),
                ":",
                React.createElement(Checkbox, { color: 'primary', classes: {
                        colorPrimary: css.checkbox,
                        checked: css.checkbox_checked
                    }, checked: selected, onChange: handleSelect, disabled: isLoading || isDisabled })))));
};
export default SelectableSegment;
