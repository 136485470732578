import { __assign, __read } from "tslib";
import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import cn from 'classnames';
import Fade from '@material-ui/core/Fade';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import FullScreenDialogHeader from './Header/Header';
var SlideUp = React.forwardRef(function (props, ref) { return (React.createElement(Slide, __assign({ direction: "up" }, props, { ref: ref, children: props.children }))); });
var FadeTransition = React.forwardRef(function (props, ref) { return (React.createElement(Fade, __assign({}, props, { ref: ref, children: props.children }))); });
initI18n('FullscreenDialog');
var FullscreenDialog = React.forwardRef(function (_a, ref) {
    var _b;
    var mountOnEnter = _a.mountOnEnter, isOpen = _a.isOpen, children = _a.children, title = _a.title, headerClassName = _a.headerClassName, closeClassName = _a.closeClassName, contentClassName = _a.contentClassName, fadeTransition = _a.fadeTransition, keepMounted = _a.keepMounted, onClose = _a.onClose, rootDialogClassName = _a.rootDialogClassName, hideHeader = _a.hideHeader;
    var theme = useTheme('FullscreenDialog').FullscreenDialog;
    var _c = __read(useState(!mountOnEnter), 2), dialogIsMounted = _c[0], setDialogIsMounted = _c[1];
    useEffect(function () {
        if (isOpen && !dialogIsMounted) {
            setDialogIsMounted(true);
        }
    }, [isOpen, dialogIsMounted]);
    return (React.createElement(Dialog, { open: isOpen, TransitionComponent: !fadeTransition ? SlideUp : FadeTransition, fullScreen: true, onClose: onClose, classes: {
            root: cn(theme.withoutPadding, rootDialogClassName)
        }, keepMounted: keepMounted },
        !hideHeader && (React.createElement(FullScreenDialogHeader, { onClose: onClose, title: title, headerClassName: headerClassName, closeClassName: closeClassName })),
        dialogIsMounted && (React.createElement("div", { ref: ref, className: cn(theme.content, contentClassName, (_b = {},
                _b[theme.content_withoutOffset] = hideHeader,
                _b)) }, children))));
});
export default FullscreenDialog;
