import { __assign } from "tslib";
import { convertICULocale, getLocale } from '@websky/core/src/utils';
import { OauthServiceType } from '@websky/graphql';
var isProd = process.env.NODE_ENV === 'production';
var getConvertedLocale = function () { return convertICULocale(getLocale()); };
var getLegalUrl = function (locale) {
    switch (locale) {
        case 'ru':
            return 'https://iraero.ru/information/the-rules-of-transportation/';
        case 'ch':
            return 'https://iraero.ru/en/information/the-rules-of-transportation/';
        default:
            return 'https://iraero.ru/en/information/the-rules-of-transportation/';
    }
};
var getPrivacyPolicyUrl = function (locale) {
    switch (locale) {
        case 'ru':
            return 'https://iraero.ru/about/user-agreement/';
        case 'ch':
            return 'https://iraero.ru/en/about/user-agreement/';
        default:
            return 'https://iraero.ru/en/about/user-agreement/';
    }
};
export var getUrls = function (locale) {
    var _a;
    var currentLocale = (_a = locale) !== null && _a !== void 0 ? _a : getConvertedLocale();
    return {
        instagram: 'https://www.instagram.com/iraero/',
        vk: 'https://vk.com/iraero',
        baggage: "https://iraero.ru/".concat(currentLocale, "/information/the-rules-of-transportation/baggage-handling-rules/"),
        seat: "https://iraero.ru/".concat(currentLocale, "/services/the-choice-of-seats-in-the-cabin/"),
        legal: getLegalUrl(currentLocale),
        privacyPolicy: getPrivacyPolicyUrl(currentLocale),
        exarePrivacyPolicyURL: "https://iraero.ru/".concat(currentLocale, "/information/tickets/contract/"),
        exareRulesURL: "https://iraero.ru/".concat(currentLocale, "/information/rate/"),
        insurance: 'https://iraero.ru/services/insurance-of-passengers/',
        loyaltyProgramRegistrationUrl: "",
        facebook: '',
        meal: ""
    };
};
export var getLocaleDependedLinks = function (locale) {
    return {
        global: {
            companyInfo: __assign({}, getUrls(locale))
        }
    };
};
export var companyInfo = __assign(__assign({}, getUrls()), { iataCode: 'IO', loyaltyProgramName: '', loyalty: {
        links: [
            {
                title: 'Status',
                url: ""
            },
            {
                title: 'Earn miles',
                url: ""
            },
            {
                title: 'Use miles',
                url: ""
            },
            {
                title: 'FAQ',
                url: ""
            }
        ]
    } });
export var i18n = {
    ru: {
        Passenger: {
            'Date expired': 'Некорректная дата'
        },
        Warnings: {
            'meal.alcohol.warning': 'Платное питание заменяет стандартное (бесплатное).\nВнешний вид блюд на борту ВС может незначительно отличаться от представленных на фотографиях. Изображения носят рекламный характер.'
        },
        Checkout: {
            'I accept the': 'Я соглашаюсь с ',
            'terms of agreement': 'правилами перевозок',
            Check: 'Проверить',
            'Online doctor': 'Врач в поездку',
            'airline rules': 'правилами авиакомпании',
            CheckoutDefaultAgreement: 'Я соглашаюсь с <span data-type="termsOfTariff">условиями тарифа</span>, <a target="_blank" href="https://iraero.ru/information/the-rules-of-transportation/">правилами перевозок</a>, <a target="_blank" href="https://iraero.ru/services/insurance-of-passengers/">условиями страхования</a> и <a target="_blank" href="https://iraero.ru/about/user-agreement/">условиями обработки персональных данных</a>',
            CheckoutCustomAgreement: 'Я соглашаюсь с <span data-type="termsOfTariff">условиями тарифа</span>, <a target="_blank" href="https://iraero.ru/information/the-rules-of-transportation/">правилами перевозок</a>, <a target="_blank" href="https://iraero.ru/services/insurance-of-passengers/">условиями страхования</a>, <a target="_blank" href="https://iraero.ru/about/user-agreement/">условиями обработки персональных данных</a> и <span data-type="passportControl">с информацией о прохождении паспортного контроля</span>',
            PassportControlModal_title: 'Информация о прохождении паспортного контроля',
            PassportControlModal_text: 'С 01.10.2024 г. в аэропорту Домодедово (также в аэропортах Шереметьево и Внуково), при прохождении паспортного контроля по Прилету и Вылету вводятся дополнительные меры контроля для иностранных граждан, за исключением дипломатов, сотрудников международных организаций, а также пассажиров в возрасте до 6 лет. На кабинах паспортного контроля дополнительно будет проводится фотографирование и дактилоскопический контроль (снятие отпечатков пальцев). Все кабины оснащены дополнительным оборудованием.'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Эл. почта'
        },
        Results: {
            'Other dates': 'График цен'
        },
        Checkin: {
            'You can now download and print your boarding passes. You will need them at the airport.': 'Вы можете самостоятельно распечатать посадочные талоны или сделать это на стойке регистрации в аэропорту. Они необходимы при прохождении предполетного досмотра, на паспортном контроле, выходе на посадку и на борту воздушного судна.',
            'Booking not found': 'Пассажир не найден. Пожалуйста, проверьте введенную информацию и повторите попытку'
        },
        CheckinConditions: {
            Rules_0: 'Онлайн-регистрация открывается за 24 часа и закрывается за 3 часа до вылета.',
            Rules_1: 'Прием багажа, как правило, закрывается за 40 минут до вылета рейса (время окончания приема багажа может меняться в зависимости от аэропорта).',
            Rules_2: 'Пожалуйста, убедитесь, что вес и размер вашей ручной клади не превышают норму по тарифу.'
        },
        Account: {
            'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm': 'Пожалуйста, убедитесь, что вес вашей ручной клади не превышает норму по тарифу, а размер не превышает 55x40x20 см.'
        },
        Refund: {
            Rerouting: 'В связи с получением повестки или мобилизационного предписания;',
            Cancellation: 'Отмена/перенос рейса',
            ByChoice: 'По собственному желанию',
            Illness: 'Болезнь пассажира, либо его близкого родственника, совместно следующего с ним на воздушном судне',
            Bereavement: 'Утрата близкого родственника, либо члена семьи'
        },
        PaymentForm: {
            Invoicebox: 'Оплата по счету для юр. лиц и ИП'
        },
        Consents: {
            agreement_rules: 'Я ознакомился(-лась) с {{links}}, оплатой данных доп. услуг, принимаю их и согласен с ними.',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">правилами страхования</a>, {{insurancesProductsLinks}}',
            agreement_interpolation: '{{insurancesRules}}',
            agreement_additionalInsuranceRules: '{{link}}',
            agreement_additionalInsuranceName: 'условиями публичной оферты «{{name}}»'
        },
        translation: {
            'Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.': 'Пожалуйста, выберите другую дату или измените свой поиск.'
        },
        Title: {
            companyName: 'IrAero'
        },
        FareLock: {
            'No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}': 'Если вы примете решение лететь в выбранную дату в течение следующих {{hours}} {{plural}}, вы сможете оплатить полную стоимость бронирования по тарифу, который вы заморозили. Обратите внимание, что сбор за заморозку тарифа не возвращается. Оплата доступна только пластиковой картой (не SberPay или СБП).',
            'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable.': 'Если вы примете решение лететь в выбранную дату в течение следующих {{hours}} {{plural}}, вы сможете оплатить полную стоимость бронирования по тарифу, который вы заморозили. Обратите внимание, что сбор за заморозку тарифа не возвращается. Оплата доступна только пластиковой картой (не SberPay или СБП).'
        }
    },
    en: {
        Passenger: {
            'Date expired': 'Incorrect date'
        },
        Warnings: {
            'meal.alcohol.warning': 'Paid meals replace standard (free) meals.\nMeals on board aircraft may look somewhat different from those shown in the pictures. The images are for promotional purposes only.'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Email'
        },
        Checkout: {
            'terms of agreement': 'transportation rules',
            Check: 'Check',
            'Online doctor': 'Online doctor',
            'airline rules': 'airline rules',
            CheckoutDefaultAgreement: 'I accept the <span data-type="termsOfTariff">terms of tariff</span>, <a target="_blank" href="https://iraero.ru/en/information/the-rules-of-transportation/">transportation rules</a>, <a target="_blank" href="https://iraero.ru/services/insurance-of-passengers/">terms of insurances</a> and <a target="_blank" href="https://iraero.ru/en/about/user-agreement/">processing of my personal data</a>'
        },
        CheckinConditions: {
            Rules_0: 'Online check-in opens 24 hours and closes 3 hours before departure.',
            Rules_1: 'Baggage check-in closes 40 minutes before flight departure (baggage check-in closing times may vary depending on the airport).',
            Rules_2: 'Please make sure that the weight and size of your carry-on baggage does not exceed the fare allowance.'
        },
        Results: {
            'Other dates': 'Show price diagram'
        },
        Refund: {
            Rerouting: 'Due to receipt of a summons or mobilization order;',
            Cancellation: 'Flight cancellation/rescheduling',
            ByChoice: 'By choice',
            Illness: 'Illness of a passenger of a member of his/her family travelling together with the passenger on the aircraft',
            Bereavement: 'Loss of a close relative or family member'
        },
        PaymentForm: {
            Invoicebox: 'Payment by invoice for legal entities'
        },
        Consents: {
            agreement_rules: 'I have read the {{links}}, payment terms of these additional services. I accept them and agree with them.',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">rules of insurance</a>, {{insurancesProductsLinks}}',
            agreement_interpolation: '{{insurancesRules}}',
            agreement_additionalInsuranceRules: '{{link}}',
            agreement_additionalInsuranceName: 'terms of public offer «{{name}}»'
        },
        translation: {
            'Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.': 'Please select another date or modify your search.'
        },
        Title: {
            companyName: 'IrAero'
        },
        FareLock: {
            'No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}': 'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable. Payment is available by plastic card only (not SberPay or Faster Payments System (SBP)).',
            'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable.': 'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable. Payment is available by plastic card only (not SberPay or Faster Payments System (SBP)).'
        }
    },
    kk: {
        Consents: {
            agreement_rules: 'I have read the {{links}}, payment terms of these additional services. I accept them and agree with them.',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">rules of insurance</a>, {{insurancesProductsLinks}}',
            agreement_interpolation: '{{insurancesRules}}',
            agreement_additionalInsuranceRules: '{{link}}',
            agreement_additionalInsuranceName: 'terms of public offer «{{name}}»'
        },
        Warnings: {
            'meal.alcohol.warning': 'Paid meals replace standard (free) meals.'
        },
        Title: {
            companyName: 'IrAero'
        },
        Checkout: {
            CheckoutDefaultAgreement: "\u041C\u0435\u043D \u043A\u0435\u043B\u0456\u0441\u0435\u043C\u0456\u043D <span data-type=\"termsOfTariff\">\u0442\u0430\u0440\u0438\u0444 \u0448\u0430\u0440\u0442\u0442\u0430\u0440\u044B</span>, <a target=\"_blank\" href=\"https://iraero.ru/en/information/the-rules-of-transportation/\">\u043A\u0435\u043B\u0456\u0441\u0456\u043C \u0448\u0430\u0440\u0442\u0442\u0430\u0440\u044B</a>, <a target=\"_blank\" href=\"https://iraero.ru/services/insurance-of-passengers/\">\u0441\u0430\u049B\u0442\u0430\u043D\u0434\u044B\u0440\u0443 \u0448\u0430\u0440\u0442\u0442\u0430\u0440\u044B\u043C\u0435\u043D</a> \u043C\u0435\u043D <a target=\"_blank\" href=\"https://iraero.ru/en/about/user-agreement/\">\u0434\u0435\u0440\u0431\u0435\u0441 \u0434\u0435\u0440\u0435\u043A\u0442\u0435\u0440\u0434\u0456 \u04E9\u04A3\u0434\u0435\u0443 \u0448\u0430\u0440\u0442\u0442\u0430\u0440\u044B</a>"
        }
    },
    fr: {
        Account: {
            'Online check-in opens 30 hours and closes 50 minutes before departure': "L'enregistrement en ligne ouvre 24 heures avant et ferme 40 minutes avant le d\u00E9part",
            'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm': 'La récupération des bagages ferme 40 minutes avant le départ du vol. Veuillez vous assurer que votre bagage à main ne dépasse pas la franchise tarifaire et ne dépasse pas 55x40x20 cm.'
        },
        CheckinConditions: {
            Rules_0: "L'enregistrement en ligne ouvre 24 heures avant et ferme 3 heures avant le d\u00E9part.",
            Rules_1: 'La récupération des bagages ferme 40 minutes avant le départ du vol.',
            Rules_2: 'Veuillez vous assurer que votre bagage à main ne dépasse pas la franchise tarifaire et ne dépasse pas 55x40x20 cm.'
        },
        PaymentForm: {
            Invoicebox: 'Payment by invoice for legal entities'
        },
        Warnings: {
            'meal.alcohol.warning': 'Paid meals replace standard (free) meals.'
        },
        Consents: {
            agreement_rules: 'I have read the {{links}}, payment terms of these additional services. I accept them and agree with them.',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">rules of insurance</a>, {{insurancesProductsLinks}}',
            agreement_interpolation: '{{insurancesRules}}',
            agreement_additionalInsuranceRules: '{{link}}',
            agreement_additionalInsuranceName: 'terms of public offer «{{name}}»'
        },
        Title: {
            companyName: 'IrAero'
        },
        Checkout: {
            CheckoutDefaultAgreement: "J'accepte <span data-type=\"termsOfTariff\">conditions tarifaires</span>, <a target=\"_blank\" href=\"https://iraero.ru/en/information/the-rules-of-transportation/\">les termes de l'accord</a>, <a target=\"_blank\" href=\"https://iraero.ru/services/insurance-of-passengers/\">conditions d'assurance</a> et <a target=\"_blank\" href=\"https://iraero.ru/en/about/user-agreement/\">conditions de traitement des donn\u00E9es personnelles</a>"
        }
    },
    de: {
        Consents: {
            agreement_rules: 'I have read the {{links}}, payment terms of these additional services. I accept them and agree with them.',
            agreement_insurancesRules: '<a href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf" target="_blank">rules of insurance</a>, {{insurancesProductsLinks}}',
            agreement_interpolation: '{{insurancesRules}}',
            agreement_additionalInsuranceRules: '{{link}}',
            agreement_additionalInsuranceName: 'terms of public offer «{{name}}»'
        },
        Warnings: {
            'meal.alcohol.warning': 'Paid meals replace standard (free) meals.'
        },
        Title: {
            companyName: 'IrAero'
        },
        Checkout: {
            CheckoutDefaultAgreement: "Ich akzeptiere die <span data-type=\"termsOfTariff\">tarifbedingungen</span>, <a target=\"_blank\" href=\"https://iraero.ru/en/information/the-rules-of-transportation/\">Bedingungen der Vereinbarung</a>, <a target=\"_blank\" href=\"https://iraero.ru/services/insurance-of-passengers/\">versicherungsbedingungen</a> und <a target=\"_blank\" href=\"https://iraero.ru/en/about/user-agreement/\">Verarbeitung meiner pers\u00F6nlichen Daten</a>"
        }
    },
    zh: {
        CheckinConditions: {
            Rules_0: '网上值机会在起飞前24小时开放，并在起飞前3小钟关闭。',
            Rules_1: '提取在航班起飞前40分钟关闭。',
            Rules_2: '請確保您隨身攜帶的行李重量和尺寸不超過票價限額。'
        },
        Account: {
            'Online check-in opens 30 hours and closes 50 minutes before departure': '网上值机会在起飞前24小时开放, 并在起飞前40分钟关闭',
            'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm': '行李提取在航班起飞前40分钟关闭。 请确保您的手提行李重量不超过票价限额，尺寸不超过 55x40x20 厘米。'
        },
        PaymentForm: {
            Invoicebox: 'Payment by invoice for legal entities'
        },
        Warnings: {
            'meal.alcohol.warning': 'Paid meals replace standard (free) meals.'
        },
        Title: {
            companyName: 'IrAero'
        },
        Checkout: {
            'airline rules': '航空公司規則',
            CheckoutDefaultAgreement: "\u6211\u540C\u610F <span data-type=\"termsOfTariff\">\u8D44\u8D39\u6761\u6B3E</span>, <a target=\"_blank\" href=\"https://iraero.ru/en/information/the-rules-of-transportation/\">\u5408\u540C\u6761\u6B3E</a>, <a target=\"_blank\" href=\"https://iraero.ru/services/insurance-of-passengers/\">\u4FDD\u9669\u6761\u6B3E\u53CA\u7EC6\u5219</a> \u548C <a target=\"_blank\" href=\"https://iraero.ru/en/about/user-agreement/\">\u4E2A\u4EBA\u6570\u636E\u5904\u7406\u6761\u6B3E</a>"
        }
    }
};
export var config = {
    global: {
        socialAuthMethods: [
            {
                service: OauthServiceType.Vkontakte,
                isAvailable: true
            }
        ],
        authMethods: {
            ffp: false,
            phone: false
        },
        showIsActualizationResultOk: false,
        yandexMetrikaId: '47743996',
        companyInfo: companyInfo,
        findOrderURL: '',
        maxBaggagePerPassenger: 4,
        i18n: i18n,
        reserveEngines: {
            siteUrl: "https://iraero.ru/".concat(getConvertedLocale())
        }
    },
    Engine: {
        searchFormURL: isProd ? './' : null,
        calendarDaysCount: 7,
        mealWarning: 'meal.alcohol.warning'
    },
    Checkin: {
        CheckinConditions: ['Check-in conditions', 'OpenCloseCheckinCondition', 'Rules_3']
    },
    SearchForm: {
        payByMiles: false,
        showPriceGraph: true,
        enableComplexRoute: false,
        showPricesInDatepicker: true
    }
};
