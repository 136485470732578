import { produce } from 'immer';
import { initialStateFactory } from './initialStateFactory';
export var useMethods = function (state, setState, service, parentState) {
    var onToggle = function (passengerId, segmentId) {
        setState(produce(state, function (draft) {
            draft[passengerId][segmentId].selected = !draft[passengerId][segmentId].selected;
            return draft;
        }));
    };
    var restoreState = function () { return setState(initialStateFactory(service, parentState)); };
    var removeTransfer = function () {
        setState(produce(state, function (draft) {
            Object.keys(draft).map(function (passengerId) {
                Object.keys(draft[passengerId]).map(function (segmentId) {
                    if (draft[passengerId][segmentId] && !draft[passengerId][segmentId].confirmed) {
                        draft[passengerId][segmentId].selected = false;
                    }
                });
            });
        }));
    };
    return { onToggle: onToggle, removeTransfer: removeTransfer, restoreState: restoreState };
};
