import { __awaiter, __generator } from "tslib";
import { useGetUserProfileQuery } from '@websky/graphql';
import { currentUserFactory } from './utils';
import { useConfig } from '../context';
var EVENT_NAME = 'webskyCurrentUser';
export var useCurrentUser = function (skip) {
    var _a, _b;
    var currentUserInGlobalScope = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.currentUserInGlobalScope;
    var onCompleted = function (data) {
        if (!(data === null || data === void 0 ? void 0 : data.CurrentUser)) {
            return;
        }
        var userData = currentUserFactory(data.CurrentUser);
        var event = new CustomEvent(EVENT_NAME, {
            detail: userData
        });
        window[EVENT_NAME] = userData;
        document.dispatchEvent(event);
    };
    var _c = useGetUserProfileQuery({
        fetchPolicy: 'no-cache',
        skip: skip !== null && skip !== void 0 ? skip : !currentUserInGlobalScope,
        onCompleted: onCompleted
    }), data = _c.data, refetch = _c.refetch, loading = _c.loading;
    var reinitialize = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refetch()];
                case 1:
                    data = _a.sent();
                    onCompleted(data === null || data === void 0 ? void 0 : data.data);
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        data: data,
        loading: loading,
        reinitialize: reinitialize
    };
};
