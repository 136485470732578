import { __assign } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { useSelector } from 'react-redux';
import { getMealMinPrice, getMealServices, getOrder, getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import { useTranslation } from 'react-i18next';
import { getMealsPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { useRenderers } from '../../../renderProps';
import MealSummary from '../../../ServiceSummary/services/MealSummary';
import { getTravellersMap } from '../../../utils';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useIsCanModifyServices } from '../../hooks';
var MealAdditionalService = function () {
    var _a;
    var servicePageProps = useServicePageProps(ServiceType.Meal);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var shouldRedirect = useSelector(shouldRedirectToFallback);
    var _b = useUpsaleWarning(), showWarning = _b.showWarning, closeWarning = _b.closeWarning, toggleWarningIfOrderConfirmed = _b.toggleWarningIfOrderConfirmed;
    var handleClick = shouldRedirect
        ? toggleWarningIfOrderConfirmed
        : servicePageProps.toggleModal.bind(null, ServiceType.Meal);
    var t = useTranslation('Checkout').t;
    var minPrice = useSelector(getMealMinPrice);
    var issuedPrice = useSelector(getMealsPrice);
    var mealServices = useSelector(getMealServices);
    var css = useTheme('CheckoutAdditionalService').CheckoutAdditionalServiceMeal;
    var onDeleteItem = useCart().onDeleteItem;
    var isCanModifyServices = useIsCanModifyServices(mealServices);
    var order = useSelector(getOrder);
    var buttons = mealServices.length
        ? [
            {
                text: isCanModifyServices ? t('Change') : t('More details'),
                action: servicePageProps.toggleModal.bind(null, ServiceType.Meal),
                buttonType: 'change'
            }
        ]
        : [];
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(MealSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onDeleteItem }, function (content) { return (React.createElement(ServiceWrapper, { className: css.meal, icon: icons.mealIcon, addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, buttons: buttons, header: t('Meal'), priceFrom: minPrice, description: t('Feel the taste in flight!'), isSelected: !!content, priceTotal: issuedPrice.amount ? issuedPrice : null, addButtonText: !isCanModifyServices ? t('More details') : null }, content)); }),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(ServicePage, null, function (childrenProps) { return (React.createElement(ServiceCardDialog, __assign({ order: servicePageProps.order, openedModal: servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels, analyticsMeta: servicePageProps.analyticsMeta }, childrenProps))); })));
};
export default MealAdditionalService;
