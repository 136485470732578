import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore } from './store';
import Refund from './Components/Refund/Refund';
import { useCheckoutOrderRefundAdapter } from './hooks';
var Component = function (props) {
    var _a;
    if (!props.order) {
        return null;
    }
    var initialRefundState = useCheckoutOrderRefundAdapter(props.order);
    var store = createStore(initialRefundState);
    return (React.createElement(Provider, { store: store },
        React.createElement(Refund, { orderId: props.order.id, refundOrder: (_a = props.order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastRefund, onReloadCheckoutOrder: props.onReloadCheckoutOrder })));
};
export default Component;
