import * as Card from './components/Card/Card.css';
import * as OptionsList from './components/OptionsList/OptionsList.css';
import * as Banner from './components/Banner/Banner.css';
import * as InsuranceProduct from './components/InsuranceProduct/InsuranceProduct.css';
import * as InsurancePage from './components/InsurancePage/InsurancePage.css';
import * as InsurancePageModal from './components/InsurancePageModal/InsurancePageModal.css';
import * as InsurancePrice from './components/InsurancePrice/InsurancePrice.css';
import * as SelectForm from './components/SelectForm/SelectForm.css';
import * as InsuranceUpsaleModal from './components/InsuranceUpsaleModal/InsuranceUpsaleModal.css';
export default {
    Card: Card,
    Banner: Banner,
    InsuranceProduct: InsuranceProduct,
    InsurancePage: InsurancePage,
    InsurancePageModal: InsurancePageModal,
    InsurancePrice: InsurancePrice,
    SelectForm: SelectForm,
    OptionsList: OptionsList,
    InsuranceUpsaleModal: InsuranceUpsaleModal
};
