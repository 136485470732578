import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '../../../theme';
var RefundPaper = function (props) {
    var css = useTheme('Refund').RefundPaper;
    return (React.createElement(Paper, __assign({ elevation: 0, square: true }, props, { className: cn(css.paper, props.className) }), props.children));
};
export default RefundPaper;
