import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../theme';
import { SlideBottom } from '../../index';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import Modal from '../../Modal';
import CustomerContactsForm from '../CustomerContactsForm';
initI18n('CustomerContactsForm');
var CustomerContactsModal = function (props) {
    var contactsModalOpen = props.open;
    var css = useTheme('CustomerContactsForm').CustomerContactsModal;
    var t = useTranslation('CustomerContactsForm').t;
    var renderTitle = useCallback(function () { return React.createElement("span", { className: css.saveContactInfo__title }, t('Please, enter your contacts')); }, []);
    var renderForm = useCallback(function () { return React.createElement(CustomerContactsForm, __assign({}, props)); }, [props.isLoading, props.open]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { classes: { root: css.root, paper: css.saveContactInfo__modal }, open: contactsModalOpen },
                renderTitle(),
                renderForm())),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: contactsModalOpen, onClose: function () { return null; }, wrapperClassName: css.root },
                renderTitle(),
                renderForm()))));
};
export default CustomerContactsModal;
