import * as React from 'react';
import { useTranslation } from 'react-i18next';
import WarningModal from '../../../WarningModal';
import { initI18n } from '../../../utils';
import { ThemeProvider, useTheme } from '../../../theme';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
initI18n('PaymentErrors');
var ErrorModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, error = _a.error;
    var t = useTranslation('PaymentErrors').t;
    var theme = useTheme('PaymentMethodsError').ErrorModal;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var styles = React.useMemo(function () { return ({
        WarningModal: {
            WarningModal: {
                header: theme.header
            }
        }
    }); }, []);
    return (React.createElement(ThemeProvider, { value: styles },
        React.createElement(WarningModal, { title: t('An error occurred while executing the request'), onClose: onClose, content: t('Please try again later or contact a customer support service'), errorMessage: "".concat(t('Error'), ": ").concat(t(error)), onButtonClick: onClose, buttonText: t('OK'), bottomDrawer: isMobile, isOpen: open })));
};
export default ErrorModal;
