import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox/Checkbox';
import FareConditionsModal from '../../InfoLinksLabel/components/FareConditionsModal';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { isCheckoutOrderGuard } from '../../typeguards';
import { useConsents } from './Consents.hook';
initI18n('Consents');
var Consents = function (_a) {
    var children = _a.children;
    var t = useTranslation('Consents').t;
    var theme = useTheme('Consents').Consents;
    var model = useConsents();
    var orderId = model.order.id;
    // this is done in order to prevent an error.
    // won't work correctly with CheckinOrderFragment
    var flightId = isCheckoutOrderGuard(model.order) ? model.order.flight.id : null;
    var segmentId = isCheckoutOrderGuard(model.order) ? model.order.flight.segments[0].segment.id : null;
    var tooltipLabel = t('Checkout:Please, read the rules of service and accept it');
    return (React.createElement(React.Fragment, null,
        (model.isNeedToShowRules || model.isNeedToShowTransferRules) && (React.createElement("div", { className: theme.container, onClick: model.onClick },
            model.isNeedToShowRules && (React.createElement("div", { className: theme.consent },
                React.createElement(Checkbox, { label: model.rules, tooltipLabel: tooltipLabel, isChecked: model.commonConsentsCheckbox.isChecked, isTooltipOpen: model.commonConsentsCheckbox.isTooltipOpen, onChange: model.commonConsentsCheckbox.onChange, ref: model.commonConsentsCheckbox.checkboxRef }))),
            model.isNeedToShowTransferRules && (React.createElement("div", { className: theme.consent },
                React.createElement(Checkbox, { label: model.transferRules, tooltipLabel: tooltipLabel, isChecked: model.transferConsentsCheckbox.isChecked, isTooltipOpen: model.transferConsentsCheckbox.isTooltipOpen, onChange: model.transferConsentsCheckbox.onChange, ref: model.transferConsentsCheckbox.checkboxRef }))))),
        children(model),
        model.order && (React.createElement(FareConditionsModal, { segmentId: segmentId, flightId: flightId, orderId: orderId, isOpen: model.isFareModalOpen, onClose: model.closeFareModal }))));
};
export default Consents;
