import { createAction } from 'redux-actions';
export var CLEAR = 'CLEAR';
export var DIFFERENT_FOR_RETURN_FLIGHT = 'DIFFERENT_FOR_RETURN_FLIGHT';
export var REMOVE_SERVICES = 'REMOVE_SERVICES';
export var DISABLE = 'DISABLE';
export var SET_SERVICES = 'SET_SERVICES';
export var SET_BAGGAGE_TAB = 'SET_BAGGAGE_TAB';
export var SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export var clear = createAction(CLEAR);
export var differentForReturnFlight = createAction(DIFFERENT_FOR_RETURN_FLIGHT);
export var disable = createAction(DISABLE);
export var setServices = createAction(SET_SERVICES);
export var setBaggageTab = createAction(SET_BAGGAGE_TAB);
export var setAllServices = createAction(SET_ALL_SERVICES);
