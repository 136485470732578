import * as React from 'react';
import { Radio } from '@mui/material';
import cn from 'classnames';
import { useTheme } from '../../../theme';
var AeroexpressPassenger = function (_a) {
    var className = _a.className, label = _a.label, trips = _a.trips, value = _a.value, onChange = _a.onChange;
    var css = useTheme('CheckoutAdditionalService').AeroexpressPassenger;
    var onChangeHandler = function (selectedTrip) { return function (_, checked) {
        var _a;
        if (checked) {
            var tripByClass = (_a = trips.find(function (trip) { return trip.id === selectedTrip.id; })) !== null && _a !== void 0 ? _a : null;
            onChange(tripByClass);
        }
        else {
            onChange(null);
        }
    }; };
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.label }, label),
        React.createElement("div", { className: css.controls }, trips.map(function (trip, index) { return (React.createElement("div", { key: "".concat(trip.id, "-").concat(index), className: css.control },
            React.createElement(Radio, { value: trip.class, checked: trip.id === (value === null || value === void 0 ? void 0 : value.id), onChange: onChangeHandler(trip) }))); }))));
};
export default AeroexpressPassenger;
