import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { AdditionalTravellerFieldEnum, InputType } from '../../../../Passenger/types';
import Group from './Group/Group';
import { useTheme } from '../../../../theme';
import { getGroupIcon, getGroupsMap } from './utils';
import { OverrideComponent } from '../../../../renderProps';
import Accompanying from './Group/Accompanying/Accompanying';
var FormFields = function (props) {
    var theme = useTheme('PassengerForm').FormFields;
    var fields = __spreadArray(__spreadArray([], __read(props.fields), false), [
        { name: 'disability', type: AdditionalTravellerFieldEnum.Disability, input: InputType.Switch }
    ], false);
    var fieldGroups = React.useMemo(function () {
        return getGroupsMap(fields, props.passengerId, props.isChangePassengers);
    }, [props.fields, props.isChangePassengers]);
    return (React.createElement("div", null,
        !props.inline && React.createElement("div", { className: theme.label }, props.label),
        React.createElement("div", { className: theme.container }, fieldGroups.map(function (group, index) {
            return (React.createElement(OverrideComponent, { key: group.type, componentProps: {
                    key: group.type,
                    fields: group.fields,
                    groupType: group.type,
                    passengerId: props.passengerId,
                    passengerType: props.passengerType,
                    passengerDocType: props.passengerDocType,
                    disabled: props.disabled,
                    onTravellerSelect: props.onTravellerSelect,
                    isAuthorized: props.isAuthorized,
                    autofocus: props.autofocus && index === 0,
                    onSignUpLoyaltyProgram: props.onSignUpLoyaltyProgram,
                    groupIcon: props.showGroupIcons && getGroupIcon(group.type),
                    selectedProfileId: props.selectedProfileId,
                    isChangeFiles: props.isChangeFiles,
                    onSwap: props.onSwap,
                    onToggleChangeFiles: props.onToggleChangeFiles
                }, component: { PassengerFormGroup: Group } }));
        })),
        React.createElement(Accompanying, { passengerId: props.passengerId, passengerType: props.passengerType, disabled: props.disabled, accompanyingFields: props.accompanyingFields })));
};
export default FormFields;
