import { __assign } from "tslib";
import { SET_LOCATION, SWITCH_LOCATION } from './actions';
var initialState = {
    departure: null,
    arrival: null
};
export var segmentsReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_LOCATION:
            return __assign(__assign({}, state), (_a = {}, _a[action.payload.locationType] = action.payload.location, _a));
        case SWITCH_LOCATION:
            return {
                departure: state.arrival,
                arrival: state.departure
            };
        default:
            return state;
    }
};
