import { SimpleFlightPrice } from '../SimpleFlightPrice/SimpleFlightPrice';
import Money from '../../../../Money/components/Money';
import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import PriceInMiles from '../../../../PriceInMiles/PriceInMiles';
import { useMemo } from 'react';
import cn from 'classnames';
import { CloseClear } from '../../../../Icons';
export var SelectedFlightPrice = function (_a) {
    var fareFamily = _a.fareFamily, selectedFare = _a.selectedFare, canceled = _a.canceled, onClear = _a.onClear, hideOnlyPrice = _a.hideOnlyPrice, subsidized = _a.subsidized;
    var css = useTheme('DesktopFlightRoute').FlightPriceStyles;
    var t = useTranslation('DesktopFlightInfo').t;
    var priceInMiles = useMemo(function () {
        var _a, _b;
        if ((_b = (_a = selectedFare.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.priceInMiles) {
            return selectedFare.prices[0].priceInMiles;
        }
        return null;
    }, [selectedFare]);
    return (React.createElement(SimpleFlightPrice, { singleFlight: false, best: false, selected: true, subsidized: subsidized, originalPriceOrLabel: React.createElement("div", { className: css.fare_family }, fareFamily.title), additionalTaxes: priceInMiles && (React.createElement("div", { className: css.miles__taxes },
            t('taxes and fees'),
            ": ",
            React.createElement(Money, { money: selectedFare.prices[0].price }))), price: !hideOnlyPrice && (React.createElement(React.Fragment, null, !canceled ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.prices__wrp) },
                !priceInMiles && (React.createElement(Money, { moneyClassName: css.price__money, money: selectedFare.prices[0].price })),
                priceInMiles && (React.createElement(PriceInMiles, { amount: priceInMiles, moneyClassName: cn(css.price__money, css.price_miles), currencyClassName: css.currency }))),
            onClear && (React.createElement("div", { className: css.clear, onClick: onClear }, CloseClear)))) : (React.createElement("div", { className: css.canceled }, t('Canceled'))))) }));
};
