import { __assign, __read } from "tslib";
import * as React from 'react';
import Dropdown from '../../../../Dropdown';
import { useConfig } from '../../../../context';
import { getCurrency, setCurrency } from '../../../../cache';
import { useTheme } from '../../../../theme';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import SlideBottom from '../../../../SlideBottom/components/SlideBottom';
import cn from 'classnames';
import { useToggleable } from '../../../../hooks';
var arrowIcon = (React.createElement("svg", { width: "10", height: "5", viewBox: "0 0 10 5", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M0 0L5 5L10 0L0 0Z", fill: "currentColor" })));
var CurrencyComponent = function (props) {
    var _a;
    var _b = useToggleable(false), isOpen = _b.isOpen, toggle = _b.toggle, close = _b.close;
    var _c = __read(React.useState(getCurrency()), 2), currentCurrency = _c[0], setCurrencyState = _c[1];
    var theme = useTheme('SearchForm').Currency;
    var currencies = useConfig().SearchForm.selectableCurrencies;
    var currenciesList = React.useMemo(function () {
        return currencies.map(function (currency) { return (__assign(__assign({}, currency), { selected: currency.code === currentCurrency })); });
    }, [currencies, currentCurrency]);
    var selectedCurrency = React.useMemo(function () {
        var currencyFromList = currenciesList.find(function (currency) { return currency.code === currentCurrency; });
        return currencyFromList !== null && currencyFromList !== void 0 ? currencyFromList : currenciesList[0];
    }, [currenciesList, currentCurrency]);
    var setCurrencyHandler = function (code) {
        setCurrency(code);
        setCurrencyState(code);
        close();
    };
    var renderList = React.useMemo(function () {
        return (React.createElement("div", { className: theme.dropdown }, currenciesList.map(function (currency) {
            return (React.createElement("div", { key: currency.code, className: theme.item, onClick: function () { return setCurrencyHandler(currency.code); } },
                currency.symbol,
                " - ",
                currency.code));
        })));
    }, [currenciesList]);
    return (React.createElement("div", { className: theme.currency },
        React.createElement("button", { className: theme.selector, type: "button", onClick: toggle },
            React.createElement("span", { className: theme.selector__inner },
                React.createElement("span", null, selectedCurrency.symbol),
                React.createElement("span", null, selectedCurrency.code)),
            React.createElement("span", { className: cn(theme.arrowIcon, (_a = {}, _a[theme.arrowIcon_flipped] = isOpen, _a)) }, arrowIcon)),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, { isOpen: isOpen, onClose: close, outsideClickIgnoreClass: theme.currency.split(' ')[0] }, renderList)),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpen, onClose: close }, renderList))));
};
export default CurrencyComponent;
