import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { Tooltip } from '../../../index';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
initI18n('RecommendedBadge');
var RecommendedBadge = function (props) {
    var css = useTheme('FareGroup').RecommendedBadge;
    var t = useTranslation('RecommendedBadge').t;
    return (React.createElement("div", { className: cn(css.wrapper, props.className) },
        React.createElement(Tooltip, { title: t('Best choice'), tooltipClassName: css.tooltip },
            React.createElement("svg", { className: css.badge, width: "34", height: "34", viewBox: "0 0 34 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("circle", { cx: "17", cy: "17", r: "17", fill: "currentColor" }),
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.8001 13.9779C24.6312 13.9779 25.3112 14.6579 25.3112 15.489L25.3036 15.5494L25.3112 15.557V17.0001C25.3112 17.1965 25.2734 17.3779 25.2054 17.5516L22.9236 22.8783C22.697 23.4223 22.1605 23.8001 21.5334 23.8001H14.7334C13.9023 23.8001 13.2223 23.1201 13.2223 22.289V14.7334C13.2223 14.3179 13.3885 13.9401 13.6681 13.6681L18.6396 8.68896L19.4405 9.4823C19.6445 9.6863 19.773 9.97341 19.773 10.2832L19.7503 10.525L19.0325 13.9779H23.8001ZM11.7112 23.8001H8.68896V14.7334H11.7112V23.8001Z", fill: "white" })))));
};
export default RecommendedBadge;
