import { __read } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { memo } from 'react';
import MediaQuery from 'react-responsive';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import * as theme from '../Passenger.css';
import { Money } from '../../../Money';
import { fieldsArrayToMap, getAdditionalLabel } from '../../utils';
import memes from '../../../memes';
var memoizedGetAdditionalLabel = memes(getAdditionalLabel);
var Header = memo(function (_a) {
    var _b, _c, _d;
    var passengerId = _a.passengerId, label = _a.label, passengerType = _a.passengerType, supplierType = _a.supplierType, servicesTotalPrice = _a.servicesTotalPrice, hasServices = _a.hasServices, setOpen = _a.setOpen, fields = _a.fields, isReadonly = _a.isReadonly, isOpen = _a.isOpen;
    var t = useTranslation('Passenger').t;
    var formValues = useFormState().values;
    var fieldsMap = fieldsArrayToMap(fields);
    var _e = __read(useState(formValues.passengers[passengerId]), 1), values = _e[0];
    var onHeaderClick = useCallback(function () { return isReadonly && hasServices && setOpen(!isOpen); }, [
        isReadonly,
        hasServices,
        isOpen
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(theme.header, (_b = {},
                    _b[theme.header_notInteractive] = !isReadonly || !hasServices,
                    _b)), onClick: onHeaderClick },
                React.createElement("div", { className: cn(theme.header__text, (_c = {}, _c[theme.header__text_notpassed] = !isReadonly, _c)) }, label),
                isReadonly && (React.createElement("div", { className: theme.header__additional },
                    React.createElement("span", null, memoizedGetAdditionalLabel(values, fieldsMap, passengerType, supplierType)))),
                servicesTotalPrice && (React.createElement("div", { className: theme.header__services },
                    React.createElement("div", { className: theme.services__label }, t('Onboard services')),
                    React.createElement(Money, { moneyClassName: cn(theme.services__price, (_d = {},
                            _d[theme.services__price_null] = servicesTotalPrice.amount === 0,
                            _d)), money: servicesTotalPrice }))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.header__text }, label)))));
});
export default Header;
