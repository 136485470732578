import { useTranslation } from 'react-i18next';
var PNR_OR_TICKET_NUMBER_REGEX = /(^[0-9БВГДКЛМНПРСТФХЦЖШA-Z]{6,6}$)|(^[0-9A-Z]{3}[0-9]{10}$)/;
var TICKET_NUMBER_REGEX = /(^[0-9A-Z]{3}[0-9]{10}$)/;
var ORDER_NUMBER = /^\d{1,13}$/;
var PASSENGER_LAST_NAME_LATIN_REGEX = /^[- a-zA-Z]{1,41}$/;
var PASSENGER_LAST_NAME_RU_REGEX = /^[- а-яА-ЯЁё]{1,41}$/;
export var isPnrOrTicketNumber = function (value) { return PNR_OR_TICKET_NUMBER_REGEX.test(value); };
export var isTicketNumber = function (value) { return TICKET_NUMBER_REGEX.test(value); };
export var isOrderNumber = function (value) { return ORDER_NUMBER.test(value); };
export var isCorrectLatingLastname = function (value) { return PASSENGER_LAST_NAME_LATIN_REGEX.test(value); };
export var isCorrectCyrillicLastName = function (value) { return PASSENGER_LAST_NAME_RU_REGEX.test(value); };
export var isSingleWord = function (value) { return value.split(' ').length === 1; };
export var useRetrieveBookingValidation = function (validationType) {
    var t = useTranslation('RetrieveBooking').t;
    var validateByOrderNumber = function (value) {
        if (!isOrderNumber(value)) {
            return t('Enter your book code or ticket number');
        }
    };
    var ticketNumberValidation = function (value) {
        if (validationType === 'ticketNumber') {
            if (!isTicketNumber(value)) {
                return t('Enter your ticket number');
            }
        }
        else {
            if (!isPnrOrTicketNumber(value)) {
                return t('Enter your book code or ticket number');
            }
        }
    };
    var lastNameValidation = function (value) {
        var splittedValue = (value === null || value === void 0 ? void 0 : value.trim().split(' ')) || [];
        if (!splittedValue.every(isCorrectCyrillicLastName) && !splittedValue.every(isCorrectLatingLastname)) {
            return t('Incorrect value');
        }
        else {
            return undefined;
        }
    };
    var ticketNumberFormat = function (value) {
        return value === null || value === void 0 ? void 0 : value.split(' ').join('').slice(0, 13).toUpperCase();
    };
    var requiredValidation = function (value) {
        if (!(value === null || value === void 0 ? void 0 : value.length)) {
            return t('Required field');
        }
    };
    var requiredValidationWrapper = function (validateFn) { return function (value) {
        return requiredValidation(value) || validateFn(value);
    }; };
    return {
        ticketNumberValidation: ticketNumberValidation,
        lastNameValidation: lastNameValidation,
        validateByOrderNumber: validateByOrderNumber,
        ticketNumberFormat: ticketNumberFormat,
        requiredValidation: requiredValidation,
        requiredValidationWrapper: requiredValidationWrapper
    };
};
