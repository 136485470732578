import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StepType } from '../../types';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import Stepbar from './Stepbar/Stepbar';
import MobileStepbar from '../../../MobileStepbar';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import { convertSearchParameters } from '../../../Results/utils';
import { useTheme } from '../../../theme';
import Seats from './routes/Seats';
import Baggage from './routes/Baggage';
import Meal from './routes/Meal';
import Passengers from './routes/Passengers';
import Order from './routes/Order';
import { getIsAllSegmentsAvailableOnlyOnCheckin, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmedOrCancelled, getIsFareLockSelected, getIsNew, getIsOrderBlockedForPayment, getIsTravellersDataFilled, getOrder, getSearchParameters } from '../../store/order/selectors';
import { analyzePurchasedProducts as analyzePurchasedProductsAction, fillOrder as fillOrderAction } from '../../store/order/actions';
import PaymentTimeLimitPage from '../../../PaymentTimeLimitPage';
import Payment from './routes/Payment';
import PaymentResult from '../../../PaymentResult';
import Actualization from './Actualization/Actualization';
import { CheckoutSteps } from '../../../MobileStepbar/types';
import { OrderGoal, reachGoal } from '../../../analytics';
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import SimpleLoader from '../../../SimpleLoader';
import Modal from '../../../Modal';
import SlideBottom from '../../../SlideBottom';
import { isServicesSaving } from '../../store/servicesIsSaving/selectors';
import { useConfig } from '../../../context';
import Refund from '../../../Refund';
import Exchange from '../../../Modules/Exchange/components/Module';
import { ActualizationStatus, OrderStatus, PaymentStatusEnum, useActualizeOrderMutation, useGetAccountInfoQuery, useOrderQuery, useResetOrderPaymentBlockMutation } from '@websky/graphql';
import { useSubsidies } from './Form/SubsidyRequest/subsidyHook';
import { useSteps, useUpdateTravellerServices } from './hooks';
import { OverrideComponent } from '../../../renderProps';
import { createDataLayer } from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
import { StepsEnum } from '../../../types';
import { CustomContentPageEnum } from '../../../CustomContent/types';
import CustomContent from '../../../CustomContent/CustomContent';
import EditPassenger from '../../../EditPassenger';
import { pnrBlockedRegExp } from '../../../apolloErrorLink';
import { useCartPrices } from '../../../Cart/hooks';
import { Insurance } from './routes/Insurance';
import { useCart } from './Stepbar/hooks';
import { useIataCode } from '../../../hooks';
initI18n('Checkout');
var checkTimeLimit = function (timelimitInSecond) { return timelimitInSecond !== null && timelimitInSecond <= 0; };
export var PaymentResultStatus;
(function (PaymentResultStatus) {
    PaymentResultStatus["Success"] = "success";
    PaymentResultStatus["Fail"] = "fail";
})(PaymentResultStatus || (PaymentResultStatus = {}));
var Checkout = function (_a) {
    var _b, _c;
    var _d;
    var match = _a.match, history = _a.history, location = _a.location, startNewSearch = _a.startNewSearch;
    createDataLayer(OrderType.Checkout);
    var theme = useTheme('Checkout').CheckoutStyles;
    var _e = useTranslation(), t = _e.t, i18n = _e.i18n;
    var order = useSelector(getOrder);
    var isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    var isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    var searchParameters = useSelector(getSearchParameters);
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var isNew = useSelector(getIsNew);
    var _f = useSteps(), steps = _f.steps, nextStep = _f.nextStep, setStep = _f.setStep, currentStep = _f.currentStep, prevStep = _f.prevStep;
    var isOrderCancelled = useSelector(getIsCancelled);
    var isOrderServicesSaving = useSelector(isServicesSaving);
    var isFareLockSelected = useSelector(getIsFareLockSelected);
    var isBlockedForPayment = useSelector(getIsOrderBlockedForPayment);
    var isAllSegmentsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    var _g = useCart(), onDeleteItem = _g.onDeleteItem, cartLoading = _g.loading;
    var priceToPay = useCartPrices();
    var dispatch = useDispatch();
    var fillOrder = function (orderParam) { return dispatch(fillOrderAction(orderParam)); };
    var analyzePurchasedProducts = function () { return dispatch(analyzePurchasedProductsAction()); };
    var orderId = order.id;
    var _h = __read(useState(null), 2), paymentResults = _h[0], setPaymentResults = _h[1];
    var _j = __read(useState(false), 2), paymentResultsModalIsVisible = _j[0], setPaymentResultsModalIsVisible = _j[1];
    var _k = __read(useState(!order.actualizationResult), 2), isLoading = _k[0], setLoading = _k[1];
    var _l = __read(useState(false), 2), orderIsRefetching = _l[0], setOrderIsRefetching = _l[1];
    var _m = __read(useState(isBookedOrConfirmed && checkTimeLimit(order.timelimitInSeconds)), 2), timelimitHasPast = _m[0], setTimelimitHasPast = _m[1];
    var _o = __read(useState(false), 2), showActualization = _o[0], setShowActualization = _o[1];
    var _p = __read(useState(null), 2), bookingErr = _p[0], setBookingErr = _p[1];
    var _q = __read(useState(null), 2), changePassengerId = _q[0], setChangePassengerId = _q[1];
    var _r = __read(useActualizeOrderMutation(), 1), actualization = _r[0];
    var _s = useOrderQuery({
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            id: orderId
        }
    }), refetchOrder = _s.refetch, refetchOrderLoading = _s.loading;
    var _t = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache'
    }), userInfo = _t.data, refetchUser = _t.refetch, userLoading = _t.loading;
    var _u = __read(useResetOrderPaymentBlockMutation(), 2), resetOrderPaymentBlock = _u[0], resetOrderLoading = _u[1].loading;
    var _v = __read(useState(resetOrderLoading), 2), isResetOrderPaymentBlockLoading = _v[0], setResetOrderPaymentBlockLoading = _v[1];
    var config = useConfig();
    var _w = config.global, showIsActualizationResultOk = _w.showIsActualizationResultOk, iataCode = _w.companyInfo.iataCode, ignoreBlockedPaymentToReturnOrder = config.Engine.ignoreBlockedPaymentToReturnOrder;
    useEffect(function () {
        setResetOrderPaymentBlockLoading(resetOrderLoading);
    }, [resetOrderLoading]);
    useUpdateTravellerServices();
    var closePaymentResultsModal = function () {
        setPaymentResultsModalIsVisible(false);
        setStep(StepsEnum.Order);
        setTimeout(function () { return setPaymentResults(null); }, 300);
    };
    var startActualize = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, actualization({
                            variables: {
                                id: orderId
                            }
                        })];
                case 1:
                    data = _b.sent();
                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.ActualizeOrder) {
                        fillOrder(data.data.ActualizeOrder);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var refetchOrderAfterPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var initialInterval, step, tryMaxCount, tryCount, polling;
        return __generator(this, function (_a) {
            initialInterval = 500;
            step = 1.5;
            tryMaxCount = 10;
            setOrderIsRefetching(true);
            tryCount = 0;
            polling = function (interval) { return __awaiter(void 0, void 0, void 0, function () {
                var data, order_1, isFullyPayedOrder, isPayedPriceFreeze, orderIsCancelled, e_1;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            tryCount++;
                            return [4 /*yield*/, refetchOrder()];
                        case 1:
                            data = _b.sent();
                            order_1 = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.Order;
                            isFullyPayedOrder = (order_1 === null || order_1 === void 0 ? void 0 : order_1.status) === OrderStatus.Confirmed && (order_1 === null || order_1 === void 0 ? void 0 : order_1.paymentStatus) === PaymentStatusEnum.Full;
                            isPayedPriceFreeze = order_1.isPriceFreezeProductSelected &&
                                !isTravellersDataFilled &&
                                (order_1 === null || order_1 === void 0 ? void 0 : order_1.paymentStatus) === PaymentStatusEnum.Part;
                            orderIsCancelled = (order_1 === null || order_1 === void 0 ? void 0 : order_1.status) === OrderStatus.Cancelled;
                            if (isFullyPayedOrder || isPayedPriceFreeze || orderIsCancelled) {
                                fillOrder(order_1);
                                setPaymentResultsModalIsVisible(true);
                                setOrderIsRefetching(false);
                            }
                            else {
                                if (tryCount <= tryMaxCount) {
                                    setTimeout(function () { return polling(interval * step); }, interval * step);
                                }
                                else {
                                    setOrderIsRefetching(false);
                                }
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _b.sent();
                            setOrderIsRefetching(false);
                            console.warn(e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            setTimeout(function () { return polling(initialInterval); }, initialInterval);
            return [2 /*return*/];
        });
    }); };
    var baseUrl = match.url;
    var refundURL = "".concat(baseUrl, "/refund");
    var exchangeURL = "".concat(baseUrl, "/exchange");
    var changePassengerDocsURL = "".concat(baseUrl, "/change-passenger-docs");
    var fallbackUrl = "".concat(baseUrl, "/order");
    var isPayment = location.pathname === "".concat(baseUrl, "/payment");
    var isExchange = location.pathname.includes(exchangeURL);
    var isRefund = location.pathname.includes(refundURL);
    var isChange = location.pathname.includes(changePassengerDocsURL);
    var isDv = useIataCode() === 'DV';
    var retrySearch = startNewSearch.bind(null, convertSearchParameters(searchParameters));
    var backToResults = function () {
        if (order.flight.searchId) {
            history.push("/results/".concat(order.flight.searchId));
        }
        else {
            retrySearch();
        }
    };
    var goToRefund = function () { return history.push(refundURL); };
    var goToExchange = function () { return history.push(exchangeURL); };
    var goToChangePassenger = function (passengerId) {
        setChangePassengerId(passengerId);
        history.push(changePassengerDocsURL);
    };
    var goToPayment = useCallback(function () {
        setPaymentResultsModalIsVisible(false);
        history.push("".concat(baseUrl, "/payment"));
    }, [paymentResults]);
    useEffect(function () {
        var _a;
        if (currentStep) {
            var contentPageByStep = (_a = {},
                _a[StepsEnum.Seats] = CustomContentPageEnum.Seats,
                _a[StepsEnum.Meal] = CustomContentPageEnum.Meal,
                _a[StepsEnum.Baggage] = CustomContentPageEnum.Baggage,
                _a[StepsEnum.Order] = CustomContentPageEnum.Order,
                _a[StepsEnum.Payment] = CustomContentPageEnum.Payment,
                _a);
            var contentPage = contentPageByStep[currentStep.type];
            if (contentPage) {
                CustomContent.sendCustomContentEvent({
                    config: config,
                    locale: i18n.language,
                    page: contentPage,
                    data: order
                });
            }
        }
    }, [currentStep, order]);
    useEffect(function () {
        var timer;
        if (isBookedOrConfirmed && order.timelimitInSeconds !== null) {
            setTimelimitHasPast(checkTimeLimit(order.timelimitInSeconds));
            // https://stackoverflow.com/questions/12633405/what-is-the-maximum-delay-for-setinterval
            var maxSignedInt = Math.pow(2, 31) - 1;
            var interval = maxSignedInt < order.timelimitInSeconds * 1000 ? maxSignedInt : order.timelimitInSeconds * 1000;
            timer = window.setInterval(function () {
                setTimelimitHasPast(true);
            }, interval);
        }
        return function () { return clearInterval(timer); };
    }, [order]);
    useEffect(function () {
        var _a;
        if (!currentStep && order.actualizationResult && !isNew) {
            history.replace(fallbackUrl);
        }
        if (!isBookedOrConfirmed &&
            ((_a = order.actualizationResult) === null || _a === void 0 ? void 0 : _a.status) === ActualizationStatus.NotAvailable &&
            order.status === OrderStatus.New) {
            setShowActualization(true);
        }
        if (!order.actualizationResult) {
            startActualize();
        }
    }, []);
    useEffect(function () {
        if (paymentResults === PaymentResultStatus.Success) {
            analyzePurchasedProducts();
            reachGoal(OrderGoal.Paid);
            refetchOrderAfterPayment();
        }
        else if (paymentResults === PaymentResultStatus.Fail) {
            reachGoal(OrderGoal.PaymentFailed);
            setPaymentResultsModalIsVisible(true);
            if (isBlockedForPayment) {
                resetPaymentBlock();
            }
        }
    }, [paymentResults]);
    var stepbarConfig = (_b = {},
        _b[CheckoutSteps.Flights] = {
            label: t('Stepbar:Flights'),
            type: CheckoutSteps.Flights,
            onClick: backToResults,
            isDisabled: !!((_d = steps[0]) === null || _d === void 0 ? void 0 : _d.disabled)
        },
        _b);
    steps.forEach(function (step) {
        stepbarConfig[step.type] = {
            label: t("Stepbar:".concat(step.type)),
            type: CheckoutSteps[step.type],
            onClick: function () { return setStep(step.type); },
            isDisabled: step.disabled
        };
    });
    var goToPrevStep = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            currentStep.type === steps[0].type ? backToResults() : prevStep();
            return [2 /*return*/];
        });
    }); };
    var hasPaymentStep = steps.some(function (step) { return step.type === StepType.Payment; });
    var getCurrentStep = function () { return steps.findIndex(function (step) { return location.pathname.search(step.url) >= 0; }) + 1; };
    var goToOrder = function (reload) {
        setStep(StepType.Order);
        if (reload) {
            window.location.reload();
        }
    };
    var setBookingErrorHandler = function (error) {
        if (!pnrBlockedRegExp.test(error === null || error === void 0 ? void 0 : error.message)) {
            setBookingErr(error);
        }
    };
    var onPaymentSuccess = useCallback(function (reload) {
        reachGoal(OrderGoal.Paid);
        analyzePurchasedProducts();
        goToOrder(reload);
    }, []);
    var handlePaymentMethodsLoaded = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!(errors === null || errors === void 0 ? void 0 : errors.length) && (data === null || data === void 0 ? void 0 : data.Order)) {
                        fillOrder(data.Order);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [isBlockedForPayment]);
    var handleRefetchCheckoutOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (!(errors === null || errors === void 0 ? void 0 : errors.length) && (data === null || data === void 0 ? void 0 : data.Order)) {
                        fillOrder(data.Order);
                    }
                    return [2 /*return*/, data.Order];
            }
        });
    }); }, [refetchOrder, orderId]);
    var resetPaymentBlock = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setResetOrderPaymentBlockLoading(true);
                    return [4 /*yield*/, resetOrderPaymentBlock({ variables: { id: orderId } })];
                case 1:
                    _a.sent();
                    setResetOrderPaymentBlockLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleReturnToOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isBlockedForPayment) return [3 /*break*/, 2];
                    return [4 /*yield*/, resetPaymentBlock()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, refetchOrder({ id: orderId })];
                case 3:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.Order) {
                        fillOrder(data.Order);
                        history.push("".concat(baseUrl, "/order"));
                    }
                    setPaymentResultsModalIsVisible(false);
                    return [2 /*return*/];
            }
        });
    }); }, [isBlockedForPayment]);
    var onRefetchUser = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, refetchUser()];
                case 1:
                    data = (_a.sent()).data;
                    if (!(data === null || data === void 0 ? void 0 : data.CurrentUser)) return [3 /*break*/, 3];
                    return [4 /*yield*/, handleRefetchCheckoutOrder()];
                case 2:
                    _a.sent();
                    return [2 /*return*/, data];
                case 3: return [2 /*return*/, null];
                case 4:
                    e_2 = _a.sent();
                    console.log(e_2.message);
                    return [2 /*return*/, null];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [refetchUser, handleRefetchCheckoutOrder]);
    var orderSubsidyState = useSubsidies();
    var isCanceledOrConfirmed = iataCode === 'WZ' ? false : isConfirmedOrCancelled;
    var onOrderBooked = function (order) {
        reachGoal(OrderGoal.Booked);
        fillOrder(order);
        nextStep();
    };
    var isBookedUserLoading = order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed ? userLoading : false;
    var onCloseChangePassenger = function () {
        history.replace(fallbackUrl);
        setChangePassengerId(null);
    };
    return (React.createElement("div", { className: cn(theme.container, (_c = {},
            _c[theme.container_locked] = !isLoading && timelimitHasPast,
            _c[theme.container_seats] = !timelimitHasPast && currentStep && currentStep.type === StepType.Seats,
            _c)) },
        !isBookedOrConfirmed && (React.createElement(Actualization, { startBooking: function () { return null; }, orderId: orderId, open: showActualization, showOkMessage: showIsActualizationResultOk, onClose: function () { return setShowActualization(false); }, onSetOrder: fillOrder, actualizationInfo: order.actualizationResult, backToResults: backToResults })),
        React.createElement(ProtectedRoute, { isAccessible: isConfirmedOrCancelled, redirectTo: fallbackUrl, path: "".concat(baseUrl, "/refund"), render: function () { return (React.createElement(React.Fragment, null,
                refetchOrderLoading && React.createElement(PromoLoader, null),
                !refetchOrderLoading && (React.createElement(Refund, { order: order, onReloadCheckoutOrder: handleRefetchCheckoutOrder })))); } }),
        React.createElement(ProtectedRoute, { isAccessible: !!changePassengerId || !!order.exareInfo.lastChangeTravellerDataOrder, redirectTo: fallbackUrl, path: changePassengerDocsURL, render: function () { return (React.createElement(EditPassenger, { passengerId: changePassengerId, refetchOrder: handleRefetchCheckoutOrder, returnToOrder: onCloseChangePassenger })); } }),
        isLoading && React.createElement(PromoLoader, null),
        (orderIsRefetching || isOrderServicesSaving || isResetOrderPaymentBlockLoading || cartLoading) && (React.createElement(SimpleLoader, null)),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, className: theme.payment__slideBottom },
                paymentResults === 'success' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: closePaymentResultsModal })),
                paymentResults === 'fail' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: goToPayment, onBackToOrder: handleReturnToOrder })))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, classes: {
                    paper: theme.payment__result
                }, closeClassName: theme.payment__close },
                paymentResults === 'success' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: closePaymentResultsModal })),
                paymentResults === 'fail' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: goToPayment, onBackToOrder: handleReturnToOrder })))),
        !isLoading && timelimitHasPast && (React.createElement("div", null,
            React.createElement(PaymentTimeLimitPage, { title: t('PaymentTimeLimit:Sorry,'), text: t('PaymentTimeLimit:time to complete booking has expired'), buttonText: t('PaymentTimeLimit:Repeat search'), onClick: backToResults }))),
        !isLoading && !timelimitHasPast && (React.createElement(React.Fragment, null,
            React.createElement(Route, { exact: true, path: baseUrl, render: function () {
                    return order.status === OrderStatus.New ? (React.createElement(Redirect, { to: "".concat(baseUrl, "/").concat(steps[0].url) })) : (React.createElement(Redirect, { to: fallbackUrl }));
                } }),
            !isBookedUserLoading && !isPayment && !isExchange && !isRefund && !isChange && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(OverrideComponent, { componentProps: {
                        order: order,
                        activeStep: getCurrentStep(),
                        steps: stepbarConfig,
                        currentStep: getCurrentStep(),
                        stepbarConfig: stepbarConfig,
                        onServiceDelete: onDeleteItem
                    }, component: {
                        CheckoutDesktopStepbar: Stepbar
                    } }))),
            !isCanceledOrConfirmed && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(MobileStepbar, { order: order, currentStep: getCurrentStep(), stepbarConfig: stepbarConfig, onServiceDelete: onDeleteItem }))),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/seats"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    var protectedComponent = (React.createElement(OverrideComponent, { componentProps: {
                            suggestSeatSelection: !isAllSegmentsAvailableOnCheckin,
                            goToNextStep: nextStep,
                            goToPrevStep: goToPrevStep
                        }, component: { CheckoutSeats: Seats } }));
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") });
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/exchange"), render: function () {
                    return React.createElement(Exchange, { orderId: orderId, onRefetchCheckoutOrder: handleRefetchCheckoutOrder });
                } }),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/baggage"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    var protectedComponent = React.createElement(Baggage, { goToPrevStep: goToPrevStep, goToNextStep: nextStep });
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        else {
                            return React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") });
                        }
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/meal"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    var protectedComponent = React.createElement(Meal, { goToPrevStep: goToPrevStep, goToNextStep: nextStep });
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") });
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(ProtectedRoute, { path: "".concat(baseUrl, "/insurance"), isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: function () {
                    var protectedComponent = (React.createElement(Insurance, { goToNextStep: nextStep, goToPrevStep: goToPrevStep, steps: steps }));
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") });
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/passengers"), render: function () {
                    // для мягкого перехода на новый набор шагов, после изменения в пхп - убрать
                    if (isBlockedForPayment && hasPaymentStep && !isFareLockSelected) {
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") });
                    }
                    return (React.createElement(Passengers, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, showRefundButton: !!order.exareInfo.lastRefund || order.exareInfo.refundAvailability, showExchangeButton: !!order.exareInfo.lastExchange || order.exareInfo.exchangeAvailability, onSetOrder: fillOrder, onOrderBooked: onOrderBooked, setBookingError: setBookingErrorHandler, bookingError: bookingErr, backToResults: backToResults, currentStep: currentStep, goToPrevStep: goToPrevStep, userInfo: userInfo === null || userInfo === void 0 ? void 0 : userInfo.CurrentUser, refetchUser: onRefetchUser, orderSubsidyState: orderSubsidyState, setStep: setStep, steps: steps, setNextStep: nextStep }));
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/order"), render: function () {
                    if (userLoading) {
                        return React.createElement(PromoLoader, null);
                    }
                    if (!isTravellersDataFilled && isNew) {
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") });
                    }
                    if (isBlockedForPayment && hasPaymentStep && !isFareLockSelected) {
                        return React.createElement(Redirect, { to: "".concat(baseUrl, "/payment") });
                    }
                    return (React.createElement(Order, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, setBookingError: setBookingErrorHandler, bookingError: bookingErr, userInfo: userInfo === null || userInfo === void 0 ? void 0 : userInfo.CurrentUser, refetchUser: onRefetchUser, currentStep: currentStep, paymentSuccess: paymentResults === PaymentResultStatus.Success, setStep: setStep, setNextStep: nextStep }));
                } }),
            React.createElement(ProtectedRoute, { exact: true, path: "".concat(baseUrl, "/payment"), isAccessible: hasPaymentStep, redirectTo: fallbackUrl, render: function (params) { return (React.createElement(Payment, { onBackToOrder: (isBlockedForPayment || ignoreBlockedPaymentToReturnOrder) && handleReturnToOrder, priceToPay: priceToPay, orderId: orderId, order: order, timelimit: order.timelimit ? new Date(order.timelimit) : null, redirectPathname: params.location.pathname, onPaymentMethodsLoaded: handlePaymentMethodsLoaded, onSuccess: onPaymentSuccess, goToPrevStep: handleReturnToOrder, orderPaymentResult: paymentResults, orderIsRefetching: orderIsRefetching })); } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/payment/successfull"), render: function () {
                    setPaymentResults(PaymentResultStatus.Success);
                    return React.createElement(Redirect, { to: fallbackUrl });
                } }),
            React.createElement(Route, { path: "".concat(baseUrl, "/payment/failed"), render: function () {
                    setPaymentResults(PaymentResultStatus.Fail);
                    return React.createElement(React.Fragment, null);
                } })))));
};
export default withRouter(Checkout);
