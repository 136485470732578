import { __read, __values } from "tslib";
import { useMemo } from 'react';
export var useTransferServiceSummary = function (service, segments, passengers, servicesState) {
    var serviceSummaryOptions = useMemo(function () {
        var e_1, _a, e_2, _b;
        var serviceSummaryOptions = [];
        try {
            for (var _c = __values(Object.keys(servicesState)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 1), passengerId = _e[0];
                var summaryOption = {
                    header: passengers[passengerId].fullName,
                    items: []
                };
                try {
                    for (var _f = (e_2 = void 0, __values(Object.keys(servicesState[passengerId]))), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var _h = __read(_g.value, 1), segmentId = _h[0];
                        var passengerService = servicesState[passengerId][segmentId].find(function (passengerService) { return passengerService.service.id === service.id; });
                        if (!passengerService) {
                            continue;
                        }
                        if (passengerService.selected) {
                            summaryOption.items.push({
                                price: service.price,
                                name: "".concat(segments[segmentId].date, ", ").concat(segments[segmentId].city),
                                id: passengerService.service.id
                            });
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                serviceSummaryOptions.push(summaryOption);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return serviceSummaryOptions.filter(function (summaryOption) { var _a; return (_a = summaryOption.items) === null || _a === void 0 ? void 0 : _a.length; });
    }, [servicesState, segments, service]);
    return {
        serviceSummaryOptions: serviceSummaryOptions
    };
};
