import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useField, useForm } from 'react-final-form';
import { DateField } from '../../../../../../Passenger/components/Field/Date/Date';
import { Select } from '../../../../../../Passenger/components/Field/Select/Select';
import { InputType } from '../../../../../../Passenger/types';
import { validate } from '../../../../../../Passenger/utils';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../Input';
import PhoneInput from '../../../../../../PhoneInput';
import GenderSwitch from '../GenderSwitch/GenderSwitch';
var Field = function (props) {
    var t = useTranslation('Passenger').t;
    var form = useForm();
    var field = useField(props.name, {
        type: 'text',
        validate: function (value, allValues) {
            return t(validate(value, allValues, props.type, props.validationRules));
        }
    });
    var isError = field.meta.error && field.meta.touched;
    var error = isError ? field.meta.error : undefined;
    if (props.type === InputType.Phone) {
        return (React.createElement(PhoneInput, { value: field.input.value, textFieldsProps: {
                onChange: field.input.onChange,
                name: field.input.name,
                error: isError,
                helperText: error,
                label: t(props.label)
            } }));
    }
    if (props.type === InputType.Switch) {
        return React.createElement(GenderSwitch, { value: field.input.value, onChange: field.input.onChange, error: error });
    }
    if (props.type === InputType.Date) {
        return (React.createElement(DateField, { label: t(props.label), value: field.input.value, onChange: field.input.onChange, onBlur: field.input.onBlur, error: error }));
    }
    if (props.type === InputType.Select) {
        var val_1 = field.input.value;
        if (props.name === 'docType') {
            var isExist = props.options.some(function (otion) { return otion.value === val_1; });
            if (!isExist) {
                val_1 = null;
            }
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Select, { options: props.options, name: props.name, label: t(props.label), selectFirstOptionByDefault: props.name === 'docType', onChange: function (key, e) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (props.name === 'country') {
                            form.change('docType', null);
                        }
                        field.input.onChange(e);
                        return [2 /*return*/];
                    });
                }); }, onBlur: field.input.onBlur, value: val_1, error: error, translateValue: props.translateValue })));
    }
    return (React.createElement(Input, { label: t(props.label), value: field.input.value, name: field.input.name, TextFieldProps: __assign(__assign({}, field.input), { error: isError, helperText: error, disabled: props.disabled }) }));
};
export default Field;
