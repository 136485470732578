import { __assign, __read, __spreadArray } from "tslib";
import { useMemo, useReducer } from 'react';
import { isRoundTrip } from './utils';
import { useToggleable } from '../../../hooks';
import { getTotalPrice } from '../../../utils';
import { parse } from 'date-fns';
var reducer = function (state, action) {
    var _c, _d, _e, _f, _g;
    if (action.type === 'set') {
        if (isRoundTrip(action.value)) {
            var newState = __assign({}, state);
            for (var key in newState) {
                newState = __assign(__assign({}, newState), (_c = {}, _c[key] = null, _c));
            }
            return __assign(__assign({}, newState), (_d = {}, _d[action.key] = action.value, _d));
        }
        else {
            var newState = __assign({}, state);
            for (var key in newState) {
                if (newState[key] && isRoundTrip(newState[key])) {
                    newState = __assign(__assign({}, newState), (_e = {}, _e[key] = null, _e));
                }
            }
            return __assign(__assign({}, newState), (_f = {}, _f[action.key] = action.value, _f));
        }
    }
    else if (action.type === 'clear') {
        var clearState = __assign({}, state);
        for (var key in clearState) {
            clearState = __assign(__assign({}, clearState), (_g = {}, _g[key] = null, _g));
        }
        return clearState;
    }
    return state;
};
export var useAeroexpressState = function (trips, selectedTrips) {
    var initialState = useMemo(function () {
        var state = {};
        trips.forEach(function (trip) {
            var _c;
            var _d;
            var key = trip.fromStation.direction;
            if (!state[key]) {
                var selectedTrip = (_d = selectedTrips.find(function (selectedTrip) { return selectedTrip.id === trip.id; })) !== null && _d !== void 0 ? _d : null;
                state = __assign(__assign({}, state), (_c = {}, _c[key] = selectedTrip, _c));
            }
        });
        return state;
    }, [trips, selectedTrips]);
    var _c = __read(useReducer(reducer, initialState), 2), state = _c[0], dispatch = _c[1];
    var _d = useToggleable(false), allPassengers = _d.isOpen, toggleAllPassengers = _d.toggle;
    var tripsByDirection = useMemo(function () {
        var tripsByDirectionMap = new Map();
        trips.forEach(function (trip) {
            var _c;
            var key = trip.fromStation.direction;
            tripsByDirectionMap.set(key, __spreadArray(__spreadArray([], __read(((_c = tripsByDirectionMap.get(key)) !== null && _c !== void 0 ? _c : [])), false), [trip], false));
        });
        return __spreadArray([], __read(tripsByDirectionMap.entries()), false).sort(function (_c, _d) {
            var _e = __read(_c, 2), _a = _e[0], tripsA = _e[1];
            var _f = __read(_d, 2), _b = _f[0], tripsB = _f[1];
            var tripA = tripsA[0];
            var tripB = tripsB[0];
            if (isRoundTrip(tripA) || isRoundTrip(tripB)) {
                return 1;
            }
            var departureDateA = (tripA === null || tripA === void 0 ? void 0 : tripA.departureDate)
                ? parse(tripsA[0].departureDate, 'HH:mm dd.MM.yyyy', new Date())
                : null;
            var departureDateB = (tripB === null || tripB === void 0 ? void 0 : tripB.departureDate)
                ? parse(tripsB[0].departureDate, 'HH:mm dd.MM.yyyy', new Date())
                : null;
            return departureDateA > departureDateB ? 1 : -1;
        });
    }, [trips]);
    var selectedTripItems = useMemo(function () {
        var selectedItems = [];
        Object.keys(state).forEach(function (key) {
            var item = state[key];
            if (item) {
                selectedItems = __spreadArray(__spreadArray([], __read(selectedItems), false), [item], false);
            }
        });
        return selectedItems;
    }, [state, trips]);
    var totalPrice = useMemo(function () {
        if (selectedTripItems.length) {
            return getTotalPrice(selectedTripItems, function (item) { return item.price; });
        }
        return null;
    }, [selectedTripItems]);
    var setTrip = function (key, trip) {
        dispatch({ type: 'set', key: key, value: trip });
    };
    var clearTrips = function () {
        dispatch({ type: 'clear' });
    };
    return {
        state: state,
        tripsByDirection: tripsByDirection,
        selectedTripItems: selectedTripItems,
        totalPrice: totalPrice,
        allPassengers: allPassengers,
        setTrip: setTrip,
        clearTrips: clearTrips,
        toggleAllPassengers: toggleAllPassengers
    };
};
