import * as Flight from './components/DesktopFlight.css';
import * as Header from './components/Header/Header.css';
import * as ModalContent from './components/DesktopFlightModalContent/DesktopFlightModalContent.css';
import * as DesktopFlightPopup from './components/DesktopFlightPopup/DesktopFlightPopup.css';
import * as FlightInfoSegment from './components/FlightInfoSegment/FlightInfoSegment.css';
import * as Location from './components/Location/Location.css';
import * as DesktopFlightModalHeader from './components/DesktopFlightModalHeader/DesktopFlightModalHeader.css';
import * as DesktopFlightModalContentSelected from './components/DesktopFlightModalContentSelected/DesktopFlightModalContentSelected.css';
import * as SelectedFareGroup from './components/SelectedFareGroup/SelectedFareGroup.css';
export default {
    Flight: Flight,
    Header: Header,
    ModalContent: ModalContent,
    DesktopFlightPopup: DesktopFlightPopup,
    FlightInfoSegment: FlightInfoSegment,
    Location: Location,
    DesktopFlightModalHeader: DesktopFlightModalHeader,
    DesktopFlightModalContentSelected: DesktopFlightModalContentSelected,
    SelectedFareGroup: SelectedFareGroup
};
