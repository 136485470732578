import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
import { getIsNotNew, getIsBookedOrConfirmed, isRoundTrip as getIsRoundTrip } from '../../../store/order/selectors';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { DesktopSummaryFlight } from '../../../../SummaryFlight';
import { FlightClass } from '@websky/graphql';
import { useTranslation } from 'react-i18next';
var charterFareFamilyMock = {
    infoImage: '',
    description: '',
    title: 'charter',
    airline: { iata: null, icon: null, logo: null, name: 'null' },
    category: FlightClass.Economy,
    isRecommended: false,
    id: null,
    marker: '',
    options: [],
    promo: ''
};
var Flights = function (_a) {
    var order = _a.order, backToResults = _a.backToResults;
    var css = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation().t;
    var isRoundTrip = useSelector(getIsRoundTrip);
    var isNotNew = useSelector(getIsNotNew);
    var isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    return (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, order.flight.segmentGroups.map(function (group, index) {
        var _a;
        var _b;
        return (React.createElement(DesktopSummaryFlight, { key: index, orderId: order.id, flight: {
                id: order.flight.id,
                segments: group.segments.map(function (groupSegment) {
                    return order.flight.segments.find(function (segment) { return segment.segment.id === groupSegment.id; });
                })
            }, price: group.price, fareFamily: (_b = group === null || group === void 0 ? void 0 : group.fareFamily) !== null && _b !== void 0 ? _b : __assign(__assign({}, charterFareFamilyMock), { title: t('DesktopSummaryFlight:Charter') }), className: cn(css.flight, (_a = {},
                _a[css.flight_noMargin] = isNotNew,
                _a)), onChange: isBookedOrConfirmed ? null : backToResults, isReturnFlight: index === 1 && isRoundTrip }));
    })));
};
export default Flights;
