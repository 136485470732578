import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Datepicker as DatepickerComponent } from '../../../../../../../Datepicker/index';
import { ThemeProvider, useTheme } from '../../../../../../../theme';
import { mergeCSS } from '../../../../../../../utils';
var Datepicker = function (props) {
    var _a;
    var defaultDatepickerTheme = useTheme('Datepicker');
    var _b = useTheme('SearchFormDatepicker'), datepickerTheme = _b.SearchFormDatepickerStyles, customDatepickerTheme = __rest(_b, ["SearchFormDatepickerStyles"]);
    var customStyle = {
        Datepicker: __assign(__assign({}, customDatepickerTheme), { PopupDatepickerStyles: {
                block: props.popupDatepickerStyles
            } })
    };
    var defaultStyles = {
        Datepicker: __assign({}, defaultDatepickerTheme)
    };
    var styles = mergeCSS(defaultStyles, customStyle);
    return (React.createElement(ThemeProvider, { value: styles },
        React.createElement(DatepickerComponent, __assign({}, props, { highlightedDates: (_a = {},
                _a[props.highlightClassName] = props.availableDates,
                _a) })),
        props.renderFooter && props.renderFooter(props)));
};
export default Datepicker;
