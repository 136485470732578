import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import { useTranslation } from 'react-i18next';
import MediaQuery from '../MediaQuery/MediaQuery';
import Disclaimer from './сomponents/Disclaimer/Disclaimer';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
var AdditionalServiceBanner = function (_a) {
    var _b;
    var service = _a.service, disclaimer = _a.disclaimer;
    var css = useTheme('AdditionalServiceBanner').AdditionalServiceBannerStyles;
    var t = useTranslation().t;
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.baggageBanner] = service === 'baggage',
            _b[css.mealBanner] = service === 'meal',
            _b[css.seatBanner] = service === 'seat',
            _b[css.extrasBanner] = service === 'extras',
            _b)) },
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: css.text },
                React.createElement(MediaQuery, { minWidth: 'tablet' }, t(service === 'baggage'
                    ? 'BaggagePage:Transportation of impressions no limitations'
                    : service === 'seat'
                        ? 'SeatMap:The top ones get the top seats. Be on top!'
                        : service === 'meal'
                            ? 'MealPage:Feel the taste in flight'
                            : 'Checkin:Anything else for your trip?')),
                React.createElement(MediaQuery, { maxWidth: 'tablet' }, t(service === 'baggage'
                    ? 'BaggagePage:Baggage'
                    : service === 'seat'
                        ? 'SeatMap:Seats'
                        : service === 'meal'
                            ? 'MealPage:Meal'
                            : 'Checkin:Anything else for your trip?')),
                service !== 'extras' && (React.createElement(MediaQuery, { minWidth: "mobile" }, !!disclaimer ? (React.createElement(Tooltip, { title: disclaimer, placement: "bottom", classes: { tooltip: css.tooltip } },
                    React.createElement("div", { className: css.disclaimer__wrapper },
                        React.createElement(Disclaimer, { service: service })))) : (React.createElement(Disclaimer, { service: service })))))),
        React.createElement("div", { className: css.image }),
        React.createElement("div", { className: css.whiteLines },
            React.createElement("div", { className: css.whiteLine_wide }),
            React.createElement("div", { className: css.whiteLine_narrow }))));
};
export default AdditionalServiceBanner;
