import * as React from 'react';
import { useTheme } from '../../../../theme';
var Details = function (_a) {
    var items = _a.items;
    var css = useTheme('DesktopFlightInfo').Details;
    var groups = [];
    var chunkSize = 3;
    for (var i = 0, max = items.length; i < max; i += chunkSize) {
        groups.push(items.slice(i, i + chunkSize));
    }
    return (React.createElement("div", null, groups.map(function (group, index) { return (React.createElement("ul", { key: index, className: css.group }, group.map(function (item, index) { return (React.createElement("li", { key: index, className: css.item },
        React.createElement("span", { className: css.label }, item.label),
        React.createElement("span", { className: css.value }, item.value))); }))); })));
};
export default Details;
