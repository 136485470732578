import classnames from 'classnames';
import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { RendererFunctionsContext } from '../../../Datepicker';
export var Day = function (props) {
    var _a;
    var theme = useTheme('Datepicker').DayStyles;
    return (React.createElement("div", { className: classnames(theme.day, props.day.customClass, (_a = {},
            _a[theme.day_fromOtherMonth] = props.day.fromOtherMonth,
            _a[theme.day_selected] = props.day.isSelected && !props.day.fromOtherMonth,
            _a[theme.day_startPeriod] = props.day.isStartPeriod,
            _a[theme.day_inPeriod] = props.day.inPeriod,
            _a[theme.day_endPeriod] = props.day.isEndPeriod,
            _a[theme.day_notAvailable] = !props.day.isAvailable,
            _a[theme.day_weekend] = props.day.isWeekend,
            _a[theme.day_today] = props.day.isToday,
            _a[theme.day_highlighted] = props.day.customClass,
            _a[theme.day_startHover] = props.day.isStartHover,
            _a[theme.day_endHover] = props.day.isEndHover,
            _a)), onMouseOver: function () {
            if (props.day.isAvailable && props.onHover) {
                props.onHover(props.day.date);
            }
        }, onMouseOut: function () {
            if (props.day.isAvailable && props.onHover) {
                props.onHover(null);
            }
        }, onClick: function () {
            props.day.isAvailable ? props.onSelect(props.day.date) : null;
        } },
        React.createElement(RendererFunctionsContext.Consumer, null, function (data) {
            if (props.day.fromOtherMonth) {
                return null;
            }
            return data.dayRenderer(props.day.date, theme.day__inside);
        })));
};
