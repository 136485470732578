var _a;
import { __assign, __read, __spreadArray } from "tslib";
import { handleAction, handleActions } from 'redux-actions';
import { setStep, nextStep, prevStep, setSteps } from './actions';
import { StepType } from '../../types';
export var initialState = [
    {
        isActive: true,
        type: StepType.Passengers,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.Reason,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.AttachFiles,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.CreateTicket,
        additionalText: null
    },
    {
        isActive: false,
        type: StepType.Ticket,
        additionalText: null
    }
];
var handleSetSteps = handleAction(setSteps, function (state, _a) {
    var payload = _a.payload;
    return __spreadArray([], __read(payload), false);
}, initialState);
var handleSetStep = handleAction(setStep, function (state, _a) {
    var payload = _a.payload;
    return state.map(function (step) { return (__assign(__assign({}, step), { isActive: step.type === payload.type })); });
}, initialState);
var handleNextStep = handleAction(nextStep, function (state) {
    var currentActiveIndex = state.findIndex(function (step) { return step.isActive; });
    var nextStepIndex = currentActiveIndex + 1;
    var nextStep = state[nextStepIndex];
    return handleSetStep(state, setStep(nextStep));
}, initialState);
var handlePrevStep = handleAction(prevStep, function (state) {
    var currentActiveIndex = state.findIndex(function (step) { return step.isActive; });
    var prevStepIndex = currentActiveIndex - 1;
    var prevStep = state[prevStepIndex];
    return handleSetStep(state, setStep(prevStep));
}, initialState);
export default handleActions((_a = {},
    _a[setSteps.toString()] = handleSetSteps,
    _a[setStep.toString()] = handleSetStep,
    _a[nextStep.toString()] = handleNextStep,
    _a[prevStep.toString()] = handlePrevStep,
    _a), initialState);
