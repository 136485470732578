import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { getReadablePeriod } from '../../hooks';
import OrderCities from './OrderCities/OrderCities';
import { OrderCardContext } from '../OrderCard';
var OrderPreview = function (_a) {
    var _b;
    var className = _a.className, onClick = _a.onClick;
    var css = useTheme('OrderCard').OrderPreview;
    var order = useContext(OrderCardContext).order;
    var period = getReadablePeriod(order);
    var styles = useMemo(function () {
        var _a, _b, _c, _d;
        var segments = order.flight.segmentGroups[0].segments;
        var overlay = (_d = (_c = (_b = (_a = segments[segments.length - 1].arrival.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.images) === null || _c === void 0 ? void 0 : _c.panorama) === null || _d === void 0 ? void 0 : _d.url;
        if (overlay) {
            return {
                backgroundImage: "url('".concat(overlay, "')")
            };
        }
        else {
            return {};
        }
    }, [order.flight.segmentGroups]);
    return (React.createElement("div", { className: cn(css.root, className, (_b = {}, _b[css.clickable] = onClick, _b)), onClick: onClick, style: styles },
        React.createElement("div", { className: css.overlay }),
        React.createElement("div", { className: css.inner },
            React.createElement(OrderCities, { flight: order.flight }),
            React.createElement("div", { className: css.period }, period))));
};
export default OrderPreview;
