import LoyaltyInfo from './components/LoyaltyInfo.css';
import Card from './components/Card/Card.css';
import Menu from './components/Menu/Menu.css';
import FfpInfo from './components/FfpInfo/FfpInfo.css';
export default {
    LoyaltyInfo: LoyaltyInfo,
    Card: Card,
    Menu: Menu,
    FfpInfo: FfpInfo
};
