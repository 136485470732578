var _a;
import { __assign } from "tslib";
import { handleAction, handleActions } from 'redux-actions';
import { setDocuments } from './actions';
export var initialState = {
    0: []
};
var handleSetDocuments = handleAction(setDocuments, function (state, _a) {
    var payload = _a.payload;
    return (__assign({}, payload));
}, initialState);
export default handleActions((_a = {},
    _a[setDocuments.toString()] = handleSetDocuments,
    _a), initialState);
