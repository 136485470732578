import { __awaiter, __generator } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { OrderCard } from './OrderCard/OrderCard';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PaximizerCommunicationSectionEnum, useGetPaxOrderNotificationTeasersQuery } from '@websky/graphql';
import InfiniteScroll from 'react-infinite-scroller';
import { useCommunicationList } from '../../hooks';
import Loader from '../../../Loader';
import { CommunicationPageWrapper } from '../CommunicationPageWrapper/CommunicationPageWrapper';
export var OrdersPage = function () {
    var css = useTheme('Communications').Communication;
    var path = useRouteMatch().path;
    var _a = useGetPaxOrderNotificationTeasersQuery({
        skip: true
    }), loading = _a.loading, refetch = _a.refetch;
    var loadOrders = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data, notification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refetch({
                        offset: values.offset,
                        count: values.count
                    })];
                case 1:
                    data = (_a.sent()).data;
                    notification = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationOrderNotificationTeasers;
                    return [2 /*return*/, {
                            items: (notification === null || notification === void 0 ? void 0 : notification.items.length) ? notification.items : [],
                            total: (notification === null || notification === void 0 ? void 0 : notification.total) || 0
                        }];
            }
        });
    }); };
    var _b = useCommunicationList(PaximizerCommunicationSectionEnum.order_notifications, loadOrders, loading), readIds = _b.readIds, orders = _b.items, pagination = _b.pagination, loadMore = _b.loadMore, markAsRead = _b.markAsRead, errorMessage = _b.errorMessage;
    return (React.createElement(CommunicationPageWrapper, { type: PaximizerCommunicationSectionEnum.order_notifications, pagination: { total: pagination.total, hasMore: pagination.hasMore }, errorMessage: errorMessage },
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: pagination.hasMore, loader: React.createElement(Loader, { key: "orders-loader" }), pageStart: pagination.pageLoaded },
            React.createElement("div", { className: css.list }, orders.map(function (item) { return (React.createElement(Link, { key: item.id, to: "".concat(path, "/").concat(item.id, "?locator=").concat(item.locator) },
                React.createElement(OrderCard, { title: item.title, locator: item.locator, passengers: item.travellers, departureAirport: item.departureAirport.iata, arrivalAirport: item.arrivalAirport.iata, arrivalDate: item.arrivalDate, departureDate: item.departureDate, text: item.text, isRead: item.read || readIds[item.id], sendingDate: item.date, routeType: item.routeType, orderId: item.orderId, markAsRead: function () { return markAsRead(item.id); } }))); })))));
};
