import * as React from 'react';
import * as css from './Segment.css';
import TransferInfo from '../../../TransferInfo';
import MobileSegmentInfo from '../../../MobileSegmentInfo';
var Segment = function (_a) {
    var segment = _a.segment, transferDuration = _a.transferDuration, nextSegment = _a.nextSegment;
    return (React.createElement("div", { className: css.segment },
        React.createElement(MobileSegmentInfo, { showFullInfo: true, segments: [
                {
                    transferDuration: transferDuration,
                    segment: segment
                }
            ] }),
        transferDuration && nextSegment && (React.createElement(TransferInfo, { className: css.transfer, segment: segment, nextSegment: nextSegment, transferDuration: transferDuration }))));
};
export default Segment;
