import { __assign } from "tslib";
import { TravellerFieldEnum } from '@websky/graphql';
import { AdditionalTravellerFieldEnum } from '../../../../Passenger/types';
import i18n from 'i18next';
import { overridedIcons } from '../../../../Icons';
export var FieldsGroupType;
(function (FieldsGroupType) {
    FieldsGroupType["Identity"] = "Identity";
    FieldsGroupType["Documents"] = "Documents";
    FieldsGroupType["Loyalty"] = "Loyalty";
    FieldsGroupType["Contacts"] = "Contacts";
    FieldsGroupType["Changes"] = "Changes";
    FieldsGroupType["Other"] = "Other";
    FieldsGroupType["Accompanying"] = "accompanying";
})(FieldsGroupType || (FieldsGroupType = {}));
export var getGroupIcon = function (type) {
    switch (type) {
        case FieldsGroupType.Identity:
            return overridedIcons['Person'];
        case FieldsGroupType.Documents:
            return overridedIcons['Passport'];
        case FieldsGroupType.Loyalty:
            return overridedIcons['Star'];
        case FieldsGroupType.Other:
            return overridedIcons['DisabledMan'];
        default:
            return null;
    }
};
export var getGroupsMap = function (fields, passengerId, isChangePassengers) {
    var _a;
    var groups = (_a = {},
        _a[FieldsGroupType.Identity] = [],
        _a[FieldsGroupType.Documents] = [],
        _a[FieldsGroupType.Contacts] = [],
        _a[FieldsGroupType.Accompanying] = [],
        _a[FieldsGroupType.Loyalty] = [],
        _a[FieldsGroupType.Changes] = [],
        _a[FieldsGroupType.Other] = [],
        _a);
    fields.forEach(function (originalField) {
        var field = __assign(__assign({}, originalField), { name: "passengers[".concat(passengerId, "].").concat(originalField.name), placeholder: i18n.t("Passenger:".concat(originalField.placeholder)) });
        switch (field.type) {
            case TravellerFieldEnum.LastName:
            case TravellerFieldEnum.FirstName:
            case TravellerFieldEnum.MiddleName:
            case TravellerFieldEnum.BirthDate:
            case TravellerFieldEnum.Gender:
                groups[FieldsGroupType.Identity].push(field);
                break;
            case TravellerFieldEnum.Nationality:
            case TravellerFieldEnum.DocType:
            case TravellerFieldEnum.DocNumber:
            case TravellerFieldEnum.DocExpiryDate:
                groups[FieldsGroupType.Documents].push(field);
                break;
            case TravellerFieldEnum.LoyaltyNumber:
            case TravellerFieldEnum.LoyaltyAirline:
                groups[FieldsGroupType.Loyalty].push(field);
                break;
            case TravellerFieldEnum.Phone:
            case TravellerFieldEnum.Email:
                groups[FieldsGroupType.Contacts].push(field);
                break;
            case TravellerFieldEnum.LinkedTraveller:
                groups[FieldsGroupType.Accompanying].push(field);
                break;
            case AdditionalTravellerFieldEnum.ChangeTravellerFile:
                groups[FieldsGroupType.Changes].push(field);
                break;
            default:
                groups[FieldsGroupType.Other].push(field);
        }
    });
    var groupsArray = [];
    for (var group in groups) {
        if (groups.hasOwnProperty(group) && groups[group].length) {
            groupsArray.push({
                type: group,
                fields: groups[group]
            });
        }
    }
    if (isChangePassengers) {
        groupsArray = groupsArray.filter(function (group) { return ![FieldsGroupType.Loyalty, FieldsGroupType.Other].includes(group.type); });
    }
    return groupsArray;
};
