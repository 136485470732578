import { __read } from "tslib";
import * as React from 'react';
import { Locale } from '../../types';
import { useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '../../theme';
import { Button } from '../../index';
import { useTranslation } from 'react-i18next';
import FareConditions from './FareConditions/FareConditions';
import { useFareRulesQuery } from '@websky/graphql';
var FareConditionsWrapper = React.memo(function (_a) {
    var orderId = _a.orderId, flightId = _a.flightId, language = _a.language, segmentId = _a.segmentId, showTranslateTab = _a.showTranslateTab, onLangChange = _a.onLangChange;
    var _b = __read(React.useState(segmentId ? segmentId : ''), 2), segment = _b[0], setSegment = _b[1];
    var _c = __read(React.useState(Locale.Russian), 2), selectedLang = _c[0], selectLang = _c[1];
    var _d = useFareRulesQuery({
        variables: { flightId: flightId !== null && flightId !== void 0 ? flightId : orderId, orderId: orderId, language: language }
    }), data = _d.data, loading = _d.loading, error = _d.error, refetch = _d.refetch;
    if (error) {
        console.log(error);
    }
    var fareRulesBySegmentsPassengersMap = {}, hasErrors = error || (!loading && !data.FareRules);
    var firstPassengerInList = 0;
    var t = useTranslation('FareConditions').t;
    var css = useTheme('FareConditions').FareConditions;
    if (!loading && !hasErrors) {
        data.FareRules.rulesForSegments.forEach(function (ruleForSegment) {
            ruleForSegment.segments.forEach(function (segment) {
                if (fareRulesBySegmentsPassengersMap.hasOwnProperty(segment.id)) {
                    fareRulesBySegmentsPassengersMap[segment.id].push(ruleForSegment);
                }
                else {
                    fareRulesBySegmentsPassengersMap[segment.id] = [ruleForSegment];
                }
            });
        });
        if (segment === '') {
            // select first available segment if there is default value
            setSegment(Object.keys(fareRulesBySegmentsPassengersMap)[0]);
        }
    }
    var tabsSegments = useMemo(function () {
        return Object.keys(fareRulesBySegmentsPassengersMap).map(function (fareRuleKey) {
            var fareRule = fareRulesBySegmentsPassengersMap[fareRuleKey];
            var segment = fareRule[firstPassengerInList].segments.filter(function (segment) { return segment.id === fareRuleKey; })[0];
            return {
                id: segment.id,
                title: "".concat(segment.departure.airport.city.name, " \u2014 ").concat(segment.arrival.airport.city.name),
                isDisabled: false
            };
        });
    }, [fareRulesBySegmentsPassengersMap]);
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement("div", { className: css.loaderWrp },
            React.createElement(CircularProgress, { classes: { colorPrimary: css.loader } }))),
        !loading && hasErrors && (React.createElement("div", { className: css.errorWrp },
            React.createElement("h6", { className: css.errorTitle }, t('Internal error')),
            error && React.createElement("p", { className: css.errorText }, error.message),
            React.createElement(Button, { size: "small", color: "primary", onClick: function () { return refetch({ flightId: flightId, language: language }); } }, t('Try again')))),
        !loading && !hasErrors && (React.createElement(FareConditions, { fareRulesBySegmentsPassengersMap: fareRulesBySegmentsPassengersMap, fareRules: data, hasErrors: hasErrors, firstPassengerInList: firstPassengerInList, segment: segment, setSegment: setSegment, onLangChange: onLangChange, selectedLang: selectedLang, selectLang: selectLang, tabsSegments: tabsSegments, showTranslateTab: showTranslateTab }))));
});
export default FareConditionsWrapper;
