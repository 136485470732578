import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ModeContext, useConfig } from '../context';
import { getTravellers, getSelectedTravellerAnimalServices, getSelectedTravellerGroundTransportationServices, getSelectedTravellerPriorityBoarding } from '../Checkout/store/order/selectors';
import { getPriceToPay } from '../Checkout/store/orderPrice/selectors';
import { Mode } from '../types';
import { useContext } from 'react';
import { getPriceToPay as checkinGetPriceToPay, getTravellers as checkinGetTravellers } from '../Modules/Checkin/store/order/selectors';
import { getTotalPrice } from '../utils';
export var useCartPrices = function () {
    var servicesAvailable = useConfig().global.servicesAvailable;
    var mode = useContext(ModeContext);
    var priceToPay;
    var travellers;
    var animalSelectedServices;
    var transferSelectedServices;
    var prioritySelectedServices;
    if (mode === Mode.Checkin) {
        priceToPay = useSelector(checkinGetPriceToPay);
        travellers = useSelector(checkinGetTravellers);
    }
    else {
        priceToPay = useSelector(getPriceToPay);
        travellers = useSelector(getTravellers);
        animalSelectedServices = useSelector(getSelectedTravellerAnimalServices);
        transferSelectedServices = useSelector(getSelectedTravellerGroundTransportationServices);
        prioritySelectedServices = useSelector(getSelectedTravellerPriorityBoarding);
    }
    return React.useMemo(function () {
        // Baggage price calculation
        var baggagePrice = __assign({}, priceToPay.baggage);
        // [Animals]
        var animalsPrice = null;
        if (servicesAvailable.animal) {
            animalsPrice = getTotalPrice(animalSelectedServices, function (service) { return service.price; });
        }
        if (animalsPrice && animalsPrice.amount <= baggagePrice.amount) {
            baggagePrice = __assign(__assign({}, baggagePrice), { amount: baggagePrice.amount - animalsPrice.amount });
        }
        if (baggagePrice.amount === 0) {
            baggagePrice = null;
        }
        // Vip services calculation
        var vipPrice = __assign({}, priceToPay.vip);
        // [Transfer]
        var transferPrice = null;
        if (transferSelectedServices === null || transferSelectedServices === void 0 ? void 0 : transferSelectedServices.length) {
            transferPrice = getTotalPrice(transferSelectedServices, function (service) { return service.price; });
        }
        if (transferPrice && transferPrice.amount <= vipPrice.amount) {
            vipPrice = __assign(__assign({}, vipPrice), { amount: vipPrice.amount - transferPrice.amount });
        }
        // [Priority boarding]
        var priorityBoardingPrice = null;
        if (prioritySelectedServices === null || prioritySelectedServices === void 0 ? void 0 : prioritySelectedServices.length) {
            priorityBoardingPrice = getTotalPrice(prioritySelectedServices, function (service) { return service.price; });
        }
        if (priorityBoardingPrice && priorityBoardingPrice.amount <= vipPrice.amount) {
            vipPrice = __assign(__assign({}, vipPrice), { amount: vipPrice.amount - priorityBoardingPrice.amount });
        }
        if (vipPrice.amount === 0) {
            vipPrice = null;
        }
        return __assign(__assign({}, priceToPay), { vip: vipPrice, baggage: baggagePrice, animals: animalsPrice, transfer: transferPrice, priorityBoarding: priorityBoardingPrice });
    }, [priceToPay, travellers, animalSelectedServices, transferSelectedServices, prioritySelectedServices]);
};
