import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import StepsBreadcrumbs from '../Breadcrumbs/StepsBreadcrumbs';
import { StepType } from '../../types';
import { useSteps } from '../Refund/hooks';
import { useSelector } from 'react-redux';
import { getSelectedPassengers } from '../../store/passengers/selectors';
import { getSelectedReason } from '../../store/reason/selectors';
import { FullscreenDialogClose } from '../../../FullscreenDialog';
var Wrapper = function (props) {
    var css = useTheme('Refund').Wrapper;
    var t = useTranslation('Refund').t;
    var selectedPassengers = useSelector(getSelectedPassengers);
    var selectedReason = useSelector(getSelectedReason);
    var _a = useSteps(), currentStep = _a.currentStep, handleMobileCloseClick = _a.handleMobileCloseClick;
    return (React.createElement("div", { className: cn(css.wrapper, props.className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.header_wrapper },
                props.renderTitle ? props.renderTitle() : React.createElement("h3", { className: css.title }, t('Refund ticket')),
                !props.hideBreadcrumbs && (React.createElement(MediaQuery, { minWidth: 'mobile' },
                    React.createElement(StepsBreadcrumbs, { reason: currentStep.type !== StepType.Passengers ? selectedReason : null, passengers: selectedPassengers, onPassengerClick: handleMobileCloseClick(StepType.Passengers), onRefundInfoClick: handleMobileCloseClick(StepType.Reason) }))),
                React.createElement(FullscreenDialogClose, { onClose: props.onClose, text: t('Back to order'), textClassName: css.close__text }))),
        React.createElement("div", { className: css.container }, props.children)));
};
export default Wrapper;
