import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { AdditionalServiceType } from '../../types';
import { getInsurances, getIsConfirmed, getOrder } from '../../../Checkout/store/order/selectors';
import { getPassengerState } from '../../../Checkout/store/passengers/selectors';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { ThemeProvider, useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { useInsurance } from './hooks';
import { useIsMobile } from '../../../utils';
import { useRenderers } from '../../../renderProps';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import DialogContent from '../../../AdditionalServices/components/Insurance/DialogContent/DialogContent';
import InsuranceSummary from '../../../ServiceSummary/services/InsuranceSummary';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import DownloadInsurances from '../../../AdditionalServices/components/Insurance/DownloadInsurances/DownloadInsurances';
import WarningModal from '../../../WarningModal';
import InsurancePopup from '../../../InsurancePopup';
import Modal from '../../../Modal';
import Close from '../Close/Close';
import icons from '../../icons';
import { useInsuranceGroupTexts } from '../InsuranceUpsale/hooks';
import { analytics_removeInsurance } from '../../../Insurance/utils';
var InsuranceAdditionalService = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var insurancePrograms = _a.insurancePrograms, groupName = _a.groupName, asSingle = _a.asSingle;
    var t = useTranslation('Checkout').t;
    var cardStyles = useTheme('CheckoutAdditionalService').Insurance;
    var css = useTheme('AdditionalServices').Insurance;
    var texts = useInsuranceGroupTexts(groupName, insurancePrograms);
    var checkoutAdditionalService = useConfig().global.checkoutAdditionalService;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var isConfirmed = useSelector(getIsConfirmed);
    var insurances = useSelector(getInsurances);
    var passengers = useSelector(getPassengerState);
    var isMobile = useIsMobile();
    var _h = __read(useSaveOrderServicesMutation(), 2), saveOrderServicesMutation = _h[0], loading = _h[1].loading;
    var onUpdateOrder = function (data) {
        dispatch(fillOrder(data.SaveOrderServices));
    };
    var _j = useInsurance({
        orderId: order.id,
        insurances: insurances,
        insurancePrograms: insurancePrograms,
        passengers: passengers,
        onSaveServices: saveOrderServicesMutation,
        onUpdateOrder: onUpdateOrder
    }), 
    // common
    multiInsurance = _j.multiInsurance, selectedInsurances = _j.selectedInsurances, buttons = _j.buttons, minPrice = _j.minPrice, totalPrice = _j.totalPrice, error = _j.error, onSave = _j.onSave, onAddInsurance = _j.onAddInsurance, onRemoveInsurance = _j.onRemoveInsurance, clearError = _j.clearError, 
    // insurance modal
    insuranceModalIsOpen = _j.insuranceModalIsOpen, closeInsuranceModal = _j.closeInsuranceModal, 
    // confirmation modal
    confirmationModalIsOpen = _j.confirmationModalIsOpen, closeConfirmationModal = _j.closeConfirmationModal, 
    // download modal
    downloadModalIsOpen = _j.downloadModalIsOpen, closeDownloadModal = _j.closeDownloadModal;
    var hasInsurancePriceToPay = ((_b = order.priceToPay) === null || _b === void 0 ? void 0 : _b.insurance) !== null;
    var byCodeMap = (_d = (_c = checkoutAdditionalService === null || checkoutAdditionalService === void 0 ? void 0 : checkoutAdditionalService.insurance) === null || _c === void 0 ? void 0 : _c.insuranceProgramCodeMap) === null || _d === void 0 ? void 0 : _d[insurancePrograms[0].code];
    var headerIcon = React.useMemo(function () {
        if (asSingle && (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.headerIcon)) {
            return byCodeMap.headerIcon;
        }
        return icons.insuranceIcon;
    }, [asSingle, byCodeMap]);
    var isSingleNotSelected = asSingle && !selectedInsurances.length;
    var style = React.useMemo(function () {
        if (isSingleNotSelected && (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.color)) {
            return { background: byCodeMap.color };
        }
        return null;
    }, [isSingleNotSelected, byCodeMap]);
    var endAdornment = React.useMemo(function () {
        if (isSingleNotSelected && (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.contentIcon)) {
            return React.createElement("div", { className: cardStyles.serviceWrapperEndAdornment }, byCodeMap.contentIcon);
        }
        return null;
    }, [isSingleNotSelected, byCodeMap]);
    var header = React.useMemo(function () {
        if (asSingle) {
            var insuranceProgram = insurancePrograms[0];
            var isImportant = insuranceProgram.important;
            if (isImportant && insuranceProgram.name) {
                return insuranceProgram.name;
            }
            else if (byCodeMap === null || byCodeMap === void 0 ? void 0 : byCodeMap.header) {
                return byCodeMap.header;
            }
        }
        return texts.title;
    }, [asSingle, insurancePrograms, byCodeMap, texts]);
    var renderCloseButton = function (onClick) {
        return React.createElement(Close, { onClick: onClick });
    };
    var onInsuranceItemRemove = function (_, payload) { return __awaiter(void 0, void 0, void 0, function () {
        var insuranceService, filterCodes, deletingProgram;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    insuranceService = payload;
                    filterCodes = insurances.selectedInsurances
                        .filter(function (insurance) { return insurance.insuranceProgram.code !== insuranceService.code; })
                        .map(function (insurance) { return insurance.insuranceProgram.code; });
                    deletingProgram = (_a = insurances.selectedInsurances.find(function (selected) { return selected.insuranceProgram.code === payload.code; })) === null || _a === void 0 ? void 0 : _a.insuranceProgram;
                    if (deletingProgram) {
                        analytics_removeInsurance(deletingProgram, order.travellers);
                    }
                    return [4 /*yield*/, onSave(filterCodes)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_e = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _e !== void 0 ? _e : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(InsuranceSummary, { flight: order.flight, travellers: order.travellers, services: __assign(__assign({}, order.additionalServices), { insurances: __assign(__assign({}, order.additionalServices.insurances), { insurancePrograms: insurancePrograms }) }), onServiceDelete: hasInsurancePriceToPay ? onInsuranceItemRemove : null, isConfirmed: isConfirmed }, function (selectedInsurances) {
            var _a;
            return (React.createElement(ServiceWrapper, { style: style, icon: headerIcon, className: cn(cardStyles.insurance, insurancePrograms[0].code, (_a = {},
                    _a[cardStyles.withEndAdornment] = !!endAdornment,
                    _a)), insuranceCode: insurancePrograms[0].code, isLoading: loading, addClick: onAddInsurance, header: header, isSelected: !!selectedInsurances.length, priceFrom: minPrice, priceTotal: totalPrice, description: texts.descr, buttons: buttons, endAdornment: endAdornment, serviceType: AdditionalServiceType.Insurance, servicesCount: insurancePrograms.length },
                React.createElement(ThemeProvider, { value: {
                        ServiceSummary: {
                            ServiceSummaryOption: {
                                option: cardStyles.selectedOption,
                                option__name: cardStyles.option__name
                            }
                        }
                    } },
                    React.createElement(ServiceSummaryDivider, null,
                        React.createElement(ServiceSummaryOption, { groupOption: {
                                items: selectedInsurances
                            } }))),
                selectedInsurances.length === 1 && (React.createElement("div", { dangerouslySetInnerHTML: { __html: selectedInsurances[0].description } }))));
        }),
        multiInsurance && (React.createElement(Modal, { classes: {
                scrollBody: css.scrollBody,
                paper: css.paper
            }, open: insuranceModalIsOpen, slideUp: isMobile, onClose: closeInsuranceModal, closeButtonRenderer: renderCloseButton },
            React.createElement(InsurancePopup, { header: header, description: (_f = insurancePrograms[0]) === null || _f === void 0 ? void 0 : _f.description, insurances: insurancePrograms, selectedInsurances: selectedInsurances, onClose: closeInsuranceModal, onSave: onSave, isLoading: loading, order: order }))),
        React.createElement(Modal, { maxWidth: "sm", classes: { scrollBody: css.scrollBody, paper: cn(css.paper, css.paper_confirm) }, open: confirmationModalIsOpen, onClose: closeConfirmationModal, slideUp: isMobile },
            React.createElement(DialogContent, { closeConfirmationPopup: closeConfirmationModal, onInsuranceRemove: onRemoveInsurance, refusingIsInProgress: loading })),
        React.createElement(Modal, { maxWidth: "sm", classes: { scrollBody: css.scrollBody, paper: cn(css.paper, css.paper_download) }, open: downloadModalIsOpen, onClose: closeDownloadModal, slideUp: isMobile },
            React.createElement(DownloadInsurances, { selectedInsurances: selectedInsurances })),
        React.createElement(WarningModal, { title: t('Oops, something went wrong'), content: t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.'), errorMessage: t((_g = error === null || error === void 0 ? void 0 : error.message) !== null && _g !== void 0 ? _g : ''), isOpen: !!error, onClose: clearError, onButtonClick: clearError })));
};
export default InsuranceAdditionalService;
