export var Steps;
(function (Steps) {
    Steps[Steps["LogIn"] = 0] = "LogIn";
    Steps[Steps["EnterCode"] = 1] = "EnterCode";
    Steps[Steps["Registration"] = 2] = "Registration";
    Steps[Steps["SelectEmailOrFFP"] = 3] = "SelectEmailOrFFP";
    Steps[Steps["WithoutFFP"] = 4] = "WithoutFFP";
    Steps[Steps["Welcome"] = 5] = "Welcome";
})(Steps || (Steps = {}));
export var LoginInputType;
(function (LoginInputType) {
    LoginInputType["Email"] = "Email";
    LoginInputType["Phone"] = "Phone";
    LoginInputType["Text"] = "Text";
})(LoginInputType || (LoginInputType = {}));
