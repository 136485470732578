import { __read, __values } from "tslib";
import { useEffect, useReducer } from 'react';
import { initialStateFactory } from './initialStateFactory';
import reducer from './reducer';
import { useVariables } from './variables';
import { useMethods } from './methods';
export * from './types';
export var useTransferService = function (order, onChange, onStateChanged, activeSegment) {
    var _a = __read(useReducer(reducer, order, initialStateFactory), 2), state = _a[0], dispatch = _a[1];
    var variables = useVariables(order, state, activeSegment);
    var methods = useMethods(dispatch);
    useEffect(function () {
        var e_1, _a, e_2, _b, e_3, _c;
        var servicesToSave = { id: order.id, services: [] };
        try {
            for (var _d = __values(Object.keys(state.services)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var _f = __read(_e.value, 1), passengerId = _f[0];
                var travellerSetServices = [];
                try {
                    for (var _g = (e_2 = void 0, __values(Object.keys(state.services[passengerId]))), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var _j = __read(_h.value, 1), segmentId = _j[0];
                        try {
                            for (var _k = (e_3 = void 0, __values(state.services[passengerId][segmentId])), _l = _k.next(); !_l.done; _l = _k.next()) {
                                var service = _l.value;
                                if (service.selected || service.confirmed) {
                                    travellerSetServices.push({
                                        serviceId: service.service.id,
                                        segmentIds: [segmentId],
                                        count: 1
                                    });
                                }
                                else {
                                    travellerSetServices.push({
                                        serviceId: service.service.id,
                                        segmentIds: [segmentId],
                                        count: 0
                                    });
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                servicesToSave.services.push({
                    travellerId: passengerId,
                    gdsServices: {
                        setServices: travellerSetServices
                    }
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        onChange(servicesToSave);
    }, [state.services]);
    useEffect(function () {
        onStateChanged(variables.stateChanged);
    }, [variables.stateChanged]);
    return {
        activeService: state.activeService,
        services: state.services,
        transferServices: variables.transferServices,
        segmentsById: variables.segmentsById,
        passengersById: variables.passengersById,
        selectedServices: variables.selectedServicesIds,
        activeSegmentServices: variables.activeSegmentServices,
        setActiveService: methods.setActiveService,
        toggleService: methods.toggleService,
        onChangeService: methods.setServiceState
    };
};
