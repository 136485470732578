import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import Gateway from '../Gateway';
import { useTheme } from '../../../../theme';
import { PaymentType } from '../../../types';
import Button from '../../../../Button';
import Input from '../../../../Input';
import { Money } from '../../../../Money';
var Invoice = function (_a) {
    var children = _a.children, isPreOpened = _a.isPreOpened, frameInfo = _a.frameInfo, headerTitle = _a.headerTitle, type = _a.type, onCompletePayment = _a.onCompletePayment, onClick = _a.onClick;
    var css = useTheme('PaymentForm').Invoice;
    var t = useTranslation('PaymentForm').t;
    var onCompletePaymentHandler = useCallback(function (values) {
        return onCompletePayment(__assign({ transactionId: frameInfo.transactionId }, values));
    }, [onCompletePayment, frameInfo]);
    var onClickGateway = useCallback(function () {
        return onClick({
            title: headerTitle,
            type: PaymentType.Invoice,
            content: (React.createElement(Form, { onSubmit: onCompletePaymentHandler, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (React.createElement("div", { className: css.content },
                        React.createElement("form", { onSubmit: handleSubmit },
                            React.createElement(Field, { name: "additionalText", render: function (_a) {
                                    var input = _a.input;
                                    return (React.createElement(Input, __assign({}, input, { TextFieldProps: {
                                            label: t('Payment order numbers'),
                                            onChange: input.onChange
                                        } })));
                                } }),
                            React.createElement("div", { className: css.footer },
                                React.createElement(Button, { type: "submit" },
                                    t('Pay'),
                                    React.createElement(Money, { money: frameInfo.totalPrice }))))));
                } }))
        });
    }, [onCompletePayment, onClick, frameInfo]);
    useEffect(function () {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { header: children, onClick: onClickGateway, type: type, paymentType: PaymentType.Invoice });
};
export default Invoice;
