import { __assign, __read } from "tslib";
import { useToggleable } from '../hooks';
import { useState } from 'react';
export var useSecureAccess = function () {
    var _a = useToggleable(false), isOpen = _a.isOpen, close = _a.close, open = _a.open;
    var _b = __read(useState(), 2), submitParams = _b[0], setSubmitParams = _b[1];
    var _c = __read(useState(false), 2), showEror = _c[0], setShowError = _c[1];
    // @ts-ignore
    var setSecret2fa = function (secret2fa) { return setSubmitParams(function (params) { return ({ params: __assign(__assign({}, params), { secret2fa: secret2fa }) }); }); };
    var isNeedToOpenSecureAccessModal = function (errors) {
        if (!(errors === null || errors === void 0 ? void 0 : errors.length)) {
            return false;
        }
        return errors.some(function (responseError) {
            return [responseError.message, responseError['debugMessage']].includes('PNR is locked. Try to unlock with data from e-mail');
        });
    };
    var isFindGroupOrderVariablesGuard = function (params) {
        return 'travellersDataForSplit' in params.params;
    };
    return {
        isOpen: isOpen,
        open: open,
        close: close,
        submitParams: submitParams,
        setSubmitParams: setSubmitParams,
        setSecret2fa: setSecret2fa,
        isNeedToOpenSecureAccessModal: isNeedToOpenSecureAccessModal,
        isFindGroupOrderVariablesGuard: isFindGroupOrderVariablesGuard,
        showEror: showEror,
        setShowError: setShowError
    };
};
