var _a;
import { handleActions } from 'redux-actions';
import { SET_REDEEM_MILES, TOGGLE_REDEEM_MILES } from './actions';
export var redeemMilesReducer = handleActions((_a = {},
    _a[TOGGLE_REDEEM_MILES] = function (state) {
        return !state;
    },
    _a[SET_REDEEM_MILES] = function (state, action) {
        return action.payload;
    },
    _a), false);
