import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoDetails } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useToggleable } from '../../../../../hooks';
import Button from '../../../../../Button';
import Conditions from './Conditions/Conditions';
import ModalX from '../../../../../BaseComponents/ModalX';
var ConditionsModal = function (_a) {
    var _b;
    var renderTrigger = _a.renderTrigger;
    var t = useTranslation('CheckinConditions').t;
    var theme = useTheme('Checkin').ConditionsModal;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ onClick: open })) !== null && _b !== void 0 ? _b : (React.createElement(Button, { variant: "text", className: theme.button, onClick: open },
        React.createElement("div", { className: theme.icon }, InfoDetails),
        t('Check-in conditions'))),
        React.createElement(ModalX, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, isOpen: isOpen, onClose: close },
            React.createElement(Conditions, { className: theme.conditions }))));
};
export default ConditionsModal;
