import * as React from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../../theme';
import Autocomplete from '../../../../Autocomplete';
import { useNationality } from './hooks';
var Nationality = memo(function (_a) {
    var label = _a.label, field = _a.field, fieldState = _a.fieldState, onChange = _a.onChange, className = _a.className, error = _a.error, onBlur = _a.onBlur, disabled = _a.disabled, variant = _a.variant, options = _a.options, autofocus = _a.autofocus;
    var inputCss = useTheme('Input').Input;
    var model = useNationality({ options: options, onChange: onChange, field: field, fieldState: fieldState });
    var autocompleteTheme = useMemo(function () { return ({
        Autocomplete: {
            Autocomplete: {
                option: inputCss.optionLabel,
                disabled: inputCss.disabled,
                inner_input: inputCss.input
            }
        }
    }); }, []);
    return (React.createElement(ThemeProvider, { value: autocompleteTheme },
        React.createElement(Autocomplete, { name: field.name, onFocus: model.handleFocus, label: label, variant: variant, autofocus: autofocus, callOnSelectOn: 'mouseDown', onClickOutsideProps: {
                outsideClickIgnoreClass: 'MuiInputBase-input'
            }, inputValue: model.value, onSelect: model.handleSelect, onBlur: model.handleBlur, onInputChange: model.handleChange, options: model.options, className: cn(inputCss.nationality, className), error: error, disabled: disabled })));
});
export default Nationality;
