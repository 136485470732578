import { __extends, __read, __spreadArray } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
import { ThemeConsumer } from '../../theme';
import { createWithThemeDecorator } from '../../utils';
var Slider = /** @class */ (function (_super) {
    __extends(Slider, _super);
    function Slider(props) {
        var _this = _super.call(this, props) || this;
        _this.sliderInsideRef = null;
        _this.sliderRef = null;
        _this.leftArrowRef = null;
        _this.rightArrowRef = null;
        _this.sliderOffset = 0;
        _this.sliderWidth = 0;
        _this.sliderWrapperWidth = 0;
        _this.itemWidth = 1;
        _this.itemsCount = 0;
        _this.visibleItemsCount = 0;
        _this.touchInitX = 0;
        _this.touchBegin = false;
        _this.itemsBeyondRightEdge = _this.itemsBeyondRightEdge.bind(_this);
        _this.itemsBeyondLeftEdge = _this.itemsBeyondLeftEdge.bind(_this);
        _this.startTouch = _this.startTouch.bind(_this);
        _this.endTouch = _this.endTouch.bind(_this);
        _this.touchMove = _this.touchMove.bind(_this);
        _this.screenResize = _this.screenResize.bind(_this);
        _this.slideRight = _this.slideRight.bind(_this);
        _this.slideLeft = _this.slideLeft.bind(_this);
        return _this;
    }
    /**
     * Get items count on beyond the right edge
     */
    Slider.prototype.itemsBeyondRightEdge = function () {
        return Math.round((this.sliderWrapperWidth + (this.sliderOffset - this.sliderWidth)) / this.itemWidth);
    };
    /**
     * Get items count on beyond the left edge
     */
    Slider.prototype.itemsBeyondLeftEdge = function () {
        return Math.round(Math.abs(this.sliderOffset / this.itemWidth));
    };
    Slider.prototype.calculateSizes = function () {
        if (this.sliderRef && this.sliderInsideRef) {
            this.sliderWidth = this.sliderRef.offsetWidth;
            this.sliderWrapperWidth = this.sliderInsideRef.offsetWidth;
            if (this.props.maxItemsCount) {
                this.itemWidth = this.calculateItemWidth(this.props.maxItemsCount);
            }
            else {
                this.itemWidth = this.sliderInsideRef.offsetWidth / this.itemsCount;
            }
            this.visibleItemsCount = Math.round(this.sliderWidth / this.itemWidth);
        }
    };
    // Touch events
    Slider.prototype.startTouch = function (e) {
        var _a;
        var _b, _c;
        var theme = this.props.theme.Slider.SliderTheme;
        var inside = (_c = (_b = theme === null || theme === void 0 ? void 0 : theme.inside_transition) === null || _b === void 0 ? void 0 : _b.split(' ')) !== null && _c !== void 0 ? _c : [];
        this.touchBegin = true;
        (_a = this.sliderInsideRef.classList).remove.apply(_a, __spreadArray([], __read(inside), false));
        this.touchInitX = e.touches[0].clientX;
    };
    Slider.prototype.endTouch = function () {
        var _a;
        var _b, _c;
        var theme = this.props.theme.Slider.SliderTheme;
        var insideTransition = (_c = (_b = theme === null || theme === void 0 ? void 0 : theme.inside_transition) === null || _b === void 0 ? void 0 : _b.split(' ')) !== null && _c !== void 0 ? _c : [];
        this.touchBegin = false;
        (_a = this.sliderInsideRef.classList).add.apply(_a, __spreadArray([], __read(insideTransition), false));
        this.touchInitX = 0;
        this.offsetCorrection();
    };
    Slider.prototype.touchMove = function (e) {
        if (this.touchBegin) {
            e.preventDefault();
            var delta = e.touches[0].clientX - this.touchInitX;
            if (this.props.touchTransition === 'default') {
                this.setOffset(this.sliderOffset + delta);
                this.touchInitX = e.touches[0].clientX;
            }
            else if (this.props.touchTransition === 'by-items') {
                if (Math.abs(delta) > this.props.minItemWidth / 1.5) {
                    delta > 0 ? this.slideLeft(1) : this.slideRight(1);
                    this.touchInitX = e.touches[0].clientX;
                }
            }
        }
    };
    Slider.prototype.offsetCorrection = function () {
        if (this.sliderOffset > 0) {
            this.setOffset(0);
        }
        else if (this.itemsBeyondRightEdge() < 0) {
            this.setOffset(this.sliderWidth - this.sliderWrapperWidth);
        }
    };
    Slider.prototype.screenResize = function () {
        this.calculateSizes();
        if (this.props.leftStartItem) {
            this.setInLeftView(this.props.leftStartItem);
        }
        else if (this.props.centeredItem) {
            this.setInCenterView(this.props.centeredItem);
        }
        if (this.props.onItemCentered) {
            this.props.onItemCentered(Math.round(this.itemsBeyondLeftEdge()), this.visibleItemsCount, this.itemsBeyondRightEdge());
        }
    };
    Slider.prototype.calculateItemWidth = function (itemsCount) {
        var itemSize = Math.round(this.sliderWidth / itemsCount);
        if (this.props.minItemWidth && itemSize < this.props.minItemWidth) {
            return this.calculateItemWidth(itemsCount - 1);
        }
        this.setItemsWidth(itemSize);
        return itemSize;
    };
    Slider.prototype.setItemsWidth = function (size) {
        if (this.sliderInsideRef) {
            Array.from(this.sliderInsideRef.childNodes[0].childNodes).forEach(function (node) {
                node.style.width = "".concat(size, "px");
            });
        }
    };
    Slider.prototype.componentDidMount = function () {
        this.itemsCount = this.sliderInsideRef.childNodes[0].childNodes.length;
        this.calculateSizes();
        window.addEventListener('resize', this.screenResize);
        if (this.props.touchEvents && this.sliderRef) {
            this.sliderRef.addEventListener('touchstart', this.startTouch);
            this.sliderRef.addEventListener('touchmove', this.touchMove);
            this.sliderRef.addEventListener('touchend', this.endTouch);
        }
        this.screenResize();
        this.arrowAvailabilityCheck();
    };
    Slider.prototype.componentDidUpdate = function () {
        if (this.props.leftStartItem >= 0) {
            this.setInLeftView(this.props.leftStartItem);
        }
        else if (this.props.centeredItem >= 0) {
            this.setInCenterView(this.props.centeredItem);
        }
        this.arrowAvailabilityCheck();
    };
    Slider.prototype.slideRight = function (neededOffset) {
        var itemsCount = this.itemsBeyondRightEdge(), itemsOffset = neededOffset
            ? neededOffset
            : this.props.offset
                ? this.props.offset
                : Math.round(this.visibleItemsCount / 2);
        var offset = this.sliderOffset;
        if (this.sliderInsideRef && itemsCount > 0) {
            offset -= this.itemWidth * (itemsCount > itemsOffset ? itemsOffset : itemsCount);
            this.setOffset(offset);
        }
        if (this.props.onRangeChange) {
            this.props.onRangeChange('next', Math.round(this.itemsBeyondLeftEdge()), this.visibleItemsCount, itemsCount);
        }
    };
    Slider.prototype.slideLeft = function (neededOffset) {
        var itemsCount = this.itemsBeyondLeftEdge(), itemsOffset = neededOffset
            ? neededOffset
            : this.props.offset
                ? this.props.offset
                : Math.round(this.visibleItemsCount / 2);
        var offset = this.sliderOffset;
        if (this.sliderInsideRef && itemsCount > 0) {
            offset += this.itemWidth * (itemsCount > itemsOffset ? itemsOffset : itemsCount);
            this.setOffset(offset);
        }
        if (this.props.onRangeChange) {
            this.props.onRangeChange('prev', Math.round(this.itemsBeyondLeftEdge()), this.visibleItemsCount, itemsCount);
        }
    };
    /**
     * Set item in center of view
     * @param {number} item
     */
    Slider.prototype.setInCenterView = function (item) {
        var itemsCountInView = Math.round(this.sliderWidth / this.itemWidth), itemsHalfCountInView = Math.round(itemsCountInView / 2), itemsCountBefore = item - itemsHalfCountInView;
        var offset = 0;
        if (itemsCountBefore > 0) {
            offset = itemsCountBefore;
            if (this.itemsCount - offset < itemsCountInView) {
                offset = this.itemsCount - itemsCountInView;
            }
        }
        this.setOffset(offset * this.itemWidth * -1);
    };
    Slider.prototype.setInLeftView = function (item) {
        this.setOffset(item * this.itemWidth * -1);
    };
    Slider.prototype.setOffset = function (offset) {
        if (this.sliderInsideRef) {
            this.sliderInsideRef.style.left = "".concat(offset, "px");
        }
        this.sliderOffset = offset;
        this.arrowAvailabilityCheck();
    };
    Slider.prototype.arrowAvailabilityCheck = function () {
        var _a, _b, _c, _d;
        var _e, _f;
        var theme = this.props.theme.Slider.SliderTheme;
        var hide = (_f = (_e = theme === null || theme === void 0 ? void 0 : theme.hide) === null || _e === void 0 ? void 0 : _e.split(' ')) !== null && _f !== void 0 ? _f : [];
        if (!this.props.alwaysShowArrows) {
            if (this.props.hideDisabledLeftArrow && this.itemsBeyondLeftEdge() <= 0) {
                (_a = this.leftArrowRef.classList).add.apply(_a, __spreadArray([], __read(hide), false));
            }
            else {
                (_b = this.leftArrowRef.classList).remove.apply(_b, __spreadArray([], __read(hide), false));
            }
            if (this.props.hideDisabledRightArrow && this.itemsBeyondRightEdge() <= 0) {
                (_c = this.rightArrowRef.classList).add.apply(_c, __spreadArray([], __read(hide), false));
            }
            else {
                (_d = this.rightArrowRef.classList).remove.apply(_d, __spreadArray([], __read(hide), false));
            }
        }
    };
    Slider.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.screenResize);
        if (this.props.touchEvents && this.sliderRef) {
            this.sliderRef.removeEventListener('touchstart', this.startTouch);
            this.sliderRef.removeEventListener('touchmove', this.touchMove);
            this.sliderRef.removeEventListener('touchend', this.endTouch);
        }
    };
    Slider.prototype.render = function () {
        var _this = this;
        var theme = this.props.theme.Slider.SliderTheme;
        return (React.createElement("div", { className: theme.container },
            React.createElement("div", { className: classnames(theme.arrow, theme.arrow_next), ref: function (ref) { return (_this.rightArrowRef = ref); }, onClick: function () { return _this.slideRight(); } }),
            React.createElement("div", { className: classnames(theme.arrow, theme.arrow_prev), ref: function (ref) { return (_this.leftArrowRef = ref); }, onClick: function () { return _this.slideLeft(); } }),
            React.createElement("div", { ref: function (ref) { return (_this.sliderRef = ref); }, className: theme.slider },
                React.createElement("div", { ref: function (ref) { return (_this.sliderInsideRef = ref); }, className: classnames(theme.inside, theme.inside_transition) },
                    React.createElement("div", { ref: this.props.innerRef, className: classnames(theme.elementsWrapper, this.props.wrapperClassName) }, this.props.children)))));
    };
    Slider.displayName = 'Slider';
    return Slider;
}(React.Component));
export default createWithThemeDecorator(ThemeConsumer)(Slider);
