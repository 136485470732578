import { __assign } from "tslib";
import * as React from 'react';
import { memo } from 'react';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import Toolbar from '../../Toolbar';
import MobileToolbar from '../../MobileToolbar';
var ServicesToolbar = memo(function (_a) {
    var toolbarProps = _a.toolbarProps, mobileToolbarProps = _a.mobileToolbarProps, children = _a.children;
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Toolbar, __assign({}, toolbarProps))),
        mobileToolbarProps && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileToolbar, __assign({}, mobileToolbarProps))))));
});
export default ServicesToolbar;
