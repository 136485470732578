import * as FareGroup from './components/FareGroup.css';
import * as RecommendedBadge from './components/RecommendedBadge/RecommendedBadge.css';
import * as FareGroupGrid from './components/FareGroupGrid/FareGroupGrid.css';
import * as FareGroupIcon from './components/FareGroupIcon/FareGroupIcon.css';
export default {
    FareGroup: FareGroup,
    FareGroupGrid: FareGroupGrid,
    RecommendedBadge: RecommendedBadge,
    FareGroupIcon: FareGroupIcon
};
