import * as React from 'react';
import Link from '../../../Link';
import { infoIcon } from '../../../Warnings/Icons';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context';
var Disclaimer = function (_a) {
    var service = _a.service;
    var css = useTheme('AdditionalServiceBanner').AdditionalServiceBannerStyles;
    var t = useTranslation().t;
    var companyInfo = useConfig().global.companyInfo;
    var link;
    switch (service) {
        case 'baggage':
            link = companyInfo.baggage && "".concat(companyInfo.baggage);
            break;
        case 'meal':
            link = companyInfo.meal && "".concat(companyInfo.meal);
            break;
        case 'seat':
            link = companyInfo.seat && "".concat(companyInfo.seat);
            break;
    }
    return link ? (React.createElement("div", { className: css.disclaimer },
        React.createElement(Link, { target: "_blank", action: link },
            React.createElement("span", { className: css.disclaimer__icon }, infoIcon),
            service === 'meal' && t('MealService:Terms & conditions'),
            service === 'baggage' && t('BaggagePage:Baggage rules'),
            service === 'seat' && t('SeatMap:Terms & conditions')))) : null;
};
export default Disclaimer;
