import * as Aeroexpress from './components/Aeroexpress.css';
import * as ModalContent from './components/ModalContent/ModalContent.css';
import * as CardContent from './components/CardContent/CardContent.css';
import * as MobileModalContent from './components/MobileModalContent/MobileModalContent.css';
export default {
    Aeroexpress: Aeroexpress,
    ModalContent: ModalContent,
    CardContent: CardContent,
    MobileModalContent: MobileModalContent
};
