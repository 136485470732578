import { __assign, __rest } from "tslib";
import * as React from 'react';
import { createCartContext } from './utils';
import { CartService } from './types';
import { initI18n } from '../utils';
import { useTheme } from '../theme';
import Toolbar from './Toolbar/Toolbar';
import CartFactory from './Fields/CartFactory';
initI18n('Cart');
export var CartContext = React.createContext({
    travellersById: null,
    segmentsById: null,
    totalPrices: {}
});
var CART_SERVICES = [
    CartService.Flights,
    CartService.Seats,
    CartService.Baggage,
    CartService.Meal,
    CartService.Passengers,
    CartService.Extra
];
var Cart = function (_a) {
    var prices = _a.prices, _b = _a.cartServices, cartServices = _b === void 0 ? CART_SERVICES : _b, hideToolbar = _a.hideToolbar, onClose = _a.onClose, renderServices = _a.renderServices, props = __rest(_a, ["prices", "cartServices", "hideToolbar", "onClose", "renderServices"]);
    var theme = useTheme('Cart').Cart;
    var includeInsuranceService = cartServices.includes(CartService.Insurances);
    return (React.createElement(CartContext.Provider, { value: createCartContext(props.travellers, props.flight.segments, prices) },
        React.createElement("div", { className: theme.wrapper },
            React.createElement("div", { className: theme.cart },
                cartServices.map(function (type) { return (React.createElement(CartFactory, __assign({}, props, { key: type, type: type, separateInsurance: includeInsuranceService }))); }), renderServices === null || renderServices === void 0 ? void 0 :
                renderServices()),
            !hideToolbar && (React.createElement("div", { className: theme.toolbar },
                React.createElement(Toolbar, { onClick: onClose }))))));
};
export default Cart;
