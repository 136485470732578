import { __assign, __generator } from "tslib";
import { put, select, takeEvery } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { ANALYZE_PURCHASEd_PRODUCTS, FILL_ORDER, setOrder } from './actions';
import { setBreakdownPrices } from '../priceBreakdown/action';
import { setPassengersState } from '../passengers/actions';
import { checkoutSelectedServicesStateFactory, parsePriceBreakdown } from '../../utils';
import { getOrder } from './selectors';
import { ProductType } from '../../../analytics';
import { getAeroexpressProductId } from '../../../Aeroexpress/utils';
import { setOrderPrice } from '../orderPrice/actions';
import { setAllSelectedServices } from '../selectedServices/actions';
import getOrderDataLayer from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
/**
 * @deprecated Should use DataLayerAnalytics
 */
function analyzePurchasedProducts() {
    var order, products;
    var _a, _b, _c, _d, _e, _f, _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0: return [4 /*yield*/, select(getOrder)];
            case 1:
                order = _h.sent();
                products = [];
                (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.forEach(function (_a) {
                    var insuranceProgram = _a.insuranceProgram;
                    products.push({
                        id: insuranceProgram.code,
                        name: insuranceProgram.name,
                        category: ProductType.Insurance,
                        price: insuranceProgram === null || insuranceProgram === void 0 ? void 0 : insuranceProgram.totalPrice.amount
                    });
                });
                (_g = (_f = (_e = (_d = order.additionalServices) === null || _d === void 0 ? void 0 : _d.transfer) === null || _e === void 0 ? void 0 : _e.aeroexpress) === null || _f === void 0 ? void 0 : _f.selectedTrips) === null || _g === void 0 ? void 0 : _g.forEach(function (trip) {
                    products.push({
                        id: getAeroexpressProductId(trip),
                        category: ProductType.Transfer
                    });
                });
                order.travellers.forEach(function (_a) {
                    var _b, _c, _d;
                    var travellerId = _a.id, services = _a.services;
                    (_c = (_b = services.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(function (_a) {
                        var _b, _c;
                        var serviceId = _a.serviceId, count = _a.count;
                        var service = (_c = (_b = order.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (_a) {
                            var id = _a.id;
                            return id === serviceId;
                        });
                        if (service) {
                            if (service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage) {
                                var baggage = service;
                                products.push({
                                    id: baggage.rfisc,
                                    category: ProductType.Baggage,
                                    name: baggage.name,
                                    quantity: count,
                                    variant: baggage.baggageWeight,
                                    price: baggage.price.amount
                                });
                            }
                            if (service.type === OrderAdditionalServiceGdsServiceServiceType.Meal) {
                                var meal = service;
                                products.push({
                                    id: meal.rfisc,
                                    category: ProductType.Meal,
                                    name: meal.name,
                                    quantity: count,
                                    price: meal.price.amount
                                });
                            }
                        }
                    });
                    (_d = services.seats) === null || _d === void 0 ? void 0 : _d.forEach(function (_a) {
                        var _b, _c;
                        var seat = _a.seat;
                        if (!seat.seatService) {
                            console.warn("seat without service passenger ".concat(travellerId));
                            products.push({
                                id: null,
                                name: null,
                                category: ProductType.Seat,
                                variant: seat.number,
                                price: seat.price ? seat.price.amount : 0
                            });
                            return;
                        }
                        var service = (_c = (_b = order.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (_a) {
                            var id = _a.id;
                            return id === seat.seatService.id;
                        });
                        products.push({
                            id: service.rfisc,
                            name: service.name,
                            category: ProductType.Seat,
                            variant: seat.number,
                            price: seat.price ? seat.price.amount : 0
                        });
                    });
                });
                return [2 /*return*/];
        }
    });
}
function fillOrder(_a) {
    var actions, passengers, selectedServices, orderPrice;
    var order = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                actions = [];
                if (order.travellers.length) {
                    passengers = order.travellers.map(function (passenger) { return ({
                        id: passenger.id,
                        type: passenger.type,
                        values: passenger.values,
                        supplierTravellerType: passenger.supplierTravellerType,
                        linkedTraveller: passenger.linkedTraveller
                    }); });
                    actions.push(setPassengersState(passengers));
                    selectedServices = checkoutSelectedServicesStateFactory(order);
                    actions.push(setAllSelectedServices(selectedServices));
                }
                if ((order === null || order === void 0 ? void 0 : order.price) || (order === null || order === void 0 ? void 0 : order.priceToPay)) {
                    orderPrice = {
                        priceToPay: __assign({}, order.priceToPay),
                        price: __assign({}, order.price)
                    };
                    actions.push(setOrderPrice(orderPrice));
                }
                if (order.priceBreakdown) {
                    actions.push(setBreakdownPrices(parsePriceBreakdown(order.priceBreakdown)));
                }
                getOrderDataLayer(OrderType.Checkout).setOrder(order);
                actions.push(setOrder(order));
                return [4 /*yield*/, put(batchActions(actions))];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function fillOrderSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(FILL_ORDER, fillOrder)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * @deprecated Should use DataLayerAnalytics
 */
export function analyzePurchasedProductsSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ANALYZE_PURCHASEd_PRODUCTS, analyzePurchasedProducts)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
