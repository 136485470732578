import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwipableViews from 'react-swipeable-views';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { initI18n } from '../../../utils';
import { ThemeProvider, useTheme } from '../../../theme';
import { Locale } from '../../../types';
import { useMemo } from 'react';
import FareCode from '../FareCode/FareCode';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import { textIsLink } from '../../utils';
initI18n('FareConditions');
initI18n('MobileStepbar');
var FareConditions = function (_a) {
    var _b;
    var _c;
    var onLangChange = _a.onLangChange, showTranslateTab = _a.showTranslateTab, fareRulesBySegmentsPassengersMap = _a.fareRulesBySegmentsPassengersMap, tabsSegments = _a.tabsSegments, selectLang = _a.selectLang, setSegment = _a.setSegment, segment = _a.segment, selectedLang = _a.selectedLang, hasErrors = _a.hasErrors, firstPassengerInList = _a.firstPassengerInList, fareRules = _a.fareRules;
    var css = useTheme('FareConditions').FareConditions;
    var t = useTranslation('FareConditions').t;
    var handleLangChange = function (lang) {
        if (lang === selectedLang) {
            return;
        }
        onLangChange(lang);
        selectLang(lang);
    };
    var fareRulesSuccessfullyParsed = useMemo(function () { return Object.keys(fareRulesBySegmentsPassengersMap).length > 0; }, [
        fareRulesBySegmentsPassengersMap
    ]);
    var segmentTabTheme = useMemo(function () { return ({
        SegmentTabs: {
            SegmentTabs: {
                wrapper: css.segmentTabsWrapper,
                title: css.segmentTabsTitle,
                segments: css.segmentTabsSegments,
                inner: css.segmentTabsInner
            },
            SegmentTab: { segment_active: css.segmentTabsTab__active }
        }
    }); }, []);
    var ruleTextRender = function (text, index) {
        return (React.createElement("div", { key: index }, textIsLink(text) ? (React.createElement("a", { href: text, target: "_blank" }, t('See more details on this page'))) : (React.createElement("pre", { dangerouslySetInnerHTML: { __html: text } }))));
    };
    return (React.createElement(Paper, { className: css.paperRoot },
        fareRulesSuccessfullyParsed && (React.createElement(ThemeProvider, { value: segmentTabTheme },
            React.createElement(SegmentTabs, { title: t('Fare conditions'), segments: tabsSegments, isCompact: false, scrollToActive: true, sticky: true, onSegmentClick: function (segmentId) {
                    setSegment(segmentId);
                }, activeSegment: segment, classNameTab: css.segmentTabsTab, segmentsClassName: css.segmentsTabs }))),
        React.createElement("div", { className: cn(css.tabsWrp, (_b = {}, _b[css.tabsWrp_error] = hasErrors, _b)) }, showTranslateTab && (React.createElement(Tabs, { value: selectedLang, classes: {
                root: css.translateTabs,
                flexContainer: css.translateTabsContainer,
                indicator: cn(css.tabIndicator, css.translateTabIndicator)
            } },
            React.createElement(Tab, { classes: {
                    root: cn(css.tab, css.translateTab),
                    selected: cn(css.tab_active, css.translateTab_selected)
                }, onClick: function () { return handleLangChange(Locale.English); }, label: t('Original') }),
            React.createElement(Tab, { classes: {
                    root: cn(css.tab, css.translateTab),
                    selected: cn(css.tab_active, css.translateTab_selected)
                }, onClick: function () { return handleLangChange(Locale.Russian); }, label: t('Translate to russian*') })))),
        React.createElement(React.Fragment, null,
            React.createElement(SwipableViews, { index: Object.keys(fareRulesBySegmentsPassengersMap).indexOf(segment), onChangeIndex: function (index) {
                    setSegment(Object.keys(fareRulesBySegmentsPassengersMap)[index]);
                } }, Object.keys(fareRulesBySegmentsPassengersMap).map(function (key) { return (React.createElement("div", { key: key, className: css.fareConditionsContent },
                React.createElement("div", null,
                    React.createElement("pre", null, segment && (React.createElement(FareCode, { passengersRules: fareRulesBySegmentsPassengersMap[segment] }))),
                    segment &&
                        fareRulesBySegmentsPassengersMap[segment][firstPassengerInList].rules.map(function (rule, index) { return ruleTextRender(rule.text, index); })))); })),
            !fareRulesSuccessfullyParsed && (React.createElement("div", { className: css.fareConditionsContent },
                React.createElement("div", null, (_c = fareRules.FareRules.rulesForSegments) === null || _c === void 0 ? void 0 : _c.map(function (rule) {
                    return rule.rules.map(function (rule, index) { return ruleTextRender(rule.text, index); });
                })))))));
};
export default FareConditions;
