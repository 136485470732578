import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
var Point = function (_a) {
    var location = _a.location, showAirportModal = _a.showAirportModal;
    var css = useTheme('DesktopFlightInfo').Point;
    var t = useTranslation().t;
    return (React.createElement("div", { className: css.point },
        React.createElement("span", null, location.time.substr(0, 5)),
        React.createElement("span", null, location.airport.iata),
        showAirportModal ? (React.createElement("span", { className: css.link }, location.airport.name)) : (React.createElement("span", { className: css.airportName }, location.airport.name)),
        location.terminal && (React.createElement("span", { className: css.terminal },
            "\u2022 ",
            t('DesktopFlightInfo:Terminal'),
            " ",
            location.terminal))));
};
export default Point;
