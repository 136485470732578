import { __assign, __generator } from "tslib";
import { getContext, put, select, takeEvery } from 'redux-saga/effects';
import { ADD_PASSENGER, REMOVE_PASSENGER, setPassengers } from './actions';
import { getPassengersState } from './selectors';
import { PassengerType } from '../../../enums';
import { isResetSelectedOnlyInfants, setPassengerCountLessOrEqualAdt } from './utils';
function clearConflictedPassengers(_a) {
    var passengersConfig, state, newState;
    var passengerType = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getContext('config')];
            case 1:
                passengersConfig = (_b.sent()).SearchForm.passengersConfig;
                return [4 /*yield*/, select(getPassengersState)];
            case 2:
                state = _b.sent();
                newState = __assign({}, state);
                passengersConfig.forEach(function (config) {
                    var _a, _b;
                    if (config.code === passengerType) {
                        (_a = config.noAvailableWith) === null || _a === void 0 ? void 0 : _a.forEach(function (conflictedCode) {
                            newState[conflictedCode] = 0;
                        });
                    }
                    else if ((_b = config.noAvailableWith) === null || _b === void 0 ? void 0 : _b.includes(passengerType)) {
                        newState[config.code] = 0;
                    }
                });
                if (isResetSelectedOnlyInfants(newState, passengersConfig)) {
                    Object.keys(newState).forEach(function (type) {
                        newState[type] = 0;
                    });
                }
                return [4 /*yield*/, put(setPassengers(newState))];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function decrementInfants() {
    var passengersConfig, state, adultConf, adultCount, newState;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getContext('config')];
            case 1:
                passengersConfig = (_a.sent()).SearchForm.passengersConfig;
                return [4 /*yield*/, select(getPassengersState)];
            case 2:
                state = _a.sent();
                adultConf = passengersConfig.find(function (config) { return config.code === PassengerType.Adult || config.isAdult; });
                adultCount = state[adultConf.code];
                newState = __assign({}, state);
                newState = setPassengerCountLessOrEqualAdt(newState, adultCount, passengersConfig, PassengerType.Infant);
                newState = setPassengerCountLessOrEqualAdt(newState, adultCount, passengersConfig, PassengerType.InfantWithSeat);
                return [4 /*yield*/, put(setPassengers(newState))];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function handleAddPassenger(action) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, clearConflictedPassengers(action)];
            case 1:
                _a.sent();
                return [4 /*yield*/, decrementInfants()];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default function passengersSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ADD_PASSENGER, handleAddPassenger)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(REMOVE_PASSENGER, decrementInfants)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
