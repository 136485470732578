import { Locale } from './types';
import { Currency } from './enums';
import { LOCALES } from './utils';
export var RECENT_SEARCHES_KEY = 'RECENT_SEARCHES_KEY';
export var IS_CHECKIN_TRAVELLERS_DATA_FILLED = ' IS_CHECKIN_TRAVELLERS_DATA_FILLED';
export var SEARCH_FORM_STATE_KEY = 'SEARCH_FORM_STATE_KEY';
export var LOCALE_KEY = 'LOCALE_KEY';
export var CURRENCY_KEY = 'CURRENCY_KEY';
export var SEATS_RECOMMENDATION_SHOWN = 'SEATS_RECOMMENDATION_SHOWN';
export var BAGGAGE_RECOMMENDATION_SHOWN = 'BAGGAGE_RECOMMENDATION_SHOWN ';
export var MEAL_RECOMMENDATION_SHOWN = 'MEAL_RECOMMENDATION_SHOWN';
export var TEST_AUTHORIZATION_REFUSED = 'TEST_AUTHORIZATION_REFUSED';
export var GRAPHQL_KEY = 'hashed_value';
export var SHOW_RENDERERS_IN_CONSOLE = 'SHOW_RENDERERS_IN_CONSOLE';
export var FOREIGN_PAYMENT_KEY = 'FOREIGN_PAYMENT';
export var SHOW_TRANSLATION_KEYS = 'SHOW_TRANSLATION_KEYS';
export var UTM_MARKS_KEY = 'UTM_MARKS';
export var CUSTOM_SCHEMAS_URL = 'CUSTOM_SCHEMAS_URL';
export var SOCIAL_AUTH_REDIRECT_URL = 'SOCIAL_AUTH_REDIRECT_URL';
export var AUTH_DUPLICATES = 'AUTH_DUPLICATES';
export var IMPORTANT_INSURANCE_TOUCHED = 'IMPORTANT_INSURANCE_TOUCHED';
export var set = function (key, value, storage) {
    if (storage === void 0) { storage = localStorage; }
    if (typeof storage !== 'undefined') {
        if (value) {
            try {
                storage.setItem(key, JSON.stringify(value));
            }
            catch (e) {
                console.warn(e);
            }
        }
    }
};
export var get = function (key, defaultValue, storage) {
    if (storage === void 0) { storage = localStorage; }
    var result = defaultValue;
    if (typeof storage !== 'undefined') {
        var string = storage.getItem(key);
        if (string) {
            try {
                result = JSON.parse(string);
            }
            catch (e) {
                console.warn(e);
            }
        }
    }
    return result;
};
export var sessionStorageGet = function (key, defaultValue) {
    return get(key, defaultValue, sessionStorage);
};
export var sessionStorageSet = function (key, value) {
    return set(key, value, sessionStorage);
};
export var getShowRenderersInConsole = function () {
    return !!get(SHOW_RENDERERS_IN_CONSOLE);
};
export var getLocaleFromLocalStorage = function () {
    var locale = get(LOCALE_KEY);
    return LOCALES.has(locale) ? locale : Locale.Russian;
};
export var getShowTranslationKeysFromLocalStorage = function () { return get(SHOW_TRANSLATION_KEYS, false) === true; };
export var getCurrency = function () {
    var _a, _b, _c, _d;
    return get(CURRENCY_KEY, ((_d = (_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.SearchForm) === null || _b === void 0 ? void 0 : _b.selectableCurrencies) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.code) || Currency.RUB);
};
export var setLocaleToLocalStorage = function (locale) {
    set(LOCALE_KEY, locale);
};
export var setCurrency = function (currency) {
    var oldCurrency = getCurrency();
    set(CURRENCY_KEY, currency);
    dispatchEvent(new StorageEvent('storage', { key: CURRENCY_KEY, oldValue: oldCurrency, newValue: currency }));
};
export var getIsCheckinTravellersDataFilledFromLocalStorage = function (orderId) {
    return get("".concat(IS_CHECKIN_TRAVELLERS_DATA_FILLED, "_").concat(orderId));
};
export var setIsCheckinTravellersDataFilledToLocalStorage = function (orderId, value) {
    set("".concat(IS_CHECKIN_TRAVELLERS_DATA_FILLED, "_").concat(orderId), value);
};
export var removeFromSessionStorage = function (key) {
    sessionStorage.removeItem(key);
};
