import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Gateway from '../Gateway';
import { useTheme } from '../../../../theme';
import { PaymentType } from '../../../types';
import Button from '../../../../Button';
import { Money } from '../../../../Money';
var Cash = function (_a) {
    var children = _a.children, isPreOpened = _a.isPreOpened, frameInfo = _a.frameInfo, headerTitle = _a.headerTitle, type = _a.type, onCompletePayment = _a.onCompletePayment, onClick = _a.onClick;
    var css = useTheme('PaymentForm').Cash;
    var t = useTranslation('PaymentForm').t;
    var onCompletePaymentHandler = useCallback(function () { return onCompletePayment({ transactionId: frameInfo.transactionId }); }, [
        onCompletePayment,
        frameInfo
    ]);
    var onClickGateway = useCallback(function () {
        return onClick({
            title: headerTitle,
            type: PaymentType.Cash,
            content: (React.createElement("div", { className: css.content },
                React.createElement(Button, { onClick: onCompletePaymentHandler },
                    t('Pay'),
                    React.createElement(Money, { money: frameInfo.totalPrice }))))
        });
    }, [onCompletePayment, onClick, frameInfo]);
    useEffect(function () {
        if (isPreOpened) {
            onClickGateway();
        }
    }, [isPreOpened]);
    return React.createElement(Gateway, { header: children, onClick: onClickGateway, type: type, paymentType: PaymentType.Cash });
};
export default Cash;
