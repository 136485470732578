import * as React from 'react';
import { isDemo as isDemoInstance } from '../../utils';
var IsDemo = function () {
    React.useEffect(function () {
        if (isDemoInstance) {
            window.document.body.setAttribute('data-demo-wx', 'true');
        }
    }, []);
    return null;
};
export default IsDemo;
