import { __read } from "tslib";
import { useEffect, useMemo, useState } from 'react';
import { useConfig } from '../context';
export var useUpsaleServicesModal = function (travellers, additionalServices, serviceType, onRefuse, onAgreeServices) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var upsaleServicesConfig = useConfig().global.upsaleServices;
    var validServices = upsaleServicesConfig === null || upsaleServicesConfig === void 0 ? void 0 : upsaleServicesConfig[serviceType];
    var _b = __read(useMemo(function () {
        var refuseServicesPayload = [];
        var upsaleServices = [];
        travellers.forEach(function (traveller) {
            traveller.preselectedServices.gdsServices.services.forEach(function (preSelectedService) {
                var service = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices.services.find(function (srv) { return srv.id === preSelectedService.serviceId; });
                if (service && service.type === serviceType) {
                    var validSrv = validServices === null || validServices === void 0 ? void 0 : validServices.find(function (srv) { return srv.rfisc === service.rfisc; });
                    if (validSrv) {
                        var payloadService_1 = {
                            id: service.id,
                            type: service.type,
                            price: service.price,
                            name: service.name
                        };
                        preSelectedService.segmentIds.forEach(function (segmentId) {
                            refuseServicesPayload.push({
                                passengerId: traveller.id,
                                segmentId: segmentId,
                                serviceId: preSelectedService.serviceId,
                                service: payloadService_1
                            });
                        });
                        upsaleServices.push({
                            service: service,
                            description: validSrv.description,
                            icon: validSrv.icon,
                            title: validSrv === null || validSrv === void 0 ? void 0 : validSrv.title,
                            extraTitle: validSrv === null || validSrv === void 0 ? void 0 : validSrv.extraTitle,
                            extraTitleShowDates: validSrv === null || validSrv === void 0 ? void 0 : validSrv.extraTitleShowDates
                        });
                    }
                }
            });
        });
        return [refuseServicesPayload, upsaleServices];
    }, [travellers, additionalServices, serviceType]), 2), refuseServicesPayload = _b[0], upsaleServices = _b[1];
    useEffect(function () {
        if (refuseServicesPayload.length) {
            setOpen(true);
        }
    }, []);
    var refuseHandler = function () {
        setOpen(false);
        onRefuse === null || onRefuse === void 0 ? void 0 : onRefuse(refuseServicesPayload);
    };
    var agreeHandler = function () {
        setOpen(false);
        onAgreeServices === null || onAgreeServices === void 0 ? void 0 : onAgreeServices(refuseServicesPayload);
    };
    return {
        isOpen: isOpen,
        upsaleServices: upsaleServices,
        refuseHandler: refuseHandler,
        agreeHandler: agreeHandler
    };
};
