import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import SeatInfo from '../SeatInfo/SeatInfo';
var SeatInfoGroup = function (props) {
    var groupedSeatsInfo = React.useMemo(function () {
        var seatsMap = new Map();
        props.seatService.forEach(function (service) {
            var existService = seatsMap.get(service.comfort);
            if (existService && (existService.price.amount > service.price.amount || !existService.serviceImage)) {
                seatsMap.set(service.comfort, __assign(__assign({}, existService), { serviceImage: !existService.serviceImage ? service.serviceImage : existService.serviceImage, price: service.price.amount < existService.price.amount ? service.price : existService.price }));
            }
            else if (!existService) {
                seatsMap.set(service.comfort, service);
            }
        });
        return __spreadArray([], __read(seatsMap.values()), false);
    }, [props.seatService]);
    return (React.createElement(React.Fragment, null, groupedSeatsInfo.map(function (seat) { return (React.createElement(SeatInfo, { seatService: seat, key: seat.id, amenities: props.amenities, fullInfo: props.fullInfo, hideImage: props.hideImage, services: props.services, passengerId: props.passengerId, segmentId: props.segmentId })); })));
};
export default SeatInfoGroup;
