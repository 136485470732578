import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from 'apollo-boost';
import { TravellerFieldEnum, useFindOrderMutation } from '@websky/graphql';
import { capitalizeFirstLetterInName, format, getUserValue, redirectToOrder, removeDotsFromDate } from '../../utils';
import { useConfig } from '../../context';
import { useHistory } from 'react-router-dom';
var ATTEMPT_TO_IMPORT_ORDER_ERROR = 'Попытка импорта заказа не удалась';
export function useBookingPreview(_a) {
    var _this = this;
    var order = _a.order, onClearOrder = _a.onClearOrder, redirectViaHistory = _a.redirectViaHistory;
    var t = useTranslation('BookingPreview').t;
    var history = useHistory();
    var engineURL = useConfig().Account.engineURL;
    var _b = __read(useState(null), 2), error = _b[0], setError = _b[1];
    var _c = __read(useState(false), 2), isSaveLoading = _c[0], setIsSaveLoading = _c[1];
    var _d = __read(useFindOrderMutation(), 2), findOrder = _d[0], loading = _d[1].loading;
    var formatDate = useCallback(function (date) { return removeDotsFromDate(format(date, 'dd MMM, yyyy')); }, []);
    var _e = useMemo(function () {
        var _a, _b, _c, _d, _e;
        var segmentGroups = (_a = order === null || order === void 0 ? void 0 : order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups;
        var departure = (_c = (_b = segmentGroups === null || segmentGroups === void 0 ? void 0 : segmentGroups[0]) === null || _b === void 0 ? void 0 : _b.segments[0]) === null || _c === void 0 ? void 0 : _c.departure;
        var arrival = (_e = (_d = segmentGroups === null || segmentGroups === void 0 ? void 0 : segmentGroups[segmentGroups.length - 1]) === null || _d === void 0 ? void 0 : _d.segments) === null || _e === void 0 ? void 0 : _e[0].arrival;
        return { departure: departure, arrival: arrival };
    }, [order === null || order === void 0 ? void 0 : order.flight]), departure = _e.departure, arrival = _e.arrival;
    var date = useMemo(function () {
        var _a, _b;
        if (!order) {
            return null;
        }
        if (((_b = (_a = order === null || order === void 0 ? void 0 : order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups) === null || _b === void 0 ? void 0 : _b.length) > 1) {
            if (departure.date === arrival.date) {
                return "".concat(formatDate(departure.date), ", ").concat(departure.time, " - ").concat(arrival.time);
            }
            else {
                return "".concat(formatDate(departure.date), " - ").concat(formatDate(arrival.date));
            }
        }
        return formatDate(departure.date);
    }, [order === null || order === void 0 ? void 0 : order.flight]);
    var travellersCount = useMemo(function () { var _a, _b; return (_b = (_a = order === null || order === void 0 ? void 0 : order.travellers) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0; }, [order === null || order === void 0 ? void 0 : order.travellers]);
    var onError = useCallback(function (e) {
        var _a, _b, _c;
        var errorMessage = 'Unknown error';
        if (e instanceof ApolloError && ((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message)) {
            var gqlError = e.graphQLErrors[0];
            if (((_c = gqlError.debugMessage) !== null && _c !== void 0 ? _c : gqlError.message).includes(ATTEMPT_TO_IMPORT_ORDER_ERROR)) {
                errorMessage = 'The attempt to import the order failed';
            }
            else {
                errorMessage = gqlError.message;
            }
        }
        else if (e instanceof Error && e.message) {
            errorMessage = e.message;
        }
        setError(t(errorMessage));
    }, [setError]);
    var onClearError = useCallback(function () {
        setError(null);
    }, []);
    var onViewOrder = useCallback(function () {
        if (redirectViaHistory) {
            history.push("/booking/".concat(order.id));
            return;
        }
        redirectToOrder(engineURL, order.id);
    }, [engineURL, order, redirectViaHistory, history]);
    var onSaveAndView = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var accessCode, data, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSaveLoading(true);
                    accessCode = order.accessCode;
                    if ((_a = order.travellers) === null || _a === void 0 ? void 0 : _a.length) {
                        accessCode = capitalizeFirstLetterInName(getUserValue(order.travellers[0], TravellerFieldEnum.LastName));
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, findOrder({
                            variables: {
                                params: {
                                    id: order.locator,
                                    secret: accessCode,
                                    saveInProfile: true
                                }
                            }
                        })];
                case 2:
                    data = (_b.sent()).data;
                    if (!(data === null || data === void 0 ? void 0 : data.FindOrder)) {
                        return [2 /*return*/];
                    }
                    onViewOrder();
                    onClearOrder === null || onClearOrder === void 0 ? void 0 : onClearOrder();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    onError(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setIsSaveLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [order, findOrder, setIsSaveLoading, onViewOrder, onClearOrder, onError]);
    return {
        // data
        departure: departure,
        arrival: arrival,
        date: date,
        travellersCount: travellersCount,
        error: error,
        isLoading: loading,
        isSaveLoading: isSaveLoading,
        // methods
        onViewOrder: onViewOrder,
        onSaveAndView: onSaveAndView,
        onClearError: onClearError
    };
}
