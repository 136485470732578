import * as React from 'react';
import { Passenger } from './Passenger/Passenger';
import { PassengerType } from '../../enums';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import cn from 'classnames';
initI18n('Passengers');
export var Passengers = React.memo(function (_a) {
    var passengers = _a.passengers, addPassenger = _a.addPassenger, removePassenger = _a.removePassenger;
    var css = useTheme('Passengers').PassengersStyles;
    return (React.createElement("ul", { className: css.list }, passengers.map(function (passenger) {
        var _a;
        return (React.createElement("li", { "data-pass-type": passenger.type, key: passenger.type, className: cn(css.item, (_a = {},
                _a[css.item_FNN] = passenger.type === PassengerType.Teenager,
                _a)) },
            React.createElement(Passenger, { passenger: passenger, addPassenger: addPassenger, removePassenger: removePassenger, decreaseIsEnabled: passenger.canBeDecreased, increaseIsEnabled: passenger.canBeIncreased })));
    })));
});
export default Passengers;
