import { isNameFieldType } from '../PassengerForm/utils';
export var getDifferentPassengerValues = function (originalValues, changeValues) {
    var originalValueMap = new Map(originalValues.map(function (value) { return [value.type, value.value]; }));
    var editableValueMap = new Map();
    changeValues.forEach(function (value) {
        if (!originalValueMap.has(value.type)) {
            return;
        }
        var originalValue = originalValueMap.get(value.type);
        var changeValue = value.value;
        if (isNameFieldType(value.type)) {
            if (!originalValue) {
                originalValue = '';
            }
            if (!changeValue) {
                changeValue = '';
            }
        }
        if (originalValue !== changeValue) {
            editableValueMap.set(value.type, value);
        }
    });
    return editableValueMap;
};
