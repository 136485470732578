var _a, _b;
import { createActions } from 'redux-actions';
export var TOGGLE_MULTI_CITY = 'TOGGLE_MULTI_CITY';
export var ADD_SEGMENT = 'ADD_SEGMENT';
export var REMOVE_SEGMENT = 'REMOVE_SEGMENT';
export var SET_DATE = 'SET_DATE';
export var SET_DATE_MULTI_CITY = 'SET_DATE_MULTI_CITY';
export var CLEAR_DATES = 'CLEAR_DATES';
export var SET_LOCATION = 'SET_LOCATION';
export var SWAP_LOCATIONS = 'SWAP_LOCATIONS';
export var SWAP_MULTI_LOCATIONS = 'SWAP_MULTI_LOCATIONS';
export var SET_SEGMENTS = 'SET_SEGMENTS';
export var SET_MULTI_CITY = 'SET_MULTI_CITY';
export var SET_MULTI_CITY_ROUTE = 'SET_MULTI_CITY_ROUTE';
export var toggleMultiCity = (_b = createActions((_a = {},
    _a[SET_DATE] = function (date, isBackDate) {
        if (isBackDate === void 0) { isBackDate = false; }
        return ({ date: date, isBackDate: isBackDate });
    },
    _a[SET_DATE_MULTI_CITY] = function (date, segmentIndex) { return ({ date: date, segmentIndex: segmentIndex }); },
    _a[TOGGLE_MULTI_CITY] = null,
    _a[SET_MULTI_CITY] = function (value) { return value; },
    _a[ADD_SEGMENT] = null,
    _a[CLEAR_DATES] = null,
    _a[REMOVE_SEGMENT] = function (segmentIndex) { return segmentIndex; },
    _a[SET_LOCATION] = function (location, type, segmentIndex) { return ({
        location: location,
        type: type,
        segmentIndex: segmentIndex
    }); },
    _a[SWAP_LOCATIONS] = null,
    _a[SWAP_MULTI_LOCATIONS] = function (segmentIndex) { return segmentIndex; },
    _a[SET_SEGMENTS] = function (segments) { return segments; },
    _a)), _b.toggleMultiCity), addSegment = _b.addSegment, removeSegment = _b.removeSegment, setDate = _b.setDate, setDateMultiCity = _b.setDateMultiCity, clearDates = _b.clearDates, setLocation = _b.setLocation, swapLocations = _b.swapLocations, swapMultiLocations = _b.swapMultiLocations, setSegments = _b.setSegments, setMultiCity = _b.setMultiCity;
