import { __assign, __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import FareGroup from '../FareGroup';
import { useTheme } from '../../../theme';
import { icons, payment } from '../../icons';
import { useOptionIconModifier } from './hooks';
import { LuggageExcessIcon } from './icons';
import Tooltip from '../../../Tooltip';
import { FareFeaturePaymentType } from '@websky/graphql';
import { getUniqueValidOptions } from '../../../Results/utils';
import { OverrideComponent, useRenderers } from '../../../renderProps';
import Button from '../../../Button';
import { ArrowForward, Exchangeable, Refundable } from '../../../Icons';
import { useConfig } from '../../../context';
import { FareFamilyOptionsType } from '@websky/graphql';
var FareGroupGrid = function (props) {
    var _a, _b;
    var _c, _d;
    var css = useTheme('FareGroup').FareGroupGrid;
    var fare = props.fare, upgradeOption = props.upgradeOption, orderId = props.orderId;
    var t = useTranslation('FareGroup').t;
    var iataCode = useConfig().global.companyInfo.iataCode;
    var isF7 = iataCode === 'F7';
    var isY7 = iataCode === 'Y7';
    var options = getUniqueValidOptions(fare.options, false, true, (_c = props.showUnavailableOptions) !== null && _c !== void 0 ? _c : false).filter(function (option) { return option.isKeyOption; });
    var notKeyOptions = getUniqueValidOptions(fare.options, false, false, (_d = props.showUnavailableOptions) !== null && _d !== void 0 ? _d : false).filter(function (option) { return !option.isKeyOption; });
    var smallAfterFirst = isF7 || isY7 || notKeyOptions.length > 4;
    var smallAfterFirst4 = options.length > 6 && options.length <= 7;
    var smallOptions = options.length > 7;
    var _e = useRenderers(), renderFareGroupOptionValue = _e.renderFareGroupOptionValue, renderFareGroupOptionIcon = _e.renderFareGroupOptionIcon;
    var renderOptionAvailabilityIcon = useCallback(function (availability) {
        if (renderFareGroupOptionValue) {
            return renderFareGroupOptionValue(availability);
        }
        return payment[availability];
    }, [payment, renderFareGroupOptionValue]);
    var renderOptionIcon = useCallback(function (type, isSpecialLuggageOverweightOption) {
        if (renderFareGroupOptionIcon) {
            return renderFareGroupOptionIcon(type, isSpecialLuggageOverweightOption);
        }
        return isSpecialLuggageOverweightOption ? LuggageExcessIcon : icons[type];
    }, [icons, renderFareGroupOptionIcon]);
    var renderOption = useCallback(function (option, index) {
        var _a;
        var _b, _c, _d;
        var isSpecialLuggageOverweightOption = useOptionIconModifier(option).isSpecialLuggageOverweightOption;
        var currentIndex = index + 1;
        var isSmall = false;
        var _e = __read(React.useState(false), 2), isTooltipOpened = _e[0], setTooltipOpened = _e[1];
        var setTooltipState = function (state) {
            var _a;
            setTooltipOpened(state);
            (_a = props.setTooltipsOpened) === null || _a === void 0 ? void 0 : _a.call(props, state);
        };
        if (smallAfterFirst4 && currentIndex > 4) {
            isSmall = true;
        }
        if (smallAfterFirst) {
            isSmall = true;
        }
        if (smallOptions && currentIndex > 2) {
            isSmall = true;
        }
        return (React.createElement(Tooltip, { title: option.description ? option.description : option.title, key: option.type, enterTouchDelay: 0, open: isTooltipOpened && props.isTooltipsOpened, onClose: function () { return setTooltipOpened(false); }, onOpen: function () { return setTooltipState(true); } },
            React.createElement("div", { className: cn(css.option, (_a = {},
                    _a[css.paid] = option.availability === FareFeaturePaymentType.Pay,
                    _a[css.not_available] = option.availability === FareFeaturePaymentType.NotAvailable,
                    _a[css.small] = isSmall,
                    _a)) }, isY7 ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.icon }, renderOptionIcon(option.type, isSpecialLuggageOverweightOption)),
                React.createElement("span", { className: css.availabilityIcon }, (_b = (isSmall && option.value)) !== null && _b !== void 0 ? _b : renderOptionAvailabilityIcon(option.availability)),
                !isSmall && (React.createElement(React.Fragment, null, (_c = option.value) !== null && _c !== void 0 ? _c : option.title,
                    React.createElement("div", { className: css.size }, option.size))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css.icon }, renderOptionIcon(option.type, isSpecialLuggageOverweightOption)),
                React.createElement("div", { className: css.availabilityIcon }, renderOptionAvailabilityIcon(option.availability)),
                React.createElement("div", { className: css.title }, (_d = option.value) !== null && _d !== void 0 ? _d : option.title),
                React.createElement("div", { className: css.size }, option.size))))));
    }, [fare, props.isTooltipsOpened]);
    var renderHeader = useCallback(function () { return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: css.header }, fare.title),
        React.createElement("div", { className: css.container }, options.map(renderOption)))); }, [fare, options]);
    var optionIcon = (_a = {},
        _a[FareFamilyOptionsType.Exchangeable] = Exchangeable,
        _a[FareFamilyOptionsType.Refundable] = Refundable,
        _a);
    var renderUpgradeOption = useCallback(function () { return (React.createElement("div", { className: css.upgradeOffer, onClick: props.onUpgrade },
        React.createElement("div", { className: css.upgradeOffer__content },
            React.createElement("span", { className: css.upgradeOffer__text }, upgradeOption.upgradeOffer),
            React.createElement("span", { className: css.upgradeOffer__choose },
                t('Choose'),
                " ",
                upgradeOption.canUpgradeTo.fareFamily.title),
            React.createElement("div", { className: css.upgradeOffer__options },
                React.createElement("div", { className: css.upgradeOffer__optionsPlus }),
                upgradeOption.optionsAvailableOnNextFare.map(function (option, index) { return (React.createElement("div", { key: index }, optionIcon[option])); }))),
        React.createElement(Button, { className: css.upgradeOffer__button }, ArrowForward))); }, [upgradeOption, props.onUpgrade]);
    var renderRulesButton = useCallback(function (_a) {
        var flightId = _a.flightId;
        return (React.createElement(OverrideComponent, { componentProps: {
                flightId: flightId,
                orderId: orderId
            }, component: {
                FareGroupGridRulesButton: function () { return null; }
            } }));
    }, []);
    var renderers = useMemo(function () { return ({
        renderHeader: renderHeader,
        renderRulesButton: renderRulesButton,
        renderUpgradeOption: upgradeOption ? renderUpgradeOption : function () { return null; }
    }); }, [upgradeOption, props.onUpgrade, fare, props.isTooltipsOpened]);
    return (React.createElement(OverrideComponent, { componentProps: __assign(__assign({}, props), { className: cn(props.className, (_b = {}, _b[css.recommended] = fare.isRecommended && !props.isDisabled, _b)), renderProps: renderers }), component: {
            FareGroup: FareGroup
        } }));
};
export default FareGroupGrid;
