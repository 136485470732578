var _a;
import { AmenityType } from '@websky/graphql';
import * as React from 'react';
export var amenitiesIcons = (_a = {},
    _a[AmenityType.WiFi] = (React.createElement("svg", { width: "21", height: "19", viewBox: "0 0 21 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.33398 7.50005L3.00065 9.16671C7.14232 5.02505 13.859 5.02505 18.0006 9.16671L19.6673 7.50005C14.609 2.44172 6.40065 2.44172 1.33398 7.50005ZM8.00052 14.1667L10.5005 16.6667L13.0005 14.1667C11.6255 12.7834 9.38386 12.7834 8.00052 14.1667ZM6.33411 12.5001L4.66744 10.8334C7.89244 7.61676 13.1174 7.61676 16.3341 10.8334L14.6674 12.5001C12.3674 10.2001 8.63411 10.2001 6.33411 12.5001Z", fill: "currentColor" })))),
    _a[AmenityType.MainsPower] = (React.createElement("svg", { width: "21", height: "19", viewBox: "0 0 21 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { d: "M13.8343 5.83333V2.5H12.1676V5.83333H8.83431V2.5H7.16764V5.83333C6.25098 5.83333 5.50098 6.58333 5.50098 7.5V12.0833L8.41764 15V17.5H12.5843V15L15.501 12.0833V7.5C15.501 6.58333 14.751 5.83333 13.8343 5.83333Z", fill: "currentColor" })))),
    _a[AmenityType.FreeMeal] = (React.createElement("svg", { width: "21", height: "19", viewBox: "0 0 21 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.00098 7.50008H9.66764V1.66675H11.3343V7.50008C11.3343 9.34175 9.84264 10.8334 8.00098 10.8334V18.3334H6.33431V10.8334C4.49264 10.8334 3.00098 9.34175 3.00098 7.50008V1.66675H4.66764V7.50008H6.33431V1.66675H8.00098V7.50008ZM13.8344 11.6667V5.00008C13.8344 3.53342 15.7011 1.66675 18.0011 1.66675V18.3334H16.3344V11.6667H13.8344Z", fill: "currentColor" })))),
    _a[AmenityType.ExtraLegroom] = (React.createElement("svg", { width: "21", height: "19", viewBox: "0 0 21 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", null,
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.95962 5.53281C4.20962 4.99948 4.02628 3.96615 4.55128 3.20781C5.07628 2.45782 6.11795 2.27448 6.87628 2.79948C7.62628 3.33281 7.80962 4.36615 7.28462 5.12448C6.75128 5.87448 5.71795 6.05781 4.95962 5.53281ZM13.8346 16.6662H7.94297C6.70964 16.6662 5.65964 15.7662 5.4763 14.5495L3.83464 6.66621H2.16797L3.8263 14.7995C4.14297 16.8329 5.89297 18.3329 7.9513 18.3329H13.8346V16.6662ZM9.95946 13.3328H14.0261L18.8345 17.0828L17.5845 18.3328L14.4011 15.8328H8.69279C7.49279 15.8328 6.46779 14.9828 6.23446 13.7995L5.10946 8.86615C4.93446 7.84948 5.61779 6.87448 6.64279 6.69115H6.65946C6.93446 6.64115 7.21779 6.66615 7.48446 6.74115C7.70946 6.80781 7.92612 6.90781 8.11779 7.05781L9.48446 8.11615C10.5261 8.93281 12.0345 9.41615 13.3928 9.15781V10.9328C11.8345 11.1995 10.4178 10.6578 9.10112 9.91615L9.95946 13.3328Z", fill: "currentColor" })))),
    _a[AmenityType.Alcohol] = (React.createElement("svg", { width: "21", height: "19", viewBox: "0 0 21 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("mask", { id: "mask1", mask: "alpha", maskUnits: "userSpaceOnUse", x: "3", y: "3", width: "18", height: "18" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21 3V5L13 14V19H18V21H6V19H11V14L3 5V3H21ZM5.66 5L7.43 7H16.57L18.35 5H5.66Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask1)" },
            React.createElement("rect", { width: "20", height: "20", fill: "currentColor" })))),
    _a[AmenityType.FreeSnack] = (React.createElement("svg", { width: "21", height: "19", viewBox: "0 0 21 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("mask", { id: "mask0", mask: "alpha", maskUnits: "userSpaceOnUse", x: "1", y: "1", width: "22", height: "22" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 1V5H23L21.35 21.53C21.25 22.35 20.56 23 19.72 23H18.02L18 15C18 10.77 14.9 8.16 11.23 7.31L11 5H16V1H18ZM2.01 22.99C1.45 22.99 1 22.54 1 21.98V21H16.01V21.98C16.01 22.54 15.56 22.99 15 22.99H2.01ZM8.5 8.99C4.75 8.99 1 11 1 15H16C16 11 12.25 8.99 8.5 8.99ZM16 17H1V19H16V17Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("rect", { width: "20", height: "20", fill: "currentColor" })))),
    _a);
export var leafIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M6.47561 5.43687C3.25652 7.31002 3.4413 10.361 3.55213 11.4899C7.70996 6.55073 13.9292 6.79026 13.9292 6.79026C13.9292 6.79026 5.11326 9.82287 2.52396 15.9041C2.31948 16.3842 3.48341 17.0085 3.74904 16.441C4.5419 14.7497 5.64671 13.4813 5.64671 13.4813C7.27672 14.0896 10.0964 14.8025 12.095 13.3921C14.7497 11.5187 14.4783 7.3656 18.2679 5.34346C19.1531 4.87133 10.8398 2.89717 6.47561 5.43687Z", fill: "#41B74C" })));
