import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Money } from '../../../Money';
import { format, removeDotsFromDate } from '../../../utils';
import { useTheme } from '../../../theme';
import { OverrideComponent } from '../../../renderProps';
var planeIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M16 22H14L9 14H3.5C2.67 14 2 13.33 2 12.5C2 11.67 2.67 11 3.5 11H9L14 3H16L13.5 11L19 11L20.5 9H22L21 12.5L22 16H20.5L19 14L13.5 14L16 22Z", fill: "#9A9A9A" })));
var arrowIcon = (React.createElement("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M1 4L6 9L11 4L1 4Z", fill: "currentColor" })));
var Header = function (_a) {
    var _b, _c, _d, _e;
    var firstSegment = _a.firstSegment, lastSegment = _a.lastSegment, onClick = _a.onClick, isReturnFlight = _a.isReturnFlight, fareFamily = _a.fareFamily, price = _a.price, isOpen = _a.isOpen, isWidePrice = _a.isWidePrice;
    var t = useTranslation('DesktopSummaryFlight').t;
    var css = useTheme('DesktopSummaryFlight').Header;
    var formattedDeparture = removeDotsFromDate(format(firstSegment.segment.departure.date, 'dd MMM, EEEEEE'));
    var formattedArrivalDate = removeDotsFromDate(format(lastSegment.segment.arrival.date, 'dd MMM, EEEEEE'));
    return (React.createElement("div", { className: css.header, onClick: onClick },
        React.createElement("div", { className: cn(css.header__cell, (_b = {},
                _b[css.header__cell_short] = isWidePrice,
                _b)) },
            React.createElement("span", { className: cn(css.header__icon, (_c = {},
                    _c[css.header__icon_reverse] = isReturnFlight,
                    _c)) }, planeIcon),
            React.createElement("span", { className: css.title },
                firstSegment.segment.departure.airport.city.name,
                " \u2014",
                ' ',
                lastSegment.segment.arrival.airport.city.name)),
        React.createElement("div", { className: css.header__cell },
            React.createElement("span", { className: css.title }, formattedDeparture),
            React.createElement("span", { className: css.travelTime },
                firstSegment.segment.departure.time.substr(0, 5),
                " \u2014",
                ' ',
                lastSegment.segment.arrival.time.substr(0, 5)),
            lastSegment.segment.arrival.date !== firstSegment.segment.departure.date && (React.createElement("span", { className: css.title }, formattedArrivalDate))),
        React.createElement("div", { className: cn(css.header__cell, (_d = {},
                _d[css.header__cell_wide] = isWidePrice,
                _d)) },
            React.createElement("div", { className: css.fareGroup },
                fareFamily.airline.icon && (React.createElement("span", { className: css.fareGroup__logo },
                    React.createElement("img", { src: fareFamily.airline.icon, alt: fareFamily.airline.name }))),
                React.createElement("span", { className: css.fareGroup__name }, fareFamily.title)),
            price ? (React.createElement(OverrideComponent, { componentProps: {
                    moneyClassName: css.price,
                    money: price
                }, component: {
                    renderDesktopSummaryHeaderPrice: Money
                } })) : (React.createElement("span", { className: css.details }, t('Details'))),
            React.createElement("div", { className: cn(css.arrowIcon, (_e = {}, _e[css.arrowIcon_flipped] = isOpen, _e)) }, arrowIcon))));
};
export default Header;
