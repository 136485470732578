import { __assign, __awaiter, __generator, __read } from "tslib";
import i18next from 'i18next';
import { initI18n } from '../utils';
var DEFAULT_NAMESPACES = 'translation';
var I18N_SEPARATOR = ':';
export var addExternalResource = function (i18next, externalResource) { return __awaiter(void 0, void 0, void 0, function () {
    var languages, errors, _loop_1, lang;
    return __generator(this, function (_a) {
        languages = i18next.languages;
        errors = [];
        _loop_1 = function (lang) {
            if (languages.indexOf(lang) >= 0) {
                var namespacesMap = new Map();
                for (var key in externalResource[lang]) {
                    var splitKey = key.split(I18N_SEPARATOR), translate = externalResource[lang][key];
                    if (splitKey.length === 1) {
                        addTranslationToMap(DEFAULT_NAMESPACES, splitKey[0], translate, namespacesMap);
                    }
                    else {
                        var _b = __read(splitKey), namespace = _b[0], key_1 = _b.slice(1);
                        var bundle = getExistsBundle(lang, namespace);
                        if (!bundle) {
                            errors.push("Bundle with name '".concat(namespace, "' is not exists"));
                        }
                        else {
                            addTranslationToMap(namespace, key_1.join(I18N_SEPARATOR), translate, namespacesMap);
                        }
                    }
                }
                namespacesMap.forEach(function (resources, ns) {
                    i18next.addResourceBundle(lang, ns, resources, true, true);
                });
            }
            else {
                errors.push("Lang '".concat(lang, "' is not defined or initialized"));
            }
        };
        for (lang in externalResource) {
            _loop_1(lang);
        }
        return [2 /*return*/, errors];
    });
}); };
var addTranslationToMap = function (ns, key, translate, map) {
    var _a;
    map.set(ns, __assign(__assign({}, (map.get(ns) || {})), (_a = {}, _a[key] = translate, _a)));
};
var getExistsBundle = function (lang, ns) {
    try {
        if (!i18next.hasResourceBundle(lang, ns)) {
            initI18n(ns);
        }
        return i18next.getResourceBundle(lang, ns);
    }
    catch (e) {
        return undefined;
    }
};
