import { useMemo } from 'react';
import { initI18n } from '../utils';
import i18n from 'i18next';
import { useConfig } from '../context';
import { StepsEnum } from '../types';
initI18n('Stepbar');
export var useAvailableSteps = function (steps) {
    var config = useConfig().global;
    var defaultSteps = useMemo(function () {
        var _a;
        return _a = {},
            _a[StepsEnum.Flights] = {
                label: i18n.t('Stepbar:Flights')
            },
            _a[StepsEnum.Passengers] = {
                label: i18n.t('Stepbar:Passengers')
            },
            _a[StepsEnum.Order] = {
                label: i18n.t('Stepbar:Order')
            },
            _a[StepsEnum.Payment] = {
                label: i18n.t('Stepbar:Payment')
            },
            _a;
    }, [i18n.language]);
    return useMemo(function () {
        var _a;
        var actualSteps = steps !== null && steps !== void 0 ? steps : defaultSteps;
        if (((_a = config.stepsAvailable) === null || _a === void 0 ? void 0 : _a[StepsEnum.Meal]) === false) {
            delete actualSteps.Meal;
        }
        return actualSteps;
    }, [defaultSteps, steps, config.stepsAvailable]);
};
