import { __generator } from "tslib";
import { put, select, takeEvery } from 'redux-saga/effects';
import { nextStep, prevStep, setStep } from './actions';
import { getActiveStep } from './selectors';
import { getIsRefundVoluntary } from '../selectors';
import { StepType } from '../../types';
import { getIsDocUploadRequired } from '../refundInfo/selectors';
function goToAttachFileOrCreateTicket(isVoluntary, isDocumentsRequired) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(isVoluntary && isDocumentsRequired)) return [3 /*break*/, 2];
                return [4 /*yield*/, put(setStep({ isActive: true, type: StepType.AttachFiles, additionalText: '' }))];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, put(setStep({ isActive: true, type: StepType.CreateTicket, additionalText: '' }))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function goToAttachFilesOrReasons(isVoluntary, isDocumentsRequired) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(isVoluntary && isDocumentsRequired)) return [3 /*break*/, 2];
                return [4 /*yield*/, put(setStep({ isActive: true, type: StepType.AttachFiles, additionalText: '' }))];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, put(setStep({ isActive: true, type: StepType.Reason, additionalText: '' }))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function onNextStep() {
    var currentStep, isVoluntary, isDocUploadRequired;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getActiveStep)];
            case 1:
                currentStep = _a.sent();
                return [4 /*yield*/, select(getIsRefundVoluntary)];
            case 2:
                isVoluntary = _a.sent();
                return [4 /*yield*/, select(getIsDocUploadRequired)];
            case 3:
                isDocUploadRequired = _a.sent();
                if (!(currentStep.type === StepType.AttachFiles)) return [3 /*break*/, 5];
                return [4 /*yield*/, goToAttachFileOrCreateTicket(isVoluntary, isDocUploadRequired)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function onPrevStep() {
    var currentStep, isVoluntary, isDocUploadRequired;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getActiveStep)];
            case 1:
                currentStep = _a.sent();
                return [4 /*yield*/, select(getIsRefundVoluntary)];
            case 2:
                isVoluntary = _a.sent();
                return [4 /*yield*/, select(getIsDocUploadRequired)];
            case 3:
                isDocUploadRequired = _a.sent();
                if (!(currentStep.type === StepType.AttachFiles)) return [3 /*break*/, 5];
                return [4 /*yield*/, goToAttachFilesOrReasons(isVoluntary, isDocUploadRequired)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
export function manageSteps() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(nextStep.toString(), onNextStep)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(prevStep.toString(), onPrevStep)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
