import { getIn } from 'final-form';
// get all inputs on the page
var getAllInputs = function () {
    if (typeof document === 'undefined') {
        return [];
    }
    return Array.prototype.slice
        .call(document.forms)
        .reduce(function (accumulator, form) {
        return accumulator.concat(Array.prototype.slice.call(form).filter(function (input) { return 'focus' in input; }));
    }, []);
};
var findInput = function (inputs, errors) {
    return inputs.find(function (input) { return input.name && getIn(errors, input.name); });
};
// In fact, this is slightly modified form-focus decorator
// https://github.com/final-form/final-form-focus/blob/master/src/decorator.js
var scrollToFirstErrorDecorator = function (form) {
    if (!('scrollTo' in window)) {
        return null;
    }
    var scrollToFirstError = function (errors) {
        var firstInput = findInput(getAllInputs(), errors);
        if (firstInput) {
            window.scrollBy({ top: firstInput.getBoundingClientRect().top - 150, behavior: 'smooth' });
        }
    };
    var originalSubmit = form.submit;
    var state = {};
    var unsubscribe = form.subscribe(function (nextState) {
        state = nextState;
    }, { errors: true, submitErrors: true });
    // What to do after submit
    var afterSubmit = function () {
        var errors = state.errors, submitErrors = state.submitErrors;
        if (errors && Object.keys(errors).length) {
            scrollToFirstError(errors);
        }
        else if (submitErrors && Object.keys(submitErrors).length) {
            scrollToFirstError(submitErrors);
        }
    };
    // Rewrite submit function
    form.submit = function () {
        var result = originalSubmit.call(form);
        if (result && typeof result.then === 'function') {
            // async
            result.then(afterSubmit, function () { });
        }
        else {
            // sync
            afterSubmit();
        }
        return result;
    };
    return function () {
        unsubscribe();
        form.submit = originalSubmit;
    };
};
export default scrollToFirstErrorDecorator;
