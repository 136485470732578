import * as React from 'react';
import { useTheme } from '../theme';
import { Button } from '../index';
import { timeIcon } from '../PaymentTimeLimit/Icons';
var PaymentTimeLimitPage = function (_a) {
    var onClick = _a.onClick, title = _a.title, text = _a.text, buttonText = _a.buttonText;
    var theme = useTheme('PaymentTimeLimit').PaymentTimeLimitPage;
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement("div", { className: theme.timeImage }, timeIcon),
        React.createElement("div", { className: theme.text },
            React.createElement("span", null, title),
            React.createElement("span", null, text)),
        React.createElement(Button, { className: theme.button, onClick: onClick }, buttonText)));
};
export default PaymentTimeLimitPage;
