import { __assign, __rest } from "tslib";
import React from 'react';
import { useTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import ModalX from '../../BaseComponents/ModalX';
import classNames from 'classnames';
import { BaseButton } from '../../BaseComponents';
initI18n('ModalConfirm');
var ModalConfirm = function (_a) {
    var _b;
    var classes = _a.classes, title = _a.title, cancelBtn = _a.cancelBtn, confirmBtn = _a.confirmBtn, customButtons = _a.customButtons, _c = _a.hideFooter, hideFooter = _c === void 0 ? false : _c, _d = _a.hideTitle, hideTitle = _d === void 0 ? false : _d, children = _a.children, scroll = _a.scroll, isOpen = _a.isOpen, onClose = _a.onClose, onCancel = _a.onCancel, onConfirm = _a.onConfirm, ignoreClose = _a.ignoreClose, disableConfirmBtn = _a.disableConfirmBtn, buttonsExtra = _a.buttonsExtra, isLoading = _a.isLoading, props = __rest(_a, ["classes", "title", "cancelBtn", "confirmBtn", "customButtons", "hideFooter", "hideTitle", "children", "scroll", "isOpen", "onClose", "onCancel", "onConfirm", "ignoreClose", "disableConfirmBtn", "buttonsExtra", "isLoading"]);
    var theme = (_b = useTheme('ModalConfirm')) === null || _b === void 0 ? void 0 : _b.ModalConfirm;
    var t = useTranslation('ModalConfirm').t;
    var hideCancelBtn = !onCancel;
    var hideConfirmBtn = !onConfirm;
    function ConfirmButton() {
        if (confirmBtn && typeof confirmBtn !== 'string') {
            return confirmBtn;
        }
        return (React.createElement(BaseButton, { variant: "smallPrimary", isLoading: isLoading, classes: {
                root: classNames(theme.confirmButton, classes === null || classes === void 0 ? void 0 : classes.confirmButton),
                disabled: theme.disabled
            }, onClick: onConfirm, disabled: disableConfirmBtn }, !confirmBtn ? t('Confirm') : confirmBtn));
    }
    function CancelButton() {
        if (cancelBtn && typeof cancelBtn !== 'string') {
            return cancelBtn;
        }
        return (React.createElement(BaseButton, { variant: "smallSecondary", disabled: isLoading, classes: {
                root: classNames(theme.cancelButton, classes === null || classes === void 0 ? void 0 : classes.cancelButton),
                disabled: theme.disabled
            }, onClick: onCancel }, !cancelBtn ? t('Cancel') : cancelBtn));
    }
    function Buttons() {
        if (customButtons) {
            return (React.createElement("div", { className: classNames(theme.buttons, classes === null || classes === void 0 ? void 0 : classes.buttons, theme[props.size]) }, customButtons));
        }
        return (React.createElement("div", { className: classNames(theme.buttons, classes === null || classes === void 0 ? void 0 : classes.buttons, theme[props.size]) },
            !hideCancelBtn && React.createElement(CancelButton, null),
            !hideConfirmBtn && React.createElement(ConfirmButton, null)));
    }
    function Title() {
        return (React.createElement("div", { className: classNames(theme.header, classes === null || classes === void 0 ? void 0 : classes.header) },
            React.createElement("div", { className: classNames(theme.title, classes === null || classes === void 0 ? void 0 : classes.title) }, title)));
    }
    function Footer() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames(theme.footer, classes === null || classes === void 0 ? void 0 : classes.footer) },
                buttonsExtra && (React.createElement("div", { className: classNames(theme.buttonsExtra, classes === null || classes === void 0 ? void 0 : classes.buttonsExtra) }, buttonsExtra)),
                React.createElement(Buttons, null))));
    }
    return (React.createElement(ModalX, __assign({ isOpen: isOpen, onClose: onClose, ignoreClose: ignoreClose, scroll: scroll, classes: __assign(__assign({}, theme), { paper: classNames(theme.paper, classes === null || classes === void 0 ? void 0 : classes.paper), closeButton: classes === null || classes === void 0 ? void 0 : classes.closeButton, swipeBlock: classes === null || classes === void 0 ? void 0 : classes.swipeBlock }), title: title && !hideTitle && React.createElement(Title, null), footer: !hideFooter && React.createElement(Footer, null) }, props), children && React.createElement("div", { className: theme.content }, children)));
};
export default ModalConfirm;
