import { LOCK_FORM, UNLOCK_FORM } from './actions';
export var initialFormIsLockedState = false;
// #todo: add tests
export var formIsLockedReducer = function (state, action) {
    if (state === void 0) { state = initialFormIsLockedState; }
    switch (action.type) {
        case UNLOCK_FORM:
            return false;
        case LOCK_FORM:
            return true;
    }
    return state;
};
