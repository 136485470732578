import { __assign } from "tslib";
import classnames from 'classnames';
import * as React from 'react';
import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { clearDates, removeSegment, setDate, setDateMultiCity, setLocation, swapLocations, swapMultiLocations, addSegment } from '../../../../store/segments/actions';
import { canRemoveSegment, getSelectedDates, isMultiCity, makeGetSegment } from '../../../../store/segments/selectors';
import { LocationType } from '../../../../../types';
import Datepicker from './Datepicker/Datepicker';
import LocationField from './Location/Location';
import { addRecentSearch } from '../../../../store/recentSearches/actions';
import { getRecentSearches } from '../../../../store/recentSearches/selectors';
import { useConfig } from '../../../../../context';
import { useTheme } from '../../../../../theme';
import { getPassengersForRequest } from '../../../../store/passengers/selectors';
import { Add, Minus } from '../../../../../Icons';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import { OverrideComponent } from '../../../../../renderProps';
var datesAreNotEqual = function (prevDates, nextDates) {
    // Quick check.
    if (prevDates.length !== nextDates.length) {
        return true;
    }
    // Slow check.
    var numOfDates = prevDates.length;
    for (var i = 0; i < numOfDates; i++) {
        if (prevDates[i] !== nextDates[i]) {
            return true;
        }
    }
    return false;
};
var Segment = memo(function (_a) {
    var _b, _c, _d;
    var isMultiCity = _a.isMultiCity, recentSearches = _a.recentSearches, setDate = _a.setDate, setDateMultiCity = _a.setDateMultiCity, swapLocations = _a.swapLocations, swapMultiLocations = _a.swapMultiLocations, setLocation = _a.setLocation, segmentId = _a.segmentId, segment = _a.segment, selectedDates = _a.selectedDates, clearDates = _a.clearDates, addRecentSearch = _a.addRecentSearch, removeSegment = _a.removeSegment, canRemoveSegment = _a.canRemoveSegment, passengers = _a.passengers, addSegment = _a.addSegment;
    var css = useTheme('SearchForm').Segment;
    var alternative = useConfig().SearchForm.mobileFormType !== 'common';
    var showPricesInCalendar = useConfig().SearchForm.showPricesInDatepicker;
    var proMode = useConfig().SearchForm.proMode;
    var onRemoveSegment = useCallback(function () { return removeSegment(segmentId); }, [segmentId]);
    var onDateChange = useCallback(function (date, isBackDate) {
        if (isBackDate === void 0) { isBackDate = false; }
        if (isMultiCity) {
            setDateMultiCity(date, segmentId);
        }
        else {
            setDate(date, isBackDate);
            if (!isBackDate) {
                // Reset back date.
                setDate(null, true);
            }
        }
    }, [segmentId, isMultiCity]);
    var onSetLocation = useCallback(function (location, type) {
        if (type === LocationType.Departure && location.iata === (segment.arrival && segment.arrival.iata)) {
            setLocation(null, LocationType.Arrival);
        }
        if (isMultiCity) {
            setLocation(location, type, segmentId);
        }
        else {
            setLocation(location, type);
        }
        addRecentSearch(location);
    }, [segmentId, isMultiCity, segment]);
    var onSwapHandler = useCallback(function () {
        if (isMultiCity) {
            swapMultiLocations(segmentId);
        }
        else {
            swapLocations();
        }
    }, [isMultiCity, segmentId, swapLocations, swapMultiLocations]);
    return (React.createElement("div", { className: classnames(css.segment, (_b = {}, _b[css.alternative] = alternative, _b[css.segment_advanced] = proMode, _b)) },
        React.createElement("div", { className: classnames(css.wrapper, (_c = {}, _c[css.withMultiCity] = isMultiCity, _c)) },
            React.createElement("div", { className: "".concat(css.cell, " ").concat(css.cell_location) },
                React.createElement("div", { className: css.departureInputWrapper },
                    React.createElement(OverrideComponent, { componentProps: {
                            type: LocationType.Departure,
                            segmentId: segmentId,
                            locations: {
                                departure: segment.departure,
                                arrival: segment.arrival
                            },
                            setLocation: onSetLocation,
                            onSwap: onSwapHandler,
                            recentSearches: recentSearches,
                            withMultiCity: isMultiCity
                        }, component: { SearchFormLocation: LocationField } }))),
            React.createElement("div", { className: "".concat(css.cell, " ").concat(css.cell_location, " ").concat(css.cell_location_arrival) },
                React.createElement(OverrideComponent, { componentProps: {
                        type: LocationType.Arrival,
                        segmentId: segmentId,
                        locations: {
                            departure: segment.departure,
                            arrival: segment.arrival
                        },
                        setLocation: onSetLocation,
                        recentSearches: recentSearches,
                        withMultiCity: isMultiCity
                    }, component: { SearchFormLocation: LocationField } })),
            React.createElement("div", { className: classnames(css.cell, css.cell_date, (_d = {},
                    _d[css.cell_fixed] = isMultiCity,
                    _d)) },
                React.createElement(OverrideComponent, { componentProps: {
                        valueClassName: css.datepicker_value,
                        passengers: passengers,
                        dateTo: segment.date,
                        dateBack: segment.dateBack,
                        selectedDates: selectedDates,
                        onDateChange: onDateChange,
                        withDateBack: !isMultiCity,
                        showPrices: showPricesInCalendar,
                        clearDates: clearDates,
                        segmentId: segmentId,
                        locations: {
                            departure: segment.departure,
                            arrival: segment.arrival
                        }
                    }, component: {
                        SearchFormSegmentDatepicker: Datepicker
                    } }))),
        isMultiCity && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { onClick: segmentId === 0 ? addSegment : onRemoveSegment, className: css.control }, segmentId === 0 ? Add : Minus)))));
}, function (prevProps, nextProps) {
    return prevProps.segmentId === nextProps.segmentId &&
        prevProps.segment === nextProps.segment &&
        !datesAreNotEqual(prevProps.selectedDates, nextProps.selectedDates) &&
        prevProps.isMultiCity === nextProps.isMultiCity &&
        prevProps.canRemoveSegment === nextProps.canRemoveSegment;
});
var mapStateToProps = function (state, ownProps) {
    var getSegment = makeGetSegment(ownProps.segmentId);
    return function (state, ownProps) {
        return __assign(__assign({}, ownProps), { selectedDates: getSelectedDates(state), isMultiCity: isMultiCity(state), canRemoveSegment: canRemoveSegment(state), segment: getSegment(state), recentSearches: getRecentSearches(state), passengers: getPassengersForRequest(state) });
    };
};
var mapDispatchToProps = {
    removeSegment: removeSegment,
    setDate: setDate,
    setDateMultiCity: setDateMultiCity,
    clearDates: clearDates,
    setLocation: setLocation,
    swapLocations: swapLocations,
    swapMultiLocations: swapMultiLocations,
    addRecentSearch: addRecentSearch,
    addSegment: addSegment
};
export default connect(mapStateToProps, mapDispatchToProps)(Segment);
