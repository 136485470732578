import { __assign, __read, __spreadArray } from "tslib";
import { DataLayerEvent, Recommendation } from '../types';
var OrderDataLayer = /** @class */ (function () {
    function OrderDataLayer(instance) {
        this.dataLayer = instance;
    }
    OrderDataLayer.prototype.pushToDataLayer = function (eventType, services, itemListName, params) {
        var _this = this;
        if (itemListName === void 0) { itemListName = null; }
        if (params === void 0) { params = {}; }
        if (this.isInitialized() && this.dataLayer) {
            try {
                this.dataLayer.pushToDataLayer(eventType, services
                    .reduce(function (newServices, service) {
                    if (service.forAllSegments) {
                        return __spreadArray(__spreadArray([], __read(newServices), false), __read(_this.getFlightSegments().map(function (segment, key) { return (__assign(__assign({}, service), { price: key === 0 ? service.price : 0, segmentId: segment.id })); })), false);
                    }
                    return __spreadArray(__spreadArray([], __read(newServices), false), [service], false);
                }, [])
                    .map(function (service) { return _this.getEcommerceItem(service); }), params, itemListName);
            }
            catch (e) {
                console.error(e);
            }
        }
    };
    OrderDataLayer.prototype.isInitialized = function () {
        return this.initialized;
    };
    OrderDataLayer.prototype.setOrderStatus = function () {
        this.dataLayer.setCommonParams({
            item_category2: this.getOrderStatus()
        });
    };
    OrderDataLayer.prototype.setOrder = function (order) {
        this.order = order;
        this.setOrderStatus();
        this.initialized = true;
    };
    OrderDataLayer.prototype.getOrder = function () {
        return this.order;
    };
    OrderDataLayer.prototype.getEcommerceItem = function (service) {
        var _a, _b;
        var segmentIds = service.segmentIds ? service.segmentIds : [(_a = service.segmentId) !== null && _a !== void 0 ? _a : '0'], segments = this.getFlightSegments().filter(function (segment) { return segmentIds.includes(segment.id); }), firstSegment = segments[0];
        var flightDestinations = segments.map(function (segment) { return segment.departure.airport.iata; });
        flightDestinations.push(segments[segments.length - 1].arrival.airport.iata);
        return {
            item_brand: service.name,
            item_category: service.category,
            item_id: "".concat(firstSegment.marketingAirline.iata, "-").concat(firstSegment.flightNumber),
            item_name: flightDestinations.join('-'),
            item_category3: service.isRecommend ? Recommendation.Recommended : Recommendation.NoRecommended,
            price: service.price,
            item_category4: firstSegment.departure.date,
            item_category5: service.travellersTypes || [],
            quantity: (_b = service.count) !== null && _b !== void 0 ? _b : null
        };
    };
    OrderDataLayer.prototype.purchaseOrder = function (transactionId, services) {
        this.pushToDataLayer(DataLayerEvent.Purchase, services, null, {
            transaction_id: transactionId
        });
    };
    OrderDataLayer.prototype.setViewItem = function (service) {
        this.pushToDataLayer(DataLayerEvent.ViewItem, [service]);
    };
    OrderDataLayer.prototype.beginCheckout = function (services) {
        this.pushToDataLayer(DataLayerEvent.Checkout, services);
    };
    OrderDataLayer.prototype.setServicesViewItemList = function (itemListName, services) {
        if (!!services.length) {
            this.pushToDataLayer(DataLayerEvent.ViewItemList, services, itemListName);
        }
    };
    OrderDataLayer.prototype.addServicesToCart = function (services) {
        if (services.length) {
            this.pushToDataLayer(DataLayerEvent.AddToCart, services);
        }
    };
    OrderDataLayer.prototype.removeServicesFromCart = function (services) {
        if (services.length) {
            this.pushToDataLayer(DataLayerEvent.RemoveFromCart, services);
        }
    };
    return OrderDataLayer;
}());
export default OrderDataLayer;
