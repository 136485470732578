import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import DesktopPassenger from '../DesktopPassenger/DesktopPassenger';
import { useTheme } from '../../../../../theme';
import DoubleSeat from '../../DoubleSeat/DoubleSeat';
var MobilePassenger = function (_a) {
    var _b;
    var className = _a.className, isActive = _a.isActive, isActiveDoubleSeat = _a.isActiveDoubleSeat, isDisabled = _a.isDisabled, hasSeatsByPassenger = _a.hasSeatsByPassenger, showDoubleSeatInfo = _a.showDoubleSeatInfo, onClick = _a.onClick, onClearSeat = _a.onClearSeat, toggleExtraSeat = _a.toggleExtraSeat, props = __rest(_a, ["className", "isActive", "isActiveDoubleSeat", "isDisabled", "hasSeatsByPassenger", "showDoubleSeatInfo", "onClick", "onClearSeat", "toggleExtraSeat"]);
    var theme = useTheme('SeatMap').MobilePassenger;
    var onClearSeatHandler = function () {
        if (isActive) {
            onClearSeat();
        }
    };
    var toggleExtraSeatHandler = function (active) {
        if (isActive) {
            toggleExtraSeat(active);
        }
    };
    return (React.createElement("div", { className: cn(className, theme.container, (_b = {},
            _b[theme.active] = isActive,
            _b[theme.disabled] = isDisabled,
            _b)), onClick: onClick },
        React.createElement(DesktopPassenger, __assign({ className: theme.passenger, showSelect: false, onClearSeat: onClearSeatHandler }, props)),
        toggleExtraSeat && !isDisabled && (React.createElement(DoubleSeat, { className: theme.doubleSeat, isActive: isActiveDoubleSeat, hasSeatsByPassenger: hasSeatsByPassenger, showInfo: showDoubleSeatInfo, toggleExtraSeat: toggleExtraSeatHandler }))));
};
export default MobilePassenger;
