import { __read } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { Link, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isActionRoute, isPathRoute } from '../../utils';
import { Badge } from '../../../Badge/Badge';
var MobileMenu = function (_a) {
    var _b;
    var routes = _a.routes, selectedRoute = _a.selectedRoute, userInfo = _a.userInfo;
    var _c = __read(useState(false), 2), isOpenedMenu = _c[0], setOpenedMenu = _c[1];
    var css = useTheme('Sidebar').MobileMenu;
    var t = useTranslation('Account').t;
    var title = useMemo(function () { var _a; return (_a = routes.find(function (item) { return (item === null || item === void 0 ? void 0 : item.pathname) === selectedRoute; })) === null || _a === void 0 ? void 0 : _a.title; }, [
        routes,
        selectedRoute
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css.wrapper, onClick: function () { return setOpenedMenu(!isOpenedMenu); } },
            React.createElement("span", { className: cn(css.button, (_b = {},
                    _b[css.button_opened] = isOpenedMenu,
                    _b)) }),
            title && React.createElement("span", { className: css.title }, t(title))),
        React.createElement(Collapse, { in: isOpenedMenu, classes: { wrapperInner: css.content, root: css.root } },
            React.createElement("div", { className: css.user },
                React.createElement("div", { className: css.user__info },
                    React.createElement("span", null,
                        userInfo.firstName,
                        " ",
                        userInfo.lastName))),
            React.createElement(List, { className: 'list' }, routes.map(function (route, index) {
                var isSelected = false;
                if (isPathRoute(route)) {
                    var match = matchPath(selectedRoute, {
                        path: route.pathname,
                        exact: !route.hasChild
                    });
                    isSelected = !!match;
                }
                var item = (React.createElement(ListItem, { button: true, selected: isSelected, classes: {
                        root: css.menu__item,
                        selected: css.menu__item_selected
                    } },
                    React.createElement(ListItemIcon, { className: css.icon },
                        React.createElement(SvgIcon, { viewBox: '0 0 20 20' }, route.icon)),
                    React.createElement(ListItemText, { className: css.item__text, primary: t(route.title) }),
                    route.badgeContent > 0 && React.createElement(Badge, { value: route.badgeContent, className: css.badge })));
                if (isActionRoute(route)) {
                    return (React.createElement("div", { key: index, onClick: route.action }, item));
                }
                else if (route.isRedirect) {
                    return (React.createElement("a", { key: index, href: route.pathname, target: "_blank" }, item));
                }
                else {
                    return (React.createElement(Link, { key: index, to: route.pathname, onClick: function () { return setOpenedMenu(false); } }, item));
                }
            })))));
};
export default MobileMenu;
