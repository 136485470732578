import * as React from 'react';
import Money from '../../../Money';
import { useTheme } from '../../../theme';
import { Currency } from '../../../enums';
import { useTranslation } from 'react-i18next';
import { TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../../../utils';
var CommonContent = function (_a) {
    var passengers = _a.passengers, services = _a.services, getServiceName = _a.getServiceName, flight = _a.flight;
    var theme = useTheme('RefundSummary').CommonContent;
    var t = useTranslation('Exchange').t;
    var getDefaultServiceName = function (service, productId, passengerId, count) {
        var traveller = passengers.find(function (passenger) { return passenger.id === passengerId; });
        var selectedService = traveller.services.gdsServices.services.find(function (service) {
            return service.products.some(function (product) { return product.id === productId; });
        });
        var segments = selectedService.segmentIds.map(function (segmentId) {
            var segment;
            if (Array.isArray(flight)) {
                // checkin segments array
                segment = flight.find(function (segment) { return segment.id === segmentId; });
            }
            else {
                segment = flight.segments.find(function (segment) { return segment.segment.id === segmentId; }).segment;
            }
            return "".concat(segment.departure.airport.iata, " - ").concat(segment.arrival.airport.iata);
        });
        return "".concat(segments.join(','), ", ").concat(count, " x ").concat(service.name);
    };
    var passengersServices = React.useMemo(function () {
        var passengersServices = [];
        Object.keys(services).forEach(function (passengerId) {
            var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; }), firstName = getUserValue(passenger, TravellerFieldEnum.FirstName), lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
            var passengerServices = [];
            Object.keys(services[passengerId]).forEach(function (emd) {
                Object.keys(services[passengerId][emd].services).forEach(function (serviceId) {
                    var _a, _b, _c, _d;
                    var service = services[passengerId][emd].services[serviceId];
                    passengerServices.push({
                        serviceId: serviceId,
                        name: getServiceName
                            ? getServiceName(service.service, (_b = (_a = service.products[0]) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.id, passengerId, service.products.length)
                            : getDefaultServiceName(service.service, (_d = (_c = service.products[0]) === null || _c === void 0 ? void 0 : _c.product) === null || _d === void 0 ? void 0 : _d.id, passengerId, service.products.length),
                        count: service.products.length,
                        totalRefund: service.sumToRefund
                    });
                });
            });
            passengersServices.push({
                passengerId: passengerId,
                passengerName: "".concat(lastName, " ").concat(firstName).toLowerCase(),
                services: passengerServices
            });
        });
        return passengersServices;
    }, [passengers, services]);
    var totalSumToRefund = React.useMemo(function () {
        var total = {
            amount: 0,
            currency: Currency.RUB
        };
        passengersServices.forEach(function (passengerService) {
            passengerService.services.forEach(function (service) {
                total.amount += service.totalRefund.amount;
                total.currency = service.totalRefund.currency;
            });
        });
        return total;
    }, [passengersServices]);
    return (React.createElement("div", { className: theme.content },
        React.createElement("div", { className: theme.services }, passengersServices.map(function (passengerServices) {
            return (React.createElement("div", { className: theme.passenger, key: passengerServices.passengerId },
                React.createElement("div", { className: theme.passengerName }, passengerServices.passengerName),
                React.createElement("div", null, passengerServices.services.map(function (service) {
                    return (React.createElement("div", { className: theme.service, key: service.serviceId },
                        React.createElement("div", { className: theme.name }, service.name),
                        React.createElement("div", null,
                            React.createElement(Money, { moneyClassName: theme.price, money: service.totalRefund }))));
                }))));
        })),
        React.createElement("div", { className: theme.totalRefund },
            t('Refund'),
            ": ",
            React.createElement(Money, { money: totalSumToRefund }))));
};
export default CommonContent;
