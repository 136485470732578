import * as React from 'react';
import ModalX from '../../../BaseComponents/ModalX';
import { Money } from '../../../Money';
import Button from '../../../BaseComponents/Button';
import Checkbox from '../../../BaseComponents/Checkbox';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { Divider } from '@mui/material';
initI18n('Transfer');
var TransferSelectorModal = function (_a) {
    var service = _a.service, passengers = _a.passengers, segments = _a.segments, isOpen = _a.isOpen, onClose = _a.onClose, servicesState = _a.servicesState, onClear = _a.onClear, onChange = _a.onChange, totalPrice = _a.totalPrice, onSubmit = _a.onSubmit;
    var t = useTranslation('Transfer').t;
    var css = useTheme('Transfer').TransferSelectorModal;
    var Header = function () {
        return (React.createElement("div", null,
            React.createElement("div", { className: css.serviceName }, service.name),
            React.createElement("div", { className: css.serviceDescription }, service.description)));
    };
    var Footer = function () {
        return (React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.total },
                React.createElement("span", { className: css.price },
                    t('Total'),
                    ":",
                    React.createElement(Money, { money: totalPrice })),
                React.createElement(Divider, { className: css.divider, orientation: "vertical" }),
                React.createElement("div", { className: css.clear },
                    React.createElement("span", { onClick: onClear }, t('Clear')))),
            React.createElement("div", { className: css.footerControls },
                React.createElement(Button, { variant: "secondary", onClick: onClose, classes: { root: css.cancel } }, t('Cancel')),
                React.createElement(Button, { variant: "action", onClick: onSubmit, classes: { root: css.confirm } }, t('Confirm')))));
    };
    return (React.createElement(ModalX, { isOpen: isOpen, onClose: onClose, title: React.createElement(Header, null), footer: React.createElement(Footer, null), classes: { paper: css.paper } },
        React.createElement("div", { className: css.content }, service.allowedSegments.map(function (segmentGroup, index) {
            return (React.createElement(React.Fragment, { key: index }, segmentGroup.map(function (segmentId) {
                return (React.createElement("div", { key: segmentId },
                    React.createElement("div", { className: css.heading },
                        React.createElement("div", { className: css.date }, segments[segmentId].date),
                        React.createElement("div", { className: css.city }, segments[segmentId].city),
                        React.createElement("div", { className: css.cost }, t('Cost'))),
                    service.allowedPassengers.map(function (passengerGroup, index) {
                        return passengerGroup.map(function (passengerId) {
                            var passengerService = servicesState[passengerId][segmentId];
                            if (!passengerService) {
                                return null;
                            }
                            var disabled = passengerService.confirmed;
                            return (React.createElement("div", { className: css.passenger, key: passengerId, onClick: function () {
                                    return disabled ? null : onChange(passengerId, segmentId);
                                } },
                                React.createElement(Checkbox, { className: css.checkbox, disabled: disabled, checked: passengerService.selected || passengerService.confirmed }),
                                React.createElement("div", { className: css.passengerNumber }, passengers[passengerId].number),
                                React.createElement("div", { className: css.passengerName }, passengers[passengerId].fullName),
                                React.createElement(Money, { moneyClassName: css.passengerPrice, money: service.price })));
                        });
                    })));
            })));
        }))));
};
export default TransferSelectorModal;
