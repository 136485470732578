import { __assign } from "tslib";
import WebskyServicesAnalytics from '../DataLayerAnalytics/WebskyServicesAnalytics';
import getOrderDataLayer from '../DataLayerAnalytics/orderDataLayerInstance';
/**
 * Calculate the difference in the number of services in two states
 *
 * @param oldSelectedServices array of services before changing
 * @param newSelectedServices array of services after changing
 *
 * @return array of Service with calculated field `delta`. If `delta` > 0, the number of service items was increased.
 * If `delta` < 0, the number was decreased.
 */
export var servicesDiff = function (oldSelectedServices, newSelectedServices) {
    if (!oldSelectedServices.length) {
        return newSelectedServices.map(function (service) { return (__assign(__assign({}, service), { delta: service.count })); });
    }
    if (!newSelectedServices.length) {
        return oldSelectedServices.map(function (service) { return (__assign(__assign({}, service), { delta: 0 - service.count })); });
    }
    var servicesModifyMap = new Map();
    oldSelectedServices.forEach(function (service) {
        servicesModifyMap.set(service.id, {
            count: service.count,
            service: service.service,
            delta: 0 - service.count
        });
    });
    newSelectedServices.forEach(function (service) {
        if (servicesModifyMap.has(service.id)) {
            var existsService = servicesModifyMap.get(service.id);
            servicesModifyMap.set(service.id, __assign(__assign({}, existsService), { count: service.count, delta: service.count + existsService.delta }));
        }
        else {
            servicesModifyMap.set(service.id, {
                count: service.count,
                service: service.service,
                delta: service.count
            });
        }
    });
    var result = [];
    servicesModifyMap.forEach(function (value, serviceId) {
        result.push({
            delta: value.delta,
            id: serviceId,
            count: value.count,
            service: value.service
        });
    });
    return result;
};
var filterInsurances = function (programs) {
    var uniqueItems = [];
    var idsSet = new Set();
    programs.forEach(function (program) {
        if (!idsSet.has(program.code)) {
            idsSet.add(program.code);
            uniqueItems.push(program);
        }
    });
    return uniqueItems;
};
export var getInsurancesDiff = function (oldSelected, newSelected) {
    var diff = servicesDiff(filterInsurances(oldSelected).map(function (insurance) { return ({
        id: insurance.code,
        count: 1,
        service: insurance
    }); }), filterInsurances(newSelected).map(function (insurance) { return ({
        id: insurance.code,
        count: 1,
        service: insurance
    }); }));
    return {
        added: diff.filter(function (service) { return service.delta > 0; }).map(function (service) { return service.service; }),
        removed: diff.filter(function (service) { return service.delta < 0; }).map(function (service) { return service.service; })
    };
};
export var analytics_addInsurance = function (insurance, travellers) {
    getOrderDataLayer().addServicesToCart(WebskyServicesAnalytics.insurances([insurance], travellers));
};
export var analytics_removeInsurance = function (insurance, travellers) {
    getOrderDataLayer().removeServicesFromCart(WebskyServicesAnalytics.insurances([insurance], travellers));
};
