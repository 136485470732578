import * as React from 'react';
import { Provider } from 'react-redux';
import { useGetPaxSectionsQuery } from '@websky/graphql';
import { createStore } from '../store';
import { setCommunicationCategory } from '../store/actions';
import { useCallback, useEffect } from 'react';
import { getUnreadTotal } from '../store/selectors';
import { useConfig } from '../../context';
var COMMUNICATION_UNREAD_TOTAL_EVENT = 'webskyCommunicationUnreadTotal';
var CommunicationUnreadTotalProvider = function (_a) {
    var children = _a.children;
    var store = createStore();
    var showCommunications = useConfig().Account.showCommunications;
    useGetPaxSectionsQuery({
        variables: { withLastMessage: true },
        skip: !showCommunications,
        onCompleted: function (data) {
            var _a;
            if ((_a = data === null || data === void 0 ? void 0 : data.PaximizerCommunicationSections) === null || _a === void 0 ? void 0 : _a.sections) {
                store.dispatch(setCommunicationCategory(data.PaximizerCommunicationSections.sections));
                var state = store.getState();
                var communicationUnreadTotal = getUnreadTotal(state);
                onUnreadTotalEvent(communicationUnreadTotal);
            }
        }
    });
    var onUnreadTotalEvent = useCallback(function (communicationUnreadTotal) {
        var event = new CustomEvent(COMMUNICATION_UNREAD_TOTAL_EVENT, {
            detail: { communicationUnreadTotal: communicationUnreadTotal }
        });
        document.dispatchEvent(event);
    }, []);
    useEffect(function () {
        store.subscribe(function () {
            var state = store.getState();
            var communicationUnreadTotal = getUnreadTotal(state);
            onUnreadTotalEvent(communicationUnreadTotal);
        });
    }, [store]);
    return React.createElement(Provider, { store: store }, children);
};
export default CommunicationUnreadTotalProvider;
