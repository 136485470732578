import { parse, isAfter, isBefore } from 'date-fns';
import { TravellerFieldEnum } from '@websky/graphql';
import { getUserValue } from '../../../utils';
export var filterProfileByBirthDate = function (profile, validations) {
    if (validations === null || validations === void 0 ? void 0 : validations.length) {
        var profileBirthDate = getUserValue(profile, TravellerFieldEnum.BirthDate);
        var validationsWithDates = validations.filter(function (validation) { return validation.minDate && validation.maxDate; });
        if (profileBirthDate && validationsWithDates.length) {
            var parseProfileBirthDate_1 = parse(profileBirthDate, 'dd.MM.yyyy', new Date());
            return validationsWithDates.some(function (validation) {
                var minDate = new Date(validation.minDate);
                var maxDate = new Date(validation.maxDate);
                return !isAfter(parseProfileBirthDate_1, maxDate) && !isBefore(parseProfileBirthDate_1, minDate);
            });
        }
    }
    return true;
};
