import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { useField } from 'react-final-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Input from '../../Input';
import { emailRegex, initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import PhoneInput from '../../PhoneInput';
import Button from '../../Button/Button';
import SocialAuth from '../../SocialAuth';
import { defaultTheme, Provider } from '../../SocialAuth/theme';
import LoginBlock from './LoginBlock/LoginBlock';
import { useTheme } from '../../theme';
import { InputType } from '../../Passenger/types';
import { hideText, validate } from '../../Passenger/utils';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { timerIcon } from '../Icons';
import FieldsOrder from './FieldsOrder/FieldsOrder';
import { useMediaQuery } from 'react-responsive';
import { WarningRounded } from '../../Icons';
import { LoginType, useAccountExistenceLazyQuery } from '@websky/graphql';
initI18n('Contacts');
var debounceTimeout = 0;
var formatPhoneNumberIntl = require('react-phone-number-input').formatPhoneNumberIntl;
var Contacts = memo(function (props) {
    var _a;
    var theme = useTheme('Contacts').ContactsStyles;
    var _b = __read(useState(null), 2), isUserExist = _b[0], setUserExist = _b[1];
    var t = useTranslation('Contacts').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var escapedT = useCallback(function (label) {
        return typeof label !== 'undefined'
            ? t(label, {
                // This is necessary to avoid colon problems in error messages.
                nsSeparator: '|',
                keySeparator: '>'
            })
            : label;
    }, []);
    var _c = __read(useAccountExistenceLazyQuery(), 2), checkAccountExistence = _c[0], accountExistenceData = _c[1].data;
    var updateUserExistenceState = function (accountExistenceData) {
        var _a, _b;
        if ((_a = accountExistenceData === null || accountExistenceData === void 0 ? void 0 : accountExistenceData.AccountExistence) === null || _a === void 0 ? void 0 : _a.result) {
            setUserExist('login');
        }
        else if (!((_b = accountExistenceData === null || accountExistenceData === void 0 ? void 0 : accountExistenceData.AccountExistence) === null || _b === void 0 ? void 0 : _b.result)) {
            setUserExist('register');
        }
    };
    React.useEffect(function () {
        updateUserExistenceState(accountExistenceData);
    }, [accountExistenceData]);
    var phone = useField(props.phone.name, {
        type: props.phone.input,
        initialValue: props.phone.defaultValue,
        validate: function (value) { return escapedT(validate(value, {}, InputType.Phone, props.phone.validations)); }
    });
    var email = useField(props.email.name, {
        type: props.email.input,
        initialValue: props.email.defaultValue,
        validate: function (value) { return escapedT(validate(value, {}, InputType.Text, props.email.validations)); }
    });
    var onLoginOpenHandler = function () {
        props.onLoginOpen(props.loginType === LoginType.Email
            ? email.input.value
            : props.loginType === LoginType.Phone
                ? phone.input.value
                : null);
    };
    var subscribed = useField('subscribed', {
        type: 'checkbox',
        defaultValue: false
    });
    var onChangeHandler = useCallback(function (event) {
        var value = event.target.value;
        if (props.open && !props.isAuthorized && emailRegex.test(event.target.value)) {
            clearTimeout(debounceTimeout);
            debounceTimeout = window.setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    checkAccountExistence({
                        variables: {
                            params: {
                                login: value,
                                loginType: LoginType.Email
                            }
                        }
                    });
                    return [2 /*return*/];
                });
            }); }, 500);
            updateUserExistenceState(accountExistenceData);
        }
        else {
            setUserExist(null);
        }
        email.input.onChange(event);
    }, [accountExistenceData]);
    var userInfo = [];
    if (email.input.value) {
        userInfo.push(hideText(email.input.value));
    }
    if (phone.input.value) {
        userInfo.push(formatPhoneNumberIntl("+".concat(phone.input.value)));
    }
    var inputVariant = props.variant ? props.variant : isMobile ? 'standard' : 'filled';
    return (React.createElement("div", { className: cn(theme.contacts, (_a = {}, _a[theme.short] = !props.open, _a)) },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.description__header }, t('Your contacts')),
            !props.open && (React.createElement("div", { className: theme.customer__info },
                React.createElement("div", { className: cn(theme.field) }, userInfo.join(', '))))),
        props.open && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.form__container },
                React.createElement("div", { className: theme.form },
                    React.createElement("div", { className: theme.description },
                        React.createElement("div", { className: theme.description__text },
                            WarningRounded,
                            React.createElement("div", { className: theme.description__content, dangerouslySetInnerHTML: {
                                    __html: t('Contact details are necessary for us to be able to pass on the latest flight information.')
                                } }))),
                    React.createElement("div", { className: theme.fields },
                        React.createElement(FieldsOrder, { reverse: props.loginType === LoginType.Email },
                            React.createElement("div", { className: theme.field },
                                React.createElement(PhoneInput, { disabled: props.isInputsReadonly, className: theme.field__contact, value: phone.input.value, textFieldsProps: __assign(__assign({}, phone.input), { variant: inputVariant, disabled: props.isInputsReadonly, label: t('Phone'), type: 'tel', helperText: ((phone.meta.modified && phone.meta.touched) ||
                                            phone.meta.submitFailed) &&
                                            (phone.meta.error || phone.meta.submitError), error: ((phone.meta.modified && phone.meta.touched) ||
                                            phone.meta.submitFailed) &&
                                            (!!phone.meta.error || !!phone.meta.submitError) }) })),
                            React.createElement("div", { className: theme.field },
                                React.createElement(Input, { variant: inputVariant, absoluteHintPosition: true, inputClassName: theme.field__contact, disabled: props.isInputsReadonly, autoFocus: props.loginType === LoginType.Email && props.autofocus, TextFieldProps: __assign(__assign({}, email.input), { value: email.input.value !== 'null' ? email.input.value : '', label: t('Email'), type: 'email', helperText: ((email.meta.modified && email.meta.touched) ||
                                            email.meta.submitFailed) &&
                                            (email.meta.error || email.meta.submitError), error: ((email.meta.modified && email.meta.touched) ||
                                            email.meta.submitFailed) &&
                                            (!!email.meta.error || !!email.meta.submitError), onChange: onChangeHandler }) }))))),
                React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: !props.isAuthorized
                        ? !!isUserExist && props.showSubscribeCheckbox
                        : props.showSubscribeCheckbox },
                    React.createElement("div", { className: theme.additional },
                        React.createElement(FormControlLabel, { classes: {
                                label: theme.checkbox__label
                            }, label: t('Receive a mailing list with promotional offers and discounts'), control: React.createElement(Checkbox, { color: "primary", classes: {
                                    root: theme.checkbox,
                                    checked: theme.checkbox_checked
                                }, checked: subscribed.input.checked, inputProps: subscribed.input }) }))),
                !props.isAuthorized && (React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: !!isUserExist && !props.isFormLocked },
                    React.createElement("div", { className: cn(theme.footer, theme.footer_login) },
                        React.createElement(MediaQuery, { maxWidth: "mobile" },
                            React.createElement("div", { className: theme.footer__timerIcon }, timerIcon)),
                        React.createElement("div", { className: theme.footer__inner },
                            React.createElement("div", { className: theme.footer__text },
                                React.createElement(MediaQuery, { minWidth: "mobile" }, t(isUserExist === 'login'
                                    ? 'Want to speed up passenger data entry? Log in and continue.'
                                    : 'Want to speed up passenger data entry? Sign up and continue.')),
                                React.createElement(MediaQuery, { maxWidth: "mobile" },
                                    React.createElement("span", null, t('Want to speed up passenger data entry?')))),
                            React.createElement(Button, { onClick: onLoginOpenHandler }, t(isUserExist === 'login' ? 'Log in' : 'Sign up'))),
                        React.createElement(MediaQuery, { minWidth: "mobile" },
                            React.createElement("div", { className: theme.footer__social },
                                React.createElement(Provider, { value: {
                                        SocialAuth: __assign(__assign({}, defaultTheme.SocialAuth), { button: theme.footer__social__button })
                                    } },
                                    React.createElement(SocialAuth, null)))))))))),
        React.createElement(Collapse, { in: props.isFormLocked, unmountOnExit: true },
            React.createElement(LoginBlock, { onClick: onLoginOpenHandler, onSkipClick: props.unlockForm }))));
});
export default Contacts;
