import { splitMoney } from '../Results/utils';
export var getFormattedAmount = function (money, multiplyBy, roundType, splitMoneyMin) {
    if (multiplyBy === void 0) { multiplyBy = 1; }
    if (splitMoneyMin === void 0) { splitMoneyMin = 10000; }
    var amount = money === null || money === void 0 ? void 0 : money.amount;
    if (!amount) {
        return '';
    }
    switch (roundType) {
        case 'ceil':
            amount = Math.ceil(amount);
            break;
        case 'floor':
            amount = Math.floor(amount);
            break;
        case 'round':
            amount = Math.round(amount);
            break;
    }
    amount *= multiplyBy;
    return splitMoney(amount, 2, splitMoneyMin).join(' ');
};
