import { __assign } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OrderRefundStatus from '../../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import { ExareStatus } from '@websky/graphql';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../index';
import { ChevronRightIcon } from '../../../../../AircraftPopup/Icons';
import { useTheme } from '../../../../../theme';
var ExchangeCheckoutStatus = function (props) {
    var status = props.status, onGoToTicket = props.onGoToTicket, passengersNames = props.passengersNames, splittedOrderId = props.splittedOrderId;
    var theme = useTheme('Exchange').ExchangeCheckoutStatus;
    var history = useHistory();
    var t = useTranslation('Exchange').t;
    var titleRenderer = useCallback(function () {
        switch (status) {
            case ExareStatus.InProcess:
            case ExareStatus.Refused:
            case ExareStatus.Rejected:
            case ExareStatus.TimelimitExpired:
                return t('Request for changes');
            case ExareStatus.Finished:
                return (React.createElement("span", null, [
                    t('Successfully create new booking'),
                    splittedOrderId,
                    passengersNames ? "".concat(t('for'), " ").concat(passengersNames) : null
                ]
                    .filter(Boolean)
                    .join(' ')));
            default:
                return t('Request for changes');
        }
    }, [status, passengersNames, splittedOrderId]);
    var handleGoToNewBooking = useCallback(function () {
        history.push("/booking/".concat(splittedOrderId));
        window.location.reload();
    }, [splittedOrderId]);
    var renderStatus = status === ExareStatus.Finished && splittedOrderId
        ? useCallback(function () {
            return (React.createElement(Button, { onClick: handleGoToNewBooking },
                t('View new booking'),
                "\u00A0",
                splittedOrderId,
                React.createElement("span", { className: theme.icon }, ChevronRightIcon)));
        }, [status, splittedOrderId, handleGoToNewBooking])
        : null;
    return (React.createElement(OrderRefundStatus, __assign({}, props, { customTranslate: t, renderTitle: titleRenderer, onGoToTicket: onGoToTicket, renderStatus: renderStatus })));
};
export default ExchangeCheckoutStatus;
