import * as React from 'react';
import { memo, useEffect } from 'react';
import { useLogout } from '../../../Modules/Account/hooks';
import Loader from '../../../Loader';
var Logout = memo(function () {
    var onLogout = useLogout('/account').onLogout;
    useEffect(function () {
        onLogout();
    }, []);
    return React.createElement(Loader, null);
});
export default Logout;
