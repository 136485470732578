import { __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDocumentType, useOrderDocumentQuery } from '@websky/graphql';
import { transferLogo } from '../../../../../../../../Aeroexpress/icons';
import { Button, FileDownload } from '../../../../../../../../index';
import { useTheme } from '../../../../../../../../theme';
import { captureErrorSentry, downloadPDF } from '../../../../../../../../utils';
var DownloadAeroexpress = function (props) {
    var orderId = props.orderId, setLoading = props.setLoading;
    var t = useTranslation('Aeroexpress').t;
    var css = useTheme('Checkin').DownloadAeroexpress;
    var getDocuments = useOrderDocumentQuery({ skip: true }).refetch;
    var handleDownloadDocuments = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getDocuments({
                            params: { orderId: orderId, documentType: OrderDocumentType.AeroexpressTicket }
                        })];
                case 2:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.OrderDocument) {
                        downloadPDF(data === null || data === void 0 ? void 0 : data.OrderDocument, 'aeroexpress.pdf');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    captureErrorSentry("Error while loading checkin aeroexpress tickets");
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [getDocuments, orderId, props.travellers]);
    return (React.createElement("div", { className: css.download },
        React.createElement("span", { className: css.logo }, transferLogo),
        React.createElement(Button, { onClick: handleDownloadDocuments, className: css.button },
            React.createElement("span", { className: css.downloadIcon }, FileDownload),
            t('Download tickets'))));
};
export default DownloadAeroexpress;
