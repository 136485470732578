import { __generator } from "tslib";
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_RECENT_SEARCH, setRecentSearches } from './actions';
import { get, set } from '../../../cache';
import { RECENT_SEARCHES_KEY } from '../../../cache';
function worker(_a) {
    var searches;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                searches = get(RECENT_SEARCHES_KEY, []);
                searches = searches.filter(function (location) { return location.iata !== payload.iata; });
                searches.unshift(payload);
                searches = searches.slice(0, 3);
                set(RECENT_SEARCHES_KEY, searches);
                return [4 /*yield*/, put(setRecentSearches(searches))];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export default function recentSearchesSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ADD_RECENT_SEARCH, worker)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
