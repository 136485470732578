import { __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import ServiceWithSegments from '../../ServiceWithSegments/ServiceWithSegments';
import ServiceWrapper from './ServiceWrapper/ServiceWrapper';
import { getSegmentsWithMeal } from '../utils';
import { useTheme } from '../../theme';
initI18n('MealService');
var MealService = function (_a) {
    var _b = _a.selectedMeals, selectedMeals = _b === void 0 ? [] : _b, _c = _a.initialSelectedMeals, initialSelectedMeals = _c === void 0 ? [] : _c, recommendedMeals = _a.recommendedMeals, isLoading = _a.isLoading, mealRenderer = _a.mealRenderer, onMobileProceed = _a.onMobileProceed, onMobileBack = _a.onMobileBack, meals = _a.meals, segments = _a.segments, onClose = _a.onClose;
    var t = useTranslation('MealPage').t;
    var css = useTheme('MealService').ServiceWrapper;
    var segmentsWithMeal = getSegmentsWithMeal(meals);
    var firstSegmentWithMeal = __spreadArray([], __read(segmentsWithMeal), false).sort().shift();
    return (React.createElement(ServiceWithSegments, { title: t('Feel the taste in flight'), initialSegmentId: firstSegmentWithMeal, isLoading: isLoading, onMobileProceed: onMobileProceed, onMobileBack: onMobileBack, enabledSegments: segmentsWithMeal, segments: segments.map(function (segment) { return ({
            id: segment.id,
            title: "".concat(segment.departure.airport.city.name, " \u2014 ").concat(segment.arrival.airport.city.name),
            isDisabled: !segmentsWithMeal.has(segment.id),
            isChecked: initialSelectedMeals.some(function (_a) {
                var segmentId = _a.segmentId;
                return segmentId === segment.id;
            })
        }); }), onClose: onClose, segmentTabsClassName: css.segmentTabs, segmentTabsWrapperClassName: css.segmentTabs_meal, segmentTabsSegmentsClassName: css.segmentTabs_segments }, function (_a) {
        var activeSegmentId = _a.activeSegmentId;
        return (React.createElement(ServiceWrapper, { activeSegmentId: activeSegmentId, mealRenderer: mealRenderer, meals: meals, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, recommendedMeals: recommendedMeals }));
    }));
};
export default MealService;
