import * as React from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { parseDate } from '../../Loyalty/utils';
var MilesHistory = function (_a) {
    var className = _a.className, records = _a.records;
    var css = useTheme('MilesHistory').MilesHistory;
    var t = useTranslation('Account').t;
    var numberWithSpaces = function (x) { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '); };
    return (React.createElement("div", { className: cn(className, css.container) },
        React.createElement("div", { className: css.title }, t('Operations history')),
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.list },
                React.createElement("div", { className: css.listHeader },
                    React.createElement("div", { className: css.listHeader_item }, t('Date')),
                    React.createElement("div", { className: css.listHeader_item }, t('Operation')),
                    React.createElement("div", { className: css.listHeader_item }, t('Balance')),
                    React.createElement("div", { className: css.listHeader_item }, t('Status miles'))),
                records.map(function (_a) {
                    var _b, _c;
                    var recordId = _a.recordId, onTimeUTC = _a.onTimeUTC, description = _a.description, milesInc = _a.milesInc, milesDec = _a.milesDec, miles = _a.miles, statVol = _a.statVol;
                    return (React.createElement("div", { className: css.listItem, key: recordId },
                        React.createElement("div", { className: css.listItem_col }, onTimeUTC && (React.createElement(React.Fragment, null,
                            React.createElement("span", null, format(parseDate(onTimeUTC), 'dd.MM.yy')),
                            React.createElement("br", null),
                            format(parseDate(onTimeUTC), 'HH:mm')))),
                        React.createElement("div", { className: css.listItem_col },
                            React.createElement("span", { className: cn((_b = {},
                                    _b[css.label_accrued] = milesInc > 0,
                                    _b[css.label_spent] = milesDec > 0,
                                    _b)) }, milesInc > 0
                                ? "".concat(t('Assessed'), ":")
                                : milesDec > 0
                                    ? "".concat(t('Purchase for miles'), ":")
                                    : ''),
                            React.createElement("br", null),
                            description || ''),
                        React.createElement("div", { className: css.listItem_col },
                            React.createElement("span", { className: cn(css.number, (_c = {},
                                    _c[css.label_accrued] = milesInc > 0,
                                    _c[css.label_spent] = milesDec > 0,
                                    _c)) }, milesInc > 0
                                ? "+".concat(numberWithSpaces(milesInc))
                                : milesDec > 0
                                    ? "-".concat(numberWithSpaces(milesDec))
                                    : ''),
                            React.createElement("div", { className: cn(css.balance, css.number) }, miles ? numberWithSpaces(miles) : '0')),
                        React.createElement("div", { className: css.listItem_col }, statVol ? numberWithSpaces(statVol) : '0')));
                })))));
};
export default MilesHistory;
