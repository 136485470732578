import * as React from 'react';
import { cityIn } from 'lvovich';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { getDurationString } from '../../../../Results/utils';
import cn from 'classnames';
var StopsInfo = function (_a) {
    var stops = _a.stops, stopCityName = _a.stopCityName, transferDuration = _a.transferDuration, className = _a.className, _b = _a.separator, separator = _b === void 0 ? '-' : _b;
    var t = useTranslation('DesktopFlightInfo').t;
    var theme = useTheme('DesktopFlightRoute').StopsInfoStyles;
    return (React.createElement("div", { className: cn(theme.stop, className) },
        React.createElement("span", { className: theme.stop__label },
            stops.length > 0 &&
                "".concat(t('DesktopFlightInfo:TechnicalLandingIn', {
                    count: stops.length
                }), " ").concat(stops
                    .map(function (stop) {
                    var _a, _b;
                    return "".concat(cityIn((_b = (_a = stop.airport) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name)).concat((stop === null || stop === void 0 ? void 0 : stop.duration) ? " ".concat(separator, " ").concat(getDurationString(stop.duration)) : '');
                })
                    .join(', '), ". "),
            transferDuration &&
                "".concat(t('Stop in'), " ").concat(cityIn(stopCityName), " ").concat(separator, " ").concat(getDurationString(transferDuration)))));
};
export default StopsInfo;
