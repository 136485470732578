import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { ExareStatus } from '@websky/graphql';
import { ArrowForward, Included } from '../../../Icons';
import { Button } from '../../../index';
import { useHistory } from 'react-router-dom';
var REJECTED_STATUSES = [ExareStatus.Rejected, ExareStatus.Refused, ExareStatus.TimelimitExpired];
var WAITING_STATUSES = [ExareStatus.NeedConfirm];
var PROCESSING_STATUSES = [ExareStatus.InProcess, ExareStatus.AwaitingPayment];
var SUCCESS_STATUSES = [ExareStatus.Finished];
var OrderRefundStatus = function (props) {
    var _a;
    var _b;
    var refundTranslate = useTranslation('Refund').t;
    var t = (_b = props.customTranslate) !== null && _b !== void 0 ? _b : refundTranslate;
    var css = useTheme('Refund').OrderRefundStatus;
    var history = useHistory();
    var cssStatusClassModifier = cn((_a = {},
        _a[css.rejected] = REJECTED_STATUSES.includes(props.status),
        _a[css.processing] = PROCESSING_STATUSES.includes(props.status),
        _a[css.waiting] = WAITING_STATUSES.includes(props.status),
        _a[css.success] = SUCCESS_STATUSES.includes(props.status),
        _a));
    var renderTitle = useCallback(function () {
        if (props.renderTitle) {
            return props.renderTitle(props.status);
        }
        switch (props.status) {
            case ExareStatus.InProcess:
            case ExareStatus.Rejected:
            case ExareStatus.Refused:
            case ExareStatus.AwaitingPayment:
            case ExareStatus.NeedConfirm:
                return t('Request for refund');
            case ExareStatus.Finished:
                return (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: cn(css.icon, cssStatusClassModifier) }, Included),
                    [
                        t('Successfully created new booking'),
                        props.splittedOrderId,
                        props.passengersNames ? "".concat(t('For').toLowerCase(), " ").concat(props.passengersNames) : null
                    ]
                        .filter(Boolean)
                        .join(' ')));
            default:
                return null;
        }
    }, [props.status, props.renderTitle]);
    var goToSplittedBooking = useCallback(function () {
        history.push("/booking/".concat(props.splittedOrderId));
        window.location.reload();
    }, [props.splittedOrderId]);
    var renderStatus = useCallback(function () {
        if (props.renderStatus) {
            return props.renderStatus(props.status);
        }
        switch (props.status) {
            case ExareStatus.AwaitingPayment:
            case ExareStatus.NeedConfirm:
            case ExareStatus.InProcess:
            case ExareStatus.TimelimitExpired:
            case ExareStatus.Rejected:
                return (React.createElement(React.Fragment, null,
                    t(props.status),
                    props.onGoToTicket && (React.createElement(Button, { onClick: props.onGoToTicket, className: cn(css.button, cssStatusClassModifier) }, ArrowForward))));
            case ExareStatus.Refused:
                return t(props.status);
            case ExareStatus.Finished:
                if (!props.splittedOrderId) {
                    return null;
                }
                return React.createElement(Button, { onClick: goToSplittedBooking }, t('Open'));
            default:
                return null;
        }
    }, [props.status, props.renderStatus, props.splittedOrderId]);
    if (!props.show) {
        return null;
    }
    return (React.createElement("div", { className: cn(css.status, cssStatusClassModifier, props.className) },
        React.createElement("div", { className: cn(css.title, cssStatusClassModifier) }, renderTitle()),
        React.createElement("div", { className: css.right }, renderStatus())));
};
export default OrderRefundStatus;
