export var RefundMode;
(function (RefundMode) {
    RefundMode[RefundMode["Auto"] = 0] = "Auto";
    RefundMode[RefundMode["Manual"] = 1] = "Manual";
})(RefundMode || (RefundMode = {}));
export var StepType;
(function (StepType) {
    StepType["Passengers"] = "Passengers";
    StepType["Reason"] = "Reason";
    StepType["AttachFiles"] = "AttachFiles";
    StepType["CreateTicket"] = "CreateTicket";
    StepType["Ticket"] = "Ticket";
})(StepType || (StepType = {}));
export var Unvoluntary;
(function (Unvoluntary) {
    Unvoluntary["Unvoluntary"] = "Unvoluntary";
})(Unvoluntary || (Unvoluntary = {}));
