import * as React from 'react';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '../../../theme';
var StepTitle = function (props) {
    var css = useTheme('Refund').StepTitle;
    return (React.createElement("div", { className: cn(css.step, props.className) },
        React.createElement("span", { className: css.icon }, props.icon),
        React.createElement(Typography, { className: css.text, variant: 'h6' }, props.title)));
};
export default StepTitle;
