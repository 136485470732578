import { __assign, __read, __values } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RefundPaper from '../RefundPaper/RefundPaper';
import AddFile from '../AddFile/AddFile';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
var AddFiles = function (props) {
    var _a;
    var t = useTranslation('Refund').t;
    var css = useTheme('Refund').AddFiles;
    var initialState = {};
    Object.entries(props.selectedDocuments).forEach(function (_a) {
        var _b = __read(_a, 2), passengerId = _b[0], documents = _b[1];
        var index = 1;
        var passengersIds = props.passengers.map(function (passenger) { return passenger.id; });
        if (!passengersIds.includes(passengerId)) {
            return;
        }
        initialState[passengerId] = {};
        documents.forEach(function (document, index) {
            initialState[passengerId][index + 1] = document;
        });
        while (index !== 6) {
            if (!initialState[passengerId][index]) {
                initialState[passengerId][index] = null;
            }
            index++;
        }
    });
    var _b = __read(useState(initialState), 2), state = _b[0], setState = _b[1];
    var _c = useToggleable(false), hasErrors = _c.isOpen, setHasErrors = _c.open, setErrorsStateTo = _c.setOpen;
    var convertStateToRedux = useCallback(function () {
        var documentsState = {};
        Object.entries(state).forEach(function (_a) {
            var _b = __read(_a, 2), passenger = _b[0], documentsMap = _b[1];
            documentsState[passenger] = Object.values(state[passenger]).map(function (value) { return value; });
        });
        return documentsState;
    }, [state]);
    var handleAddFile = useCallback(function (passengerId, fileInputKey, file) {
        var _a, _b;
        var passengerState = __assign({}, state[passengerId]);
        setErrorsStateTo(false);
        setState(__assign(__assign({}, state), (_a = {}, _a[passengerId] = __assign(__assign({}, passengerState), (_b = {}, _b[fileInputKey] = file, _b)), _a)));
    }, [state, props.passengers]);
    var handleClear = useCallback(function (passengerId, fileInputIndex) {
        var _a, _b;
        var passengerState = __assign({}, state[passengerId]);
        setState(__assign(__assign({}, state), (_a = {}, _a[passengerId] = __assign(__assign({}, passengerState), (_b = {}, _b[fileInputIndex] = null, _b)), _a)));
    }, [state, props.passengers]);
    useEffect(function () {
        props.onFilesLoaded(convertStateToRedux());
    }, [state]);
    var renderFileInput = useCallback(function (passenger, fileInputKey) { return (React.createElement(AddFile, { onValidationError: setHasErrors, key: "".concat(passenger, "_").concat(fileInputKey), passenger: passenger, documentType: props.docsFilesMap[props.refundReason], className: css.file, onLoad: function (loaded) { return handleAddFile(passenger.id, fileInputKey, loaded.file); }, onClear: function () { return handleClear(passenger.id, fileInputKey); } })); }, [props.passengers, props.refundReason, props.onFilesLoaded, state]);
    var renderFileInputs = useCallback(function (passenger) {
        var e_1, _a;
        var inputsToRender = [];
        var hasEmpty = false;
        if (!state[passenger.id]) {
            return null;
        }
        try {
            for (var _b = __values(Object.entries(state[passenger.id])), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                if (value === null && !hasEmpty) {
                    hasEmpty = true;
                    inputsToRender.push(renderFileInput(passenger, key));
                }
                if (value !== null) {
                    inputsToRender.push(renderFileInput(passenger, key));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return inputsToRender;
    }, [renderFileInput, state]);
    return (React.createElement(Paper, { elevation: 0, square: true, className: css.wrapper },
        React.createElement(RefundPaper, { className: css.files }, props.passengers.map(renderFileInputs)),
        React.createElement(Typography, { className: cn(css.info, (_a = {}, _a[css.hasErrors] = hasErrors, _a)) }, t('File format: jpg, pdf. File size: no more than 5MB', { nsSeparator: '|' }))));
};
export default AddFiles;
