import { __assign, __read, __spreadArray } from "tslib";
import { BaggageType } from '@websky/graphql';
export function getServiceKey(service) {
    var segmentIds = service.segmentIds.join('-');
    return [service.serviceId, service.passengerId, segmentIds].join('/');
}
export function getSelectedServicesByType(state, action) {
    var _a = action.payload, items = _a.items, type = _a.type;
    var selectedServicesMap = new Map();
    state.selectedServices.forEach(function (service) {
        var key = getServiceKey(service);
        selectedServicesMap.set(key, service);
    });
    if (type === 'radio') {
        // reset the selected services on the segment for the passenger, as only one service can be selected
        selectedServicesMap.forEach(function (selectedService, key) {
            var _a;
            var baggageType = (_a = state.services.find(function (service) { return service.id === selectedService.serviceId; })) === null || _a === void 0 ? void 0 : _a.type;
            if (baggageType === BaggageType.BaggageExcess) {
                items.forEach(function (newService) {
                    if (newService.passengerId === selectedService.passengerId &&
                        newService.segmentIds.join('/') === selectedService.segmentIds.join('/')) {
                        selectedServicesMap.set(key, __assign(__assign({}, selectedService), { count: 0 }));
                    }
                });
            }
        });
    }
    items.forEach(function (newService) {
        var key = getServiceKey(newService);
        selectedServicesMap.set(key, newService);
    });
    return __spreadArray([], __read(selectedServicesMap.values()), false);
}
