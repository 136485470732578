import { __read, __spreadArray } from "tslib";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RowContext } from '../SeatMap/Row/context';
import { useIataCode } from '../../../hooks';
export var useRestrictionsConditionForKV = function () {
    var _a;
    var row = (_a = useContext(RowContext)) === null || _a === void 0 ? void 0 : _a.row;
    var iataCode = useIataCode();
    return iataCode === 'KV' && row ? row.exitRow : true;
};
export var useSeatAdditionalInfo = function (seat) {
    var _a, _b;
    var t = useTranslation('SeatMap').t;
    var additional = [];
    if (!(seat === null || seat === void 0 ? void 0 : seat.isRecline)) {
        additional = __spreadArray(__spreadArray([], __read(additional), false), [t('Chair back does not recline')], false);
    }
    if (seat === null || seat === void 0 ? void 0 : seat.isNearWindow) {
        additional = __spreadArray(__spreadArray([], __read(additional), false), [t('Window seat')], false);
    }
    else if ((seat === null || seat === void 0 ? void 0 : seat.isNearWindow) === false || (seat === null || seat === void 0 ? void 0 : seat.isNearWindowWithoutWindow)) {
        additional = __spreadArray(__spreadArray([], __read(additional), false), [t('Seat with no window')], false);
    }
    if (seat === null || seat === void 0 ? void 0 : seat.isComfort) {
        additional = __spreadArray(__spreadArray([], __read(additional), false), [t('Comfort seat')], false);
    }
    if (seat === null || seat === void 0 ? void 0 : seat.isNearPartitionWall) {
        additional = __spreadArray(__spreadArray([], __read(additional), false), [t('Seat next to the partition')], false);
    }
    var restrictions = (_a = seat === null || seat === void 0 ? void 0 : seat.restrictions) !== null && _a !== void 0 ? _a : [];
    var permissions = (_b = seat === null || seat === void 0 ? void 0 : seat.permissions) !== null && _b !== void 0 ? _b : [];
    var withInfo = additional.length > 0 || (restrictions.length > 0 && useRestrictionsConditionForKV()) || permissions.length > 0;
    return {
        restrictions: restrictions,
        permissions: permissions,
        additional: additional,
        withInfo: withInfo
    };
};
