import * as React from 'react';
import { useTheme } from '../theme';
import { infoIcon } from './Icons';
import MediaQuery from '../MediaQuery/MediaQuery';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { initI18n } from '../utils';
initI18n('Warnings');
var Warnings = function (_a) {
    var warnings = _a.warnings, children = _a.children, className = _a.className, textClassName = _a.textClassName, iconClassName = _a.iconClassName, iconTextClassName = _a.iconTextClassName, contentClassName = _a.contentClassName;
    var css = useTheme('Warnings').WarningsStyles;
    var t = useTranslation('Warnings').t;
    return (React.createElement("div", { className: cn(css.wrapper, className) }, children ? (React.createElement("div", { className: cn(css.warning, contentClassName) },
        React.createElement("div", { className: cn(css.warning__icon, iconClassName) },
            infoIcon,
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement("span", { className: cn(css.icon__text, iconTextClassName) }, t('Clarification')))),
        React.createElement("div", { className: cn(css.warning__text, textClassName) }, children))) : (warnings.map(function (warning, index) { return (React.createElement("div", { key: index, className: cn(css.warning, contentClassName) },
        React.createElement("div", { className: cn(css.warning__icon, iconClassName) },
            infoIcon,
            React.createElement(MediaQuery, { maxWidth: 'mobile' },
                React.createElement("span", { className: cn(css.icon__text, iconTextClassName) }, t('Clarification')))),
        React.createElement("div", { className: cn(css.warning__text, textClassName) }, warning))); }))));
};
export default Warnings;
