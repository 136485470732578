import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { planeIcon } from '../../../../BoardingPassDesk/Icons';
import { format } from '../../../../utils';
var SegmentCompact = function (_a) {
    var _b, _c, _d;
    var segment = _a.segment;
    var css = useTheme('RetrieveBooking').SegmentCompact;
    var t = useTranslation('Segment').t;
    var fareFamily = segment.fareFamily;
    var firstSegment = segment.segments[0];
    var lastSegment = segment.segments[segment.segments.length - 1];
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.header },
            React.createElement("div", { className: css.segmentInfo },
                React.createElement("div", { className: css.planeIcon }, planeIcon),
                React.createElement("div", null,
                    React.createElement("span", { className: css.segmentInfo__item },
                        firstSegment.departure.airport.city.name,
                        " \u2013 ",
                        lastSegment.arrival.airport.city.name),
                    React.createElement("span", { className: css.segmentInfo__item }, format(firstSegment.departure.date, 'dd MMM, yyyy')),
                    React.createElement("span", { className: css.segmentInfo__item },
                        firstSegment.departure.time.substr(0, 5),
                        " - ",
                        lastSegment.arrival.time.substr(0, 5),
                        ' '))),
            React.createElement("div", { className: css.fareFamily },
                ((_b = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _b === void 0 ? void 0 : _b.icon) && (React.createElement("div", { className: css.fareFamily__img, style: {
                        backgroundImage: "url(".concat(fareFamily.airline.icon, ")")
                    } })),
                React.createElement("span", null,
                    ((_c = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.airline) === null || _c === void 0 ? void 0 : _c.name) && "".concat(fareFamily.airline.name, ", "), (_d = fareFamily === null || fareFamily === void 0 ? void 0 : fareFamily.title) === null || _d === void 0 ? void 0 :
                    _d.toLowerCase()))),
        React.createElement("div", { className: css.footer },
            React.createElement("div", { className: css.flightInfo },
                React.createElement("span", { className: css.flightInfo__item },
                    firstSegment.operatingAirline.iata,
                    " ",
                    firstSegment.flightNumber),
                firstSegment.departure.terminal && (React.createElement("span", { className: css.flightInfo__item },
                    t('Terminal'),
                    ": ",
                    firstSegment.departure.terminal))))));
};
export default SegmentCompact;
