import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { WarningRounded } from '../../../Icons';
import { useTheme } from '../../../theme';
var ServiceError = function (_a) {
    var className = _a.className, errorText = _a.errorText, isShow = _a.isShow, children = _a.children;
    var css = useTheme('CheckoutAdditionalService').ServiceError;
    var t = useTranslation('Cart').t;
    if (isShow) {
        return (React.createElement("div", { className: cn(css.container, className) },
            React.createElement("div", { className: css.icon }, WarningRounded), errorText !== null && errorText !== void 0 ? errorText : t('The service you have added cannot be activated. Try connecting later or contact the airline operator.')));
    }
    return React.createElement(React.Fragment, null, children);
};
export default ServiceError;
