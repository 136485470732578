import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import OrderRefundStatus from '../../../../Refund/Components/OrderRefundStatus/OrderRefundStatus';
import Button from '../../../../Button';
import { useTheme } from '../../../../theme';
import { Chevron } from '../../../../Icons';
var ParentOrderStatus = function (_a) {
    var parentOrderId = _a.parentOrderId, props = __rest(_a, ["parentOrderId"]);
    var t = useTranslation('Checkout').t;
    var theme = useTheme('Checkout').ParentOrderStatus;
    var history = useHistory();
    var renderTitle = useCallback(function () { return (React.createElement("span", null,
        t('Order created from'),
        " ",
        parentOrderId)); }, [parentOrderId]);
    var handleGoToOldBooking = useCallback(function () {
        history.push("/booking/".concat(parentOrderId));
        window.location.reload();
    }, [parentOrderId]);
    var renderStatus = useCallback(function () { return (React.createElement(Button, { onClick: handleGoToOldBooking },
        t('View old booking'),
        "\u00A0",
        parentOrderId,
        React.createElement("span", { className: theme.icon }, Chevron))); }, [parentOrderId]);
    return (React.createElement(OrderRefundStatus, __assign({}, props, { className: theme.container, status: null, renderTitle: renderTitle, renderStatus: renderStatus })));
};
export default ParentOrderStatus;
