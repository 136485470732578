import { __read, __spreadArray } from "tslib";
var defaultIsEqual = function (a, b) { return a === b; };
export var memes = function (fn, isEqual) {
    if (isEqual === void 0) { isEqual = defaultIsEqual; }
    var lastArgs = [];
    var lastResult;
    var calledOnce = false;
    var isNewArgEqualToLast = function (newArg, index) { return isEqual(newArg, lastArgs[index]); };
    return function () {
        var newArgs = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            newArgs[_i] = arguments[_i];
        }
        if (calledOnce && newArgs.length === lastArgs.length && newArgs.every(isNewArgEqualToLast)) {
            return lastResult;
        }
        lastResult = fn.apply(void 0, __spreadArray([], __read(newArgs), false));
        calledOnce = true;
        lastArgs = newArgs;
        return lastResult;
    };
};
export default memes;
