import { useCallback, useMemo } from 'react';
import { useConfig } from '../../../context';
import { useImportantInsurancesTouched } from '../../hooks';
/**
 * model for insurance product
 */
export var useInsuranceProduct = function (insuranceProgram, onToggle, isPopular, productState) {
    var allowImplicitConfirm = useConfig().global.insurance.allowImplicitConfirm;
    var importantInsurancesTouched = useImportantInsurancesTouched();
    var isAvailable = productState === 'available';
    var isSelected = productState === 'selected';
    var isConfirmed = productState === 'confirmed';
    var showIconButton = useMemo(function () {
        if (!isPopular) {
            return true;
        }
        else if (productState === 'confirmed') {
            return true;
        }
        return false;
    }, [isPopular, productState]);
    var isMainInsurance = isPopular;
    var isTouched = !isMainInsurance || (isMainInsurance && importantInsurancesTouched.getIsTouched(insuranceProgram.code));
    var isError = allowImplicitConfirm && !isTouched;
    var handleToggle = useCallback(function () {
        onToggle(insuranceProgram.code);
    }, [insuranceProgram, productState, onToggle]);
    return {
        // data
        showIconButton: showIconButton,
        isAvailable: isAvailable,
        isSelected: isSelected,
        isConfirmed: isConfirmed,
        isTouched: isTouched,
        isError: isError,
        // methods
        handleToggle: handleToggle
    };
};
