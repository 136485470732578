import * as React from 'react';
import BaggageCount from '../BaggageCount/BaggageCount';
import BaggageDirection from '../BaggageDirection/BaggageDirection';
import { useTheme } from '../../../../theme';
import Baggage from '../../../Baggage';
import Money from '../../../../Money';
import { useTranslation } from 'react-i18next';
import { BaggageContext } from '../../../context';
import cn from 'classnames';
import { BaseButton } from '../../../../index';
var PlusIcon = (React.createElement("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M14.5834 9.75H10.0834V14.25H8.58337V9.75H4.08337V8.25H8.58337V3.75H10.0834V8.25H14.5834V9.75Z", fill: "currentColor" })));
var BaggageItem = function (_a) {
    var _b;
    var _c;
    var baggage = _a.baggage, servicesBySegments = _a.servicesBySegments, realIconSize = _a.realIconSize, priceFrom = _a.priceFrom;
    var css = useTheme('BaggageList').BaggageList;
    var t = useTranslation('AdditionalBaggage').t;
    var baggageIconClickHandler = (_c = React.useContext(BaggageContext)) === null || _c === void 0 ? void 0 : _c.onBaggageIconClick;
    return (React.createElement("div", { className: css.item },
        React.createElement(Baggage, { baggage: baggage[0], realIconSize: realIconSize }),
        servicesBySegments.length > 0 && (React.createElement("div", { className: css.counts }, servicesBySegments.map(function (service, index) { return (React.createElement("div", { className: css.count, key: index },
            React.createElement(BaggageCount, { className: css.baggage_count, count: service.count }),
            React.createElement(BaggageDirection, { className: css.baggage_direction, allSegments: service.allSegments, directions: service.directions }))); }))),
        servicesBySegments.length === 0 && (React.createElement("div", { className: cn(css.footer, (_b = {}, _b[css.footer_empty] = !baggage[0].canBeAdded, _b)) },
            React.createElement(BaseButton, { variant: "optionAction", className: css.add, onClick: baggageIconClickHandler }, PlusIcon),
            React.createElement("div", { className: css.price },
                t('from'),
                " ",
                React.createElement(Money, { money: priceFrom }))))));
};
export default BaggageItem;
