import { __assign } from "tslib";
import * as React from 'react';
import { memo } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useGraphQLClient } from '../../graphql-client';
import SocialAuth from './SocialAuth';
var Component = memo(function (props) {
    var client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(SocialAuth, __assign({}, props))));
});
Component.displayName = 'SocialAuth';
export default Component;
