import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { OrderCardContext } from '../OrderCard';
import Button from '../../../Button';
import { Download, Included, InfoDetails } from '../../../Icons';
import Money from '../../../Money';
import CommonItem from '../CommonItem/CommonItem';
import { OrderStatus } from '@websky/graphql';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
var OrderBooking = function (_a) {
    var _b;
    var _c, _d;
    var className = _a.className, onDownloadTicket = _a.onDownloadTicket, hidePrice = _a.hidePrice;
    var css = useTheme('OrderCard').OrderBooking;
    var t = useTranslation('OrderCard').t;
    var _e = useContext(OrderCardContext), order = _e.order, type = _e.type;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    return (React.createElement(CommonItem, { icon: type === 'review' ? InfoDetails : order.status === OrderStatus.Confirmed ? Included : null, type: type, iconClassName: css.icon, className: cn(css.root, className, (_b = {},
            _b[css.type_review] = type === 'review',
            _b[css.type_account] = type === 'account',
            _b)), text: React.createElement(React.Fragment, null,
            type === 'account' && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.orderCode },
                    t('Booking ID'),
                    ":",
                    ' ',
                    React.createElement("span", { className: css.value }, order.locator ? order.locator : order.id)))),
            type === 'review' && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.orderCode },
                    t('Booking ID'),
                    ":",
                    ' ',
                    React.createElement("span", { className: css.value }, order.locator ? order.locator : order.id)),
                React.createElement("span", { className: css.security_code },
                    t('Security code'),
                    ": ",
                    React.createElement("span", { className: css.value }, order.accessCode)),
                !hidePrice && (React.createElement("span", { className: css.price },
                    t('Total'),
                    ": ",
                    React.createElement(Money, { money: (_c = order.price) === null || _c === void 0 ? void 0 : _c.total })))))), buttons: type === 'review'
            ? order.status === OrderStatus.Confirmed && (React.createElement("div", { className: css.download_wrapper },
                React.createElement(Button, { size: 'small', className: css.download, onClick: onDownloadTicket },
                    t('Download'),
                    Download)))
            : !isMobile &&
                !hidePrice && (React.createElement(React.Fragment, null,
                t('Total'),
                ":",
                React.createElement(Money, { money: (_d = order.price) === null || _d === void 0 ? void 0 : _d.total }))) }));
};
export default OrderBooking;
