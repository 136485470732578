import { __assign, __read } from "tslib";
import * as React from 'react';
import { useMemo, useReducer, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import Modal from '../../../../../../Modal';
import Datepicker from './Datepicker/Datepicker';
import Button from '../../../../../../Button/Button';
import { ThemeProvider, useTheme } from '../../../../../../theme';
import { API_DATE_FORMAT, format } from '../../../../../../utils';
import { sortSegments } from '../../../../../../Results/components/Results/Calendar/utils';
import { selectedDatesReducer } from './store/store';
import { useAvailableDates } from '../../hooks';
var calendarIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.3333 4.88911H18.2222C19.2 4.88911 20 5.68911 20 6.66688V19.1113C20 20.0891 19.2 20.8891 18.2222 20.8891H5.77778C4.79111 20.8891 4 20.0891 4 19.1113L4.00889 6.66688C4.00889 5.68911 4.79111 4.88911 5.77778 4.88911H6.66667V3.11133H8.44444V4.88911H15.5556V3.11133H17.3333V4.88911ZM5.77778 19.1113H18.2222V9.33355H5.77778V19.1113Z", fill: "currentColor" })));
var Calendar = function (_a) {
    var _b;
    var searchParams = _a.searchParams, startNewSearch = _a.startNewSearch, legId = _a.legId, minDate = _a.minDate;
    var _c = __read(React.useState(false), 2), open = _c[0], setOpen = _c[1];
    var _d = __read(useReducer(selectedDatesReducer, {}), 2), selectedDatesState = _d[0], dispatch = _d[1];
    var _e = __read(React.useState(typeof legId !== 'undefined' ? legId : 0), 2), currentLeg = _e[0], setCurrentLeg = _e[1];
    var segmentsCount = searchParams.segments.length;
    var selectedDatesCount = Object.keys(selectedDatesState).length;
    var isAllDatesSelected = selectedDatesCount === segmentsCount;
    var hasDatesToClear = selectedDatesCount >= legId + 1;
    var theme = useTheme('Exchange').Calendar;
    var t = useTranslation('Exchange').t;
    var date = parse(searchParams.segments[0].date, 'yyyy-MM-dd', new Date());
    var currentSegment = (_b = searchParams.segments) === null || _b === void 0 ? void 0 : _b[legId];
    var dates = useAvailableDates(currentSegment).dates;
    var selectedDates = useMemo(function () {
        if (Object.keys(selectedDatesState).length > 0) {
            return Object.keys(selectedDatesState).map(function (key) {
                return parse(selectedDatesState[key].date, API_DATE_FORMAT, new Date());
            });
        }
        else {
            return searchParams.segments.map(function (segment) { return parse(segment.date, API_DATE_FORMAT, new Date()); });
        }
    }, [selectedDatesState]);
    var onStartSearch = function () {
        startNewSearch(__assign(__assign({}, searchParams), { segments: sortSegments(currentLeg, date, Object.keys(selectedDatesState).map(function (key) {
                var segment = selectedDatesState[key];
                if (parseInt(key, 10) + 1 === legId) {
                    return __assign(__assign({}, segment), { date: format(date, API_DATE_FORMAT) });
                }
                return segment;
            })) }));
    };
    var onSelect = function (date) {
        var segment = searchParams.segments
            .map(function (segment, key) {
            if (key === currentLeg) {
                return __assign(__assign({}, segment), { date: format(date, API_DATE_FORMAT) });
            }
            return null;
        })
            .filter(Boolean)[0];
        dispatch({
            type: 'select',
            payload: {
                legId: Math.max(currentLeg, legId),
                segment: segment
            }
        });
        if (searchParams.segments[legId + 1]) {
            setCurrentLeg(legId + 1);
        }
    };
    useEffect(function () {
        dispatch({
            type: 'actialize',
            payload: {
                segmentsSearchParams: searchParams.segments
            }
        });
    }, [legId]);
    var datepickerStyles = useMemo(function () { return ({
        Datepicker: {
            DayStyles: {
                day: theme.day__day,
                day__inside: theme.day__inside,
                day_endPeriod: theme.day_end,
                day_startPeriod: theme.day_start,
                day_selected: theme.day_selected
            },
            PopupDatepickerStyles: {
                block: theme.datepicker__modal
            }
        }
    }); }, []);
    var clearLastSelectedDate = function () {
        dispatch({
            type: 'clear',
            payload: {
                legId: legId
            }
        });
        setCurrentLeg(legId);
    };
    var renderFooter = function () {
        var hasButtons = hasDatesToClear || isAllDatesSelected;
        if (!hasButtons) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { className: theme.buttons },
            hasDatesToClear && (React.createElement(Button, { size: 'small', variant: 'flat', className: cn(theme.button, theme.clear), onClick: clearLastSelectedDate }, t('Clear'))),
            isAllDatesSelected && (React.createElement(Button, { size: 'small', variant: 'flat', className: theme.button, onClick: onStartSearch }, t('Search')))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: function () { return setOpen(true); }, className: theme.calendar },
            t('Open calendar'),
            calendarIcon),
        React.createElement(Modal, { open: open, classes: { paper: theme.datepicker__paper }, closeClassName: theme.datepicker__closeIcon, onClose: function () { return setOpen(false); } },
            React.createElement(ThemeProvider, { value: datepickerStyles },
                React.createElement(Datepicker, { onSelect: onSelect, openDate: date, monthCount: 3, minDate: minDate, availableDates: dates, highlightClassName: theme.day_highlight, selectedDates: selectedDates, renderFooter: renderFooter })))));
};
export default Calendar;
