import * as React from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { useContext } from 'react';
import { OrderCardContext } from '../OrderCard';
var CommonItem = function (props) {
    var _a;
    var theme = useTheme('OrderCard').CommonItem;
    var type = useContext(OrderCardContext).type;
    return (React.createElement("div", { className: cn(theme.root, props.className, (_a = {},
            _a[theme.account] = type === 'account',
            _a[theme.review] = type === 'review',
            _a)) },
        React.createElement("div", { className: cn(theme.payload, props.payloadClassName) },
            props.icon && React.createElement("div", { className: cn(theme.icon, props.iconClassName) }, props.icon),
            React.createElement("div", null, props.text)),
        props.buttons && React.createElement("div", { className: theme.buttons }, props.buttons)));
};
export default CommonItem;
