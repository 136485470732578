import * as React from 'react';
import Segment from '../../../DesktopFlightRoute/components/Segment/Segment';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { MobileSegmentUpgrade } from '../../../MobileSegmentInfo';
export var SelectableCheckoutSegment = function (_a) {
    var _b, _c, _d, _e;
    var _f, _g;
    var index = _a.index, highlight = _a.highlight, parentFlight = _a.parentFlight, segment = _a.segment, transferInfo = _a.transferInfo, showStopsInfo = _a.showStopsInfo;
    var css = useTheme('UpgradeFlightClass').SelectableSegment;
    var checkoutFlight = parentFlight;
    return (React.createElement(ThemeProvider, { value: {
            DesktopFlightRoute: {
                StopsInfoStyles: {
                    stop: css.stopsInfo__stop,
                    stop__label: css.stopsInfo__label
                },
                RouteArrowStyles: {
                    circle: cn((_b = {},
                        _b[css.selected] = highlight,
                        _b))
                }
            }
        } },
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Segment, { first: index === 0, last: false, className: cn(css.segment, (_c = {},
                    _c[css.selected] = highlight,
                    _c[css.transfer] = checkoutFlight.segments.length > 1,
                    _c[css.first] = index === 0,
                    _c[css.last] = index !== 0,
                    _c)), segment: {
                    segment: segment,
                    transferDuration: showStopsInfo ? transferInfo : null
                }, stops: checkoutFlight.segments[index].stops, singleFlight: checkoutFlight.segments.length === 1, multipleSegments: checkoutFlight.segments.length > 1 })),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(MobileSegmentUpgrade, { flightNumberClassName: cn(css.flightNumber, (_d = {}, _d[css.selected] = highlight, _d)), showFullInfo: true, segments: [
                    {
                        segment: segment,
                        transferDuration: showStopsInfo ? transferInfo : null
                    }
                ], className: cn(css.segment, (_e = {}, _e[css.selected] = highlight, _e)), stopCount: (_g = (_f = checkoutFlight.segments[index]) === null || _f === void 0 ? void 0 : _f.stops) === null || _g === void 0 ? void 0 : _g.length, multipleSegments: checkoutFlight.segments.length > 1, showAirplaneInfo: true, last: false }))));
};
