import * as React from 'react';
import Input from '../../../../Input';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
var SpecialDocumentField = function (_a) {
    var _b;
    var passenger = _a.passenger, setValue = _a.setValue, documentError = _a.documentError;
    var inputRef = React.useRef(null);
    var t = useTranslation('AdditionalServiceCard').t;
    var theme = useTheme('Insurance').SpecialDocumentField;
    var onBlur = function (event) {
        var value = event.target.value;
        setValue(passenger.id, value);
    };
    React.useEffect(function () {
        inputRef.current.value = passenger.value;
    }, [passenger]);
    return (React.createElement("div", { className: theme.field },
        React.createElement("div", { className: theme.name }, passenger.name),
        React.createElement(Input, { name: "insurance_field_".concat(passenger.id), label: t('Enter document number'), TextFieldProps: {
                onBlur: onBlur,
                error: !passenger.isValid,
                helperText: !passenger.isValid && t(((_b = passenger.regex) === null || _b === void 0 ? void 0 : _b.error) || 'Required'),
                defaultValue: passenger.value,
                disabled: passenger.disabled
            }, inputRef: inputRef, absoluteHintPosition: true, className: theme.input })));
};
export default SpecialDocumentField;
