import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import MetasearchLoader from '../../../MetasearchLoader';
import { isOdd, changeLocale } from '../../../utils';
import { setSplitTest, SplitTest } from '../../../analytics';
import { useHandleMetasearchRedirectMutation } from '@websky/graphql';
var MetaRedirect = function (_a) {
    var onSuccess = _a.onSuccess, onResultsRedirect = _a.onResultsRedirect, location = _a.location, match = _a.match;
    var token = match.params['token'];
    var _b = __read(useState(false), 2), hasErrors = _b[0], setHasErrors = _b[1];
    var redirectParams = parse(location.search);
    var _c = __read(useHandleMetasearchRedirectMutation(), 1), loadOrderMutation = _c[0];
    var loadOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setHasErrors(false);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, loadOrderMutation({
                            variables: {
                                id: token
                            }
                        })];
                case 2:
                    data = _b.sent();
                    if ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.HandleMetasearchRedirect) {
                        if (data.data.HandleMetasearchRedirect.order) {
                            setSplitTest(SplitTest.SkipServicesSteps, !data.data.HandleMetasearchRedirect.order.showServicesBeforeBooking);
                            setSplitTest(SplitTest.SkipServicesRecommendations, isOdd(data.data.HandleMetasearchRedirect.order.id));
                            onSuccess(data.data.HandleMetasearchRedirect.order, true);
                        }
                        else if (data.data.HandleMetasearchRedirect.searchId) {
                            onResultsRedirect(data.data.HandleMetasearchRedirect.searchId);
                        }
                        else {
                            setHasErrors(true);
                        }
                    }
                    else {
                        setHasErrors(true);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    setHasErrors(true);
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (token) {
            if (redirectParams === null || redirectParams === void 0 ? void 0 : redirectParams.language) {
                changeLocale(redirectParams.language);
            }
            loadOrder();
        }
        return function () {
            token = '';
        };
    }, []);
    return React.createElement(MetasearchLoader, { hasError: hasErrors, variant: redirectParams.source, onRetry: loadOrder });
};
export default withRouter(MetaRedirect);
