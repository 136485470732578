import { CUSTOM_CONTENT, CUSTOM_CONTENT_DATA_READY } from './constants';
var CustomContent = /** @class */ (function () {
    function CustomContent() {
        if (process.env.NODE_ENV === 'development') {
            this.debugMode = true;
        }
    }
    CustomContent.debugEvent = function (data) {
        console.log("".concat(CUSTOM_CONTENT, ": ").concat(data.page), data);
    };
    CustomContent.prototype.sendCustomContentEvent = function (data) {
        var event = new CustomEvent(CUSTOM_CONTENT_DATA_READY, { detail: data });
        document.dispatchEvent(event);
        if (this.debugMode) {
            CustomContent.debugEvent(data);
        }
    };
    return CustomContent;
}());
var customContent = new CustomContent();
export default customContent;
