import { __extends } from "tslib";
import OrderDataLayer from './OrderDataLayer';
import { OrderType } from './types';
import { OrderStep } from '../types';
var SearchResultsDataLayer = /** @class */ (function (_super) {
    __extends(SearchResultsDataLayer, _super);
    function SearchResultsDataLayer(instance) {
        return _super.call(this, instance) || this;
    }
    SearchResultsDataLayer.prototype.init = function (currency, promocode) {
        this.dataLayer.init({
            coupon: promocode,
            currency: currency
        });
    };
    SearchResultsDataLayer.prototype.getOrderType = function () {
        return OrderType.SearchResults;
    };
    SearchResultsDataLayer.prototype.getFlightSegments = function () {
        return [];
    };
    SearchResultsDataLayer.prototype.getOrderStatus = function () {
        return OrderStep.Booking;
    };
    SearchResultsDataLayer.prototype.getDataLayer = function () {
        return this.dataLayer;
    };
    return SearchResultsDataLayer;
}(OrderDataLayer));
export default SearchResultsDataLayer;
