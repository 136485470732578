import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { format } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_DATE_FORMAT } from '../../../constants';
import { Badge } from '../../../../Badge/Badge';
export var CategoryCard = function (props) {
    var _a;
    var css = useTheme('Communications').CategoryCard;
    var t = useTranslation('Communications').t;
    return (React.createElement("div", { className: cn(css.card) },
        React.createElement("div", { className: css.card__icon }, props.icon),
        React.createElement("div", null,
            React.createElement("div", { className: cn(css.card__group, css.card__group_center) },
                React.createElement("h3", { className: cn(css.card__title) }, props.title),
                props.unreadCount > 0 && React.createElement(Badge, { value: props.unreadCount })),
            React.createElement("div", { className: css.card__body }, props.lastMessage ? (React.createElement("div", { className: cn(css.card__group, (_a = {}, _a[css.card__group_custom] = !props.lastMessage.title, _a)) },
                props.lastMessage.title && (React.createElement("h4", { className: css.card__subTitle }, props.lastMessage.title)),
                React.createElement("span", null, format(props.lastMessage.datetime, NOTIFICATION_DATE_FORMAT)),
                React.createElement("p", { className: css.card__desc }, props.lastMessage.text))) : (React.createElement("p", { className: css.card__desc }, t('There is nothing yet')))))));
};
