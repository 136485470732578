export var getTravellerItemData = function (traveller, promoCodesInfo, error) {
    var _a, _b, _c, _d;
    var travellerId = Number.parseInt(traveller.id);
    var promoCodeInfo = (_a = promoCodesInfo.travellers[travellerId]) === null || _a === void 0 ? void 0 : _a.promoCodeInfo;
    var hasError = !!promoCodesInfo.travellers[travellerId] && (!promoCodeInfo || !!error);
    var origPriceAmount = 0;
    traveller.fares.forEach(function (fare) {
        return fare.passengerFares.forEach(function (segFare) { return (origPriceAmount += segFare.totalFare.amount); });
    });
    var origPrice = {
        amount: origPriceAmount,
        currency: (_c = (_b = traveller.fares[0]) === null || _b === void 0 ? void 0 : _b.passengerFares[0]) === null || _c === void 0 ? void 0 : _c.totalFare.currency
    };
    var discountPrice = (_d = promoCodeInfo === null || promoCodeInfo === void 0 ? void 0 : promoCodeInfo.resultPrice) !== null && _d !== void 0 ? _d : origPrice;
    return {
        hasError: hasError,
        origPrice: origPrice,
        discountPrice: discountPrice
    };
};
