import { __read } from "tslib";
import * as React from 'react';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../context';
import Link from '../../Link';
import { capitalizeFirstLetter, getLocale } from '../../utils';
import { useRenderers } from '../../renderProps';
import FareConditionsModal from './FareConditionsModal';
var InfoLinksLabel = function (_a) {
    var _b, _c, _d;
    var className = _a.className, order = _a.order, type = _a.type, onClickLink = _a.onClickLink;
    var _e = useTranslation(), t = _e.t, i18n = _e.i18n;
    var companyInfo = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.global.companyInfo;
    var locale = getLocale();
    var _f = __read(useState(false), 2), isFareModalOpen = _f[0], setIsFareModalOpen = _f[1];
    var onClick = function (e) {
        var target = e.target;
        if (target.hasAttribute('data-type')) {
            e.preventDefault();
            onClickLink === null || onClickLink === void 0 ? void 0 : onClickLink(target);
            var linkType = target.getAttribute('data-type');
            if (linkType === 'termsOfTariff') {
                setIsFareModalOpen(true);
            }
        }
    };
    var hasPrivacyPolicy = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.privacyPolicy;
    var hasInsurancePolicy = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.insurance;
    var isCheckoutState = function (state) {
        return (state === null || state === void 0 ? void 0 : state.flight) !== undefined;
    };
    var isLocaleKA = locale === 'ka';
    var segmentId = isCheckoutState(order) ? order.flight.segments[0].segment.id : null;
    var flightId = isCheckoutState(order) ? order.flight.id : order === null || order === void 0 ? void 0 : order.flightId;
    var orderId = isCheckoutState(order) ? order.id : order === null || order === void 0 ? void 0 : order.orderId;
    var customAgreement = useMemo(function () {
        var ns = capitalizeFirstLetter(type);
        if (!i18n.exists('Agreement', { ns: ns, fallbackLng: i18n.language })) {
            return null;
        }
        return t('Agreement', { ns: ns });
    }, [type, i18n]);
    var _g = useRenderers(), renderCheckoutLinksLabel = _g.renderCheckoutLinksLabel, renderRefundLinksLabel = _g.renderRefundLinksLabel;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(className), onClick: onClick }, customAgreement ? (React.createElement("span", { dangerouslySetInnerHTML: { __html: customAgreement } })) : (React.createElement(React.Fragment, null,
            type === 'checkout' && (React.createElement(React.Fragment, null, (_c = renderCheckoutLinksLabel === null || renderCheckoutLinksLabel === void 0 ? void 0 : renderCheckoutLinksLabel(companyInfo)) !== null && _c !== void 0 ? _c : (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    t('Checkout:I accept the'),
                    " "),
                React.createElement(Link, { action: function () { } },
                    React.createElement("span", { "data-type": "termsOfTariff" }, t('Checkout:terms of tariff'))),
                (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.legal) ? (React.createElement(React.Fragment, null,
                    hasPrivacyPolicy ? (isLocaleKA ? (React.createElement(React.Fragment, null,
                        "\u00A0",
                        t('Checkout:and2'))) : (',')) : (React.createElement(React.Fragment, null,
                        "\u00A0",
                        t('Checkout:and'))),
                    "\u00A0",
                    React.createElement(Link, { target: '_blank', action: companyInfo.legal }, t('Checkout:terms of agreement')),
                    hasInsurancePolicy && (React.createElement("span", { "data-linkto": "insurance" },
                        ",\u00A0",
                        React.createElement(Link, { target: "_blank", action: companyInfo.insurance }, t('Checkout:terms of insurances')))),
                    hasPrivacyPolicy && (React.createElement(React.Fragment, null,
                        isLocaleKA ? ', ' : React.createElement(React.Fragment, null, "\u00A0"),
                        t('Checkout:and'),
                        "\u00A0",
                        React.createElement(Link, { target: '_blank', action: companyInfo.privacyPolicy }, t('Checkout:processing of my personal data')))))) : (React.createElement(React.Fragment, null,
                    "\u00A0",
                    t('Checkout:and'),
                    "\u00A0",
                    React.createElement(Link, { target: '_blank', action: companyInfo.privacyPolicy }, t('Checkout:processing of my personal data')))),
                React.createElement(React.Fragment, null, t('Checkout:infoLinksLabel_end', {
                    defaultValue: '',
                    fallbackLng: 'en'
                })))))),
            type === 'exchange' && (React.createElement(React.Fragment, null,
                t('Exchange:I agree with the'),
                "\u00A0",
                React.createElement(Link, { action: function () { } },
                    React.createElement("span", { "data-type": "termsOfTariff" }, t('Exchange:terms of tariff'))),
                (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.exareRulesURL) && (React.createElement(React.Fragment, null,
                    ',',
                    "\u00A0",
                    React.createElement(Link, { target: '_blank', action: companyInfo.exareRulesURL }, t('Exchange:Terms of Service')))),
                (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.exarePrivacyPolicyURL) && (React.createElement(React.Fragment, null,
                    "\u00A0",
                    t('Exchange:And'),
                    "\u00A0",
                    React.createElement(Link, { target: '_blank', action: companyInfo.exarePrivacyPolicyURL }, t('Exchange:Privacy Policy')))))),
            type === 'refund' && (React.createElement(React.Fragment, null, (_d = renderRefundLinksLabel === null || renderRefundLinksLabel === void 0 ? void 0 : renderRefundLinksLabel(companyInfo)) !== null && _d !== void 0 ? _d : (React.createElement(React.Fragment, null,
                t('Refund:I agree with the'),
                ' ',
                React.createElement(Link, { action: function () { } },
                    React.createElement("span", { "data-type": "termsOfTariff" }, t('Refund:terms of tariff'))),
                (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.exareRulesURL) && (React.createElement(React.Fragment, null,
                    ',',
                    React.createElement(Link, { target: '_blank', action: companyInfo.exareRulesURL }, t('Refund:Terms of Service')))),
                (companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.exarePrivacyPolicyURL) && (React.createElement(React.Fragment, null,
                    ' ',
                    t('Refund:And'),
                    "\u00A0",
                    React.createElement(Link, { target: '_blank', action: companyInfo.exarePrivacyPolicyURL }, t('Refund:Privacy Policy'))))))))))),
        order && (React.createElement(FareConditionsModal, { segmentId: segmentId, flightId: flightId, orderId: orderId, isOpen: isFareModalOpen, onClose: function () { return setIsFareModalOpen(false); } }))));
};
export default InfoLinksLabel;
