import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCheckinUpdateTravellersMutation } from '@websky/graphql';
import CheckinAddDocuments from '../CheckinAddDocumentsContent/CheckinAddDocuments';
import { getCheckinOrderId, getTravellers } from '../../../../../store/order/selectors';
import Dialog from '../../../../../../../Service/components/Dialog/Dialog';
import { prepareBookingRequest } from '../../../../../../../Checkout/utils';
import { fillCheckinOrder } from '../../../../../store/order/actions';
import SimpleLoader from '../../../../../../../SimpleLoader';
import { initI18n } from '../../../../../../../utils';
import { useTheme } from '../../../../../../../theme';
initI18n('CheckinAddDocuments');
var CheckinAddDocumentsService = function (_a) {
    var isOpen = _a.isOpen, onDocumentsUpdated = _a.onDocumentsUpdated;
    var t = useTranslation('CheckinAddDocuments').t;
    var theme = useTheme('Checkin').CheckinAddDocumentsService;
    var dispatch = useDispatch();
    var travellers = useSelector(getTravellers);
    var orderId = useSelector(getCheckinOrderId);
    var _b = __read(useCheckinUpdateTravellersMutation(), 2), updateTravellersValues = _b[0], _c = _b[1], loading = _c.loading, updateTravellersResponse = _c.data;
    var handleSubmit = function (passengers) { return __awaiter(void 0, void 0, void 0, function () {
        var req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    req = prepareBookingRequest(orderId, { customer: {}, passengers: passengers });
                    return [4 /*yield*/, updateTravellersValues({
                            variables: {
                                params: {
                                    orderId: orderId,
                                    travellersData: req.params.travellers
                                }
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (updateTravellersResponse) {
            dispatch(fillCheckinOrder(updateTravellersResponse.CheckinSetTravellersData));
            onDocumentsUpdated();
        }
    }, [updateTravellersResponse]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { dialogHeader: t('Add documents'), onClose: null, isOpen: isOpen, dialogType: 'fullscreen', dialogCloseClassName: theme.closeClassName, content: React.createElement(React.Fragment, null,
                loading && React.createElement(SimpleLoader, null),
                React.createElement(CheckinAddDocuments, { travellers: travellers, onSubmit: handleSubmit })) })));
};
export default CheckinAddDocumentsService;
