import { createSelector } from 'reselect';
var getState = function (state) { return state; };
export var getCommunicationCategory = createSelector(getState, function (state) { return state; });
export var getUnreadTotal = createSelector(getState, function (state) {
    var count = 0;
    for (var category in state) {
        count += state[category].unreadMessagesCount;
    }
    return count;
});
