import * as React from 'react';
import { useContext } from 'react';
import BaseComponents from './BaseComponents/theme';
import Inner from './Engine/theme';
import Button from './Button/theme';
import SelectedFare from './SelectedFare/theme';
import Input from './Input/theme';
import Counter from './Counter/theme';
import Link from './Link/theme';
import Switch from './Switch/theme';
import WarningModal from './WarningModal/theme';
import PromoLoader from './PromoLoader/theme';
import Toolbar from './Toolbar/theme';
import Money from './Money/theme';
import PhoneInput from './PhoneInput/theme';
import StatusMessage from './StatusMessage/theme';
import Tooltip from './Tooltip/theme';
import Slider from './Slider/theme';
import CloseIcon from './CloseIcon/theme';
import ServiceWithSegments from './ServiceWithSegments/theme';
import AccountModule from './Modules/Account/theme';
import Radio from './Radio/theme';
import Tabs from './Tabs/theme';
import PromoCode from './PromoCode/theme';
import Schedule from './LEO/Schedule/theme';
import Autocomplete from './Autocomplete/theme';
import FlightSelect from './FlightSelect/theme';
import PromoMessage from './PromoMessage/theme';
import Bag from './Baggage/Bag/theme';
import Passengers from './Passengers/theme';
import Refund from './Refund/theme';
import Pagination from './Pagination/theme';
import CheckoutOrderSummary from './CheckoutOrderSummary/theme';
import CustomerContactsForm from './CustomerContactsForm/theme';
import ResendTicketsForm from './ResendTicketsForm/theme';
import AdditionalServices from './AdditionalServices/theme';
import VisaForm from './VisaForm/theme';
import IncludedOptions from './Baggage/IncludedOptions/theme';
import RecaptchaPage from './RecaptchaPage/theme';
import CheckinRules from './CheckinRules/theme';
import MobileToolbar from './MobileToolbar/theme';
import PaymentResult from './PaymentResult/theme';
import Segment from './BoardingPassDesk/theme';
import Weather from './Weather/theme';
import MobileSelectedFare from './MobileSelectedFare/theme';
import SimpleLoader from './SimpleLoader/theme';
import SelectedPassenger from './SelectedPassenger/theme';
import SelectSegments from './SelectSegments/theme';
import ServiceRecommendation from './ServiceRecommendation/theme';
import Aeroexpress from './Aeroexpress/theme';
import Warnings from './Warnings/theme';
import Sidebar from './Sidebar/theme';
import SocialAuth from './SocialAuth/theme';
import MobileStepbar from './MobileStepbar/theme';
import PaymentForm from './PaymentForm/theme';
import PaymentTimeLimit from './PaymentTimeLimit/theme';
import MetasearchLoader from './MetasearchLoader/theme';
import Traveller from './Traveller/theme';
import OfferCard from './OfferCard/theme';
import CurrencyConverter from './CurrencyConverter/theme';
import Page404 from './Page404/theme';
import RetrieveBooking from './RetrieveBooking/theme';
import Amenities from './Amenities/theme';
import Datepicker from './Datepicker/theme';
import DateRangePicker from './DateRangePicker/theme';
import SearchFormDatepicker from './SearchFormDatepicker/theme';
import Lang from './Lang/theme';
import InfoCard from './InfoCard/theme';
import FullscreenDialog from './FullscreenDialog/theme';
import Modal from './Modal/theme';
import FlightPrice from './FlightPrice/theme';
import FareConditions from './FareConditions/theme';
import DocumentForm from './DocumentForm/theme';
import CompareFares from './CompareFares/theme';
import BaggageService from './BaggageService/theme';
import BaggagePage from './BaggagePage/theme';
import Airport from './Airport/theme';
import AdditionalServiceCard from './AdditionalServiceCard/theme';
import FareGroup from './FareGroup/theme';
import Meal from './Meal/theme';
import MealService from './MealService/theme';
import DesktopFlightInfo from './DesktopFlightInfo/theme';
import DesktopFlight from './DesktopFlight/theme';
import MobileFlight from './MobileFlight/theme';
import DesktopSummaryFlight from './DesktopSummaryFlight/theme';
import MobileSegmentInfo from './MobileSegmentInfo/theme';
import MobileSummaryFlight from './MobileSummaryFlight/theme';
import Contacts from './Contacts/theme';
import LoginPage from './LoginPage/theme';
import MobileAuthForm from './MobileAuthForm/theme';
import OrderSummary from './OrderSummary/theme';
import SeatMap from './SeatMap/theme';
import SuggestionModalContent from './SuggestionModalContent/theme';
import AdditionalServiceBanner from './AdditionalServiceBanner/theme';
import Stepbar from './Stepbar/theme';
import WeekCalendar from './WeekCalendar/theme';
import { defaultTheme as PriceGraph } from './PriceGraph/theme';
import Timer from './Timer/theme';
import Account from './Account/theme';
import { theme as SearchForm } from './SearchForm/theme';
import QuickSearchForm from './QuickSearchForm/theme';
import Results from './Results/theme';
import Checkout from './Checkout/theme';
import Disclaimer from './Disclaimer/theme';
import Service from './Service/theme';
import Checkin from './Modules/Checkin/theme';
import AddPassenger from './AddPassenger/theme';
import MobileSegment from './BoardingPassMob/theme';
import RegisteredPassenger from './RegisteredPassenger/theme';
import CheckoutAdditionalService from './CheckoutAdditionalService/theme';
import LoyaltyBonusStyles from './LoyaltyBonus/theme';
import Passenger from './Passenger/theme';
import SelectableSegment from './SelectableSegment/theme';
import Loyalty from './Loyalty/theme';
import Insurance from './Insurance/theme';
import Location from './Location/theme';
import FlightCard from './FlightCard/theme';
import TransferInfo from './TransferInfo/theme';
import FlightInfo from './FlightInfo/theme';
import SegmentTabs from './SegmentTabs/theme';
import DesktopFlightRoute from './DesktopFlightRoute/theme';
import FlightSortFilter from './FlightSortFilter/theme';
import OrderChangeHistoryModal from './OrderChangeHistoryModal/theme';
import FlightsSortings from './LEO/FlightsSortings/theme';
import FlightsFilters from './LEO/FlightsFilters/theme';
import FlightsListControls from './LEO/FlightsListControls/theme';
import ResultsSegments from './LEO/ResultsSegments/theme';
import TabButton from './Button/components/TabButton/theme';
import FareLock from './FareLock/theme';
import OrderCard from './OrderCard/theme';
import Exchange from './Modules/Exchange/theme';
import VipServiceSelect from './VipServiceSelect/theme';
import TravellerForm from './TravellerForm/theme';
import MilesHistory from './MilesHistory/theme';
import MilesRecovery from './MilesRecovery/theme';
import PassengerForm from './PassengerForm/theme';
import Notification from './Notification/theme';
import FfpInfoNotice from './FfpInfoNotice/theme';
import PnrHistory from './PnrHistory/theme';
import AircraftPopup from './AircraftPopup/theme';
import AddBooking from './AddBooking/theme';
import ProfileControls from './ProfileControls/theme';
import Baggage from './Baggage/Baggage/theme';
import BaggageList from './Baggage/BaggageList/theme';
import IncludedBaggage from './Baggage/IncludedBaggage/theme';
import AdditionalBaggage from './Baggage/AdditionalBaggage/theme';
import BaggageTotalPriceBlock from './Baggage/BaggageTotalPriceBlock/theme';
import BaggageCounter from './Baggage/BaggageCounter/theme';
import UpgradeBaggageDialog from './Baggage/UpgradeBaggageDialog/theme';
import SpecialEquipment from './Baggage/SpecialEquipment/theme';
import BaggageCard from './Baggage/BaggageCard/theme';
import OverweightBaggage from './Baggage/OverweightBaggage/theme';
import LocationAutocomplete from './LocationAutocomplete/theme';
import ScheduleForm from './ScheduleForm/theme';
import CoreSchedule from './Schedule/theme';
import PassengersDetails from './PassengersDetails/theme';
import AdditionalServiceWrapper from './AdditionalServiceWrapper/theme';
import InsurancePopup from './InsurancePopup/theme';
import SmsPopup from './SmsPopup/theme';
import PriorityBoardingPopup from './PriorityBoardingPopup/theme';
import DoubleSeatsModal from './DoubleSeatsModal/theme';
import ActualizationOk from './Checkout/components/Checkout/Actualization/components/ActualizationOk/theme';
import Forms from './Forms/theme';
import CheckinStart from './CheckinStart/theme';
import CheckinConditions from './CheckinConditions/theme';
import MobileHeader from './MobileHeader/theme';
import SubsidySuggestPopup from './SubsidySuggestPopup/theme';
import SubsidyRejectPopup from './SubsidyRejectPopup/theme';
import UserCard from './UserCard/theme';
import TravelCompanions from './TravelCompanions/theme';
import SlideBottom from './SlideBottom/theme';
import UpsaleModal from './UpsaleServicesModal/theme';
import Subscriptions from './Modules/Subscriptions/theme';
import SubscriptionsLanding from './Modules/Subscriptions/components/Landing/theme';
import TransitTooltip from './TransitTooltip/theme';
import ServiceSummary from './ServiceSummary/theme';
import Cart from './Cart/theme';
import TicketExample from './TicketExample/theme';
import LoyaltyInfo from './LoyaltyInfo/theme';
import SureModal from './SureModal/theme';
import InfoMessages from './InfoMessages/theme';
import WxPagination from './WxPagination/theme';
import SecureAccess from './SecureAccess/theme';
import html from './html/theme';
import ResultsError from './ResultsError/theme';
import FlightStatus from './Modules/FlightStatus/theme';
import Communications from './Communications/theme';
import PaymentMethodsError from './PaymentMethodsError/theme';
import InsurancePage from './InsurancePage/theme';
import ModalConfirm from './ModalConfirm/theme';
import Badge from './Badge/theme';
import EditPassenger from './EditPassenger/theme';
import TicketRequest from './TicketRequest/theme';
import Consents from './Consents/theme';
import Transfer from './Transfer/theme';
import RefundSummary from './RefundSummary/theme';
import UpgradeFlightClass from './UpgradeFlightClass/theme';
import AIPurchasePredictionService from './AIPurchasePredictionService/theme';
import AgreementCheckboxWrapper from './AgreementCheckboxWrapper/theme';
import AircraftLivery from './AircraftLivery/theme';
import BookingPreview from './BookingPreview/theme';
import Flights from './widgets/Flights/theme';
import Navigation from './widgets/navigation/theme';
import { mergeCSS } from './utils';
var theme = {
    // Elements
    Link: Link,
    Button: Button,
    Counter: Counter,
    Input: Input,
    Switch: Switch,
    Slider: Slider,
    Money: Money,
    WarningModal: WarningModal,
    PromoLoader: PromoLoader,
    Toolbar: Toolbar,
    Radio: Radio,
    WxPagination: WxPagination,
    Tabs: Tabs,
    ModalConfirm: ModalConfirm,
    Badge: Badge,
    // Widgets
    UpgradeFlightClass: UpgradeFlightClass,
    LocationAutocomplete: LocationAutocomplete,
    ServiceWithSegments: ServiceWithSegments,
    FlightsSortings: FlightsSortings,
    FlightsFilters: FlightsFilters,
    FlightsListControls: FlightsListControls,
    ResultsSegments: ResultsSegments,
    TabButton: TabButton,
    CloseIcon: CloseIcon,
    TransferInfo: TransferInfo,
    FlightInfo: FlightInfo,
    FlightCard: FlightCard,
    Location: Location,
    SelectableSegment: SelectableSegment,
    RecaptchaPage: RecaptchaPage,
    Bag: Bag,
    AdditionalServices: AdditionalServices,
    ResendTicketsForm: ResendTicketsForm,
    IncludedOptions: IncludedOptions,
    MobileToolbar: MobileToolbar,
    PaymentResult: PaymentResult,
    PaymentMethodsError: PaymentMethodsError,
    MobileAuthForm: MobileAuthForm,
    CheckinRules: CheckinRules,
    Segment: Segment,
    Weather: Weather,
    StatusMessage: StatusMessage,
    SimpleLoader: SimpleLoader,
    ServiceRecommendation: ServiceRecommendation,
    AddPassenger: AddPassenger,
    MobileSegment: MobileSegment,
    MobileSelectedFare: MobileSelectedFare,
    Aeroexpress: Aeroexpress,
    Pagination: Pagination,
    RegisteredPassenger: RegisteredPassenger,
    SelectedPassenger: SelectedPassenger,
    SelectSegments: SelectSegments,
    Warnings: Warnings,
    AdditionalServiceBanner: AdditionalServiceBanner,
    Sidebar: Sidebar,
    MobileStepbar: MobileStepbar,
    PaymentForm: PaymentForm,
    PaymentTimeLimit: PaymentTimeLimit,
    Stepbar: Stepbar,
    SocialAuth: SocialAuth,
    WeekCalendar: WeekCalendar,
    MetasearchLoader: MetasearchLoader,
    VisaForm: VisaForm,
    OfferCard: OfferCard,
    Timer: Timer,
    Traveller: Traveller,
    OrderSummary: OrderSummary,
    PhoneInput: PhoneInput,
    CurrencyConverter: CurrencyConverter,
    Page404: Page404,
    RetrieveBooking: RetrieveBooking,
    Amenities: Amenities,
    Datepicker: Datepicker,
    SearchFormDatepicker: SearchFormDatepicker,
    DateRangePicker: DateRangePicker,
    Lang: Lang,
    InfoCard: InfoCard,
    FullscreenDialog: FullscreenDialog,
    FlightPrice: FlightPrice,
    LoginPage: LoginPage,
    FareConditions: FareConditions,
    DocumentForm: DocumentForm,
    CompareFares: CompareFares,
    BaggageService: BaggageService,
    BaggagePage: BaggagePage,
    Airport: Airport,
    AircraftPopup: AircraftPopup,
    AdditionalServiceCard: AdditionalServiceCard,
    FareGroup: FareGroup,
    Meal: Meal,
    MealService: MealService,
    DesktopFlightInfo: DesktopFlightInfo,
    DesktopFlight: DesktopFlight,
    MobileFlight: MobileFlight,
    DesktopSummaryFlight: DesktopSummaryFlight,
    MobileSummaryFlight: MobileSummaryFlight,
    Contacts: Contacts,
    Results: Results,
    SearchForm: SearchForm,
    QuickSearchForm: QuickSearchForm,
    SeatMap: SeatMap,
    PriceGraph: PriceGraph,
    Account: Account,
    Checkout: Checkout,
    SuggestionModalContent: SuggestionModalContent,
    MobileSegmentInfo: MobileSegmentInfo,
    Modal: Modal,
    Disclaimer: Disclaimer,
    Service: Service,
    Passenger: Passenger,
    Checkin: Checkin,
    CheckoutAdditionalService: CheckoutAdditionalService,
    LoyaltyBonusStyles: LoyaltyBonusStyles,
    CheckoutOrderSummary: CheckoutOrderSummary,
    Loyalty: Loyalty,
    Passengers: Passengers,
    Autocomplete: Autocomplete,
    Insurance: Insurance,
    Tooltip: Tooltip,
    SegmentTabs: SegmentTabs,
    DesktopFlightRoute: DesktopFlightRoute,
    SelectedFare: SelectedFare,
    PromoMessage: PromoMessage,
    FlightSortFilter: FlightSortFilter,
    PromoCode: PromoCode,
    CustomerContactsForm: CustomerContactsForm,
    OrderCard: OrderCard,
    FlightSelect: FlightSelect,
    Schedule: Schedule,
    OrderChangeHistoryModal: OrderChangeHistoryModal,
    Inner: Inner,
    FareLock: FareLock,
    VipServiceSelect: VipServiceSelect,
    MilesRecovery: MilesRecovery,
    MilesHistory: MilesHistory,
    ActualizationOk: ActualizationOk,
    Exchange: Exchange,
    Refund: Refund,
    Notification: Notification,
    PassengerForm: PassengerForm,
    FfpInfoNotice: FfpInfoNotice,
    PnrHistory: PnrHistory,
    AddBooking: AddBooking,
    ProfileControls: ProfileControls,
    AccountModule: AccountModule,
    TravellerForm: TravellerForm,
    Forms: Forms,
    Baggage: Baggage,
    BaggageList: BaggageList,
    BaggageTotalPriceBlock: BaggageTotalPriceBlock,
    BaggageCounter: BaggageCounter,
    IncludedBaggage: IncludedBaggage,
    AdditionalBaggage: AdditionalBaggage,
    UpgradeBaggageDialog: UpgradeBaggageDialog,
    SpecialEquipment: SpecialEquipment,
    BaggageCard: BaggageCard,
    OverweightBaggage: OverweightBaggage,
    AdditionalServiceWrapper: AdditionalServiceWrapper,
    CheckinStart: CheckinStart,
    CheckinConditions: CheckinConditions,
    MobileHeader: MobileHeader,
    ScheduleForm: ScheduleForm,
    CoreSchedule: CoreSchedule,
    SubsidySuggestPopup: SubsidySuggestPopup,
    SubsidyRejectPopup: SubsidyRejectPopup,
    UserCard: UserCard,
    TravelCompanions: TravelCompanions,
    PassengersDetails: PassengersDetails,
    UpsaleModal: UpsaleModal,
    SlideBottom: SlideBottom,
    Subscriptions: Subscriptions,
    SubscriptionsLanding: SubscriptionsLanding,
    BaseComponents: BaseComponents,
    InsurancePopup: InsurancePopup,
    SmsPopup: SmsPopup,
    PriorityBoardingPopup: PriorityBoardingPopup,
    TransitTooltip: TransitTooltip,
    ServiceSummary: ServiceSummary,
    Cart: Cart,
    TicketExample: TicketExample,
    DoubleSeatsModal: DoubleSeatsModal,
    LoyaltyInfo: LoyaltyInfo,
    SureModal: SureModal,
    InfoMessages: InfoMessages,
    SecureAccess: SecureAccess,
    html: html,
    ResultsError: ResultsError,
    FlightStatus: FlightStatus,
    Communications: Communications,
    InsurancePage: InsurancePage,
    EditPassenger: EditPassenger,
    TicketRequest: TicketRequest,
    Consents: Consents,
    Transfer: Transfer,
    RefundSummary: RefundSummary,
    AIPurchasePredictionService: AIPurchasePredictionService,
    AgreementCheckboxWrapper: AgreementCheckboxWrapper,
    AircraftLivery: AircraftLivery,
    BookingPreview: BookingPreview,
    Flights: Flights,
    Navigation: Navigation
};
var ThemeContext = React.createContext(theme);
export var ThemeProvider = React.memo(function (_a) {
    var value = _a.value, children = _a.children;
    var theme = useContext(ThemeContext);
    return React.createElement(ThemeContext.Provider, { value: mergeCSS(theme, value) }, children);
});
export var ThemeConsumer = ThemeContext.Consumer;
export { ThemeContext };
export var useTheme = function (module) { return useContext(ThemeContext)[module]; };
