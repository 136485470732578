import { __assign, __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import CardHeader from '@material-ui/core/CardHeader';
var ModalHeading = function (_a) {
    var heading = _a.heading, classes = _a.classes, headerClassName = _a.headerClassName, headingClassName = _a.headingClassName, props = __rest(_a, ["heading", "classes", "headerClassName", "headingClassName"]);
    var css = useTheme().ModalHeading;
    return (React.createElement(CardHeader, __assign({}, props, { classes: __assign(__assign({}, classes), { root: cn(css.header, headerClassName), title: cn(css.heading, headingClassName) }), title: React.createElement("span", null, heading) })));
};
export default ModalHeading;
