import { __assign, __read, __spreadArray } from "tslib";
import { getTotalPrice } from '../../utils';
export var getAnimalTotalPrice = function (services, travellers, withoutUnconfirmed) {
    if (withoutUnconfirmed === void 0) { withoutUnconfirmed = false; }
    var selectedServicePrices = [];
    services.forEach(function (service) {
        var allowedPassengerIds = new Set(service.allowedPassengers.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
        travellers === null || travellers === void 0 ? void 0 : travellers.forEach(function (traveller) {
            var _a, _b, _c;
            if (allowedPassengerIds.has(traveller.id)) {
                (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(function (selectedService) {
                    if (selectedService.serviceId === service.id &&
                        (!withoutUnconfirmed || (withoutUnconfirmed && selectedService.confirmedCount === 0))) {
                        selectedServicePrices.push(__assign(__assign({}, service.price), { amount: service.price.amount * selectedService.segmentIds.length }));
                    }
                });
            }
        });
    });
    return getTotalPrice(selectedServicePrices, function (price) { return price; });
};
