import * as React from 'react';
import { InsurancePage, InsuranceUpsaleModal } from '../../../../InsurancePage';
import ServicesToolbar from '../../../../ServicesToolbar';
import { useTheme } from '../../../../theme';
import { Money } from '../../../../Money';
import { useTranslation } from 'react-i18next';
import { useCheckoutInsuranceRoute } from './insurance.hook';
import MediaQuery from '../../../../MediaQuery/MediaQuery';
import SimpleLoader from '../../../../SimpleLoader';
export var Insurance = function (_a) {
    var goToNextStep = _a.goToNextStep, goToPrevStep = _a.goToPrevStep, steps = _a.steps;
    var theme = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation('Checkout').t;
    var model = useCheckoutInsuranceRoute({ goToNextStep: goToNextStep, goToPrevStep: goToPrevStep, steps: steps });
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            isLoading: model.isLoading,
            additional: model.insurancePriceToPay.amount ? (React.createElement("div", { className: theme.gdsTotalPrice },
                t('Insurance'),
                ": ",
                React.createElement(Money, { money: model.insurancePriceToPay }))) : null,
            totalPrice: model.orderPriceToPay,
            onButtonClick: model.handleNextStep,
            onBackButtonClick: model.isCanGoBack ? goToPrevStep : undefined
        }, mobileToolbarProps: {
            onContinue: model.handleNextStep,
            onBack: goToPrevStep
        } },
        React.createElement(InsurancePage, { onServicesChanged: model.setIsNeedToSendSaveReq, onTotalInsurancePriceChange: model.setInsurancePriceToPay, onSaveOrderServicesParamsChange: model.setSaveOrderServicesParams, isShowImportantInsurancesError: model.isShowImportantInsurancesError, className: theme.container_insurance }),
        React.createElement(MediaQuery, { maxWidth: 'mobile' }, model.isLoading && React.createElement(SimpleLoader, null)),
        React.createElement(InsuranceUpsaleModal, { insurance: model.priorityInsurance, isOpen: model.isUpsaleOpen, onAgree: model.handleAgree, onDisagree: model.handleDisagree })));
};
