import React from 'react';
import cn from 'classnames';
import { CloseClear } from '../../../Icons';
import { useTheme } from '../../../theme';
var Close = function (_a) {
    var className = _a.className, onClick = _a.onClick;
    var theme = useTheme('CheckoutAdditionalService').Close;
    return (React.createElement("div", { className: cn(theme.close, className), onClick: onClick }, CloseClear));
};
export default Close;
export var closeRender = function (onClick) {
    return React.createElement(Close, { onClick: onClick });
};
