import { __assign, __read, __spreadArray } from "tslib";
import i18n from 'i18next';
import { addDays, parse } from 'date-fns';
import { isRoundTrip } from './components/AeroexpressPopup/utils';
import { format } from '../utils';
export var getPassengerPricesMap = function (travellers, services, isMinPrice) {
    var pricesByPassengerMap = new Map();
    services.forEach(function (service) {
        var allowedPassengerIds = new Set(service.allowedPassengers.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
        allowedPassengerIds.forEach(function (passengerId) {
            var _a;
            var traveller = travellers.find(function (traveller) { return traveller.id === passengerId; });
            if (traveller) {
                var type = traveller.type;
                var supplierType = traveller.supplierTravellerType;
                var priceByPassenger = pricesByPassengerMap.get(type);
                var condition = isMinPrice
                    ? !priceByPassenger || ((_a = priceByPassenger === null || priceByPassenger === void 0 ? void 0 : priceByPassenger.price) === null || _a === void 0 ? void 0 : _a.amount) > service.price.amount
                    : !priceByPassenger;
                if (condition) {
                    pricesByPassengerMap.set(type, { type: type, supplierType: supplierType, price: service.price });
                }
            }
        });
    });
    return __spreadArray([], __read(pricesByPassengerMap.values()), false);
};
export var getAeroexpressTripLabel = function (trip) {
    var departureDate = parse(trip.departureDate, 'HH:mm dd.MM.yyyy', new Date());
    var label = format(departureDate, 'dd.MM.yyyy');
    if (isRoundTrip(trip)) {
        label += " - ".concat(i18n.t('Aeroexpress:To'), " ").concat(format(addDays(departureDate, 30), 'dd.MM.yyyy'));
    }
    return label;
};
export var getVipServicesToDelete = function (payload, travellers) {
    var _a, _b, _c;
    var removeServices = [];
    var commonRemoveService = {
        serviceId: payload.serviceId,
        passengerId: payload.passengerId,
        count: 0
    };
    if (payload.allSegments) {
        var travellerServicesById = (_c = (_b = (_a = travellers.find(function (traveller) { return traveller.id === payload.passengerId; })) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services;
        if (travellerServicesById === null || travellerServicesById === void 0 ? void 0 : travellerServicesById.length) {
            travellerServicesById.forEach(function (service) {
                if (service.serviceId === payload.serviceId) {
                    removeServices.push(__assign(__assign({}, commonRemoveService), { segmentIds: service.segmentIds }));
                }
            });
        }
    }
    else {
        removeServices.push(__assign(__assign({}, commonRemoveService), { segmentIds: [payload.segmentId] }));
    }
    return removeServices;
};
