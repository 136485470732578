import * as React from 'react';
import { useTheme } from '../../../theme';
var InsuranceOptions = function (_a) {
    var insurance = _a.insurance;
    var theme = useTheme('Insurance').InsuranceCardStyles;
    return (React.createElement("div", { className: theme.options }, insurance.options
        .filter(function (option) { return option.included; })
        .map(function (option, key) { return (React.createElement("div", { key: key, className: theme.option, dangerouslySetInnerHTML: { __html: option.name } })); })));
};
export default InsuranceOptions;
