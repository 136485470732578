import { __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useCallback, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '../../../theme';
import { useFileConverter } from './hooks';
import { useToggleable } from '../../../hooks';
import { AddCircle, Remove, Choosed } from '../../../Icons';
import Link from '../../../Link';
var AddFile = function (props) {
    var _a;
    var t = useTranslation('Refund').t;
    var passenger = props.passenger;
    var _b = useToggleable(false), hasValue = _b.isOpen, setEmpty = _b.close, setHasValue = _b.open;
    var css = useTheme('Refund').AddFile;
    var inputRef = useRef(null);
    var toBase64 = useFileConverter().toBase64;
    var handleChange = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var input, document, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!inputRef.current) {
                        return [2 /*return*/];
                    }
                    input = inputRef.current;
                    if (!input.value) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, toBase64(input.files[0])];
                case 2:
                    document = _a.sent();
                    setHasValue();
                    props.onLoad({
                        passengerId: passenger.id,
                        file: document
                    });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    input.value = null;
                    if (props.onValidationError) {
                        props.onValidationError();
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [props.onLoad, props.onClear, inputRef, hasValue]);
    var handleClear = useCallback(function (e) {
        if (!inputRef.current || !hasValue) {
            return;
        }
        e.preventDefault();
        props.onClear(passenger.id);
        setEmpty();
    }, [hasValue, inputRef, props.onClear]);
    var handleOpenInput = useCallback(function () {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.click();
    }, [inputRef, hasValue]);
    return (React.createElement(FormControlLabel, { className: cn(css.label, props.className), classes: { label: css.labelContent }, control: React.createElement("input", { ref: inputRef, className: css.input, onChange: handleChange, type: "file" }), label: React.createElement("span", { className: css.labelContent },
            React.createElement("span", { className: cn(css.icon, (_a = {}, _a[css.addIcon] = !hasValue, _a[css.addedIcon] = hasValue, _a)) }, hasValue ? Choosed : AddCircle),
            React.createElement(Link, { action: handleOpenInput, className: css.link },
                props.passenger.name,
                ": ",
                t(props.documentType)),
            hasValue && (React.createElement("span", { className: css.removeIcon, onClick: handleClear }, Remove))) }));
};
export default AddFile;
