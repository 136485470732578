import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
// TODO: по факту это обвязка для любого места ( см SeatsInfoByPrice )
var FreeSeatInfo = function (_a) {
    var className = _a.className, seatClassName = _a.seatClassName, customTitle = _a.customTitle, dataColorNum = _a.dataColorNum;
    var theme = useTheme('SeatMap').SeatsInfoStyles;
    var t = useTranslation('SeatMap').t;
    return (React.createElement("div", { className: cn(theme.freeSeat__container, className) },
        React.createElement("span", { "data-color-num": dataColorNum, className: cn(theme.freeSeat__seat, seatClassName) }),
        React.createElement("span", null, customTitle !== null && customTitle !== void 0 ? customTitle : t('Free seats'))));
};
export default FreeSeatInfo;
