import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSingleInsurancePrograms } from '../../../store/order/selectors';
import { Insurance } from '../../../../CheckoutAdditionalService/components';
var OrderInsuranceBlockSlot = function () {
    var withOutSingleInsurancePrograms = useSelector(getSingleInsurancePrograms(true));
    var singleInsurancePrograms = useSelector(getSingleInsurancePrograms());
    var singleInsuranceProgramsLists = useMemo(function () { return singleInsurancePrograms.map(function (p) { return [p]; }); }, [
        singleInsurancePrograms
    ]);
    return (React.createElement(React.Fragment, null,
        withOutSingleInsurancePrograms.length > 0 && (React.createElement(Insurance, { insurancePrograms: withOutSingleInsurancePrograms })),
        singleInsuranceProgramsLists.map(function (insurance, index) { return (React.createElement(Insurance, { key: index, insurancePrograms: insurance, asSingle: true })); })));
};
export default OrderInsuranceBlockSlot;
