import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TravellerFieldEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { decapitalizeFirstLetter, initI18n } from '../../utils';
import { hideText } from '../../Passenger/utils';
import { Person } from '../../Icons';
import Button from '../../Button';
initI18n('SelectedPassenger');
var SelectedPassenger = function (_a) {
    var className = _a.className, passengerValues = _a.passenger, services = _a.services, onDelete = _a.onDelete;
    var css = useTheme('SelectedPassenger').SelectedPassenger;
    var t = useTranslation('SelectedPassenger').t;
    var passenger = {};
    var nationalities = new Map();
    passengerValues.values.forEach(function (value) {
        passenger[decapitalizeFirstLetter(value.type)] = value.value;
        if (value.type === TravellerFieldEnum.Nationality) {
            value.validationRules[0].options.forEach(function (nationality) {
                nationalities.set(nationality.value, nationality.label);
            });
        }
    });
    var onDeleteHandler = function () {
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(passengerValues);
    };
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.passenger },
            React.createElement("div", { className: css.passengerContent },
                React.createElement("div", { className: css.icon }, Person),
                React.createElement("div", { className: css.passengerName },
                    passenger.firstName.toLowerCase(),
                    " ",
                    passenger.lastName.toLowerCase()),
                React.createElement("div", { className: css.passengerInfo },
                    React.createElement("div", { className: css.passengerInfo__item },
                        React.createElement("span", null,
                            passenger.birthDate,
                            ", "),
                        React.createElement("span", null, passenger.gender === 'M' ? t('Male') : t('Female'))),
                    React.createElement("div", { className: css.passengerInfo__item },
                        React.createElement("span", null,
                            t('Citizenship'),
                            ": "),
                        React.createElement("span", null,
                            nationalities.get(passenger.nationality),
                            ", "),
                        React.createElement("span", null,
                            t(passenger.docType),
                            ": "),
                        ' ',
                        React.createElement("span", null,
                            passenger.docNumber ? hideText(passenger.docNumber) : '-',
                            ","),
                        ' ',
                        React.createElement("span", null,
                            t('Expiry date'),
                            ": "),
                        " ",
                        React.createElement("span", null, passenger.docExpiryDate)),
                    React.createElement("div", { className: css.passengerInfo__item },
                        React.createElement("span", null,
                            t('Ticket'),
                            ": "),
                        React.createElement("span", null, passengerValues.tickets.map(function (_a) {
                            var number = _a.number;
                            return number;
                        }).join(', '))),
                    passenger.loyaltyNumber && (React.createElement("div", { className: css.passengerInfo__item },
                        t('Frequent Flyer Number'),
                        ": ",
                        passenger.loyaltyNumber)))),
            onDelete && (React.createElement(Button, { variant: "text", className: css.button, onClick: onDeleteHandler }, t('Cancel check-in')))),
        services && React.createElement("div", { className: css.services }, services)));
};
export default SelectedPassenger;
