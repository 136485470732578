import { __assign, __rest } from "tslib";
import * as React from 'react';
import RadioMUI from '@material-ui/core/Radio';
import cn from 'classnames';
import { useTheme } from '../../theme';
var Radio = React.forwardRef(function (_a, ref) {
    var classes = _a.classes, _b = _a.disableRipple, disableRipple = _b === void 0 ? true : _b, props = __rest(_a, ["classes", "disableRipple"]);
    var css = useTheme('Radio').Radio;
    return (React.createElement(RadioMUI, __assign({}, props, { classes: __assign(__assign({}, classes), { root: cn(classes === null || classes === void 0 ? void 0 : classes.root, css.root, css.additional_class), checked: cn(classes === null || classes === void 0 ? void 0 : classes.checked, css.checked), disabled: cn(classes === null || classes === void 0 ? void 0 : classes.disabled, css.disabled) }), disableRipple: disableRipple, ref: ref })));
});
export default Radio;
