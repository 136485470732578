import * as React from 'react';
import { useEffect, useMemo } from 'react';
import PromoLoader from '../../../PromoLoader';
import { useCreateOrder } from './hooks';
var CreateOrder = function (props) {
    var flightIdsString = props.flightsIds, redirectOnFail = props.redirectOnFail, onSuccess = props.onSuccess;
    var flightIds = flightIdsString.split('/');
    var createOrderParams = useMemo(function () {
        return flightIds.length > 1 ? { flightIds: flightIds } : { flightId: flightIds[0] };
    }, [flightIdsString]);
    var _a = useCreateOrder(createOrderParams), loading = _a.loading, order = _a.order, orderError = _a.orderError;
    useEffect(function () {
        if (order) {
            onSuccess(order, true);
        }
    }, [order]);
    if (orderError) {
        return redirectOnFail;
    }
    return loading ? React.createElement(PromoLoader, null) : null;
};
export default CreateOrder;
