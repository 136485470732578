import { __read, __spreadArray } from "tslib";
import i18n from 'i18next';
import { createSelector } from 'reselect';
import { getSegments } from '../order/selectors';
import { ALL_FLIGHT } from './reducers';
export var getFlightIdsToExchange = function (state) { return state.flightsToExchange; };
export var getExchangeFlights = createSelector(getSegments, function (segments) {
    var flights = [];
    if (segments.length > 1) {
        flights = __spreadArray(__spreadArray([], __read(flights), false), [{ key: ALL_FLIGHT, value: i18n.t('Exchange:Whole flight') }], false);
    }
    segments.forEach(function (segment) {
        var departure = segment.segments[0].departure;
        var arrival = segment.segments[segment.segments.length - 1].arrival;
        flights = __spreadArray(__spreadArray([], __read(flights), false), [
            {
                key: segment.groupId,
                value: [departure.airport.city.name, arrival.airport.city.name].join(' - '),
                date: new Date(departure.date)
            }
        ], false);
    });
    return flights;
});
export var getIsExchangeFlightsSelected = createSelector(getFlightIdsToExchange, function (selectedExchangeFlights) {
    return selectedExchangeFlights.filter(function (flight) { return flight.key !== ALL_FLIGHT; }).every(function (flight) { return flight.date; });
});
