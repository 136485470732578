import * as React from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '../../../theme';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { CloseClear } from '../../../Icons';
var MobileStep = function (props) {
    var _a, _b;
    var isOpen = props.isActive, renderTitle = props.renderTitle, onCloseClick = props.onCloseClick, className = props.className;
    var css = useTheme('Refund').MobileStep;
    return (React.createElement(MediaQuery, { maxWidth: "mobile" },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.header, className, (_a = {}, _a[css.active] = isOpen, _a)) },
                props.icon && React.createElement("div", { className: css.icon }, props.icon),
                renderTitle(),
                onCloseClick && (React.createElement("div", { onClick: onCloseClick, className: css.close }, CloseClear))),
            React.createElement(Collapse, { in: isOpen, classes: { wrapperInner: cn(css.content, (_b = {}, _b[css.active] = isOpen, _b)) } }, props.children))));
};
export default MobileStep;
