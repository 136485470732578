import * as React from 'react';
import { useCallback } from 'react';
import { isAfter, isToday, isEqual } from 'date-fns';
import cn from 'classnames';
import { API_DATE_FORMAT, format } from '../../../utils';
import { useTheme } from '../../../theme';
import { getWeekDatesIntervalOfDate } from '../../utils';
var WeekDays = function (_a) {
    var className = _a.className, startDate = _a.startDate, availableDates = _a.availableDates, onClickDate = _a.onClickDate;
    var theme = useTheme('CoreSchedule').WeekDays;
    var week = getWeekDatesIntervalOfDate(startDate).map(function (date) {
        var isAvailable = availableDates.some(function (availableDate) { return isEqual(availableDate, date); });
        var isAfterOrToday = isAfter(date, new Date()) || isToday(date);
        return {
            isActive: isAvailable && isAfterOrToday,
            metaDate: format(date, API_DATE_FORMAT),
            weekday: format(date, 'EEEEEE'),
            date: format(date, 'dd.MM')
        };
    });
    var onClick = useCallback(function (date) { return function () {
        onClickDate === null || onClickDate === void 0 ? void 0 : onClickDate(date);
    }; }, [onClickDate]);
    return (React.createElement("div", { className: cn(theme.container, className) }, week.map(function (day) {
        var _a;
        return (React.createElement("div", { key: day.date, className: cn(theme.day, (_a = {},
                _a[theme.active] = day.isActive,
                _a)), onClick: day.isActive ? onClick(day.metaDate) : null },
            React.createElement("div", { className: theme.weekday }, day.weekday),
            React.createElement("div", { className: theme.date }, day.date)));
    })));
};
export default WeekDays;
