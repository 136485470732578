import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { Link } from '../index';
import { useTheme } from '../theme';
import * as icons from './icons';
import { useConfig } from '../context';
import { PromoMessageIcons } from './types';
import { initI18n } from '../utils';
import { useTranslation } from 'react-i18next';
initI18n('PromoMessage');
var PromoMessage = function (props) {
    var theme = useTheme('PromoMessage').PromoMessage;
    var t = useTranslation('PromoMessage').t;
    var icon;
    switch (props.icon) {
        case PromoMessageIcons.TimeIsMoney:
            icon = icons.TimeIsMoneyIcon;
            break;
        default:
            break;
    }
    return (React.createElement("div", { className: cn(props.className, theme.wrapper) },
        React.createElement("div", { className: theme.inner },
            React.createElement("div", { className: theme.icon }, icon),
            React.createElement("h6", { className: theme.title }, t(props.title)),
            React.createElement("p", { className: theme.content },
                React.createElement("div", { className: theme.content__text, dangerouslySetInnerHTML: { __html: t(props.content) } }),
                props.link && (React.createElement("div", { className: theme.links },
                    React.createElement(Link, { className: theme.link, target: '_blank', action: props.link.url }, t(props.link.text))))))));
};
export var PromoMessages = function (_a) {
    var _b, _c, _d, _e, _f;
    var className = _a.className;
    var config = useConfig();
    if (!((_c = (_b = config.Engine) === null || _b === void 0 ? void 0 : _b.promoMessages) === null || _c === void 0 ? void 0 : _c.length)) {
        return null;
    }
    return (React.createElement("div", { className: className }, (_f = (_e = (_d = config.Engine) === null || _d === void 0 ? void 0 : _d.promoMessages) === null || _e === void 0 ? void 0 : _e.map(function (promoMessage) { return React.createElement(PromoMessage, __assign({}, promoMessage)); })) !== null && _f !== void 0 ? _f : null));
};
export default PromoMessage;
