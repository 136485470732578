import { combineReducers } from 'redux';
import { selectedFaresReducer } from './selectedFares/reducers';
import { currentLegReducer } from './currentLeg/reducers';
import { legsReducer } from './legs/reducers';
import legInView from './legInView/reducers';
import infoMessagesReducer from './infoMessages/reducers';
export var rootReducer = combineReducers({
    selectedFares: selectedFaresReducer,
    currentLeg: currentLegReducer,
    legs: legsReducer,
    legInView: legInView,
    infoMessages: infoMessagesReducer
});
