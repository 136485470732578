export var ItemCategory;
(function (ItemCategory) {
    ItemCategory["PriceFreeze"] = "hold";
    ItemCategory["Meal"] = "meal";
    ItemCategory["Flight"] = "flight";
    ItemCategory["Seat"] = "seat";
    ItemCategory["Baggage"] = "baggage";
    ItemCategory["Insurance"] = "insurance";
    ItemCategory["Aeroexpress"] = "aeroexpress";
    ItemCategory["Pets"] = "pets";
    ItemCategory["Business"] = "business hall";
})(ItemCategory || (ItemCategory = {}));
export var Recommendation;
(function (Recommendation) {
    Recommendation["Recommended"] = "recommended";
    Recommendation["NoRecommended"] = "no recommendation";
})(Recommendation || (Recommendation = {}));
export var OrderStep;
(function (OrderStep) {
    OrderStep["Booking"] = "booking";
    OrderStep["Checkout"] = "checkout";
    OrderStep["Paid"] = "paid";
    OrderStep["Checkin"] = "check in";
})(OrderStep || (OrderStep = {}));
export var PurchaseShowPopupAction;
(function (PurchaseShowPopupAction) {
    PurchaseShowPopupAction["Decline"] = "decline";
    PurchaseShowPopupAction["Add"] = "add";
    PurchaseShowPopupAction["Change"] = "change";
})(PurchaseShowPopupAction || (PurchaseShowPopupAction = {}));
export var DataLayerEvent;
(function (DataLayerEvent) {
    DataLayerEvent["ViewItemList"] = "view_item_list";
    DataLayerEvent["ViewItem"] = "view_item";
    DataLayerEvent["AddToCart"] = "add_to_cart";
    DataLayerEvent["RemoveFromCart"] = "remove_from_cart";
    DataLayerEvent["Checkout"] = "begin_checkout";
    DataLayerEvent["Purchase"] = "purchase";
    DataLayerEvent["PurchaseShowPopup"] = "purchase_show_popup";
})(DataLayerEvent || (DataLayerEvent = {}));
export var DataLayerItemsListName;
(function (DataLayerItemsListName) {
    DataLayerItemsListName["SearchResults"] = "Search results";
    DataLayerItemsListName["Fares"] = "Tariffs";
    DataLayerItemsListName["Seats"] = "Seats";
    DataLayerItemsListName["Baggage"] = "Baggage options";
    DataLayerItemsListName["Meal"] = "Meals";
    DataLayerItemsListName["Insurance"] = "Insurance options";
    DataLayerItemsListName["Aeroexpress"] = "Aeroexpress options";
    DataLayerItemsListName["Pets"] = "Pets options";
    DataLayerItemsListName["Business"] = "Business hall options";
})(DataLayerItemsListName || (DataLayerItemsListName = {}));
export var WxEvents;
(function (WxEvents) {
    WxEvents[WxEvents["PriceFreeze"] = 0] = "PriceFreeze";
    WxEvents[WxEvents["MealItem"] = 1] = "MealItem";
    WxEvents[WxEvents["MealsList"] = 2] = "MealsList";
    WxEvents[WxEvents["AeroexpressList"] = 3] = "AeroexpressList";
    WxEvents[WxEvents["PetsList"] = 4] = "PetsList";
    WxEvents[WxEvents["InsurancesList"] = 5] = "InsurancesList";
    WxEvents[WxEvents["BaggageList"] = 6] = "BaggageList";
    WxEvents[WxEvents["BaggageItem"] = 7] = "BaggageItem";
    WxEvents[WxEvents["SeatsList"] = 8] = "SeatsList";
    WxEvents[WxEvents["VipList"] = 9] = "VipList";
    WxEvents[WxEvents["BusinessLoungeList"] = 10] = "BusinessLoungeList";
    WxEvents[WxEvents["AiPurchaseModalOpen"] = 11] = "AiPurchaseModalOpen";
    WxEvents[WxEvents["AiPurchaseModalClose"] = 12] = "AiPurchaseModalClose";
})(WxEvents || (WxEvents = {}));
