import { __assign, __read, __spreadArray } from "tslib";
import { createSelector } from 'reselect';
import { getTotalPrice } from '../../../utils';
import { joinServicesByRfics } from '../utils';
var getState = function (state) { return state; };
export var getService = createSelector(getState, function (state) { return state.services; });
export var getSelectedServices = createSelector(getState, function (state) { return state.selectedServices; });
export var getPassengers = createSelector(getState, function (state) { return state.passengers; });
export var getSegmentIds = createSelector(getState, function (state) { return state.segmentIds; });
export var getAvailableSegmentIds = createSelector(getService, function (baggage) {
    var availableSegmentIdsSet = new Set();
    baggage.forEach(function (baggage) {
        baggage.segmentIds.forEach(function (segmentIds) {
            segmentIds.forEach(function (segmentId) { return availableSegmentIdsSet.add(segmentId); });
        });
    });
    return __spreadArray([], __read(availableSegmentIdsSet), false);
});
export var createGetSelected = function (segmentId, travellerId, servicesIds) {
    return createSelector(getSelectedServices, function (items) {
        return items.filter(function (el) {
            return (el.passengerId === travellerId &&
                servicesIds.includes(el.serviceId) &&
                (!segmentId || el.segmentIds.includes(segmentId)));
        });
    });
};
export var getSelectedBaggage = createSelector([
    function (state) { return state; },
    function (_, segmentId, travellerId, servicesIds) { return ({
        segmentId: segmentId,
        travellerId: travellerId,
        servicesIds: servicesIds
    }); }
], function (state, params) {
    return state.selectedServices.filter(function (el) {
        return (el.passengerId === params.travellerId &&
            params.servicesIds.includes(el.serviceId) &&
            (!params.segmentId || el.segmentIds.includes(params.segmentId)));
    });
});
export var getIsAllServicesCanAddedToEntireFlight = createSelector([getService, getSegmentIds], function (services, segmentIds) {
    var groupedServices = joinServicesByRfics(services.filter(function (service) { return service.canBeAdded; }));
    if (!groupedServices.length) {
        return false;
    }
    return groupedServices.every(function (servicesWithSameRfisc) {
        var availableSegmentsSet = new Set();
        servicesWithSameRfisc.forEach(function (service) {
            return service.segmentIds.forEach(function (segmentGroup) {
                return segmentGroup.forEach(function (segmentId) { return availableSegmentsSet.add(segmentId); });
            });
        });
        return availableSegmentsSet.size === segmentIds.length;
    });
});
export var getDifferentForReturnFlight = createSelector([getState, getIsAllServicesCanAddedToEntireFlight], function (state, isAllServicesCanAddForWholeFlight) {
    if (isAllServicesCanAddForWholeFlight) {
        return state.differentForReturnFlight;
    }
    return true;
});
export var getPrice = createSelector(getService, getSelectedServices, function (services, items) {
    if (items.every(function (item) { return !item.count; })) {
        return null;
    }
    return getTotalPrice(items, function (item) {
        var serviceItem = services.find(function (service) { return item.serviceId === service.id; });
        if (serviceItem) {
            return __assign(__assign({}, serviceItem.price), { amount: serviceItem.price.amount * item.count * item.segmentIds.length });
        }
        return null;
    });
});
export var getDisabled = createSelector(getState, function (state) { return state.disabled; });
export var getMaxBaggageCount = createSelector(getState, function (state) { return state.maxBaggageCount; });
export var getMaxBaggagePerPassengerCount = createSelector(getState, function (state) { return state.maxBaggagePerPassengerCount; });
export var getSelectedServicesByPassengersMap = createSelector(getState, function (state) {
    var passengerMap = new Map();
    state.selectedServices.forEach(function (service) {
        if (passengerMap.has(service.passengerId)) {
            passengerMap.get(service.passengerId).push(service);
        }
        else {
            passengerMap.set(service.passengerId, [service]);
        }
    });
    return passengerMap;
});
export var getBaggageTab = createSelector(getState, function (state) { return state.baggageTab; });
export var getPassenger = createSelector(function (state) { return state.passengers; }, function (_, passengerId) { return passengerId; }, function (passengers, passengerId) { return passengers.find(function (p) { return p.id === passengerId; }); });
