import { __assign, __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
import * as React from 'react';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { Form } from 'react-final-form';
import { useTheme } from '../../../theme';
import { FormStages, TravellerFields } from '../../types';
import { useTranslation } from 'react-i18next';
import Preferences from '../Preferences/Preferences';
import { TravellerTypes } from '../../../Traveller/types';
import { clearPhoneNumber } from '../../../PhoneInput/utils';
import { successLoadingIcon as successIcon } from '../../Icons';
import { DeleteIcon, Tooltip } from '../../../index';
import { validate } from '../../../Passenger/utils';
import { ButtonBase } from '@material-ui/core';
import { convertDocuments, docTypeMutator, documentsMutator, getInputType, citizenshipMutator, documentMutator, fixEmptyFieldDocumentMutator, fixEmptyFieldMutator } from '../../utils';
import DocumentsSection from '../DocumentsSection/DocumentsSection';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import PersonalInfoSection from '../PersonalInfoSection/PersonalInfoSection';
import ContactsSection from '../ContactsSection/ContactsSection';
import Button from '../../../BaseComponents/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { initialTravellerDocument } from '../../consts';
var SET_STAGE_TIMEOUT = 500;
var ERROR_TIMEOUT = 5000;
var TravellerForm = function (_a) {
    var traveller = _a.traveller, type = _a.type, showPreferences = _a.showPreferences, primaryCountryCodes = _a.primaryCountryCodes, onSubmit = _a.onSubmit, onDelete = _a.onDelete, onClose = _a.onClose;
    var css = useTheme('TravellerForm').TravellerForm;
    var tTraveller = useTranslation('Traveller').t;
    var tPassenger = useTranslation('Passenger').t;
    var _b = __read(useState(FormStages.Default), 2), stage = _b[0], setStage = _b[1];
    var _c = __read(useState(false), 2), openedErrorTooltip = _c[0], setOpenedErrorTooltip = _c[1];
    var fieldValuesObject = useMemo(function () {
        var e_1, _a;
        var _b;
        var values = {};
        try {
            for (var _c = __values(traveller.values), _d = _c.next(); !_d.done; _d = _c.next()) {
                var field = _d.value;
                values[field.name] = field.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if ((_b = traveller.documents) === null || _b === void 0 ? void 0 : _b.length) {
            values[TravellerFields.Documents] = __spreadArray([], __read(convertDocuments(traveller.documents)), false);
        }
        else {
            values[TravellerFields.Documents] = [initialTravellerDocument];
        }
        return values;
    }, [traveller]);
    var defaultFormValues = useMemo(function () {
        var _a;
        var res = {};
        traveller.values.forEach(function (v) { return (res[v.name] = v); });
        (_a = traveller.documents[0]) === null || _a === void 0 ? void 0 : _a.values.forEach(function (v) { return (res[v.name] = v); });
        return res;
    }, [traveller]);
    var onPreInnerSubmit = function (event, handleSubmit, form, hasValidationErrors) {
        if (!hasValidationErrors) {
            handleSubmit(event).then(function (res) {
                if (res) {
                    setStage(FormStages.Success);
                    if (type !== TravellerTypes.PersonalDetails) {
                        setTimeout(function () {
                            form.reset();
                            setStage(FormStages.Default);
                        }, SET_STAGE_TIMEOUT);
                    }
                    else {
                        setTimeout(function () {
                            setStage(FormStages.Default);
                        }, SET_STAGE_TIMEOUT);
                    }
                }
                else {
                    setStage(FormStages.Default);
                    setOpenedErrorTooltip(true);
                    setTimeout(function () {
                        setOpenedErrorTooltip(false);
                    }, ERROR_TIMEOUT);
                }
            });
        }
        else {
            handleSubmit(event);
        }
    };
    var onInnerSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStage(FormStages.Loading);
                    return [4 /*yield*/, onSubmit({
                            firstName: values.firstName,
                            lastName: values.lastName,
                            middleName: values.middleName,
                            citizenship: values.nationality,
                            birthDate: values.birthDate,
                            gender: values.gender,
                            email: values.email,
                            phone: values.phone && clearPhoneNumber(values.phone),
                            documents: values.documents.map(function (doc) { return ({
                                type: doc.docType,
                                number: doc.docNumber,
                                expiryDate: doc.docExpiryDate,
                                citizenship: doc.citizenship,
                                firstName: doc.firstName,
                                lastName: doc.lastName,
                                middleName: doc.middleName
                            }); })
                        })];
                case 1:
                    data = _a.sent();
                    if (data && onClose) {
                        setTimeout(onClose, 500);
                    }
                    return [2 /*return*/, data];
            }
        });
    }); };
    var validateForm = function (allValues) {
        var documents = [];
        function validateValues(values) {
            var e_2, _a;
            var _b;
            var valuesErrors = {};
            try {
                for (var _c = __values(Object.entries(values)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var docFieldItem = _d.value;
                    var fieldName = docFieldItem[0];
                    var inputType = getInputType(fieldName);
                    var inputValue = docFieldItem[1];
                    var validationRules = (_b = defaultFormValues[fieldName]) === null || _b === void 0 ? void 0 : _b.validationRules;
                    if (validationRules) {
                        var error = validate(inputValue, values, inputType, validationRules);
                        if (error) {
                            valuesErrors[fieldName] = tPassenger(error);
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return valuesErrors;
        }
        var commonFieldsErrors = validateValues(allValues);
        allValues.documents.forEach(function (doc) {
            var docErrors = validateValues(doc);
            documents.push(docErrors);
        });
        return __assign(__assign({}, commonFieldsErrors), { documents: documents });
    };
    var renderLoading = function (button) {
        return stage === FormStages.Loading ? (React.createElement("div", { className: css.loader },
            React.createElement(CircularProgress, { size: "100%", classes: { root: css.circularProgress } }))) : stage === FormStages.Success ? (React.createElement("div", { className: cn(css.loader, css.success) },
            React.createElement("div", { className: css.success__icon },
                successIcon,
                " "))) : (button);
    };
    return (React.createElement("div", { className: css.travellerForm },
        React.createElement(Form, { initialValues: fieldValuesObject, onSubmit: onInnerSubmit, validate: validateForm, mutators: {
                documentsMutator: documentsMutator,
                documentMutator: documentMutator,
                docTypeMutator: docTypeMutator,
                citizenshipMutator: citizenshipMutator,
                fixEmptyFieldMutator: fixEmptyFieldMutator,
                fixEmptyFieldDocumentMutator: fixEmptyFieldDocumentMutator
            }, render: function (_a) {
                var handleSubmit = _a.handleSubmit, form = _a.form, hasValidationErrors = _a.hasValidationErrors, values = _a.values;
                return (React.createElement("form", { onSubmit: function (event) { return onPreInnerSubmit(event, handleSubmit, form, hasValidationErrors); } },
                    React.createElement("div", { className: css.travellerForm__content },
                        React.createElement(SectionWrapper, { title: tTraveller('Documents') },
                            React.createElement(DocumentsSection, { documents: values.documents, traveller: traveller, primaryCountryCodes: primaryCountryCodes })),
                        React.createElement(SectionWrapper, { title: tTraveller('Personal info') },
                            React.createElement(PersonalInfoSection, null)),
                        React.createElement(SectionWrapper, { title: tTraveller('Contacts') },
                            React.createElement("p", { className: css.description }, tTraveller('With the help of this data, we will be able to inform the fellow traveler about changes in booking, flight status, check-in time, etc. Optional data.')),
                            React.createElement(ContactsSection, null)),
                        showPreferences && (React.createElement(Preferences, { Meal: ['Vegetarian menu', 'same another'], Seats: ['Window seat', 'Center seat'] })),
                        React.createElement("div", { className: cn(css.travellerForm__contentControls) },
                            type === TravellerTypes.CompanionFilled && (React.createElement(ButtonBase, { className: css.removeButton, onClick: onDelete },
                                DeleteIcon,
                                tTraveller('Remove passengers data'))),
                            React.createElement("div", { className: css.travellerForm__actions }, renderLoading(React.createElement(React.Fragment, null,
                                onClose && (React.createElement(Button, { variant: "smallSecondary", onClick: onClose }, tTraveller('Cancel'))),
                                React.createElement(Tooltip, { title: tTraveller('An error occurred while executing the request, please try again'), open: openedErrorTooltip },
                                    React.createElement(Button, { className: css.saveButton, variant: "smallPrimary", type: "submit" }, tTraveller('Save'))))))))));
            } })));
};
export default TravellerForm;
