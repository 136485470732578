import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import Seats from '../routes/Seats';
import { getIsSkipSeatSelectionAllowed, getSeatMap, getTravellers } from '../../../../store/order/selectors';
import { useServiceRequest } from '../../../../utils';
import { checkAllSeatsAreSelected, getSeatPrice } from '../../../../../../SeatMap/utils';
import { MOBILE_MIN_WIDTH } from '../../../../../../utils';
import Modal from '../../../../../../Modal';
import SuggestionModalContent from '../../../../../../SuggestionModalContent/SuggestionModalContent';
import { SeatIcon } from '../../../../../../SuggestionModalContent/icons';
import { SlideBottom } from '../../../../../../index';
import WarningModal from '../../../../../../WarningModal';
import SimpleLoader from '../../../../../../SimpleLoader';
var SeatsReducer = function (state, actionVar) {
    var _a;
    if (actionVar.type && actionVar.type === 'SET_STATE') {
        return __assign({}, actionVar.payload);
    }
    var action = actionVar;
    var seatId = "".concat(action.passengerId, "_").concat(action.segmentId);
    return __assign(__assign({}, state), (_a = {}, _a[seatId] = action.seats, _a));
};
var PreselectedSeats = function (props) {
    var _a;
    var travellers = useSelector(getTravellers);
    var seatMap = useSelector(getSeatMap);
    var isSkipSeatSelectionAllowed = useSelector(getIsSkipSeatSelectionAllowed);
    var _b = __read(useState(null), 2), checkinError = _b[0], setCheckinError = _b[1];
    var _c = __read(useState(false), 2), requiredSeatsModalIsVisible = _c[0], setRequiredSeatsModalIsVisible = _c[1];
    var _d = __read(React.useReducer(SeatsReducer, {}), 2), state = _d[0], dispatch = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var runServiceRequest = useServiceRequest().runServiceRequest;
    var recommendedSeatsPlaced = useRef(false);
    var t = useTranslation('Checkin').t;
    var closeRequiredSeatsModal = function () { return setRequiredSeatsModalIsVisible(false); };
    var closeWarningModal = function () { return setCheckinError(null); };
    React.useEffect(function () {
        if (!recommendedSeatsPlaced.current) {
            travellers.forEach(function (traveller) {
                var _a, _b;
                (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.forEach(function (seat) {
                    var _a;
                    var seatId = "".concat(traveller.id, "_").concat(seat.segment.id);
                    if (!state[seatId] &&
                        !((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.some(function (selected) { return selected.segment.id === seat.segment.id; }))) {
                        setSeatCallback([
                            {
                                number: seat.seat.number,
                                row: seat.seat.row,
                                price: seat.seat.price,
                                letter: seat.seat.letter,
                                service: null,
                                rfisc: null,
                                isAvailable: null,
                                isAisle: null,
                                isExistent: null,
                                isSmoking: null,
                                isRecline: null,
                                seatService: null,
                                seatServices: null,
                                services: [],
                                restrictions: null,
                                permissions: []
                            }
                        ], traveller.id, seat.segment.id);
                    }
                });
            });
            recommendedSeatsPlaced.current = true;
        }
    }, [travellers, recommendedSeatsPlaced, state]);
    React.useEffect(function () {
        var newState = {};
        travellers.forEach(function (traveller) {
            var _a, _b;
            (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(function (seat) {
                var _a;
                var key = "".concat(traveller.id, "_").concat((_a = seat.segment) === null || _a === void 0 ? void 0 : _a.id);
                newState[key] = __spreadArray(__spreadArray([], __read((newState[key] || [])), false), [seat], false);
            });
        });
        dispatch({
            type: 'SET_STATE',
            payload: newState
        });
    }, [travellers]);
    var setSeatCallback = function (_a, passengerId, segmentId) {
        var _b = __read(_a, 1), seat = _b[0];
        if (seat) {
            travellers.map(function (traveller) {
                var _a, _b;
                if (state && ((_b = (_a = state["".concat(traveller.id, "_").concat(segmentId)]) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.seat.number) === seat.number) {
                    dispatch({
                        passengerId: traveller.id,
                        segmentId: segmentId,
                        seats: []
                    });
                }
            });
            // WEBSKY-2988: for preselected seats in checkin
            setTimeout(function () {
                return dispatch({
                    seats: [
                        {
                            isConfirmed: false,
                            isChangeable: true,
                            seat: seat,
                            row: parseInt(seat.row),
                            letter: seat.letter,
                            segment: {
                                id: segmentId
                            },
                            product: null,
                            type: null
                        }
                    ],
                    passengerId: passengerId,
                    segmentId: segmentId
                });
            });
        }
        else {
            checkTravellerClearSeatMiddleware({
                passengerId: passengerId,
                segmentId: segmentId,
                seats: []
            }, dispatch);
        }
    };
    var setClearSeatCallback = function (seats, passengerId, segmentId) {
        checkTravellerClearSeatMiddleware({
            passengerId: passengerId,
            segmentId: segmentId,
            seats: []
        }, dispatch);
    };
    /*
     Если пользователь удаляет место, которое есть в заказе, то отправляем запрос на удаление,
     если нет, то добавляем во внутренний стейт
     */
    var checkTravellerClearSeatMiddleware = function (action, next) {
        var _a, _b, _c;
        var seats = action.seats, passengerId = action.passengerId, segmentId = action.segmentId;
        var seat = seats[0];
        var travellerSeat = (_c = (_b = (_a = travellers
            .find(function (traveller) { return traveller.id === passengerId; })) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.seats) === null || _c === void 0 ? void 0 : _c.find(function (seat) { return seat.segment.id === segmentId; });
        if (!seat && travellerSeat) {
            var travellersWithSeats_1 = travellers.map(function (traveller) {
                return {
                    passengerId: traveller.id,
                    seats: passengerId === traveller.id ? null : traveller.services.seats,
                    setServices: null
                };
            });
            onRequest(travellersWithSeats_1).then(function () {
                setIsLoading(false);
            });
        }
        else {
            next({
                seats: seats,
                passengerId: passengerId,
                segmentId: segmentId
            });
        }
    };
    var setOnClearSegmentCallback = function (segmentId) {
        travellers.map(function (traveller) {
            checkTravellerClearSeatMiddleware({
                passengerId: traveller.id,
                segmentId: segmentId.toString(),
                seats: []
            }, dispatch);
        });
    };
    var travellersWithSeats = React.useMemo(function () {
        return travellers.map(function (traveller) {
            var passengerSeats = [];
            seatMap.segments.forEach(function (segment) {
                if (!!state["".concat(traveller.id, "_").concat(segment.segmentsInfo.id)]) {
                    state["".concat(traveller.id, "_").concat(segment.segmentsInfo.id)].forEach(function (seat) {
                        passengerSeats.push(seat);
                    });
                }
            });
            return __assign(__assign({}, traveller), { services: __assign(__assign({}, traveller.services), { seats: passengerSeats }), needOthsSsr: null });
        });
    }, [travellers, state]);
    var passengers = React.useMemo(function () {
        return travellersWithSeats.map(function (traveller) {
            var _a, _b;
            var passenger = {
                id: traveller.id,
                values: traveller.values,
                type: traveller.type,
                seats: (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.map(function (seat) {
                    var _a, _b;
                    return {
                        id: (_b = (_a = seat.seat) === null || _a === void 0 ? void 0 : _a.seatService) === null || _b === void 0 ? void 0 : _b.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        deckId: '0',
                        letter: seat.letter,
                        row: seat.row.toString(),
                        number: seat.seat.number,
                        price: seat.seat.price,
                        segmentId: seat.segment.id,
                        rfisc: seat.seat.rfisc,
                        isConfirmed: seat.isConfirmed,
                        isChangeable: seat.isChangeable,
                        seatServices: seat.seat.seatServices
                    };
                })
            };
            return passenger;
        });
    }, [travellersWithSeats]);
    var onRequest = React.useCallback(function (passengers) { return __awaiter(void 0, void 0, void 0, function () {
        var order, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, runServiceRequest(passengers)];
                case 2:
                    order = _a.sent();
                    return [2 /*return*/, order];
                case 3:
                    e_1 = _a.sent();
                    setCheckinError(e_1);
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [runServiceRequest]);
    var onProceed = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var allRequiredSeatsAreSelected, passengers_1, isEverySeatServiceConfirmed, services_1, allRequiredSeatsAreSelected_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    allRequiredSeatsAreSelected = checkAllSeatsAreSelected(passengers, seatMap.segments.filter(function (segment) { return segment.availableSeatsCount > 0; }).length);
                    if (!(isSkipSeatSelectionAllowed || (allRequiredSeatsAreSelected && !requiredSeatsModalIsVisible))) return [3 /*break*/, 7];
                    setIsLoading(true);
                    passengers_1 = travellersWithSeats.map(function (travellerWithSeats) {
                        return {
                            passengerId: travellerWithSeats.id,
                            seats: travellerWithSeats.services.seats,
                            setServices: null
                        };
                    });
                    isEverySeatServiceConfirmed = passengers_1
                        .filter(function (passenger) {
                        return travellersWithSeats.find(function (traveller) { return traveller.id === passenger.passengerId; }).type !== 'INF';
                    })
                        .every(function (passenger) {
                        var _a, _b, _c;
                        return ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.every(function (seat) { return seat.isConfirmed; })) &&
                            ((_b = passenger.seats) === null || _b === void 0 ? void 0 : _b.length) >= ((_c = seatMap.segments) === null || _c === void 0 ? void 0 : _c.length);
                    });
                    if (!isEverySeatServiceConfirmed) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.onProceed()];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 6];
                case 2: return [4 /*yield*/, onRequest(passengers_1)];
                case 3:
                    services_1 = _a.sent();
                    if (!(services_1 && services_1.travellers)) return [3 /*break*/, 6];
                    allRequiredSeatsAreSelected_1 = services_1.travellers.every(function (traveller) {
                        var _a;
                        return (traveller.type === 'INF' ||
                            ((_a = traveller.services.seats) === null || _a === void 0 ? void 0 : _a.length) >=
                                services_1.seatMap.segments.filter(function (segment) { return segment.availableSeatsCount > 0; }).length);
                    });
                    if (!(allRequiredSeatsAreSelected_1 || isSkipSeatSelectionAllowed)) return [3 /*break*/, 5];
                    return [4 /*yield*/, props.onProceed()];
                case 4:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 6];
                case 5:
                    setRequiredSeatsModalIsVisible(true);
                    setIsLoading(false);
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    setRequiredSeatsModalIsVisible(true);
                    setIsLoading(false);
                    _a.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    }); }, [travellersWithSeats]);
    var seatsPriceToPay = React.useMemo(function () {
        var price = {
            amount: 0,
            currency: null
        };
        passengers.forEach(function (passenger) {
            passenger.seats.forEach(function (seat) {
                var _a;
                if ((seat === null || seat === void 0 ? void 0 : seat.isConfirmed) === false) {
                    price.amount += getSeatPrice(seat).amount;
                    if ((_a = seat.price) === null || _a === void 0 ? void 0 : _a.currency) {
                        price.currency = seat.price.currency;
                    }
                }
            });
        });
        return price;
    }, [passengers]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Seats, { onProceed: onProceed, passengers: passengers, travellers: travellersWithSeats, setSeatCallback: setSeatCallback, setClearSeatCallback: setClearSeatCallback, isLoading: isLoading, setIsLoading: setIsLoading, seatsPriceToPay: seatsPriceToPay, onSegmentClear: setOnClearSegmentCallback }),
        React.createElement(MediaQuery, { minWidth: +MOBILE_MIN_WIDTH + 1 },
            React.createElement(Modal, { maxWidth: "sm", open: requiredSeatsModalIsVisible, onClose: closeRequiredSeatsModal, scroll: "paper" },
                React.createElement(SuggestionModalContent, { text: t('To complete online check-in you must choose seats for all passengers on all flight directions'), icon: SeatIcon, agreeButtonText: 'OK', onAgree: closeRequiredSeatsModal }))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: requiredSeatsModalIsVisible, onClose: closeRequiredSeatsModal },
                React.createElement(SuggestionModalContent, { insideSlideBottom: true, icon: SeatIcon, text: t('To complete online check-in you must choose seats for all passengers on all flight directions'), agreeButtonText: 'OK', onAgree: closeRequiredSeatsModal }))),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An error occurred during the check-in process. Please try again later or contact a customer support service.'), errorMessage: (_a = checkinError === null || checkinError === void 0 ? void 0 : checkinError.message) !== null && _a !== void 0 ? _a : '', onClose: closeWarningModal, onButtonClick: closeWarningModal, buttonText: t('OK, thank you'), isOpen: !!checkinError }),
        isLoading && React.createElement(SimpleLoader, null)));
};
export default PreselectedSeats;
