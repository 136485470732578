var formatRelativeLocale = {
    lastWeek: 'eeee\'и гузашта соати\' p',
    yesterday: '\'дирӯз соати\' p',
    today: '\'имрӯз соати\' p',
    tomorrow: '\'фардо соати\' p',
    nextWeek: 'eeee\'и оянда соати\' p',
    other: 'P'
};
var formatRelative = function (token, _date, _baseDate, _options) { return formatRelativeLocale[token]; };
export default formatRelative;
