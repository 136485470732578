import * as React from 'react';
import cn from 'classnames';
import Card from '@material-ui/core/Card';
import { Time } from '../../../Icons';
import { ExareStatus } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var STATUS_TO_ICONS_MAP = new Map([
    [ExareStatus.NeedConfirm, Time],
    [ExareStatus.InProcess, Time],
    [ExareStatus.Finished, Time],
    [ExareStatus.Refused, Time],
    [ExareStatus.Rejected, Time],
    [ExareStatus.AwaitingPayment, Time],
    [ExareStatus.TimelimitExpired, Time]
]);
var WAITING_STATUSES = [ExareStatus.NeedConfirm, ExareStatus.AwaitingPayment, ExareStatus.InProcess];
var REJECTED_STATUSES = [
    ExareStatus.Rejected,
    ExareStatus.Refused,
    ExareStatus.TimelimitExpired,
    ExareStatus.TimelimitExpired
];
var PROCESSING_STATUSES = [ExareStatus.AwaitingPayment];
var Status = function (props) {
    var _a;
    var t = useTranslation('Exchange').t;
    var status = props.status;
    var css = useTheme('Refund').Status;
    return (React.createElement(Card, { className: cn(css.status, props.className, (_a = {},
            _a[css.waiting] = WAITING_STATUSES.includes(status),
            _a[css.processing] = PROCESSING_STATUSES.includes(status),
            _a[css.rejected] = REJECTED_STATUSES.includes(status),
            _a)), elevation: 0, square: true },
        React.createElement("i", { className: css.icon }, STATUS_TO_ICONS_MAP.get(status)),
        React.createElement("span", null, t(status))));
};
export default Status;
