export var FormStages;
(function (FormStages) {
    FormStages[FormStages["Default"] = 0] = "Default";
    FormStages[FormStages["Loading"] = 1] = "Loading";
    FormStages[FormStages["Success"] = 2] = "Success";
})(FormStages || (FormStages = {}));
export var TravellerFields;
(function (TravellerFields) {
    TravellerFields["BirthDate"] = "birthDate";
    TravellerFields["Gender"] = "gender";
    TravellerFields["Phone"] = "phone";
    TravellerFields["Email"] = "email";
    TravellerFields["Documents"] = "documents";
    // TODO: remove later, needed for back compatibility
    TravellerFields["Nationality"] = "nationality";
    TravellerFields["FirstName"] = "firstName";
    TravellerFields["LastName"] = "lastName";
    TravellerFields["MiddleName"] = "middleName";
})(TravellerFields || (TravellerFields = {}));
export var TravellerDocumentFields;
(function (TravellerDocumentFields) {
    TravellerDocumentFields["FirstName"] = "firstName";
    TravellerDocumentFields["LastName"] = "lastName";
    TravellerDocumentFields["MiddleName"] = "middleName";
    TravellerDocumentFields["DocNumber"] = "docNumber";
    TravellerDocumentFields["DocExpiryDate"] = "docExpiryDate";
    TravellerDocumentFields["DocType"] = "docType";
    TravellerDocumentFields["Nationality"] = "nationality";
    TravellerDocumentFields["Citizenship"] = "citizenship";
})(TravellerDocumentFields || (TravellerDocumentFields = {}));
