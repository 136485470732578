import { __read } from "tslib";
import * as React from 'react';
import RequestFormComponent from '../RequestForm/RequestForm';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from '../../../store/order/selectors';
import { useRefuseExchangeMutation } from '@websky/graphql';
import { setLastExchange } from '../../../store/order/actions';
var RequestForm = function (_a) {
    var goToNewRequest = _a.goToNewRequest, goToPayment = _a.goToPayment, goToOrder = _a.goToOrder;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var _b = __read(useRefuseExchangeMutation(), 1), deleteMutation = _b[0];
    var deleteRequest = function (order, redirectToOrder) {
        return deleteMutation({
            variables: {
                id: order.id
            }
        }).then(function (data) {
            var _a;
            if ((_a = data.data) === null || _a === void 0 ? void 0 : _a.RefuseExare) {
                dispatch(setLastExchange(data.data.RefuseExare));
                if (redirectToOrder) {
                    goToOrder();
                }
                return true;
            }
            return false;
        });
    };
    return (React.createElement(RequestFormComponent, { order: order.exareInfo.lastExchange, deleteRequest: deleteRequest, goToNewExchange: goToNewRequest, goToPayment: goToPayment }));
};
export default RequestForm;
