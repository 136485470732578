import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Log } from '../../sentry';
import { isResultsStateGuard, isCheckoutStateGuard } from '../../typeguards';
var fareGroupCompareTableVariablesFactory = function (state, orderId) {
    var _a, _b, _c, _d;
    var variables = {};
    if (!isResultsStateGuard(state) && !isCheckoutStateGuard(state)) {
        Log({
            message: 'CompareFares: undefined state',
            data: state,
            level: 'error'
        });
        return variables;
    }
    if (isCheckoutStateGuard(state) || orderId) {
        variables.flightId = null;
        variables.orderId = orderId !== null && orderId !== void 0 ? orderId : state.order.id;
        variables.iata = null;
    }
    else if (isResultsStateGuard(state)) {
        variables.orderId = null;
        if (state.legInView) {
            var fareGroupWithFlightId = (_a = state.legInView.pricesForFareGroups) === null || _a === void 0 ? void 0 : _a.find(function (priceForFareGroup) { var _a, _b, _c; return (_c = (_b = (_a = priceForFareGroup === null || priceForFareGroup === void 0 ? void 0 : priceForFareGroup.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight) === null || _c === void 0 ? void 0 : _c.id; });
            variables.flightId = (_d = (_c = (_b = fareGroupWithFlightId === null || fareGroupWithFlightId === void 0 ? void 0 : fareGroupWithFlightId.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.flight) === null || _d === void 0 ? void 0 : _d.id;
            variables.iata = null;
        }
        else if (state.selectedFares.length) {
            variables.flightId = state.selectedFares[0].flightInfo.id;
            variables.iata = null;
        }
    }
    return variables;
};
export var useCompareFaresModel = function (orderId) {
    var state = useSelector(function (state) { return state; });
    var compareTableVariables = useMemo(function () { return fareGroupCompareTableVariablesFactory(state, orderId); }, [
        state,
        orderId
    ]);
    return {
        compareTableVariables: compareTableVariables
    };
};
