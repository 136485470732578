import * as React from 'react';
import { useRef } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../../theme';
var wcIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.20003 6C8.26563 6 9.12003 5.11 9.12003 4C9.12003 2.89 8.26563 2 7.20003 2C6.13443 2 5.28003 2.89 5.28003 4C5.28003 5.11 6.13443 6 7.20003 6ZM5.28003 14.5V22H9.12003V14.5H10.56V9C10.56 7.9 9.69603 7 8.64003 7H5.76003C4.70403 7 3.84003 7.9 3.84003 9V14.5H5.28003ZM17.28 16V22H14.4V16H11.52L13.9584 8.37C14.2176 7.55 14.9568 7 15.7824 7H15.8976C16.7232 7 17.4528 7.55 17.7216 8.37L20.16 16H17.28ZM17.76 4C17.76 5.11 16.9056 6 15.84 6C14.7744 6 13.92 5.11 13.92 4C13.92 2.89 14.7744 2 15.84 2C16.9056 2 17.76 2.89 17.76 4Z", fill: "currentColor" })));
var TechnicalFacilities = React.memo(function (_a) {
    var part = _a.part, isSmall = _a.isSmall;
    var theme = useTheme('SeatMap').SeatMapStyles;
    var leftSideRef = useRef();
    var rightSideRef = useRef();
    var iconRef = useRef();
    React.useEffect(function () {
        if (leftSideRef.current && rightSideRef.current && iconRef.current) {
            var leftSideRect = leftSideRef.current.getBoundingClientRect();
            var rightSideRect = rightSideRef.current.getBoundingClientRect();
            var icon = iconRef.current;
            icon.style.left = "".concat((rightSideRect.right - leftSideRect.left) / 2 - icon.getBoundingClientRect().width / 2, "px");
        }
    }, []);
    var hasExistentFrontOfToiletSeats = part.seats.some(function (seat) { return seat.isFrontOfToilet && seat.isExistent; });
    if (!hasExistentFrontOfToiletSeats) {
        return null;
    }
    return (React.createElement("div", { className: theme.row__toilet }, part.seats.map(function (seat, index) {
        var _a;
        var _b, _c;
        if (!seat.isExistent) {
            return null;
        }
        var isLeftSide = seat.isFrontOfToilet && !((_b = part.seats[index - 1]) === null || _b === void 0 ? void 0 : _b.isFrontOfToilet);
        var isRightSide = seat.isFrontOfToilet && !((_c = part.seats[index + 1]) === null || _c === void 0 ? void 0 : _c.isFrontOfToilet);
        return (React.createElement("div", { ref: isLeftSide ? leftSideRef : isRightSide ? rightSideRef : null, key: seat.number, className: cn(theme.row__toiletPart, (_a = {},
                _a[theme.row__toiletPart_small] = isSmall,
                _a[theme.row__toiletPart_leftSide] = isLeftSide,
                _a[theme.row__toiletPart_innerSide] = seat.isFrontOfToilet,
                _a[theme.row__toiletPart_rightSide] = isRightSide,
                _a)) }, isLeftSide && (React.createElement("div", { ref: iconRef, className: theme.row__toiletPart_icon }, wcIcon))));
    })));
});
export default TechnicalFacilities;
