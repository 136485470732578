import { __read, __values } from "tslib";
export var initialStateFactory = function (service, state) {
    var e_1, _a, e_2, _b;
    var singleServiceState = {};
    try {
        for (var _c = __values(Object.keys(state)), _d = _c.next(); !_d.done; _d = _c.next()) {
            var _e = __read(_d.value, 1), passengerId = _e[0];
            singleServiceState[passengerId] = {};
            try {
                for (var _f = (e_2 = void 0, __values(Object.keys(state[passengerId]))), _g = _f.next(); !_g.done; _g = _f.next()) {
                    var _h = __read(_g.value, 1), segmentId = _h[0];
                    singleServiceState[passengerId][segmentId] = state[passengerId][segmentId].find(function (stateService) { return stateService.service.id === service.id; });
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return singleServiceState;
};
