import { __read, __spreadArray } from "tslib";
import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import sagaMiddlewareFactory from 'redux-saga';
import { enableBatching } from 'redux-batched-actions';
import { rootReducer } from './reducers';
import memes from '../../../memes';
import { rootSaga } from './sagas';
export var createStore = memes(function () {
    var saga = sagaMiddlewareFactory();
    var middleware = [saga];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    var store = originalCreateStore(enableBatching(rootReducer), applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false)));
    saga.run(rootSaga);
    return store;
});
