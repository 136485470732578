import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { getTotalPrice } from '../utils';
import { getVipServiceStateKeys } from './utils';
var serviceReducer = function (state, action) {
    var _a, _b;
    return __assign(__assign({}, state), (_a = {}, _a[action.segmentId] = __assign(__assign({}, state[action.segmentId]), (_b = {}, _b[action.passengerId] = action.count, _b)), _a));
};
export var useVipServiceState = function (passengers, services, selectedServices) {
    var initialState = useMemo(function () {
        var state = {};
        passengers.forEach(function (passenger) {
            services.forEach(function (service) {
                if (selectedServices[passenger.id] && selectedServices[passenger.id][service.id]) {
                    selectedServices[passenger.id][service.id].forEach(function (service) {
                        service.segments.forEach(function (segment) {
                            var _a, _b;
                            state = __assign(__assign({}, state), (_a = {}, _a[segment] = __assign(__assign({}, state[segment]), (_b = {}, _b[passenger.id] = service.number, _b)), _a));
                        });
                    });
                }
            });
        });
        return state;
    }, [passengers, services, selectedServices]);
    var _a = __read(React.useReducer(serviceReducer, initialState), 2), state = _a[0], dispatch = _a[1];
    var minPrice = useMemo(function () {
        if (services.length) {
            var min_1 = services[0].price;
            services.forEach(function (service) {
                if (service.price.amount < min_1.amount) {
                    min_1 = service.price;
                }
            });
            return min_1;
        }
        return null;
    }, [services]);
    var totalPrice = useMemo(function () {
        var _a;
        if (Object.keys(state).length) {
            var servicePrices = [];
            var _loop_1 = function (segmentId) {
                var _loop_2 = function (passengerId) {
                    var count = state[segmentId][passengerId];
                    if (count > 0) {
                        var serviceByPassengerId = services.find(function (service) {
                            return service.allowedSegments.find(function (ids) { return ids.includes(segmentId); }) &&
                                service.allowedPassengers.find(function (ids) { return ids.includes(passengerId); });
                        });
                        var servicePrice = (_a = serviceByPassengerId === null || serviceByPassengerId === void 0 ? void 0 : serviceByPassengerId.price) !== null && _a !== void 0 ? _a : services[0].price;
                        servicePrices = __spreadArray(__spreadArray([], __read(servicePrices), false), [__assign(__assign({}, servicePrice), { amount: servicePrice.amount * count })], false);
                    }
                };
                for (var passengerId in state[segmentId]) {
                    _loop_2(passengerId);
                }
            };
            for (var segmentId in state) {
                _loop_1(segmentId);
            }
            var totalPrice_1 = getTotalPrice(servicePrices, function (price) { return price; });
            if (totalPrice_1.amount > 0) {
                return totalPrice_1;
            }
            return null;
        }
        return null;
    }, [state, services]);
    var isDifferentState = React.useMemo(function () {
        var initialStateKeys = getVipServiceStateKeys(initialState);
        var stateKeys = getVipServiceStateKeys(state);
        return JSON.stringify(__spreadArray([], __read(initialStateKeys), false)) !== JSON.stringify(__spreadArray([], __read(stateKeys), false));
    }, [initialState, state]);
    return {
        state: state,
        minPrice: minPrice,
        totalPrice: totalPrice,
        isDifferentState: isDifferentState,
        dispatch: dispatch
    };
};
