import * as React from 'react';
import { useCallback } from 'react';
import { Choosed } from '../../../../index';
import { useTheme } from '../../../../theme';
import ModalConfirm from '../../../../ModalConfirm/components/ModalConfirm';
var RefundInfoModal = function (props) {
    var open = props.open, onAgree = props.onAgree, onDisagree = props.onDisagree, renderAgreeText = props.renderAgreeText, renderDisagreeText = props.renderDisagreeText, renderText = props.renderText;
    var css = useTheme('Refund').RefundInfoModal;
    var renderContent = useCallback(function () { return (React.createElement("div", { className: css.content },
        React.createElement("span", { className: css.icon }, Choosed),
        React.createElement("div", { className: css.text }, renderText()))); }, []);
    return (React.createElement(ModalConfirm, { isOpen: open, size: "small", confirmBtn: renderAgreeText(), onConfirm: onAgree, cancelBtn: onDisagree && renderDisagreeText && renderDisagreeText(), onCancel: onDisagree, onClose: onDisagree }, renderContent()));
};
export default RefundInfoModal;
