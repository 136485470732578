import { __assign, __extends, __read, __spreadArray } from "tslib";
import { getBaggageDatalayerName, getServiceCategory } from '../utils';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import WebskyServicesAnalytics from '../WebskyServicesAnalytics';
import { isFareLockService } from '../../FareLock/utils';
import Base from './Base';
import { getHoldDatalayerItem } from './utils';
var Gds = /** @class */ (function (_super) {
    __extends(Gds, _super);
    function Gds(orderBeforeChange, orderAfterChange) {
        var _this = _super.call(this, orderBeforeChange, orderAfterChange) || this;
        _this.convertGdsServicesToDataLayerServices = function (services, additionalServices, traveller, travellerIndex) {
            var converted = [];
            services.forEach(function (service) {
                var _a, _b, _c, _d, _e;
                var id = service.serviceId;
                var additionalService = (_b = (_a = additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s.id === id; });
                switch (additionalService.type) {
                    case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                        var baggageAdditionalService_1 = additionalService;
                        var baggageService_1 = service;
                        converted.push.apply(converted, __spreadArray([], __read((_c = baggageService_1.segmentIds) === null || _c === void 0 ? void 0 : _c.map(function (segmentId) {
                            var _a, _b, _c;
                            return ({
                                name: getBaggageDatalayerName(baggageAdditionalService_1),
                                price: baggageAdditionalService_1.price.amount,
                                category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Baggage, baggageAdditionalService_1.baggageType),
                                segmentId: segmentId,
                                count: baggageService_1.count,
                                isRecommend: (_c = (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) {
                                    return service.serviceId === baggageService_1.serviceId;
                                }),
                                travellersTypes: [traveller.type]
                            });
                        })), false));
                        break;
                    case OrderAdditionalServiceGdsServiceServiceType.Meal:
                        var mealAdditionalService_1 = additionalService;
                        var mealService = service;
                        converted.push.apply(converted, __spreadArray([], __read((_d = mealService.segmentIds) === null || _d === void 0 ? void 0 : _d.map(function (segmentId) {
                            var _a, _b, _c;
                            return ({
                                category: getServiceCategory(OrderAdditionalServiceGdsServiceServiceType.Meal),
                                name: mealAdditionalService_1.name,
                                price: mealAdditionalService_1.price.amount,
                                segmentId: segmentId,
                                isRecommend: (_c = (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) {
                                    return service.serviceId === mealAdditionalService_1.id;
                                }),
                                count: 1,
                                travellersTypes: [traveller.type]
                            });
                        })), false));
                        break;
                    case OrderAdditionalServiceGdsServiceServiceType.Vip:
                    case OrderAdditionalServiceGdsServiceServiceType.BusinessLounge:
                        var vipAdditionalService_1 = additionalService;
                        if (isFareLockService(vipAdditionalService_1)) {
                            if (travellerIndex === 0) {
                                converted.push(__assign({}, getHoldDatalayerItem(vipAdditionalService_1, [traveller])));
                            }
                        }
                        else {
                            (_e = service.segmentIds) === null || _e === void 0 ? void 0 : _e.forEach(function (segmentId) {
                                converted.push(__assign(__assign({}, WebskyServicesAnalytics.selectVip(vipAdditionalService_1, traveller.id, segmentId)), { travellersTypes: [traveller.type] }));
                            });
                        }
                        break;
                }
            });
            return converted;
        };
        return _this;
    }
    Gds.prototype.diff = function () {
        var _this = this;
        var added = [];
        var removed = [];
        this.orderAfterChange.travellers.forEach(function (travellerAfterChange, index) {
            var _a, _b;
            var travellerBeforeChange = _this.orderBeforeChange.travellers[index];
            var _c = _this.getTravellersServicesDiff((_a = travellerBeforeChange.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services, (_b = travellerAfterChange.services.gdsServices) === null || _b === void 0 ? void 0 : _b.services, function (service) { return service.serviceId; }), gdsAdded = _c.added, gdsRemoved = _c.removed;
            added.push.apply(added, __spreadArray([], __read(_this.convertGdsServicesToDataLayerServices(gdsAdded, _this.orderAfterChange.additionalServices, travellerAfterChange, index)), false));
            removed.push.apply(removed, __spreadArray([], __read(_this.convertGdsServicesToDataLayerServices(gdsRemoved, _this.orderBeforeChange.additionalServices, travellerBeforeChange, index)), false));
        });
        return { added: added, removed: removed };
    };
    return Gds;
}(Base));
export default Gds;
