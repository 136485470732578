import * as React from 'react';
import { useSelector } from 'react-redux';
import Money from '../../Money';
import { useTheme } from '../../theme';
import Button from '../../Button/Button';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import InsuranceOptions from './InsuranceOptions/InsuranceOptions';
import { useTranslation } from 'react-i18next';
import { getCheckinOrder } from '../../Modules/Checkin/store/order/selectors';
import { ModeContext } from '../../context';
import { Mode } from '../../types';
import { getOrder } from '../../Checkout/store/order/selectors';
import { analytics_addInsurance, analytics_removeInsurance } from '../../Insurance/utils';
var InsuranceCard = function (props) {
    var _a, _b;
    var theme = useTheme('Insurance').InsuranceCardStyles;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var mode = React.useContext(ModeContext);
    var order = mode === Mode.Booking ? useSelector(getOrder) : useSelector(getCheckinOrder);
    var onClick = function () {
        if (props.selected) {
            analytics_removeInsurance(props.insurance, order.travellers);
        }
        else {
            analytics_addInsurance(props.insurance, order.travellers);
        }
        props.dispatcher({
            type: props.selected ? 'remove' : 'add',
            payload: props.insurance
        });
    };
    var t = useTranslation('AdditionalServiceCard').t;
    return (React.createElement("div", { className: cn(theme.insurance, (_a = {}, _a[theme.selected] = props.selected, _a)) },
        React.createElement("div", { className: theme.insurance__header },
            props.insurance.name,
            !isMobile && React.createElement(Money, { money: props.insurance.totalPrice, moneyClassName: theme.insurance__money })),
        React.createElement("div", { className: theme.insurance__content },
            React.createElement(InsuranceOptions, { insurance: props.insurance }),
            React.createElement(Button, { variant: "outline", className: cn(theme.insurance__button, (_b = {},
                    _b[theme.insurance__button_selected] = props.selected,
                    _b)), onClick: onClick },
                t(props.selected ? 'Selected' : 'Select'),
                isMobile && React.createElement(Money, { money: props.insurance.totalPrice, moneyClassName: theme.insurance__money })))));
};
export default InsuranceCard;
