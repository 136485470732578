import { __read } from "tslib";
import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import * as Icons from '../../../Icons';
import { useTranslation } from 'react-i18next';
import TabButton from '../../../Button/components/TabButton/TabButton';
import cn from 'classnames';
import { useRouteMatch, generatePath, useLocation } from 'react-router-dom';
import { matchPath, Route, Switch, useHistory } from 'react-router-dom';
import Loader from '../../../Loader';
import MediaQuery from '../../../MediaQuery/MediaQuery';
import { Badge } from '../../../Badge/Badge';
export var NotificationsPage = function (props) {
    var _a = useTheme('Communications'), css = _a.Notifications, baseCss = _a.Communication;
    var t = useTranslation('Communications').t;
    var _b = useRouteMatch(), path = _b.path, params = _b.params;
    var history = useHistory();
    var location = useLocation();
    var _c = __read(useState(), 2), activeTab = _c[0], setActiveTab = _c[1];
    var tabRefs = React.useRef({});
    useEffect(function () {
        if ((params === null || params === void 0 ? void 0 : params.categoryId) !== activeTab) {
            var category = props.categories.find(function (category) { return category.path === params.categoryId; });
            if (category && activeTab !== category.id) {
                setActiveTab(category.id);
                if (tabRefs.current) {
                    tabRefs.current[category.id].scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center',
                        block: 'nearest'
                    });
                }
            }
        }
    }, [params, props.categories]);
    useEffect(function () {
        var scrollToTabEl = function () {
            if (activeTab && tabRefs.current) {
                tabRefs.current[activeTab].scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                    block: 'nearest'
                });
            }
        };
        screen.orientation.addEventListener('change', scrollToTabEl);
        return function () { return screen.orientation.removeEventListener('change', scrollToTabEl); };
    }, [activeTab, tabRefs.current]);
    var onBack = function () {
        var newPath = location.pathname.split('/').slice(0, -1).join('/');
        history.push(newPath);
    };
    var onClickTab = function (id) {
        history.push(generatePath(path, { categoryId: id }));
    };
    var hasUnreadMessageInCategory = useMemo(function () {
        var category = props.categories.find(function (category) { return category.id === activeTab; });
        return !!(category && category.unreadMessagesCount);
    }, [props.categories, activeTab]);
    return (React.createElement("div", null,
        React.createElement("div", { className: css.notifications__head },
            React.createElement("div", { className: css.notifications__top },
                React.createElement(MediaQuery, { maxWidth: 'mobile' },
                    React.createElement(Button, { variant: "text", onClick: onBack, className: cn(baseCss.btn, baseCss.btn_back) }, Icons.ArrowBack)),
                React.createElement("h1", { className: baseCss.title }, t('Notifications'))),
            React.createElement("div", { className: css.notifications__sub },
                React.createElement(MediaQuery, { minWidth: 'mobile' },
                    React.createElement(Button, { variant: "text", onClick: onBack, className: cn(baseCss.btn, baseCss.btn_back) },
                        Icons.ArrowBack,
                        t('Back'))),
                hasUnreadMessageInCategory && matchPath(location.pathname, { path: path, exact: true }) && (React.createElement(Button, { variant: "text", className: cn(baseCss.btn, baseCss.btn_read), onClick: function () { return props.markAllAsRead(activeTab); } },
                    props.loadingRead ? (React.createElement(Loader, { className: baseCss.btn__loader, spinnerClassName: baseCss.btn__loaderSpinner, hideText: true })) : (Icons.Choosed),
                    t('Mark all as read')))),
            React.createElement("div", { className: css.notifications__tabs }, props.categories.map(function (tab) { return (React.createElement("div", { key: tab.id, ref: function (el) { return (tabRefs.current[tab.id] = el); } },
                React.createElement(TabButton, { className: css.notifications__tabsItem, onClick: function () { return onClickTab(tab.path); }, inverted: true, active: activeTab === tab.id },
                    React.createElement("span", null, t(tab.title)),
                    tab.unreadMessagesCount > 0 && (React.createElement(Badge, { value: tab.unreadMessagesCount, active: activeTab === tab.id }))))); }))),
        React.createElement(Switch, null,
            props.categories.map(function (item) { return (React.createElement(Route, { exact: true, key: item.id, path: generatePath(path, { categoryId: item.path }) }, item.listComponent)); }),
            props.categories.map(function (item) { return (React.createElement(Route, { exact: true, key: "page-detail-".concat(item.id), path: "".concat(generatePath(path, { categoryId: item.path }), "/:detailId") }, item.detailComponent)); }))));
};
