import React from 'react';
import { useTheme } from '../../../../theme';
import { NotificationCard } from '../../NotificationCard/NotificationCard';
import { format } from '../../../../utils';
import { NOTIFICATION_DATE_FORMAT } from '../../../constants';
import { fromUTCToLocalTimeZone } from '../../../utils';
export var PromotionCard = function (props) {
    var css = useTheme('Communications').PromotionCard;
    var date = fromUTCToLocalTimeZone(props.date);
    return (React.createElement(NotificationCard, { isRead: props.isRead, title: props.title, date: format(date, NOTIFICATION_DATE_FORMAT), markAsRead: props.markAsRead },
        React.createElement("div", { className: css.promotion__body },
            React.createElement("p", null, props.desc),
            props.preview && React.createElement("img", { src: props.preview, alt: "", className: css.promotion__img }))));
};
