import { __awaiter, __generator, __read } from "tslib";
import { useCheckinSaveOrderServicesMutation } from '@websky/graphql';
import * as React from 'react';
import { UpgradeFlightClass } from '../../../../../../UpgradeFlightClass';
import { CheckinStep, useSteps } from '../../../../utils';
import PassengersHeader from '../Passengers/PassengersHeader/PassengersHeader';
import { Log } from '../../../../../../sentry';
import { useTheme } from '../../../../../../theme';
import { PAYMENT_BACK_REDIRECT_PARAM } from '../../constants';
export var Upgrade = function () {
    var _a = useSteps(), goToNextStep = _a.goToNextStep, setStep = _a.setStep;
    var css = useTheme('Checkin').Checkin;
    var _b = __read(useCheckinSaveOrderServicesMutation(), 2), saveServices = _b[0], loading = _b[1].loading;
    var handleSave = function (variables) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveServices({
                            variables: {
                                params: variables.params
                            }
                        })];
                case 1:
                    _a.sent();
                    setStep(CheckinStep.Payment, new URLSearchParams([[PAYMENT_BACK_REDIRECT_PARAM, CheckinStep.Baggage]]).toString());
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    Log({
                        level: 'error',
                        message: 'error while saving upgrade service'
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(PassengersHeader, null),
        React.createElement(UpgradeFlightClass, { contentClassName: css.upgrade, loading: loading, onSaveServices: handleSave, onContinueWithoutSelection: function () { return goToNextStep(CheckinStep.Upgrade); } })));
};
