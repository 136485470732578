import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useRequestFfpMilesForOrderMutation } from '@websky/graphql';
import { useTheme } from '../../../theme';
import { initI18n } from '../../../utils';
import Button from '../../../Button';
import { addDays, format, isAfter, subMonths } from 'date-fns';
import Input from '../../../Input';
import { isTicketNumber } from '../../../RetrieveBooking/hooks';
import { Datepicker } from '../../../Datepicker';
import { ThemeProvider } from '../../../theme';
initI18n('MilesRecovery');
var MilesRecoveryContent = function (_a) {
    var className = _a.className, onClose = _a.onClose;
    var theme = useTheme('MilesRecovery').MilesRecoveryContentStyles;
    var t = useTranslation('MilesRecovery').t;
    var _b = __read(useRequestFfpMilesForOrderMutation(), 1), requestFfpMiles = _b[0];
    var _c = __read(React.useState(''), 2), success = _c[0], setSuccessMessage = _c[1];
    var _d = __read(React.useState(''), 2), error = _d[0], setError = _d[1];
    var currentDate = new Date();
    var conditions = ['OnlyCompletedFlights', 'MoreThanSixMonthsFlights'];
    var validate = function (values) {
        var errors = {};
        if (!values.ticketNumber) {
            errors.ticketNumber = t('Required');
        }
        else if (!isTicketNumber(values.ticketNumber)) {
            errors.ticketNumber = t('Enter your ticket number');
        }
        if (!values.flightNumber) {
            errors.flightNumber = t('Required');
        }
        if (!values.flightDate) {
            errors.flightDate = t('Pick a flight date');
        }
        else if (isAfter(values.flightDate, currentDate)) {
            errors.flightDate = t('Invalid date');
        }
        return errors;
    };
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var request, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestFfpMiles({
                            variables: {
                                flightNumber: values.flightNumber,
                                flightDate: format(values.flightDate, 'dd.MM.yyyy'),
                                ticketNumber: values.ticketNumber
                            }
                        })];
                case 1:
                    request = _d.sent();
                    if ((_c = (_b = request === null || request === void 0 ? void 0 : request.data) === null || _b === void 0 ? void 0 : _b.RequestFfpMilesForOrder) === null || _c === void 0 ? void 0 : _c.result) {
                        setSuccessMessage(request.data.RequestFfpMilesForOrder.message);
                        setTimeout(onClose, 1500);
                    }
                    else {
                        setError(t('Order not found'));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    _a = _d.sent();
                    setError(t('Order not found'));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var datePickerStyles = React.useMemo(function () { return ({
        Datepicker: {
            MonthsWrapperStyles: {
                wrapper: theme.month_wrapper,
                monthHeader: theme.monthHeader,
                nav_prev: theme.month_nav_prev,
                nav_next: theme.month_nav_next
            },
            MonthHeaderStyles: {
                monthHeader__select: theme.monthHeader__select
            },
            WeeklyHeaderStyles: {
                day: theme.weeklyHeader_dayOfWeekName
            },
            DayStyles: {
                day: theme.datePicker_day,
                day_notAvailable: theme.datePicker_day_notAvailable,
                day_selected: theme.datePicker_day_selected
            }
        }
    }); }, []);
    var textFieldPropsFactory = function (field) { return (__assign(__assign({}, field.input), { onChange: field.input.onChange, error: field.meta.submitFailed && !!field.meta.error, helperText: field.meta.submitFailed && field.meta.error })); };
    return (React.createElement("div", { className: cn(theme.wrapper, className) },
        React.createElement("div", { className: theme.header }, t('Miles recovery')),
        success && React.createElement("div", { className: theme.success }, success),
        !success && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.about }, t('Please note that miles recovery is possible')), conditions === null || conditions === void 0 ? void 0 :
            conditions.map(function (condition, index) { return (React.createElement("li", { className: theme.about__item, key: index }, t(condition))); }),
            React.createElement(Form, { onSubmit: onSubmit, validate: validate, render: function (_a) {
                    var handleSubmit = _a.handleSubmit, submitting = _a.submitting, errors = _a.errors, submitFailed = _a.submitFailed;
                    return (React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement("div", { className: theme.form },
                            React.createElement(ThemeProvider, { value: datePickerStyles },
                                React.createElement(Field, { type: "text", name: "flightDate", render: function (field) { return (React.createElement(Datepicker, { openDate: currentDate, selectedDates: [field.input.value], minDate: subMonths(currentDate, 6), maxDate: addDays(currentDate, 1), onSelect: function (date) { return field.input.onChange(date); } })); } })),
                            React.createElement("div", { className: theme.fields },
                                React.createElement(Field, { type: "text", name: "ticketNumber", render: function (field) { return (React.createElement(Input, __assign({}, field.input, { name: field.input.name, label: t('Ticket number'), absoluteHintPosition: true, TextFieldProps: __assign(__assign({}, textFieldPropsFactory(field)), { onChange: function (event) {
                                                setError(null);
                                                field.input.onChange(event.target.value);
                                            }, error: !!error ||
                                                !!(field.meta.submitFailed && field.meta.error), helperText: error || (field.meta.submitFailed && field.meta.error) }) }))); } }),
                                React.createElement(Field, { type: "number", name: "flightNumber", absoluteHintPosition: true, render: function (field) { return (React.createElement(Input, __assign({}, field.input, { inputClassName: theme.input_number, name: field.input.name, label: t('Flight number'), TextFieldProps: __assign({}, textFieldPropsFactory(field)) }))); } }),
                                React.createElement("div", { className: theme.error }, submitFailed && errors.flightDate),
                                React.createElement("div", { className: theme.buttons },
                                    React.createElement(Button, { className: theme.buttonSubmit, isLoading: submitting, type: "submit" }, t('Create')))))));
                } })))));
};
export default MilesRecoveryContent;
