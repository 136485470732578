export var PASSENGER_FIRST_NAME = 'Alex';
export var PASSENGER_LAST_NAME = 'Brown';
export var PASSENGER_PASSPORT_NUMBER = '0123456789';
export var PASSENGER_RESERVATION_NUMBER = '2T7L5M';
export var PASSENGER_TICKET_NUMBER = '0123 4567 8901 2345';
export var PASSENGER_DATE_OF_SALE = '28 Sep. 2023';
export var PASSENGER_LOYALTY_CARD_NUMBER = '0123 4567 8901 2345';
export var AGENT_NAME = 'TKP';
export var AGENT_URL = 'www.airlines-site.com';
export var AGENT_PHONE_NUMBER = '+7 800 567 00 80';
export var AGENT_FORM_OF_PAYMENT = 'CCVIXXXXXXXXXXXX5574';
