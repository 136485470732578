import { __read, __rest } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { fastSearchParse, isFastSearchCorrect } from './utils';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import { useConfig } from '../../../context';
import LoyaltyConnect from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { getCurrency } from '../../../cache';
import { getUserValue } from '../../../utils';
import { TravellerFieldEnum, useGetAccountInfoQuery } from '@websky/graphql';
import { useConfigRedirects } from '../../../hooks';
var FastSearch = function (_a) {
    var fastSearch = _a.fastSearch, startSearch = _a.startSearch, redirect = _a.redirect;
    var _b = __read(useState(true), 2), isValid = _b[0], setIsValid = _b[1];
    var _c = __read(useState(false), 2), authorizationFfp = _c[0], setAuthorizationFfp = _c[1];
    var config = useConfig();
    var payByMiles = config.SearchForm.payByMiles;
    var allowPromoCodes = config.global.allowPromoCodes;
    var passengersConfig = config.SearchForm.passengersConfig;
    var onGoToAccountSettings = useConfigRedirects().onGoToAccountSettings;
    var _d = useGetAccountInfoQuery({ skip: !payByMiles }), currentUser = _d.data, loading = _d.loading;
    var userInfo = useMemo(function () {
        var _a;
        if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) || !((_a = currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userProfile)) {
            return {
                lastName: '',
                firstName: '',
                email: ''
            };
        }
        return {
            lastName: getUserValue(currentUser.CurrentUser.userProfile, TravellerFieldEnum.LastName),
            firstName: getUserValue(currentUser.CurrentUser.userProfile, TravellerFieldEnum.FirstName),
            email: getUserValue(currentUser.CurrentUser.userProfile, TravellerFieldEnum.Email)
        };
    }, [currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser]);
    useEffect(function () {
        var _a, _b;
        if (isFastSearchCorrect(config, fastSearch)) {
            var parsedSearchParams = fastSearchParse(fastSearch, passengersConfig, allowPromoCodes);
            if (!parsedSearchParams.currency) {
                parsedSearchParams.currency = getCurrency();
            }
            if (payByMiles) {
                if (parsedSearchParams.ffpMode &&
                    (!((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _a === void 0 ? void 0 : _a.userFfpInfo) || ((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.CurrentUser) === null || _b === void 0 ? void 0 : _b.isFfpAuthorizationNeeded))) {
                    setAuthorizationFfp(true);
                }
                else {
                    startSearch(parsedSearchParams);
                }
            }
            else {
                var ffpMode = parsedSearchParams.ffpMode, filteredSearchParams = __rest(parsedSearchParams, ["ffpMode"]);
                startSearch(filteredSearchParams);
            }
        }
        else {
            setIsValid(false);
        }
    }, [fastSearch, currentUser]);
    var onCloseAuthorizationFfp = function () {
        setAuthorizationFfp(false);
        setIsValid(false);
    };
    var onSaveAuthorizationFfp = function () {
        setTimeout(function () {
            startSearch(fastSearchParse(fastSearch, passengersConfig, allowPromoCodes));
        }, 2000);
    };
    if (payByMiles && !loading && authorizationFfp) {
        return (React.createElement(LoyaltyConnect, { userInfo: userInfo, isOpen: authorizationFfp, authType: "authenticate", onLoyaltyCardSaved: onSaveAuthorizationFfp, onClose: onCloseAuthorizationFfp, onGoToSettings: onGoToAccountSettings }));
    }
    return isValid && loading ? React.createElement(PromoLoader, null) : React.createElement(React.Fragment, null, redirect);
};
export default FastSearch;
