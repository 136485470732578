import { __assign, __read, __spreadArray } from "tslib";
import { CLEAR, DIFFERENT_FOR_RETURN_FLIGHT, DISABLE, SET_ALL_SERVICES, SET_SERVICES, SET_BAGGAGE_TAB } from './actions';
import { getSelectedServicesByType } from './utils';
var defaultState = {
    services: null,
    selectedServices: [],
    differentForReturnFlight: false,
    disabled: false,
    maxBaggageCount: 3,
    maxBaggagePerPassengerCount: null,
    baggageTab: null,
    passengers: null,
    segmentIds: []
};
export var upgradeModalReducer = function (state, action) {
    if (state === void 0) { state = defaultState; }
    if (action.type !== DISABLE && state.disabled) {
        return state;
    }
    switch (action.type) {
        case CLEAR:
            return __assign(__assign({}, state), { selectedServices: state.selectedServices.map(function (selectedService) {
                    var _a, _b, _c;
                    return __assign(__assign({}, selectedService), { 
                        // значение сбрасываем на кол-во оплаченных позиций либо на 0
                        count: state.services.find(function (service) { return service.id === selectedService.serviceId; })
                            ? (_c = (_b = (_a = state.passengers
                                .find(function (p) { return p.id === selectedService.passengerId; })) === null || _a === void 0 ? void 0 : _a.services.find(function (s) {
                                return s.serviceId === selectedService.serviceId &&
                                    s.segmentIds.some(function (s) { return selectedService.segmentIds.includes(s); });
                            })) === null || _b === void 0 ? void 0 : _b.confirmedCount) !== null && _c !== void 0 ? _c : 0
                            : selectedService.count });
                }) });
        case DIFFERENT_FOR_RETURN_FLIGHT:
            var newValue = !state.differentForReturnFlight;
            if (newValue) {
                return __assign(__assign({}, state), { differentForReturnFlight: newValue });
            }
            else {
                var minCountByServiceMap_1 = new Map();
                state.selectedServices.forEach(function (service) {
                    var key = "".concat(service.serviceId, "-").concat(service.passengerId);
                    var count = minCountByServiceMap_1.get(key);
                    minCountByServiceMap_1.set(key, count !== undefined ? (service.count < count ? service.count : count) : service.count);
                });
                return __assign(__assign({}, state), { selectedServices: state.selectedServices.map(function (selectedService) {
                        var _a;
                        var key = "".concat(selectedService.serviceId, "-").concat(selectedService.passengerId);
                        var hasService = state.services.find(function (service) { return service.id === selectedService.serviceId; });
                        return __assign(__assign({}, selectedService), { count: hasService ? (_a = minCountByServiceMap_1.get(key)) !== null && _a !== void 0 ? _a : 0 : selectedService.count });
                    }), differentForReturnFlight: newValue });
            }
        case SET_SERVICES:
            var selectedServices = getSelectedServicesByType(state, action);
            return __assign(__assign({}, state), { selectedServices: selectedServices });
        case SET_BAGGAGE_TAB:
            return __assign(__assign({}, state), { baggageTab: action.payload });
        case SET_ALL_SERVICES:
            return __assign(__assign({}, state), { selectedServices: __spreadArray([], __read(action.payload.map(function (service) { return (__assign({}, service)); })), false) });
        case DISABLE:
            return __assign(__assign({}, state), { disabled: action.payload });
    }
    return state;
};
