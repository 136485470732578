import { __awaiter, __generator, __read } from "tslib";
import { useEffect } from 'react';
import { LoginType, OauthServiceType, useAuthenticateMutation } from '@websky/graphql';
import { useLocation } from 'react-router-dom';
import { prevSocialAuthKeyFactory, tinkoffCallbackUrlSuffix } from './utils';
import { sessionStorageGet } from '../../cache';
import { captureErrorSentry } from '../../utils';
export var useTinkoffCallback = function (onSuccessFullAuth) {
    var location = useLocation();
    var _a = __read(useAuthenticateMutation(), 2), authenticate = _a[0], loading = _a[1].loading;
    var auth = function (state, code) { return __awaiter(void 0, void 0, void 0, function () {
        var prevKey, prevUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 2, 3]);
                    return [4 /*yield*/, authenticate({
                            variables: {
                                params: {
                                    loginType: LoginType.TinkoffID,
                                    secureCode: JSON.stringify({ state: state, code: code })
                                }
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    prevKey = prevSocialAuthKeyFactory(OauthServiceType.TinkoffID);
                    prevUrl = sessionStorageGet(prevKey);
                    if (prevUrl) {
                        window.location.href = prevUrl;
                    }
                    else {
                        captureErrorSentry('Social auth callback url has not been found in the session storage');
                    }
                    return [7 /*endfinally*/];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (location.pathname.includes(tinkoffCallbackUrlSuffix)) {
            var searchParams = new URL(window.location.href).searchParams;
            var code = searchParams.get('code');
            var state = searchParams.get('state');
            if (code && state) {
                auth(state, code).then(onSuccessFullAuth);
            }
        }
    }, []);
    return {
        isLoading: loading
    };
};
