import * as React from 'react';
import { useMemo } from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import PromoMessage from './PromoMessage/PromoMessage';
import Footer from './Footer/Footer';
import { BaggageList } from '../../BaggageList';
import { convertBaggageOnBaggageItem } from '../utils';
import { useToggleable } from '../../../hooks';
import UpgradeBaggageDialog from '../../UpgradeBaggageDialog/components/UpgradeBaggageDialog';
import { joinServicesByRfics } from '../../UpgradeBaggageDialog/utils';
import { initI18n } from '../../../utils';
import { getMinPrice, getPriceToPay } from '../../../BaggagePage/utils';
import BaggageContextProvider from '../../context';
import cn from 'classnames';
initI18n('AdditionalBaggage');
var AdditionalBaggage = function (props) {
    var css = useTheme('AdditionalBaggage').AdditionalBaggage;
    var t = useTranslation('AdditionalBaggage').t;
    var _a = useToggleable(false), isOpen = _a.isOpen, open = _a.open, close = _a.close;
    var baggagesByRfics = useMemo(function () { return joinServicesByRfics(props.additionalBaggage); }, [props.additionalBaggage]);
    var priceToPay = useMemo(function () { return getPriceToPay(props.selectedServices, props.additionalBaggage); }, [
        props.additionalBaggage,
        props.selectedServices
    ]);
    var minPrice = useMemo(function () { return getMinPrice(props.additionalBaggage); }, [props.additionalBaggage]);
    var items = useMemo(function () {
        var baggages = baggagesByRfics.map(function (baggages) {
            return convertBaggageOnBaggageItem(baggages, props.selectedServices, props.segments);
        });
        baggages = baggages.sort(function (a, b) {
            var _a, _b;
            return ((_a = a.baggage[0].value) === null || _a === void 0 ? void 0 : _a.amount) - ((_b = b.baggage[0].value) === null || _b === void 0 ? void 0 : _b.amount);
        });
        return baggages;
    }, [props.additionalBaggage, props.selectedServices, baggagesByRfics, props.segments]);
    return (React.createElement("div", { className: cn(css.root, props.className) },
        React.createElement("div", { className: css.header },
            React.createElement("div", null, t('More bags?')),
            React.createElement(PromoMessage, { className: css.promo_message })),
        React.createElement("div", { className: css.body },
            React.createElement(BaggageContextProvider, { value: { onBaggageIconClick: open } },
                React.createElement(BaggageList, { items: items, slidesToShow: 2 }))),
        React.createElement("div", { className: css.footer },
            React.createElement(Footer, { price: priceToPay, minPrice: minPrice, onClickEdit: open, isLoading: props.isLoading })),
        React.createElement(UpgradeBaggageDialog, { className: props.className, open: isOpen, onClose: close, onConfirm: props.onConfirm, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, includedBaggage: [], upgradeBaggage: props.additionalBaggage, disabled: props.disabled, selectedServices: props.selectedServices, maxBaggagePerPassengerCount: props.maxBaggagePerPassenger, maxBaggageCount: props.maxBaggage, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: props.isLoading, headerProps: {
                header: t('Check-in baggage'),
                description: t('More baggage for you')
            } })));
};
export default AdditionalBaggage;
