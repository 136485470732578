import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSegments } from '../../../Modules/Checkin/store/order/selectors';
import { useToggleable } from '../../../hooks';
export var useSelectCancelSegments = function (onCheckinCancel) {
    var segments = useSelector(getSegments);
    var _a = useToggleable(false), isSelectSegmentsModalOpen = _a.isOpen, openSelectSegmentsModal = _a.open, closeSelectSegmentsModal = _a.close;
    var _b = __read(useState([]), 2), selectedSegmentsToCancel = _b[0], setSelectedSegmentsToCancel = _b[1];
    var selectSegmentToCancel = useCallback(function (segment) {
        var selectedSegmentIndex = selectedSegmentsToCancel.findIndex(function (selectedSegment) { return selectedSegment.id === segment.id; });
        if (selectedSegmentIndex !== -1) {
            setSelectedSegmentsToCancel(function (state) {
                var newState = state.slice();
                newState.splice(selectedSegmentIndex, 1);
                return newState;
            });
        }
        else {
            setSelectedSegmentsToCancel(function (state) { return state.concat(segment); });
        }
    }, [selectedSegmentsToCancel, setSelectedSegmentsToCancel]);
    var handleCancelSegments = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onCheckinCancel(selectedSegmentsToCancel.map(function (segment) { return segment.id; }))];
                case 1:
                    _a.sent();
                    closeSelectSegmentsModal();
                    return [2 /*return*/];
            }
        });
    }); }, [selectedSegmentsToCancel, onCheckinCancel]);
    var segmentsWithCancellationAvailable = useMemo(function () {
        return segments.filter(function (segment) { return segment.checkinInfo.cancelAvailable; });
    }, [segments]);
    var isCancelAvailableForMultipleSegments = useMemo(function () {
        return segments.length > 1;
    }, [segments]);
    return {
        isCancelAvailableForMultipleSegments: isCancelAvailableForMultipleSegments,
        segmentsWithCancellationAvailable: segmentsWithCancellationAvailable,
        openSelectSegmentsModal: openSelectSegmentsModal,
        closeSelectSegmentsModal: closeSelectSegmentsModal,
        isSelectSegmentsModalOpen: isSelectSegmentsModalOpen,
        selectedSegmentsToCancel: selectedSegmentsToCancel,
        setSelectedSegmentsToCancel: setSelectedSegmentsToCancel,
        selectSegmentToCancel: selectSegmentToCancel,
        onConfirm: handleCancelSegments
    };
};
