import { __assign } from "tslib";
import * as React from 'react';
import { useRef, useEffect } from 'react';
import SliderSlick from 'react-slick';
var Slider = function (_a) {
    var children = _a.children, settings = _a.settings, passengers = _a.passengers, segmentId = _a.segmentId, passengerId = _a.passengerId;
    var sliderRef = useRef();
    useEffect(function () {
        if (sliderRef.current) {
            setTimeout(function () { var _a, _b; return (_b = (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickGoTo) === null || _b === void 0 ? void 0 : _b.call(_a, 0); });
        }
    }, [sliderRef.current, segmentId]);
    useEffect(function () {
        if (sliderRef.current) {
            var passengerIndex_1 = passengers.findIndex(function (passenger) { return passenger.id === passengerId; });
            setTimeout(function () { var _a, _b; return (_b = (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.slickGoTo) === null || _b === void 0 ? void 0 : _b.call(_a, passengerIndex_1); });
        }
    }, [sliderRef.current, passengers, passengerId]);
    return (React.createElement(SliderSlick, __assign({}, settings, { ref: sliderRef }), children));
};
export default Slider;
