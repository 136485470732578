import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';
import { AviaPassengerType } from '@websky/graphql';
export var convertScheduleSearchParams = function (parameters, date) { return ({
    passengers: [
        {
            passengerType: AviaPassengerType.ADT,
            count: 1
        }
    ],
    segments: parameters.segments.map(function (segment) { return ({
        departure: {
            iata: segment.departure.iata
        },
        arrival: {
            iata: segment.arrival.iata
        },
        date: date
    }); })
}); };
export var getWeekDatesIntervalOfDate = function (date) {
    return eachDayOfInterval({
        start: startOfWeek(date, { weekStartsOn: 1 }),
        end: endOfWeek(date, { weekStartsOn: 1 })
    });
};
export var sortLocationsByName = function (a, b) {
    if (a.name > b.name) {
        return 1;
    }
    else if (a.name < b.name) {
        return -1;
    }
    return 0;
};
