import * as React from 'react';
import Note from '../Note/Note';
import { useTranslation } from 'react-i18next';
var Notes = function (_a) {
    var isAutoMode = _a.isAutoMode, showReturnMessage = _a.showReturnMessage;
    var t = useTranslation('Refund').t;
    return (React.createElement(React.Fragment, null,
        isAutoMode && showReturnMessage && (React.createElement(Note, { title: t('Note'), type: 'info', iconColor: 'gray', content: t('The money will be returned to the card used to pay for the ticket within 15 days') })),
        !isAutoMode && (React.createElement(React.Fragment, null,
            React.createElement(Note, { title: t('Note'), type: 'info', iconColor: 'gray', content: t('The possibility and amount of refund will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.') }),
            React.createElement(Note, { title: t('Refund restrictions'), type: 'restriction', iconColor: 'red', content: t('After submitting the request, the seats you have selected will be canceled.') })))));
};
export default Notes;
