import * as React from 'react';
import MediaQuery from 'react-responsive';
import Dialog from '@material-ui/core/Dialog';
import * as DialogStyles from './DialogAdapter.css';
import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import Dropdown from '../../../Dropdown';
import ModalTransitionComponent from '../../../ModalTransitionComponent';
export var DialogAdapter = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
            React.createElement(Dropdown, { onClose: props.onClose, isOpen: props.isOpen, outsideClickIgnoreClass: props.outsideClickIgnoreClass }, props.children)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH, minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { onClose: props.onClose, open: props.isOpen, classes: {
                    root: props.popupClassName,
                    paper: DialogStyles.dialog__paper
                } }, props.children)),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dialog, { TransitionComponent: ModalTransitionComponent, onClose: props.onClose, open: props.isOpen, fullScreen: true, classes: {
                    root: props.popupClassName
                } }, props.children))));
};
