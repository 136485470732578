import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../../../theme';
import { format } from '../../../../../../../utils';
import { getAbsoluteDate } from '../../../../../../../Engine/components/FastSearch/utils';
import Notification from '../../../../../../../Notification/components/Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
var Timelimit = function (_a) {
    var timelimit = _a.timelimit, info = _a.info, restProps = __rest(_a, ["timelimit", "info"]);
    var css = useTheme('Checkin').Passengers;
    var t = useTranslation('Checkin').t;
    var text = "".concat(t('Online check-in will close at'), " ").concat(format(getAbsoluteDate(timelimit), 'HH:mm, dd MMMM')).concat(info ? " ".concat(info) : '');
    return (React.createElement(Notification, __assign({ className: css.timelimit, status: InfoMessageTypeEnum.Warning, type: "context", title: t('Attention'), infoMessage: {
            text: text,
            type: InfoMessageTypeEnum.Warning,
            code: 'timelimit'
        } }, restProps)));
};
export default Timelimit;
