import { __generator } from "tslib";
import { all } from 'redux-saga/effects';
import { analyzePurchasedProductsSaga, fillOrderSaga } from './order/sagas';
import { saveServicesSaga } from './selectedServices/sagas';
export default function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([fillOrderSaga(), analyzePurchasedProductsSaga(), saveServicesSaga()])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
