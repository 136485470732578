import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import SummaryHeader from './SummaryHeader';
var SummaryItem = function (_a) {
    var className = _a.className, price = _a.price, header = _a.header, children = _a.children;
    var theme = useTheme('PaymentForm').Summary;
    return (React.createElement("div", { className: theme.item },
        header && React.createElement(SummaryHeader, null, header),
        React.createElement("div", { className: cn(theme.item__content, className) },
            React.createElement("div", null, children),
            price)));
};
export default SummaryItem;
