import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useBookingPreview } from './BookingPreview.hook';
import OrderCities from '../../OrderCard/components/OrderPreview/OrderCities/OrderCities';
import OldButton from '../../Button';
import Button from '../../BaseComponents/Button';
import Loader from '../../BaseComponents/Button/components/Loader/Loader';
import { CloseClear } from '../../Icons';
import WarningModal from '../../WarningModal';
initI18n('BookingPreview');
var BookingPreview = function (props) {
    var className = props.className, order = props.order, isLoading = props.isLoading;
    var t = useTranslation('BookingPreview').t;
    var css = useTheme('BookingPreview').BookingPreview;
    var model = useBookingPreview(props);
    if (isLoading) {
        return (React.createElement("div", { className: cn(css.root, className) },
            React.createElement("div", { className: css.title }, t('Searching flight')),
            React.createElement("div", { className: css.loader },
                React.createElement(Loader, null))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.root, className) },
            props.onClearOrder && !model.isSaveLoading && (React.createElement("div", { className: css.header },
                React.createElement(OldButton, { variant: "text", className: css.clear, onClick: props.onClearOrder },
                    React.createElement("div", { className: css.clear__icon }, CloseClear),
                    t('Reset search')))),
            React.createElement("div", { className: css.title }, t('Flight found')),
            React.createElement("div", { className: css.wrapper },
                React.createElement("div", { className: css.info_field },
                    React.createElement("div", null, order.accessCode),
                    React.createElement("div", { className: css.info_field__travellers },
                        model.travellersCount,
                        " ",
                        t('SearchForm:passenger', { count: model.travellersCount }))),
                React.createElement("div", { className: cn(css.info_field, css.info_field__cities) },
                    React.createElement(OrderCities, { flight: order.flight, bookingPreview: true })),
                React.createElement("div", { className: css.info_field }, model.date)),
            React.createElement("div", { className: css.footer },
                !model.isLoading && (React.createElement(Button, { variant: 'action', className: cn(css.button, css.button_cancel), onClick: model.onViewOrder }, t('View booking'))),
                React.createElement(Button, { variant: 'action', className: cn(css.button), isLoading: model.isLoading, onClick: model.onSaveAndView }, t('View and add to cabinet')))),
        React.createElement(WarningModal, { title: t('Checkout:Oops...'), buttonText: t('Checkout:OK, thank you'), isOpen: !!model.error, content: model.error, onClose: model.onClearError, onButtonClick: model.onClearError })));
};
export default BookingPreview;
